import { CardMedia, Grid } from "@mui/material";
import { lookupViaCity } from "city-timezones";
import { DateTime } from "luxon";
import { useState } from "react";
import { useEffect } from "react";
import { BIRTH_DATE_HOROSCOPE, BIRTH_LOCATION_HOROSCOPE, BIRTH_TIME_HOROSCOPE, COMMUNITY_ID, DATA_HOROSCOPE_CHART, DATA_HOROSCOPE_MATCHMAKING, GENDER, NAME, TIMEZONE_HOROSCOPE, USER_ID } from "../../common_utils/constants";
import "../../styles/compatibility_table.css";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import "../../styles/transitionstyle.css";
import { useNotificationContext } from "../../contexts/notificationContext";
import MsgConfirmClose from "../../app_utils/MsgConfirmClose";
import React from "react";
import { useMemberDetailsContext } from "../../contexts/memberdetailsContext";


function Horoscope_details({ list_member_details ,data_navmsa_horo , urlSearchParams }) {


  const [navmsaImage, setNavmsaImage] = useState("");
  const [horoscopeImage, setHoroscopeImage] = useState("");
  const [isAnimated, setAnimated] = useState(false);
  const [seehoroscope, setSeeHoroscope] = useState(false);
  const [msg, setMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isgrant, setgrant] = useState("-1");
  const [firsttime , setfirstTime]  = useState(false);
  const [parsedOutput, setParsedOutput] = useState(null);
  const [matchMaking, setMatchMaking] = useState(null);

   
  const {fetchMatchMakingHoro, data_match_making_horo , fetchHoroscopeChartHoro, data_horoscope_horo} = useMemberDetailsContext();
  const { allowed_features_list_horo, allowed_list_horo , insert_allowed_from_user_horo , insert_allowed_list_horo , update_allowed_from_user_horo , update_allowed_list_horo , insert_notificatioin_data_notification_horo , insertNotification_memberdetails_notification_horo , user_validation_data_list , insert_horoscope_view_list , insert_horoscope_view} = useNotificationContext();

  const { t } = useTranslation();

  const handleclosemsg = () => {
    setMsg(false);

    insertNotification_memberdetails_notification_horo({
      "notifi_type": "request_horoscope",
      "message": localStorage.getItem(NAME) + " sent you the request regarding the details about horoscope",
      "sender_type": "user",
      "sender_id": localStorage.getItem(USER_ID),
      "reciever_id": urlSearchParams.get("userId"),
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });

  }

  const handleCancel = () => {
    setMsg(false);
  }

  useEffect(()=>{

     if(list_member_details[6][0]["0"] !== undefined){


    let year = list_member_details[6][0]["0"]["birth_date"].split("/")[2];
    let month = list_member_details[6][0]["0"]["birth_date"].split("/")[1];
    let date = list_member_details[6][0]["0"]["birth_date"].split("/")[0];

    let hour = list_member_details[6][0]["0"]["birth_time"].split(":")[0];
    let minutes = list_member_details[6][0]["0"]["birth_time"].split(":")[1];
    let seconds = list_member_details[6][0]["0"]["birth_time"].split(":")[2];

    let latitude = list_member_details[6][0]["0"]["birth_location"].split(",")[0];
    let longitude = list_member_details[6][0]["0"]["birth_location"].split(",")[1];

    const cityLookup = lookupViaCity(list_member_details[6][0]["0"]["birth_place"]);

    const timezoneIdentifier = cityLookup[0].timezone;
    const now = DateTime.now().setZone(timezoneIdentifier);
    const offsetInHours = now.offset / 60;


    const input_horoscope = {
    "dob": date+"/"+month+"/"+year,
   "tob": hour+":"+minutes,
   "tz":parseFloat(offsetInHours),
   "lat":parseFloat(latitude),
   "lon":parseFloat(longitude), 
    }

    if(localStorage.getItem(DATA_HOROSCOPE_CHART) == null){
      fetchHoroscopeChartHoro(input_horoscope);
   }else{
      setHoroscopeImage(localStorage.getItem(DATA_HOROSCOPE_CHART));
    }
    

    //fetchNavmsaChartHoro(input_horoscope);
    

    let year2 = localStorage.getItem(BIRTH_DATE_HOROSCOPE).split("/")[2];
    let month2 = localStorage.getItem(BIRTH_DATE_HOROSCOPE).split("/")[1];
    let date2 = localStorage.getItem(BIRTH_DATE_HOROSCOPE).split("/")[0];

    let hour2 = localStorage.getItem(BIRTH_TIME_HOROSCOPE).split(":")[0];
    let minutes2 = localStorage.getItem(BIRTH_TIME_HOROSCOPE).split(":")[1];
    let seconds2 = localStorage.getItem(BIRTH_TIME_HOROSCOPE).split(":")[2];

    let latitude2 = localStorage.getItem(BIRTH_LOCATION_HOROSCOPE).split(",")[0];
    let longitude2 = localStorage.getItem(BIRTH_LOCATION_HOROSCOPE).split(",")[1];


    if(localStorage.getItem(GENDER).toLowerCase() == "male"){


      const input_horoscope_match_making =  {
        "boy_dob": date2+"/"+month2+"/"+year2,
        "boy_tob": hour2+":"+minutes2,
        "boy_tz": parseFloat(localStorage.getItem(TIMEZONE_HOROSCOPE)).toString(),
        "boy_lat": latitude2,
        "boy_lon": longitude2,
        "girl_dob": date+"/"+month+"/"+year,
        "girl_tob": hour+":"+minutes,
        "girl_tz": parseFloat(offsetInHours).toString(),
        "girl_lat": latitude,
        "girl_lon": longitude
      }

       if(localStorage.getItem(DATA_HOROSCOPE_MATCHMAKING) == null){
        fetchMatchMakingHoro(input_horoscope_match_making);

       }else{

      //  alert(localStorage.getItem(DATA_HOROSCOPE_MATCHMAKING));
        setSeeHoroscope(true);
         setMatchMaking(JSON.parse(localStorage.getItem(DATA_HOROSCOPE_MATCHMAKING)));
      }
      
    }else{

      const input_horoscope_match_making =  {
        "boy_dob": date+"/"+month+"/"+year,
        "boy_tob": hour+":"+minutes,
        "boy_tz": parseFloat(offsetInHours).toString(),
        "boy_lat": latitude,
        "boy_lon": longitude,
        "girl_dob": date2+"/"+month2+"/"+year2,
        "girl_tob": hour2+":"+minutes2,
        "girl_tz": parseFloat(localStorage.getItem(TIMEZONE_HOROSCOPE)).toString(),
        "girl_lat": latitude2,
        "girl_lon": longitude2
      }

      if(localStorage.getItem(DATA_HOROSCOPE_MATCHMAKING) == null){
        fetchMatchMakingHoro(input_horoscope_match_making);

      }else{

       // alert(localStorage.getItem(DATA_HOROSCOPE_MATCHMAKING)["tara"]["boy_tara"]);

        setSeeHoroscope(true);
         setMatchMaking(localStorage.getItem(DATA_HOROSCOPE_MATCHMAKING));
      }

    }
   

  }

    

    

  },[]);



  useEffect(() => {

    setAnimated(true);


    if(firsttime == false){
      allowed_list_horo({
        "from_id": localStorage.getItem(USER_ID),
        "to_id": urlSearchParams.get("userId"),
        "type": "horoscope",
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });

      setfirstTime(true);
    }
  
      if(allowed_features_list_horo.length > 0){
       setgrant(allowed_features_list_horo[0]["is_grant"]);
      }





  }, [allowed_features_list_horo]);



  useEffect(() => {
   // console.log(data_match_making_horo["status"]);
    
   if(data_horoscope_horo != undefined){
    if (data_horoscope_horo.length > 0) {
      setHoroscopeImage(data_horoscope_horo);

     // alert(data_horoscope_horo);
    localStorage.setItem(DATA_HOROSCOPE_CHART , data_horoscope_horo);


    }


    
    
  }

    if(data_match_making_horo["status"] != undefined){
    if(data_match_making_horo["status"] == 200){

      alert(data_match_making_horo["response"].toString());

      console.log(data_match_making_horo["response"]);
    
      setSeeHoroscope(true);
      setMatchMaking(data_match_making_horo["response"]);

      const matchMakingDataString = JSON.stringify(data_match_making_horo["response"]);

      localStorage.setItem(DATA_HOROSCOPE_MATCHMAKING , matchMakingDataString);
    // Check if parsing was successful
   }

  }
  


    if(insert_notificatioin_data_notification_horo.affectedRows != undefined){
      if(insert_notificatioin_data_notification_horo.affectedRows == 1 && insert_allowed_list_horo.affectedRows == undefined){
   
         insert_allowed_from_user_horo({
           "from_id":localStorage.getItem(USER_ID),
           "to_id":urlSearchParams.get("userId"),
           "type":"horoscope",
           "communityId":localStorage.getItem(COMMUNITY_ID)
       });
   
       }
     }
   
   
      console.log("test");
   
     if(insert_allowed_list_horo.affectedRows != undefined){
       if(insert_allowed_list_horo.affectedRows == 1 &&   allowed_features_list_horo[0] == undefined){
   
         allowed_list_horo({
           "from_id": localStorage.getItem(USER_ID),
           "to_id": urlSearchParams.get("userId"),
           "type": "horoscope",
           "communityId": localStorage.getItem(COMMUNITY_ID)
         });
         
   
       }
     }
   

   


  }, [data_horoscope_horo, data_navmsa_horo, data_match_making_horo , allowed_features_list_horo , insert_notificatioin_data_notification_horo , insert_allowed_list_horo]);


  useEffect(()=>{


    if(user_validation_data_list[0].length >  0){
      if(user_validation_data_list[1][0]["num_contact"]  <= user_validation_data_list[0][0]["number_contacts"]){

        if(list_member_details[6][0]["0"] != undefined ){
    insert_horoscope_view({
      "fromId":localStorage.getItem(USER_ID),
      "memberId":urlSearchParams.get("userId"),
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });
  }
}
 }


  },[]);

  const requestCallNumber = () => {

    setTitle("Request for Horoscope BirthChart and Match Making");
    setContent("Are you sure u want to request horoscope of this person?");
    setMsg(true);

  }


  return (
    <>
      <div>
      { user_validation_data_list[0].length >  0 ?

      <div>
        {user_validation_data_list[1][0]["num_horoscope"]  <= user_validation_data_list[0][0]["number_horoscope"]  ||   list_member_details[17][0]["0"]["view_horoscope"].split(",").includes(urlSearchParams.get("userId")) ?

        <CSSTransition
          in={isAnimated}
          timeout={3000} // Duration of the transition in milliseconds
          classNames="fade"
          unmountOnExit>
          {list_member_details[6][0]["0"] != undefined ?
            <div style={{ "paddingBottom": "50px", "marginTop": "80px" }}>


              <div style={{ "paddingBottom": "0px" }}>

                <div className="rounded_box">
                  <div className="header">{t('horoscope_l')}</div>

                  <div className="contact_details_value">
                    <span className="keycontact">{t('birth_date_l') + ":"}</span> <span className="valuecontact">{list_member_details[6][0]["0"]["birth_date"]}</span><br />
                    <span className="keycontact">{t('birth_time_label') + ":"}</span> <span className="valuecontact">{list_member_details[6][0]["0"]["birth_time"]}</span><br />
                    <span className="keycontact">{t('birth_place_label') + ":"}</span> <span className="valuecontact">{list_member_details[6][0]["0"]["birth_place"]}</span><br />
                    <span className="keycontact">{t('mangalik_or_not') + ":"}</span> <span className="valuecontact">{list_member_details[6][0]["0"]["is_mangalik"] == "1" ? "This Person is Mangalik" : "This Person is not Mangalik"}</span><br />
                    <span className="keycontact">{t('believe_horoscope') + ":"}</span> <span className="valuecontact">{list_member_details[6][0]["0"]["believe_horoscope"] == "Yes" ? "Believes in horoscope" : "Does not believe in horoscope"}</span><br />
                    <span className="keycontact">{t('gotra_l') + ":"}</span> <span className="valuecontact">{list_member_details[6][0]["0"]["gotra"]}</span><br />
                  </div>

                </div>
              </div>


               {list_member_details[14][0]["0"] == undefined ?  <div>
                
                <Grid container xs={12} md={12} style={{ justifyContent: "space-around" }}>
  
  
                  <Grid item xs={12} md={0.5} ></Grid>
                  <Grid item xs={12} md={12}>
                    <CardMedia
                      component="div" // Use a <div> as the component
      sx={{
        '& > *': { // Apply styles to the child (in this case, the SVG)
          width: '40%',
          height: '40%',
        },
      }}
      // Render the SVG string using dangerouslySetInnerHTML
      dangerouslySetInnerHTML={{ __html: data_horoscope_horo }}
                    />
                  </Grid>
  
                </Grid>
  
                {seehoroscope == true  && matchMaking !=  undefined ?
                  <div style={{ "marginTop": "-60px" }}>
                  <table className="compatibility_table">
      <thead>
        <tr>
          <th>Aspect</th>
          <th>Boy</th>
          <th>Girl</th>
          <th>Score</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Tara</td>
          <td>{matchMaking["tara"]["boy_tara"]}</td>
          <td>{matchMaking["tara"]["girl_tara"]}</td>
          <td>{matchMaking["tara"]["full_score"]}</td>
          <td>{matchMaking["tara"]["description"]}</td>
        </tr>
        <tr>
          <td>Gana</td>
          <td>{matchMaking["gana"]["boy_gana"]}</td>
          <td>{matchMaking["gana"]["girl_gana"]}</td>
          <td>{matchMaking["gana"]["full_score"]}</td>
          <td>{matchMaking["gana"]["description"]}</td>
        </tr>
        <tr>
          <td>Yoni</td>
          <td>{matchMaking["yoni"]["boy_yoni"]}</td>
          <td>{matchMaking["yoni"]["girl_yoni"]}</td>
          <td>{matchMaking["yoni"]["full_score"]}</td>
          <td>{matchMaking["yoni"]["description"]}</td>
        </tr>
        <tr>
          <td>Bhakoot</td>
          <td>{matchMaking["bhakoot"]["boy_rasi_name"]}</td>
          <td>{matchMaking["bhakoot"]["girl_rasi_name"]}</td>
          <td>{matchMaking["bhakoot"]["full_score"]}</td>
          <td>{matchMaking["bhakoot"]["description"]}</td>
        </tr>
        <tr>
          <td>Grahamaitri</td>
          <td>{matchMaking["grahamaitri"]["boy_lord"]}</td>
          <td>{matchMaking["grahamaitri"]["girl_lord"]}</td>
          <td>{matchMaking["grahamaitri"]["full_score"]}</td>
          <td>{matchMaking["grahamaitri"]["description"]}</td>
        </tr>
        <tr>
          <td>Vasya</td>
          <td>{matchMaking["vasya"]["boy_vasya"]}</td>
          <td>{matchMaking["vasya"]["girl_vasya"]}</td>
          <td>{matchMaking["vasya"]["full_score"]}</td>
          <td>{matchMaking["vasya"]["description"]}</td>
        </tr>
        <tr>
          <td>Nadi</td>
          <td>{matchMaking["nadi"]["boy_nadi"]}</td>
          <td>{matchMaking["nadi"]["girl_nadi"]}</td>
          <td>{matchMaking["nadi"]["full_score"]}</td>
          <td>{matchMaking["nadi"]["description"]}</td>
        </tr>
        <tr>
          <td>Varna</td>
          <td>{matchMaking["varna"]["boy_varna"]}</td>
          <td>{matchMaking["varna"]["girl_varna"]}</td>
          <td>{matchMaking["varna"]["full_score"]}</td>
          <td>{matchMaking["varna"]["description"]}</td>
        </tr>
        <tr>
          <td colSpan="3">Total Score:</td>
          <td>{matchMaking["score"]}</td>
          <td>{matchMaking["bot_response"]}</td>
        </tr>
      </tbody>
    </table>
                  </div>
  
                  : ""
  
                }
  
              
                </div>    : list_member_details[14][0]["0"]["horoscope_privacy"] == "2" && allowed_features_list_horo[0] == undefined ?  <div style={{textAlign:"center" , fontSize:"20px" , fontWeight:"bold"}} onClick={requestCallNumber}>{"Click here to request birthchart and Gunmilan Match Making"}</div> : isgrant == "0"  ?  <div style={{textAlign:"center" , fontSize:"20px" , fontWeight:"bold"}}>{"Request Sent For BirthChart and Match Making"}</div> :
              <div>
                
              
              <Grid container xs={12} md={12} style={{ justifyContent: "left" }}>


                <Grid item xs={12} md={3} style={{marginLeft:"10%" , color:"white" , padding:"10px" , width:"20%" , fontWeight:"bold" , borderRadius:"20px" ,fontSize:"20px" , backgroundColor:"#ee4d4d" ,border:'2px solid pink' , marginBottom:"15px" , display:"flex" , justifyContent:"center"}}>Birth Chart</Grid>
                <Grid item xs={12} md={12} style={{marginLeft:"10%"}}>
                <CardMedia
     
      component="div" // Use a <div> as the component
      sx={{
        '& > *': { // Apply styles to the child (in this case, the SVG)
          width: '40%',
          height: '40%',
        },
      }}
      // Render the SVG string using dangerouslySetInnerHTML
      dangerouslySetInnerHTML={{ __html: horoscopeImage }}
    />
                </Grid>

                

              </Grid>

              {seehoroscope == true && matchMaking !=  undefined  ?
                <div style={{ "marginTop": "-60px" }}>
                  <Grid item xs={12} md={12} style={{marginLeft:"10%" , color:"white" ,padding:"10px" , width:"20%" , fontWeight:"bold"  , borderRadius:"20px" , fontSize:"20px" , backgroundColor:"#ee4d4d" ,border:'2px solid pink' , display:"flex" , justifyContent:"center"}}>GunMilan Score</Grid>
                 <table className="compatibility_table">
      <thead>
        <tr>
          <th>Aspect</th>
          <th>Boy</th>
          <th>Girl</th>
          <th>Score</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Tara</td>
          <td>{matchMaking["tara"]["boy_tara"]}</td>
          <td>{matchMaking["tara"]["girl_tara"]}</td>
          <td>{matchMaking["tara"]["full_score"]}</td>
          <td>{matchMaking["tara"]["description"]}</td>
        </tr>
        <tr>
          <td>Gana</td>
          <td>{matchMaking["gana"]["boy_gana"]}</td>
          <td>{matchMaking["gana"]["girl_gana"]}</td>
          <td>{matchMaking["gana"]["full_score"]}</td>
          <td>{matchMaking["gana"]["description"]}</td>
        </tr>
        <tr>
          <td>Yoni</td>
          <td>{matchMaking["yoni"]["boy_yoni"]}</td>
          <td>{matchMaking["yoni"]["girl_yoni"]}</td>
          <td>{matchMaking["yoni"]["full_score"]}</td>
          <td>{matchMaking["yoni"]["description"]}</td>
        </tr>
        <tr>
          <td>Bhakoot</td>
          <td>{matchMaking["bhakoot"]["boy_rasi_name"]}</td>
          <td>{matchMaking["bhakoot"]["girl_rasi_name"]}</td>
          <td>{matchMaking["bhakoot"]["full_score"]}</td>
          <td>{matchMaking["bhakoot"]["description"]}</td>
        </tr>
        <tr>
          <td>Grahamaitri</td>
          <td>{matchMaking["grahamaitri"]["boy_lord"]}</td>
          <td>{matchMaking["grahamaitri"]["girl_lord"]}</td>
          <td>{matchMaking["grahamaitri"]["full_score"]}</td>
          <td>{matchMaking["grahamaitri"]["description"]}</td>
        </tr>
        <tr>
          <td>Vasya</td>
          <td>{matchMaking["vasya"]["boy_vasya"]}</td>
          <td>{matchMaking["vasya"]["girl_vasya"]}</td>
          <td>{matchMaking["vasya"]["full_score"]}</td>
          <td>{matchMaking["vasya"]["description"]}</td>
        </tr>
        <tr>
          <td>Nadi</td>
          <td>{matchMaking["nadi"]["boy_nadi"]}</td>
          <td>{matchMaking["nadi"]["girl_nadi"]}</td>
          <td>{matchMaking["nadi"]["full_score"]}</td>
          <td>{matchMaking["nadi"]["description"]}</td>
        </tr>
        <tr>
          <td>Varna</td>
          <td>{matchMaking["varna"]["boy_varna"]}</td>
          <td>{matchMaking["varna"]["girl_varna"]}</td>
          <td>{matchMaking["varna"]["full_score"]}</td>
          <td>{matchMaking["varna"]["description"]}</td>
        </tr>
        <tr>
          <td colSpan="3">Total Score:</td>
          <td>{matchMaking["score"]}</td>
          <td>{matchMaking["bot_response"]}</td>
        </tr>
      </tbody>
    </table>
                </div>

                : ""

              }


            
              </div>

            }


<MsgConfirmClose
            isOpen={msg}
            handleClose={handleclosemsg}
            handleCancel={handleCancel}
            title={title}
            content={content}
            closeText={"Request Horoscope"}
            cancelText={"Cancel"} />


            </div>
            : <div style={{fontSize:"25px" , marginTop:"100px", paddingBottom:"100px" , fontWeight:"bold" , color:"#E3256B" , textAlign:"center"}}>{"Horoscope Details not available"}</div>


          }

        </CSSTransition>
        :
        <div style={{fontSize:"25px" , marginTop:"200px" , fontWeight:"bold" , color:"#E3256B" , textAlign:"center"}}>{"sorry Horoscope View limit as per your plan has reached its peak"}</div>
     }
        </div>

        : <div style={{fontSize:"25px" , marginTop:"200px" , fontWeight:"bold" , color:"#E3256B" , textAlign:"center"}}>{"Only Premium user can see Horoscope details Please Click to upgrade"}</div>

      }
      </div>
    </>);






}
export default Horoscope_details;
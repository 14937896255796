import { useState } from "react";
import { ROLE, SENDER_EMAIL, SENDER_NAME } from "../../../common_utils/constants";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { Grid ,TextField , MenuItem ,Button ,Chip} from "@mui/material";
import StylishLabelOther from "../../component/StylishLabelOther";
import { EmailMarketingProvider, useEmailMarketingContext } from "../../contexts_admin/email_marketing_context";
import { useEffect } from "react";
import RichTextEditor from "../../component/RichTextEditor";
import EventDialog from "./eventDialog";
import BlogDialog from "./blogDialog";
import MembershipDialog from "./membershipDialog";
import UserDialog from "./userDailog";
import CSVReader from 'react-csv-reader';
import  styles  from "../../styles_admin/CsvReader.module.css";
import React from "react";
import { useRef } from "react";



function SendEmail(){

    const {email_template_fetch , data_emailtemplate_fetch ,  clear_event_list , clear_blog_list , user_email , email_welcomeuser , insert_emailsent , email_sent_fetch , send_email ,statusCode , clear_email_send} = useEmailMarketingContext();

    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);

    const [TemplateTypeDropdown , setTemplateTypeDropdown] = useState("");
    const [TemplateType , setTemplateType] = useState("");
    const [HtmlContent, setHtmlContent] = useState("");
    const [getContent, setgetContent] = useState("");
    const [Subject , setSubject] = useState("");
    const [openEvent ,setopenEvent] = useState(false);
    const [openBlog ,setopenBlog] = useState(false);
    const [openMembership ,setopenMembership] = useState(false);
    const [openUser ,setopenUser] = useState(false);

    const [type ,setType] = useState("");
    const [typeId ,setTypeId] = useState("");

    const [Id, setId] = useState("");

    useEffect(()=>{

        let communityId_admin = localStorage.getItem("communityId_admin");
        setCommunityId(communityId_admin);

        if(data_emailtemplate_fetch.length <= 0){
            email_template_fetch();
          }else{
    
           
          }

    },[]);


     const handleChangeEmailTemplate = (event) => {

        setTemplateTypeDropdown(event.target.value);
        setTemplateType(data_emailtemplate_fetch[event.target.value].type);

        setgetContent(data_emailtemplate_fetch[event.target.value].template_details.replace(/""/g, '"').replace(/''/g , "'"));

        const item = data_emailtemplate_fetch.find(i => i.type === data_emailtemplate_fetch[event.target.value].type);
        setId(item.Id);

    } 

     const handleSubject = (event) => {
    
        setSubject(event.target.value);
     }


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }


      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
      }


      const handleClickEvent = () => {
        clear_event_list();
        setopenEvent(true);

      }
  
      const handleCloseEvent = (isopen) => {
        setopenEvent(isopen);
      }

      const handleEventClickLink = (Item) => {

        setgetContent("<h2>Hi Dear! </h2><h4>This is Detail About Event from Community matrimonial</h4><h4> Event Name : "+Item.eventname+"</h4>\n"+"<h4> Event Date & Time : "+Item.date+"  "+Item.time+"\n"+"<h4> Event Venue : "+Item.venue+"</h4>\n"+Item.details.replace(/""/g, '"').replace(/''/g , "'"));

        setType("event");
        setTypeId(Item.Id);


      }




      const handleClickBlog = () => {
        clear_blog_list();
        setopenBlog(true);
      }
  
      const handleCloseBlog = (isopen) => {
        setopenBlog(isopen);
      }

      const handleBlogClickLink = (Item) => {

        setType("blog");
        setTypeId(Item.Id);


       
      const chipHtml = Item.key_words.split(',').map(chip => {
        return `<div style="display: inline-block; margin-right: 4px; margin-top: 5px; background-color: ${"#ff0000"}; color: white; padding: 4px 8px; border-radius: 16px;">${chip}</div>`;
      }).join('')


        setgetContent("<h2>Hi Dear! </h2><h4>This is Detail About Educational Content from Community matrimonial</h4><h4> Blog Title : "+Item.title+"</h4>\n"+"<h4> Keywords : "+chipHtml+"\n"+"<h4> Blog Details : </h4>\n"+Item.details.replace(/""/g, '"').replace(/''/g , "'"));

      }



      const handleClickMembership = () => {
        setopenMembership(true);
      }
  
      const handleCloseMembership = (isopen) => {
        setopenMembership(isopen);
      }

      const handleMembershipClickLink = (Item) => {


        setType("membership");
        setTypeId(Item.Id);

     const htmlTemplate =  "<div style={{border:'1px solid #ccc' , borderRadius:'5px' , padding:'10px' ,marginBottom:'20px'}}>"+
  "<h2>Membership Package</h2>"+
   "<div style={{marginTop:'10px',  fontSize:'16px'}}>"+
    "<p><strong>Plan Name:</strong> "+Item.plan_name+"</p>"+
    "<p><strong>Plan Price:</strong> "+Item.plan_price+"</p>"+
    "<p><strong>Validity (Days):</strong> "+Item.validity_days+"</p>"+
    "<p><strong>Profile Display:</strong> "+Item.profile_display+"</p>"+
    "<p><strong>Gallery View:</strong> "+Item.gallery_view+"</p>"+
    "<p><strong>Chat Option:</strong> "+Item.chatoption+"</p>"+
    "<p><strong>Express Interest:</strong> "+Item.express_interest+"</p>"+
    "<p><strong>Number of Express Interest:</strong>"+Item.number_express_interest+"</p>"+
    "<p><strong>Horoscope View:</strong> "+Item.horoscopeview+"</p>"+
    "<p><strong>PDF Access:</strong> "+Item.pdf_access+"</p>"+
    "<p><strong>Number of PDF Access:</strong> "+Item.num_pdf_access+"</p>"+
    "<p><strong>Verified Phone Numbers:</strong> "+Item.verified_phonenumbers+"</p>"+
    "<p><strong>Allowed Contacts:</strong> "+Item.allowed_contacts+"</p>"+
    "<p><strong>Number of Contacts:</strong> "+Item.number_contacts+"</p>"+
    "<p><strong>Number of Horoscope:</strong> "+Item.number_horoscope+"</p>"+
    "<p><strong>Number of Chat:</strong> "+Item.number_chat+"</p>"+
    "<p><strong>Number of Video:</strong> "+Item.number_video+"</p>"+
    "<p><strong>Verified ID Docs:</strong> "+Item.verified_id_docs+"</p>"+
  "</div>"+
"</div>";

    let d_type = "" , discount = 0 , final_price = 0;
   if(Item.discount_type == "special"){
           d_type = "<h2>You can Enjoy Special Discount of "+Item.discount+"% </h2>";
   }else if(Item.discount_type == "flash"){
          d_type = "<h2>This is flash sale for 3 days for discount of "+Item.discount+"% </h2>";
   }else if(Item.discount_type == "feature"){
          d_type = "<h2> This is about special features provided for discount of "+Item.discount+"% </h2>";
   }

          discount = (Item.plan_price * Item.discount)/100 ;
          final_price = Item.plan_price - discount;

        setgetContent("<h2>Hi Dear! </h2><h4>This is Detail About Exciting features and offers from Community matrimonial</h4>"+d_type+htmlTemplate+"<br/>"+" <h3> Your Special offer of "+final_price+" instead of "+Item.plan_price+"</h3><h2>Please hurry up!</h2>");

      }



      const handleOpenUser = () => {
        setopenUser(true);
      }
  
      const handleCloseUser = (isopen) => {
        setopenUser(isopen);
      }

      const handleUserClickLink = (Item) => {


      }




      const handleContent = (content) => {
        setHtmlContent(content);
    }




    const [key , setKey] =useState("");

     const resetCSVReader = () => {
    // Increment the key state to trigger reinitialization
    setKey(prevKey => prevKey + 1);
  };



    useEffect(()=>{
    
      if(statusCode == 201 || statusCode == 204){
  
         
  
          const currentDate = new Date();
  
    // Extract hour, minute, and date
    const hour = currentDate.getHours();
    const minute = currentDate.getMinutes();
    const date = currentDate.toDateString(); 
  
          insert_emailsent({"email_type":type, "email_type_Id":typeId ,"date_sent":date+", "+hour+":"+minute});
          alert("Email send out for delivery");
  
  
      }else{


      //  alert(statusCode);


      }

      resetCSVReader();
  
  
    },[statusCode]);



  const handleCSVData = (data, fileInfo) => {

    clear_email_send();

    console.log(data);
    // Assuming the CSV has two columns: email and name
    const emailarray = data.map(row => ({
      email: row["email"],
      name: row["name"]
    }));

   
    console.log(emailarray);
    
    
  
  send_email({
   "sender_name": SENDER_NAME,
   "sender_email": SENDER_EMAIL,
   "subject": Subject,
   "htmlContent": HtmlContent,
   "tolist": emailarray
    });

   

  };

  const csvReaderRef = useRef(null);

  const handleCSVButtonClick = () => {
    csvReaderRef.current.click();
  };




    let role = localStorage.getItem(ROLE);

    return(<>
    
    <div>
    <Grid container spacing={1} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "-10px" : "10px", left: role == "developer" ? "20px" : "15px" , width: "95%" , paddingRight:"15px"  , justifyContent:"center"  }}>

    <Grid  item xs={12}>
    { role == "developer" ? <StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

</Grid>

    <Grid  item xs={12} style={{marginTop:"130px" ,marginLeft:"25px" , marginRight:"10px"}}>
   <TextField
  select
  label="Select Email Template Type"
  variant="outlined"
  value={TemplateTypeDropdown}
  onChange= {handleChangeEmailTemplate}
  fullWidth
>
{data_emailtemplate_fetch.length > 0 &&  data_emailtemplate_fetch.map((option , index) => (
        <MenuItem key={option.Id} value={index}>
          {option.type}
        </MenuItem>
      ))}
</TextField>
</Grid>

   <Grid container style={{marginLeft:"40px" ,marginTop:"20px"}}>
   <Grid  item xs={4} >
   <Button variant="contained" style={{ border:'1px solid dotted'}} onClick={handleClickMembership}>Select Membership Packages</Button>
   </Grid>

   <Grid  item xs={4} >
   <Button variant="contained" onClick={handleClickEvent} >Send Email for Event To User</Button>
   </Grid>

   <Grid  item xs={4} >

   <Button variant="contained" onClick={handleClickBlog}>Send Educational Content To User</Button>

   </Grid>

   </Grid>

   <Grid item xs={12} style={{marginTop:"0px" ,marginLeft:"25px" , marginRight:"10px"}}>

<TextField
  label="Enter Subject"
  variant="outlined"
  value={Subject}
  onChange={handleSubject}
  fullWidth
  style={{marginTop:"10px" , float:"right" }}
  >
</TextField>

</Grid>


<Grid item xs={12} style={{marginTop:"20px" ,marginLeft:"25px" , marginRight:"10px"}}>

    <RichTextEditor SentContent={handleContent} getContent={getContent}/>

    </Grid>

    <Grid item xs={6} style={{ display:"flex" ,justifyContent:"center"}} >
    <Button variant="contained" style={{  width: "350px" ,  backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"50px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={handleOpenUser}>Send Email From Record</Button>
    </Grid>

    
    <Grid item xs={6} style={{ display:"flex" ,justifyContent:"center"}} >
    <Button variant="contained" style={{  width: "350px" ,  backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"50px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={handleCSVButtonClick}>Select CSV file To Send Emails</Button>
    </Grid>

    <Grid item xs={1} style={{marginTop:"50px" , display:"none"}}>
      
    <CSVReader
    ref={csvReaderRef}
        onFileLoaded={handleCSVData}
        parserOptions={{
          header: true,
          skipEmptyLines: true
        }}
       key={key}
      // Hide the default file input
      >
        
      </CSVReader>

    </Grid>


</Grid>


    {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
    {openEvent == true ? <EmailMarketingProvider><EventDialog isopen={true} setClose={handleCloseEvent}   setClickedLink={handleEventClickLink} communityId= {communityId} /></EmailMarketingProvider> : ""}
    {openBlog == true ? <EmailMarketingProvider><BlogDialog isopen={true} setClose={handleCloseBlog}   setClickedLink={handleBlogClickLink} communityId= {communityId} /></EmailMarketingProvider> : ""}
    {openMembership == true ? <EmailMarketingProvider><MembershipDialog isopen={true} setClose={handleCloseMembership}   setClickedLink={handleMembershipClickLink} communityId= {communityId} /></EmailMarketingProvider> : ""}
    {openUser == true ? <EmailMarketingProvider><UserDialog isopen={true} setClose={handleCloseUser}   setClickedLink={handleUserClickLink} communityId= {communityId} subject={Subject} template={HtmlContent} type={type} typeId={typeId}/></EmailMarketingProvider> : ""}
    </div>

    
    
    
    </>);


}

export default SendEmail;
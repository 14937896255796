const aboutusReducer = (state, action) => {
    switch (action.type) {

      case 'ABOUT_US':
        return {
          ...state,
          aboutus: action.payload,
          loading: false,
          error: null,
        };
      case 'ABOUT_US_ERROR':
        return {
          ...state,
          aboutus: [],
          loading: false,
          error: action.payload,
        };


        case 'CONTACT_US':
        return {
          ...state,
          contactus: action.payload,
          loading: false,
          error: null,
        };
      case 'CONTACT_US_ERROR':
        return {
          ...state,
          contactus: [],
          loading: false,
          error: action.payload,
        };



        case 'FAQS':
            return {
              ...state,
              faqs: action.payload,
              loading: false,
              error: null,
            };
          case 'FAQS_ERROR':
            return {
              ...state,
              faqs: [],
              loading: false,
              error: action.payload,
            };




            case 'PRIVACY_POLICY':
                return {
                  ...state,
                  privacy_policy: action.payload,
                  loading: false,
                  error: null,
                };
              case 'PRIVACY_POLICY_ERROR':
                return {
                  ...state,
                  privacy_policy: [],
                  loading: false,
                  error: action.payload,
                };





                case 'REFUND_POLICY':
                    return {
                      ...state,
                      refund_policy: action.payload,
                      loading: false,
                      error: null,
                    };
                  case 'REFUND_POLICY_ERROR':
                    return {
                      ...state,
                      refund_policy: [],
                      loading: false,
                      error: action.payload,
                    };
    




                    case 'CHIEF_MEMBERS':
                        return {
                          ...state,
                          chief_members: action.payload,
                          loading: false,
                          error: null,
                        };
                      case 'CHIEF_MEMBERS_ERROR':
                        return {
                          ...state,
                          chief_members: [],
                          loading: false,
                          error: action.payload,
                        };
    
                        

   
      default:
        return state;
    }
  };

  export default aboutusReducer;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/emailmarketingReducer';
import { BASE_URL } from '../../common_utils/constants';

const emailmarketingContext = createContext();

const initialState = {
  loading_emailmarketing_admin: true,
  error: null,
  data_user_email_admin: [],
  data_maritalstatus_email_admin: [],
  data_emailbymaritalstatus_admin: [],
  data_email_welcomeuser:[],
  data_insert_template:[],
  data_update_template:[],
  data_emailtemplate_fetch:[],
  data_emailtemplate_event_fetch:[],
  data_emailtemplate_blog_fetch:[],
  data_insert_emailsent:[],
  data_emailsent_fetch:[],
  data_membership_admin:[],
  statusCode: null,
};

let API = `${BASE_URL}`;


export const EmailMarketingProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);


const send_email = async (article) => {
  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/sendemail` , article);
    const jsonData = JSON.parse(response.data.data);
    
    dispatch({ type: 'SEND_EMAIL', payload: jsonData.response.statusCode });
  } catch (error) {
    dispatch({ type: 'SEND_EMAIL_ERROR', payload: error });
  }
};


const user_email = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/user_email` , article);
      
      dispatch({ type: 'AGERANGE_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'AGERANGE_ERROR', payload: error });
    }
  };


  


  const membership_fetchByCommunityId = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/membership_fetchByCommunityId` , article);
      
      dispatch({ type: 'MEMBERSHIP_COMMUNITYID_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'MEMBERSHIP_COMMUNITYID_ERROR', payload: error });
    }
  };

  const maritalstatus_email = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/maritalstatus_email` , article);
      
      dispatch({ type: 'MARITALSTATUSEMAIL_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'MARITALSTATUSEMAIL_ERROR', payload: error });
    }
  };


  const emailbyMaritalStatus = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/emailbyMaritalStatus` , article);
      
      dispatch({ type: 'EMAILBYMARITAL_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'EMAILBYMARITAL_ERROR', payload: error });
    }
  };



  const email_welcomeuser = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/email_welcomeuser` , article);
      
      dispatch({ type: 'EMAIL_WELCOME_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'EMAIL_WELCOME_ERROR', payload: error });
    }
  };


  const insert_emailtemplate = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/insert_emailtemplate` , article);
      
      dispatch({ type: 'INSERT_EMAILTEMPLATE_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'INSERT_EMAILTEMPLATE_ERROR', payload: error });
    }
  };



  const update_emailtemplate = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/update_emailtemplate` , article);
      
      dispatch({ type: 'UPDATE_EMAILTEMPLATE_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'UPDATE_EMAILTEMPLATE_ERROR', payload: error });
    }
  };
  


  const email_template_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/email_template_fetch` , article);
      
      dispatch({ type: 'EMAIL_TEMPLATE_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'EMAIL_TEMPLATE_ERROR', payload: error });
    }
  };


  const email_template_event_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/email_template_event_fetch` , article);
      
      dispatch({ type: 'EMAIL_TEMPLATE_EVENT_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'EMAIL_TEMPLATE_EVENT_ERROR', payload: error });
    }
  };


  const email_template_blog_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/email_template_blog_fetch` , article);
      
      dispatch({ type: 'EMAIL_TEMPLATE_BLOG_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'EMAIL_TEMPLATE_BLOG_ERROR', payload: error });
    }
  };


  const insert_emailsent = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/insert_emailsent` , article);
      
      dispatch({ type: 'INSERTEMAIL_SENT_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'INSERTEMAIL_SENT_ERROR', payload: error });
    }
  };



  const email_sent_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/email_sent_fetch` , article);
      
      dispatch({ type: 'EMAILSENT_FETCH_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'EMAILSENT_FETCH_ERROR', payload: error });
    }
  };

  const clear_insert_template = () => {

    dispatch({ type: 'CLEAR_INSERT_TEMPLATE' });

  }

  const clear_update_template = () => {

    dispatch({ type: 'CLEAR_UPDATE_TEMPLATE' });

  }


  const clear_data_mmebership = () => {

    dispatch({ type: 'CLEAR_DATA_MEMBERSHIP' });

  }

  const clear_event_list = () => {

    dispatch({ type: 'CLEAR_EVENT_LIST_ADMIN' });

  }


  const clear_blog_list = () => {

    dispatch({ type: 'CLEAR_BLOG_LIST_ADMIN' });

  }

  const clear_email_send = () => {

    dispatch({ type: 'CLEAR_EMAIL_SEND' });

  }

  return <emailmarketingContext.Provider value={{ ...state , dispatch , email_sent_fetch , email_template_blog_fetch , email_template_event_fetch , email_template_fetch , email_welcomeuser , emailbyMaritalStatus , user_email , insert_emailsent ,insert_emailtemplate , update_emailtemplate , maritalstatus_email , clear_insert_template , clear_update_template , membership_fetchByCommunityId , clear_blog_list, clear_data_mmebership , clear_event_list , send_email , clear_email_send}}>{children}</emailmarketingContext.Provider>;
};

export const useEmailMarketingContext = () => useContext(emailmarketingContext);
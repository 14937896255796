import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/dashboard_reducer';
import { BASE_URL } from '../../common_utils/constants';

const dashboardContext = createContext();

const initialState = {
  loading_dashboard_admin: true,
  error: null,
  data_dashboard_admin: [],
  data_community:[],
  loading_community:true,
};

let API = `${BASE_URL}`;


export const DashboardProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const dashboard = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/dashboard_api` , article);
      

      dispatch({ type: 'DASHBOARD_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'DASHBOARD_ERROR', payload: error });
    }
  };



  const community_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/community_fetchall` , article);
      
      dispatch({ type: 'COMMUNITY_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'COMMUNITY_ERROR', payload: error });
    }
  };


  
  return <dashboardContext.Provider value={{ ...state , dispatch , dashboard ,community_select}}>{children}</dashboardContext.Provider>;
};

export const useAdminDashboardContext = () => useContext(dashboardContext);
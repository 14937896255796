import React from 'react';
import Footer1 from './Footer1';
import "../styles/app.css";
import Footer from './Footer';
import { ADMIN_IS_LOGIN, IS_LOGIN } from '../common_utils/constants';

const Layout = ({ children }) => {
  return (
    <div id="root">
      <div id="content">
        {children}
        
        { localStorage.getItem(IS_LOGIN) == '1' && localStorage.getItem(ADMIN_IS_LOGIN) != '1' ? <Footer/> : <div id="push1"/> }
      </div>
      
    </div>
  );
};

export default Layout;
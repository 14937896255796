import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import ImageCropperScreen from "../../app_utils/ImageCropper";
import { useBasicContext } from '../../contexts/basicdetailsContext';
import { COMMUNITY_ID, PROFILE_ID, USER_ID } from '../../common_utils/constants';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';



const ImageGalleryWithCrop = ({ images , passtocropper , childFunction , fetchImagesItems}) => {



  


 const {insert_photos , picInsert , update_photos , picUpdate , picList} = useBasicContext();

     const [open , setopen] = useState(false);
     const [indexpass , setIndex] = useState(-1);
     const [indexpassother , setIndexother] = useState(-1);
     const [file , setFile] = useState(null);
     const [localFiles, setLocalFiles] = useState([]);
     const [isupdate ,setUpdate] = useState(-1);
     const [updateIndex , setUpdateIndex] = useState(-1);

  // Use useEffect to update local state when props change
  useEffect(() => {
  

    if(indexpassother != -1){

      setLocalFiles(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles[indexpassother] = file;
        return newFiles;
      });
  

    }else{

      setLocalFiles(images);

    }
    

  }, [file]);


  
  useEffect(()=>{

    if(childFunction == "1"){
      uploadFiles();
    }
    

  } , [childFunction ]);


  const navigate = useNavigate();

   useEffect(() => {

      if(isupdate == "1"){
        
            if(picInsert.affectedRows >= 1){
               
              alert("Successfully Uploaded");

            }else{

              alert("Error Uploading! Please try again");

            }

      }else if(isupdate == "2"){

        if( picUpdate.success == 1){

          navigate('/basic', { replace: true });
          alert("update Successfully Uploaded");
          

        }else{

          alert("Error Uploading! Please try again");

        }



      }


   }, [picInsert , picUpdate]);


    

      const handlecrop = (index) =>{
         setopen(true);
         setIndex(index);

         console.log(index); 
      };


      const onClose = () =>{
        setopen(false);
     };


     const convertTofile = (base64Image , indexcropper) =>{
        convertBase64ToFile(base64Image);
        setIndexother(indexcropper);

     };

     

     const convertBase64ToFile = (base64Image) => {
        if (base64Image) {
          // Extract the base64 data (remove the data:image/png;base64, prefix)
          const base64Data = base64Image.split(',')[1];
    
          // Convert base64 to a Blob
          const blob = atob(base64Data);
          const arrayBuffer = new ArrayBuffer(blob.length);
          const uint8Array = new Uint8Array(arrayBuffer);
    
          for (let i = 0; i < blob.length; i++) {
            uint8Array[i] = blob.charCodeAt(i);
          }
    
          // Create a File from the Blob
          const file = new File([arrayBuffer], 'image'+indexpass+'.png', { type: 'image/png' });

          setFile(file);

          // Now 'file' is a File object that you can use or upload as needed
          console.log('File object:', file);
        }
    };


     const uploadFiles = () => {

      

      const formData = new FormData();

      localFiles.forEach((file, index) => {

      console.log(file);
        
      formData.append('sampleFile[]', file);

      if(file.name.includes("_")){
        formData.append("pic"+(index+1) ,  localStorage.getItem(PROFILE_ID)+"_"+file.name.split("_")[1]);
      }else{
        formData.append("pic"+(index+1) ,  localStorage.getItem(PROFILE_ID)+"_"+file.name);
      }

      });

      formData.append("userId" , localStorage.getItem(USER_ID));
      formData.append("communityId" , localStorage.getItem(COMMUNITY_ID));
      formData.append("profileId" , localStorage.getItem(PROFILE_ID));

      if(picList.length <= 0){
        
        setUpdate("1");
        insert_photos(formData);
      }else{

        setUpdate("2");
        formData.append("Id" , picList[0].flat()[0].Id);
        update_photos(formData);

      }
       
       passtocropper();

     };



     const dropzoneRef = useRef(null);

     const onDrop = useCallback((acceptedFiles) => {
      
      setLocalFiles(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles[localStorage.getItem("selected")] = acceptedFiles[0];
        return newFiles;
      });

     }, []);
   
     const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: 'image/*',
    });
  
   
     const handleButtonClick = (index) => {
      
      localStorage.setItem("selected"  , index);

     };
   
     
    

    return (

        <Grid container spacing={3} width={"1300px"} style={{justifyContent:"center" , width:"100%"}}>

          {localFiles.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card>
                <CardMedia component="img" height="220" image = { URL.createObjectURL(image)} alt={`Image ${index + 1}`}  style={{objectFit:"contain"}} />
                
                
                <Grid container xs={12}>

                <Grid item xs={6}>
                <Button style={{color:"palevioletred" ,justifyContent:"center" , backgroundColor:"antiquewhite" , textAlign:"center" , fontWeight:"bold",
                fontSize:"20px" , width:"100%" , opacity:"0.9"}}   onClick={()=> handlecrop(index)}>Crop</Button>
                </Grid>

                <Grid item xs={6}>
                <Button style={{color:"palevioletred" ,justifyContent:"center" , backgroundColor:"antiquewhite" , textAlign:"center" , fontWeight:"bold",
                fontSize:"20px" , width:"100%" , height:"47px"  , opacity:"0.9"}}    {...getRootProps()}> <input {...getInputProps()} />
                <p onClick={()=> handleButtonClick(index)} >Update</p></Button>

                </Grid>

                 </Grid>
        
              </Card>
            </Grid>
          ))}

            { open == true ? <ImageCropperScreen image={images}  openPass={open} indexcropper={indexpass}  close={onClose} passBase64={convertTofile} /> :"" }
 
        

        </Grid>
      );


};

export default ImageGalleryWithCrop;

const reducerContact = (state, action) => {
    switch (action.type) {
      case 'CONTACT_DATA_FETCH_ADMIN':
        return {
          ...state,
          data_contact_admin: action.payload,
          loading_admin: false,
          error: null,
        };
     
        case 'CITY_FETCH_PERM_ADMIN':
        return {
          ...state,
          data_city_perm_admin: action.payload,
          loading_admin: false,
          error: null,
        };
        case 'CITY_FETCH_WORK_ADMIN':
        return {
          ...state,
          data_city_work_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'CONTACT_DATA_FETCH_ERROR_ADMIN':
        return {
          ...state,
          data_contact_admin: [],
          loading: false,
          error: action.payload,
        };
        case 'CONTACT_DETAILS_SUBMIT_ADMIN':
      return {
        ...state,
        contactInsert_admin: action.payload,
        loading: false,
        error: null,
      };
    case 'CONTACT_DETAILS_SUBMIT_ERROR_ADMIN':
      return {
        ...state,
        contactInsert_admin: [],
        loading: false,
        error: action.payload,
      };

      case 'CONTACT_DETAILS_UPDATE_ADMIN':
      return {
        ...state,
        contactUpdate_admin: action.payload,
        loading: false,
        error: null,
      };
    case 'CONTACT_DETAILS_UPDATE_ERROR_ADMIN':
      return {
        ...state,
        contactUpdate_admin: [],
        loading: false,
        error: action.payload,
      };

   
      default:
        return state;
    }
  };

  export default reducerContact;


import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid , IconButton , Modal} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';






function FamilyStatus(){



  const {data_insert_familystatus , list_familystatus_admin , data_update_familystatus , familyStatus_fetch , familyStatus_insert , familyStatus_update , delete_master_data} = useMasterContextAdmin();

  const [familystatus, setFamilystatus] = useState('');
  const [familystatus_update, setFamilystatusUpdate] = useState('');
  const [familystatus_id, setFamilystatusId] = useState('');

  const [open , setopen] = useState(false);
  const [first , setFirst] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(()=>{

   if(first == false){
    familyStatus_fetch();

     setFirst(true);
   }
   

  },[list_familystatus_admin , data_insert_familystatus , data_update_familystatus]);


  const handleFamilystatusChange = (event) => {
    setFamilystatus(event.target.value);
};


const handleFamilystatusUpdateChange = (event) => {
  setFamilystatusUpdate(event.target.value);
};


const handleAddFamilyStatus = () => {
  if (familystatus.trim() !== '') {

   setFirst(false);
   familyStatus_insert({'label':familystatus})
   
  }
};


const handleUpdateFamilyStatus = () => {

  if (familystatus_update.trim() !== '') {

    setFirst(false);
   familyStatus_update({'label':familystatus_update , 'Id' :familystatus_id});
    setopen(false);

  }

};





const handleEditClick = (religion , Id) => {


 setFirst(false);
 
  setFamilystatusUpdate(religion);
  setFamilystatusId(Id);

  setopen(true);
  
}


const handleClose = () => {
   setopen(false);
};


const handleDeleteClick = (is_enabled , Id) => {
  delete_master_data({ "type":"fml_status"  , "is_enabled":"1" , "Id":Id});

  setTimeout(() => {

    familyStatus_fetch();
  
  }, 500);
}


let role = localStorage.getItem(ROLE);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Family Status</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_familystatus_admin.length > 0 &&  list_familystatus_admin.map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.label}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.label , religion.Id)}>
        <EditIcon />
      </IconButton></TableCell>
      <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter Family Status"
        variant="outlined"
        value={familystatus}
        fullWidth
        onChange={handleFamilystatusChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddFamilyStatus}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>






          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Family Status"
            variant="outlined"
            value={familystatus_update}
            fullWidth
            onChange={handleFamilystatusUpdateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateFamilyStatus}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>


        </div>
      );


}
export default FamilyStatus;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerAdvancedSearch from '../reducer/advancedSearchReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';
import { insert } from 'formik';

const advanced_searchContext = createContext();

const initialState = {
  data: [],
  data_saved_search: [],
  loading: true,
  error: null,
};

let API = `${BASE_URL}`;


export const AdvancedSearchProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerAdvancedSearch , initialState);

const fetchadvancedSearchItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/advanced_searchdata_fetch` , params);
      
      dispatch({ type: 'ADVANCED_SEARCH_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ADVANCED_SEARCH_EDIT_ERROR', payload: error });
    }
  };


  const insertSavedSearch = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/save_search_insert` , params);

      dispatch({ type: 'SAVED_SEARCH_INSERT' , payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SAVED_SEARCH_INSERT_ERROR' , payload: error });
    }
  };
  

  useEffect(() => {
       
    fetchadvancedSearchItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <advanced_searchContext.Provider value={{ ...state , dispatch , fetchadvancedSearchItems , insertSavedSearch}}>{children}</advanced_searchContext.Provider>;
};

export const useAdvancedSearchContext = () => useContext(advanced_searchContext);
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerNotification from '../reducer/notificationReducer';
import { BASE_URL, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const notificationContext = createContext();

const initialState = {
  notification_list: [],
  loading: true,
  error: null,
  notification_accept_reject:[],
  accept_reject_list:[],
  insert_notification_count_list:[],
  allowed_features_list:[],
  insert_allowed_list:[],
  update_allowed_list:[],

  allowed_features_list_horo:[],
  insert_allowed_list_horo:[],
  update_allowed_list_horo:[],

  insert_notificatioin_data_notification:[],
  insert_notificatioin_data_notification_horo:[],

  user_validation_data_list:[],
  insert_horoscope_view_list:[],
  update_payment_expire_list:[],
  data_select_webtheme:[],
};

let API = `${BASE_URL}`;


export const NotificationProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerNotification , initialState);

const notificationList = async (article) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/fetch_notification` , article);
      

      dispatch({ type: 'NOTIFICATION_LIST', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'NOTIFICATION_LIST_ERROR', payload: error });
    }
  };


  const accept_reject_notification = async (article) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/fetch_notification_interest` , article);
      

      dispatch({ type: 'ACC_REJ_NOTIFICATION_LIST', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ACC_REJ_NOTIFICATION_LIST_ERROR', payload: error });
    }
  };



  const accept_reject_request = async (article) => {
    try {
      const response = await axios.post(`${API}`+`activity/interest_accept_reject` , article);
      

      dispatch({ type: 'ACCEPT_REJECT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ACCEPT_REJECT_ERROR', payload: error });
    }
  };

  


  const insert_notification_count = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification_count` , article);
      
      dispatch({ type: 'INSERT_NOTIFICATION_COUNT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_COUNT_ERROR', payload: error });
    }
  };



  const insert_allowed_from_user = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_allowed_from_user` , article);
      
      dispatch({ type: 'INSERT_ALLOWED', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_ALLOWED_ERROR', payload: error });
    }
  };

  const update_allowed_from_user = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_allowed_from_user` , article);
      
      dispatch({ type: 'UPDATE_ALLOWED', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_ALLOWED_ERROR', payload: error });
    }
  };

  const allowed_list = async (article) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/fetch_allowed_from_user` , article);
      
      dispatch({ type: 'ALLOWED_LIST', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ALLOWED_LIST_ERROR', payload: error });
    }
  };













  const insert_allowed_from_user_horo = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_allowed_from_user` , article);
      
      dispatch({ type: 'INSERT_ALLOWED_HORO', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_ALLOWED_ERROR_HORO', payload: error });
    }
  };

  const update_allowed_from_user_horo = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_allowed_from_user` , article);
      
      dispatch({ type: 'UPDATE_ALLOWED_HORO', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_ALLOWED_ERROR_HORO', payload: error });
    }
  };

  const allowed_list_horo = async (article) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/fetch_allowed_from_user` , article);
      
      dispatch({ type: 'ALLOWED_LIST_HORO', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ALLOWED_LIST_ERROR_HORO', payload: error });
    }
  };


  const insertNotification_memberdetails_notification = async (params) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification` , params);

      dispatch({ type: 'INSERT_NOTIFICATION_MEMBER_NOTIFICATION', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_MEMBER_NOTIFICATRION_ERROR', payload: error });
    }
  };



  const insertNotification_memberdetails_notification_horo = async (params) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification` , params);

      dispatch({ type: 'INSERT_NOTIFICATION_MEMBER_NOTIFICATION_HORO', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_MEMBER_NOTIFICATRION_ERROR_HORO', payload: error });
    }
  };


  const user_validation_data = async (article) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/premium_user_data` , article);
      
      dispatch({ type: 'PREMIUM_USER_DATA', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PREMIUM_USER_DATA_ERROR', payload: error });
    }
  };


  const insert_horoscope_view = async (article) => {
    try {
      const response = await axios.post(`${API}`+`activity/view_other_horoscope` , article);
      
      dispatch({ type: 'VIEW_OTHER_HOROSCOPE', payload: response.data });
    } catch (error) {
      dispatch({ type: 'VIEW_OTHER_HOROSCOPE_ERROR', payload: error });
    }
  };

 

  const update_payment_expire = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_payment_isexpired` , article);
      
      dispatch({ type: 'UPDATE_PAYMENT_EXPIRE', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_PAYMENT_EXPIRE_ERROR', payload: error });
    }
  };


  const select_web_theme = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/select_web_theme` , article);
      
      dispatch({ type: 'SELECT_WEB_THEME', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_WEB_THEME_ERROR', payload: error });
    }
  };


  const reset = async () => {
    
      dispatch({ type: 'RESET', payload: [] });
   
  };

  useEffect(()=>{

    user_validation_data({
      "userId": localStorage.getItem(USER_ID) 
    });

  },[]);

  

  
  
  return <notificationContext.Provider value={{ ...state , dispatch , notificationList , accept_reject_notification , accept_reject_request , insert_notification_count , reset ,allowed_list , insert_allowed_from_user , update_allowed_from_user ,  insertNotification_memberdetails_notification , insertNotification_memberdetails_notification_horo , insert_allowed_from_user_horo , update_allowed_from_user_horo , allowed_list_horo , user_validation_data , insert_horoscope_view , update_payment_expire , select_web_theme }}>{children}</notificationContext.Provider>;
};

export const useNotificationContext = () => useContext(notificationContext);
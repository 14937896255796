import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerPartnerprefs from '../../reducer_admin/partnerprefsReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const partnerprefsAdminContext = createContext();

const initialState = {
  data_partner_prefs_admin: [],
  loading: true,
  error: null,
  data_city_multiple_admin:[],
  partner_insert_admin:[],
  partner_update_admin:[]
};

let API = `${BASE_URL}`;


export const PartnerPrefsAdminProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerPartnerprefs , initialState);

const fetchPartnerPrefsItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/partner_prefs_details_edit` , params);

      dispatch({ type: 'PARTNER_PREFS_DATA_FETCH_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PARTNER_PREFS_DATA_FETCH_ERROR_ADMIN', payload: error });
    }
  };



  const fetch_city_multipleAdmin = async (passdata) => {
    try {
     
        const response = await axios.post(`${API}`+`fetch_data/city_fetch_multiple` , passdata);
      dispatch({ type: 'CITY_FETCH_MULTIPLE_ADMIN', payload: response.data.data });

      
    } catch (error) {
      dispatch({ type: 'CITY_FETCH_MULTIPLE_ERROR_ADMIN', payload: error });
    }
  };


  const submit_partner_prefs_detailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_partner` , article);
      dispatch({ type: 'PARTNER_DETAILS_SUBMIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PARTNER_DETAILS_SUBMIT_ERROR_ADMIN', payload: error });
    }
  };


  const update_partner_prefs_detailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_partner` , article);
      dispatch({ type: 'PARTNER_DETAILS_UPDATE_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'PARTNER_DETAILS_UPDATE_ERROR_ADMIN', payload: error });
    }
  };





 


 
  
  return <partnerprefsAdminContext.Provider value={{ ...state , dispatch , fetchPartnerPrefsItemsAdmin , fetch_city_multipleAdmin , submit_partner_prefs_detailsAdmin ,update_partner_prefs_detailsAdmin}}>{children}</partnerprefsAdminContext.Provider>;
};

export const usePartnerPrefsAdminContext = () => useContext(partnerprefsAdminContext);
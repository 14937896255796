import { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle , Pagination , LinearProgress} from "@mui/material";
import { useState } from "react";
import styles from "../../styles_admin/Community.module.css";
import { useEmailMarketingContext } from "../../contexts_admin/email_marketing_context";
import { SENDER_EMAIL, SENDER_NAME } from "../../../common_utils/constants";
import React from "react";




function UserDialog({isopen , setClose , setClickedLink , communityId ,  subject , template , type ,typeId}){

    const { user_email , data_user_email_admin , send_email ,statusCode , insert_emailsent , data_insert_emailsent} = useEmailMarketingContext();
    const [currentPage, setCurrentPage] = useState(1);

    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }

  useEffect(()=>{
    
    if(statusCode == 201 || statusCode == 204){

        setProgress(false);

        const currentDate = new Date();

  // Extract hour, minute, and date
  const hour = currentDate.getHours();
  const minute = currentDate.getMinutes();
  const date = currentDate.toDateString(); 

        insert_emailsent({"email_type":type, "email_type_Id":typeId ,"date_sent":date+", "+hour+":"+minute});
        alert("Email send out for delivery");


    }


  },[statusCode]);


  const handleCommunityClick = (Item) => {
     
    setClose(false);
    setClickedLink(Item);

    
  }

  const handleSendEmail = () => {

   let tot_page = Math.ceil(data_user_email_admin[1][0]["total_row_count"] / 1000);


   setProgress(true);
 

   const emailarray = data_user_email_admin[0].map(user => ({
    "email": user.emailid,
    "name": user.name+" "+user.surname
  }));
      
  
    send_email({
 "sender_name": SENDER_NAME,
 "sender_email": SENDER_EMAIL,
 "subject": subject,
 "htmlContent": template,
 "tolist": emailarray

});


  }

    useEffect(()=>{
        

        setOpen(isopen);
        user_email({
            "communityId":communityId,
            "offset":"0"
         });

        

    },[]);


    const handlePageChange = (event, value) => {
    
        setCurrentPage(value);

        user_email({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 1000) + 1000)
         });
            

    }

    





    return (<>
{ progress == true ? <LinearProgress /> : ""}
<Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle>Select Community</DialogTitle>
      <DialogContent>

      
        <table className={styles.nicetable} width={"100%"}> 
          <thead>
            <tr>
              <th style={{width:"10%"}}>UserName</th>
              <th style={{width:"10%"}}>EmailId</th>
              <th style={{width:"10%"}}>Gender</th>
            </tr>
          </thead>
          <tbody>
            {data_user_email_admin.length > 0 && data_user_email_admin[0].map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? styles.pinkrow : styles.greyrow}>
                <td style={{width:"10%"}}>{item.name+" "+item.surname}</td>
                <td style={{width:"10%"}}>{item.emailid}</td>
                <td style={{width:"30%"}}>{item.gender}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={data_user_email_admin.length > 0 && Math.ceil(data_user_email_admin[1][0]["total_row_count"] / 1000)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="medium"
                />
            </div>
        

            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      <DialogActions>
        <Button onClick={handleSendEmail}>Send Email</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default UserDialog;
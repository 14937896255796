import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerEducation from '../../reducer_admin/educationalReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const educationAdminContext = createContext();

const initialState = {
  data_education_admin: [],
  loading: true,
  error: null,
  education_insert_admin: [],
  education_update_admin:[]
};

let API = `${BASE_URL}`;


export const EducationProviderAdmin = ({ children }) => {

const [state, dispatch] = useReducer(reducerEducation , initialState);

const fetchEducationItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/education_details_edit` , params);

      console.log(response.data.data[1][0].Id);
      

      dispatch({ type: 'EDUCATION_DETAILS_EDIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'EDUCATION_DETAILS_EDIT_ERROR_ADMIN', payload: error });
    }
  };


  const submit_educationaldetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_education` , article);
      dispatch({ type: 'INSERT_EDUCATION_DETAILS_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_EDUCATION_DETAILS_ERROR_ADMIN', payload: error });
    }
  };


  const update_educationaletailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_education` , article);
      dispatch({ type: 'UPDATE_EDUCATION_DETAILS_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_EDUCATION_DETAILS_ERROR_ADMIN', payload: error });
    }
  };



  


 
  
  return <educationAdminContext.Provider value={{ ...state , dispatch , fetchEducationItemsAdmin , submit_educationaldetailsAdmin , update_educationaletailsAdmin}}>{children}</educationAdminContext.Provider>;
};

export const useEducationAdminContext = () => useContext(educationAdminContext);
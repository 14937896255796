
import { useState } from 'react';
import validator from 'validator';
import Validator from 'validatorjs';


function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isPassword(password){
    return validator.isStrongPassword(password,{minLength:8 ,minUppercase:1});
  }


function validationlistRegister(firstName , lastName , gender , date , month ,year , email , mobile , mobiledigit , password){

  let validationlist = [];

  if(firstName.trim() === '' || lastName.trim() === '' || gender.trim() === '' || date.trim() === '' || month.trim() === ''
  || year === '' || email.trim() === '' || mobile.trim() === ''|| password.trim() === ''
  ){

    validationlist.push("<b style={'color':'red'}>All Fields are compulsory</b><br/>");

  }

  if(date === '0'){
    validationlist.push("<b>Please select valid date</b><br/>");
  }

  
  if(month === '0'){
    validationlist.push("<b>Please select valid month</b><br/>");
  }

  
  if(year === '0'){
    validationlist.push("<b>Please select valid year</b><br/>");
  }

  if(!isValidEmail(email)){
    validationlist.push("<b>Email must be valid</b><br/>");
  }


  if(parseInt(mobiledigit) != 10){
    validationlist.push("<b>Mobile number should be of 10 digit</b><br/>");
  }

  if(password.length < 4){
    validationlist.push("<b>Password should be minimum of 4 digit</b>");
  }

  return validationlist;
}



function login_validation(login_email_mobile , password){

   let login_validation = [];

   if(login_email_mobile.trim() === '' || password.trim() === ''){

    login_validation.push("<b style={'color':'red'}>All Fields are compulsory</b><br/>");

   }else if(password.trim().length < 4){


    login_validation.push("<b style={'color':'red'}>Password Should be minimum of 4 characters</b><br/>");

   }


   const isDigit = /^\d+$/.test(login_email_mobile);

   if(isDigit){

    if(login_email_mobile.length == 0){
      login_validation.push("<b>Mobile number should be of 10 digit</b>");
    }

   }else{

    if(!isValidEmail(login_email_mobile)){
      login_validation.push("<b>EMAILID ID must be valid</b><br/>");
    }

   }

   
     return login_validation;
}






function validationlistBasicDetails(name , surname , created_by , date , month ,year , age , marital_status , caste , subcaste,
  lang_known , mother_tongue){

  let validationlistBasic = [];

  const namecompare = String(name).length === 0;
  const surnamecompare = String(surname).length === 0;

//  alert(namecompare+"---"+surnamecompare+"---"+created_by+"----"+marital_status+"---"+month+"---"+year+"--"+date+"---");


console.log('name:', namecompare);
  console.log('surname:', surnamecompare);
  console.log('created_by:', created_by);
  console.log('date:', date);
  console.log('month:', month);
  console.log('year:', year);
  console.log('age:', age);
  console.log('marital_status:', marital_status);
  console.log('caste:', caste);
  console.log('subcaste:', subcaste);
  console.log('lang_known:', lang_known);
  console.log('mother_tongue:', mother_tongue);

  if( namecompare || surnamecompare || created_by === '' || marital_status.trim() === '' || month === ''
  || year === '' || date === '' || age.trim() === '' || caste === '' || subcaste === '' || lang_known === '',
  mother_tongue === ''
  ){

    alert("11111111111");
    validationlistBasic.push("<b style={'color':'red'}>All Fields are compulsory</b><br/>");

  }


  //alert(namecompare);
 
  
  

  return validationlistBasic;
}



// validation.js


const validateData = (data, customMessages = {}) => {
  const rules = {};
  const validationErrors = {};

  // Build dynamic validation rules
  Object.keys(data).forEach((field) => {
    rules[field] = 'required';
  });

  // Validate the form data
  const validation = new Validator(data, rules, customMessages);

  if (validation.fails()) {
    // If validation fails, store the errors
    Object.keys(data).forEach((field) => {
      if (validation.errors.has(field)) {
        validationErrors[field] = validation.errors.first(field);
      }
    });
  }

  return validationErrors;
};

export default validateData;


  


  export{isValidEmail , isPassword , validationlistRegister , login_validation , validationlistBasicDetails};
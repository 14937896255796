const reducerProofs = (state, action) => {
    switch (action.type) {


      case 'PROOFS_FETCH_ADMIN':
        return {
          ...state,
          data_proofs_list_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'PROOFS_FETCH_ERROR_ADMIN':
        return {
          ...state,
          data_proofs_list_admin: [],
          loading: false,
          error: action.payload,
        };


        case 'INSERT_PROOFS_ADMIN':
      return {
        ...state,
        insert_proofs_list_admin: action.payload,
        loading: false,
        error: null,
      };
    case 'INSERT_PROOFS_ERROR_ADMIN':
      return {
        ...state,
        insert_proofs_list_admin: [],
        loading: false,
        error: action.payload,
      };



      case 'UPDATE_PROOFS_ADMIN':
        return {
          ...state,
          update_proofs_list_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_PROOFS_ERROR_ADMIN':
        return {
          ...state,
          update_proofs_list_admin: [],
          loading: false,
          error: action.payload,
        };



        case 'UPDATE_IDPROOFS_ADMIN':
          return {
            ...state,
            data_idproofs: action.payload,
            loading: false,
            error: null,
          };
        case 'UPDATE_IDPROOFS_ERROR_ADMIN':
          return {
            ...state,
            data_idproofs: [],
            loading: false,
            error: action.payload,
          };
  
  
          case 'UPDATE_EDUCATIONPROOFS_ADMIN':
          return {
            ...state,
            data_educationproofs: action.payload,
            loading: false,
            error: null,
          };
        case 'UPDATE_EDUCATIONPROOFS_ERROR_ADMIN':
          return {
            ...state,
            data_educationproofs: [],
            loading: false,
            error: action.payload,
          };


          case 'UPDATE_SALARYPROOFS_ADMIN':
            return {
              ...state,
              data_salaryproofs: action.payload,
              loading: false,
              error: null,
            };
          case 'UPDATE_SALARYPROOFS_ERROR_ADMIN':
            return {
              ...state,
              data_salaryproofs: [],
              loading: false,
              error: action.payload,
            };
        
      
       
      default:
        return state;
    }
  };

  export default reducerProofs;
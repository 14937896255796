const reducerHoroscope = (state, action) => {
    switch (action.type) {
      case 'HOROSCOPE_DETAILS_EDIT':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'HOROSCOPE_DETAILS_EDIT_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };
        case 'HOROSCOPE_CHART_VIEW':
        return {
          ...state,
          data_horoscope: action.payload,
          loading: false,
          error: null,
        };
      case 'HOROSCOPE_CHART_VIEW_ERROR':
        return {
          ...state,
          data_horoscope: [],
          loading: false,
          error: action.payload,
        };
        case 'NAVMSA_CHART_VIEW':
          return {
            ...state,
            data_navmsa: action.payload,
            loading: false,
            error: null,
          };
        case 'NAVMSA_CHART_VIEW_ERROR':
          return {
            ...state,
            data_horoscope: [],
            loading: false,
            error: action.payload,
          };

          case 'INSERT_HOROSCOPE_DETAILS':
        return {
          ...state,
          horoscope_insert: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_HOROSCOPE_DETAILS_ERROR':
        return {
          ...state,
          horoscope_insert: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_HOROSCOPE_DETAILS':
        return {
          ...state,
          horoscope_update: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_HOROSCOPE_DETAILS_ERROR':
        return {
          ...state,
          horoscope_update: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerHoroscope;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer/homeReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../common_utils/constants';

const homeContext = createContext();

const initialState = {
  data: [],
  loading_home: true,
  error: null,
  happy_story_list:[]
};

let API = `${BASE_URL}`;


export const HomeProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const fetchHomeItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/home_page_items` , params);
      

      dispatch({ type: 'HOME_ITEMS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HOME_TEMS_ERROR', payload: error });
    }
  };

  

  const fetchHappyStoryFetch = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/happy_story_fetch` , params);
      

      dispatch({ type: 'HAPPY_STORY', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HAPPY_STORY_ERROR', payload: error });
    }
  };



  useEffect(() => {
       
      fetchHomeItems({
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "ishidden":"1",
             "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });

        fetchHappyStoryFetch({
          "communityId":localStorage.getItem(COMMUNITY_ID),
          "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
      });
    
  }, []);


 
  
  return <homeContext.Provider value={{ ...state , dispatch , fetchHomeItems , fetchHappyStoryFetch}}>{children}</homeContext.Provider>;
};

export const useHomeContext = () => useContext(homeContext);

import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid , Pagination , IconButton , Modal} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';






function Language(){

   const {languages_fetch , languages_insert , languages_update , data_insert_language , list_language_admin, data_update_language , delete_master_data} = useMasterContextAdmin();



   const [languages, setLanguages] = useState('');
   const [language_update, setLanguageUpdate] = useState('');
   const [language_id, setLanguageId] = useState('');

   const [open , setopen] = useState(false);
   const [first , setFirst] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [Id, setId] = useState(null);


   useEffect(()=>{

    if(first == false){
      languages_fetch({"offset": currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10)});

      setFirst(true);
    }
    

   },[list_language_admin , data_insert_language , data_update_language]);


   const handleLanguageChange = (event) => {
   setLanguages(event.target.value);
 };


 const handleLanguageUpdateChange = (event) => {
  setLanguageUpdate(event.target.value);
 };


 const handleAddLanguage = () => {
   if (languages.trim() !== '') {

    setFirst(false);
    languages_insert({'label':languages})
    
   }
 };


 const handleUpdateReligion = () => {

   if (language_update.trim() !== '') {

    languages_update({'label':language_update , 'value' :language_id})
     setopen(false);

   }

 };



 const handlePageChange = (event, value) => {
  
  setCurrentPage(value);

  languages_fetch({
      "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
  });

} 

 const handleEditClick = (religion , Id) => {

  setFirst(false);
   setopen(true);
   setLanguageUpdate(religion);
   setLanguageId(Id);
 }


 const openModal = (is_enabled , Id) => {
  setModalIsOpen(true);
  setId(Id);
};

// Function to close the modal
const closeModal = () => {
  setModalIsOpen(false);
};


 const handleDeleteClick = () => {
  delete_master_data({ "type":"lang"  , "is_enabled":"1" , "Id":Id});

  setModalIsOpen(false);

  setTimeout(() => {

    languages_fetch({"offset": currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10)});
  
  }, 500);
  };




 const handleClose = () => {
    setopen(false);
 };

 let role = localStorage.getItem(ROLE);
  

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_language_admin.length > 0 &&  list_language_admin[0].map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.label}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.label , religion.Id)}>
        <EditIcon />
      </IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => openModal("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>



          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_language_admin.length > 0 && Math.ceil(list_language_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter Languages"
        variant="outlined"
        value={languages}
        fullWidth
        onChange={handleLanguageChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddLanguage}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>



          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Religion"
            variant="outlined"
            value={language_update}
            fullWidth
            onChange={handleLanguageUpdateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateReligion}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>


    <Modal open={modalIsOpen} onClose={closeModal} label="Confirm Delete">
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
        <h2>Confirm Delete</h2>
        </Grid>
        <Grid item xs={12}>
        <p>Are you sure you want to delete ?</p>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleDeleteClick}>
            Delete
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={closeModal}>
            Close
          </Button>
        </Grid>

      </Grid>
    </Modal>



        </div>
      );


}

export default Language;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerHoroscope from '../reducer/horoscopeReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const horoscopeContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  data_horoscope: [],
  data_navmsa: [],
  horoscope_insert:[],
  horoscope_update:[]
};

let API = `${BASE_URL}`;


export const HoroscopeProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerHoroscope , initialState);

const fetchHoroscopeItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/horoscope_details_edit` , params);

      console.log(response.data.data[1][0].Id);
      

      dispatch({ type: 'HOROSCOPE_DETAILS_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HOROSCOPE_DETAILS_EDIT_ERROR', payload: error });
    }
  };



  const fetchHoroscopeChart = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/horoscope_chart` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'HOROSCOPE_CHART_VIEW', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HOROSCOPE_CHART_VIEW_ERROR', payload: error });
    }
  };



  const fetchNavmsaChart = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/navmsa_chart` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'NAVMSA_CHART_VIEW', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'NAVMSA_CHART_VIEW_ERROR', payload: error });
    }
  };

  
  const submit_horoscopedetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_horoscope` , article);
      dispatch({ type: 'INSERT_HOROSCOPE_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_HOROSCOPE_DETAILS_ERROR', payload: error });
    }
  };


  const update_horoscopedetails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_horoscope` , article);
      dispatch({ type: 'UPDATE_HOROSCOPE_DETAILS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_HOROSCOPE_DETAILS_ERROR', payload: error });
    }
  };


  


  useEffect(() => {
       
    fetchHoroscopeItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": ["en" , localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <horoscopeContext.Provider value={{ ...state , dispatch , fetchHoroscopeItems , fetchHoroscopeChart , fetchNavmsaChart , submit_horoscopedetails ,update_horoscopedetails}}>{children}</horoscopeContext.Provider>;
};

export const useHoroscopeContext = () => useContext(horoscopeContext);
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/webthemeReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';
import { data } from 'browserslist';

const  webthemeContext = createContext();

const initialState = {
  loading_webtheme: true,
  error: null,
  data_insert_webtheme: [],
  data_insert_visibility:[],
  data_select_webtheme:[],
  data_select_visibility:[],
  data_homepage_slider:[],
  data_homepage_slider_insert:[],
  data_homepage_slider_update:[],
  data_insert_androidtheme:[],
  data_update_androidtheme:[],
  data_select_androidtheme:[]
};

let API = `${BASE_URL}`;

export const WebThemeProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const insert_update_visibility = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/insert_update_visibility` , article);
      
      dispatch({ type: 'INSERT_UPDATE_VISIBILITY', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'INSERT_UPDATE_VISIBILITY_ERROR', payload: error });
    }
  };



  const insert_update_webtheme = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/insert_update_theme` , article);
      
      dispatch({ type: 'INSERT_UPDATE_THEME', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_UPDATE_THEME_ERROR', payload: error });
    }
  };



  const select_web_theme = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/select_web_theme` , article);
      
      dispatch({ type: 'SELECT_WEB_THEME', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_WEB_THEME_ERROR', payload: error });
    }
  };



  const select_visibility = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/select_visibility` , article);
      
      dispatch({ type: 'SELECT_WEB_VISIBILITY', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_WEB_VISIBILITY_ERROR', payload: error });
    }
  };


  const  homepage_slider_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/homepage_slider_fetch` , article);
      
      dispatch({ type: 'SELECT_HOMEPAGE_SLIDER', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_HOMEPAGE_SLIDER_ERROR', payload: error });
    }
  };



  const  homepage_slider_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/homepage_slider_insert` , article);
      
      dispatch({ type: 'SELECT_HOMEPAGE_SLIDER_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_HOMEPAGE_SLIDER_INSERT_ERROR', payload: error });
    }
  };




  const  homepage_slider_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/homepage_slider_update` , article);
      
      dispatch({ type: 'SELECT_HOMEPAGE_SLIDER_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SELECT_HOMEPAGE_SLIDER_UPDATE_ERROR', payload: error });
    }
  };


 


  const  androidapp_theme_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/androidapp_theme_insert` , article);
      
      dispatch({ type: 'ANDROID_APP_THEME_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ANDROID_APP_THEME_INSERT_ERROR', payload: error });
    }
  };
 


  const  androidapp_theme_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/androidapp_theme_update` , article);
      
      dispatch({ type: 'ANDROID_APP_THEME_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ANDROID_APP_THEME_UPDATE_ERROR', payload: error });
    }
  };
 


  const   androidapp_theme_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/androidapp_theme_fetch` , article);
      
      dispatch({ type: 'ANDROID_APP_THEME_FETCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ANDROID_APP_THEME_FETCH_ERROR', payload: error });
    }
  };

 
  return <webthemeContext.Provider value={{ ...state , dispatch , insert_update_visibility , insert_update_webtheme , select_web_theme , select_visibility ,homepage_slider_fetch , homepage_slider_insert , homepage_slider_update , androidapp_theme_insert ,androidapp_theme_update ,androidapp_theme_fetch}}>{children}</webthemeContext.Provider>;
};

export const useWebThemeContextAdmin = () => useContext(webthemeContext);

const reducer = (state, action) => {
    switch (action.type) {

      case 'FREEMEMBERS_SUCCESS':
        return {
          ...state,
          data_free_members: action.payload,
          loading_freemmebers: false,
          error: null,
        };

      case 'FREEMEMBERS_ERROR':
        return {
          ...state,
          data_free_members: [],
          loading_freemmebers: false,
          error: action.payload,
        };



        case 'PAID_MEMBER_SUCCESS':
        return {
          ...state,
          data_paid_members: action.payload,
          loading_freemmebers: false,
          error: null,
        };

      case 'PAID_MEMBER_ERROR':
        return {
          ...state,
          data_paid_members: [],
          loading_freemmebers: false,
          error: action.payload,
        };


        case 'DELTED_MEMBER_SUCCESS':
          return {
            ...state,
            data_deleted_members: action.payload,
            loading_freemmebers: false,
            error: null,
          };
  
        case 'DELETED_MEMBER_ERROR':
          return {
            ...state,
            data_deleted_members: [],
            loading_freemmebers: false,
            error: action.payload,
          };



          case 'BLOCKED_MEMBER_SUCCESS':
          return {
            ...state,
            data_blocked_members: action.payload,
            loading_freemmebers: false,
            error: null,
          };
  
        case 'BLOCKED_MEMBER_ERROR':
          return {
            ...state,
            data_blocked_members: [],
            loading_freemmebers: false,
            error: action.payload,
          };


          case 'UNAPPROVED_MEMBER_SUCCESS':
            return {
              ...state,
              data_unapproved_members: action.payload,
              loading_freemmebers: false,
              error: null,
            };
    
          case 'UNAPPROVED_MEMBER_ERROR':
            return {
              ...state,
              data_unapproved_members: [],
              loading_freemmebers: false,
              error: action.payload,
            };




            case 'PICTURES_SELECT_SUCCESS':


            const images = action.payload[0].map(item => {
              const picUrlsWithSequence = [];
 
              // Extracting pic URLs and adding them to the picUrls array
              for (let i = 1; i <= 8; i++) {
                const picField = `pic${i}`;
                const isverifypicField = `isverifypic${i}`; 
                if (item[picField] && (item[isverifypicField] == "0" || item[isverifypicField] == "2")) {
                  picUrlsWithSequence.push({
                    url: item[picField],
                    sequence: i,
                    userId: item["userId"]
                  });
                }
              }
              return picUrlsWithSequence;
            });
          



              return{
                ...state,
                list_images_unapproved: images,
                total_row_count_unapproved:action.payload[1][0].total_row_count,
                loading_freemmebers: false,
              error: action.payload,
            };
    
          case 'PICTURES_SELECT_ERROR':
            return {
              ...state,
              list_images_unapproved: [],
              loading_freemmebers: false,
              error: action.payload,
            };




        case 'VIEW_MEMBER_DETAILS_OWN_ADMIN':
          return {
            ...state,
            list_member_details_own_admin: action.payload,
            loading_own_details_admin: false,
            error_own_details: null,
          };
        case 'VIEW_MEMBER_DETAILS_OWN_ADMIN_ERROR':
          return {
            ...state,
            list_member_details_own_admin: [],
            loading_own_details_admin: false,
            error_own_details: action.payload,
          };
  

          case 'CLEAR_LIST_ADMIN_DATA':
            return {
              ...state,
              list_member_details_own_admin: [],
              loading_own_details_admin: false,
              error_own_details: null,
            };


            case 'CLEAR_LIST_IMAGES':
            return {
              ...state,
              list_images_unapproved: [],
              loading_own_details_admin: false,
              error_own_details: null,
            };


            case 'VERIFYPIC1':
              return {
                ...state,
                verifypic1_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'VERIFYPIC2':
              return {
                ...state,
                verifypic2_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'VERIFYPIC3':
              return {
                ...state,
                verifypic3_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC4':
              return {
                ...state,
                verifypic4_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC5':
              return {
                ...state,
                verifypic5_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC6':
              return {
                ...state,
                verifypic6_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC7':
              return {
                ...state,
                verifypic7_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'VERIFYPIC8':
              return {
                ...state,
                verifypic8_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };
  


              case 'VERIFY_USER_PROFILE':
              return {
                ...state,
                verify_user_profile_list: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'INSERT_FEATURED_PROFILE':
                return {
                  ...state,
                  insert_featured_profile_list: action.payload,
                  loading_own_details_admin: false,
                  error_own_details: null,
                };


                case 'DELETE_FEATURED_PROFILE':
                  return {
                    ...state,
                    delete_featured_profile_list: action.payload,
                    loading_own_details_admin: false,
                    error_own_details: null,
                  };


                  case 'CLEAR_FEATURED_PROFILE':
                    return {
                      ...state,
                      verify_user_profile_list: [],
                      insert_featured_profile_list:[],
                      delete_featured_profile_list:[],
                      loading_own_details_admin: false,
                      error_own_details: null,
                    };
  



                    case 'MASTER_FETCH_ALL':
                      return {
                        ...state,
                        list_master_data: action.payload,
                        loading_own_details_admin: false,
                        error_own_details: null,
                      };
    
    
                      case 'MASTER_FETCH_ALL_ERROR':
                        return {
                          ...state,
                          list_master_data: [],
                          loading_own_details_admin: false,
                          error_own_details: null,
                        };


                        case 'MASTER_FETCH_ALL_CLEAR':
                      return {
                        ...state,
                        list_master_data: [],
                        loading_own_details_admin: false,
                        error_own_details: null,
                      };
    


                        // insert multi details

                        case 'INSERT_MULTI_BASICDETAIL':

                        console.log(action.payload);
                          return {
                            ...state,
                            data_multibasic: action.payload.affectedRows,
                            loading_own_details_admin: false,
                            error_own_details: null,
                          };
        
        
                          case 'INSERT_MULTI_BASICDETAIL_ERROR':

                          console.log("basic_detail_error");
                            return {
                              ...state,
                              data_multibasic: [],
                              loading_own_details_admin: false,
                              error_own_details: null,
                            };


                            case 'INSERT_MULTI_CONTACTDETAIL':


                            console.log(action.payload);
                          return {
                            ...state,
                            data_multicontact: action.payload.affectedRows,
                            loading_own_details_admin: false,
                            error_own_details: null,
                          };
        
        
                          case 'INSERT_MULTI_CONTACTDETAIL_ERROR':


                          console.log("contact_detail_error");
                            return {
                              ...state,
                              data_multicontact: [],
                              loading_own_details_admin: false,
                              error_own_details: null,
                            };



                            case 'INSERT_MULTI_EDUDETAIL':

                            console.log("EDUCATION : "+action.payload);

                              return {
                                ...state,
                                data_multieducation: action.payload.affectedRows,
                                loading_own_details_admin: false,
                                error_own_details: null,
                              };
            
            
                              case 'INSERT_MULTI_EDUDETAIL_ERROR':

                              console.log("edu_detail_error");
                                return {
                                  ...state,
                                  data_multieducation: [],
                                  loading_own_details_admin: false,
                                  error_own_details: null,
                                };


                                case 'INSERT_MULTI_FAMILYDETAIL':
                              return {
                                ...state,
                                data_multifamily: action.payload.affectedRows,
                                loading_own_details_admin: false,
                                error_own_details: null,
                              };
            
            
                              case 'INSERT_MULTI_FAMILYDETAIL_ERROR':
                                return {
                                  ...state,
                                  data_multifamily: [],
                                  loading_own_details_admin: false,
                                  error_own_details: null,
                                };




                                case 'INSERT_MULTI_LIFESTYLEDETAIL':
                              return {
                                ...state,
                                data_multilifestyle: action.payload.affectedRows,
                                loading_own_details_admin: false,
                                error_own_details: null,
                              };
            
            
                              case 'INSERT_MULTI_LIFESTYLEDETAIL_ERROR':
                                return {
                                  ...state,
                                  data_multilifestyle: [],
                                  loading_own_details_admin: false,
                                  error_own_details: null,
                                };



                                case 'INSERT_MULTI_OCCUPATIONDETAIL':
                                  return {
                                    ...state,
                                    data_multioccupation: action.payload.affectedRows,
                                    loading_own_details_admin: false,
                                    error_own_details: null,
                                  };
                
                
                                  case 'INSERT_MULTI_OCCUPATIONDETAIL_ERROR':
                                    return {
                                      ...state,
                                      data_multioccupation: [],
                                      loading_own_details_admin: false,
                                      error_own_details: null,
                                    };




                                    case 'INSERT_MULTI_HOROSCOPEDETAIL':
                                      return {
                                        ...state,
                                        data_multihoroscope: action.payload.affectedRows,
                                        loading_own_details_admin: false,
                                        error_own_details: null,
                                      };
                    
                    
                                      case 'INSERT_MULTI_HOROSCOPEDETAIL_ERROR':
                                        return {
                                          ...state,
                                          data_multihoroscope: [],
                                          loading_own_details_admin: false,
                                          error_own_details: null,
                                        };
    

                                        case 'INSERT_MULTI_PHOTO':
                                      return {
                                        ...state,
                                        data_multiphoto: action.payload.affectedRows,
                                        loading_own_details_admin: false,
                                        error_own_details: null,
                                      };
                    
                    
                                      case 'INSERT_MULTI_PHOTO_ERROR':
                                        return {
                                          ...state,
                                          data_multiphoto: [],
                                          loading_own_details_admin: false,
                                          error_own_details: null,
                                        };




                                        case 'INSERT_MULTI_PARTNER_PREFERENCE':
                                          return {
                                            ...state,
                                            data_multipartnerprefs: action.payload.affectedRows,
                                            loading_own_details_admin: false,
                                            error_own_details: null,
                                          };
                        
                        
                                          case 'INSERT_MULTI_PARTNER_PREFERENCE_ERROR':
                                            return {
                                              ...state,
                                              data_multipartnerprefs: [],
                                              loading_own_details_admin: false,
                                              error_own_details: null,
                                            };




                                            
                                        case 'INSERT_MULTI_NOTIFICATION':
                                          return {
                                            ...state,
                                            data_multinotification: action.payload.affectedRows,
                                            loading_own_details_admin: false,
                                            error_own_details: null,
                                          };
                        
                        
                                          case 'INSERT_MULTI_NOTIFICATION_ERROR':
                                            return {
                                              ...state,
                                              data_multinotification: [],
                                              loading_own_details_admin: false,
                                              error_own_details: null,
                                            };



                                            case 'INSERT_MULTI_NOTIFICATIONCOUNT':
                                          return {
                                            ...state,
                                            data_multinotification_count: action.payload.affectedRows,
                                            loading_own_details_admin: false,
                                            error_own_details: null,
                                          };
                        
                        
                                          case 'INSERT_MULTI_NOTIFICATIONCOUNT_ERROR':
                                            return {
                                              ...state,
                                              data_multinotification_count: [],
                                              loading_own_details_admin: false,
                                              error_own_details: null,
                                            };



                                            case 'INSERT_MULTI_SETTINGS':
                                          return {
                                            ...state,
                                            data_multisettings: action.payload.affectedRows,
                                            loading_own_details_admin: false,
                                            error_own_details: null,
                                          };
                        
                        
                                          case 'INSERT_MULTI_SETTINGS_ERROR':
                                            return {
                                              ...state,
                                              data_multisettings: [],
                                              loading_own_details_admin: false,
                                              error_own_details: null,
                                            };



                                            case 'INSERT_MULTI_PROOF':
                                          return {
                                            ...state,
                                            data_multiproof: action.payload.affectedRows,
                                            loading_own_details_admin: false,
                                            error_own_details: null,
                                          };
                        
                        
                                          case 'INSERT_MULTI_PROOF_ERROR':
                                            return {
                                              ...state,
                                              data_multiproof: [],
                                              loading_own_details_admin: false,
                                              error_own_details: null,
                                            };


      case 'INSERT_MULTI_ANNUALINCOME':
      return {
        ...state,
        data_annual_income: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_ANNUALINCOME_ERROR':
      return {
        ...state,
        data_annual_income:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_BIRTHSTAR':
      return {
        ...state,
        data_birthstar: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_BIRTHSTAR_ERROR':
      return {
        ...state,
        data_birthstar:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };



      case 'INSERT_MULTI_BLOODGROUP':
      return {
        ...state,
        data_blood_group: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_BLOODGROUP_ERROR':
      return {
        ...state,
        data_blood_group:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_BODYTYPE':
      return {
        ...state,
        data_bodytype: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_BODYTYPE_ERROR':
      return {
        ...state,
        data_bodytype:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_CASTE':
      return {
        ...state,
        data_caste: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_CASTE_ERROR':
      return {
        ...state,
        data_caste:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_CITY':
      return {
        ...state,
        data_city: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_CITY_ERROR':
      return {
        ...state,
        data_city:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };
    

      case 'INSERT_MULTI_CREATEDBY':
        return {
          ...state,
          data_createdby: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };
      case 'INSERT_MULTI_CREATEDBY_ERROR':
        return {
          ...state,
          data_createdby:[],
          loading_own_details_admin: false,
          error_own_details: action.payload,
        };

      case 'INSERT_MULTI_BIGINSTITUTE':
        return {
          ...state,
          data_biginstitute: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };
      case 'INSERT_MULTI_BIGINSTITUTE_ERROR':
        return {
          ...state,
          data_biginstitute:[],
          loading_own_details_admin: false,
          error_own_details: action.payload,
        };


      case 'INSERT_MULTI_DEGREE':
        return {
          ...state,
          data_degree: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };
      case 'INSERT_MULTI_DEGREE_ERROR':
        return {
          ...state,
          data_degree:[],
          loading_own_details_admin: false,
          error_own_details: action.payload,
        };

      case 'INSERT_MULTI_DIETTYPE':
        return {
          ...state,
          data_diet_type: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };
      case 'INSERT_MULTI_DIETTYPE_ERROR':
        return {
          ...state,
          data_diet_type:[],
          loading_own_details_admin: false,
          error_own_details: action.payload,
        };
        
      case 'INSERT_MULTI_DRINKTYPE':
        return {
          ...state,
          data_drinktype: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };
      case 'INSERT_MULTI_DRINKTYPE_ERROR':
        return {
          ...state,
          data_drinktype:[],
          loading_own_details_admin: false,
          error_own_details: action.payload,
        };


      case 'INSERT_MULTI_FMLSTATUS':

      return {
        ...state,
        data_fmlstatus: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_FMLSTATUS_ERROR':
      return {
        ...state,
        data_fmlstatus:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };


    case 'INSERT_MULTI_FMLTYPE':
      return {
        ...state,
        data_fmltype: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_FMLTYPE_ERROR':
      return {
        ...state,
        data_fmltype: [],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };


    case 'INSERT_MULTI_FMLVALUES':
      return {
        ...state,
        data_fmlvalues: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_FMLVALUES_ERROR':
      return {
        ...state,
        data_fmlvalues:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };


    case 'INSERT_MULTI_HOUSETYPE':
      return {
        ...state,
        data_housetype: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_HOUSETYPE_ERROR':
      return {
        ...state,
        data_housetype:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_MARITALSTATUS':
      return {
        ...state,
        data_maritalstatus: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_MARITALSTATUS_ERROR':
      return {
        ...state,
        data_maritalstatus:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_LANGUAGES':
      return {
        ...state,
        data_languages: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_LANGUAGES_ERROR':
      return {
        ...state,
        data_languages: [],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_OCCUPATION':
      return {
        ...state,
        data_occupation: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_OCCUPATION_ERROR':
      return {
        ...state,
        data_occupation:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_RASHI':
      return {
        ...state,
        data_rashi: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_RASHI_ERROR':
      return {
        ...state,
        data_rashi:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_SKINTONE':
      return {
        ...state,
        data_skintone: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_SKINTONE_ERROR':
      return {
        ...state,
        data_skintone:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_SMOKETYPE':
      return {
        ...state,
        data_smoketype: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };
    case 'INSERT_MULTI_SMOKETYPE_ERROR':
      return {
        ...state,
        data_smoketype:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_STATE':
      return {
        ...state,
        data_state: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };

    case 'INSERT_MULTI_STATE_ERROR':
      return {
        ...state,
        data_state:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

    case 'INSERT_MULTI_SUBCASTE':
      return {
        ...state,
        data_subcaste: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };

    case 'INSERT_MULTI_SUBCASTE_ERROR':
      return {
        ...state,
        data_subcaste:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };


      case 'CASTE_FETCH':
        return {
          ...state,
          list_caste_admin: action.payload,
          loading_own_details_admin: false,
          error: null,
        };

      case 'CASTE_FETCH_ERROR':
        return {
          ...state,
          list_caste_admin: [],
          loading_own_details_admin: false,
          error: action.payload,
        };




        case 'EXECUTE_CREATE_STATEMENTS':
        return {
          ...state,
          loading_own_details_admin: false,
          error: null,
        };

      case 'EXECUTE_CREATE_STATEMENTS_ERROR':
        return {
          ...state,
          loading_own_details_admin: false,
          error: action.payload,
        };


        case 'INSERT_MULTI_REGISTERATION':
      return {
        ...state,
        data_registeration: action.payload.affectedRows,
        loading_own_details_admin: false,
        error_own_details: null,
      };

    case 'INSERT_MULTI_REGISTERATION_ERROR':
      return {
        ...state,
        data_registeration:[],
        loading_own_details_admin: false,
        error_own_details: action.payload,
      };

      case 'INSERT_MULTI_REGISTERATION':
        return {
          ...state,
          data_registeration: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };


        case 'INSERT_MULTI_REGISTERATION':
        return {
          ...state,
          data_registeration: action.payload.affectedRows,
          loading_own_details_admin: false,
          error_own_details: null,
        };


        case 'CLEAR_MASTER_TABLE':
        return {
          ...state,
          list_master_data: [],
          loading_own_details_admin: false,
          error_own_details: null,
        };


      default:
        return state;
    }
  };

  export default reducer;
import { about, matches, profile, profile_acitivty, searches ,others } from "../data/link_menu";
import "../styles/footer.css";



function Footer(){



    return (
        <footer id="footer">
          <div className="footer_section">
            <h3 style={{marginLeft : "20px"}}>About</h3>
            <ul>
              {about.map(([key, path, label]) => (
                <li key={key}><a href={path}>{label}</a></li>
              ))}
            </ul>
          </div>
          <div className="footer_section">
            <h3 style={{marginLeft : "20px"}}>Profile</h3>
            <ul>
              {profile.map(([key, path, label]) => (
                <li key={key}><a href={path}>{label}</a></li>
              ))}
            </ul>
          </div>
        
          <div className="footer_section">
            <h3 style={{marginLeft : "20px"}}>Searches</h3>
            <ul>
              {searches.map(([key, path, label]) => (
                <li key={key}><a href={path}>{label}</a></li>
              ))}
            </ul>
          </div>
          <div className="footer_section">
            <h3 style={{marginLeft : "20px"}}>Profile Activity</h3>
            <ul>
              {profile_acitivty.map(([key, path, label]) => (
                <li key={key}><a href={path}>{label}</a></li>
              ))}
            </ul>
          </div>


          <div className="footer_section">
            <h3 style={{marginLeft : "20px"}}>Others</h3>
            <ul>
              {others.map(([key, path, label]) => (
                <li key={key}><a href={path}>{label}</a></li>
              ))}
            </ul>
          </div>
        </footer>
      );




}
export default Footer;
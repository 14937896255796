import { Autocomplete, Button, Grid, TextField, colors } from "@mui/material";
import { useBasicContext } from "../../contexts/basicdetailsContext";
import "../../styles/animationfade.css";
import { CSSTransition } from "react-transition-group";
import { DropdownSelect, MultiselectDropdown, StyledTextField } from "../../app_utils/input_fields";
import  React,{ useEffect, useState } from "react";
import { birth_date, created_by, created_by_guj, created_by_hindi, created_by_marathi, created_by_tamil, created_by_urdu, marital_status, marital_status_english, marital_status_guj, marital_status_hindi, marital_status_marathi, marital_status_tamil, marital_status_urdu, month_values, year  } from "../../data/data";
import { useTranslation } from 'react-i18next';
import { BIRTH_DATE, COMMUNITY_ID, NAME, PROFILE_ID, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { produce } from "immer";
import { calculateAge, generateDays, generateMonths, generateYears } from "../../common_utils/utils";
import FileUpload from "./Fileupload";
import { Maincolor } from "../../common_utils/colors";
import useFormValidator from "../../common_utils/useFormValidator";
import { useNavigate } from "react-router-dom";
import RotatingHeart from "../../common_utils/loading_animator";
import Footer from "../../components/Footer";
import useMeasure from "react-use-measure";
import { useLayout } from "../../components/useLayout";
import { utils } from "xlsx";



function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
}




 function Basic_details_input({passPath}){

  const{  data ,loading , error , dataInsert , dataUpdate , submit_basicdetails , update_basicdetails} = useBasicContext();
  const [isAnimated, setAnimated] = useState(false);
  const [validationtext , setValidationtext] = useState("");
  

  const { t } = useTranslation();

  const [casteIdpass, getCasteId] = useState("");

  const [setup, setUpdataaray] = useState([]);
  const [userData, setUserData] = useState([]);
  const [subCaste, setSubcaste] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [date, setDate] = useState([]);
  const [months, setMonths] = useState([]);
  const [year, setYears] = useState([]);
  const [load, loadset] = useState(true);

  const { layoutRef , isHidden, width, height } = useLayout();
  
  

  
  
   useEffect(()=>{

    passPath(t('basic_details_label'));

    setAnimated(true);
   

    if(data.length > 0){
    const caste_data = data[1].flat();

    const labelValueCaste = caste_data.map(user => ({
      label: user.caste,  // Adjust based on your data structure
      value: user.Id         // Adjust based on your data structure
    }));

   
    const subcaste_data = data[2].flat();
    const labelValueSubCaste = subcaste_data.filter(user => user.casteId.includes(casteIdpass)).map(user1 => ({
      label: user1.subcaste,  // Adjust based on your data structure
      value: user1.Id         // Adjust based on your data structure
    }));



    const createdBy = data[3].flat();
    const labelValuecreatedBy = createdBy.map(user => ({
      label: user.label,  // Adjust based on your data structure
      value: user.value         // Adjust based on your data structure
    }));


    const maritalStatus = data[4].flat();
    const labelValuemaritalStatus = maritalStatus.map(user => ({
      label: user.label,  // Adjust based on your data structure
      value: user.value         // Adjust based on your data structure
    }));

    const lanaguages = data[5].flat();
    const labelValuelanaguages = lanaguages.map(user => ({
      label: user.label,  // Adjust based on your data structure
      value: user.value         // Adjust based on your data structure
    }));


    setUserData(labelValueCaste);
    setSubcaste(labelValueSubCaste);
    setCreatedBy(labelValuecreatedBy);
    setMaritalStatus(labelValuemaritalStatus);
    setLanguages(labelValuelanaguages);
    setDate(generateDays);
    setMonths(generateMonths);

    const currentYear = new Date().getFullYear();

    setYears(generateYears(1901  , currentYear-18));


     const setup = data[0].flat()[0];
     if (setup != undefined) {
     const nextState = produce((draft) => {
     
        draft["age"] =   setup.age;
        draft["firstName"] =  setup.fullname.split(" ")[0];
        draft["lastName"] =   setup.fullname.split(" ")[1];
        draft["birthDate"] =   setup.dob.split("-")[2];
        draft["month"] =   setup.dob.split("-")[1];
        draft["year"] =   setup.dob.split("-")[0];
        draft["mother_tongue"] =   setup.mother_tongue;
        draft["caste"] =   setup.caste;
        draft["subcaste"] =   setup.subcaste;
        draft["language_known"] =   setup.language_known;
        draft["marital_status"] =   setup.marital_status;
        draft["profile_created"] =   setup.created_by;

    });
    setBasicFields(nextState);

  }else{

    const nextState = produce((draft) => {

      const currentDate = new Date();

      let age =  calculateAge(localStorage.getItem(BIRTH_DATE) , currentDate.getFullYear()+"-"+(currentDate.getMonth()+1)+"-"+currentDate.getDate());
      draft["age"] =   age;
      draft["firstName"] =  localStorage.getItem(NAME).split(" ")[0];
      draft["lastName"] =   localStorage.getItem(NAME).split(" ")[1];
      draft["birthDate"] =   localStorage.getItem(BIRTH_DATE).split("-")[2];
      draft["month"] =   localStorage.getItem(BIRTH_DATE).split("-")[1];
      draft["year"] =   localStorage.getItem(BIRTH_DATE).split("-")[0];
      draft["mother_tongue"] =   "";
      draft["caste"] =   "";
      draft["subcaste"] =   "";
      draft["language_known"] =   "";
      draft["marital_status"] =   "";
      draft["profile_created"] =   "";

  });
  setBasicFields(nextState);


  }
    // Set the updated state
   
    
 
    
    
    
  

  }
  
   },[data]);


   const navigate = useNavigate();

   useEffect(() => {

      if(dataInsert.affectedRows >= 1 ||  dataUpdate.success == 1){
     setTimeout(() => {
      
       navigate('/life', { replace: true });
     }, 2000);
    }


   }, [dataInsert , dataUpdate]);


   const [basicFields, setBasicFields] = useState({
    firstName: '',
    lastName: '',
    birthDate:'',
    month:'',
    year:'',
    age:'',
    profile_created:'',
    marital_status:'',
    caste:'',
    subcaste:'',
    language_known:'',
    mother_tongue:'',
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    birthDate:false,
    month:false,
    year: false,
    age: false,
    profile_created:false,
    marital_status:false,
    caste:false,
    subcaste:false,
    language_known:false,
    mother_tongue:false,
  });


  const [hasBeenFocused, setHasBeenFocused] = useState({
    firstName: false,
    lastName: false,
    birthDate:false,
    month:false,
    year: false,
    age: false,
    profile_created:false,
    marital_status:false,
    caste:false,
    subcaste:false,
    language_known:false,
    mother_tongue:false,
  });
  

  const handleClick = (fieldName) => (event) => {

    const trimmedValue = event.target.value;
  

    setHasBeenFocused((prevHasBeenFocused) =>
      produce(prevHasBeenFocused, (draftHasBeenFocused) => {
        draftHasBeenFocused[fieldName] = true;
      })
    );

    //alert(hasBeenFocused[fieldName]);


    setErrors((prevErrors) =>
      produce(prevErrors, (draftErrors) => {

       // alert(trimmedValue);
       
        if(basicFields[fieldName] !== ''){
          draftErrors[fieldName] = false;
        }else{
         draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined ;
        }
        
        
      })
    );

  }


  const handleChange = (fieldName) => (event , value) => {

    const newValue = event.target.value;

   

    if(fieldName == "caste"){
    const subcaste_data = data[2].flat();
    
    const labelValueSubCaste = subcaste_data.filter(user => user.casteId.includes(newValue)).map(user1 => ({
      label: user1.subcaste,  // Adjust based on your data structure
      value: user1.Id         // Adjust based on your data structure
    }));

    setSubcaste(labelValueSubCaste);
 
    }

    
    const nextState = produce((draft) => {

      if(fieldName == "language_known"){
        draft[fieldName] =   value.map(lang => lang.value).join(",");
   

      }else{
        draft[fieldName] = newValue;


         console.log(newValue);

      }

    });

    // Set the updated state
    setBasicFields(nextState);

    setHasBeenFocused((prevHasBeenFocused) =>
      produce(prevHasBeenFocused, (draftHasBeenFocused) => {
        draftHasBeenFocused[fieldName] = true;
      })
    );

    setErrors((prevErrors) =>
      produce(prevErrors, (draftErrors) => {
        const trimmedValue = newValue;

        //alert(trimmedValue);

        if(fieldName == "firstName" || fieldName == "lastName" || fieldName == "age"){
          draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '';
        }else{
          draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '' ;
        }
        
      })
    );
    


  };


  




  const handleSubmit  =  () => {

    const currentDate = new Date();

  let age =  calculateAge(basicFields.year+"-"+(basicFields.month)+"-"+basicFields.birthDate , currentDate.getFullYear()+"-"+(currentDate.getMonth()+1)+"-"+currentDate.getDate());
 
   const newErrors = produce(errors, (draftErrors) => {
      for (const field in basicFields) {
        const trimmedValue = basicFields[field];

          draftErrors[field] =  trimmedValue === '' || trimmedValue.length <= 0;

       
      }
    });

   
  

    setErrors(newErrors);


     if(Object.values(newErrors).some(error=>error) == true){

      let item = "";
      
    
     }else{

      let bdate = "" , month = "";
      if(basicFields.birthDate.length <2){
          bdate = "0"+basicFields.birthDate;
      }else{
          bdate = basicFields.birthDate;
      }

      if(basicFields.month.length <2){
        month = "0"+basicFields.month;
    }else{
        month = basicFields.month;
    }

      
      loadset(false);
      const article = {
        "fullname":basicFields.firstName+" "+basicFields.lastName,
        "created_by":basicFields.profile_created,
        "dob":basicFields.year+"-"+month+"-"+bdate,
        "age":age,
        "marital_status":basicFields.marital_status,
        "religion":"hindu",
        "caste":basicFields.caste,
        "subcaste":basicFields.subcaste,
        "language_known": basicFields.language_known,
        "mother_tongue":basicFields.mother_tongue ,
        "userId":localStorage.getItem(USER_ID),
        "communityId":localStorage.getItem(COMMUNITY_ID),
        "profileId":localStorage.getItem(PROFILE_ID),
        "Id": data[0].flat()[0] == undefined ? "0"  : data[0].flat()[0].Id 
      };
          
    

        if(data[0].flat()[0] == null || data[0].flat()[0] == undefined){
          submit_basicdetails(article);
        }else{
          update_basicdetails(article);
        }
      
         
         
        

    }
        

      

    
        

    };

   
  



  
   
   return(
    <>
    {  load == false ?  <RotatingHeart/> : 

    <div style={{width:"100%"}} ref={layoutRef} id="container">
  <div style={{marginTop: "230px" , marginBottom:"50px" , width:"80%" , marginLeft:"10%" , marginRight:"10%" }}>

 

  <CSSTransition
  in={isAnimated}
  timeout={0} // Duration of the transition in milliseconds
  classNames="fade"
  unmountOnExit>

  <Grid container xs={12} >

  <Grid container xs={12} md={7} columnSpacing={1} rowSpacing={1}>

  <Grid item xoffsetHeights={12} md={6} >

   
    <StyledTextField label={t('name')}  variant="contained" focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('firstName')}
                            value={basicFields.firstName}
                            />

{errors.firstName && <span style={{ color: 'red' }}>First Name cannot be empty!</span>}
                          
                          
      
    </Grid>
    <Grid item xs={12} md={6} >

    <StyledTextField label={t('surname')}  variant="contained" focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('lastName')}
                            value={basicFields.lastName}
                           
                            />
                            {errors.lastName && <span style={{ color: 'red' }}>Last Name cannot be empty!</span>}
                            
    </Grid>

    <Grid item xs={12} md={3} >


    <DropdownSelect  options={date} label={t('birthdate')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5} 
                            onChange={handleChange('birthDate')}
                            onMouseDown={handleClick('birthDate')}
                            value={basicFields.birthDate} />


{errors.birthDate && <span style={{ color: 'red' }}>required!</span>}

    </Grid>

    <Grid item xs={12} md={3} >

    <DropdownSelect  options={months} label={t('birth_month')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5} 
                            onChange={handleChange('month')}
                            value={basicFields.month}  />

{errors.month && <span style={{ color: 'red' }}>required!</span>}

    </Grid>

    <Grid item xs={12} md={3} >

    <DropdownSelect  options={year} label={t('birth_year')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} borderWidth={0.5}
                            onChange={handleChange('year')}
                            onClick={setHasBeenFocused}
                            value={basicFields.year}  />
                            {errors.year && <span style={{ color: 'red' }}>required!</span>}

    </Grid>

    <Grid item xs={12} md={3} style={{marginTop:"15px"}}>

    <StyledTextField label={t('age_l')}   focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5} 
                            onChange={handleChange('age')}
                            value={basicFields.age}

                            />

{errors.age && <span style={{ color: 'red' }}>required!</span>}
    </Grid>


    <Grid item xs={12} md={6} style={{marginTop:"-10px"}}>

<DropdownSelect options={createdBy}  label={t('created_by_l')} focusedBorderColor={"#E3256B"}
                    borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                    sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }}  borderWidth={0.5}
                    onChange={handleChange('profile_created')}
                            value={basicFields.profile_created} />

{errors.profile_created && <span style={{ color: 'red' }}>required!</span>}

</Grid>

    <Grid item xs={12} md={6}  style={{marginTop:"-10px"}}>

    <DropdownSelect options={maritalStatus}  label={t('marital_status_l')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }}  borderWidth={0.5}
                            onChange={handleChange('marital_status')}
                            value={basicFields.marital_status} />

{errors.marital_status && <span style={{ color: 'red' }}>required!</span>}

    </Grid>


    <Grid item xs={12} md={6} style={{marginTop:"-10px"}}>

<DropdownSelect options={userData}  label={t('caste_l')} focusedBorderColor={"#E3256B"}
                    borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                    sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }}  borderWidth={0.5} 
                    onChange={handleChange('caste')}
                            value={basicFields.caste}/>

{errors.caste && <span style={{ color: 'red' }}>required!</span>}

</Grid>

    <Grid item xs={12} md={6}  style={{marginTop:"-10px"}}>

    <DropdownSelect options={subCaste}  label={t('subcaste_l')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }}  borderWidth={0.5} 
                            onChange={handleChange('subcaste')}
                            value={basicFields.subcaste}/>

{errors.subcaste && <span style={{ color: 'red' }}>required!</span>}

    </Grid>


   

    <Grid item xs={12} md={12} style={{marginTop:"0px"}}>

<MultiselectDropdown options={languages}  label={t('language_known_l')} focusedBorderColor={"#E3256B"}
                    borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                    sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }}  borderWidth={0.5} 
                   
                    onChange={handleChange('language_known')}
                            
                            initialSelectedValues={languages.filter(lang => basicFields.language_known.includes(lang.value))}
                            
                            />

{errors.language_known && <span style={{ color: 'red' }}>required!</span>}

</Grid>

    <Grid item xs={12} md={12}  style={{marginTop:"0px"}}>

    <DropdownSelect options={languages}  label={t('mother_tongue_l')} focusedBorderColor={"#E3256B"}
                            borderColor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={50} color={"#7f7f7f"}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }}  borderWidth={0.5} 
                            onChange={handleChange('mother_tongue')}
                            value={basicFields.mother_tongue}/>

{errors.mother_tongue && <span style={{ color: 'red' }}>required!</span>}

    </Grid>

    <Grid item xs={12} md={12}  style={{marginTop:"0px"}}>
    <Button type="button" style={{justifyContent:"center" ,alignContent:"center",
                background:"#E3256B" ,width:"100%"  , color:"white" , fontWeight:"bold" }} onClick={handleSubmit}>{t('basic_details_submit')}</Button>

                </Grid>

    </Grid>
    

    <Grid container xs={12} md={0.5} style={{marginLeft:"50px"}}>
       <div style={{  height:"100%" , backgroundColor:Maincolor , width:"3px" }} ></div>
  </Grid>


     <Grid container xs={12} md={3.5} style={{marginLeft:"50px"}}>

      <FileUpload />

     </Grid>


    

  </Grid>

  </CSSTransition>

 


   </div>

  

</div>
 }


 
    
    </>
);


 }

export default Basic_details_input;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/communityReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';

const communityContext = createContext();

const initialState = {
  loading_community: true,
  error: null,
  data_insert_community: [],
  list_community_admin:[],
  data_update_community:[]
};

let API = `${BASE_URL}`;


export const CommunityProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const add_community = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/community_details_insert` , article);
      

      dispatch({ type: 'ADD_COMMUNITY_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'ADD_COMMUNITY_ERROR', payload: error });
    }
  };



  const update_community = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/community_details_update` , article);
      

      dispatch({ type: 'UPDATE_COMMUNITY_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'UPDATE_COMMUNITY_ERROR', payload: error });
    }
  };


  const community_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/community_fetchall` , article);
      
      dispatch({ type: 'COMMUNITY_LIST_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'COMMUNITY_LIST_ERROR', payload: error });
    }
  };

 


  
  return <communityContext.Provider value={{ ...state , dispatch , add_community , update_community , community_select}}>{children}</communityContext.Provider>;
};

export const useCommunityContextAdmin = () => useContext(communityContext);

import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import styles from "../../../styles/CssModulesSlider.module.css";
import Chat from '@mui/icons-material/Chat';

function Card(){



    return(<>
    
    
    <Grid item xs={12} sm={2} md={3} lg={4} elevation={15} >



        <div elevation={3} className={styles.ourteam}>
          <div className={styles.picture}>
            <img
              src={"https://i.pinimg.com/736x/73/f1/62/73f16207e11bab3bea90a0eb7e0a194a.jpg"}
              alt="Team Member"
              style={{ width: "130px", height: "130px", borderRadius: "50%" }}
            />


          </div>



          <div className={styles.rounded}>
            <Typography color="white" fontSize="15px" fontWeight="bold">
             Details1
            </Typography>
            <Typography color="white" fontSize="13px" fontWeight="bold">
            Details2
            </Typography>
            <Typography color="white" fontSize="14px" fontWeight="normal" fontFamily={"sans-serif"}>
            Details3
            </Typography>
            <Typography color="white" fontSize="13px" fontWeight="normal">
            Details4
            </Typography>
            <Typography color="white" fontSize="13px" fontWeight="normal" style={{ marginBottom: "5px", marginLeft: "5px", marginRight: "5px" }}>
              
            </Typography>
          </div>
          <ul className={styles.social}>

            <li style={{ display: "inline-block" }}>

              <div id="shortlist" >
                <Tooltip open={false} title={""}>
                  <IconButton style={{ "color": "white" }} size="medium">
                  </IconButton>
                </Tooltip>
              </div>
            </li>

            <li style={{ display: "inline-block" }}>
              <div id="likes" >
                <Tooltip open={false} title={""}>
                  <IconButton style={{ "color": "white" }} size="medium">
                  </IconButton>
                </Tooltip>
              </div>
            </li>
            <li style={{ display: "inline-block" }}>
              <div id="chat">
                <Tooltip open={false} title={""}>
                  <IconButton style={{ "color": "white" }} size="medium">
                    <Chat />
                  </IconButton>
                </Tooltip>
              </div>

            </li>

          </ul>
        </div>





       



      </Grid>
    
    </>);



}

export default Card;
import { useEffect, useState } from "react";
import { useAboutusContext } from "../../contexts/aboutusContext";
import ReactMarkdown from 'react-markdown';
import { Parser } from 'html-to-react';
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import React from "react";
import { COMMUNITY_ID, TRANSLATED_LANG } from "../../common_utils/constants";


function Aboutus({passPath}){

    const { t } = useTranslation();
    const containerRef = useRef(null);

   const{ fetchAboutus , aboutus} = useAboutusContext();
   const[aboutus_string , setaboutus] = useState("");

   useEffect(()=>{

    passPath(t('about_us'));

   },[]);

   useEffect(()=>{

    if(aboutus.length <= 0){
    fetchAboutus({
        "type":"about",   //about , contact , chief_members , privacy_policy , refund_policy , faqs
        "communityId":localStorage.getItem(COMMUNITY_ID),
         "original": "en",
         "translate": [localStorage.getItem(TRANSLATED_LANG)]
       });
    }else{

        setaboutus(aboutus[0][0]["0"]["description"]);

       // alert(aboutus[0][0]["description"]);

    }

   },[aboutus]);

   const parseHtml = new Parser();
 
   return (
   <div style={{position: "absolute", top: "165px" , width:"100%"}}>
   {aboutus.length > 0 ?
    <div dangerouslySetInnerHTML={{ __html: aboutus_string }} />
   :""
  }
   </div>
   );



}
export default Aboutus;
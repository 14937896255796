import { useEffect, useState } from "react";
import { DropdownSelect, StyledTextField } from "../../app_utils/input_fields";
import { useHoroscopeContext } from "../../contexts/horoscopeContext";
import { rashish } from "../../data/data";
import { CSSTransition } from "react-transition-group";
import { Button, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid } from "@mui/material";
import { produce } from "immer";
import { useTranslation } from "react-i18next";
import { DateMaskedTextField, TimeMaskedTextField } from "../../app_utils/DateMaskedTextField";
import { Maincolor } from "../../common_utils/colors";
import { lookupViaCity } from "city-timezones";
import { DateTime } from "luxon";
import { BIRTH_DATE_HOROSCOPE, BIRTH_LOCATION, BIRTH_LOCATION_HOROSCOPE, BIRTH_TIME_HOROSCOPE, COMMUNITY_ID, PROFILE_ID, TIMEZONE_HOROSCOPE, USER_ID } from "../../common_utils/constants";
import RotatingHeart from "../../common_utils/loading_animator";
import { useNavigate } from "react-router-dom";
import MyComponent from "../profile_detail/MyComponent";
import React from "react";






function HoroscopeInput({passPath}) {

    const customColorStyle = {
        color: "#7f7f7f", // Replace with your custom color code
        '&$checked': {
            color: Maincolor, // Replace with your custom color code for checked state
        },
    };



    const { data, loading, error, data_horoscope, fetchHoroscopeChart, data_navmsa, fetchNavmsaChart, horoscope_insert,
        horoscope_update, submit_horoscopedetails, update_horoscopedetails } = useHoroscopeContext();

    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();

    const [Rashi, setRashish] = useState([]);
    const [BirthStar, setBirthstar] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedHo, setIsCheckedHo] = useState(false);

    const [navmsaImage, setNavmsaImage] = useState("");
    const [horoscopeImage, setHoroscopeImage] = useState("");
    const [locationdata, setLocationData] = useState("");
    const [open, setOpen] = useState(false);
    const [load, loadset] = useState(true);
    const [locateset, setLocation] = useState(false);


    const handleClose = () => {
        setOpen(false);
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleCheckboxChangeHo = () => {
        setIsCheckedHo(!isCheckedHo);
    };

    const handlesetLocation = () => {
        setLocation(true);
    };

    const close_getdata = (isclose , data) => {

        setLocation(isclose);
        setLocationData(data);
    
    }


    const handleHoroscopeView = () => {


        try {
            const cityLookup = lookupViaCity(horoscopeFields.birth_place);

            const timezoneIdentifier = cityLookup[0].timezone;


            const latitude = cityLookup[0].lat;
            const longitude = cityLookup[0].lng;

            const now = DateTime.now().setZone(timezoneIdentifier);
            const offsetInHours = now.offset / 60;

            const year = horoscopeFields.birth_date.split("/")[2];
            const month = horoscopeFields.birth_date.split("/")[1];
            const date = horoscopeFields.birth_date.split("/")[0];

            const hour = horoscopeFields.birth_time.split(":")[0];
            const minutes = horoscopeFields.birth_time.split(":")[1];
            const seconds = horoscopeFields.birth_time.split(":")[2];


            const input_horoscope = {
                "year": parseInt(year),
                "month": parseInt(month),
                "date": parseInt(date),
                "hours": parseInt(hour),
                "minutes": parseInt(minutes),
                "seconds": parseInt(seconds),
                "latitude": parseFloat(latitude),
                "longitude": parseFloat(longitude),
                "timezone": parseFloat(offsetInHours),
                "observation_point": "topocentric",
                "ayanamsha": "lahiri"

            }

            fetchHoroscopeChart(input_horoscope);

            fetchNavmsaChart(input_horoscope);

        } catch (error) {

            alert("Please fill up all data correctly with correct spellings then only charts can be shown");


        }


    }



    const navigate = useNavigate();
    useEffect(() => {

        passPath(t('horscope_details_label'));

        if (data_horoscope.length > 0) {

            setHoroscopeImage(JSON.parse(data_horoscope).output);
        }

        if (data_navmsa.length > 0) {

            setNavmsaImage(JSON.parse(data_navmsa).output);
            setOpen(true);

        }

        if(horoscope_insert.affectedRows >= 1 ||  horoscope_update.success == 1){
            setTimeout(() => {
             
              navigate('/family', { replace: true });
            }, 1500);
        }

    }, [data_horoscope, data_navmsa , horoscope_insert ,horoscope_update]);



    useEffect(() => {

        if (data.length > 0) {

            const rashi = Object.values(data[1][1]).map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));
            setRashish(rashi);


            const birth_star = Object.values(data[2][1]).flat().map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));

            setBirthstar(birth_star);


            const setup = data[0].flat()[0]["0"];

            if(setup != undefined){

              
         setLocationData(setup.birth_location);
 
         const nextState = produce((draft) => {
      
         draft["rashi"] =   setup.rashi;
         draft["birth_star"] =  setup.birth_star;
         draft["gotra"] =   setup.gotra;
         draft["believe_horoscope"] =   setup.believe_horoscope;
         draft["birth_date"] =   setup.birth_date;
         draft["birth_place"] =   setup.birth_place;
         draft["birth_time"] =   setup.birth_time;
         draft["birth_country"] =   "";
         draft["isMangalik"] =   setup.is_mangalik;

        // alert(setup.is_mangalik);

         
 
         if(setup.is_mangalik == '1'){
            setIsChecked(true);
         }
 
         if(setup.believe_horoscope == '1'){
              setIsCheckedHo(true);
         }
        
     });
 
     // Set the updated state
     setHoroscopeFields(nextState);
   }

  }


    }, [data]);


    const [horoscopeFields, setHoroscopeFields] = useState({
        rashi: '',
        birth_star: '',
        gotra: '',
        believe_horoscope: '',
        birth_date: '',
        birth_place: '',
        birth_time: '',
        birth_country: '',
        isMangalik: ''
    });


    const [errors, setErrors] = useState({
        rashi: false,
        birth_star: false,
        gotra: false,
        believe_horoscope: false,
        birth_date: false,
        birth_place: false,
        birth_time: false,
        birth_country: false,
        isMangalik: false
    });

    const [hasBeenFocused, setHasBeenFocused] = useState({
        rashi: false,
        birth_star: false,
        gotra: false,
        believe_horoscope: false,
        birth_date: false,
        birth_place: false,
        birth_time: false,
        birth_country: false,
        isMangalik: false
    });




    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setHoroscopeFields(nextState);

        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {
                const trimmedValue = newValue;

                //alert(trimmedValue);
                draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '';


            })
        );

    };



    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;


        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);


                draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;



            })
        );

    }



    const handleSubmit = () => {


        const newErrors = produce(errors, (draftErrors) => {
            for (const field in horoscopeFields) {
                const trimmedValue = horoscopeFields[field];

                //draftErrors[field] = trimmedValue === '' || trimmedValue.length <= 0;
                draftErrors["gotra"] = false;
                draftErrors["believe_horoscope"] = false;
                draftErrors["isMangalik"] = false;
                draftErrors["birth_country"] = false;

            }
        });


        setErrors(newErrors);


        if (Object.values(newErrors).some(error => error) == true) {

            console.log(newErrors);

        } else {



            const cityLookup = lookupViaCity(horoscopeFields.birth_place);

            const timezoneIdentifier = cityLookup[0].timezone;

            
            const latitude = cityLookup[0].lat;
            const longitude = cityLookup[0].lng;

            const now = DateTime.now().setZone(timezoneIdentifier);
            const offsetInHours = now.offset / 60;


            loadset(false);
            const article = {

                "rashi": horoscopeFields.rashi,
                "birth_star": horoscopeFields.birth_star,
                "gotra": horoscopeFields.gotra,
                "believe_horoscope": isCheckedHo == true ? "1" : "0",
                "birth_date": horoscopeFields.birth_date,
                "birth_place": horoscopeFields.birth_place,
                "birth_time": horoscopeFields.birth_time,
                "birth_country": "",
                "horoscope_doc": "",
                "birth_location":locationdata,
                "timezone":parseFloat(offsetInHours),
                "userId": localStorage.getItem(USER_ID),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "profileId": localStorage.getItem(PROFILE_ID),
                "ismangalik": isChecked == true ? "1" : "0",
                "Id": data[0].flat()[0]["0"] == undefined ? "0" : data[0].flat()[0]["0"].Id
            }


            localStorage.setItem(BIRTH_DATE_HOROSCOPE ,  horoscopeFields.birth_date);
            localStorage.setItem(BIRTH_TIME_HOROSCOPE ,  horoscopeFields.birth_time);
            localStorage.setItem(BIRTH_LOCATION_HOROSCOPE ,  locationdata);
            localStorage.setItem(TIMEZONE_HOROSCOPE , parseFloat(offsetInHours));


            if (data[0].flat()[0]["0"] == undefined) {
                submit_horoscopedetails(article);
            } else {
                update_horoscopedetails(article);
            }





        }







    };





    return (<>

{  load == false ?  <RotatingHeart/> : 
        <div style={{  width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "230px" , marginBottom:"60px"}}>
            <CSSTransition
                in={true}
                timeout={500} // Duration of the transition in milliseconds
                classNames="fade"
                unmountOnExit>


                <Grid container xs={12} >

                    <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>

                        <Grid item xs={12} md={12} >

                            <DropdownSelect options={Rashi} label={t('rashi_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('rashi')}
                                onMouseDown={handleClick('rashi')}
                                value={horoscopeFields.rashi} />

                            {errors.rashi && <span style={{ color: 'red' }}>Please Select Rashi</span>}


                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: "-15px" }}>

                            <DropdownSelect options={BirthStar} label={t('birth_star_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('birth_star')}
                                onMouseDown={handleClick('birth_star')}
                                value={horoscopeFields.birth_star} />

                            {errors.birth_star && <span style={{ color: 'red' }}>Please Select BirthStar</span>}

                        </Grid>

                        <Grid item xs={12} md={6} style={{ marginTop: "10px" }} >

                            <StyledTextField label={t('gotra_l')} variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('gotra')}
                                value={horoscopeFields.gotra} />

                        </Grid>


                        <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>

                            <DateMaskedTextField onChange={handleChange('birth_date')}
                                value={horoscopeFields.birth_date}
                                label={t('birth_date_l')}
                                height={50} />

                            {errors.birth_date && <span style={{ color: 'red' }}>Please Select BirthStar</span>}
                        </Grid>


                        <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>

                            <StyledTextField label={t('birth_city')} variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('birth_place')}
                                value={horoscopeFields.birth_place} />

                            {errors.birth_place && <span style={{ color: 'red' }}>Please Select BirthStar</span>}

                        </Grid>


                        <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>

                            <TimeMaskedTextField label={t('birth_time_l')} variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                onChange={handleChange('birth_time')}
                                value={horoscopeFields.birth_time} />

                            {errors.birth_time && <span style={{ color: 'red' }}>Please Select BirthStar</span>}

                        </Grid>

                        <Grid item xs={12} md={6}>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        style={customColorStyle} // Set the color to 'primary', or use 'secondary', 'default', etc.
                                    />
                                }
                                label={t('is_mangalik')}
                            />


                        </Grid>


                        <Grid item xs={12} md={6}>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isCheckedHo}
                                        onChange={handleCheckboxChangeHo}
                                        style={customColorStyle} // Set the color to 'primary', or use 'secondary', 'default', etc.
                                    />
                                }
                                label={t('believe_horoscope_l')}
                            />


                        </Grid>

                        <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>

                            <Button style={{ backgroundColor: Maincolor, width: "350px", color: "white" }} onClick={handleHoroscopeView}>View My Birth Chart and Navmsa chart</Button>


                        </Grid>

                        <Grid item xs={12} md={4} style={{ marginTop: "10px" , marginLeft:"60px" }}>

                            <Button style={{ backgroundColor: Maincolor, width: "200px", color: "white" }} onClick={handlesetLocation}>Set Birth Location</Button>


                        </Grid>


                        <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>
                            <Button type="button" style={{
                                justifyContent: "center", alignContent: "center",
                                background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                            }} onClick={handleSubmit}>Submit Horoscope Details</Button>

                        </Grid>



                    </Grid>

                    <Grid container xs={12} md={0.5} columnSpacing={1} rowSpacing={1} style={{ marginLeft: "30px" }}>
                        <div style={{ height: "100%", backgroundColor: Maincolor, width: "3px" }}></div>
                    </Grid>

                    <Grid container xs={12} md={4.5} columnSpacing={1} rowSpacing={1} style={{ marginLeft: "0px" }}>





                        <Dialog open={open} onClose={handleClose} sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "1500px",  // Set your width here
                                },
                            },
                        }}>
                            <DialogTitle>Yours Birth & Navmsa Charts</DialogTitle>
                            <DialogContent>

                                <div >

                                    <Grid container xs={12} md={12} style={{ justifyContent: "space-around" }}>
                                        <Grid item xs={12} md={0.5} ></Grid>
                                        <Grid item xs={12} md={4.5}>
                                            <CardMedia
                                                component="img"
                                                height={"400"}
                                                image={horoscopeImage}
                                                style={{
                                                    width: '100%', // Ensure the image takes up the full width of the CardMedia
                                                    objectFit: 'contain', // Maintain aspect ratio and cover the entire CardMedia
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4.5} >
                                            <CardMedia
                                                component="img"
                                                height={"400"}
                                                image={navmsaImage}
                                                style={{
                                                    width: '100%', // Ensure the image takes up the full width of the CardMedia
                                                    objectFit: 'contain',
                                                    marginLeft: "35%" // Maintain aspect ratio and cover the entire CardMedia
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={2.5} ></Grid>

                                    </Grid>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>


                    </Grid>

                   <div>{locateset == true ? <MyComponent close_getdata={close_getdata}/> : ""}</div>
                       
                </Grid>
            </CSSTransition>
        </div>

                                            }


    </>);



}

export default HoroscopeInput;
import { Button, CardMedia, Grid, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useWebThemeContextAdmin } from "../../contexts_admin/web_theme_context";
import { useEffect } from "react";
import { ADMIN_COMMUNITY_ID, IMAGE_URL, ROLE } from "../../../common_utils/constants";
import { useState } from "react";
import StylishLabel from "../../component/StylishLabel";
import { bool } from "yup";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import StylishLabelOther from "../../component/StylishLabelOther";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import RichTextEditor from "../../component/RichTextEditor";







function Homepageslider(){

    const { data_homepage_slider , homepage_slider_fetch ,homepage_slider_insert , homepage_slider_update , data_homepage_slider_insert , data_homepage_slider_update} = useWebThemeContextAdmin();

    const [webfunction , setwebfunction] = useState("");
    const [communityId , setcommunityId] = useState("");
    const [openCommunity , setopenCommunity] = useState(false);
    const [files , setFiles] = useState([]);
    const [HtmlContent, setHtmlContent] = useState("");
    const [open , setopen] = useState(false);

    const [htmlphoto , sethtmlphoto] = useState("");
    const [contentdata, setHtmlContentdata] = useState("");
    const [Id, setId] = useState("");
    const [isinsert , setisinsert] = useState(true);


    const dropzoneStyle = { 
         border: '2px dashed #d9d9d9',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
      };


    const onDrop = useCallback((acceptedFiles) => {
        // Assuming there is an array of existing files in state called 'files'
    
        setFiles([]);
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ]);
    
      }, [setFiles]);
    
    
    
      const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
      });

  
    useEffect(()=>{
  
        let communityId_admin  =  "0";
        if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

          communityId_admin = localStorage.getItem("communityId_admin");
          setcommunityId(communityId_admin);

        }else{
         communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
         setcommunityId(communityId_admin);
        }

       
        if(data_homepage_slider_insert.affectedRows >= 1 ||  data_homepage_slider_update.affectedRows >= 1){

            setHtmlContentdata("");
            setFiles([]);
            setisinsert(true);

            homepage_slider_fetch({"communityId":communityId_admin});
        }

      
  

    },[data_homepage_slider_insert , data_homepage_slider_update]);

    useEffect(()=>{

        let communityId_admin  =  "0";
        if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

          communityId_admin = localStorage.getItem("communityId_admin");
          setcommunityId(communityId_admin);

        }else{
         communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
         setcommunityId(communityId_admin);
        }


        homepage_slider_fetch({"communityId":communityId_admin});


    },[]);
  

  const handleFunctionChange = (event) => {
  
    setwebfunction(event.target.value);
  
  };
  
    const handleAddFunction = () => {
  
        

        const formData = new FormData();

        formData.append('sampleFile[]', files[0]);
        
        formData.append("slider_text" , HtmlContent);
  
        formData.append("communityId" , communityId);
        formData.append("ishidden" , "0");
        formData.append("Id" , Id);
  
        if(isinsert == true){

            formData.append("photos",  IMAGE_URL+"uploads/"+files[0].name);
            homepage_slider_insert(formData);

        }else{
            
            if(files[0].name.includes("http:")){
                formData.append("photos",  files[0].name);
            }else{
                formData.append("photos",  IMAGE_URL+"uploads/"+files[0].name);
            }
            
            homepage_slider_update(formData);

        }
        
  
      
       
        
      
    };
  

    const handleSwitchChange = (data , event, index) => {
  
      const newData = [...homepage_slider_fetch];
      event.target.checked  ? newData[index].visibility = 0 :  newData[index].visibility = 1;

    };
  
    const handleClickCommunityLink = (communityId_admin) => {
  
      setcommunityId(communityId_admin);
      localStorage.setItem("communityId_admin" , communityId_admin);

      homepage_slider_fetch({"communityId":communityId_admin});
  
    };
  
  
    const handleClickCommunity = () => {

      setopenCommunity(true);
  
    };
  
  
    const handleCloseCommunity = (isopen) => {
      setopenCommunity(isopen);
    }

    const handleContent = (content) => {

        setHtmlContent(content);
    
        console.log(content);
    
       }

       const handleClose = () => {
        setopen(false);
    }
  
    const handleopen = (homepage) => {
        setopen(true);
        setHtmlContentdata(homepage.slider_text);
        //sethtmlphoto(homepage.photos);
        setId(homepage.Id);

        downloadFile(homepage.photos , 0);
        setisinsert(false);
    }
  


    const downloadFile = async (element , index) => {

        try {
          const response = await fetch(element);
          const blob = await response.blob();
          const file = new File([blob], element);
    
          //setFiles(prevFiles => [...prevFiles, file]);
    
          setFiles((prevFiles) =>{ let files = [...prevFiles]; files[index] = file;  return files});
          
        } catch (error) {
          console.error('Error fetching or creating file:', error);
        }
    
      };
    
  
    let role = localStorage.getItem(ROLE);
  
    return(<>
    
  
    { role == "developer" ? <StylishLabelOther text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "150px" : "100px", left: role == "developer" ? "5px" : "5px" , width: "100%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>
  
  
   
  
    <Grid container spacing={1}>
  
    <Grid  item xs={7}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>SliderText</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Visibility</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data_homepage_slider.length > 0 && data_homepage_slider.map((religion , index) => (
                    <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                       <TableCell>{religion.Id}</TableCell>
                      <TableCell>{religion.slider_text}</TableCell>
                      <TableCell><img src={religion.photos} alt="Couple" style={{ width: 50, height: 50 }} /></TableCell>
                      <TableCell style={{ color: 'black', fontSize: '15px'  }}>
                <Switch
                    checked={religion.visibility == 0 ? true : false}
                    onChange={(event) => handleSwitchChange(religion , event, index)}
                    color="primary"
                  />
                  </TableCell>
                  <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleopen(religion)}>
      {"Update"}
    </span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
  
  
  
            
  
  </Grid>
  
  
  <Grid  item xs={5}>
  <Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
  <Grid container spacing={1}>
  
  
  <Grid item xs={12}>

  <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop Your Slider Image here</p>
      </div>

  </Grid>
  <Grid item xs={12}>

  {files.length > 0 ? <CardMedia component="img" height="130" image = { URL.createObjectURL(files[0])} alt={`Image 0}`}  style={{objectFit:"contain"}} /> : ""}

  </Grid>

  <Grid item xs={12}>


  <RichTextEditor SentContent={handleContent}  getContent={contentdata}/>

  </Grid>
  
          <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleAddFunction}>
          {isinsert == true ? "Add Data" : "Update Data"}
        </Button>
        </Grid>
  
        </Grid>
      </Paper>
  
  </Grid>
  
  
  </Grid>
  
  
  {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
 
 
  </div>
  
  
  
  
    
    
    </>);


}

export default Homepageslider;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/supportTicketReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';

const supportTicketContext = createContext();

const initialState = {
  loading_supportTikcet_admin: true,
  error: null,
  data_supportticket_admin: [],
  data_support_insert:[],
  data_support_update:[],
  data_supportComment:[],
  data_supportComment_select:[]
};

let API = `${BASE_URL}`;


export const SupportTicketProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const SupportTicketInsert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticket_insert` , article);
      

      dispatch({ type: 'SUPPORT_INSERT_SUCCESS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SUPPORT_INSERT_ERROR', payload: error });
    }
  };



  const SupportTicketUpdate = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticket_update` , article);
      

      dispatch({ type: 'SUPPORT_UPDATE_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'SUPPORT_UPDATE_ERROR', payload: error });
    }
  };



  const SupportTicketCommentsInsert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticketcomments_insert` , article);
      

      dispatch({ type: 'SUPPORT_TICKET_COMMENTS_INSERT', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'SUPPORT_TICKET_COMMENTS_INSERT_ERROR', payload: error });
    }
  };




  const SupportTicketSelect = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticketcomments_select` , article);
      

      dispatch({ type: 'SUPPORT_COMMENTS_SELECT', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'SUPPORT_COMMENTS_SELECT_ERROR', payload: error });
    }
  };



   const SupportTicketSelectByPriority = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticketcomments_ByPriority` , article);
      

      dispatch({ type: 'SUPPORT_COMMENTS_PRIORITY', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SUPPORT_COMMENTS_PRIORITY_ERROR', payload: error });
    }
  };


  const SupportTicketSelectByStatus = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticketcomments_ByStatus` , article);
      
      dispatch({ type: 'SUPPORT_BY_STATUS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SUPPORT_BY_STATUS_ERROR', payload: error });
    }
  };


  const SupportTicketCommentsSelect = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/support_ticketcomments_selectcomment` , article);
      
      dispatch({ type: 'SUPPORT_TIKCET_COMMENT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SUPPORT_TIKCET_COMMENT_ERROR', payload: error });
    }
  };

  const deleteSupportComment = () =>{

    dispatch({ type: 'SUPPORT_TIKCET_COMMENT_DELETE'});

  };


  
  return <supportTicketContext.Provider value={{ ...state , dispatch , SupportTicketInsert , SupportTicketUpdate , SupportTicketCommentsInsert , SupportTicketSelect ,SupportTicketSelectByPriority ,SupportTicketSelectByStatus ,SupportTicketCommentsSelect , deleteSupportComment}}>{children}</supportTicketContext.Provider>;
};

export const useAdminsupportTicketContext = () => useContext(supportTicketContext);
import Map, { GeolocateControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useState } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

mapboxgl.accessToken = 'pk.eyJ1Ijoic2hhbm5pIiwiYSI6ImNscmFmYXV4eDBjMXgyanZ4OXljZ3NpaWoifQ.tWESH9DJG51lOuqjnB1KgA';


export default function MyComponent({close_getdata}) {

    
  const [ close , setClose] = useState(false);

    useEffect(()=>{
      setClose(true);
    },[]);

    const closeMap = (isclose , data) =>{

        close_getdata(isclose , data);
        setClose(isclose);
       
    }


    return (

        <Dialog open={close} onClose={closeMap} maxWidth={false} fullWidth>
        <DialogTitle>Double Click your Birth location (Search from right corner)</DialogTitle>
        <DialogContent style={{ height: '100vh' }}>
        <MyComponent1  closedata={closeMap}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMap} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    );

  }


const MyComponent1 = ({closedata}) => {
  const mapContainer = useRef(null);
  const[lati , setLat] = useState(-1.0);
  const[lngi , setLng] = useState(-1.0);
  const[markers , setMarker] = useState([]);

  const [map, setMap] = useState(null);
  

  const closeMap = (data , loc) => {

    closedata(data , loc);

  }

  useEffect(() => {

    if (navigator.geolocation && lati == -1.0) {
        // Use the Geolocation API to get the current position
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLat(latitude);
            setLng(longitude);
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by your browser');
      }


    
    
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lngi , lati],
      zoom: 10.5,
    });


    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
      });
  
      map.addControl(geocoder);

    

      const navControl = new mapboxgl.NavigationControl();
      map.addControl(navControl, 'top-left');
  


    map.on('dblclick', (e) => {
        const { lng, lat } = e.lngLat;
        console.log( lat+",");

        const newMarker = new mapboxgl.Marker();
        //newMarker.setLngLat([lng, lat]).addTo(map);

        closeMap(false , lat+","+lng);


      });

      //setMap(map);
  

    return () => map.remove(); 
      // Cleanup on component unmount
  }, [lati ,lngi]);


  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const functionInsideModal = () => {
    // Your React function logic goes here
    alert('Hello from the function inside the modal!');
  };

  return (<>
  
  <div ref={mapContainer} style={{ height: '700px', width: '100%' }} />;
  
  
  </>)      
};


import React, { useState } from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useAboutusContext } from '../../contexts/aboutusContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { COMMUNITY_ID, TRANSLATED_LANG } from '../../common_utils/constants';

const ContactUs = ({passPath}) => {
  const iconStyle = { fontSize: 35, marginRight: 15, color: 'var(--aboutus-icon-colour)' };
  const headerStyle = { color: '#E3256B', fontSize: '2rem', marginBottom: '0.5rem' };
  const subHeaderStyle = { color: 'var(--aboutus-heading-colour)', fontSize: '1.3rem', marginBottom: '0.25rem', fontWeight:'bold'};


  const { t } = useTranslation();

  const{ fetchContactus , contactus} = useAboutusContext();
  const[contact_us , setcontactus] = useState([]);
  

  useEffect(()=>{

   if(contactus.length <= 0){

    passPath(t('contact_us'));

    fetchContactus({
       "type":"contact",   //about , contact , chief_members , privacy_policy , refund_policy , faqs
       "communityId":localStorage.getItem(COMMUNITY_ID),
        "original": "en",
        "translate": [localStorage.getItem(TRANSLATED_LANG)]
      });
   }else{

    setcontactus(contactus[0][0]["0"]);
    

   }

  },[contactus]);

  return (
    <div style={{position: "absolute", top: "165px" , width:"100%" }}>
    <Container maxWidth="md">
     

      {/* Call Section */}
      <Box my={2}>
        <Typography variant="h6" style={subHeaderStyle}>Call Us</Typography>
        <Box display="flex" alignItems="center">
          <PhoneIcon style={iconStyle} />
          <Typography variant="h5">{contact_us["call_us"]}</Typography>
        </Box>
      </Box>
      
      <Divider />

      {/* WhatsApp Section */}
      <Box my={2}>
        <Typography variant="h6" style={subHeaderStyle}>WhatsApp</Typography>
        <Box display="flex" alignItems="center">
          <WhatsAppIcon style={iconStyle} />
          <Typography variant="h5">{contact_us["whatsapp_us"]}</Typography>
        </Box>
      </Box>
      
      <Divider />

      {/* Email Section */}
      <Box my={2}>
        <Typography variant="h6" style={subHeaderStyle}>Email</Typography>
        <Box display="flex" alignItems="center">
          <EmailIcon style={iconStyle} />
          <Typography variant="h5">{contact_us["mail_us"]}</Typography>
        </Box>
      </Box>
      
      <Divider />

      {/* Office Hours Section */}
      <Box my={2}>
        <Typography variant="h6" style={subHeaderStyle}>Office Hours</Typography>
        <Box display="flex" alignItems="center">
          <AccessTimeIcon style={iconStyle} />
          <Typography variant="h5">{contact_us["office_time"]}</Typography>
        </Box>
      </Box>
      
      <Divider />

      {/* Office Address Section */}
      <Box my={2}>
        <Typography variant="h6" style={subHeaderStyle}>Office Address</Typography>
        <Box display="flex" alignItems="center">
          <LocationOnIcon style={iconStyle} />
          <Typography variant="h5">
          {contact_us["office_address"]}
          </Typography>
        </Box>
      </Box>
    </Container>
    </div>
  );
};

export default ContactUs;

import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerOccupation from '../../reducer_admin/occupationReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const occupationAdminContext = createContext();

const initialState = {
  data_occupation_admin: [],
  loading: true,
  error: null,
  occupation_insert_admin:[],
  occupation_update_admin:[]
};

let API = `${BASE_URL}`;


export const OccupationAdminProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerOccupation , initialState);

const fetchOccupationItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/occupation_details_edit` , params);

      dispatch({ type: 'OCCUPATION_DETAILS_EDIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'OCCUPATION_DETAILS_EDIT_ERROR_ADMIN', payload: error });
    }
  };


  const submit_occupationdetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_occupation` , article);
      dispatch({ type: 'INSERT_OCCUP_DETAILS_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_OCCUP_DETAILS_ERROR_ADMIN', payload: error });
    }
  };


  const update_occupationdetailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_occupation` , article);
      dispatch({ type: 'UPDATE_OCCUP_DETAILS_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_OCCUP_DETAILS_ERROR_ADMIN', payload: error });
    }
  };


 
  
  return <occupationAdminContext.Provider value={{ ...state , dispatch , fetchOccupationItemsAdmin , submit_occupationdetailsAdmin ,update_occupationdetailsAdmin}}>{children}</occupationAdminContext.Provider>;
};

export const useOccupationAdminContext = () => useContext(occupationAdminContext);







const about = [

["about_us","/about" , "About Us"],
["contact_us", "/contact_us" , "Contact Us"],
["terms_conditions","/terms" , "Terms & Conditions"],
["privacy policy", "/privacy" , "Privacy Policy"],
["faqs", "/faqs" , "Faqs"],
["refund_policy_l","/refund", "Refund Policy"],
["chief_members","/chief" , "Chief Members"]

];



const profile = [
    ["your_details","/all_details" , "Your Details"],
    ["basic_details_label", "/basic" , "Basic Details"],
    ["lifestyle_label","/life" , "Lifestyle Details"],
    ["contact_details_label","/contact" , "Contacts Details"],
    ["education_ddetails_label", "/educational" , "Educational Details"],
    ["occupation_details_label", "/occupation" , "Occupational Details"],
    ["horscope_details_label","/horoscope" , "Horoscope Details"],
    ["family_details_label","/family" , "Family Details"],
    ["partner_preference_label","/partner" , "Partner Details"],
    ["upload_documents","/docs" , "Documents/Proofs"],
    ["settings","/settings" , "Settings"],
    ];

    const matches = [

         ["latest_matches" , "/matches" , "Matches"],
         ["mutual_matches" , "/mutual_matches" , "Mutual Matches"],
         ["new_joined" , "/just_joined" , "Just Joined"]

    ]


    const searches = [

        ["Normal Search" , "/normal_search" , "Regular Search"],
        ["Advanced Search" , "/advanced_search" , "Advanced Search"],
        ["Handicap Search" , "/handicap_search" , "Handicap Search"],
        ["Mangalik Search", "/mangalik_search" , "Mangalik Search"],
        ["IIT/IIM/NIT Search" , "/iit_iim_nit_search" , "IIT/IIM/NIT Search"],
        ["Saved Search" , "/saved_search" , "Saved Searches"]
   ]


   const profile_acitivty = [

    ["view_others_profile" , "/viewed_profile/i" , "Views of Other's Profiles"],
    ["short_lists" , "/shortlisted/i" , "My Shortlists"],
    ["view_others_contact" , "/viewed_contacts/i" , "Other's Contacts Views"],
    ["ignore_profiles", "/ignored_profiles/ignore" , "Ignored Profile Views"],
    ["block_profiles" , "/blocked_profiles/block" , "Blocked Profiles"],
    ["other_view_profile" , "/other_view_profile/other" , "Other View My Profile"],
    ["other_view_contact" , "/other_view_contacts/other" , "Other View My Contacts"] ,
    ["other_shortlisted_me" , "/other_shortlists/other" , "Other Shortlist Me"],
]
    
   const inbox = [ 
    ["interest_recieved" , "/interset_recieved/other" , "Like/Interest Recieved"],
    ["interest_sent" , "/interset_sent/i" , "Interest/Like Sent"],
    ["accept_request" , "/accepted_request/i" , "Accept Request"],
   ]


   const others = [ 
    ["interest_recieved" , "/events" , "Events"],
    ["interest_sent" , "/blogs" , "Blogs"],
    ["happy_story" , "/happy_story" , "Happy Story"]
   ]





export {about , profile , matches , searches ,profile_acitivty , inbox ,others};
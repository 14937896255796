const reducer = (state, action) => {
    switch (action.type) {

      case 'ADD_ROLES_SUCCESS':
        return {
          ...state,
          data_insert_roles: action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'ADD_ROLES_PERMISSION_SUCCESS':
        return {
          ...state,
          data_insert_rolespermission: action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'UPDATE_ROLES_SUCCESS':
        return {
          ...state,
          data_update_roles:  action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'ROLES_LIST_SUCCESS':
        return {
          ...state,
          data_roles_select: action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'ADD_ALLSTAFF_SUCCESS':
        return {
          ...state,
          data_insert_allstaff:  action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'UPDATE_ALLSTAFF_SUCCESS':
        return {
          ...state,
          data_update_allstaff:  action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'PERMISSION_LIST_SUCCESS':
        return {
          ...state,
          data_rolespermision_select: action.payload,
          loading_user_roles: false,
          error: null
        };
      case 'ADD_ROLES_ERROR':
        return {
            ...state,
            data_insert_roles: [],
            loading_user_roles: false,
            error: action.payload
          };
      case 'ADD_ROLES_PERMISSION_ERROR':
        return {
            ...state,
            data_insert_rolespermission: [],
            loading_user_roles: false,
            error: action.payload
          };
      case 'UPDATE_ROLES_ERROR':
        return {
            ...state,
            data_update_roles: [] ,
            loading_user_roles: false,
            error: action.payload
          };
      case 'ROLES_LIST_ERROR':
        return {
            ...state,
            data_roles_select:[] ,
            loading_user_roles: false,
            error: action.payload
          };
      case 'ADD_ALLSTAFF_ERROR':
        return {
            ...state,
            data_insert_allstaff: [],
            loading_user_roles: false,
            error: action.payload
          };
      case 'UPDATE_ALLSTAFF_ERROR':
        return {
            ...state,
            data_update_allstaff: [],
            loading_user_roles: false,
            error: action.payload
          };
      case 'PERMISSION_LIST_ERROR':
        return {
            ...state,
            data_rolespermision_select: [],
            loading_user_roles: false,
            error: action.payload
          };

          case 'ROLESMASTER_LIST_SUCCESS':
        return {
          ...state,
          data_rolesmaster_select: action.payload,
          loading_user_roles: false,
          error: null
        };

        case 'ROLESMASTER_LIST_ERROR':
          return {
            ...state,
            data_rolesmaster_select: [],
            loading_user_roles: false,
            error: action.payload
          };
        
      default:
        return state;
    }
  };

  export default reducer;
import { useState } from "react";
import { useBlogsContextAdmin } from "../../contexts_admin/blogs_context";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useEffect } from "react";
import { Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import StylishLabel from "../../component/StylishLabel";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { useNavigate } from "react-router-dom";
import DetailBlogs from "./DetailBlogs";
import React from "react";



function BlogsList(){

    const {list_blogs_admin  , blogs_select  , loading_blogs } = useBlogsContextAdmin();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [Index, setIndex] = useState(-1);
    const [openBlogs , setopenBlogs] = useState(false);

    let role = localStorage.getItem(ROLE);


    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });

      


    useEffect(()=>{

        if(list_blogs_admin.length > 0 || loading_blogs == false){

          setLoad(true);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

          blogs_select({
                "communityId":communityId_admin,
                "offset":"0",
                "query":""
              });

        }
       
    },[list_blogs_admin]);




    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  



      const hanldeOpenBlogs = () => {
        setopenBlogs(true);
      }
  
  
      const handleCloseBlogs = (isopen) => {
        setopenBlogs(isopen);
      }

  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        blogs_select({
            "communityId":communityId_admin,
            "offset":"0",
            "query":""  
          });
  
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
    
        blogs_select({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
            "query":query  
          });
  
    }
  
    const [query, setValue] = useState('');

    const handleChange = (event) => {
      setValue(event.target.value);

      blogs_select({
        "communityId":communityId,
        "offset": "0",
        "query":event.target.value  
      });

    };

    const handleUserdetailsViewClick = (index) => {
      
      setIndex(index);
      hanldeOpenBlogs();

    }


    const navigate = useNavigate();

    const addblogsClick = () =>{

         navigate("/add_blogs/"+communityId);

    }

   



    return (<>
    
    {  load == false ?  <RotatingHeart/> : 
       
       <div  style={{backgroundColor:"#FAFBFD"}}>

        { role == "developer" ? <StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px"   }}>
        
        <Grid key={5} item xs={12}>
  <ThemeProvider theme={theme}>
  <TextField
      label="Enter text to search"
      value={query}
      onChange={handleChange}
      variant="outlined"

      fullWidth
    />
    </ThemeProvider>
    </Grid>



    <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>



<TableContainer component={Paper}>
  <Table>
    <TableHead>
      <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Blogs Listing</Typography>
      <Typography  variant="h6" align="left" width="300px" style={{position:"absolute" , right:"15px" , marginTop:"-32px" ,paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}} onClick={addblogsClick}>Click To Add Blogs</Typography>
      <TableRow style={{width:"100%"}}>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Title</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Keywords</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>View Details</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {list_blogs_admin.length > 0 && list_blogs_admin[0].map((free_members, index) => (
        <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.Id}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.title}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.key_words.split(',').map((status, index) => (
                  <Chip key={index} label={status.trim()} color={"primary"} style={{ marginRight: '4px' , marginTop:"6px" }} />
                ))}</TableCell>
          <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUserdetailsViewClick(index)}>
      {"View Details"}
    </span></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_blogs_admin.length > 0 && Math.ceil(list_blogs_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>





        </Grid>

        </Grid>
    

        {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
        {openBlogs == true ? <DetailBlogs isopen={true} setClose={handleCloseBlogs}   Details={list_blogs_admin[0][Index]}  /> : ""}

    </div>

    }
    
    </>);


}

export default BlogsList;
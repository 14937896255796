const reducer = (state, action) => {
  switch (action.type) {
    case 'BASIC_DETAILS_EDIT':
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case 'BASIC_DETAILS_EDIT_ERROR':
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      };


      case 'IMAGE_FETCH_EDIT':
      return {
        ...state,
        picList: action.payload,
        loading: false,
        error: null,
      };
    case 'IMAGE_FETCH_EDIT_ERROR':
      return {
        ...state,
        picList: [],
        loading: false,
        error: action.payload,
      };



    case 'BASIC_DETAILS_SUBMIT':
      return {
        ...state,
        dataInsert: action.payload,
        loading: false,
        error: null,
      };
    case 'BASIC_DETAILS_SUBMIT_ERROR':
      return {
        ...state,
        dataInsert: [],
        loading: false,
        error: action.payload,
      };

      case 'BASIC_DETAILS_UPDATE':
      return {
        ...state,
        dataUpdate: action.payload,
        loading: false,
        error: null,
      };
    case 'BASIC_DETAILS_UPDATE_ERROR':
      return {
        ...state,
        dataUpdate: [],
        loading: false,
        error: action.payload,
      };


      case 'INSERT_PHOTOS':
      return {
        ...state,
        picInsert: action.payload,
        loading: false,
        error: null,
      };
    case 'INSERT_PHOTOS_ERROR':
      return {
        ...state,
        picInsert: [],
        loading: false,
        error: action.payload,
      };


      case 'UPDATE_PHOTOS':
        return {
          ...state,
          picUpdate: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_PHOTOS_ERROR':
        return {
          ...state,
          picUpdate: [],
          loading: false,
          error: action.payload,
        };



    default:
      return state;
  }
};

export default reducer;
import { useState } from "react";
import { ROLE } from "../../../common_utils/constants";
import { Button, Chip, TextField, Typography } from "@mui/material";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect } from "react";
import RichTextEditor from "../../component/RichTextEditor";
import { useBlogsContextAdmin } from "../../contexts_admin/blogs_context";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";






function AddBlogs({text}){

    const {data_insert_blogs  , add_blogs  , loading_blogs } = useBlogsContextAdmin();
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState([]);
    const [HtmlContent, setHtmlContent] = useState("");

    const { communityId } = useParams();
    const [issubmit , setIsSubmit] = useState(false);

    const [formData, setFormData] = useState({
      title: '',
      details: '',
      key_words: '',
    });


      const navigate = useNavigate();

     useEffect(()=>{

        if(data_insert_blogs.affectedRows >= 1 && issubmit == true){
           
          navigate("/list_blogs");
          window.location.reload();
           
        }

      },[data_insert_blogs]);


      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
    
      const handleInputKeyPress = (event) => {
        if (event.key === ',') {
          event.preventDefault();
          addTagsFromInput();
        }
      };
    
      const addTagsFromInput = () => {
        const newTags = inputValue
          .split('\n')
          .map((tag) => tag.trim())
          .filter((tag) => tag !== '');
    
        setTags([...tags, ...newTags]);
        setInputValue('');
      };
    
      const removeTag = (tagToRemove) => {
        setTags(tags.filter((tag) => tag !== tagToRemove));
      };


     
  const [errors, setErrors] = useState({});
  const ClickToAddCommunity = () =>{

    const requiredFields = ['title'];
    const newErrors = {};
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = 'This field is required';
      }
    });
    setErrors(newErrors);


    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0  && tags != [] && HtmlContent != "") {

      add_blogs({
        "title":formData.title,
        "details":HtmlContent,
        "key_words":tags.toString(),
        "communityId":communityId,
        "ishidden":"0"
      });

      setIsSubmit(true);

    }else{

      alert("All Fields are compulsory");

    }

  }

  
   const handleContent = (content) => {

    setHtmlContent(content);

    console.log(content);

   }


    let role = localStorage.getItem(ROLE);


   return (<>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>
          
          <Typography variant="h5" style={{ color: '#800080' , backgroundColor:"pink" , padding:"10px 20px" , fontWeight:"bold"}}>Add Community</Typography>
   
          <TextField name="title" label="Title *" variant="outlined" value={formData.title} onChange={handleChange} fullWidth/>


           <TextField
        label="Key Words *"
        variant="outlined"
        onKeyPress={handleInputKeyPress}
        fullWidth
        onChange={handleInputChange}
        rows={3}
        value={inputValue}
        name="inputValue" 
        multiline
        InputProps={{
          startAdornment: tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => removeTag(tag)}
              style={{ marginRight: 5 }}
            />
          )),
        }}
      />

           <RichTextEditor SentContent={handleContent} />
     
           <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={ClickToAddCommunity}>Save Blogs</Button>



   </div>
   </>);


}

export default AddBlogs;
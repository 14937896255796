import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../../reducer_admin/basicReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const basicContextAdmin = createContext();

const initialState = {
  data_admin: [],
  loading_admin: true,
  error_basic_admin: null,
  dataInsert_admin:[],
  dataUpdate_admin:[],
  picInsert_admin:[],
  picUpdate_admin:[],
  picList_admin:[]
};

let API = `${BASE_URL}`;


export const BasicProviderAdmin = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const fetchBasicItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/basic_details_edit` , params);
      
      dispatch({ type: 'BASIC_DETAILS_EDIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BASIC_DETAILS_EDIT_ERROR_ADMIN', payload: error });
    }
  };



  const fetchImagesItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/profile_fetch` , params);
      
      dispatch({ type: 'IMAGE_FETCH_EDIT_ADMIN' , payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'IMAGE_FETCH_EDIT_ERROR_ADMIN' , payload: error });
    }
  };



  const submit_basicdetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/basic_detail` , article);
      dispatch({ type: 'BASIC_DETAILS_SUBMIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BASIC_DETAILS_SUBMIT_ERROR_ADMIN', payload: error });
    }
  };


  const update_basicdetailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/basic_detail` , article);
      dispatch({ type: 'BASIC_DETAILS_UPDATE_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'BASIC_DETAILS_UPDATE_ERROR_ADMIN', payload: error });
    }
  };


  const insert_photosAdmin = async (formData) => {
    try {
      const response = await axios.post(`${API}`+'profile/insert_photos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'INSERT_PHOTOS_ADMIN', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'INSERT_PHOTOS_ERROR_ADMIN', payload: error });
    }
  };



  const update_photosAdmin = async (formData) => {
    try {
      const response = await axios.patch(`${API}`+'profile/update_photos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'UPDATE_PHOTOS_ADMIN', payload: response.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_PHOTOS_ERROR_ADMIN', payload: error });
    }
  };




  
  return <basicContextAdmin.Provider value={{ ...state , dispatch , fetchBasicItemsAdmin , fetchImagesItemsAdmin , submit_basicdetailsAdmin , update_basicdetailsAdmin , insert_photosAdmin ,update_photosAdmin}}>{children}</basicContextAdmin.Provider>;
};

export const useBasicContextAdmin = () => useContext(basicContextAdmin);
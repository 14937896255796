import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerProofs from "../../src/reducer/proofsReducer";
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const proofsContext = createContext();

const initialState = {
  data_proofs_list: [],
  loading: true,
  error: null,
  insert_proofs_list:[],
  update_proofs_list:[]
};

let API = `${BASE_URL}`;


export const ProofsProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducerProofs, initialState);

  const fetchProofsList = async (params) => {
    try {
      const response = await axios.post(`${API}` + `fetch_data/profile_fetch`, params);

      dispatch({ type: 'PROOFS_FETCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PROOFS_FETCH_ERROR', payload: error });
    }
  };



  const insert_proofs = async (formData) => {
    try {
      const response = await axios.post(`${API}`+'profile/insert_proofs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'INSERT_PROOFS', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'INSERT_PROOFS_ERROR', payload: error });
    }
  };



  const update_proofs = async (formData) => {
    try {
      const response = await axios.patch(`${API}`+'profile/update_proofs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'UPDATE_PROOFS', payload: response.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_PROOFS_ERROR', payload: error });
    }
  };






  useEffect(() => {

    fetchProofsList({
      "type": "proofs",
      "userId": localStorage.getItem(USER_ID),
      "communityId": localStorage.getItem(COMMUNITY_ID),
      "original": "en",
      "translate": [localStorage.getItem(TRANSLATED_LANG)]
    });




  }, []);




  return <proofsContext.Provider value={{ ...state, dispatch, fetchProofsList , insert_proofs , update_proofs}}>{children}</proofsContext.Provider>;
};

export const useProofsContext = () => useContext(proofsContext);
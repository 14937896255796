import { useEffect } from "react";
import { useAdminMembersContext } from "../../contexts_admin/member_context";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Grid, Modal, TextField, ThemeProvider, useTheme } from "@mui/material";
import * as XLSX from 'xlsx';
import ProgressBar from '../../../adminpanel/component/ProgressBar';
import { ADMIN_COMMUNITY_ID, IMAGE_URL } from "../../../common_utils/constants";

import matchPronunciationUsingSome from "../../../common_utils/match_pronounciation";
import ListValues from "./list_values";
import { saveAs } from 'file-saver';



function  ImportExcel({open , handleClose})  {

  const delay =  (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const { master_table_fetch_all , list_master_data , INSERT_MULTI_BASICDETAIL , INSERT_MULTI_EDUDETAIL ,INSERT_MULTI_FAMILYDETAIL , INSERT_MULTI_HOROSCOPEDETAIL ,INSERT_MULTI_CONTACTDETAIL , INSERT_MULTI_LIFESTYLEDETAIL ,INSERT_MULTI_NOTIFICATION , INSERT_MULTI_NOTIFICATIONCOUNT ,INSERT_MULTI_OCCUPATIONDETAIL , INSERT_MULTI_PARTNER_PREFERENCE ,INSERT_MULTI_PHOTO ,INSERT_MULTI_PROOF ,INSERT_MULTI_SETTINGS , data_multibasic , data_multicontact ,data_multieducation, data_multifamily ,data_multilifestyle, data_multioccupation ,data_multihoroscope ,data_multiphoto ,data_multipartnerprefs, data_multinotification , data_multinotification_count, data_multisettings ,data_multiproof,
      INSERT_MULTI_ANNUALINCOME , INSERT_MULTI_BIRTHSTAR , INSERT_MULTI_BLOODGROUP ,INSERT_MULTI_BODYTYPE ,INSERT_MULTI_CASTE , INSERT_MULTI_CITY ,INSERT_MULTI_CREATEDBY ,INSERT_MULTI_BIGINSTITUTE ,INSERT_MULTI_DEGREE ,INSERT_MULTI_DIETTYPE , INSERT_MULTI_DRINKTYPE ,INSERT_MULTI_FMLSTATUS ,INSERT_MULTI_FMLTYPE ,INSERT_MULTI_FMLVALUES ,INSERT_MULTI_HOUSETYPE ,INSERT_MULTI_MARITALSTATUS ,INSERT_MULTI_LANGUAGES ,INSERT_MULTI_OCCUPATION ,INSERT_MULTI_RASHI ,INSERT_MULTI_SKINTONE ,INSERT_MULTI_SMOKETYPE ,INSERT_MULTI_STATE ,INSERT_MULTI_SUBCASTE ,caste_fetch,
      data_annual_income , data_birthstar,data_blood_group, data_bodytype,data_caste,data_city,data_createdby,data_biginstitute,data_degree, data_diet_type,
  data_drinktype,data_fmlstatus,data_fmltype,data_fmlvalues,data_housetype,data_maritalstatus, data_languages, data_occupation,data_rashi,
  data_skintone,data_smoketype, data_state,  data_subcaste, list_caste_admin , EXECUTE_CREATE_STATEMENTS ,INSERT_MULTI_REGISTERATION
  , data_registeration , master_table_fetch_all_clear} = useAdminMembersContext();


    const [progress , setProgress] = useState(0);
    const [tot_count , setTotalCount] = useState(0);
    const [tot_progress_change , setTotalProgressChange] = useState(0);
    const [callMasterTable , setMasterTable] = useState(0);

    const [sheetNames, setSheetNames] = useState([]);
    const [newResults , setnewResults] = useState([]);
    const [IsCombined , setIscombined] = useState(false);

    const [newAdd, setNewAdd] = useState({
      caste: [],
      marital_status: [],
      subcaste: [],
      createdby: [],
      languages: [],
      skintone: [],
      blood_group: [],
      body_type: [],
      diet_type: [],
      drink_type: [],
      smoke_type: [],
      annual_income: [],
      rashi: [],
      birth_star: [],
      family_values: [],
      family_type: [],
      family_status: [],
      house_type: []
    });
  

    const [categories , setCategories] = useState({
      caste: [],
      marital_status: [],
      subcaste: [],
      createdby: [],
      languages: [],
      skintone: [],
      blood_group: [],
      body_type: [],
      diet_type: [],
      drink_type: [],
      smoke_type: [],
      annual_income: [],
      rashi: [],
      birth_star: [],
      family_values: [],
      family_type: [],
      family_status: [],
      house_type: []
    });


    const [batchdata, setData] = useState([
    {
      sheetName: 'Sheet1',
      rows: [
        []
      ],
      rowCount: 0
    }
    ]);


    const [batchdata1, setData1] = useState([
      {
        sheetName1: 'Sheet1',
        rows1: [
          []
        ],
        rowCount1: 0
      }
      ]);

    

 
    let communityId_admin  =  "0";

    useEffect(()=>{

      if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
        communityId_admin = localStorage.getItem("communityId_admin");

      }else{
        communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);

      }

      localStorage.setItem("communityIdSet" , communityId_admin);

    },[]);


    useEffect(()=>{
      

        if(callMasterTable == 1 || callMasterTable == 2){

        if(list_master_data.length <= 0){

            master_table_fetch_all({
                "type":"", // blog , caste , roles , all_staff 
                "communityId": localStorage.getItem("communityIdSet"),
                "original": "en",
                "translate": ["en"]  
              });

        }else{

            const extractedArraysByCategory = extractArraysByCategory();

        }

      }


    } , [list_master_data , callMasterTable]);


    useEffect(()=>{

       

    },[newResults]);


    const performTask = (task) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('first_task');
        }, 1500); // Simulates a 1-second task
      });
    };



    const performTask2 = (task) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('second_task');
        }, 2000); // Simulates a 1-second task
      });
    };

    const performTask3 = (task) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('second_task');
        }, 5000); // Simulates a 1-second task
      });
    };
    
   
    const extractArraysByCategory = () => {



      const categories = {
        caste: [],
        marital_status: [],
        subcaste: [],
        createdby: [],
        languages: [],
        skintone: [],
        blood_group: [],
        body_type: [],
        diet_type: [],
        drink_type: [],
        smoke_type: [],
        annual_income: [],
        rashi: [],
        birth_star: [],
        family_values: [],
        family_type: [],
        family_status: [],
        house_type: [],
        state: [],
        city: [],
        degree: [],
        iit_iim_nit: [],
        occupation: []
      };
        
    
      list_master_data.map((category ,index) => {
          
       
          category.forEach(item => {

            if (index == 1) {
              categories.caste.push({ id: item.Id, label: item.caste });
            }
            if ( index == 5) categories.marital_status.push({ id: item.value, label: item.label });
            if (index == 6)  categories.subcaste.push({ id: item.Id, label: item.subcaste });
            if (index == 7 ) categories.createdby.push({ id: item.value, label: item.label });
            if (index == 8)  categories.languages.push({ id: item.value, label: item.label });
            if (index == 9)  categories.skintone.push({ id: item.value, label: item.label });
            if (index == 10) categories.blood_group.push({ id: item.value, label: item.label });
            if (index == 11) categories.body_type.push({ id: item.value, label: item.label });
            if (index == 12) categories.diet_type.push({ id: item.value, label: item.label });
            if (index == 13) categories.drink_type.push({ id: item.value, label: item.label });
            if (index == 14) categories.smoke_type.push({ id: item.value, label: item.label });
            if (index == 15) categories.annual_income.push({ id: item.value, label: item.label });
            if (index == 16) categories.rashi.push({ id: item.value, label: item.label });
            if (index == 17) categories.birth_star.push({ id: item.value, label: item.label });
            if (index == 18) categories.family_values.push({ id: item.value, label: item.label });
            if (index == 19) categories.family_type.push({ id: item.value, label: item.label });
            if (index == 20) categories.family_status.push({ id: item.value, label: item.label });
            if (index == 21) categories.house_type.push({ id: item.value, label: item.label });
            if (index == 22) categories.state.push({ id: item.Id, label: item.state_name });
            if (index == 23) categories.city.push({ id: item.Id, label: item.city_name });
            if (index == 24) categories.degree.push({ id: item.Id, label: item.degree_name });
            if (index == 25) categories.iit_iim_nit.push({ id: item.Id, label: item.institute_name });
            if (index == 26) categories.occupation.push({ id: item.Id, label: item.occupation });

          });


        });

       
    
        setCategories(categories);


      };



      const addNewSheetWithData = (sheetName , newRows) => {

        setData(prevData => [
          ...prevData,
          {
            sheetName,
            rows: newRows,
            rowCount: newRows.length
          }
        ]);

        
      };



      const addNewSheetWithData1 = (sheetName1 , newRows) => {

        setData1(prevData1 => [
          ...prevData1,
          {
            sheetName1,
            rows1: newRows,
            rowCount1: newRows.length
          }
        ]);

      };




    /*  const excelDateToJSDate = (serial) => {
        const utcDays = Math.floor(serial - 25569);
        const utcValue = utcDays * 86400;
        const dateInfo = new Date(utcValue * 1000);
      
        const fractionalDay = serial - Math.floor(serial) + 0.0000001;
      
        const totalSeconds = Math.floor(86400 * fractionalDay);
      
        const seconds = totalSeconds % 60;
      
        const hours = Math.floor(totalSeconds / (60 * 60));
        const minutes = Math.floor(totalSeconds / 60) % 60;
      
        return new Date(
          dateInfo.getFullYear(),
          dateInfo.getMonth(),
          dateInfo.getDate(),
          hours,
          minutes,
          seconds
        );
      };
      */
      
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      
      const convertExcelDateToProperFormat = (serial) => {
        const jsDate = excelDateToJSDate(serial);
        return formatDate(jsDate);
      };



      const formatDateDMY = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
      };
      
      const convertExcelDateToProperFormatDMY = (serial) => {
        const jsDate = excelDateToJSDate(serial);
        return formatDateDMY(jsDate);
      };

      const excelDateToJSDateRight = (serial) => {
        // Excel serial date is the number of days since 1899-12-30
        const excelEpoch = new Date(1899, 11, 30);
        const jsDate = new Date(excelEpoch.getTime() + serial * 24 * 60 * 60 * 1000);
        return jsDate;
      };





      const excelDateToJSDate = (serial) => {
    const excelEpoch = new Date(1899, 11, 30);
    const jsDate = new Date(excelEpoch.getTime() + serial * 24 * 60 * 60 * 1000);
    return jsDate;
  };

  // Format the date as dd/mm/yyyy
  const formatDateToDDMMYYYY = (date) => {
    const day = String(date.getMonth()+1).padStart(2, '0');
    const month = String(date.getDate()).padStart(2, '0'); // months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };



  const isValidDate = (dateString) => {
    // Regex pattern to match dd/mm/yyyy format
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  
    // Test the format
    if (!regex.test(dateString)) {
      return false;
    }
  
    // Extract day, month, and year from the date string
    const [day, month, year] = dateString.split('/').map(Number);
  
    // Create a new Date object from the formatted date
    const date = new Date(`${month}/${day}/${year}`);
  
    // Validate the date object
    return (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    );
  };

  


      const convertExcelTime = (excelTime) => {
        // Excel time is stored as a fraction of a day (1 = 24 hours)
        // Multiply by 24 to get the hours and then split by integer/decimal parts
        let totalHours = excelTime * 24;
        
        let hours = Math.floor(totalHours); // Get whole hours
        let minutes = Math.round((totalHours - hours) * 60); // Get fractional minutes
    
        // Handle AM/PM format (optional)
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
    
        // Format time with leading zeros for minutes
        const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    
        return formattedTime;
      };
    
      
      
    let totalRows = 0 ;

    const[binarystr , setBinaryStr] = useState("");

    const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']; // .xlsx MIME type
    const requiredSheetNames = ['caste', 'marital_status' , 'subcaste' , 'created_by' , 'languages' , 'skintone' , 'blood_group',
      'body_type' , 'diet_type' , 'drink_type' , 'smoke_type' , 'rashish' , 'birth_star' , 'family_values' , 'family_type' , 'family_status'
    ];
    
    const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

   // const files = acceptedFiles.dataTransfer.files;

    
   // Check if the file is an .xlsx file
   if (file && allowedTypes.includes(file.type)) {



     let workbook2 = [];
    const reader = new FileReader();
    reader.onload =  async(event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr , { type: 'binary' });
      setSheetNames(workbook.SheetNames);
      setBinaryStr(binaryStr);
     
      
      /*const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      jsonData.map((row, index) => {

        console.log(row);
        
      })
      */



      const hasRequiredSheets = requiredSheetNames.every(sheetName => workbook.SheetNames.includes(sheetName));

      if (hasRequiredSheets) {

      workbook.SheetNames.slice(0 , 23).map( async (sheetName ,indexsheet) => {
        // Get worksheet
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet , { header: 1 });
        
        const newSheetName =  sheetName;
        const newRows = [];

       // console.log(sheetName);

         if(indexsheet == 0){

          
          
          rows.map((sheet ,index) => {

            if(index > 0){

              newRows.push({"caste":sheet[1] , "religion_id":sheet[2] , "communityId": localStorage.getItem("communityIdSet") });
            }

          });

          
          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

        }else if(indexsheet == 1){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });


          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

      
        }else if(indexsheet == 2){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"subcaste":sheet[1] , "casteId":sheet[2]  , "communityId": localStorage.getItem("communityIdSet") });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;


        }else if(indexsheet == 3){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
         
          

        }else if(indexsheet == 4){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
          

        }else if(indexsheet == 5){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
          
        
        }else if(indexsheet == 6){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

         

        }else if(indexsheet == 7){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

          

        }else if(indexsheet == 8){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

        

        }else if(indexsheet == 9){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
         
          
        }else if(indexsheet == 10){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

         

        }else if(indexsheet == 11){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

          

        }else if(indexsheet == 12){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
          

        }else if(indexsheet == 13){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
         

        }else if(indexsheet == 14){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
          
      

        }else if(indexsheet == 15){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

          

        }else if(indexsheet == 16){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
          
         

        }else if(indexsheet == 17){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"value":sheet[1] , "label":sheet[2] });
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

          
          
        }else if(indexsheet == 18){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"state_name":sheet[1] , "state_hindi":sheet[2] , "state_guj":sheet[3] , "state_marathi":sheet[4] , "state_tamil":sheet[5] , "state_urdu":sheet[6] , "country_id":sheet[7]});
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;


        }else if(indexsheet == 19){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"city_name":sheet[1] , "city_hindi":sheet[2] , "city_guj":sheet[3] , "city_marathi":sheet[4] , "city_tamil":sheet[5] , "city_urdu":sheet[6] ,  "state_id":sheet[7] , "country_id":sheet[8]});
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

          
          

        }else if(indexsheet == 20){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"degree_name":sheet[1] , "degree_hindi":sheet[2] , "degree_guj":sheet[3] , "degree_marathi":sheet[4] , "degree_tamil":sheet[5] , "degree_urdu":sheet[6]});
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

         

        }else if(indexsheet == 21){
          
          rows.map((sheet ,index) => {
            if(index > 0){
              newRows.push({"institute_name":sheet[1] , "institute_hindi":sheet[2] , "institute_guj":sheet[3] , "institute_marathi":sheet[4] , "institute_tamil":sheet[5] , "institute_urdu":sheet[6]});
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;

         

        }else if(indexsheet == 22){


          rows.map((sheet ,index) => {
            
            if(index > 0){
              newRows.push({"occupation":sheet[1] , "occup_hindi":sheet[2] , "occup_guj":sheet[3] , "occup_marathi":sheet[4] , "occup_tamil":sheet[5] , "occup_urdu":sheet[6]});
            }
          });

          addNewSheetWithData(newSheetName , newRows);
          totalRows += newRows.length;
          

         // setMasterTable(1);
          
        }

          //alert(totalRows);
        setTotalCount(totalRows);
        setProgress(2.78*(indexsheet+1));

        

      });

      const result3  = await performTask2();

      await Promise.all([
        executeBatchForFunction(INSERT_MULTI_CASTE , 1),
        executeBatchForFunction(INSERT_MULTI_MARITALSTATUS , 2),
        executeBatchForFunction(INSERT_MULTI_SUBCASTE, 3),
        executeBatchForFunction(INSERT_MULTI_CREATEDBY, 4),
        executeBatchForFunction(INSERT_MULTI_LANGUAGES, 5),
        executeBatchForFunction(INSERT_MULTI_SKINTONE, 6),
        executeBatchForFunction(INSERT_MULTI_BLOODGROUP, 7),
        executeBatchForFunction(INSERT_MULTI_BODYTYPE, 8),
        executeBatchForFunction(INSERT_MULTI_DIETTYPE, 9),
        executeBatchForFunction(INSERT_MULTI_DRINKTYPE, 10),
        executeBatchForFunction(INSERT_MULTI_SMOKETYPE, 11),
        executeBatchForFunction(INSERT_MULTI_ANNUALINCOME, 12),
        executeBatchForFunction(INSERT_MULTI_RASHI, 13),
        executeBatchForFunction(INSERT_MULTI_BIRTHSTAR, 14),
        executeBatchForFunction(INSERT_MULTI_FMLVALUES, 15),
        executeBatchForFunction(INSERT_MULTI_FMLTYPE, 16),
        executeBatchForFunction(INSERT_MULTI_FMLSTATUS, 17),
        executeBatchForFunction(INSERT_MULTI_HOUSETYPE, 18),
        executeBatchForFunction(INSERT_MULTI_STATE, 19),
        executeBatchForFunction(INSERT_MULTI_CITY, 20),
        executeBatchForFunction(INSERT_MULTI_DEGREE, 21),
        executeBatchForFunction(INSERT_MULTI_BIGINSTITUTE, 22),
        executeBatchForFunction(INSERT_MULTI_OCCUPATION, 23)
    ]);

  

      const result1  = await performTask();

      

      setMasterTable(1);

        const result2  = await performTask2();

        const newMaritalStatusRows = [];
        const newCasteRows = [];
        const newCreatedbyRows = [];
        const newSubcasteRows = [];
        const newMothertongueRows = [];
        const newLanguageRows = [];
        const newPermstateRows = [];
        const newPermcityRows = [];
        const newWorkStateRows = [];
        const newWorkcityRows = [];
        const newiitiimnitRows = [];
        const newdegreeRows = [];
        const newfamilyvalueRows = [];
        const newFamilystatusRows = [];
        const newhousetypeRows = [];
        const newfamilyTypeRows = [];
        const newBirthstarRows = [];
        const newRashiRows = [];
        const newOccupationRows = [];
        const newskintoneRows = [];
        const newbodytyperows = [];
        const newDietTypeRows = [];
        const newDrinkTypeRows = [];
        const newSmokeTypeRows = [];

         let times = 0;

         let MaritalStatusRowsId = 0, CasteRowsId  =0 , CreatedbyRows = 0 ,SubcasteRows =0 , MothertongueRows = 0;
         let stateRows = 0 , cityRows = 0 , iitiimnitRows = 0 , degreeRows = 0 ,familyvalueRows = 0 ,familyTypeRows = 0;
         let FamilystatusRows = 0 , housetypeRows = 0 ,BirthstarRows = 0,  RashiRows = 0, OccupationRows = 0, skintoneRows = 0;
         let bodytyperows = 0, DietTypeRows =0, DrinkTypeRows = 0, SmokeTypeRows = 0;

      
         
      
      workbook.SheetNames.map( async (sheetName ,indexsheet) => {
        // Get worksheet
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet , { header: 1 });
        const newSheetName =  sheetName;
        const newRows = [];

        

        console.log(indexsheet);

        setCategories((categories) => {

        

          if(times == 0){

            MaritalStatusRowsId = categories.marital_status[categories.marital_status.length-1].id;
            CasteRowsId = categories.caste[categories.caste.length-1].id;
            CreatedbyRows = categories.createdby[categories.createdby.length-1].id;
            SubcasteRows = categories.subcaste[categories.subcaste.length-1].id;
            MothertongueRows = categories.languages[categories.languages.length-1].id;
            stateRows = categories.state[categories.state.length-1].id;
            cityRows = categories.city[categories.city.length-1].id;
            iitiimnitRows = categories.iit_iim_nit[categories.iit_iim_nit.length-1].id;
            degreeRows = categories.degree[categories.degree.length-1].id;
            familyvalueRows = categories.family_values[categories.family_values.length-1].id;
            FamilystatusRows = categories.family_status[categories.family_status.length-1].id;
            housetypeRows = categories.house_type[categories.house_type.length-1].id;;
            familyTypeRows = categories.family_type[categories.family_type.length-1].id;
            BirthstarRows = categories.birth_star[categories.birth_star.length-1].id;
            RashiRows = categories.rashi[categories.rashi.length-1].id;
            OccupationRows =  categories.occupation[categories.occupation.length-1].id;
            skintoneRows = categories.skintone[categories.skintone.length-1].id;
            bodytyperows = categories.body_type[categories.body_type.length-1].id;
            DietTypeRows = categories.diet_type[categories.diet_type.length-1].id;
            DrinkTypeRows = categories.drink_type[categories.drink_type.length-1].id;
            SmokeTypeRows = categories.smoke_type[categories.smoke_type.length-1].id;

             times++;
          }
         


          if(indexsheet == 23){   // basic details

          

          rows.map((sheet , index) => {


            if(index > 0){

              const created_by = categories.createdby.find(item => matchPronunciationUsingSome(sheet[2] , item.label));
              const marital_status = categories.marital_status.find(item => matchPronunciationUsingSome(sheet[5] , item.label));
              const casteid = categories.caste.find(item => matchPronunciationUsingSome(sheet[7] , item.label));
              const subcaste = categories.subcaste.find(item => matchPronunciationUsingSome(sheet[8] , item.label));
              const mother_tongue = categories.languages.find(item => matchPronunciationUsingSome(sheet[10] , item.label));

              let created_by_check = created_by ?? false;
              if(created_by_check == false &&  newCreatedbyRows.some(item => item.created_by.toLowerCase() === sheet[2].toLowerCase()) == false){
                  newCreatedbyRows.push({"created_by": sheet[2] , "Id": CreatedbyRows++});
                  
              }
             
              let marital_status_check = marital_status ?? false;
              if( marital_status_check == false && newMaritalStatusRows.some(item => item.marital_status.toLowerCase() === sheet[5].toLowerCase()) == false){
                  newMaritalStatusRows.push({"marital_status": sheet[5] , "Id":MaritalStatusRowsId++});
              }

              let casteid_check = casteid ?? false;

              
              if(casteid_check == false && newCasteRows.some(item => item.caste.toLowerCase() === sheet[7].toLowerCase()) == false){
                 
                CasteRowsId = CasteRowsId + 1;
                newCasteRows.push({"caste": sheet[7] , "Id":CasteRowsId});

                console.log(casteId(categories , "suryavanshi" , newCasteRows)+"====++++++");
              }

                 
              let subcaste_check = subcaste ?? false;
              if(subcaste_check == false && newSubcasteRows.some(item => item.subcaste.toLowerCase() === sheet[8].toLowerCase()) == false){
                   
                   newSubcasteRows.push({"subcaste": sheet[8] , "Id":SubcasteRows++ , "caste" :casteId(categories , sheet[7] , newCasteRows)});
              }

              let mother_tongue_check = mother_tongue ?? false;
              if(mother_tongue_check == false && newMothertongueRows.some(item => item.mother_tongue.toLowerCase() === sheet[10].toLowerCase()) == false){
                  newMothertongueRows.push({"mother_tongue": sheet[10] , "Id":MothertongueRows++});
              }


              if(sheet[9] != undefined){
              sheet[9].split(",").map(label => {
                const result = categories.languages.find(item => matchPronunciationUsingSome(label.toLowerCase() , item.label.toLowerCase()));

                console.log(label+"[][][]");
                
                let result_check = result ?? false;
              if( result_check == false &&  newLanguageRows.some(item => item.language.toLowerCase() === label.toLowerCase()) == false){
                  newLanguageRows.push({"language": label , "Id":MothertongueRows++});
              }

                return result ? result.id : null; // Return null if not found
              });

            }

              
            }
           
          });


          setProgress(2.78*(indexsheet+1));
         

        }else if(indexsheet == 24){  // contact details

          rows.map((sheet ,index) => {

            if(index > 0){

              //alert(sheet[14]);
             
              const perm_country = "1";
              const perm_state = categories.state.find(item => matchPronunciationUsingSome(sheet[10] , item.label));
              const perm_city = categories.city.find(item =>  item.label === sheet[11]) == undefined ? false : categories.city.find(item => matchPronunciationUsingSome(sheet[11] , item.label));

              const work_country = "1";
              const work_state = categories.state.find(item => matchPronunciationUsingSome(sheet[13] , item.label));
              const work_city = categories.city.find(item => item.label === sheet[14]) == undefined ? false  : categories.city.find(item => item.label === sheet[14]);

              let perm_state_check = perm_state ?? false;
              if(perm_state_check == false &&  newPermstateRows.some(item => item.state_rows.toLowerCase() === sheet[10].toLowerCase()) == false){
                  newPermstateRows.push({"state_rows": sheet[10] , "Id":stateRows++});
              }

              let perm_city_check = perm_city ?? false;
              if(perm_city_check == false &&  newPermcityRows.some(item => item.city_rows.toLowerCase() === sheet[11].toLowerCase()) == false){
                  newPermcityRows.push({"city_rows": sheet[11] , "Id":cityRows++ , "stateId": PermstateId(categories , sheet[10] , newPermstateRows)});
              }


              let work_state_check = work_state ?? false;
              if(work_state_check == false &&  newWorkStateRows.some(item => item.state_rows_w.toLowerCase() === sheet[13].toLowerCase()) == false){
                newWorkStateRows.push({"state_rows_w": sheet[13]  , "Id":stateRows++});
              }

              let work_city_check = work_city ?? false;
              if(work_city_check == false &&  newWorkcityRows.some(item => item.city_rows_w.toLowerCase() === sheet[14].toLowerCase()) == false){
                newWorkcityRows.push({"city_rows_w": sheet[14]  , "Id":cityRows++ , "stateId": workstateId(categories , sheet[13] , newWorkStateRows)});
              }

              
            }
           
          });

          setProgress(2.78*(indexsheet+1));
          
        }else if(indexsheet == 25){    // educational details

          rows.map((sheet ,index) => {

          if(index > 0){

           //alert(sheet[4]+"----"+sheet[5]);
           
          let college_name = "" , degree = "";
          if(sheet[4] != undefined){
             college_name = categories.iit_iim_nit.find(item => matchPronunciationUsingSome(sheet[4] , item.label)) ==undefined ? false :  categories.iit_iim_nit.find(item => matchPronunciationUsingSome(sheet[4] , item.label));
          }else{
             college_name =  "";
          }

          if(sheet[5].length > 0){
            degree = categories.degree.find(item => matchPronunciationUsingSome(sheet[5] , item.label)) == undefined ? false  : categories.degree.find(item => matchPronunciationUsingSome(sheet[5] , item.label));
          }else{
            
            degree = "";

          }
           

            let college_name_check = college_name ?? false;

            if(sheet[4] != undefined){
            if(college_name_check == false &&  newiitiimnitRows.some(item => item.iitiimnit.toLowerCase() === sheet[4].toLowerCase()) == false){
              newiitiimnitRows.push({"iitiimnit": sheet[4]  , "Id":iitiimnitRows++});
            }
          }

            let degree_name = degree ?? false;
            if(degree_name == false &&  newdegreeRows.some(item => item.degree.toLowerCase() === sheet[5].toLowerCase()) == false){
              newdegreeRows.push({"degree": sheet[5]  , "Id":degreeRows++});
            }



          }

        });

      
        setProgress(2.78*(indexsheet+1));

        }else if(indexsheet == 26){        // family details

          
          rows.map((sheet ,index) => {

           // alert(sheet[1]);


          if(index > 0){
           
            const family_value = categories.family_values.find(item => matchPronunciationUsingSome(sheet[1] , item.label));
            const family_type = categories.family_type.find(item => matchPronunciationUsingSome(sheet[2] , item.label));
            const family_status = categories.family_status.find(item => matchPronunciationUsingSome(sheet[3] , item.label)) == undefined ? false : categories.family_status.find(item => matchPronunciationUsingSome(sheet[3] , item.label));
            const house_type = categories.house_type.find(item => matchPronunciationUsingSome(sheet[13] , item.label)) == undefined ? false : categories.house_type.find(item => matchPronunciationUsingSome(sheet[13] , item.label));

            let family_value_check = family_value ?? false;
            if(family_value_check == false &&  newfamilyvalueRows.some(item => item.fml_value.toLowerCase() === sheet[1].toLowerCase()) == false){
              newfamilyvalueRows.push({"fml_value": sheet[1]  , "Id":familyvalueRows++});
            }

            let family_type_check = family_type ?? false;
            if(family_type_check == false &&  newfamilyTypeRows.some(item => item.fml_type.toLowerCase() === sheet[2].toLowerCase()) == false){
              newfamilyTypeRows.push({"fml_type": sheet[2]  , "Id":familyTypeRows++});
            }

            let family_status_check = family_status ?? false;
            if(family_status_check == false &&  newFamilystatusRows.some(item => item.fml_status.toLowerCase() === sheet[3].toLowerCase()) == false){
              newFamilystatusRows.push({"fml_status": sheet[3]  , "Id":FamilystatusRows++});
            }

            let house_type_check = house_type  ?? false;
            if(house_type_check == false &&  newhousetypeRows.some(item => item.house_type.toLowerCase() === sheet[13].toLowerCase()) == false){
              newhousetypeRows.push({"house_type": sheet[13]  , "Id":housetypeRows++});
            }

           
          }

          });
        
            
            setProgress(2.78*(indexsheet+1));

        }else if(indexsheet == 27){            // horoscope

           rows.map((sheet ,index) => {

          if(index > 0){
           
            const rashi = categories.rashi.find(item => matchPronunciationUsingSome(sheet[1] , item.label));
            const birth_star = categories.birth_star.find(item => matchPronunciationUsingSome(sheet[2] , item.label));
            
            let rashi_check = rashi ?? false;
            if(rashi_check == false &&  newRashiRows.some(item => item.rashi_value.toLowerCase() === sheet[1].toLowerCase()) == false){
              newRashiRows.push({"rashi_value": sheet[1] ,  "Id":RashiRows++});
            }

            let birth_star_check = birth_star ?? false;
            if(birth_star_check == false &&  newBirthstarRows.some(item => item.birth_star.toLowerCase() === sheet[2].toLowerCase()) == false){
              newBirthstarRows.push({"birth_star": sheet[2]  , "Id":BirthstarRows++});
            }


          }

        });
        
          
            setProgress(2.78*(indexsheet+1));

        }else if(indexsheet == 28){     // occupation


          rows.map((sheet ,index) => {

          if(index > 0){
           
            const occupation_list = categories.occupation.find(item => matchPronunciationUsingSome(sheet[1].toLowerCase() , item.label.toLowerCase()));
         
            let occupation_list_check = occupation_list ?? false;
            if(occupation_list_check == false &&  newOccupationRows.some(item => item.occupation.toLowerCase() === sheet[1].toLowerCase()) == false){
            //   const occupation_list = categories.occupation.find(item => item.label === sheet[1]).id;
              newOccupationRows.push({"occupation": sheet[1] , "Id":OccupationRows++});
            }


          }

        });
        
          
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 29){   //notification

          rows.map((sheet ,index) => {

          if(index > 0){
           
            newRows.push({ "Id":sheet[0] , "notifi_type":sheet[1] , "message":sheet[2] , "sender_type":sheet[3] ,"sender_id":sheet[4] , "reciever_id":sheet[5] , "communityId": localStorage.getItem("communityIdSet") });
          }

        });
        
          

            totalRows += newRows.length;
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 30){   // notification_count
      
          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({"Id":sheet[0] , "notification_id":sheet[1] , "user_id":sheet[2] , "isread":sheet[3] });
          }
        
          });

          
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 31){  // partner preference

          rows.map((sheet ,index) => {

          if(index > 0){

            //alert(sheet[3]);

            const marital_status = categories.marital_status.find(item => item.label === sheet[3]) == undefined ? sheet[3] : categories.marital_status.find(item => item.label === sheet[3]).id;
            const caste  =  categories.caste.find(item => item.label === sheet[4]) == undefined ? sheet[4] : sheet[4] == "Any" ? "Any" : (sheet[4] == "" || sheet[4] == null) ? sheet[4] : categories.caste.find(item => item.label === sheet[4]).id;
            const subcaste  =  categories.subcaste.find(item => item.label === sheet[5]) == undefined ? sheet[5] :  sheet[5] == "Any" ? "Any" : (sheet[5] == "" || sheet[5] == null) ? sheet[5] : categories.subcaste.find(item => item.label === sheet[5]).id;
            const skintone =  categories.skintone.find(item => item.label === sheet[6]) == undefined ? sheet[6] :   sheet[6] == "Any" ? "Any" : (sheet[6] == "" || sheet[6] == null) ? sheet[6] :  categories.skintone.find(item => item.label === sheet[6]).id;
            const state =  categories.state.find(item => item.label === sheet[7]) == undefined ? sheet[7] :  sheet[7] == "Any" ? "Any" : (sheet[7] == "" || sheet[7] == null) ? sheet[7] :  categories.state.find(item => item.label === sheet[7]).id;
            const city =  categories.city.find(item => item.label === sheet[8]) == undefined ? sheet[8] : sheet[8] == "Any" ? "Any" : (sheet[8] == "" || sheet[8] == null) ? sheet[8] :  categories.city.find(item => item.label === sheet[8]).id;
            const education =  categories.degree.find(item => item.label === sheet[9]) == undefined ? sheet[9]:  sheet[9] == "Any" ? "Any" : (sheet[9] == "" || sheet[9] == null) ? sheet[9] :  categories.degree.find(item => item.label === sheet[9]).id;
            const occupation = categories.occupation.find(item => item.label === sheet[10]) == undefined ? sheet[10]  : sheet[10] == "Any" ? "Any" : (sheet[10] == "" || sheet[10] == null) ? sheet[10] :  categories.occupation.find(item => item.label === sheet[10]).id;
            const diet_type =  categories.diet_type.find(item => item.label === sheet[11]) == undefined ? sheet[11]:  sheet[11] == "Any" ? "Any" : (sheet[11] == "" || sheet[11] == null) ? sheet[11] :  categories.diet_type.find(item => item.label === sheet[11]).id;
            const family_value = categories.family_values.find(item => item.label === sheet[12]) == undefined ? sheet[12] :  sheet[12] == "Any" ? "Any" : (sheet[12] == "" || sheet[12] == null) ? sheet[12] :  categories.family_values.find(item => item.label === sheet[12]).id;
            const annual_income =    sheet[13] == "Any" ? "Any" : (sheet[13] == "" || sheet[13] == null) ? sheet[13] :  sheet[13];
            const body_type =  categories.body_type.find(item => item.label === sheet[14]) == undefined ? sheet[14] : sheet[14] == "Any" ? "Any" : (sheet[14] == "" || sheet[14] == null) ? sheet[14] :  categories.body_type.find(item => item.label === sheet[14]).id;
            const smoke_type =   categories.smoke_type.find(item => item.label === sheet[15]) == undefined ? sheet[15]: sheet[15] == "Any" ? "Any" : (sheet[15] == "" || sheet[15] == null) ? sheet[15] :  categories.smoke_type.find(item => item.label === sheet[15]).id;
            const drink_type =  categories.drink_type.find(item => item.label === sheet[16]) == undefined ? sheet[16] : sheet[16] == "Any" ? "Any" : (sheet[16] == "" || sheet[16] == null) ? sheet[16] :  categories.drink_type.find(item => item.label === sheet[16]).id;

            newRows.push({"Id":sheet[0] , "age_range":sheet[1] , "height_range":sheet[2] , "marital_status":marital_status , "caste":caste , "subcaste":subcaste , "skintone":skintone ,"state":state ,"city":city , "education":education , "occupation":occupation ,"diet_type":diet_type , "family_value":family_value ,  "annual_income":annual_income ,"body_type":body_type ,"smoke_type":smoke_type ,"drink_type":drink_type , "filter":sheet[17] , "partner_details":sheet[18] , "userId":sheet[19] ,"communityId":sheet[20] ,"profileId":sheet[21] });
          }

        });
        
          
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 32){   // physical lifestyle
    
           rows.map((sheet ,index) => {

          if(index > 0){

            const skintone =  categories.skintone.find(item => matchPronunciationUsingSome(sheet[3] , item.label));
            const bodytype =  categories.body_type.find(item => matchPronunciationUsingSome(sheet[6] , item.label));
            const diettype =  categories.diet_type.find(item => matchPronunciationUsingSome(sheet[9] , item.label));
            const drinktype = categories.drink_type.find(item => matchPronunciationUsingSome(sheet[10] , item.label));
            const smoketype = categories.smoke_type.find(item => matchPronunciationUsingSome(sheet[11] , item.label));

            let skintone_check = skintone ?? false;
            if(skintone_check == false &&  newskintoneRows.some(item => item.skintone.toLowerCase() === sheet[3].toLowerCase()) == false){
              newskintoneRows.push({"skintone": sheet[3]  , "Id":skintoneRows++});
            }

            let bodytype_check = bodytype ?? false;
            if(bodytype_check == false &&  newbodytyperows.some(item => item.bodytype.toLowerCase() === sheet[6].toLowerCase()) == false){
              newbodytyperows.push({"bodytype": sheet[6] , "Id":bodytyperows++});
            }

            let diettype_check = diettype ?? false;
            if(diettype_check == false &&  newDietTypeRows.some(item => item.diet_type.toLowerCase() === sheet[9].toLowerCase()) == false){
              newDietTypeRows.push({"diet_type": sheet[9]  , "Id":DietTypeRows++});
            }

            let drinktype_check = drinktype ?? false;
            if(drinktype_check == false &&  newDrinkTypeRows.some(item => item.drink_type.toLowerCase() === sheet[10].toLowerCase()) == false){
              newDrinkTypeRows.push({"drink_type": sheet[10] , "Id":DrinkTypeRows++});
            }

            let smoketype_check = smoketype ?? false;
            if(smoketype_check == false &&  newSmokeTypeRows.some(item => item.smoke_type.toLowerCase() === sheet[11].toLowerCase()) == false){
              newSmokeTypeRows.push({"smoke_type": sheet[11]  , "Id":SmokeTypeRows++});
            }


          }

          });
        
            addNewSheetWithData(newSheetName , newRows);

            totalRows += newRows.length;
            setProgress(2.78*(indexsheet+1));

        }else if(indexsheet == 33){   // notification_count
      
          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({"Id":sheet[0] , "pic1":sheet[1] , "pic2":sheet[2] , "pic3":sheet[3] , "pic4":sheet[4] , "pic5":sheet[5] , "pic6":sheet[6] , "pic7":sheet[7] , "pic8":sheet[8] , "pic9":sheet[9] , "pic10":sheet[10] ,"isverifypic1":sheet[11] , "isverifypic2":sheet[12] , "isverifypic3":sheet[13] , "isverifypic4":sheet[14] , "isverifypic5":sheet[15] ,"isverifypic6":sheet[16] ,"isverifypic7":sheet[17] ,"isverifypic8":sheet[18] , "userId":sheet[21] ,"communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[23] });
          }
        
          });

            addNewSheetWithData(newSheetName , newRows);

            totalRows += newRows.length;
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 34){   // notification_count
      
          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({"Id":sheet[0] , "id_proof":sheet[1] , "education_proof":sheet[2] , "income_proof":sheet[3] , "is_id_verify":sheet[4] , "is_education_verify":sheet[5] , ".is_income_verify":sheet[6] , "userId":sheet[7] , "communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[9] });
          }
        
          });

            addNewSheetWithData(newSheetName , newRows);

            totalRows += newRows.length;
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 35){   // notification_count
      
          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({ "Id":sheet[0] , "phone_privacy":sheet[1] , "photo_privacy":sheet[2] , "horoscope_privacy":sheet[3] , "userId":sheet[4] , "communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[6] });
          }
        
          });

            addNewSheetWithData(newSheetName , newRows);

            totalRows += newRows.length;
            setProgress(2.78*(indexsheet+1));
            
        }else if(indexsheet == 36){   // registeration
      
          rows.map((sheet ,index) => {
            
          if(index > 0){

            newRows.push({ "Id":sheet[0] , "name": sheet[1],
    "surname": sheet[2],
    "emailid": sheet[3],
    "password": sheet[4],
    "gender": sheet[5],
    "birthdate": convertExcelDateToProperFormat(sheet[6]),
    "mobile": sheet[7],
    "profile_id": sheet[8],
    "user_verify":sheet[9],
    "email_verify":sheet[10],
    "mobile_verify":sheet[11],
    "community_id":  localStorage.getItem("communityIdSet"),
    "community_name": sheet[13],
    "delete_account":sheet[14],
    "delete_account_reason":sheet[15],
    "mobile_reg_token": sheet[16],
    "web_reg_token": sheet[17],
    "joined_date": convertExcelDateToProperFormat(sheet[18]),
     "otp":sheet[19]});

          }
        
          });

            addNewSheetWithData(newSheetName , newRows);

            totalRows += newRows.length;
            setProgress(2.78*(indexsheet+1));
            
        }


       setTotalCount(totalRows);
       
       console.log(categories.length)
      
       return categories;

        });       

          //alert(totalRows);
      });


      const combined = [{ marital_status: newMaritalStatusRows, created_by: newCreatedbyRows, caste: newCasteRows ,subcaste: newSubcasteRows,
        language: newLanguageRows , perm_state : newPermstateRows , perm_city: newPermcityRows , work_state : newWorkStateRows ,work_city : newWorkcityRows
         , degree : newdegreeRows , institute: newiitiimnitRows , family_type : newfamilyTypeRows , family_status: newFamilystatusRows , family_value:newfamilyvalueRows  ,
          house_type: newhousetypeRows , skintone : newskintoneRows , rashi: newRashiRows ,birth_star: newBirthstarRows , body_type: newbodytyperows , drink_type :newDrinkTypeRows ,
          diet_type : newDietTypeRows ,   smoke_type:newSmokeTypeRows ,  occupation:newOccupationRows}];

        console.log(combined);

        setnewResults(combined);

        setIscombined(true);

        
    }else{

      alert('You need the exact file. This is not the one');

    }



      //setProgress(prev => ( 100));
 
    };

    
       
    reader.readAsArrayBuffer(file);




    







  
  
  
    } else{

      alert('Only .xlsx files are allowed.');
    }
  
  };


/*
  useEffect(()=>{

   // if(data_multibasic){
      tot_progress += data_multibasic;
      console.log(data_multibasic);
  //  }
    
   // if(data_multicontact){
      tot_progress += data_multicontact;
      console.log(data_multicontact);
  //  }
    
 //   if(data_multieducation){
      tot_progress += data_multieducation;
      console.log(data_multieducation);
 //   }
    
  //  if(data_multifamily){
      tot_progress += data_multifamily;
      console.log(data_multifamily);
//}
    
   // if(data_multihoroscope){
      tot_progress += data_multihoroscope;
      console.log(data_multihoroscope);
   // }
    
  //  if(data_multilifestyle){
      tot_progress += data_multilifestyle;
      console.log(data_multilifestyle);
  //  }
    
  //  if(data_multinotification){
      tot_progress += data_multinotification;
      console.log(data_multinotification);
  //  }
    
  //  if(data_multioccupation){
      tot_progress += data_multioccupation;
      console.log(data_multioccupation);
   // }
    
  //  if(data_multipartnerprefs){
      tot_progress += data_multipartnerprefs;
      console.log(data_multipartnerprefs);
  //  }
    
  //  if(data_multiphoto){
      tot_progress += data_multiphoto;
      console.log(data_multiphoto);
   // }
  //   if(data_multinotification_count){
      tot_progress += data_multinotification_count;
      console.log(data_multinotification_count);
 //   }
  //   if(data_multiproof){
      tot_progress += data_multiproof;
      console.log(data_multiproof);
  //  }
    
  //  if(data_multisettings){
      tot_progress += data_multisettings;
      console.log(data_multisettings);
  //  }

   console.log(tot_progress+"+++++++-------"+tot_count);

    

  },[data_multibasic ,data_multicontact , data_multieducation , data_multifamily , data_multihoroscope ,data_multilifestyle , data_multinotification ,data_multinotification_count , data_multioccupation , data_multipartnerprefs ,data_multiphoto ,data_multiproof ,data_multisettings]);
*/
  
function casteId(categories , caste1 , newCasteRows) {

  let id = categories.caste.find(caste => caste.label.toLowerCase() === caste1.toLowerCase()) == undefined ? false :  categories.caste.find(caste => caste.label.toLowerCase() === caste1.toLowerCase())["id"];

  if(id == false){
   id = newCasteRows.find(caste => caste.caste.toLowerCase() === caste1.toLowerCase()) == undefined ? false :  newCasteRows.find(caste => caste.caste.toLowerCase() === caste1.toLowerCase())["Id"];
  }

  return id;
}

function workstateId(categories , state1 , newWorkStateRows) {

  let id = categories.state.find(state => state.label.toLowerCase() === state1.toLowerCase()) == undefined ? false :  categories.state.find(state => state.label.toLowerCase() === state1.toLowerCase())["id"];

  if(id == false){
   id = newWorkStateRows.find(state => state.state_rows_w.toLowerCase() === state1.toLowerCase()) == undefined ? false :  newWorkStateRows.find(state => state.state_rows_w.toLowerCase() === state1.toLowerCase())["Id"];
  }

  return id;
}


function PermstateId(categories , state1 , newPermstateRows) {

  let id = categories.state.find(state => state.label.toLowerCase() === state1.toLowerCase()) == undefined ? false :  categories.state.find(state => state.label.toLowerCase() === state1.toLowerCase())["id"];

  if(id == false){
   id = newPermstateRows.find(state => state.state_rows.toLowerCase() === state1.toLowerCase()) == undefined ? false :  newPermstateRows.find(state => state.state_rows.toLowerCase() === state1.toLowerCase())["Id"];
  }

  
  return id;
}



const updateProgress = (newProgress) => {
  setTotalProgressChange((prevProgress) => {
    const updatedProgress = prevProgress + newProgress;
    const percentage = (updatedProgress * 100) / tot_count;
    setProgress(percentage);
    return updatedProgress;
  });
};
  



  useEffect(() => {
   // tot_progress += data_multibasic;
    console.log(data_multibasic);
    updateProgress(data_multibasic);

   // tot_progress += data_multibasic;
  }, [data_multibasic]);


  useEffect(() => {

    updateProgress(data_multicontact);
    console.log(data_multicontact);
    
  }, [data_multicontact]);

  useEffect(() => {

  //  tot_progress += data_multieducation;
  //  setTotalProgressChange(tot_progress);
     updateProgress(data_multieducation);
    console.log(data_multieducation);
    
  }, [data_multieducation]);


  useEffect(() => {

   // tot_progress += data_multifamily;
  //  setTotalProgressChange(tot_progress);
    updateProgress(data_multifamily);
    console.log(data_multifamily);
    
  }, [data_multifamily]);



  useEffect(() => {

   // tot_progress += data_multihoroscope;
   updateProgress(data_multihoroscope);

    console.log(data_multihoroscope);
    
  }, [data_multihoroscope]);



  useEffect(() => {

  //  tot_progress += data_multilifestyle;
  updateProgress(data_multilifestyle);
    console.log(data_multilifestyle);
    
  }, [data_multilifestyle]);


  useEffect(() => {

   // tot_progress += data_multinotification;
   updateProgress(data_multinotification);

    console.log(data_multinotification);
    
  }, [data_multinotification]);


  useEffect(() => {

   // tot_progress += data_multinotification_count;

   updateProgress(data_multinotification_count);
    console.log(data_multinotification_count);
    
  }, [data_multinotification_count]);


  useEffect(() => {

   // tot_progress += data_multioccupation;
   updateProgress(data_multioccupation);

    console.log(data_multioccupation);
    
  }, [data_multioccupation]);


  useEffect(() => {

  //  tot_progress += data_multipartnerprefs;
  
  updateProgress(data_multipartnerprefs);
    console.log(data_multipartnerprefs);
    
  }, [data_multipartnerprefs]);


  useEffect(() => {

   // tot_progress += data_multiphoto;
    updateProgress(data_multiphoto);

    console.log(data_multiphoto);
    
  }, [data_multiphoto]);


  useEffect(() => {

  //  tot_progress += data_multiproof;

     updateProgress(data_multiproof);
    console.log(data_multiproof);
    
  }, [data_multiproof]);


  useEffect(() => {

  //  tot_progress += data_multisettings;
  updateProgress(data_multisettings);

    console.log(data_multisettings);
    
  }, [data_multisettings]);



  useEffect(() => {

    //  tot_progress += data_multisettings;
    updateProgress(data_registeration);
  
      console.log(data_registeration);
      
    }, [data_registeration]);


  const theme = useTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& $notchedOutline': {
              borderColor: 'red', // Change this to your desired outline color
            },
            '&:hover $notchedOutline': {
              borderColor: '#FFC0CB', // Change this to your desired outline color on hover
            },
          },
        },
      },
    },
  });


    const [query, setValue] = useState('100');
    const handleChange = (event) => {
      setValue(event.target.value);
    };


    const[isimoprt ,setisimport] = useState(0);




    const asyncOperation = (indexsheet , rows , newSheetName) => {

      const newRows = [];

      return new Promise((resolve) => {
      
        setCategories((categories) => {

         


          if(indexsheet == 23){   // basic details

          

           rows.map((sheet ,index) => {

          
           

            if(index > 0){

            //  alert(sheet[2] +"____");

              console.log("1()");
             
              const created_by = categories.createdby.find(item => item.label.toLowerCase() === sheet[2].toLowerCase()).id;
              const marital_status = categories.marital_status.find(item => item.label.toLowerCase() === sheet[5].toLowerCase()).id;
              const casteid = categories.caste.find(item => matchPronunciationUsingSome(sheet[7] , item.label)).id
              const subcaste = categories.subcaste.find(item => item.label.toLowerCase() === sheet[8].toLowerCase()).id;
              const ids = sheet[9].split(",").map(label => {
                const result = categories.languages.find(item => item.label.toLowerCase() === label.toLowerCase());
                return result ? result.id : null; // Return null if not found
              });
              const lang_known = ids.join(",");
              const mother_tongue = categories.languages.find(item => item.label.toLowerCase() === sheet[10].toLowerCase()).id;

              newRows.push({"Id":sheet[0] , "fullname":sheet[1] , "created_by":created_by , "dob":convertExcelDateToProperFormat(sheet[3]), "age":sheet[4] , "marital_status":marital_status , "religion":sheet[6] , "caste":casteid ,"subcaste":subcaste , "language_known":lang_known ,  "mother_tongue":mother_tongue , "userId":sheet[11] , "communityId":  localStorage.getItem("communityIdSet") , "profileId":sheet[13] });
              
            }
           
          });

          

        }else if(indexsheet == 24){  // contact details


          console.log("2()");

          rows.map((sheet ,index) => {

            if(index > 0){

              //alert(sheet[14]);
             
              const perm_country = "1";
              const perm_state = categories.state.find(item => item.label.toLowerCase() === sheet[10].toLowerCase()).id;
              const perm_city = categories.city.find(item => item.label.toLowerCase() === sheet[11].toLowerCase()) == undefined ? "0" : categories.city.find(item => item.label === sheet[11]).id;

              const work_country = "1";
              const work_state = categories.state.find(item => item.label.toLowerCase() === sheet[13].toLowerCase()).id;
              const work_city = categories.city.find(item => item.label.toLowerCase() === sheet[14].toLowerCase()) == undefined ? "0"  : categories.city.find(item => item.label === sheet[14]).id;

              newRows.push({"Id":sheet[0] , "mobile_number":sheet[1] , "whatsapp_number":sheet[2] , "permanent_adddress":sheet[3], "emailid":sheet[4] , "alternate_mobile":sheet[5] , "alternate_email":sheet[6] , "working_address":sheet[7] , "contact_time":sheet[8]  ,  "perm_country":perm_country , "perm_state":perm_state , "perm_city":perm_city , "cur_country":"" , "cur_state":"" , "cur_city":"" , "work_country":work_country ,"work_state":work_state ,"work_city":work_city , "location":sheet[15] , "cur_city":"" ,"userId":sheet[16] ,"communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[18]});
              
            }
           
          });

          
          

        }else if(indexsheet == 25){    // educational details


          console.log("3()");

          rows.map((sheet ,index) => {

          if(index > 0){

          // alert(sheet[4]+"----"+sheet[5]);

          let college_name = "";
           
           if(sheet[4] != undefined){
            college_name = categories.iit_iim_nit.find(item => item.label.toLowerCase() === sheet[4].toLowerCase()) ==undefined ? "0" :  categories.iit_iim_nit.find(item => item.label.toLowerCase() === sheet[4].toLowerCase()).id;
           }
            const degree = categories.degree.find(item => item.label.toLowerCase() === sheet[5].toLowerCase()) == undefined ? "0"  : categories.degree.find(item => item.label.toLowerCase() === sheet[5].toLowerCase()).id;
            
            console.log("------------"+sheet[5]+"-------"+categories.degree[categories.degree.length - 1]);
           
            newRows.push({"Id":sheet[0] ,  "is_from_admin_service":sheet[1] , "admin_position_name":sheet[2] ,"is_from_iit_iim_nit":sheet[3] ,  "college_name":college_name , "education_list":degree , "education_detail":sheet[6] ,"userId":sheet[7]  , "communityId": localStorage.getItem("communityIdSet") ,"profileId":sheet[9]});
          }

        });

       

        }else if(indexsheet == 26){        // family details

          console.log("4()");
          
          rows.map((sheet ,index) => {

           // alert(sheet[1]);


          if(index > 0){
           
            const family_value = categories.family_values.find(item => item.label.toLowerCase() === sheet[1].toLowerCase()).id;
            const family_type = categories.family_type.find(item => item.label.toLowerCase() === sheet[2].toLowerCase()).id;
            const family_status = categories.family_status.find(item => item.label.toLowerCase() === sheet[3].toLowerCase()) == undefined ? "0" : categories.family_status.find(item => item.label === sheet[3]).id;

            const house_type = categories.house_type.find(item => item.label.toLowerCase() === sheet[13].toLowerCase()) == undefined ? "0" : categories.house_type.find(item => item.label === sheet[13]).id;

           
            newRows.push({"Id":sheet[0] ,"family_value":family_value , "family_type":family_type , "family_status":family_status ,  "no_brother":sheet[4]  , "no_sister":sheet[5] , "married_brother":sheet[6]  , "married_sister":sheet[7] ,"father_name":sheet[8]  ,"mother_name":sheet[9] ,"father_occupation":sheet[10] , "mother_occupation":sheet[11] , "house_owned":sheet[12] ,"house_type":house_type , "parents_stay_options":sheet[14] ,"detail_family":sheet[15] , "userId":sheet[16] , "communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[18]});
          }

          });
        
          

        }else if(indexsheet == 27){            // horoscope

          console.log("5()");

           rows.map((sheet ,index) => {

           

          if(index > 0){

           // alert(sheet[5]+"------"+sheet[7]);
           
            const rashi = categories.rashi.find(item => item.label.toLowerCase() === sheet[1].toLowerCase()).id;
            const birth_star = categories.birth_star.find(item => item.label.toLowerCase() === sheet[2].toLowerCase()).id;
           
            let fomattedDate = "";

            if(isValidDate(sheet[5]) == false){
              const jsondate = excelDateToJSDate(sheet[5]);
              fomattedDate = formatDateToDDMMYYYY(jsondate);  
            }else{
              fomattedDate = sheet[5];
            }
           
            newRows.push({"Id":sheet[0] , "rashi":rashi ,"birth_star":birth_star , "gotra":sheet[3] ,"believe_horoscope":sheet[4] , "birth_date":fomattedDate ,  "birth_place":sheet[6], "birth_time":convertExcelTime(sheet[7]) , "birth_country":"", "horoscope_doc":""  , "birth_location":sheet[8] , "timezone":sheet[9] , "userId":sheet[10] ,"communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[12] ,"is_mangalik":sheet[13] });
          }

        });
        
          

        }else if(indexsheet == 28){     // occupation

          console.log("6()");

          rows.map((sheet ,index) => {

          if(index > 0){
           
            const occupation_list = categories.occupation.find(item => item.label.toLowerCase() === sheet[1].toLowerCase()).id;
           
            newRows.push({"Id":sheet[0] , "occupation_list":occupation_list , "occupation_detail":sheet[2] , "annual_income":sheet[3] ,"employment_type":sheet[4] , "office_address":sheet[5] , "userId":sheet[6], "communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[8]});
          }

        });
        
          
            
        }else if(indexsheet == 29){   //notification

          console.log("7()");

          rows.map((sheet ,index) => {

          if(index > 0){
           
            newRows.push({ "Id":sheet[0] , "notifi_type":sheet[1] , "message":sheet[2] , "sender_type":sheet[3] ,"sender_id":sheet[4] , "reciever_id":sheet[5] , "communityId": localStorage.getItem("communityIdSet") });
          }

        });
        
          
            
        }else if(indexsheet == 30){   // notification_count
      
          console.log("8()");

          rows.map((sheet ,index) => {
            
          if(index > 0){
            newRows.push({"Id":sheet[0] , "notification_id":sheet[1] , "user_id":sheet[2] , "isread":sheet[3] });
          }
        
          });

            
            
        }else if(indexsheet == 31){  // partner preference

          console.log("9()");

          rows.map((sheet ,index) => {

          if(index > 0){

            //alert(sheet[3]);

            const marital_status = categories.marital_status.find(item => item.label === sheet[3]) == undefined ? sheet[3] : categories.marital_status.find(item => item.label === sheet[3]).id;
            const caste  =  categories.caste.find(item => item.label === sheet[4]) == undefined ? sheet[4] : sheet[4] == "Any" ? "Any" : (sheet[4] == "" || sheet[4] == null) ? sheet[4] : categories.caste.find(item => item.label === sheet[4]).id;
            const subcaste  =  categories.subcaste.find(item => item.label === sheet[5]) == undefined ? sheet[5] :  sheet[5] == "Any" ? "Any" : (sheet[5] == "" || sheet[5] == null) ? sheet[5] : categories.subcaste.find(item => item.label === sheet[5]).id;
            const skintone =  categories.skintone.find(item => item.label === sheet[6]) == undefined ? sheet[6] :   sheet[6] == "Any" ? "Any" : (sheet[6] == "" || sheet[6] == null) ? sheet[6] :  categories.skintone.find(item => item.label === sheet[6]).id;
            const state =  categories.state.find(item => item.label === sheet[7]) == undefined ? sheet[7] :  sheet[7] == "Any" ? "Any" : (sheet[7] == "" || sheet[7] == null) ? sheet[7] :  categories.state.find(item => item.label === sheet[7]).id;
            const city =  categories.city.find(item => item.label === sheet[8]) == undefined ? sheet[8] : sheet[8] == "Any" ? "Any" : (sheet[8] == "" || sheet[8] == null) ? sheet[8] :  categories.city.find(item => item.label === sheet[8]).id;
            const education =  categories.degree.find(item => item.label === sheet[9]) == undefined ? sheet[9]:  sheet[9] == "Any" ? "Any" : (sheet[9] == "" || sheet[9] == null) ? sheet[9] :  categories.degree.find(item => item.label === sheet[9]).id;
            const occupation = categories.occupation.find(item => item.label === sheet[10]) == undefined ? sheet[10]  : sheet[10] == "Any" ? "Any" : (sheet[10] == "" || sheet[10] == null) ? sheet[10] :  categories.occupation.find(item => item.label === sheet[10]).id;
            const diet_type =  categories.diet_type.find(item => item.label === sheet[11]) == undefined ? sheet[11]:  sheet[11] == "Any" ? "Any" : (sheet[11] == "" || sheet[11] == null) ? sheet[11] :  categories.diet_type.find(item => item.label === sheet[11]).id;
            const family_value = categories.family_values.find(item => item.label === sheet[12]) == undefined ? sheet[12] :  sheet[12] == "Any" ? "Any" : (sheet[12] == "" || sheet[12] == null) ? sheet[12] :  categories.family_values.find(item => item.label === sheet[12]).id;
            const annual_income =    sheet[13] == "Any" ? "Any" : (sheet[13] == "" || sheet[13] == null) ? sheet[13] :  sheet[13];
            const body_type =  categories.body_type.find(item => item.label === sheet[14]) == undefined ? sheet[14] : sheet[14] == "Any" ? "Any" : (sheet[14] == "" || sheet[14] == null) ? sheet[14] :  categories.body_type.find(item => item.label === sheet[14]).id;
            const smoke_type =   categories.smoke_type.find(item => item.label === sheet[15]) == undefined ? sheet[15]: sheet[15] == "Any" ? "Any" : (sheet[15] == "" || sheet[15] == null) ? sheet[15] :  categories.smoke_type.find(item => item.label === sheet[15]).id;
            const drink_type =  categories.drink_type.find(item => item.label === sheet[16]) == undefined ? sheet[16] : sheet[16] == "Any" ? "Any" : (sheet[16] == "" || sheet[16] == null) ? sheet[16] :  categories.drink_type.find(item => item.label === sheet[16]).id;

            newRows.push({"Id":sheet[0] , "age_range":sheet[1] , "height_range":sheet[2] , "marital_status":marital_status , "caste":caste , "subcaste":subcaste , "skintone":skintone ,"state":state ,"city":city , "education":education , "occupation":occupation ,"diet_type":diet_type , "family_value":family_value ,  "annual_income":annual_income ,"body_type":body_type ,"smoke_type":smoke_type ,"drink_type":drink_type , "filter":sheet[17] , "partner_details":sheet[18] , "userId":sheet[19] ,"communityId":sheet[20] ,"profileId":sheet[21] });
          }

        });
        
           
            
        }else if(indexsheet == 32){   // physical lifestyle
    

          console.log("10()");
           rows.map((sheet ,index) => {

          if(index > 0){

            const skintone =  categories.skintone.find(item => item.label.toLowerCase() ===  sheet[3].toLowerCase()).id;
            const bodytype =  categories.body_type.find(item => item.label.toLowerCase() === sheet[6].toLowerCase()).id;
            const diettype =  categories.diet_type.find(item => item.label.toLowerCase() === sheet[9].toLowerCase()).id;
            const drinktype = categories.drink_type.find(item => item.label.toLowerCase() === sheet[10].toLowerCase()).id;
            const smoketype = categories.smoke_type.find(item => item.label.toLowerCase() === sheet[11].toLowerCase()).id;

            newRows.push({"Id":sheet[0] , "weight":sheet[1] , "height":sheet[2] , "skintone":skintone , "blood_group":sheet[4] , "fitness":sheet[5] ,"body_type":bodytype , "is_handicap":sheet[7] , "handicap_detail":sheet[8] , "diet_type":diettype , "drink_type":drinktype , "smoke_type":smoketype , "extra_detail_physic":sheet[12], "userId":sheet[13] , "communityId": localStorage.getItem("communityIdSet") ,"profileId":sheet[15] });
          }

          });
        
          

        }else if(indexsheet == 33){   // notification_count
      
          console.log("11()");

          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({"Id":sheet[0] , "pic1":sheet[1] , "pic2":sheet[2] , "pic3":sheet[3] , "pic4":sheet[4] , "pic5":sheet[5] , "pic6":sheet[6] , "pic7":sheet[7] , "pic8":sheet[8] , "pic9":sheet[9] , "pic10":sheet[10] ,"isverifypic1":sheet[11] , "isverifypic2":sheet[12] , "isverifypic3":sheet[13] , "isverifypic4":sheet[14] , "isverifypic5":sheet[15] ,"isverifypic6":sheet[16] ,"isverifypic7":sheet[17] ,"isverifypic8":sheet[18] , "userId":sheet[21] ,"communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[23] });
          }
        
          });

           
            
      
          }else if(indexsheet == 34){   // notification_count
      
            console.log("12()");

          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({"Id":sheet[0] , "id_proof":sheet[1] , "education_proof":sheet[2] , "income_proof":sheet[3] , "is_id_verify":sheet[4] , "is_education_verify":sheet[5] , ".is_income_verify":sheet[6] , "userId":sheet[7] , "communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[9] });
          }
        
          });

           
            
        }else if(indexsheet == 35){   // notification_count
      
          console.log("13()");

          rows.map((sheet ,index) => {
            
          if(index > 0){
           
            newRows.push({ "Id":sheet[0] , "phone_privacy":sheet[1] , "photo_privacy":sheet[2] , "horoscope_privacy":sheet[3] , "userId":sheet[4] , "communityId": localStorage.getItem("communityIdSet") , "profileId":sheet[6] });
          }
        
          });

           
            
        }else if(indexsheet == 36){   // registeration
      

          console.log("14()");
          rows.forEach((sheet ,index) => {
            
          if(index > 0){

            newRows.push({ "Id":sheet[0] , "name": sheet[1],
    "surname": sheet[2],
    "emailid": sheet[3],
    "password": sheet[4],
    "gender": sheet[5],
    "birthdate": convertExcelDateToProperFormat(sheet[6]),
    "mobile": sheet[7],
    "profile_id": sheet[8],
    "user_verify":sheet[9],
    "email_verify":sheet[10],
    "mobile_verify":sheet[11],
    "community_id":  localStorage.getItem("communityIdSet"),
    "community_name": sheet[13],
    "delete_account":sheet[14],
    "delete_account_reason":sheet[15],
    "mobile_reg_token": sheet[16],
    "web_reg_token": sheet[17],
    "joined_date": convertExcelDateToProperFormat(sheet[18]),
     "otp": sheet[19]});

          }
        
          });

            
        }



       setTotalCount(totalRows);
       
       resolve(newRows);
      
          
       return categories;
        });        

      
           
      });

    };



    const processMultipleRowsSequentially = async () => {
      const allNewRows = [];
    
      for (const [indexsheet, sheetName] of sheetNames.entries()) {


        const workbook = XLSX.read(binarystr , { type: 'binary' });
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet , { header: 1 });
        
        const newSheetName =  sheetName;
        
        if(indexsheet >= 23){

        const newRow = await asyncOperation(indexsheet , rows , newSheetName);
        allNewRows.push(newRow);

        }

      }
    
      return allNewRows;
    };



    const saveFile = (url, filename) => {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "file-name";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }


    const handleDownload = async () => {
      const fileURL =  IMAGE_URL+"uploads/biodata1.xlsx"; 
      const fileName = 'excelsample.xlsx'; 

      /*fetch(fileURL)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, fileName);
      })
      .catch(error => console.error('Error downloading file:', error));
      */


      try {
        const response = await fetch(fileURL, {
          headers: {
            Accept:
              "application/json, text/plain,application/zip, image/png, image/jpeg, image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        saveFile(blobUrl, fileName);
        URL.revokeObjectURL(blobUrl);
      } catch (err) {
        console.error("Error in fetching and downloading file:", err);
      }

    };

    
  

    const handleImport = async() => {

  
      /*
    sheetNames.map( async (sheetName , indexsheet) => {
        // Get worksheet
        const workbook = XLSX.read(binarystr , { type: 'binary' });
        const worksheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet , { header: 1 });
        
        const newSheetName =  sheetName;
        

       

         
          

          //alert(totalRows);
        

      });
   */


      if(newResults.length > 0){



     
   let count = 0;
   processMultipleRowsSequentially()
  .then(allNewRows => {

    setProgress(0);

    console.log("987654");


    allNewRows.forEach( (values ,index) => {

      addNewSheetWithData1((index+1).toString() , values);

    

    });

    console.log("()()()()()()");

    if(count == 0){

      count = count + 1;
    Promise.all([
    executeBatchForFunction1(INSERT_MULTI_BASICDETAIL , 1),
    executeBatchForFunction1(INSERT_MULTI_CONTACTDETAIL , 2),
    executeBatchForFunction1(INSERT_MULTI_EDUDETAIL, 3),
    executeBatchForFunction1(INSERT_MULTI_FAMILYDETAIL , 4),
    executeBatchForFunction1(INSERT_MULTI_HOROSCOPEDETAIL, 5),
    executeBatchForFunction1(INSERT_MULTI_OCCUPATIONDETAIL, 6),
    executeBatchForFunction1(INSERT_MULTI_NOTIFICATION, 7),
    executeBatchForFunction1(INSERT_MULTI_NOTIFICATIONCOUNT, 8),
    executeBatchForFunction1(INSERT_MULTI_PARTNER_PREFERENCE  , 9),
    executeBatchForFunction1(INSERT_MULTI_LIFESTYLEDETAIL, 10),
    executeBatchForFunction1(INSERT_MULTI_PHOTO, 11),
    executeBatchForFunction1(INSERT_MULTI_PROOF, 12),
    executeBatchForFunction1(INSERT_MULTI_SETTINGS, 13),
    executeBatchForFunction1(INSERT_MULTI_REGISTERATION, 14)
    ]).then(funct => {

      setProgress(100);

    });

  }

  

    
  })
  .catch(error => {
    console.error('Error during processing:', error);
  });
      
}else{

  alert("Please upload file first and wait for completion of processing");

}


    }

   
  


    let tot_progress = 0;

    const executeBatchForFunction =  (insertFunction , index) => {

      setData((batchdata) => {

        console.log(index);
        console.log(batchdata[index]);

        const totalDataSize = batchdata[index].rowCount;
        let startIndex = 0;
        let incre = 0;

       // alert(totalDataSize);
         //  alert(batchdata[index].rows[0]["Id"]);

        while (startIndex < totalDataSize) {

          //alert(startIndex+"----"+totalDataSize);

           if( (totalDataSize-incre) ==  totalDataSize%query ){
            //const endIndex = Math.min(totalDataSize - incre , totalDataSize);
            const batch = batchdata[index].rows.slice(startIndex , totalDataSize);

           // alert("batch lenmgth : "+batch);

            insertFunction(batch);

            startIndex = totalDataSize;

            console.log(batch.length+"=");
            

           }else{


            const endIndex = Math.min(startIndex + query , totalDataSize);
            const batch = batchdata[index].rows.slice(startIndex , endIndex);

            insertFunction(batch);

            startIndex = endIndex;

            console.log(batch.length+"=");

            incre += 100;

            
           }


          

            
        }


        return batchdata;
      });


    };

    const executedIndices = new Set();
    const executeBatchForFunction1 =  (insertFunction1 , index) => {

      



        console.log(index+"===="+index);

      setData1((batchdata1) => {


        
      if (!executedIndices.has(index)) {
        executedIndices.add(index);

        console.log(index);
        console.log(batchdata1[index]);

        const totalDataSize = batchdata1[index].rowCount1;
        let startIndex = 0;
        let incre = 0;

        console.log(totalDataSize+"------- total data size");

       // alert(totalDataSize);
         //  alert(batchdata[index].rows[0]["Id"]);

        while (startIndex < totalDataSize) {

          //alert(startIndex+"----" size+totalDataSize);

           if( (totalDataSize-incre) ==  totalDataSize%query ){
            //const endIndex = Math.min(totalDataSize - incre , totalDataSize);
            const batch = batchdata1[index].rows1.slice(startIndex , totalDataSize);

           // alert("batch lenmgth : "+batch);

            insertFunction1(batch);

            startIndex = totalDataSize;

            console.log(batch.length+"=");
            

           }else{


            const endIndex = Math.min(startIndex + query , totalDataSize);
            const batch = batchdata1[index].rows1.slice(startIndex , endIndex);

            insertFunction1(batch);

            startIndex = endIndex;

            console.log(batch.length+"=");

            incre += 100;

            
           }


          

            
        }

      }


        return batchdata1;
      });

    

      
    };



  

    const handleCombined  = () => {
      setIscombined(true);
    }

    const handleCloseModal2 = () => {
      setIscombined(false);
    }
  
    const handlexecute = () =>{

      master_table_fetch_all_clear();

      setMasterTable(2);
     
    }



  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  
        
      return (
        <div>
          {/* Modal Popup */}
          <Modal
            open={open}
            onClose={(event, reason) => {
              // Prevent closing the modal on click outside or pressing Escape key
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleClose();
              }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

            >

            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>


            <Grid container spacing={2}>

            <Grid key={5} item xs={6}>
  <ThemeProvider theme={theme}>
  <TextField
      label="Enter Batch Size"
      value={query}
      onChange={handleChange}
      variant="outlined"

      fullWidth
    />
    </ThemeProvider>
    </Grid>
    <Grid key={5} item xs={6}></Grid>
    <Grid key={5} item xs={12}>
            <div {...getRootProps({ className: 'dropzone' })} style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <p>Drag & drop an Excel file here, or click to select one</p>
      </div>

      </Grid>

      <Grid item xs={12}>
      <ProgressBar progress={progress} />
      </Grid>

      <Grid item xs={12}>
      <Button type="button" style={{
                                justifyContent: "center", alignContent: "center",
                                background: "#ffc0cb", width: "100%", color: "black", fontWeight: "bold"
                            }} onClick={handleDownload}>Download Excel File Sample</Button>
      </Grid>

      <Grid item xs={6}>
      <Button type="button" style={{
                                justifyContent: "center", alignContent: "center",
                                background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                            }} onClick={handleImport}>Import Now</Button>
      </Grid>

    <Grid item xs={6}>
    <Button type="button" style={{
                                justifyContent: "center", alignContent: "center",
                                background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                            }} onClick={()=>handleClose()}>Cancel</Button>
    </Grid>

      </Grid>

           </Box>

           </Modal>

           { IsCombined == true ? <ListValues open2={true} handleClose={handleCloseModal2}  combined={newResults} categories={categories}  executeInsert={handlexecute}/> : ""}

           </div>

      )

}
 
export default ImportExcel;
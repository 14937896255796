import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/blogsReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';

const blogsContext = createContext();

const initialState = {
  loading_blogs: true,
  error: null,
  data_insert_blogs: [],
  list_blogs_admin:[],
  data_update_community:[]
};

let API = `${BASE_URL}`;


export const BlogsProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const add_blogs = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/blog_insert` , article);
      

      dispatch({ type: 'ADD_BLOGS_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'ADD_BLOGS_ERROR', payload: error });
    }
  };



  const blogs_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/blog_fetchByCommunityId` , article);
      
      dispatch({ type: 'BLOGS_LIST_SUCCESS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BLOGS_LIST_ERROR', payload: error });
    }
  };

 
  return <blogsContext.Provider value={{ ...state , dispatch , add_blogs , blogs_select}}>{children}</blogsContext.Provider>;
};

export const useBlogsContextAdmin = () => useContext(blogsContext);
const settingsReducer = (state, action) => {
    switch (action.type) {

      case 'INSERT_SETTINGS':
        return {
          ...state,
          insert_list: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_SETTINGS_ERROR':
        return {
          ...state,
          insert_list: [],
          loading: false,
          error: action.payload,
        };


        case 'UPDATE_SETTINGS':
            return {
              ...state,
              update_list: action.payload,
              loading: false,
              error: null,
            };
          case 'UPDATE_SETTINGS_ERROR':
            return {
              ...state,
              update_list: [],
              loading: false,
              error: action.payload,
            };

       
            case 'FETCH_SETTINGS':
            return {
              ...state,
              settings_list: action.payload,
              loading: false,
              error: null,
            };
          case 'FETCH_SETTINGS_ERROR':
            return {
              ...state,
              settings_list: [],
              loading: false,
              error: action.payload,
            };




            case 'DELETE_ACCOUNT':
              return {
                ...state,
                delete_account_response: action.payload,
                loading: false,
                error: null,
              };
            case 'DELETE_ACCOUNT_ERROR':
              return {
                ...state,
                delete_account_response: [],
                loading: false,
                error: action.payload,
              };

   
      default:
        return state;
    }
  };

  export default settingsReducer;
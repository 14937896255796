import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const MsgConfirmClose = ({ isOpen ,title , content , handleClose , closeText , cancelText , handleCancel }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {closeText}
        </Button>
        <Button onClick={handleCancel} color="primary">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MsgConfirmClose;
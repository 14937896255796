import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../styles/FaqsPage.css'; // Import the CSS file
import { useAboutusContext } from '../../contexts/aboutusContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { COMMUNITY_ID, TRANSLATED_LANG } from '../../common_utils/constants';



const FaqsPage = ({passPath}) => {

  const { t } = useTranslation();
  const{ fetchFaqs , faqs} = useAboutusContext();
   
   useEffect(()=>{

    passPath(t('faqs'));

    if(faqs.length <= 0){
      fetchFaqs({
        "type":"faqs",   //about , contact , chief_members , privacy_policy , refund_policy , faqs
        "communityId":localStorage.getItem(COMMUNITY_ID),
         "original": "en",
         "translate": [localStorage.getItem(TRANSLATED_LANG)]
       });
    }

   },[faqs]);


  return (
    <div style={{position: "absolute", top: "165px" , width:"100%"}}>

    {faqs.length > 0 ?
    <div className="faqContainer">
      <h2 className="faqHeading">Frequently Asked Questions</h2>

      {Object.values(faqs[0][0]).map((faq, index) => (
        <Accordion key={index} className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#E3256B' }} />}
            className="accordionSummary"
          >
            <Typography variant="h6" style={{ color: '#E3256B' }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordionDetails">
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>:""

      }
    </div>
  );
};

export default FaqsPage;

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { borderRadius, height, styled } from '@mui/system';
import { Autocomplete, MenuItem, Paper, Radio } from '@mui/material';

const CssTextField = styled(TextField)(({ marginTop, borderColor, hoverBorderColor, focusedBorderColor , size , borderWidth , height}) => ({
   
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      overflow: 'hidden', // Ensures text is not overflowing
      textOverflow: 'ellipsis', //
      '& fieldset': {
        borderColor,
      },
      '&:hover fieldset': {
        borderColor: hoverBorderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: focusedBorderColor,
      },'& .MuiOutlinedInput-notchedOutline': {
        borderWidth: borderWidth,
        height:height // Set border width
      },
    },
  }));

 

 function StyledTextField({ label, onChange  ,  value, error, helperText , marginTop , bordercolor , hovercolor , focusedcolor , width , height ,borderWidth , type , rows ,onFocus , onBlur ,onInput}) {


  return (
    <CssTextField
    sx={{ borderRadius: '30', width:width , height:height }}
        label={label}
        value={value}
        error={error}
        onChange={onChange}
        helperText={error ? helperText : ''}
        marginTop={marginTop}
        borderColor={bordercolor}
        hoverBorderColor={hovercolor}
        focusedBorderColor={focusedcolor}
        height={height}
        borderWidth={borderWidth}
        type={type}
        fullWidth
        multiline
        onFocus={onFocus}
        onBlur={onBlur}
        rows={rows}
        onInput={onInput}
      />
  );
}






const DropdownCssField = styled(TextField)(({  borderColor, hoverBorderColor, focusedBorderColor ,iconcolor , borderWidth , height}) => ({

  
  minWidth:"100%",
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: borderColor,
    },
    '&:hover fieldset': {
      borderColor: hoverBorderColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: focusedBorderColor,
    },
    '& .MuiOutlinedInput-root .MuiSelect-icon': {
      color: iconcolor, // Arrow icon color
    },'& .MuiOutlinedInput-notchedOutline': {
      borderWidth: borderWidth,
      height:height // Set border width
    },
  
    
  },
}));


const DropdownSelect = ({ value, onChange, label, error, helperText ,borderColor , hoverBorderColor , focusedBorderColor , iconcolor , size , options , borderWidth , height , required , label2 , onMouseDown }) => {
  return (
    <DropdownCssField
       select
       required={required}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      variant="outlined"
      margin="normal"
      borderColor={borderColor}
      hoverBorderColor={hoverBorderColor}
      focusedBorderColor={focusedBorderColor}
      iconcolor={iconcolor}
      borderWidth={borderWidth}
      onMouseDown={onMouseDown}
      size={size}
      hiddenLabel={label2}
      height={height}
      
      
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </DropdownCssField>
  );
};


const MultiselectDropdown = ({ value, label, error, helperText ,borderColor , hoverBorderColor , focusedBorderColor , iconcolor , size , options , borderWidth , height , onChange , initialSelectedValues}) => {
  
  
  
  return (
    <Autocomplete
      multiple
      id="multi-select-dropdown"
      options={options}
      borderColor={borderColor}
      focusedBorderColor={focusedBorderColor}
      hoverBorderColor={hoverBorderColor}
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      value={initialSelectedValues}
      width={"200px"}
      PaperComponent={({ children }) => (
        <Paper style={{  backgroundColor:"#F8F8F8"}}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label}  marginTop={"100px"}  />
      )}
    />
  );
};


const RadioButton = <Radio
  
  sx={{
    color: "#D74894",
    '&.Mui-checked': {
      color: "#E3256B",
    },
  }}
/>



export {
  StyledTextField,
  DropdownSelect,
  CssTextField,
  MultiselectDropdown,
  RadioButton
  };
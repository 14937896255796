import { useEffect } from "react";
import { useRolesContextAdmin } from "../../contexts_admin/userRoleContext";
import RotatingHeart from "../../../common_utils/loading_animator";
import StylishLabel from "../../component/StylishLabel";
import { Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme ,Modal ,MenuItem , Button } from "@mui/material";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import CommunityModal from "../../component/CommunityModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";




function ListRoles(){

       const { roles_select , data_roles_select , loading_user_roles ,update_all_staff ,data_update_allstaff } = useRolesContextAdmin();

       const [load, setLoad] = useState(false);
       const [openCommunity , setopenCommunity] = useState(false);
       const [communityId, setCommunityId] = useState(1);
       const [currentPage, setCurrentPage] = useState(1);
       const [open , setopen] = useState(false);
       const [Role3 , setRole3] = useState("");
       const [RoleId , setRoleId] = useState("");
       const [isfirst , setFirst] = useState(false);
   

    let role = localStorage.getItem(ROLE);


    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });


      const handleClose = () => {
        setopen(false);
      }


      const [userData, setUserData] = useState({
        name: '',
        email: '',
        phone: '',
        userId: '',
        password: '',
        role: ''
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const handleChangeRoles3 = (event) => {
        setRole3(event.target.value);
        //   setRoleLabel3(event.target.label);
        //alert(event.target.label);
     }


      useEffect(()=>{

        if(data_update_allstaff.affectedRows >= 1 &&  isfirst == true){

          setopen(false);
          roles_select({
            "communityId":communityId,
            "offset":"0",
          });


        }

        if(data_roles_select.length > 0 || loading_user_roles == false){

          setLoad(true);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

           roles_select({
                "communityId":communityId_admin,
                "offset":"0",
              });

        }
       
    },[data_roles_select , data_update_allstaff]);


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }


      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        roles_select({
            "communityId":communityId_admin,
            "offset":"0",
          });
  
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
    
        roles_select({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10)
          });
  
    }

    const navigate = useNavigate();
    const addRoles = () => {

      navigate("/add_roles/"+communityId);

    }

    const editDetails = (Id , UserDetails) => {


      setFirst(false);
      const itemget = data_roles_select[0].find(item => item.role === UserDetails.role);

      setUserData({
        name: UserDetails.name,
        email: UserDetails.email,
        phone: UserDetails.phone,
        userId: UserDetails.userId,
        password: UserDetails.pass,
        role: ''
      });

      
      setRole3(itemget.Id);
      setRoleId(Id);
      setopen(true);

    }

    const handleSaveStaffDetails = () => {

      const item = data_roles_select[0].find(item => item.Id === Role3);

      update_all_staff({
        "name":userData.name,
        "email":userData.email,
        "phone":userData.phone,
        "userId":userData.userId,
        "password":userData.password,
        "role": item.role,
        "Id":RoleId 
     });
 
     setFirst(true);


    }


     
    return (<>
    
        {  load == false ?  <RotatingHeart/> : 
           
           <div  style={{backgroundColor:"#FAFBFD"}}>
    
            { role == "developer" ? <StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
    
            <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "5px" : "5px" , width: "100%" , paddingRight:"15px"   }}>
            
    
        <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>
    
    
    
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>User & Roles</Typography>
          <Typography  variant="h6" align="left" width="300px" style={{position:"absolute" , right:"15px" , marginTop:"-32px" ,paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}} onClick={addRoles} >Click To Add User & Roles</Typography>
          <TableRow style={{width:"100%"}}>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Role</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Name</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Email</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Phone</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>userId</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Password</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Permissions Assigned</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Update Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data_roles_select.length > 0 && data_roles_select[0].map((free_members, index) => (
            <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{(index+1)}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.role}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.name}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.email}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.phone}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.userId}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.pass}</TableCell>
                   <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.permission.split(',').map((status, index) => (
                      <Chip key={index} label={status.trim()} color={"primary"} style={{ marginRight: '4px' , marginTop:"6px" }} />
                    ))}</TableCell>
              <TableCell><span
          style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={()=>editDetails(free_members.Id , free_members)}>
          {"Update Details"}
        </span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={data_roles_select.length > 0 && Math.ceil(data_roles_select[1][0]["total_row_count"] / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="medium"
                />
            </div>
    
    
    
    
    
            </Grid>
    
            </Grid>
        
    
            {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
            <Modal open={open} onClose={handleClose}>

            <Grid container  spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>


<Grid item xs={12}>
<Typography  variant="h6" align="left" width="300px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" }}>Update Details</Typography>
</Grid>

<Grid  item xs={12}>
  <TextField
    label="Enter Name"
    variant="outlined"
    value={userData.name}
    name="name"
    fullWidth
    onChange={handleInputChange}
    style={{ marginRight: '10px', flex: 1 , marginTop:"10px"}}/>
</Grid>

<Grid  item xs={12}>
  <TextField
    label="Enter Email"
    variant="outlined"
    name="email"
    value={userData.email}
    fullWidth
    onChange={handleInputChange}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>

<Grid  item xs={12}>
  <TextField
    label="Enter Contact"
    variant="outlined"
    name="phone"
    value={userData.phone}
    fullWidth
    onChange={handleInputChange}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>



<Grid  item xs={12}>
  <TextField
    label="Enter UserId"
    variant="outlined"
    value={userData.userId}
    name="userId"
    fullWidth
    onChange={handleInputChange}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>


<Grid  item xs={12}>
  <TextField
    label="Enter Password"
    variant="outlined"
    value={userData.password}
    name="password"
    fullWidth
    onChange={handleInputChange}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>



<Grid  item xs={12}>
<TextField
select
label="Select Roles"
variant="outlined"
value={Role3}
name="role"
onChange= {handleChangeRoles3}
fullWidth
>
{data_roles_select[0].map((option) => (
    <MenuItem key={option.Id} value={option.Id}>
      {option.role}
    </MenuItem>
  ))}
</TextField>
</Grid>

<Grid item xs={12}>
  <Button
    variant="contained"
    color="primary"
    fullWidth
    onClick={handleSaveStaffDetails}>
    Update Staff Details
  </Button>

  </Grid>

</Grid>


            </Modal>
          
        </div>
    
        }
        
        </>);


}

export default ListRoles;
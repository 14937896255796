
const reducer = (state, action) => {
    switch (action.type) {

      case 'SEND_EMAIL':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          statusCode: action.payload,
          error: null
        };
      case 'SEND_EMAIL_ERROR':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          statusCode: null,
          error:  action.payload
        };
        case 'CLEAR_EMAIL_SEND':
          return {
            ...state,
            loading_emailmarketing_admin: false,
            statusCode: null,
            error: null
          };

      case 'AGERANGE_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_user_email_admin: action.payload,
          error: null
        };
      case 'MARITALSTATUSEMAIL_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_user_email_admin: action.payload,
          error:  null 
        };
      case 'EMAILBYMARITAL_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_emailbymaritalstatus_admin: action.payload,
          error: null 
        };
      case 'EMAIL_WELCOME_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_email_welcomeuser: action.payload,
          error: null 
        }
      case 'INSERT_EMAILTEMPLATE_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_insert_template: action.payload,
          error:  null 
        };
      case 'UPDATE_EMAILTEMPLATE_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_update_template: action.payload,
          error:  null 
        };
      case 'EMAIL_TEMPLATE_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_emailtemplate_fetch: action.payload,
          error:  null 
        };
      case 'EMAIL_TEMPLATE_EVENT_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_emailtemplate_event_fetch: action.payload,
          error: null 
        };
      case 'EMAIL_TEMPLATE_BLOG_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_emailtemplate_blog_fetch: action.payload,
          error:  null 
        };
      case 'INSERTEMAIL_SENT_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_insert_emailsent: action.payload,
          error: null
        };
      case 'EMAILSENT_FETCH_SUCCESS':
        return {
          ...state,
          loading_emailmarketing_admin: false,
          data_emailsent_fetch: action.payload,
          error: null 
        };
      case 'AGERANGE_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_agerange_email_admin:[],
            error:  action.payload
          };
      case 'MARITALSTATUSEMAIL_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_maritalstatus_email_admin: [],
            error: action.payload
          };
      case 'EMAILBYMARITAL_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_emailbymaritalstatus_admin: [],
            error:  action.payload
          };
      case 'EMAIL_WELCOME_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_email_welcomeuser:[],
            error: action.payload 
          }
      case 'INSERT_EMAILTEMPLATE_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_insert_template: [],
            error: action.payload
          };
      case 'UPDATE_EMAILTEMPLATE_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_update_template: [],
            error:  action.payload 
          };
      case 'EMAIL_TEMPLATE_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_emailtemplate_fetch: [],
            error: action.payload
          };
      case 'EMAIL_TEMPLATE_EVENT_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_emailtemplate_event_fetch:[],
            error: action.payload 
          };
      case 'EMAIL_TEMPLATE_BLOG_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_emailtemplate_blog_fetch: [],
            error: action.payload 
          };
      case 'INSERTEMAIL_SENT_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_insert_emailsent: [],
            error: action.payload
          };
      case 'EMAILSENT_FETCH_ERROR':
        return {
            ...state,
            loading_emailmarketing_admin: false,
            data_emailsent_fetch: [],
            error: action.payload 
          };
          case 'CLEAR_INSERT_TEMPLATE':
            return {
                ...state,
                loading_emailmarketing_admin: false,
                data_insert_template: [],
                error: null
              };
              case 'CLEAR_UPDATE_TEMPLATE':
            return {
                ...state,
                loading_emailmarketing_admin: false,
                data_update_template: [],
                error: null
              };

              case 'MEMBERSHIP_COMMUNITYID_SUCCESS':
                return {
                    ...state,
                    loading_emailmarketing_admin: false,
                    data_membership_admin: action.payload,
                    error: null
                  };
          case 'MEMBERSHIP_COMMUNITYID_ERROR':
                    return {
                        ...state,
                        loading_emailmarketing_admin: false,
                        data_membership_admin: [],
                        error: action.payload
                      };


                      case 'CLEAR_DATA_MEMBERSHIP':
            return {
                ...state,
                loading_emailmarketing_admin: false,
                data_membership_admin: [],
                error: null
              };
              case 'CLEAR_EVENT_LIST_ADMIN':
            return {
                ...state,
                loading_emailmarketing_admin: false,
                data_emailtemplate_event_fetch: [],
                error: null
              };

              case 'CLEAR_BLOG_LIST_ADMIN':
            return {
                ...state,
                loading_emailmarketing_admin: false,
                data_emailtemplate_blog_fetch: [],
                error: null
              };
                          
      default:
        return state;
    }
  };
  
  export default reducer;
  

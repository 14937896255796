import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerOccupation from '../reducer/occupationReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const occupationContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  occupation_insert:[],
  occupation_update:[]
};

let API = `${BASE_URL}`;


export const OccupationProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerOccupation , initialState);

const fetchOccupationItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/occupation_details_edit` , params);

      dispatch({ type: 'OCCUPATION_DETAILS_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'OCCUPATION_DETAILS_EDIT_ERROR', payload: error });
    }
  };


  const submit_occupationdetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_occupation` , article);
      dispatch({ type: 'INSERT_OCCUP_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_OCCUP_DETAILS_ERROR', payload: error });
    }
  };


  const update_occupationdetails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_occupation` , article);
      dispatch({ type: 'UPDATE_OCCUP_DETAILS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_OCCUP_DETAILS_ERROR', payload: error });
    }
  };



  useEffect(() => {
       
    fetchOccupationItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": ["en" , localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <occupationContext.Provider value={{ ...state , dispatch , fetchOccupationItems , submit_occupationdetails ,update_occupationdetails}}>{children}</occupationContext.Provider>;
};

export const useOccupationContext = () => useContext(occupationContext);
import { InputLabel } from "@mui/material";
import styles from "../styles/strips.module.css";
import React, { useEffect, useState } from "react";


function Strips({title}){

   const[num ,setNum] = useState("");

   useEffect(()=>{
      
      if(title == "Basic Details"){
         setNum("1");
      }else if(title == "Physical Attributes and Lifestyle"){
         setNum("2");
      }else if(title == "Contact Details"){
         setNum("3");
      }else if(title == "Education Details"){
         setNum("4");
      }else if(title == "Occupation Details"){
         setNum("5");
      }else if(title == "Horoscope Details"){
         setNum("6");
      }else if(title == "Family Details"){
         setNum("7");
      }else if(title == "Partner Preferences"){
         setNum("8");
      }else if(title == "Upload Documents"){
         setNum("9");
      }
      

   },[title]);
   

     return (

     <>
     <div className={styles.subtitle_container} style={{ opacity:"1"  ,backgroundColor:"#FFB6C1" ,height:"100px"  , position:"absolute" , top:"65px" , left:"0" , width:"100%" , zIndex:"3"}}>
        
        <div className={styles.subtitle} style={{padding:"10px" , zIndex:"2"}}>
           
           <InputLabel style={{textAlign:"center" , fontSize:"30px" , fontWeight:"bold" , color:'var(--strip-text-color)'}}>{title}{num != "" ?   "  ("+num+"/9)" : ""}</InputLabel>

           <InputLabel style={{textAlign:"center" , fontSize:"15px" , fontWeight:"bold" , color:'var(--strip-text-color)' , marginTop:"10px"}}><div  dangerouslySetInnerHTML={{__html:"Home &nbsp;  | &nbsp;&nbsp; "+title}} ></div></InputLabel>

        </div>   
     
    </div>
     
     </>

     );



}

export default Strips;
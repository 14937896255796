import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, MenuItem , Pagination ,IconButton ,Modal} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';







function State_page(){

  const {state_fetch ,state_insert ,state_update , data_insert_state , list_state_admin , data_update_state , country_fetch ,list_country_admin , clearListState , delete_master_data} = useMasterContextAdmin();


  const [country , setCountry] = useState('');
  const [country2 , setCountry2] = useState('');
  const [state , setState] = useState('');
  const [stateId , setStateId] = useState('');
  const [statehindi , setStateHindi] = useState('');
  const [stategujarat , setStateGujarat] = useState('');
  const [statemarathi , setStateMarathi] = useState('');
  const [stateTamil , setStateTamil] = useState('');
  const [stateurdu , setStateUrdu] = useState('');

  const [state2 , setState2] = useState('');
  const [statehindi2 , setStateHindi2] = useState('');
  const [stategujarat2 , setStateGujarat2] = useState('');
  const [statemarathi2 , setStateMarathi2] = useState('');
  const [stateTamil2 , setStateTamil2] = useState('');
  const [stateurdu2 , setStateUrdu2] = useState('');

  const [open , setopen] = useState(false);
  const [insert , setInsert] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);


  useEffect(()=>{
    country_fetch();
  },[]);

  useEffect(()=>{

    if(data_insert_state.affectedRows >= 1  ||  data_update_state.affectedRows >= 1){

      setState('');
      setStateHindi('');
      setStateGujarat('');
      setStateMarathi('');
      setStateTamil('');
      setStateUrdu('');

      state_fetch({
        "country_id": country,
        "offset":currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10),
      });

    }


  },[data_insert_state , data_update_state]);

  const handleOptionChange = (event) =>{

    
    setCurrentPage(1);
    setCountry(event.target.value);

    state_fetch({
      "country_id":event.target.value,
      "offset":"0"
    });

  }


  const handleOptionChange2 = (event) =>{
    setCountry2(event.target.value);

  }


  const handleStateChange = (event) =>{
    setState(event.target.value);
  }

  const handleStateHindiChange = (event) =>{
    setStateHindi(event.target.value);
  }

  const handleStateGujChange = (event) =>{
    setStateGujarat(event.target.value);
  }


  const handleStateMarathiChange = (event) =>{
    setStateMarathi(event.target.value);
  }


  const handleStateTamilChange = (event) =>{
    setStateTamil(event.target.value);
  }


  const handleStateUrduChange = (event) =>{
    setStateUrdu(event.target.value);
  }


  const handleClose = () => {
      setopen(false);
  }


  const handleDeleteClick = (is_enabled , Id) => {
    delete_master_data({ "type":"state"  , "is_enabled":"1" , "Id":Id});
  
    setTimeout(() => {
  
      state_fetch({
        "country_id": country,
        "offset":currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10),
      });
    
    }, 500);
  }






  const handleStateChange2 = (event) =>{
    setState2(event.target.value);
  }

  const handleStateHindiChange2 = (event) =>{
    setStateHindi2(event.target.value);
  }

  const handleStateGujChange2 = (event) =>{
    setStateGujarat2(event.target.value);
  }


  const handleStateMarathiChange2 = (event) =>{
    setStateMarathi2(event.target.value);
  }


  const handleStateTamilChange2 = (event) =>{
    setStateTamil2(event.target.value);
  }


  const handleStateUrduChange2 = (event) =>{
    setStateUrdu2(event.target.value);
  }


  const handleEditClick = (state , statehindi ,stateguj ,statemarathi ,statetamil ,stateurdu , id) => {
  

    setStateId(id);
    country_fetch();
    setState2(state);
    setStateHindi2(statehindi);
    setStateGujarat2(stateguj);
    setStateMarathi2(statemarathi);
    setStateTamil2(statetamil);
    setStateUrdu2(stateurdu);
    setTimeout( function()  {setCountry2(country);  setopen(true);} , 300);

   }


  const handlePageChange = (event, value) => {
  
    setCurrentPage(value);

    state_fetch({
      "country_id":country,
        "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
    });

 } 


  const handleAddState = () => {


    if(state != "" && statehindi != "" && stategujarat != "" && statemarathi != "" && stateTamil != "" && stateurdu != "" && country2 != ""){
    state_insert({ "state_name" : state,
    "state_hindi" :statehindi,
    "state_guj" :stategujarat,
    "state_marathi" :statemarathi,
    "state_tamil" : stateTamil,
    "state_urdu" : stateurdu,
    "country_id" : country2
    });
  }else{

    alert("All Fields are compulsory");

  }


  }




  const handleUpdateState = () => {


    if(state2 != "" && statehindi2 != "" && stategujarat2 != "" && statemarathi2 != "" && stateTamil2 != "" && stateurdu2 != "" && country2 != ""){
      state_update({ "state_name" : state2,
    "state_hindi" :statehindi2,
    "state_guj" :stategujarat2,
    "state_marathi" :statemarathi2,
    "state_tamil" : stateTamil2,
    "state_urdu" : stateurdu2,
    "Id" : stateId
    });

    setopen(false);

  }else{

    alert("All Fields are compulsory");

  }


  }



  let role = localStorage.getItem(ROLE);

   return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "0px" : "0px" , width: "100%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>



<TextField
      select
      label="Select Religion to List Caste"
      variant="outlined"
      value={country}
      onChange={handleOptionChange}
      style={{marginTop:"10px" , float:"right" , width:"200px" , marginRight:"20px"}}
      >
      {list_country_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.country_name}
        </MenuItem>
      ))}
    </TextField>

<Grid container spacing={1}>

<Grid  item xs={9}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>State Hindi</TableCell>
                  <TableCell>State Gujarat</TableCell>
                  <TableCell>State Marathi</TableCell>
                  <TableCell>State Tamil</TableCell>
                  <TableCell>State Urdu</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_state_admin.length > 0 &&   list_state_admin[0].map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.state_name}</TableCell>
                    <TableCell>{religion.state_hindi}</TableCell>
                    <TableCell>{religion.state_guj}</TableCell>
                    <TableCell>{religion.state_marathi}</TableCell>
                    <TableCell>{religion.state_tamil}</TableCell>
                    <TableCell>{religion.state_urdu}</TableCell>
                    <TableCell>{religion.country}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.state_name , religion.state_hindi , religion.state_guj , religion.state_marathi , religion.state_tamil , religion.state_urdu , religion.Id)}><EditIcon /></IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_state_admin.length > 0 && Math.ceil(list_state_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>

</Grid>



<Grid  item xs={3}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
    <Grid container spacing={1}>


    <Grid item xs={12}>

<TextField
  select
  label="Select Option"
  variant="outlined"
  value={country2}
  onChange= {handleOptionChange2}
  fullWidth
>
{list_country_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.country_name}
        </MenuItem>
      ))}
</TextField>

</Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter State"
        variant="outlined"
        value={state}
        fullWidth
        onChange={handleStateChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Hindi"
        variant="outlined"
        value={statehindi}
        fullWidth
        onChange={handleStateHindiChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Gujarati"
        variant="outlined"
        value={stategujarat}
        fullWidth
        onChange={handleStateGujChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>



    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Marathi"
        variant="outlined"
        value={statemarathi}
        fullWidth
        onChange={handleStateMarathiChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Tamil"
        variant="outlined"
        value={stateTamil}
        fullWidth
        onChange={handleStateTamilChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Urdu"
        variant="outlined"
        value={stateurdu}
        fullWidth
        onChange={handleStateUrduChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddState}>
        Submit State
      </Button>

      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>

         <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
      <Grid item xs={12}>


      <TextField
  select
  label="Select Option"
  variant="outlined"
  value={country2}
  onChange= {handleOptionChange2}
  fullWidth
>
{list_country_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.country_name}
        </MenuItem>
      ))}
</TextField>

</Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter State"
        variant="outlined"
        value={state2}
        fullWidth
        onChange={handleStateChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Hindi"
        variant="outlined"
        value={statehindi2}
        fullWidth
        onChange={handleStateHindiChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Gujarati"
        variant="outlined"
        value={stategujarat2}
        fullWidth
        onChange={handleStateGujChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>



    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Marathi"
        variant="outlined"
        value={statemarathi2}
        fullWidth
        onChange={handleStateMarathiChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Tamil"
        variant="outlined"
        value={stateTamil2}
        fullWidth
        onChange={handleStateTamilChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Urdu"
        variant="outlined"
        value={stateurdu2}
        fullWidth
        onChange={handleStateUrduChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleUpdateState}>
        Update State
      </Button>

        </Grid>
        </Grid>
        </Modal>


        </div>
      );




}

export default State_page;
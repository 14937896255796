import { ThemeProvider, useTheme } from "@mui/system";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import { SupportTicketProvider, useAdminsupportTicketContext } from "../../contexts_admin/supportTicket_context";
import { useState } from "react";
import RotatingHeart from "../../../common_utils/loading_animator";
import StylishLabel from "../../component/StylishLabel";
import { Grid, MenuItem, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import AddComment from "./AddComment";
import { useNavigate } from "react-router-dom";
import ViewDetailsSupport from "./ViewDetailsSupport";
import ViewComments from "./ViewComments";
import React from "react";









function SupportTicketList(){

    const {data_supportticket_admin  , SupportTicketSelectByPriority, SupportTicketSelect ,deleteSupportComment ,  SupportTicketSelectByStatus  , loading_supportTikcet_admin } = useAdminsupportTicketContext();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [openAddCommnets , setOpenAddComments] = useState(false);
    const [openViewCommnets , setOpenViewComments] = useState(false);
    const [openSupportDetails , setOpenSupportDetails] = useState(false);

    const [TicketId , setTicketId] = useState("");

    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [Index, setIndex] = useState(-1);

    const [selectedStatus, setselectStatus] = useState('');
  const [options] = useState([
    { value: 'Open', label: 'Open' },
    { value: 'Assigned', label: 'Assigned' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'Resolved', label: 'Resolved' },
    { value: 'Closed', label: 'Closed' },
    { value: 'Reopened', label: 'Reopened' },
    { value: 'Cancelled', label: 'Cancelled' },
  ]);


  const [priority, setPriority] = useState('');
  const [optionsPriority] = useState([
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
    { value: 'Emergency', label: 'Emergency' },
  ]);



    let role = localStorage.getItem(ROLE);


    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });



      useEffect(()=>{

        if(data_supportticket_admin.length > 0 || loading_supportTikcet_admin == false){

          setLoad(true);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

          SupportTicketSelect({
                "communityId":communityId_admin,
                "offset":"0",
                "query":""
              });

        }
       
    },[data_supportticket_admin]);


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  

      const handleOpenAddComment = (ticket_id) => {

     

        setTicketId(ticket_id);
        setOpenAddComments(true);
      }
  
      const handleCloseAddComment = (isopen) => {
        setOpenAddComments(isopen);
      }



      const handleOpenViewComment = (ticket_id) => {

        deleteSupportComment();
           setTicketId(ticket_id);
           setOpenViewComments(true);
      }

      const handleCloseViewComment = (isopen) => {
        setOpenViewComments(isopen);
      }

  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        SupportTicketSelect({
            "communityId":communityId_admin,
            "offset":"0",
            "query":""  
          });
  
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
        SupportTicketSelect({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
            "query":query  
          });
  
    }

    const handleStatus = (event) => {

        setselectStatus(event.target.value);

        SupportTicketSelectByStatus({
            "communityId":communityId,
            "offset":"0",
            "query":event.target.value 
        })


    }


    const handlePriority = (event) => {

        setPriority(event.target.value);

        SupportTicketSelectByPriority({
            "communityId":communityId,
            "offset":"0",
            "query":event.target.value 
        })


    }

  
    const [query, setValue] = useState('');

    const handleChange = (event) => {
      setValue(event.target.value);

      SupportTicketSelect({
        "communityId":communityId,
        "offset": "0",
        "query":event.target.value  
      });

    };

    const navigate = useNavigate();

    const addSupportTicket = () =>{

      navigate('/add_support_ticket/'+communityId);

    };


    const handleUpdateSupportTikcet = (index) => {

      //alert(data_supportticket_admin[0][index].priority);

      navigate('/update_support_ticket', {
        // Define your object here
        state: {
          // Define your object here
          myObject: { key: data_supportticket_admin[0][index] }
        }
      });


    };

    const handleOpenDetails = (index) => {
      setIndex(index);
      setOpenSupportDetails(true);

    }

    const handleCloseDetails = () => {

      setOpenSupportDetails(false);

    }




    return (<>
    
     {  load == false ?  <RotatingHeart/> : 
       
       <div  style={{backgroundColor:"#FAFBFD"}}>

        { role == "developer" ? <div><StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity}  /></div> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "0px" : "0px" , width: "100%" , paddingRight:"15px"   }}>
        
        
        <Grid key={5} item xs={4}>
 
  <TextField
      label="Enter text to search"
      value={query}
      onChange={handleChange}
      variant="outlined"
      fullWidth
    />
   
    </Grid>


    <Grid item xs={2}>
    <TextField
      select
      label="Select Status"
      value={selectedStatus}
      onChange={handleStatus}
      variant="outlined"
      fullWidth
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    </Grid>


    <Grid item xs={2}>
    <TextField
      select
      label="Select Priority"
      value={priority}
      onChange={handlePriority}
      variant="outlined"
      fullWidth
    >
      {optionsPriority.map((option) => (
        <MenuItem key={option.value} value={option.value} >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    </Grid>


    <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>


    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  <Typography variant="h6" align="left"  style={{ paddingLeft: "20px" , paddingRight:"20px", color: "blue", fontWeight: "bold", backgroundColor: "#ffc0cb" }}>
    Support Ticket System
  </Typography>
  <Typography variant="h6" align="right" style={{ paddingLeft: "20px", paddingRight:"20px", color: "blue", fontWeight: "bold", backgroundColor: "#ffc0cb", cursor: "pointer" }} onClick={addSupportTicket}>
    Click To Add Ticket
  </Typography>
</div>
 
<TableContainer component={Paper}>
  <Table>
    <TableHead>
           <TableRow >
        <TableCell style={{ fontWeight:"bold" , color:"black" ,  paddingRight:"-80px" }}>Seq.</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Customer Name</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Customer Phone</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Customer Complaint</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Customer Timing</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>DateTime Complaint</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Status</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Task Assigned To</TableCell>
        <TableCell colSpan={1} style={{ fontWeight:"bold" , color:"black" }}>Priority</TableCell>
        <TableCell colSpan={2} style={{ fontWeight:"bold" , color:"black" }}>Add Comments/View Comments</TableCell>
        <TableCell colSpan={2} style={{ fontWeight:"bold" , color:"black" }}>View Details/Update Details</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data_supportticket_admin.length > 0 && data_supportticket_admin[0].map((free_members, index) => (
        <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
            <TableCell  colSpan={0.5} style={{ color: 'black', fontSize: '15px' ,   paddingRight:"-80px"  }}>{(index+1)}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.customer_name}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.customer_phone}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.customer_complaint}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.customer_timing}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.date_time_complain}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.status_ticket}</TableCell>
          <TableCell  colSpan={1} style={{ color: 'black', fontSize: '15px'  }}>{free_members.assigned_to}</TableCell>
          <TableCell  colSpan={2} style={{ color: 'black', fontSize: '15px'  }}>{free_members.priority}</TableCell>
    <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleOpenAddComment(free_members.ticket_id)} >
      {"Add Comments"} 
    </span>
    <br/>
    <span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleOpenViewComment(free_members.ticket_id)}>
      {"View Comments"}
    </span>
    
    </TableCell>
   

   <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={()=>handleOpenDetails(index)} >
      {"View Details"}
    </span>
    <br/>
    <span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={ ()=>handleUpdateSupportTikcet(index)}>
      {"Update Details"}
    </span>
    
    </TableCell>

        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={data_supportticket_admin.length > 0 && Math.ceil(data_supportticket_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>





        </Grid>

        

        </Grid>
    

        {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
        {openAddCommnets == true ? <SupportTicketProvider> <AddComment isopen={true} setClose={handleCloseAddComment} ticket_id={TicketId} /> </SupportTicketProvider> : ""}
        {openSupportDetails == true ?  <ViewDetailsSupport isopen={true} setClose={handleCloseDetails} Details={data_supportticket_admin[0][Index]} />  : ""}
        {openViewCommnets == true ?  <ViewComments isopen={true} setClose={handleCloseViewComment} ticket_id={TicketId} />  : ""}

    </div>
    

    

    }
    
    
    
    </>);




}

export default SupportTicketList;
const reducer = (state, action) => {
    switch (action.type) {
      case 'HOME_ITEMS':
        return {
          ...state,
          data: action.payload,
          loading_home: false,
          error: null,
        };
      case 'HOME_ITEMS_ERROR':
        return {
          ...state,
          data: [],
          loading_home: false,
          error: action.payload,
        };



        case 'HAPPY_STORY':
        return {
          ...state,
          happy_story_list: action.payload,
          loading: false,
          error: null,
        };
      case 'HAPPY_STORY_ERROR':
        return {
          ...state,
          happy_story_list: [],
          loading: false,
          error: action.payload,
        };


   
      default:
        return state;
    }
  };

  export default reducer;
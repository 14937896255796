import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';

const DeleteAccountReason = ({ open, handleClose }) => {
  const [editedText, setEditedText] = useState('');

  const handleTextChange = (event) => {
    setEditedText(event.target.value);
  };

  const handleSave = () => {
    handleClose(editedText);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="edit-dialog-title">
      <DialogTitle id="edit-dialog-title">Enter Reason For deleting account</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Edited Text"
          type="text"
          fullWidth
          value={editedText}
          onChange={handleTextChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Delete Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountReason;



export const BASE_URL = "https://matriapi.shannishah.com/api/";
export const IMAGE_URL = "https://matriapi.shannishah.com/";

//export const BASE_URL = "http://localhost:4000/api/";
//export const IMAGE_URL = "http://localhost:4000/";

export const PUBLIK_KEY = "BInPBWg1x5WcFevCn5wGNzdOC8dKP-nPmf9gDFQk1FmDaWhqZ-AUdzcBqAk8LzEEpZIdN6aNPvK4sa6Mr9sJVdo";

export const SENDER_NAME = "Shanni";
export const SENDER_EMAIL = "shah.shanni2010@gmail.com";
export const LIMIT = 8;

export const COMMUNITY_ID = "community_id";
export const USER_ID  =  "user_id";
export const PROFILE_ID  =  "profile_id";
export const EMAIL_ID  =  "email_id";
export const GENDER  =  "gender";
export const MOBILE_NO  =  "mobile_no";
export const BIRTH_DATE  =  "birth_date";
export const NAME        = "full_name"; 
export const JOINED_DATE  = "joined_date";
export const IS_LOGIN  = "is_login";
export const TRANSLATED_LANG  = "translated_language";
export const AGE_P = "age_p";
export const HEIGHT_P = "height_p";
export const MARITAL_STATUS_P = "marital_status_p";
export const CASTE_P = "caste_p";
export const SUBCASTE_P = "subcaste_p";
export const SKINTONE_P = "skintone_p";
export const STATE_P = "state_p";
export const CITY_P = "city_p";
export const EDUCATION_P = "education_p";
export const OCCUPATION_P = "occupation_p";
export const DIET_TYPE_P = "diet_type_p";
export const FAMILY_VALUE_P = "family_value_p";
export const ANNUAL_INCOME_P = "annual_income_p";
export const BODY_TYPE_P = "bodytype_p";
export const SMOKE_TYPE_P = "smoketype_p";
export const DRINK_TYPE_P = "drink_type_p";

export const AGE_FROM_P_VAL = "age_from_p_val";
export const AGE_TO_P_VAL = "age_to_p_val";
export const HEIGHT_FROM_P_VAL = "height_from_p_val";
export const HEIGHT_TO_P_VAL = "height_to_p_val";
export const MARITAL_STATUS_P_VAL = "marital_status_p_val";
export const CASTE_P_VAL = "caste_p_val";
export const SUBCASTE_P_VAL = "subcaste_p_val";
export const SKINTONE_P_VAL = "skintone_p_val";
export const STATE_P_VAL = "state_p_val";
export const CITY_P_VAL = "city_p_val";
export const EDUCATION_P_VAL = "education_p_val";
export const OCCUPATION_P_VAL = "occupation_p_val";
export const DIET_TYPE_P_VAL = "diet_type_p_val";
export const FAMILY_VALUE_P_VAL = "family_value_p_val";
export const ANNUAL_INCOME_P_VAL = "annual_income_p_val";
export const BODY_TYPE_P_VAL = "bodytype_p_val";
export const SMOKE_TYPE_P_VAL = "smoketype_p_val";
export const DRINK_TYPE_P_VAL = "drink_type_p_val";


export const AGE_FROM_P_LBL = "age_from_p_lbl";
export const AGE_TO_P_LBL = "age_to_p_lbl";
export const HEIGHT_FROM_P_LBL = "height_from_p_lbl";
export const HEIGHT_TO_P_LBL = "height_to_p_lbl";
export const MARITAL_STATUS_P_LBL = "marital_status_p_lbl";
export const CASTE_P_LBL = "caste_p_lbl";
export const SUBCASTE_P_LBL = "subcaste_p_lbl";
export const SKINTONE_P_LBL = "skintone_p_lbl";
export const STATE_P_LBL = "state_p_lbl";
export const CITY_P_LBL = "city_p_lbl";
export const EDUCATION_P_LBL = "education_p_lbl";
export const OCCUPATION_P_LBL = "occupation_p_lbl";
export const DIET_TYPE_P_LBL = "diet_type_p_lbl";
export const FAMILY_VALUE_P_LBL = "family_value_lbl";
export const ANNUAL_INCOME_P_LBL = "annual_income_p_lbl";
export const BODY_TYPE_P_LBL = "bodytype_p_lbl";
export const SMOKE_TYPE_P_LBL = "smoketype_p_lbl";
export const DRINK_TYPE_P_LBL = "drink_type_p_lbl";



export const BIRTH_DATE_HOROSCOPE = "bith_date_horoscope";
export const BIRTH_TIME_HOROSCOPE = "bith_time_horoscope";
export const BIRTH_LOCATION_HOROSCOPE = "bith_location_horoscope";
export const TIMEZONE_HOROSCOPE = "timezone_horoscope";

export const DATA_HOROSCOPE_CHART = "data_horoscope_chart";
export const DATA_HOROSCOPE_MATCHMAKING = "data_horoscope_matchmaking";


export const ADMIN_IS_LOGIN = "admin_is_login";
export const ROLE = "role";
export const PERMISSION = "permission";
export const ADMIN_COMMUNITY_ID = "admin_community_id";
export const ADMIN_NAME = "admin_name";
export const ADMIN_EMAIL = "admin_email";
export const ADMIN_PHONE = "admin_phone";
export const ADMIN_USERID = "admin_userid";
import React, { useState } from 'react';
import { Button, Card, CardContent, Modal, Typography } from '@mui/material';
import { useEffect } from 'react';

const ModalPopup = ({title , content ,openvalidate , onChildValue}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    
    setOpen(openvalidate);

  }, []);


  const handleClose = () => {
    setOpen(false);
    onChildValue(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
          <Card sx={{ width: 300 }}>
            <CardContent>
              <Typography variant="h6" id="modal-title">
                {title}
              </Typography>
              <Typography variant="body2" id="modal-description" style={{marginTop:"20px"}}>
               {<div dangerouslySetInnerHTML={content}></div>}
              </Typography>
              <Button onClick={handleClose} sx={{ height:"20px", alignSelf:"baseline" , marginTop:"20px"}} variant="contained" color="secondary">
                Close
              </Button>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </div>
  );
};






export default ModalPopup;

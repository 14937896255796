import React, { createContext, useContext, useReducer } from 'react';
import axios from 'axios';
import reducer from '../../adminpanel/reducer_admin/eventsReducerAdmin'; // Assuming you have an event reducer
import { BASE_URL } from '../../common_utils/constants';

const eventContext = createContext();

const initialState = {
  loading_event: true,
  error: null,
  data_insert_event: [],
  list_event_admin:[],
  data_update_event:[],
  data_insert_samuhlagan:[],
  list_samuhlagan_admin:[],
  data_update_samuhlagan:[],
  list_event_samuhlagan_admin:[],
  total_row_count_samuhlagan : 0,
  total_row_count_event : 0
};

const API = BASE_URL;

export const EventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer , initialState);

  const addEvent = async (eventData, endpoint) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/events_insert`, eventData);
      dispatch({ type: 'ADD_EVENT_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'ADD_EVENT_ERROR', payload: error });
    }
  };

  const updateEvent = async (eventData, endpoint) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/events_update`, eventData);
      dispatch({ type: 'UPDATE_EVENT_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'UPDATE_EVENT_ERROR', payload: error });
    }
  };

  const fetchEvents = async (article, endpoint) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/events_fetchByCommunityId`, article);
      dispatch({ type: 'EVENT_LIST_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'EVENT_LIST_ERROR', payload: error });
    }
  };


  const fetchEventsSamuhlagan = async (article, endpoint) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/events_fetchByCommunityId`, article);
      dispatch({ type: 'EVENT_LIST_SUCCESS_SAMUHLAGAN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'EVENT_LIST_ERROR_SAMUHLAGAN', payload: error });
    }
  };



   const addSamuhlagan = async (eventData, endpoint) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/samuhLagan_insert`, eventData);
      dispatch({ type: 'ADD_SamuhLagan_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'ADD_SamuhLagan_ERROR', payload: error });
    }
  };

  const updateSamuhLagan = async (eventData, endpoint) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/samuhLagan_update`, eventData);
      dispatch({ type: 'UPDATE_SamuhLagan_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'UPDATE_SamuhLagan_ERROR', payload: error });
    }
  };

  const fetchSamuhLagan = async (article, endpoint) => {
    try {
      const response = await axios.post(`${API}adminpanel/user_content/samuhLagan_fetchByCommunityId`, article);
      dispatch({ type: 'SamuhLagan_LIST_SUCCESS', payload: response.data.data, success: response.data.success });
    } catch (error) {
      dispatch({ type: 'SamuhLagan_LIST_ERROR', payload: error });
    }
  };

  return (
    <eventContext.Provider value={{ ...state, dispatch, addEvent, updateEvent, fetchEvents , fetchSamuhLagan , fetchEventsSamuhlagan , addSamuhlagan , updateSamuhLagan }}>
      {children}
    </eventContext.Provider>
  );
};

export const useEventContextAdmin = () => useContext(eventContext);
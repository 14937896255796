const reducerNotification = (state, action) => {
    switch (action.type) {


      case 'NOTIFICATION_LIST':
        return {
          ...state,
          notification_list: action.payload,
          loading: false,
          error: null,
        };
      case 'NOTIFICATION_LIST_ERROR':
        return {
          ...state,
          notification_list: [],
          loading: false,
          error: action.payload,
        };

       
        case 'ACC_REJ_NOTIFICATION_LIST':
            return {
              ...state,
              notification_accept_reject: action.payload,
              loading: false,
              error: null,
            };
          case 'ACC_REJ_NOTIFICATION_LIST_ERROR':
            return {
              ...state,
              notification_accept_reject: [],
              loading: false,
              error: action.payload,
            };


            case 'ACCEPT_REJECT':
              return {
                ...state,
                accept_reject_list: action.payload,
                loading: false,
                error: null,
              };
            case 'ACCEPT_REJECT_ERROR':
              return {
                ...state,
                accept_reject_list: [],
                loading: false,
                error: action.payload,
              };



              case 'INSERT_NOTIFICATION_COUNT':
                return {
                  ...state,
                  insert_notification_count_list: action.payload,
                  loading: false,
                  error: null,
                };
              case 'INSERT_NOTIFICATION_COUNT_ERROR':
                return {
                  ...state,
                  insert_notification_count_list: [],
                  loading: false,
                  error: action.payload,
                };
  

                case 'ALLOWED_LIST':
                  return {
                    ...state,
                    allowed_features_list: action.payload,
                    loading: false,
                    error: null,
                  };
                case 'ALLOWED_LIST_ERROR':
                  return {
                    ...state,
                    allowed_features_list: [],
                    loading: false,
                    error: action.payload,
                  };


                  case 'INSERT_ALLOWED':
                    return {
                      ...state,
                      insert_allowed_list: action.payload,
                      loading: false,
                      error: null,
                    };
                  case 'INSERT_ALLOWED_ERROR':
                    return {
                      ...state,
                      insert_allowed_list: [],
                      loading: false,
                      error: action.payload,
                    };



                    case 'UPDATE_ALLOWED':
                    return {
                      ...state,
                      update_allowed_list: action.payload,
                      loading: false,
                      error: null,
                    };
                  case 'UPDATE_ALLOWED_ERROR':
                    return {
                      ...state,
                      update_allowed_list: [],
                      loading: false,
                      error: action.payload,
                    };









                    case 'ALLOWED_LIST_HORO':
                  return {
                    ...state,
                    allowed_features_list_horo: action.payload,
                    loading: false,
                    error: null,
                  };
                case 'ALLOWED_LIST_ERROR_HORO':
                  return {
                    ...state,
                    allowed_features_list_horo: [],
                    loading: false,
                    error: action.payload,
                  };


                  case 'INSERT_ALLOWED_HORO':
                    return {
                      ...state,
                      insert_allowed_list_horo: action.payload,
                      loading: false,
                      error: null,
                    };
                  case 'INSERT_ALLOWED_ERROR_HORO':
                    return {
                      ...state,
                      insert_allowed_list_horo: [],
                      loading: false,
                      error: action.payload,
                    };



                    case 'UPDATE_ALLOWED_HERO':
                    return {
                      ...state,
                      update_allowed_list_horo: action.payload,
                      loading: false,
                      error: null,
                    };
                  case 'UPDATE_ALLOWED_ERROR_HORO':
                    return {
                      ...state,
                      update_allowed_list_horo: [],
                      loading: false,
                      error: action.payload,
                    };




                case 'INSERT_NOTIFICATION_MEMBER_NOTIFICATION':
                return {
                  ...state,
                  insert_notificatioin_data_notification: action.payload,
                  loading: false,
                  error: null,
                };
              case 'INSERT_NOTIFICATION_MEMBER_NOTIFICATRION_ERROR':
                return {
                  ...state,
                  insert_notificatioin_data_notification: [],
                  loading: false,
                  error: action.payload,
                };




                case 'INSERT_NOTIFICATION_MEMBER_NOTIFICATION_HORO':
                return {
                  ...state,
                  insert_notificatioin_data_notification_horo: action.payload,
                  loading: false,
                  error: null,
                };
              case 'INSERT_NOTIFICATION_MEMBER_NOTIFICATRION_ERROR_HORO':
                return {
                  ...state,
                  insert_notificatioin_data_notification_horo: [],
                  loading: false,
                  error: action.payload,
                };



                case 'PREMIUM_USER_DATA':
                  return {
                    ...state,
                    user_validation_data_list: action.payload,
                    loading: false,
                    error: null,
                  };
                case 'PREMIUM_USER_DATA_ERROR':
                  return {
                    ...state,
                    user_validation_data_list: [],
                    loading: false,
                    error: action.payload,
                  };



                  case 'VIEW_OTHER_HOROSCOPE':
                    return {
                      ...state,
                      insert_horoscope_view_list: action.payload,
                      loading: false,
                      error: null,
                    };
                  case 'VIEW_OTHER_HOROSCOPE_ERROR':
                    return {
                      ...state,
                      insert_horoscope_view_list: [],
                      loading: false,
                      error: action.payload,
                    };




                    case 'UPDATE_PAYMENT_EXPIRE':
                      return {
                        ...state,
                        update_payment_expire_list: action.payload,
                        loading: false,
                        error: null,
                      };
                    case 'UPDATE_PAYMENT_EXPIRE_ERROR':
                      return {
                        ...state,
                        update_payment_expire_list: [],
                        loading: false,
                        error: action.payload,
                      };

                      

                      case 'SELECT_WEB_THEME':
                        return {
                          ...state,
                          data_select_webtheme: action.payload,
                          loading: false,
                          error: null,
                        };
                
                      case 'SELECT_WEB_THEME_ERROR':
                        return {
                          ...state,
                          data_select_webtheme: [],
                          loading: false,
                          error: action.payload,
                        };

                        
                    
                  

                case `RESET`:
                return {
                  ...state,
                  notification_list: [],
                  loading: false,
                  error: null,
                };
       
      default:
        return state;
    }
  };

  export default reducerNotification;
import { useState } from "react";
import { useEmailMarketingContext } from "../../contexts_admin/email_marketing_context";
import { TextField ,Grid ,Button , MenuItem} from "@mui/material";
import { ROLE } from "../../../common_utils/constants";
import RichTextEditor from "../../component/RichTextEditor";
import { useEffect } from "react";
import React from "react";



function EditEmailTemplate(){

    const { update_emailtemplate , email_template_fetch , data_emailtemplate_fetch , data_update_template , clear_update_template} = useEmailMarketingContext(); 


    const [TemplateTypeDropdown , setTemplateTypeDropdown] = useState("");
    const [TemplateType , setTemplateType] = useState("");
    const [HtmlContent, setHtmlContent] = useState("");
    const [getContent, setgetContent] = useState("");
    const [Id, setId] = useState("");

    useEffect(()=>{

      if(data_update_template.affectedRows >= 1){
           clear_update_template();

           alert("Updated Successfully");
      }

      if(data_emailtemplate_fetch.length <= 0){
        email_template_fetch();
      }else{

       
      }
       

    },[data_emailtemplate_fetch , data_update_template]);


    const handleChangeEmailTemplate = (event) => {

        setTemplateTypeDropdown(event.target.value);
        setTemplateType(data_emailtemplate_fetch[event.target.value].type);

        setgetContent(data_emailtemplate_fetch[event.target.value].template_details.replace(/""/g, '"').replace(/''/g , "'"));

        const item = data_emailtemplate_fetch.find(i => i.type === data_emailtemplate_fetch[event.target.value].type);
        setId(item.Id);

       
       
    }

    const handleOptionChange = (event) => {
        setTemplateType(event.target.value);
    }

    const handleContent = (content) => {
        setHtmlContent(content);
    }

    const ClickToUpdateTemplates = () => {

      update_emailtemplate({
        "type":TemplateType,
        "template":HtmlContent.replace(/"/g, '""').replace(/'/g, "''"),
        "Id":Id
      });

    }

    let role = localStorage.getItem(ROLE);

    return(<>
    <Grid container spacing={4} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "30px" : "10px", left: role == "developer" ? "20px" : "15px" , width: "95%" , paddingRight:"15px"  , justifyContent:"center"  }}>



<Grid  item xs={12}>
   <TextField
  select
  label="Select Email Template Type"
  variant="outlined"
  value={TemplateTypeDropdown}
  onChange= {handleChangeEmailTemplate}
  fullWidth
>
{data_emailtemplate_fetch.length > 0 &&  data_emailtemplate_fetch.map((option , index) => (
        <MenuItem key={option.Id} value={index}>
          {option.type}
        </MenuItem>
      ))}
</TextField>
</Grid>


    <Grid item xs={12} style={{marginTop:"0px"}}>

    <TextField
      label="Enter type for Template"
      variant="outlined"
      value={TemplateType}
      onChange={handleOptionChange}
      fullWidth
      style={{marginTop:"10px" , float:"right" }}
      >
    </TextField>

    </Grid>

    <Grid item xs={12}>

    <RichTextEditor SentContent={handleContent} getContent={getContent}/>

    </Grid>

    <Grid item>

    <Button variant="contained" style={{  width: "600px" ,  backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={ClickToUpdateTemplates}>Update Template</Button>


    </Grid>

    </Grid>
    </>);

}

export default EditEmailTemplate;
const reducerEducation = (state, action) => {
    switch (action.type) {
      case 'EDUCATION_DETAILS_EDIT':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'EDUCATION_DETAILS_EDIT_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };

        case 'INSERT_EDUCATION_DETAILS':
        return {
          ...state,
          education_insert: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_EDUCATION_DETAILS_ERROR':
        return {
          ...state,
          education_insert: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_EDUCATION_DETAILS':
        return {
          ...state,
          education_update: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_EDUCATION_DETAILS_ERROR':
        return {
          ...state,
          education_update: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerEducation;
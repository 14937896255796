const reducer = (state, action) => {
    switch (action.type) {
      case 'REGISTER_SUCCESS':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'REGISTER_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };

        case 'LOGIN_SUCCESS':
        return {
          ...state,
          login_status: action.payload,
          data_login:action.payload1,
          loading: false,
          error: null,
        };
      case 'LOGIN_ERROR':
        return {
          ...state,
          login_status: [],
          loading: false,
          error: action.payload,
        };

        case 'REMOVE':
          return{
            ...state,
            data: [],
            data_login: [],
            loading:true
          }
      
       
      default:
        return state;
    }
  };

  export default reducer;
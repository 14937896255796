import { useEffect } from "react";
import { useCommunityContextAdmin } from "../../contexts_admin/community_context";
import { useState } from "react";
import RotatingHeart from "../../../common_utils/loading_animator";
import { ROLE } from "../../../common_utils/constants";
import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import styles from "../../styles_admin/CollapsibleMenu.module.css";
import DetailDialog from "./DetailDailog";
import { useNavigate } from "react-router-dom";
import React from "react";




function ListCommunity(){

    const { community_select , list_community_admin , loading_community} = useCommunityContextAdmin();
    const [currentPage, setCurrentPage] = useState(1);
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [index ,setIndex]   =useState(-1);


    useEffect(() => {
        const handleVisibilityChange = () => {
          if (!document.hidden) {
            // Page is visible, reload the page
            window.location.reload();
          }
        };
    
        const handlePopstate = () => {
          if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
            // Reload the page when navigating back
            window.location.reload();
          }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('popstate', handlePopstate);
    
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          window.removeEventListener('popstate', handlePopstate);
        };
      }, []);


    useEffect(()=>{


        if(list_community_admin.length > 0 || loading_community == false){

            setLoad(true);

        }else{

            community_select({
                "offset":"0"
             });
    


        }

    },[list_community_admin]);


    const handleCommunityClick = (index) => {

        setIndex(index);
        setopenCommunity(true);
       
      }


      const navigate = useNavigate();
      const handleCommunityClickUpdate = (index) => {

        let details = list_community_admin[0][index];
        navigate(`/update_community` , { state: { details } });

        
       
      }



   
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
    
    

        const handlePageChange = (event, value) => {
    
            setCurrentPage(value);
    
            community_select({
                    "offset": value == 1 ? 0 : (((value - 2) * 10) + 10)
            });
                
    
        };


        let role = localStorage.getItem(ROLE);


        return(<>
        
        { load == false ?  <RotatingHeart/> : 
       
       <div  style={{backgroundColor:"#FAFBFD" , marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "100px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%"}}>

       <Typography  variant="h6" align="left" width="400px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Community Listings</Typography>

       

        <TableContainer component={Paper}>
        <Table className="nicetable">
      <TableHead>
        <TableRow>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>Community Name</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>Code</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>Contact Person</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>Mobile</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>City</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>Website</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>View Details</TableCell>
          <TableCell style={{color:"black" ,fontWeight:"bold"}}>Update</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list_community_admin.length > 0 && list_community_admin[0].map((item, index) => (
          <TableRow key={index} className={index % 2 === 0 ? 'pinkrow' : 'greyrow'}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.code}</TableCell>
            <TableCell>{item.contact_person}</TableCell>
            <TableCell>{item.mobile}</TableCell>
            <TableCell>{item.city}</TableCell>
            <TableCell>{item.website}</TableCell>
            <TableCell>
              <Typography
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => handleCommunityClick(index)}
              >
                View Details
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => handleCommunityClickUpdate(index)}
              >
                Update Details
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
</TableContainer>



        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={list_community_admin.length > 0 && Math.ceil(list_community_admin[1][0]["total_row_count"] / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="medium"
                />
            </div>



            {openCommunity == true ? <DetailDialog isopen={true} setClose={handleCloseCommunity}   Details={list_community_admin[0][index]}  /> : ""}


       </div>

        }
        
        </>);
    
    
    





}

export default ListCommunity;
import { useTranslation } from "react-i18next";
import '../../styles/timelineother.css';
import RoundedPhoto from "../../app_utils/RoundedPhoto";
import { Button, Grid, IconButton, Input, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { MenuOutlined } from '@mui/icons-material';
import React from "react";
import ConfirmationModal from "../search_result/ConfirmationModal";
import MsgConfirm from "../../app_utils/MsgConfirm";
import { COMMUNITY_ID, IMAGE_URL, NAME, USER_ID } from "../../common_utils/constants";
import { useResize } from "react-spring";
import { useNavigate, useSearchParams } from "react-router-dom";





function UserDetail({ list_member_details, InsertViewProfile, data_insert_view_profile, urlSearchParams, insertShortlist_memberdetails, express_interest_insert_mmeberdetails, insert_block_user, insert_shortlist_data, insert_interest_data,
  insert_block_user_data, insertNotification_memberdetails, accept_reject_request_details, insert_accept_reject_list }) {

  const { t } = useTranslation();
  const initialized = useRef(false);


  useEffect(() => {


    console.log("check----"+list_member_details[7][0]);
  

    if (!initialized.current) {
      initialized.current = true;

      InsertViewProfile({
        "from_id": localStorage.getItem(USER_ID),
        "memberId": urlSearchParams.get('userId'),
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });

    }


  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [isconfirmInterest, setConfirmInterest] = useState(false);
  const [isblockUser, setBlockuser] = useState(false);

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setAnchorEl(null);
  };

  const handleclosemsg = () => {

    if (urlSearchParams.get("shortlist") == "--" || urlSearchParams.get("shortlist") == "---" || urlSearchParams.get("shortlist") == "----") {

      setMsg(false);


    } else {
      setMsg(false);
      window.location.reload();
    }

  }

  const handleopenmsg = () => {
    setMsg(true);
  }

  const handleOpenInterest = () => {
    setConfirmInterest(true);
  };

  const handleCloseInterest = () => {
    setConfirmInterest(false);
    setAnchorEl(null);
  };



  const handleOpenBlock = () => {
    setBlockuser(true);
  };

  const handleCloseBlock = () => {
    setBlockuser(false);
    setAnchorEl(null);
  };



  const handleConfirmAction = () => {

    insertShortlist_memberdetails({
      "fromId": localStorage.getItem(USER_ID),
      "memberId": urlSearchParams.get("userId"),
      "is_shortlist": list_member_details[11][0]["0"]["shortlist"] != undefined && list_member_details[11][0]["0"]["shortlist"].split(",").includes(urlSearchParams.get("userId")) ? "0" : "1",
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });

    handleCloseConfirmation();

    setTimeout(() => {

      if (insert_shortlist_data.affectedRows == undefined) {

        if (list_member_details[11][0]["0"]["shortlist"] != undefined && list_member_details[11][0]["0"]["shortlist"].split(",").includes(urlSearchParams.get("userId"))) {
          let message = "Shortlist removed successfully";
          setTitle("Shortlist");
          setContent(message);
          handleopenmsg();


        } else {
          let message = "ShortList done succescfully";
          setTitle("Shortlist");
          setContent(message);
          handleopenmsg();

        }

      } else {

        let message = "ShortList done succescfully";
        setTitle("Shortlist");
        setContent(message);
        handleopenmsg();


      }




    }, 500);

  };

  const handleConfirmInterest = () => {

    express_interest_insert_mmeberdetails({
      "from_id": localStorage.getItem(USER_ID),
      "to_id": urlSearchParams.get("userId"),
      "is_sent": "1",
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });


    insertNotification_memberdetails({
      "notifi_type": "interest",
      "message": localStorage.getItem(NAME) + " sent you the like request or interest",
      "sender_type": "user",
      "sender_id": localStorage.getItem(USER_ID),
      "reciever_id": urlSearchParams.get("userId"),
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });

    handleCloseInterest();

    setTimeout(() => {

      let message = "Expressed Interest Successfully";
      setTitle("Interest");
      setContent(message);
      handleopenmsg();

    }, 500);

  };




  const handleConfirmBlock = () => {



    insert_block_user({
      "from_id": localStorage.getItem(USER_ID),
      "memberId": urlSearchParams.get("userId"),
      "isblock": list_member_details[13][0]["0"]["blocked_users"] != undefined && list_member_details[13][0]["0"]["blocked_users"].split(",").includes(urlSearchParams.get("userId")) ? "0" : "1",
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });

    handleCloseBlock();

    setTimeout(() => {

      if (insert_block_user_data.affectedRows == undefined) {

        if (list_member_details[13][0]["0"]["blocked_users"] != undefined && list_member_details[13][0]["0"]["blocked_users"].split(",").includes(urlSearchParams.get("userId"))) {
          let message = "Block removed successfully";
          setTitle("Block User");
          setContent(message);
          handleopenmsg();


        } else {
          let message = "Block done succescfully";
          setTitle("Block User");
          setContent(message);
          handleopenmsg();

        }

      } else {

        let message = "Block done succescfully";
        setTitle("Block User");
        setContent(message);
        handleopenmsg();


      }




    }, 500);

  };



  const gobackForAcceptRejectRequest = () => {


    if (urlSearchParams.get("shortlist") == "--") {

      let message = "Now Having seen user details well , now you should go back to previous page for accepting or reject the like request presented before you";
      setTitle("Accept/Reject Request");
      setContent(message);
      handleopenmsg();

    } else if (urlSearchParams.get("shortlist") == "---") {

      let message = "Now Having seen user details well , now you should go back to previous page for granting the permission/Rejecting the Permission for Mobile Number/call request";
      setTitle("Grant/Reject Call Request");
      setContent(message);
      handleopenmsg();

    }else if(urlSearchParams.get("shortlist") == "----"){


      let message = "Now Having seen user details well , now you should go back to previous page for granting the permission/Rejecting the Permission for Horoscope Request";
      setTitle("Grant/Reject Horoscope Request");
      setContent(message);
      handleopenmsg();

    }




  }







  return (
    <>
      <h1 className="project-name">User Details</h1>

      {urlSearchParams.get("shortlist") == "--" || urlSearchParams.get("shortlist") == "---" ? <div className="rounded_accept_reject" style={{ padding: "10px", marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Button onClick={gobackForAcceptRejectRequest} className="accept">
          {urlSearchParams.get("shortlist") == "--" ? "Accept" : urlSearchParams.get("shortlist") == "---" ? "Call Permission" : urlSearchParams.get("shortlist") == "----" ? "Horoscope Details Permission" : ""}
        </Button>
        <Button onClick={gobackForAcceptRejectRequest} className="reject" style={{ marginLeft: "20px" }}>
          {urlSearchParams.get("shortlist") == "--" ? "Reject" : urlSearchParams.get("shortlist") == "---" ? "Reject Permission" : urlSearchParams.get("shortlist") == "----" ? "Reject Horoscope Permission" : ""}
        </Button>
      </div> : ""
      }

      <div style={{ "position": "absolute", "top": urlSearchParams.get("shortlist") == "--" ? "70%" : "60%", "left": "60%" }} >

        <Grid container xs={12} md={12} style={{ justifyContent: "space-around" }}>

          <Grid item xs={12}>
           <RoundedPhoto imageUrl={ list_member_details[7][0]["0"] !== undefined ? IMAGE_URL+"/uploads/"+list_member_details[7][0]["0"]["pic1"] : ""} altText={""} /> 
          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Grid>

      </div>




      <div style={{ position: 'absolute', top: urlSearchParams.get("shortlist") == "--" ? '100%' : '90%', left: '61%', zIndex: '999' }}>

        <Grid container>
          { list_member_details[12][0]["0"] != undefined ?
          <Grid container xs={10}>
            <Grid item xs={8}><div style={{ "color": "#fff", width: "130px", backgroundColor: "#3498db", padding: "10px" }}>{ list_member_details[12][0]["0"]["likes"].split(",").includes(urlSearchParams.get('userId')) ? "Interest Sent" : "Send Interest?" }</div></Grid> <Grid item xs={2}>{ list_member_details[12][0]["0"]["likes"].split(",").includes(urlSearchParams.get("userId")) ? "" : <div style={{ "color": "#fff", backgroundColor: "#D74894", "marginLeft": "-10px", width: "50px", textAlign: "center", padding: "10px", marginRight: "5px" }} onClick={handleOpenInterest}>Yes</div>}</Grid> <Grid item xs={2}>{list_member_details[12][0]["0"]["likes"].split(",").includes(urlSearchParams.get("userId")) ? "" : <div style={{ "color": "#ffff", backgroundColor: "#979695", padding: "10px" }} onClick={handleOpenInterest} >No</div>}</Grid>
          </Grid> 
          : 

          <Grid container xs={10}>
            <Grid item xs={8}><div style={{ "color": "#fff", width: "130px", backgroundColor: "#3498db", padding: "10px" }}>{ "Send Interest?" }</div></Grid> <Grid item xs={2}>{  <div style={{ "color": "#fff", backgroundColor: "#D74894", "marginLeft": "-10px", width: "50px", textAlign: "center", padding: "10px", marginRight: "5px" }} onClick={handleOpenInterest}>Yes</div>}</Grid> <Grid item xs={2}>{ <div style={{ "color": "#ffff", backgroundColor: "#979695", padding: "10px" }} onClick={handleOpenInterest} >No</div>}</Grid>
          </Grid>

  }
         

          <Grid item xs={2}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ backgroundColor: "#3498db", "marginLeft": "10px" }}
            >
              <MenuOutlined />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenConfirmation}>{ list_member_details[11][0]["0"] != undefined ?  list_member_details[11][0]["0"]["shortlist"].split(",").includes(urlSearchParams.get('userId')) ? "Shortlisted" : "ShortList Me" : "ShortList Me"}</MenuItem>
              <MenuItem onClick={handleOpenBlock}>{ list_member_details[13][0]["0"] != undefined ? list_member_details[13][0]["0"]["blocked_users"].split(",").includes(urlSearchParams.get('userId')) ? "Blocked Profile" : "Block Me" : "Block Me"}</MenuItem>

            </Menu>
          </Grid>
        </Grid>
      </div>



      <div id="timeline">
        <div className="timeline-item">
          <div className="timeline-icon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="21px"
              height="20px"
              viewBox="0 0 21 20"
              enableBackground="new 0 0 21 20"
              xmlSpace="preserve"
            >
              <path
                fill="#FFFFFF"
                d="M19.998,6.766l-5.759-0.544c-0.362-0.032-0.676-0.264-0.822-0.61l-2.064-4.999 c-0.329-0.825-1.5-0.825-1.83,0L7.476,5.611c-0.132,0.346-0.462,0.578-0.824,0.61L0.894,6.766C0.035,6.848-0.312,7.921,0.333,8.499 l4.338,3.811c0.279,0.246,0.395,0.609,0.314,0.975l-1.304,5.345c-0.199,0.842,0.708,1.534,1.468,1.089l4.801-2.822 c0.313-0.181,0.695-0.181,1.006,0l4.803,2.822c0.759,0.445,1.666-0.23,1.468-1.089l-1.288-5.345 c-0.081-0.365,0.035-0.729,0.313-0.975l4.34-3.811C21.219,7.921,20.855,6.848,19.998,6.766z"
              />
            </svg>
          </div>
          <div className="timeline-content">
            <h2>{t('basic_details_label')}</h2>

            <div className="basic_details_value">
              <span className="key1">{t('name_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["fullname"]}</span><br />
              <span className="key1">{t('age_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["age"]}</span><br />
              <span className="key1">{t('marital_status_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["marital_status"]}</span><br />
              <span className="key1">{t('created_by_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["created_by"]}</span><br />
              <span className="key1">{t('caste_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["caste"]}</span><br />
              <span className="key1">{t('subcaste_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["subcaste"]}</span><br />
              <span className="key1">{t('language_known_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["language_known"]}</span><br />
              <span className="key1">{t('mother_tongue_l') + ":"}</span> <span className="value1">{list_member_details[0][0]["0"]["mother_tongue"]}</span><br />
             
             {list_member_details[2][0]["0"] != undefined ?
             <div>
              <span className="key1">{t('weight_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["weight"] + " Kg"}</span> <span className="key1">{t('height_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["height"]}</span><br />
              <span className="key1">{t('skintone_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["skintone"]}</span> <span className="key1">{t('blood_group_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["blood_group"]}</span><br />
              <span className="key1">{t('fitness_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["fitness"]}</span><br />
              <span className="key1">{t('bodytype_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["body_type"]}</span> <span className="key1">{t('diet_type_l') + ":"}</span>  <span className="value1">{list_member_details[2][0]["0"]["diet_type"]}</span><br />
              <div>{list_member_details[2][0]["0"]["is_handicap"] == "1" ? <span className="value1">{list_member_details[2][0]["0"]["handicap_detail"]}</span> : <></>}</div>
              <span className="key1">{t('body_type_p_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["body_type"]}</span><br />
              <span className="key1">{t('diet_type_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["diet_type"]}</span>  <span className="key1">{t('drink_type_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["drink_type"]}</span><br />
              <span className="key1">{t('smoke_type_l') + ":"}</span> <span className="value1">{list_member_details[2][0]["0"]["smoke_type"]}</span>
            </div>

            : ""

             }

            </div>

          </div>
        </div>

        <div className="timeline-item">
          <div className="timeline-icon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="21px"
              height="20px"
              viewBox="0 0 21 20"
              enableBackground="new 0 0 21 20"
              xmlSpace="preserve"
            >
              <g>
                <path
                  fill="#FFFFFF"
                  d="M17.92,3.065l-1.669-2.302c-0.336-0.464-0.87-0.75-1.479-0.755C14.732,0.008,7.653,0,7.653,0v5.6 c0,0.096-0.047,0.185-0.127,0.237c-0.081,0.052-0.181,0.06-0.268,0.02l-1.413-0.64C5.773,5.183,5.69,5.183,5.617,5.215l-1.489,0.65 c-0.087,0.038-0.19,0.029-0.271-0.023c-0.079-0.052-0.13-0.141-0.13-0.235V0H2.191C1.655,0,1.233,0.434,1.233,0.97 c0,0,0.025,15.952,0.031,15.993c0.084,0.509,0.379,0.962,0.811,1.242l2.334,1.528C4.671,19.905,4.974,20,5.286,20h10.307 c1.452,0,2.634-1.189,2.634-2.64V4.007C18.227,3.666,18.12,3.339,17.92,3.065z M16.42,17.36c0,0.464-0.361,0.833-0.827,0.833H5.341 l-1.675-1.089h10.341c0.537,0,0.953-0.44,0.953-0.979V2.039l1.459,2.027V17.36L16.42,17.36z"
                />
              </g>
            </svg>
          </div>
          <div className="timeline-content right">
            <div>
              <h2>{t('education_occupation_l')}</h2>
            </div>


            <div className="basic_details_value">
              {list_member_details[3][0]["0"] != undefined ? <div>
              <div>{list_member_details[3][0]["0"]["is_from_admin_service"] == "1" ? <span className="value1">{"The Person is from Administrative Service His position is " + list_member_details[3][0]["0"]["admin_position_name"]}</span> : <></>}</div>
              <div>{list_member_details[3][0]["0"]["is_from_iit_iim_nit"] == "1" ? <span className="value1">{"The Person is from " + list_member_details[3][0]["0"]["institute_name"]}</span> : <></>}</div>
              <span className="key2">{t('education_p_l') + ":"}</span> <span className="value2">{list_member_details[3][0]["0"]["education"]}</span><br />
              <span className="key2">{t('education_detail_l') + ":"}</span> <span className="value2">{list_member_details[3][0]["0"]["education_detail"]}</span><br />
              
              </div>
              : ""
              }

             {
             list_member_details[4][0]["0"] != undefined ? <div>
              
              <span className="key2">{t('occupation_list_l') + ":"}</span> <span className="value2">{list_member_details[4][0]["0"]["occupation"]}</span><br />
              <span className="key2">{t('occupation_details_l') + ":"}</span> <span className="value2">{list_member_details[4][0]["0"]["occupation_detail"]}</span><br />
              <span className="key2">{t('annual_income_l') + ":"}</span> <span className="value2">{list_member_details[4][0]["0"]["annual_income"]}</span><br />
              <span className="key2">{t('employment_type_l') + ":"}</span> <span className="value2">{list_member_details[4][0]["0"]["employment_type"]}</span><br />
              <span className="key2">{t('office_address_l') + ":"}</span> <span className="value2">{list_member_details[4][0]["0"]["office_address"]}</span><br />
              </div> : "No Data Found For this user"

             }

            </div>



          </div>
        </div>

        <div className="timeline-item">
          <div className="timeline-icon">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="21px"
              height="20px"
              viewBox="0 0 21 20"
              enableBackground="new 0 0 21 20"
              xmlSpace="preserve"
            >
              <path
                fill="#FFFFFF"
                d="M19.998,6.766l-5.759-0.544c-0.362-0.032-0.676-0.264-0.822-0.61l-2.064-4.999 c-0.329-0.825-1.5-0.825-1.83,0L7.476,5.611c-0.132,0.346-0.462,0.578-0.824,0.61L0.894,6.766C0.035,6.848-0.312,7.921,0.333,8.499 l4.338,3.811c0.279,0.246,0.395,0.609,0.314,0.975l-1.304,5.345c-0.199,0.842,0.708,1.534,1.468,1.089l4.801-2.822 c0.313-0.181,0.695-0.181,1.006,0l4.803,2.822c0.759,0.445,1.666-0.23,1.468-1.089l-1.288-5.345 c-0.081-0.365,0.035-0.729,0.313-0.975l4.34-3.811C21.219,7.921,20.855,6.848,19.998,6.766z"
              />
            </svg>
          </div>
          <div className="timeline-content">
            <h2>{t('family_details_label')}</h2>

            {list_member_details[5][0]["0"] != undefined ?
            <div className="basic_details_value">

              <span className="key3">{t('family_status_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["family_status"]}</span><br />
              <span className="key3">{t('family_type_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["family_type"]}</span>  <span className="key3">{t('family_value_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["family_value"]}</span><br />
              <span className="key3">{t('no_brother_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["no_brother"]}</span>  <span className="key3">{t('no_sisters_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["no_sister"]}</span><br />
              <span className="key3">{t('no_brothers_married_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["married_brother"]}</span>  <span className="key3">{t('no_sisters_married_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["married_sister"]}</span><br />
              <span className="key3">{t('father_name_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["father_name"]}</span><br />
              <span className="key3">{t('mother_name_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["mother_name"]}</span><br />
              <span className="key3">{t('father_occupation_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["father_occupation"]}</span><br />
              <span className="key3">{t('mother_occupation_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["mother_occupation"]}</span><br />
              <span className="key3">{t('house_owned_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["house_owned"]}</span><br />
              <span className="key3">{t('house_type_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["house_type"]}</span><br />
              <span className="key3">{t('parent_stay_option_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["parents_stay_options"]}</span><br />
              <span className="key3">{t('details_family_l') + ":"}</span> <span className="value3">{list_member_details[5][0]["0"]["detail_family"]}</span>
            </div> : "\nNo Data Found For this user"
  }
          </div>
        </div>


        <ConfirmationModal
          isOpen={isConfirmationOpen}
          handleClose={handleCloseConfirmation}
          handleConfirm={handleConfirmAction}
          title={"ShortList this Profile"}
          content={list_member_details[11][0]["0"] != undefined ? list_member_details[11][0]["0"]["shortlist"] != undefined && list_member_details[11][0]["0"]["shortlist"].split(",").includes(urlSearchParams.get("userId")) ? "Are You sure you want to remove this shortlist" : "Are You sure You want to shortlist this profile" : ""}
        />


        <ConfirmationModal
          isOpen={isconfirmInterest}
          handleClose={handleCloseInterest}
          handleConfirm={handleConfirmInterest}
          title={"Express Interest"}
          content={"Are you sure you want to Express your Interest to this person?"}
        />


        <ConfirmationModal
          isOpen={isblockUser}
          handleClose={handleCloseBlock}
          handleConfirm={handleConfirmBlock}
          title={"Block User"}
          content={list_member_details[13][0]["0"] != undefined ?  list_member_details[13][0]["0"]["blocked_users"] != undefined && list_member_details[13][0]["0"]["blocked_users"].split(",").includes(urlSearchParams.get("userId")) ? "Are You sure you want to remove this Block" : "Are You sure You want to Block this user?" : ""}
        />



        <MsgConfirm
          isOpen={msg}
          handleClose={handleclosemsg}
          title={title}
          content={content}
          closeText={urlSearchParams.get("shortlist") == "--" ? "Ok" : "Close"}
        />


      </div>
    </>
  );



}
export default UserDetail;
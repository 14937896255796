
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ROLE } from "../../../common_utils/constants";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAdminsupportTicketContext } from "../../contexts_admin/supportTicket_context";
import { DateTime } from "luxon";
import React from "react";




function UpdateSupportTikcet(){


    const { data_support_update , SupportTicketUpdate , loading_supportTikcet_admin} = useAdminsupportTicketContext();
    const statusOptions = ['Open',  'Assigned' ,'In Progress', 'On Hold' , 'Resolved'  , 'Closed' , 'Reopened' , 'Cancelled'];
    const priorityOptions = ['High', 'Medium', 'Low' , 'Emergency'];

    let role = localStorage.getItem(ROLE);
    const { communityId } = useParams();
    const [issubmit , setIsSubmit] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(()=>{

        const details = location.state?.myObject;
        
        const initialTicketData = {
            title: details.key.title,
            customer_name: details.key.customer_name,
            customer_phone : details.key.customer_phone,
            customer_timing: details.key.customer_timing,
            created_by: details.key.created_by,
            assigned_to: details.key.assigned_to ,
            date_time_complain: details.key.date_time_complain,
            customer_complaint: details.key.customer_complaint,
            description: details.key.description,
            status: details.key.status_ticket,
            priority: details.key.priority
          };

        setTicketData(initialTicketData);


    },[location]);


    useEffect(()=>{

        if(data_support_update.affectedRows >= 1 && issubmit == true){
           
            navigate("/list_support_ticket");
            window.location.reload();
             
          }


    },[data_support_update]);


    const [ticketData, setTicketData] = useState({
        title: '',
        customer_name: '',
        customer_phone :'',
        customer_timing: '',
        created_by: '',
        assigned_to: '',
        date_time_complain: '',
        customer_complaint: '',
        description: '',
        status: '',
        priority: ''
      });

     
      const handleChange = (event) => {
        const { name, value } = event.target;
        setTicketData({ ...ticketData, [name]: value });
      };


      const [formValid, setFormValid] = useState(true);
    const addsupportTicket = () => {


        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString()+" "+currentDate.getHours()+":"+currentDate.getMinutes(); 

        const isValid = Object.values(ticketData).every(value => value.trim() !== '');
        setFormValid(isValid);

        if (isValid) {

        const data = {
            title: ticketData.title,
            customer_name: ticketData.customer_name,
            customer_phone :ticketData.customer_phone,
            customer_timing: ticketData.customer_timing,
            date_time_complain: ticketData.date_time_complain,
            description: ticketData.description,
            status_ticket: ticketData.status,
            status_colour: '#FFA500',
            priority: ticketData.priority,
            created_by: ticketData.created_by,
            assigned_to: ticketData.assigned_to,
            updated_at: formattedDate,
            ticket_id: location.state?.myObject.key.ticket_id
          };

          SupportTicketUpdate(data);
          setIsSubmit(true);



        }else{

           alert("All Fields are compulsory");

        }
          


    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>

         <Typography variant="h5" style={{ color: '#800080' , backgroundColor:"pink" , padding:"10px 20px" , fontWeight:"bold"}}>Update Support Ticket</Typography>

         <Grid container spacing={1}>


         <Grid  item xs={12}>
        <TextField
        label="Ticket Title"
        fullWidth
        name="title"
        value={ticketData.title}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
      />
      </Grid>

      <Grid  item xs={8}>
      <TextField
        label="Customer Name"
        fullWidth
        name="customer_name"
        margin="normal"
        value={ticketData.customer_name}
        onChange={handleChange}
        variant="outlined"
      />
      </Grid>


       <Grid  item xs={4}>
      <TextField
        label="Customer Mobile"
        fullWidth
        name="customer_phone"
        margin="normal"
        value={ticketData.customer_phone}
        onChange={handleChange}
        variant="outlined"
      />
      </Grid>

      <Grid  item xs={12}>

      <TextField
        label="Customer Complaint"
        multiline
        name="customer_complaint"
        rows={4}
        fullWidth
        value={ticketData.customer_complaint}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
      />


      </Grid>

      <Grid  item xs={6}>
      <TextField
        label="Customer Timing"
        fullWidth
        name="customer_timing"
        margin="normal"
        value={ticketData.customer_timing}
        onChange={handleChange}
        variant="outlined"
      />
     </Grid>


     <Grid  item xs={6}>
    <TextField
        label="Date & Time Complain"
        fullWidth
        margin="normal"
        name="date_time_complain"
        value={ticketData.date_time_complain}
        onChange={handleChange}
        variant="outlined"
      />

      </Grid>



      <Grid  item xs={12}>
    <TextField
        label="Description"
        multiline
        rows={4}
        fullWidth
        name="description"
        value={ticketData.description}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
      />
      </Grid>


<Grid  item xs={6}>
<TextField
        select
        label="Status"
        fullWidth
        name="status"
        margin="normal"
        value={ticketData.status}
        onChange={handleChange}
        variant="outlined"
      >
        {statusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      </Grid>


      <Grid  item xs={6}>
      <TextField
        select
        label="Priority"
        fullWidth
        name="priority"
        margin="normal"
        value={ticketData.priority}
        onChange={handleChange}
        variant="outlined"
      >
        {priorityOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      </Grid>

      <Grid  item xs={6}>
      <TextField
        label="Created By"
        fullWidth
        name="created_by"
        value={ticketData.created_by}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
      />
      </Grid>

      <Grid  item xs={6}>
      <TextField
        label="Assigned To"
        fullWidth
        name="assigned_to"
        margin="normal"
        value={ticketData.assigned_to}
        onChange={handleChange}
        variant="outlined"
      />
      </Grid>


      <Grid item xs={12} alignContent={"center"} alignItems={"center"} paddingBottom={"30px"}>
           <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , justifyContent:"center" , width:"100%"}} onClick={addsupportTicket} >Update Support Ticket</Button>

      </Grid>


         </Grid>

        </div>
    );


}

export default UpdateSupportTikcet;
import React, { useState , useEffect} from "react";
import {
  Collapse,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
  createTheme,
  getTouchRippleUtilityClass
} from "@mui/material";
import { Link } from "react-router-dom";
import  {Menu ,ExpandLess ,ExpandMore , Mail , ManageSearchOutlined , Download , VerifiedUser , Home, Details , PhotoLibrary , CastForEducation , Work ,Search , AccountBox , Money} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import i18n from "../translations/i18n";
import "../translations/en.json";
import { about ,profile ,matches ,searches, profile_acitivty , inbox } from "../data/link_menu";  






function DrawerComponentLogin() {


  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);

  

    let items_profile = [];
  let items_searches = [];
  let items_matches = [];
  let items_profile_acitivity = [];
  let items_inbox = [];


  const [open, setOpen] = React.useState(getTouchRippleUtilityClass);
  const [opensearch, setSearch] = React.useState(getTouchRippleUtilityClass);
  const [openmatches, setMatches] = React.useState(getTouchRippleUtilityClass);
  const [openInbox, setInbox] = React.useState(getTouchRippleUtilityClass);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickSearch = () => {
    setSearch(!opensearch);
  };

  const handleClickMatches = () => {
    setMatches(!openmatches);
  };

  const handleClickInbox = () => {
    setInbox(!openInbox);
  };



  const [openDrawer, setDrawerOpen] = React.useState(false);

  const handleClickDrawer = () => {
    setDrawerOpen(!openDrawer);
  };

  const text ={
     color: "white" , fontSize:"18px" , fontFamily: "Roboto, sans-serif" , fontWeight:"bold"
  }

  let item_about = [];
  let about_ion = [];

  return (
  

<div id="main-div" style={{
             backgroundColor:"#D74894"  , boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            display: "flex",
            width:"100%",
            justifyContent:"center",
            alignContent:"center",
            position:"absolute",
            top:"0",
            right:"0",
            padding:"20px",
            zIndex:"1",
            }}>

<div style={{color: "white", backgroundColor:"transparent" , display:"flex" ,position:"absolute" , top:"10" ,right:"0" , marginRight:"20px" , alignContent:"center"}}>
<Icon onClick={() => setDrawerOpen(!openDrawer)} >
        <Menu  />
      </Icon>
      </div>

      <Drawer
        open={openDrawer}
        PaperProps={{
          sx: { width: "40%" , 
        backgroundColor:"#D74894",
      color:"black"},
        }}
        width={200}
        onClose={() => setDrawerOpen(false)} >
        <List >


        <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <AccountBox style={{ color: "white" }}/>
        </ListItemIcon>
        <ListItemText primary={t('my_profile')} primaryTypographyProps={{ style: text }} />
        {open ? <ExpandLess  style={{ color: "white"}}/> : <ExpandMore  style={{ color: "white"}} />}
      </ListItemButton>
      <Collapse in={!open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{

   profile.map((s,i) => {
  items_profile.push(<><ListItemButton sx={{ pl: 4 }} onClick={() => setDrawerOpen(false)}>
  <ListItemIcon>
    
  </ListItemIcon>
  <ListItemText>
    <Link to={s[1]} style={{textDecoration:"none",
color: "white",
fontSize: "16px",}}>{t(s[0])}</Link>
  </ListItemText>
</ListItemButton></>);

        })
        
        }
          {items_profile}
        </List>
      </Collapse>

          <Divider/>


          <ListItemButton onClick={handleClickSearch}>
        <ListItemIcon>
          <Search style={{ color: "white" }}/>
        </ListItemIcon>
        <ListItemText primary={t('search')} primaryTypographyProps={{ style: text }} />
        {opensearch ? <ExpandLess  style={{ color: "white"}}/> : <ExpandMore  style={{ color: "white"}} />}
      </ListItemButton>
      <Collapse in={!opensearch} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{

   searches.map((s,i) => {
  items_searches.push(<><ListItemButton sx={{ pl: 4 }} onClick={() => setDrawerOpen(false)}>
  <ListItemIcon>
    
  </ListItemIcon>
  <ListItemText>
    <Link to={s[1]} style={{textDecoration:"none",
color: "white",
fontSize: "16px",}}>{t(s[0])}</Link>
  </ListItemText>
</ListItemButton></>);

        })
        
        }
          {items_searches}
        </List>
      </Collapse>

          <Divider/>

          <ListItemButton onClick={handleClickMatches}>
        <ListItemIcon>
          <ManageSearchOutlined style={{ color: "white" }}/>
        </ListItemIcon>
        <ListItemText primary={t('latest_matches')} primaryTypographyProps={{ style: text }} />
        {openmatches? <ExpandLess  style={{ color: "white"}}/> : <ExpandMore  style={{ color: "white"}} />}
      </ListItemButton>
      <Collapse in={!openmatches} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{

   matches.map((s,i) => {
  items_matches.push(<><ListItemButton sx={{ pl: 4 }} onClick={() => setDrawerOpen(false)}>
  <ListItemIcon>
    
  </ListItemIcon>
  <ListItemText>
    <Link to={s[1]} style={{textDecoration:"none",
color: "white",
fontSize: "16px",}}>{t(s[0])}</Link>
  </ListItemText>
</ListItemButton></>);

        })
        
        }
          {items_matches}
        </List>
      </Collapse>

          <Divider/>




          <ListItemButton onClick={handleClickInbox}>
        <ListItemIcon>
          <Mail style={{ color: "white" }}/>
        </ListItemIcon>
        <ListItemText primary={t('inbox')} primaryTypographyProps={{ style: text }} />
        {openInbox? <ExpandLess  style={{ color: "white"}}/> : <ExpandMore  style={{ color: "white"}} />}
      </ListItemButton>
      <Collapse in={!openInbox} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{

   inbox.map((s,i) => {
  items_inbox.push(<><ListItemButton sx={{ pl: 4 }} onClick={() => setDrawerOpen(false)}>
  <ListItemIcon>
    
  </ListItemIcon>
  <ListItemText>
    <Link to={s[1]} style={{textDecoration:"none",
color: "white",
fontSize: "16px",}}>{t(s[0])}</Link>
  </ListItemText>
</ListItemButton></>);

        })
        
        }
          {items_inbox}
        </List>
      </Collapse>

          <Divider/>

          <ListItem onClick={() => setOpen(false)}>
        <ListItemButton onClick={() => setDrawerOpen(false)}>
        <ListItemIcon>
          <Money style={{ color: "white" }}/>
        </ListItemIcon>
            <ListItemText>
              <Link to="/membership" style={{textDecoration:"none",
        color: "white",
        fontSize: "16px", fontWeight:"bold"}}>{t('upgrade')}</Link>
            </ListItemText>
            </ListItemButton>
          </ListItem>
          
          <Divider/>


          <ListItem onClick={() => setOpen(false)}>
        <ListItemButton onClick={() => setDrawerOpen(false)}>
        <ListItemIcon>
          <Money style={{ color: "white" }}/>
        </ListItemIcon>
            <ListItemText>
              <Link to="/chat_message" style={{textDecoration:"none",
        color: "white",
        fontSize: "16px", fontWeight:"bold"}}>{t('chat_message')}</Link>
            </ListItemText>
            </ListItemButton>
          </ListItem>
          
          <Divider/>

          

          <ListItem onClick={() => setOpen(false)}>
        <ListItemButton onClick={() => setDrawerOpen(false)}>
        <ListItemIcon>
          <Download style={{ color: "white" }}/>
        </ListItemIcon>
            <ListItemText>
              <Link to="/download" style={{textDecoration:"none",
        color: "white",
        fontSize: "16px", fontWeight:"bold"}}>{t('download_app')}</Link>
            </ListItemText>
            </ListItemButton>
          </ListItem>
          
          <Divider/>


        </List>
      </Drawer>
      
    </div>
  );
}

export default DrawerComponentLogin;
import React, { useState, useRef, useEffect } from "react";
import DayPicker from "./DayPicker";
import MonthPicker from "./MonthPicker";
import YearPicker from "./YearPicker";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function  DatePicker ({ showToday , setPassDate ,getPassDate }){
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [showYearPicker, setShowYearPicker] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [date, setDate] = useState(showToday ? new Date().getDate() : "DD");
  const [month, setMonth] = useState(
    showToday ? new Date().getMonth() + 1 : "MM"
  );
  const [year, setYear] = useState(showToday ? new Date().getFullYear() : "YYYY");
  const [formattedDate, setFormattedDate] = useState("DD/MM/YYYY");

  const dateInput = useRef(null);

  useEffect(()=>{

    
   setFormattedDate(getPassDate);

  },[getPassDate]);

  const resetDate = () => {
    const today = new Date();
    const _date = showToday ? today.getDate() : "DD";
    const _month = showToday ? today.getMonth() + 1 : "MM";
    const _year = showToday ? today.getFullYear() : "YYYY";

    setDate(_date);
    setMonth(_month);
    setYear(_year);
    setFormattedDate(`${_date}/${_month}/${_year}`);
  };

  const renderDayPicker = () => {
    setShowDayPicker(true);
    setShowDropdown(true);
    dateInput.current.focus();
  };

  const renderMonthPicker = (d) => {
    const _date = d.toString().padStart(2, "0");
    setShowDayPicker(false);
    setShowMonthPicker(true);
    setDate(_date);
    setFormattedDate(`${_date}/${month}/${year}`);
  };

  const renderYearPicker = (m) => {
    const _month = m.toString().padStart(2, "0");
    setShowMonthPicker(false);
    setShowYearPicker(true);
    setMonth(_month);
    setFormattedDate(`${date}/${_month}/${year}`);

   
    
  };

  const onDatePicked = (y) => {
    setShowDropdown(false);
    setShowYearPicker(false);
    setYear(y);
    setFormattedDate(`${date}/${month}/${y}`);


  //  alert(`${date}/${month}/${y}`);

    setPassDate(`${date}/${month}/${y}`);
  };

  const onDateChange = (e) => {
    const _value = e.target.value;
    const [_date, _month, _year] = _value.split("/");

    const showMonth = _value.indexOf("/") >= 1;
    const showYear = _value.lastIndexOf("/") >= 3;

    if (showMonth) {
      renderMonthPicker(_date);
    }
    if (showYear) {
      setShowMonthPicker(false);
      setShowYearPicker(true);
      setMonth(_month);
    }

    if (_year && _year.length === 4) {
      setShowDropdown(false);
      setShowYearPicker(false);
    }

    setFormattedDate(_value);
    setPassDate(_value);

    alert(formattedDate);

  };

  return (
    <div>
      <div>
        <input
          value={formattedDate}
          onFocus={renderDayPicker}
          onChange={onDateChange}
          ref={dateInput}
          style={{borderRadius:"10px" , border:'2px solid black' , padding:"10px"}}
        />
      </div>
      {showDropdown && (
        <div>
          {showDayPicker && <DayPicker onDatePicked={renderMonthPicker} />}
          {showMonthPicker && (
            <MonthPicker date={date} onMonthPicked={renderYearPicker} />
          )}
          {showYearPicker && <YearPicker onYearPicked={onDatePicked} />}
        </div>
      )}
    </div>
  );
};

export default DatePicker;

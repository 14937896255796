import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { produce } from "immer";
import { CSSTransition } from "react-transition-group";
import { Button, Grid, Typography } from "@mui/material";
import { DropdownSelect, StyledTextField } from "../../../../app_utils/input_fields";
import { COMMUNITY_ID, PROFILE_ID, TRANSLATED_LANG, USER_ID } from "../../../../common_utils/constants";
import { register } from "validatorjs";
import { isValidEmail } from "../../../../common_utils/validations";
import { useLocation, useNavigate } from "react-router-dom";
import RotatingHeart from "../../../../common_utils/loading_animator";
import { useContactAdminContext } from "../../../contexts_admin/members_context/contactdetailsContextAdmin";
import React from "react";






function ContactInputAdmin() {


    const { data_contact_admin , loading, error, fetch_cityAdmin, contactInsert_admin, contactUpdate_admin, data_city_perm_admin, data_city_work_admin, submit_contactdetailsAdmin, update_contactdetailsAdmin , fetchContactItemsAdmin} = useContactAdminContext();
    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();

    const [country, setCountry] = useState([]);
    const [statelistPermanent, setStateListpermanent] = useState([]);
    const [statelistWork, setStateListWork] = useState([]);


    const [citylistPermanent, setCityListpermanent] = useState([]);
    const [citylistWork, setCityListWork] = useState([]);

    const [emailid, setEmailId] = useState(true);
    const [emailidAlt, setEmailIdAlt] = useState(true);
    const [load, loadset] = useState(true);

    const [address, setAddress] = useState('');
    const navigate = useNavigate();


    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };


    const state_lngwise = (state, type) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const state_list = state.map(user => ({
                label: user.state_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setStateListpermanent(state_list);
            } else if (type == "work") {
                setStateListWork(state_list);
            }

        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const state_list = state.map(user => ({
                label: user.state_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setStateListpermanent(state_list);
            } else if (type == "work") {
                setStateListWork(state_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const state_list = state.map(user => ({
                label: user.state_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setStateListpermanent(state_list);
            } else if (type == "work") {
                setStateListWork(state_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const state_list = state.map(user => ({
                label: user.state_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setStateListpermanent(state_list);
            } else if (type == "work") {
                setStateListWork(state_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const state_list = state.map(user => ({
                label: user.state_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setStateListpermanent(state_list);
            } else if (type == "work") {
                setStateListWork(state_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const state_list = state.map(user => ({
                label: user.state_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setStateListpermanent(state_list);
            } else if (type == "work") {
                setStateListWork(state_list);
            }
        }


    }


    const city_lngwise = (city, type) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const city_list = city.map(user => ({
                label: user.city_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setCityListpermanent(city_list);
            } else if (type == "work") {
                setCityListWork(city_list);
            }

        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const city_list = city.map(user => ({
                label: user.city_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setCityListpermanent(city_list);
            } else if (type == "work") {
                setCityListWork(city_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const city_list = city.map(user => ({
                label: user.city_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setCityListpermanent(city_list);
            } else if (type == "work") {
                setCityListWork(city_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const city_list = city.map(user => ({
                label: user.city_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setCityListpermanent(city_list);
            } else if (type == "work") {
                setCityListWork(city_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const city_list = city.map(user => ({
                label: user.city_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setCityListpermanent(city_list);
            } else if (type == "work") {
                setCityListWork(city_list);
            }
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const city_list = city.map(user => ({
                label: user.city_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            if (type == "perm") {
                setCityListpermanent(city_list);
            } else if (type == "work") {
                setCityListWork(city_list);
            }
        }


    }

    const location = useLocation();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        const communityId = searchParams.get('communityId');
        const username = searchParams.get('username');

       
        fetchContactItemsAdmin({
                "userId":userId,
                "communityId":communityId,
                "original": "en",
                "translate": ["en"]
            });
        
      }, [location]);



    useEffect(() => {

        
        setAnimated(true);

        if (data_contact_admin.length > 0) {

            const country_list = data_contact_admin[1].flat().map(data => ({
                label: data_contact_admin.country_name,  // Adjust based on your data structure
                value: data_contact_admin.Id         // Adjust based on your data structure
            }));

            setCountry(country_list);

            state_lngwise(data_contact_admin[2], "perm");
            state_lngwise(data_contact_admin[3], "work");

            city_lngwise(data_contact_admin[4], "perm");
            city_lngwise(data_contact_admin[5], "work");



            const setup = data_contact_admin[0].flat()[0]["0"];

             

            if (setup != undefined) {
                const nextState = produce((draft) => {

                    draft["mobile_number"] = setup.mobile_number;
                    draft["whatsapp_number"] = setup.whatsapp_number;
                    draft["alternate_mobile"] = setup.alternate_mobile;
                    draft["emailId"] = setup.emailid;
                    draft["alternateEmailid"] = setup.alternate_email;
                    draft["perm_country"] = setup.perm_country;
                    draft["perm_state"] = setup.perm_state;
                    draft["perm_city"] = setup.perm_city;
                    draft["work_state"] = setup.work_state;
                    draft["work_city"] = setup.work_city;
                    draft["perm_addreess"] = setup.permanent_adddress;
                    draft["work_address"] = setup.working_address;
                    draft["contact_time"] = setup.contact_time;

                });

                setContactField(nextState);

            }


        }

        if (data_city_perm_admin.length > 0) {
            city_lngwise(data_city_perm_admin, "perm");
        }

        if (data_city_work_admin.length > 0) {
            city_lngwise(data_city_work_admin, "work");
        }


    }, [data_contact_admin, data_city_perm_admin, data_city_work_admin]);



    useEffect(() => {


       

        if (contactInsert_admin.affectedRows >= 1 || contactUpdate_admin.success == 1) {
            setTimeout(() => {

                navigate(-1);
            }, 2000);
        }

    }, [contactInsert_admin, contactUpdate_admin]);


    const [contactField, setContactField] = useState({
        mobile_number: '',
        whatsapp_number: '',
        alternate_mobile: '',
        emailId: '',
        alternateEmailid: '',
        perm_country: '',
        perm_state: '',
        perm_city: '',
        work_state: '',
        work_city: '',
        perm_addreess: '',
        work_address: '',
        contact_time: '',
    });

    const [errors, setErrors] = useState({
        mobile_number: false,
        whatsapp_number: false,
        emailId: false,
        alternateEmailid: false,
        perm_country: false,
        perm_state: false,
        perm_city: false,
        work_state: false,
        work_city: false,
        perm_addreess: false,
        work_address: false,
        contact_time: false
    });


    const [hasBeenFocused, setHasBeenFocused] = useState({
        mobile_number: false,
        whatsapp_number: false,
        emailId: false,
        alternateEmailid: false,
        perm_country: false,
        perm_state: false,
        perm_city: false,
        work_state: false,
        work_city: false,
        perm_addreess: false,
        work_address: false,
        contact_time: false,
    });

    const handleSubmit = () => {

        const newErrors = produce(errors, (draftErrors) => {
            for (const field in contactField) {
                const trimmedValue = contactField[field];

                if (field != 'alternate_mobile' && field != 'alternateEmailid') {
                    draftErrors[field] = trimmedValue === '' || trimmedValue.length <= 0;
                } else {
                    draftErrors[field] = false;
                }

                if (field == 'emailId') {
                    isValidEmail(contactField.emailId) == false ? setEmailId(false) : setEmailId(true);
                }

            }
        });


        setErrors(newErrors);


        if (Object.values(newErrors).some(error => error) == true) {

            //alert(data[0].map(innerArray => innerArray[0]).Id);

        } else {


            loadset(false);

            const searchParams = new URLSearchParams(location.search);
            const userId = searchParams.get('userId');
            const communityId = searchParams.get('communityId');
            const profile_id = searchParams.get('profile_id');
    


            const article = {
                "mobile_number": contactField.mobile_number,
                "whatsapp_number": contactField.whatsapp_number,
                "permanent_adddress": contactField.perm_addreess,
                "current_address": "",
                "emailid": contactField.emailId,
                "alternate_mobile": contactField.alternate_mobile,
                "alternate_email":  contactField.alternateEmailid,
                "working_address": contactField.work_address,
                "contact_time": contactField.contact_time,
                "perm_country": contactField.perm_country,
                "perm_state": contactField.perm_state,
                "perm_city": contactField.perm_city,
                "cur_country": "",
                "cur_state": "",
                "cur_city": "",
                "work_country": "0",
                "work_state": contactField.work_state,
                "work_city": contactField.work_city,
                "location": "0,0",
                "userId": userId,
                "communityId": communityId ,
                "profileId": profile_id,
                "Id": data_contact_admin[0].flat()[0]["0"] == undefined ? "0" : data_contact_admin[0].flat()[0]["0"].Id
            };


            if (data_contact_admin[0].flat()[0]["0"] == null || data_contact_admin[0].flat()[0]["0"] == undefined) {
                submit_contactdetailsAdmin(article);
            } else {
                update_contactdetailsAdmin(article);
            }









        }
    }



    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;


        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);

                if (contactField[fieldName] !== '' || fieldName == 'alternateEmailid') {
                    draftErrors[fieldName] = false;
                } else {
                    draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                }



            })
        );

    }

    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        if (fieldName == "perm_state") {

            const selectedIndex = data_contact_admin[2].flat().findIndex(option => option.Id === newValue);
            fetch_cityAdmin({
                "state_id": data_contact_admin[2].flat()[selectedIndex].Id,
                "country_id": "1",
                "original": "en",
                "translate": ["en"]
            }, 1);

        } else if (fieldName == "work_state") {

            const selectedIndex = data_contact_admin[2].flat().findIndex(option => option.Id === newValue);
            fetch_cityAdmin({
                "state_id": data_contact_admin[3].flat()[selectedIndex].Id,
                "country_id": "1",
                "original": "en",
                "translate": ["en"]
            }, 2);

        }

        const nextState = produce((draft) => {

            draft[fieldName] = newValue;
        });

        // Set the updated state
        setContactField(nextState);


        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {
                const trimmedValue = newValue;

                //alert(trimmedValue);


                draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '';

                if (fieldName == 'emailId') {
                    isValidEmail(contactField.emailId) == false ? setEmailId(false) : setEmailId(true);
                }

                if (fieldName == 'alternateEmailid') {
                    isValidEmail(contactField.alternateEmailid) == false ? setEmailIdAlt(false) : setEmailIdAlt(true);
                }



            })
        );

    };


    const handleInput = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
    };






    return (<>


        {load == false ? <RotatingHeart /> :
            <div style={{ display: 'grid', placeItems: 'center', position: "absolute", top: "65px", width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "30px",  padding: "30px" }}>



                <CSSTransition
                    in={true}
                    timeout={500} // Duration of the transition in milliseconds
                    classNames="fade"
                    unmountOnExit>

                    <Grid container xs={12} >

                    <Grid item xs={12}><Typography  variant="h6" align="center" width="400px" style={{padding:"5px" , borderRadius:"10px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" , marginLeft:"10px" , marginBottom:"40px"}}>{"Edit Contact Details of " +new URLSearchParams(location.search).get('username')}</Typography></Grid>


                        <Grid container xs={12} md={6} columnSpacing={2} rowSpacing={2}>

                            <Grid item xs={12} md={6} >
                                <StyledTextField label={t('mobile_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('mobile_number')}
                                    type="number"
                                    value={contactField.mobile_number}
                                    onInput={handleInput}
                                />
                                {errors.mobile_number && <span style={{ color: 'red' }}>Mobile No. required!</span>}
                            </Grid>


                            <Grid item xs={12} md={6} >

                                <StyledTextField label={t('alternate_mobile_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('alternate_mobile')}
                                    value={contactField.alternate_mobile}
                                    onInput={handleInput}

                                />
                            </Grid>


                            <Grid item xs={12} md={6} >

                                <StyledTextField label={t('contact_whatsapp_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('whatsapp_number')}
                                    value={contactField.whatsapp_number}
                                    onInput={handleInput}
                                />

                                {errors.whatsapp_number && <span style={{ color: 'red' }}>Wahtsapp No. required!</span>}

                            </Grid>



                            <Grid item xs={12} md={6} >

                                <StyledTextField label={t('emailid_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('emailId')}
                                    value={contactField.emailId}
                                />
                                {errors.emailId && <span style={{ color: 'red' }}>EmailID required!</span>}
                                {emailid == false && <span style={{ color: 'red' }}>Enter Valid EmailID</span>}
                            </Grid>




                            <Grid item xs={12} md={6} >

                                <StyledTextField label={t('alternate_email_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('alternateEmailid')}
                                    value={contactField.alternateEmailid}
                                />

                                {emailidAlt == false && <span style={{ color: 'red' }}>Enter Valid EmailID</span>}
                            </Grid>



                            <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>


                                <DropdownSelect options={country} label={t('country_name_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('perm_country')}
                                    value={contactField.perm_country}
                                    onMouseDown={handleClick('perm_country')}
                                />

                                {errors.perm_country && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>


                            <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>

                                <DropdownSelect options={statelistPermanent} label={t('perm_state_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('perm_state')}
                                    value={contactField.perm_state}
                                    onMouseDown={handleClick('perm_state')}
                                />
                                {errors.perm_state && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>



                            <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
                                <DropdownSelect options={statelistWork} label={t('work_state_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('work_state')}
                                    value={contactField.work_state}
                                    onMouseDown={handleClick('work_state')}
                                />
                                {errors.work_state && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>







                            <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>

                                <DropdownSelect options={citylistPermanent} label={t('perm_city_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('perm_city')}
                                    value={contactField.perm_city}
                                    onMouseDown={handleClick('perm_city')}
                                />
                                {errors.perm_city && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>



                            <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
                                <DropdownSelect options={citylistWork} label={t('work_city_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('work_city')}
                                    value={contactField.work_city}
                                    onMouseDown={handleClick('work_city')}
                                />
                                {errors.work_city && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>


                        </Grid>


                        <Grid container xs={12} md={1} columnSpacing={1} rowSpacing={1}>
                            <div style={{ backgroundColor: "#E3256B", width: "3px", marginTop: "10px", marginLeft: "55px" }}></div>
                        </Grid>

                        <Grid container xs={12} md={5} >


                            <Grid item xs={12} >

                                <StyledTextField label={t('permanent_address_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('perm_addreess')}
                                    rows={3}
                                    value={contactField.perm_addreess}
                                />
                                {errors.perm_addreess && <span style={{ color: 'red' }}>Permanent Resident Address required!</span>}
                            </Grid>



                            <Grid item xs={12} style={{ marginTop: "10px" }}>

                                <StyledTextField label={t('working_address_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('work_address')}
                                    rows={3}
                                    value={contactField.work_address}
                                />
                                {errors.work_address && <span style={{ color: 'red' }}>Work Address required!</span>}

                            </Grid>


                            <Grid item xs={12} style={{ marginTop: "10px" }}>

                                <StyledTextField label={t('contact_time_l')} focusedcolor={"#E3256B"}
                                    label2={t('contact_time_l')}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('contact_time')}
                                    value={contactField.contact_time}
                                />

                                {errors.contact_time && <span style={{ color: 'red' }}>Contact Duration required!</span>}

                            </Grid>

                        </Grid>


                    </Grid>
                </CSSTransition>

                <Button style={{
                    justifyContent: "center", alignContent: "center", marginTop: "50px",
                    background: "#E3256B", width: "400px", color: "white", fontWeight: "bold"
                }} onClick={handleSubmit}>Submit your Contact Details</Button>

            </div>




        }
    </>);





}

export default ContactInputAdmin;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerFamily from '../../reducer_admin/familyReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const familyAdminContext = createContext();

const initialState = {
  data_family_admin: [],
  loading: true,
  error: null,
  family_insert_admin:[],
  family_update_admin:[]
};

let API = `${BASE_URL}`;


export const FamilyAdminProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerFamily , initialState);

const fetchFamilyItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/family_details_edit` , params);

      dispatch({ type: 'FAMILY_DATA_FETCH_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAMILY_DATA_FETCH_ERROR_ADMIN', payload: error });
    }
  };



  const submit_familydetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_family` , article);
      dispatch({ type: 'INSERT_FAMILY_DETAILS_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_FAMILY_DETAILS_ERROR_ADMIN', payload: error });
    }
  };


  const update_familydetailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_family` , article);

      console.log(response.data);

      dispatch({ type: 'UPDATE_FAMILY_DETAILS_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_FAMILY_DETAILS_ERROR_ADMIN', payload: error });
    }
  };




 
  
  return <familyAdminContext.Provider value={{ ...state , dispatch , fetchFamilyItemsAdmin , submit_familydetailsAdmin , update_familydetailsAdmin }}>{children}</familyAdminContext.Provider>;
};

export const useFamilyAdminContext = () => useContext(familyAdminContext);
import * as React from "react";

import styles from "../../styles/CssModulesSlider.module.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from 'react-i18next';
import { getAge, marathiToEnglishNumber, toastify } from "../../common_utils/utils";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarBorder from '@mui/icons-material/StarBorder';
import Star from '@mui/icons-material/Star';
import Chat from '@mui/icons-material/Chat';
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useSearchContext } from "../../contexts/searchContext";
import ConfirmationModal from "./ConfirmationModal";
import { COMMUNITY_ID, GENDER, IMAGE_URL, IS_LOGIN, NAME, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from "react";
import ToastContainerWrapper from "./ToastContainerWrapper";
import MsgConfirm from "../../app_utils/MsgConfirm";
import { useNavigate } from "react-router-dom";
import { boolean } from "yup";
import MsgConfirmClose from "../../app_utils/MsgConfirmClose";
import { useNotificationContext } from "../../contexts/notificationContext";


const socialLinks = [
  { icon: StarBorder, icon_s: Star, info: "Click To Shortist this person" },
  { icon: FavoriteBorderIcon, icon_s: FavoriteIcon, info: "Click To Send Interest or Like to this person" },
  { icon: Chat, icon_s: Chat, info: "Click to Send Messages" }
];



export default function MatriProfileCard({ value, refresh }) {

  const { t } = useTranslation();
  const { insertShortlist, insert_shortlist, express_interest_insert, insert_interest, insertNotification, insert_notification_data } = useSearchContext();
  const { user_validation_data_list } = useNotificationContext();

  const [tooltipOpen, setTooltipOpen] = useState(Array(socialLinks.length).fill(false));
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [title_other, setTitleOther] = useState("");
  const [content_other, setContentOther] = useState("");
  const [shortlist, setshortlist] = useState(false);
  const [likes, setlikes] = useState(false);

  const navigate = useNavigate();

  // Toggle tooltip open state for a specific index
  const handleTooltipToggle = (index) => {
    const newTooltipOpen = [...tooltipOpen];
    newTooltipOpen[index] = !newTooltipOpen[index];
    setTooltipOpen(newTooltipOpen);
  };

  const handleClick = (event) => {

    

    if(localStorage.getItem(IS_LOGIN) == "1"){

    if (event.currentTarget.id == "shortlist") {

      handleOpenConfirmation(event);

    } else if (event.currentTarget.id == "likes") {
      if (user_validation_data_list[2][0]["remaining_days"] != null) {
        if (user_validation_data_list[1][0]["num_likes"] <= user_validation_data_list[0][0]["num_express_interests"]) {

          handleOpenInterest(event);
        } else {


          let message = "Sorry Your like limit as per your plan has reached it's limit";
          setTitle("Limit Reached");
          setContent(message);
          handleopenmsg();


        }
      } else {


        if (user_validation_data_list[1][0]["num_likes"] <= 2) {

          handleOpenInterest(event);
        } else {


          let message = "Please upgrade your plan as in free plan only five likes are allowed";
          setTitle("Upgrade Plan!");
          setContent(message);
          handleopenmsg();


        }


      }
    }
  }else{

    let message = "Please login to continue!";
            setTitleOther("Login Alert!");
            setContentOther(message);
            handleopenmsg_other();
    
  }

    event.stopPropagation();

  };






  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [msgother, setMsgOther] = useState(false);
  const [isconfirmInterest, setConfirmInterest] = useState(false);

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = (event) => {

    setConfirmationOpen(false);
    event.stopPropagation();

  };

  const handleclosemsg = (event) => {
    setMsg(false);
    refresh();

    event.stopPropagation();
  }


  const handleclickwithoutlogin = (event) => {
    setMsgOther(false);
    navigate('/login', { replace: true });
   
    event.stopPropagation();
  }

  const handlecancelmsg = (event) => {
    setMsgOther(false);
    event.stopPropagation();
  }

  const handleopenmsg = () => {
    setMsg(true);
  }

  const handleopenmsg_other = () => {
    setMsgOther(true);
  }

  const handleOpenInterest = () => {

    if(value.likes != undefined && value.likes.split(",").includes(value.userId)){

    }else{
    setConfirmInterest(true);
    }

  };

  const handleCloseInterest = (event) => {
    setConfirmInterest(false);
    event.stopPropagation();
  };


  const handleConfirmAction = (event) => {

    if (event) {

      event.stopPropagation();

      insertShortlist({
        "fromId": localStorage.getItem(USER_ID),
        "memberId": value.userId,
        "is_shortlist": value.shortlist != undefined && value.shortlist.split(",").includes(value.userId) ? "0" : "1",
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });

      handleCloseConfirmation(event);

      setTimeout(() => {

        if (insert_shortlist.affectedRows == undefined) {

          if (value.shortlist != undefined && value.shortlist.split(",").includes(value.userId)) {
            let message = "Shortlist removed successfully";
            setTitle("Shortlist");
            setContent(message);
            handleopenmsg();


          } else {
            let message = "ShortList done succescfully";
            setTitle("Shortlist");
            setContent(message);
            handleopenmsg();

          }

        } else {

          let message = "ShortList done succescfully";
          setTitle("Shortlist");
          setContent(message);
          handleopenmsg();


        }




      }, 500);

    }

  };

  const handleConfirmInterest = (event) => {


    if (event) {

      event.stopPropagation();

      express_interest_insert({
        "from_id": localStorage.getItem(USER_ID),
        "to_id": value.userId,
        "is_sent": "1",
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });

      insertNotification({
        "notifi_type": "interest",
        "message": localStorage.getItem(NAME) + " sent you the like request or interest",
        "sender_type": "user",
        "sender_id": localStorage.getItem(USER_ID),
        "reciever_id": value.userId,
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });

      handleCloseInterest(event);

      setTimeout(() => {

        let message = "Expressed Interest Successfully";
        setTitle("Interest");
        setContent(message);
        handleopenmsg();

      }, 500);

    }

  };


  const openUserDetails = () => {
    // Replace '/new-page' with the actual route you want to navigate to
    const newRoute = '/user_details';



    // Replace these with the actual sensitive data you want to pass
    const userId = value.userId;
    const shortlist = value.shortlist;
    const likes = value.likes;
    const blocked_users = value.blocked_users;

    // Store the data in a location state object
    const stateData = { userId, shortlist, likes, blocked_users };


    // Construct the URL with the state data
    const urlWithState = `${newRoute}?userId=${userId}&shortlist=${shortlist}&likes=${likes}&blocks=${blocked_users}`;

    // Open the link in a new tab
    if(localStorage.getItem(IS_LOGIN) != "1"){

      let message = "Please login to continue!";
            setTitleOther("Login Alert!");
            setContentOther(message);
            handleopenmsg_other();

    }else{
    window.open(urlWithState, '_blank');
    }

    // If opening in a new tab is successful, navigate in the current tab

  };




  return (
    <div onClick={openUserDetails} id="details" >



      <Grid item xs={12} sm={2} md={3} lg={4} elevation={15} >



        <div elevation={3} className={styles.ourteam}>
          <div className={styles.picture}>
            <img
              src={IMAGE_URL+"/uploads/"+value.pic}
              style={{ width: "135px", height: "135px", borderRadius: "50%" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src= localStorage.getItem(GENDER).toLocaleLowerCase() == "male" ? "https://w7.pngwing.com/pngs/193/660/png-transparent-computer-icons-woman-avatar-avatar-girl-thumbnail.png" :"https://png.pngtree.com/png-vector/20191103/ourmid/pngtree-handsome-young-guy-avatar-cartoon-style-png-image_1947775.jpg";
              }}
            />

          </div>



          <div className={styles.rounded}>
            <Typography color="white" fontSize="15px" fontWeight="bold">
              {value.profile_id}
            </Typography>
            <Typography color="white" fontSize="13px" fontWeight="bold">
              {value.fullname}
            </Typography>
            <Typography color="white" fontSize="14px" fontWeight="normal" fontFamily={"sans-serif"}>
              {getAge(value.dob) + ", " + value.height}
            </Typography>
            <Typography color="white" fontSize="13px" fontWeight="normal">
              {value.education}
            </Typography>
            <Typography color="white" fontSize="13px" fontWeight="normal" style={{ marginBottom: "5px", marginLeft: "5px", marginRight: "5px" }}>
              {value.occupation}
            </Typography>
          </div>
          <ul className={styles.social}>

            <li style={{ display: "inline-block" }}>

              <div id="shortlist" onMouseEnter={() => handleTooltipToggle(0)} onMouseLeave={() => handleTooltipToggle(0)} onClick={handleClick}>
                <Tooltip open={tooltipOpen[0]} onClose={() => handleTooltipToggle(0)} title={socialLinks[0].info}>
                  <IconButton style={{ "color": "white" }} size="medium">
                    {value.shortlist != undefined && value.shortlist.split(",").includes(value.userId) ? <Star /> : <StarBorder />}
                  </IconButton>
                </Tooltip>
              </div>
            </li>

            <li style={{ display: "inline-block" }}>
              <div id="likes" onMouseEnter={() => handleTooltipToggle(1)} onMouseLeave={() => handleTooltipToggle(1)} onClick={handleClick}>
                <Tooltip open={tooltipOpen[1]} onClose={() => handleTooltipToggle(1)} title={socialLinks[1].info}>
                  <IconButton style={{ "color": "white" }} size="medium">
                    {value.likes != undefined && value.likes.split(",").includes(value.userId) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton>
                </Tooltip>
              </div>
            </li>
            <li style={{ display: "inline-block" }}>
              <div id="chat" onMouseEnter={() => handleTooltipToggle(2)} onMouseLeave={() => handleTooltipToggle(2)} onClick={handleClick}>
                <Tooltip open={tooltipOpen[2]} onClose={() => handleTooltipToggle(2)} title={socialLinks[2].info}>
                  <IconButton style={{ "color": "white" }} size="medium">
                    <Chat />
                  </IconButton>
                </Tooltip>
              </div>

            </li>

          </ul>
        </div>





        <ConfirmationModal
          isOpen={isConfirmationOpen}
          handleClose={handleCloseConfirmation}
          handleConfirm={handleConfirmAction}
          title={"ShortList this Profile"}
          content={value.shortlist != undefined && localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(value.shortlist).split(",").includes(marathiToEnglishNumber(value.userId))  : value.shortlist != undefined ? value.shortlist.split(",").includes(value.userId) ? "Are You sure you want to remove this shortlist" : "Are You sure You want to shortlist this profile" : "Are You sure You want to shortlist this profile"}
        />


        <ConfirmationModal
          isOpen={isconfirmInterest}
          handleClose={handleCloseInterest}
          handleConfirm={handleConfirmInterest}
          title={"Express Interest"}
          content={"Are you sure you want to Express your Interest to this person?"}
        />


        <MsgConfirm
          isOpen={msg}
          handleClose={handleclosemsg}
          title={title}
          content={content}
          closeText={"Close"} />


        <MsgConfirmClose
          isOpen={msgother}
          handleClose={handleclickwithoutlogin}
          handleCancel={handlecancelmsg}
          title={title_other}
          content={content_other}
          closeText={"Ok"}
          cancelText={"Cancel"} />




      </Grid>




    </div>
  );
}
const reducer = (state, action) => {
    switch (action.type) {
      case 'MEMBERSHIPLIST_COMMUNITYID_SUCCESS':
        return {
          ...state,
          loading_payment_admin: false,
          data_membership_communityId: action.payload,
          error: null
        };
      case 'MEMBERSHIPLIST_COMMUNITYID_ERROR':
        return {
          ...state,
          data_membership_communityId:[],
          loading_payment_admin: false,
          error: action.payload
        };
      case 'MEMBERSHIPINSERT_SUCCESS':
        return {
          ...state,
          loading_payment_admin: false,
          data_membrship_insert: action.payload,
          error: null
        };
      case 'MEMBERSHIPINSERT_ERROR':
        return {
          ...state,
          loading_payment_admin: false,
          data_membrship_insert:[],
          error: action.payload
        };
      case 'MEMBERSHIUPDATE_COMMUNITYID_SUCCESS':
        return {
          ...state,
          loading_payment_admin:false,
          data_membrship_update: action.payload,
          error: null
        };
      case 'MEMBERSHIUPDATE_COMMUNITYID_ERROR':
        return {
          ...state,
          loading_payment_admin:false,
          data_membrship_update:[],
          error: action.payload
        };


        case 'PAIDLIST_FETCHALL_SUCCESS':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all: action.payload,
          error: null
        };
      case 'PAIDLIST_FETCHALL_ERROR':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all: [],
          error: action.payload
        };

      case 'PAIDLISTEXPIRED_SUCCESS':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all: action.payload,
          error: null
        };
      case 'PAIDLISTEXPIRED_ERROR':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all: action.payload,
          error: action.payload
        };
      case 'PAIDLISTPENDING_FAILED_SUCCESS':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all: action.payload,
          error: null
        };
      case 'PAIDLISTPENDING_FAILED_ERROR':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all:[],
          error: action.payload
        };
      case 'PAIDSUCESSFUL_SUCCESS':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all: action.payload,
          error: null
        };
      case 'PAIDSUCESSFUL_ERROR':
        return {
          ...state,
          loading_payment_admin:false,
          data_payment_all:[],
          error: action.payload
        };
      case 'UPDATEVERIFICATION_SUCCESS':
        return {
          ...state,
          loading_payment_admin:false,
          data_mmebership_updatepayment_verification: action.payload,
          error: null
        };
      case 'UPDATEVERIFICATION_ERROR':
        return {
          ...state,
          loading_payment_admin:false,
          data_mmebership_updatepayment_verification:[],
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  



function SearchBarHome(){



    return(<>
    

    <div

       

        style={{
          paddingTop: '10px',
          paddingBottom: '100px',
          backgroundColor: 'red',
          alignItems: 'center',
          height: '20%',
          width: '75%',
          position: 'relative',
          top: '5%',
          left: '13%',
          margin: 'auto auto',
          display: 'flex',
          justifyContent: 'center',
          zIndex: '1',
          border: '3px solid #D74894',
          borderRadius: '10px',
          backgroundImage:
            'linear-gradient(43deg, var(--homepage-searchbar-gradient) 0%, var(--homepage-searchbar-gradient2) 46%, var(--homepage-searchbar-gradient3) 100%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
        }}
      >
       
    
    </div>
    
    
    
    </>);



}

export default SearchBarHome;
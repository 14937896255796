import { Grid } from "@mui/material";
import { useMembershipContext } from "../../contexts/mmebershipContext";
import MembershipCard from "./membershipCard";
import "../../styles/styles.css";
import { useTranslation } from "react-i18next";
import React , { useEffect } from "react";


const MembershipList = ({passPath}) => {


    const { t } = useTranslation();
  const{membsership_list} = useMembershipContext();


  const duration = 1000; // ms
    const delay = 200; // ms

    useEffect(()=>{
        passPath(t('upgrade'));
    },[]);

  const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;

    return (
        <Grid container spacing={5} style={{ marginLeft: "auto", marginRight: "auto", marginTop:"165px" , width: "100%" , backgroundColor:"#ffe4e1" }}>
                        <img src="/images/flower_matri.png" alt="Rotating Image"  style={{position:"absolute" , display:"flex"  , width:"50%" , marginLeft:"25%"  ,opacity:"0.45"}}/>

           
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={5}>
                    {membsership_list.length > 0 ? Object.values(membsership_list[0][0]).map((value, i) => (
                        <Grid key={i} item>

                            <li className={'fadeInListItem'} key={i} style={{ animation: animStr(i) }}>
                                <MembershipCard value={value}  userSelection={membsership_list}/>
                            </li>



                        </Grid>
                    )) : []}

                </Grid>
            </Grid>



           



        </Grid>
    );




}

export default MembershipList;
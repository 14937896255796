
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid , IconButton , Modal} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';
import SwitchLeft from '@mui/icons-material/ToggleOffRounded';
import SwitchRight from '@mui/icons-material/ToggleOn';






function Country(){

  const {country_fetch , country_insert , country_update , data_insert_country , list_country_admin , data_update_country , delete_master_data} = useMasterContextAdmin();

  

   const [country, setCountry] = useState('');
    const [country_update_display, setCountryUpdate] = useState('');
    const [country_id, setCountryId] = useState('');

    const [open , setopen] = useState(false);
    const [first , setFirst] = useState(false);


    useEffect(()=>{

      if(first == false){

        country_fetch();
        setFirst(true);
        
      }
      

    },[list_country_admin , data_insert_country , data_update_country]);


    const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };


  const handleCountryDisplayChange = (event) => {
    setCountryUpdate(event.target.value);
  };


  const handleAddCountry = () => {
    if (country.trim() !== '') {

      setFirst(false);
      country_insert({'country_name':country , 'code':'cd' , 'show_hide':'0'});
      
    }
  };


  const handleUpdateCountry = () => {
    if (country_update_display.trim() !== '') {

      setFirst(false);
      country_update({'country':country_update_display ,  'code':'cd'  , 'Id' :country_id});
      setopen(false);
    }
  };


  const handleEditClick = (religion , Id) => {
    setopen(true);
    setCountryUpdate(religion);
    setCountryId(Id);
  }

  const handleClose = () => {
     setopen(false);
  };


  const handleDeleteClick = (is_enabled , Id) => {
    delete_master_data({ "type":"country"  , "is_show":is_enabled , "Id":Id});
  
    setTimeout(() => {
  
      country_fetch();
    
    }, 500);
  }


  let role = localStorage.getItem(ROLE);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Country Name</TableCell>
                  <TableCell>Country Code</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_country_admin.length > 0 &&  list_country_admin.map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.country_name}</TableCell>
                    <TableCell>{religion.code}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.country_name , religion.Id)}>
        <EditIcon />
      </IconButton></TableCell>
      <TableCell><IconButton onClick={ () => handleDeleteClick(religion.show_hide == "1" ? "0" : "1" , religion.Id)}>{religion.show_hide == "1"  ? <SwitchLeft color='primary' style={{height:"50px" , width:"100px"}}  /> : <SwitchRight color='disabled' style={{height:"50px" , width:"100px"}}/>}</IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter Country"
        variant="outlined"
        value={country}
        fullWidth
        onChange={handleCountryChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddCountry}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>



          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Country"
            variant="outlined"
            value={country_update_display}
            fullWidth
            onChange={handleCountryDisplayChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateCountry}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>


        </div>
      );


}
export default Country;
import { useEffect } from "react";
import { useAdminDashboardContext } from "../contexts_admin/dashboard_context";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination } from "@mui/material";
import { useState } from "react";
import styles from "../styles_admin/Community.module.css";
import React from "react";




function CommunityModal({isopen , setClose , setClickedLink}){

    const { community_select ,  data_community , loading_community} = useAdminDashboardContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }


  const handleCommunityClick = (Id) => {
     
    setClose(false);
    setClickedLink(Id);
  }

    useEffect(()=>{
        

        setOpen(isopen);
        community_select({
            "offset":"0"
         });

    },[]);



     useEffect(()=>{
        
        if(data_community.length > 0){
            
            console.log(data_community[0][0]["name"]);

        }

    },[data_community]);


    const handlePageChange = (event, value) => {
    
        setCurrentPage(value);

        community_select({
                "offset": value == 1 ? 0 : (((value - 2) * 10) + 10)
        });
            

    }





    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Select Community</DialogTitle>
      <DialogContent>

      
        <table className={styles.nicetable}> {/* Apply custom CSS class */}
          <thead>
            <tr>
              <th>Community</th>
              <th>Code</th>
              <th>Contact Person</th>
              <th>Mobile</th>
              <th>City</th>
              <th>Website</th>
              <th>Select Community</th>
            </tr>
          </thead>
          <tbody>
            {data_community.length > 0 && data_community[0].map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? styles.pinkrow : styles.greyrow}>
                <td>{item.name}</td>
                <td>{item.code}</td>
                <td>{item.contact_person}</td>
                <td>{item.mobile}</td>
                <td>{item.city}</td>
                <td>{item.website}</td>
                <td><span
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={()=>handleCommunityClick(item.Id)}>
      {"Click to Select"}
    </span></td>
              </tr>
            ))}
          </tbody>
        </table>


        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={data_community.length > 0 && Math.ceil(data_community[1][0]["total_row_count"] / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="medium"
                />
            </div>

            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default CommunityModal;


import { Grid } from "@mui/material";
import React, { useEffect } from 'react';
import styles from "../../styles/membershipcard.module.css";
import { useNavigate } from "react-router-dom";

function MembershipCard({value , userSelection}){


  const navigate = useNavigate();

  const handleRedirect = () => {

    if(userSelection[1][0]["0"] !==  undefined){
    if(userSelection[1][0]["0"]["package_name"] != null){

      alert("You already have 1 package selected Please contact support for selecting this package");

    }else{
    const jsonString = JSON.stringify(value);
    navigate(`/checkout/${encodeURIComponent(jsonString)}/${userSelection[1][0]["0"]}`);
    }
  }else{
 
    const jsonString = JSON.stringify(value);
    navigate(`/checkout/${encodeURIComponent(jsonString)}/${userSelection[1][0]["0"]}`);

  }

  }
  
    return (
        <Grid container spacing={5} style={{"marginRight":"40px"}}>
        <div className={styles.cards}>
          <article className={`${styles.plan} ${styles.card}`}>
            <div className={styles.inner}>
              <span className={styles.pricing}>
                <span>
                  {value.plan_price} <small>/ {value.validity_days+" days"}</small>
                </span>
              </span>
              <h2 className={styles.title} style={{marginTop:"20px"}}>{value.plan_name}</h2>
              {userSelection[1][0]["0"] ==undefined ? ""  : value.Id == userSelection[1][0]["0"]["package_name"] ? <div style={{borderRadius:"20px" ,  backgroundColor:"#917072" , padding:"5px" , textAlign:"center" , fontWeight:"bold" , color:"white" ,marginTop:"-20px"}}>{"Your Selected Plan"}</div> : ""}
              <p className={styles.info} style={{fontSize:"15px" , fontWeight:"bold"}}>{value.details}</p>
              <ul className={styles.features}>
                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{ value.profile_display}</span>
                </li>
                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="54" height="54">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{value.gallery_view}</span>
                </li>
                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{value.chatoption}</span>
                </li>
                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h54v54H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{value.express_interest}</span>
                </li>

                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{value.horoscopeview}</span>
                </li>

                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{value.pdf_access}</span>
                </li>

                <li>
                  <span className={styles.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" fill="currentColor" />
                    </svg>
                  </span>
                  <span>{value.allowed_contacts}</span>
                </li>

              </ul>
              <button className={styles.button} onClick={handleRedirect}>
                Choose plan
              </button>
            </div>
          </article>
        </div>
     
        </Grid>
      );


}


export default MembershipCard;
import React from 'react';
import { Paper, Typography } from '@mui/material';


const StylishLabel = ({ text , clickSelectCommunity}) => {
  const paperStyle = {
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#FFC0CB', // Grey background color
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position:'absolute',
    top:'75px',
    left:'365px',
    right:'20px'
  };

  const labelStyle = {
    color: '#333', // Text color
    fontWeight: 'bold',
    fontSize: '1.2rem', // Adjust font size as needed
  };

  const handleClick = () =>{

   
    clickSelectCommunity();

  }

  return (
    <Paper elevation={3} style={paperStyle}>
      <Typography variant="body1" style={labelStyle} onClick={handleClick}>
        {text}
      </Typography>
    </Paper>
  );
};

export default StylishLabel;
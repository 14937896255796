import { useCallback, useState } from "react";
import  React,{ useEffect } from "react";
import Razorpay from 'react-razorpay';
import { useMembershipContext } from "../../contexts/mmebershipContext";
import { GENERATE_RANDOM, getTodayDate } from "../../common_utils/utils";
import { EMAIL_ID, MOBILE_NO, NAME, USER_ID } from "../../common_utils/constants";
import { Grid } from "@mui/material";
import { Axios } from "axios";
import logo from "../../logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/paymentpage.css";
import { useTranslation } from "react-i18next";





const Checkout = ({passPath}) => {

    const { t } = useTranslation();
   const{createOrder , create_order_data , verifyPayment , verify_payment_list , PaidListMemberInsert , PaidListMemberUpdate ,insert_payment ,update_payment}  = useMembershipContext();

   const { parameterValue , paramvalue2 } = useParams();
   const [value , setValue] = useState("");
   const [isfirst , setisfirst] = useState(false);
   const [success , setSuccess] = useState(false);
   const navigate = useNavigate();

   useEffect(()=>{

    passPath(t('checkout'));
  
    setValue(JSON.parse(decodeURIComponent(parameterValue)));

    if(value != "" &&  isfirst == false){

    setisfirst(true);
    createOrder({
            "amount":value.plan_price*100,
            "reciept":GENERATE_RANDOM(12)  
    })
    }
    

   },[value]);

    useEffect(()=>{


    
        if(verify_payment_list["status"] == "success" &&  success == false){

            setSuccess(true);

            console.log("Paramvalue2 :  "+paramvalue2);
            console.log("insert payment : "+insert_payment);
             
        
             if(paramvalue2 == "undefined"){

                console.log("alert : "+paramvalue2);
                
                PaidListMemberInsert({
                    "member_name":localStorage.getItem(NAME),
                    "package_name": value.Id,
                    "payment_method":"RazorPay",
                    "amount":value.plan_price,
                    "status":"done",
                    "code":create_order_data["id"],
                    "date":getTodayDate(),
                    "userId": localStorage.getItem(USER_ID),
                    "communityId":"1"
             });
             }else{

                PaidListMemberUpdate({
                    "member_name":localStorage.getItem(NAME),
                    "package_name":value.Id,
                    "payment_method":"RazorPay",
                    "amount":value.plan_price,
                    "status":"done",
                    "code":create_order_data["id"],
                    "date":getTodayDate(),
                    "userId":localStorage.getItem(USER_ID)
                });



             }
             

        }

        if(insert_payment["affectedRows"] == 1 || update_payment["success"] == 1){

           window.location.href = "/membership";

        }

    },[verify_payment_list , insert_payment , update_payment]);





    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

       

        const options = {
            key: "rzp_test_9zJJjKW535arlG", // Enter the Key ID generated from the Dashboard
            amount: value.plan_price*100,
            currency: "INR",
            name: "Community Matrimonial",
            description: "Matrimonial Subscription",
            image: {logo},
            order_id: create_order_data["id"],
            handler: async function (response) {

                const data = {
                    orderCreationId: create_order_data["id"],
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                verifyPayment({
                    "order_id":create_order_data["id"],
                    "razorpay_payment_id":response.razorpay_payment_id,
                    "razorpay_signature":response.razorpay_signature
                 });

                
            },
            prefill: {
                name: localStorage.getItem(NAME),
                email: localStorage.getItem(EMAIL_ID),
                contact: localStorage.getItem(MOBILE_NO),
            },
            notes: {
                address: "Soumya Dey Corporate Office",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    
    return (
        <div className="payment-container" style={{ position: 'absolute', top: '165px' }}>
          <h1 className="payment-heading">Your Chosen Memebership Plan</h1>
          {value && (
            <div className={`plan-card ${value.plan_name ? 'selected' : ''}`}>
              <div className="plan-header">
                <input type="radio" className="plan-radio" name="planSelection" aria-selected/>
                <h2 className="plan-name">{value.plan_name}</h2>
                <p className="duration">{value.validity_days + ' days'}</p>
              </div>
              <p className="price">Rs: {value.plan_price}</p>
              <ul className="features">
                <li>
                   {value.profile_display}
                </li>
                <li>
                   {value.gallery_view}
                </li>
                <li>
                   {value.chatoption}
                </li>
                <li>
                   {value.express_interest}
                </li>
                <li>
                   {value.horoscopeview}
                </li>
                <li>
                   {value.pdf_access}
                </li>
                <li>
                   {value.allowed_contacts}
                </li>
              </ul>
            </div>
          )}
          <button className="pay-now-button" onClick={displayRazorpay}>
            Pay Now
          </button>
        </div>
      );
    
};

export default Checkout;






import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid , Modal ,IconButton} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';






function FamilyValue(){

  const {familyValues_fetch , familyValues_insert , familyValues_update ,  data_insert_familyvalue , list_familyvalue_admin , data_update_familyvalue , delete_master_data} = useMasterContextAdmin();

  const [familyvalue, setFamilyvalue] = useState('');
  const [familyvalue_update, setFamilyvalueUpdate] = useState('');
  const [familyvalue_id, setFamilyvalueId] = useState('');

  const [open , setopen] = useState(false);
  const [first , setFirst] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(()=>{

   if(first == false){
    familyValues_fetch();

     setFirst(true);
   }
   

  },[list_familyvalue_admin , data_insert_familyvalue , data_update_familyvalue]);


  const handleFamilyvalueChange = (event) => {
    setFamilyvalue(event.target.value);
};


const handleFamilyvalueUpdateChange = (event) => {
  setFamilyvalueUpdate(event.target.value);
};


const handleAddFamilyValue = () => {
  if (familyvalue.trim() !== '') {

   setFirst(false);
   familyValues_insert({'label':familyvalue})
   
  }
};


const handleUpdateFamilyValue = () => {

  if (familyvalue_update.trim() !== '') {

    setFirst(false);
    familyValues_update({'label':familyvalue_update , 'Id' :familyvalue_id});
    setopen(false);

  }

};



const handleDeleteClick = (is_enabled , Id) => {
  delete_master_data({ "type":"fml_values"  , "is_enabled":"1" , "Id":Id});

  setTimeout(() => {

    familyValues_fetch();
  
  }, 500);
}



const handleEditClick = (religion , Id) => {


 setFirst(false);
 
  setFamilyvalueUpdate(religion);
  setFamilyvalueId(Id);

  setopen(true);
  
}


const handleClose = () => {
   setopen(false);
};

let role = localStorage.getItem(ROLE);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Family Value</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_familyvalue_admin.length > 0  &&   list_familyvalue_admin.map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.label}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.label , religion.Id)}>
        <EditIcon />
      </IconButton></TableCell>
      <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter Family Value"
        variant="outlined"
        value={familyvalue}
        fullWidth
        onChange={handleFamilyvalueChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddFamilyValue}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>



          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Family Status"
            variant="outlined"
            value={familyvalue_update}
            fullWidth
            onChange={handleFamilyvalueUpdateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateFamilyValue}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>



        </div>
      );


}
export default FamilyValue;
import React , { useState } from "react";
import { useEffect } from "react";
import { IMAGE_URL } from "../../common_utils/constants";
import { Grid, ImageList, ImageListItem } from "@mui/material";
import { useRef } from "react";
import styles from "../../styles/photos_verification.module.css";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../contexts/notificationContext";



function PhotosVerification({ list_member_details }) {

    const [imageList, setImageList] = useState([]);

    const initialized = useRef(false);

    const {user_validation_data_list } = useNotificationContext();

    const { t } = useTranslation();

    useEffect(() => {

        if (!initialized.current  &&   list_member_details[7][0]["0"] != undefined) {
            initialized.current = true;

            if (list_member_details[7][0]["0"]["pic1"] != undefined && list_member_details[7][0]["0"]["pic1"] != null && list_member_details[7][0]["0"]["pic1"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic1"]]);
            }
            if (list_member_details[7][0]["0"]["pic2"] != undefined  && list_member_details[7][0]["0"]["pic2"] != null && list_member_details[7][0]["0"]["pic2"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic2"]]);
            }
            if (list_member_details[7][0]["0"]["pic3"] != undefined  && list_member_details[7][0]["0"]["pic3"] != null && list_member_details[7][0]["0"]["pic3"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic3"]]);
            }
            if (list_member_details[7][0]["0"]["pic4"] != undefined  && list_member_details[7][0]["0"]["pic4"] != null && list_member_details[7][0]["0"]["pic4"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic4"]]);
            }
            if (list_member_details[7][0]["0"]["pic5"] != undefined  && list_member_details[7][0]["0"]["pic5"] != null && list_member_details[7][0]["0"]["pic5"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic5"]]);
            }
            if (list_member_details[7][0]["0"]["pic6"] != undefined  && list_member_details[7][0]["0"]["pic6"] != null && list_member_details[7][0]["0"]["pic6"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic6"]]);
            }
            if (list_member_details[7][0]["0"]["pic7"] != undefined  && list_member_details[7][0]["0"]["pic7"] != null && list_member_details[7][0]["0"]["pic7"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic7"]]);
            }
            if (list_member_details[7][0]["0"]["pic8"] != undefined  && list_member_details[7][0]["0"]["pic8"] != null && list_member_details[7][0]["0"]["pic8"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic8"]]);
            }
            if (list_member_details[7][0]["0"]["pic9"] != undefined  && list_member_details[7][0]["0"]["pic9"] != null && list_member_details[7][0]["0"]["pic9"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic9"]]);
            }
            if (list_member_details[7][0]["0"]["pic10"] != undefined  && list_member_details[7][0]["0"]["pic10"] != null && list_member_details[7][0]["0"]["pic10"] != "") {
                setImageList(image => [...image, IMAGE_URL + "/uploads/" + list_member_details[7][0]["0"]["pic10"]]);
            }


        }

    }, [])



    return (

        <>
        {list_member_details[9][0]["0"] != undefined ? 
            <div>
                <Grid container style={{ "marginLeft": "5%" , "marginRight": "5%" , "marginTop":"50px"}}>
                  
               
                   {user_validation_data_list[0].length > 0 ?

                    <Grid item xs={12} md={6} lg={6}>
                        <div style={{ "backgroundColor": "#D74894", "width": "200px", "color": "white", "padding": "10px", "textAlign": "left", "fontFamily": "Dosis", "fontWeight": "bold", "fontSize": "17px", "marginTop": "50px", "marginLeft": "12%" }}>User Images</div>
                        <ImageList sx={{ width: 600, height: 450, gap: 0 }} rowHeight={164} cols={3} style={{ "marginLeft": "auto", "marginRight": "auto" }}>
                            {imageList.map((item) => (
                                <ImageListItem key={item}>
                                    <img
                                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                        alt={item}
                                        loading="lazy"
                                        style={{ "objectFit": "fill", "height": "100%", "width": "100%" }}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>

                    </Grid>

                    : <div style={{fontSize:"25px" , marginTop:"200px" , fontWeight:"bold" , color:"#E3256B" , textAlign:"center" , width:"50%"}}>{"Only Premium user can see Gallery Photos Please Click to upgrade"}</div>

                    }
                    

                    <Grid item xs={12} md={6} lg={6} style={{ "marginTop": "50px" }}>


                        <div className={styles.rounded_box}>
                            <div className={styles.header}>{t('phtos_verification')}</div>

                            <div className={styles.contact_details_value}>
                                <span className={styles.keyphotos}>{t('verified_user') + ":"}</span> <span className={styles.valuephotos}>{list_member_details[9][0]["0"]["user_verify"] == "0" ? "Verification Process Going on" : list_member_details[9][0]["0"]["user_verify"] == "1" ? "User Is Verified" : "User Is not Verified"}</span><br />
                                <span className={styles.keyphotos}>{t('verified_mobile') + ":"}</span> <span className={styles.valuephotos}>{list_member_details[9][0]["0"]["mobile_verify"] == "0" ? "Verification Process Going on" : list_member_details[9][0]["0"]["mobile_verify"] == "1" ? "Mobile number Is Verified" : "Mobile Number Is not Verified"}</span><br />
                                <span className={styles.keyphotos}>{t('verified_email') + ":"}</span> <span className={styles.valuephotos}>{list_member_details[9][0]["0"]["email_verify"] == "0" ? "Verification Process Going on" : list_member_details[9][0]["0"]["email_verify"] == "1" ? "Email Id Is Verified" : "Email Id Is not Verified"}</span><br />
                                <span className={styles.keyphotos}>{t('verified_id_proof') + ":"}</span> <span className={styles.valuephotos}>{list_member_details[9][0]["0"]["is_id_verify"] == "0" ? "Verification Process Going on" : list_member_details[9][0]["0"]["is_id_verify"] == "1" ? "Identity Is Verified" : "Identity Is not Verified"}</span><br />
                                <span className={styles.keyphotos}>{t('verified_edu_proof') + ":"}</span> <span className={styles.valuephotos}>{list_member_details[9][0]["0"]["is_education_verify"] == "0" ? "Verification Process Going on" : list_member_details[9][0]["0"]["is_education_verify"] == "1" ? "Education Proof Is Verified" : "Education Proof Is not Verified"}</span><br />
                                <span className={styles.keyphotos}>{t('verified_salary_proof') + ":"}</span> <span className={styles.valuephotos}>{list_member_details[9][0]["0"]["is_income_verify"] == "0" ? "Verification Process Going on" : list_member_details[9][0]["0"]["is_income_verify"] == "1" ? "Salary Proof Is Verified" : "Salary Proof Is not Verified"}</span><br />
                               
                            </div>

                        </div>



                    </Grid>

                </Grid>

            </div>
            : <div style={{textAlign:"center" , fontSize:"25px" , fontWeight:"bold" , marginTop:"50px"}}>{"Photos and Verification not available"}</div>
         }

        </>


    );





}
export default PhotosVerification;
import React from "react";

const WrapperStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(6, 1fr)",
  gridColumnGap: "5px",
  gridRowGap: "5px",
  maxWidth: "250px",
  maxHeight: "217px",
  margin: "0 auto"
};

const CaptionStyle = {
  color: "#555",
  fontSize: "0.75em"
};

const DayButtonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: "4px",
  textAlign: "center",
  width: "32px",
  borderRadius: "4px",
  height: "32px",
  margin: "0 auto",
  outline: "none",
  '&:focus, &:active, &:hover': {
    background: "#ff7494",
    color: "white"
  }
};

const DayPicker = ({ onDatePicked }) => {
  const days = [];
  const currentDate = new Date().getDate();
  for (let i = 0; i < 31; i++) days.push(i + 1);
  
  const pickDate = (e) => {
    onDatePicked(e.target.textContent);
  };

  return (
    <div>
      <p style={CaptionStyle}>Select a day</p>
      <div style={WrapperStyle}>
        {days.map(d => (
          <button
            key={d}
            onClick={pickDate}
            style={{ ...DayButtonStyle, border: d === currentDate ? "1px solid black" : "none" }}
          >
            {d}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DayPicker;

import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerProofs from "../../reducer_admin/proofsReducerAdmin";
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../../../common_utils/constants';

const proofsAdminContext = createContext();

const initialState = {
  data_proofs_list_admin: [],
  loading: true,
  error: null,
  insert_proofs_list_admin:[],
  update_proofs_list_admin:[],
  data_idproofs:[],
  data_educationproofs:[],
  data_salaryproofs:[],
};

let API = `${BASE_URL}`;


export const ProofsAdminProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducerProofs, initialState);

  const fetchProofsListAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}` + `fetch_data/profile_fetch`, params);

      dispatch({ type: 'PROOFS_FETCH_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PROOFS_FETCH_ERROR_ADMIN', payload: error });
    }
  };



  const insert_proofsAdmin = async (formData) => {
    try {
      const response = await axios.post(`${API}`+'profile/insert_proofs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'INSERT_PROOFS_ADMIN', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'INSERT_PROOFS_ERROR_ADMIN', payload: error });
    }
  };



  const update_proofsAdmin = async (formData) => {
    try {
      const response = await axios.patch(`${API}`+'profile/update_proofs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'UPDATE_PROOFS_ADMIN', payload: response.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_PROOFS_ERROR_ADMIN', payload: error });
    }
  };



   const update_idproofs = async (params) => {
    try {
     
      const response = await axios.post(`${API}` + `adminpanel/user_content/verify_user_identity`, params);

      dispatch({ type: 'UPDATE_IDPROOFS_ADMIN', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_IDPROOFS_ERROR_ADMIN', payload: error });
    }
  };


  const update_educationProofs = async (params) => {
    try {
     
      const response = await axios.post(`${API}` + `adminpanel/user_content/verify_user_education`, params);

      dispatch({ type: 'UPDATE_EDUCATIONPROOFS_ADMIN', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_EDUCATIONPROOFS_ERROR_ADMIN', payload: error });
    }
  };




  const update_salaryproofs = async (params) => {
    try {
     
      const response = await axios.post(`${API}` + `adminpanel/user_content/verify_user_income`, params);

      dispatch({ type: 'UPDATE_SALARYPROOFS_ADMIN', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_SALARYPROOFS_ERROR_ADMIN', payload: error });
    }
  };







  return <proofsAdminContext.Provider value={{ ...state, dispatch, fetchProofsListAdmin , insert_proofsAdmin , update_proofsAdmin ,update_idproofs , update_educationProofs ,update_salaryproofs}}>{children}</proofsAdminContext.Provider>;
};

export const useProofsAdminContext = () => useContext(proofsAdminContext);
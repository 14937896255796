import { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";




function DetailDialog({isopen , setClose, Details}){


    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }




    useEffect(()=>{
    

        setOpen(isopen);
       
    },[]);




   




    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Details of {Details.name}</DialogTitle>
      <DialogContent>

      
      <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '10px', marginTop: '20px' }}>
  <Typography variant="h6" style={{ color: '#AA336A', borderBottom: '2px solid #333', paddingBottom: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Community Details</Typography>
  <div style={{ marginBottom: '20px' }}>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Name: <span style={{ color: '#333' }}>{Details.name}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Code: <span style={{ color: '#333' }}>{Details.code}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Full Community Name: <span style={{ color: '#333' }}>{Details.fullname}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Contact Person: <span style={{ color: '#333' }}>{Details.contact_person}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Mobile: <span style={{ color: '#333' }}>{Details.mobile}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Email: <span style={{ color: '#333' }}>{Details.email}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Address: <span style={{ color: '#333' }}>{Details.address}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>City: <span style={{ color: '#333' }}>{Details.city}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>State: <span style={{ color: '#333' }}>{Details.state}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Country: <span style={{ color: '#333' }}>{Details.country}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Pin: <span style={{ color: '#333' }}>{Details.pin}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Language: <span style={{ color: '#333' }}>{Details.language}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Website: <span style={{ color: '#333' }}>{Details.website}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Super Admin 1: <span style={{ color: '#333' }}>{Details.superadmin1}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Super Admin 2: <span style={{ color: '#333' }}>{Details.superadmin2}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#AA336A', marginTop: "10px", fontWeight: 'bold' }}>Password: <span style={{ color: '#333' }}>{Details.password}</span></Typography>
  </div>
</div>


            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default DetailDialog;
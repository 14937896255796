import React, { useState , useEffect} from "react";
import {
  Collapse,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
  createTheme,
  getTouchRippleUtilityClass
} from "@mui/material";
import { Link } from "react-router-dom";
import  {Menu  , Home, Search  , Login , Download , WhatsApp ,ExpandLess ,ExpandMore ,Inbox , Call , Subject , PausePresentation , PrivacyTip , QuestionAnswer , Money , CardMembership , AccountBox} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import i18n from "../translations/i18n";
import "../translations/en.json";
import { about } from "../data/link_menu";






function DrawerComponent() {


  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);

    let item_icons_about = [Subject , Call , PausePresentation , PrivacyTip , QuestionAnswer , Money ,CardMembership];


  const [open, setOpen] = React.useState(getTouchRippleUtilityClass);

  const handleClick = () => {
    setOpen(!open);
  };


  const [openDrawer, setDrawerOpen] = React.useState(false);

  const handleClickDrawer = () => {
    setDrawerOpen(!openDrawer);
  };

  const text ={
     color: "white" , fontSize:"18px" , fontFamily: "Roboto, sans-serif" , fontWeight:"bold"
  }

  let item_about = [];
  let about_ion = [];

  return (
  

<div id="main-div" style={{
             backgroundColor:"#D74894"  , boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            width:"130%",
            justifyContent:"center",
            alignContent:"center",
            position:"absolute",
            top:"0",
            right:"0",
            padding:"20px",
            zIndex:"1",
            }}>

<div style={{color: "white", backgroundColor:"transparent" , display:"flex" ,position:"absolute" , top:"10" ,right:"0" , marginRight:"20px" , alignContent:"center"}}>
<Icon onClick={() => setDrawerOpen(!openDrawer)} >
        <Menu  />
      </Icon>
      </div>

      <Drawer
        open={openDrawer}
        PaperProps={{
          sx: { width: "70%" , 
        backgroundColor:"#D74894",
      color:"black"},
        }}
        width={200}
        onClose={() => setDrawerOpen(false)} >
        <List >

       
        <ListItem onClick={() => setOpen(false)}>
        <ListItemButton onClick={() => setDrawerOpen(false)}>
        <ListItemIcon>
          <Home style={{ color: "white" }}/>
        </ListItemIcon>
            <ListItemText>
              <Link to="/home" style={{textDecoration:"none",
        color: "white",
        fontSize: "20px", fontWeight:"bold"}}>{t('home')}</Link>
            </ListItemText>
            </ListItemButton>
          </ListItem>
          
          <Divider/>

        <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <AccountBox style={{ color: "white" }}/>
        </ListItemIcon>
        <ListItemText primary={t('about')} primaryTypographyProps={{ style: text }} />
        {open ? <ExpandLess  style={{ color: "white"}}/> : <ExpandMore  style={{ color: "white"}} />}
      </ListItemButton>
      <Collapse in={!open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{

about.map((s,i) => {
  item_about.push(<><ListItemButton sx={{ pl: 4 }} onClick={() => setDrawerOpen(false)}>
  <ListItemIcon>
    {
      i == 0? <Subject style={{ color: "white" }}/> : i==1 ? <Call style={{ color: "white" }}/>  : i==2 ?  <PausePresentation style={{ color: "white" }}/>
      : i = 3 ?  <PrivacyTip style={{ color: "white" }}/> : i==4 ? <QuestionAnswer style={{ color: "white" }}/> : i==5 ?<Money style={{ color: "white" }}/> 
      : <CardMembership style={{ color: "white" }}/> 

    } 
  </ListItemIcon>
  <ListItemText>
    <Link to={s[1]} style={{textDecoration:"none",
color: "white",
fontSize: "16px",}}>{t(s[0])}</Link>
  </ListItemText>
</ListItemButton></>);

        })
        
        }
          {item_about}
        </List>
      </Collapse>

          <Divider/>
          <ListItem onClick={() => setOpen(false)}>
          <ListItemIcon>
          <Search style={{ color: "white" }}/>
          </ListItemIcon>
            <ListItemText>
              <Link to="/search" style={{textDecoration:"none",
        color: "white",
        fontSize: "20px", fontWeight:"bold"}}>{t('search')}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpen(false)}>
          <ListItemIcon>
          <Call style={{ color: "white" }}/>
          </ListItemIcon>
            <ListItemText>
              <Link to="/contact" style={{textDecoration:"none",
        color: "white",
        fontSize: "20px", fontWeight:"bold"}}>{t('contact_us')}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpen(false)}>
          <ListItemIcon>
          <Download style={{ color: "white" }}/>
          </ListItemIcon>
            <ListItemText>
              <Link to="/download" style={{textDecoration:"none",
        color: "white",
        fontSize: "20px", fontWeight:"bold"}}> {t('download_app')}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>

          <ListItem onClick={() => setOpen(false)}>
          <ListItemIcon>
          <Login style={{ color: "white" }}/>
          </ListItemIcon>
            <ListItemText>
              <Link to="/register" style={{textDecoration:"none",
        color: "white",
        fontSize: "20px", fontWeight:"bold"}}> {t('register')}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>

          <ListItem onClick={() => setOpen(false)}>
          <ListItemIcon>
          <Login style={{ color: "white" }}/>
          </ListItemIcon>
            <ListItemText>
              <Link to="/login" style={{textDecoration:"none",
        color: "white",
        fontSize: "20px", fontWeight:"bold"}}> {t('login')}</Link>
            </ListItemText>
          </ListItem>
          <Divider/>



        </List>
      </Drawer>
      
    </div>
  );
}

export default DrawerComponent;
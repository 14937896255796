import React, { useState } from 'react';
import styles from '../../styles_admin/Tabs.module.css'; 
import { EmailMarketingProvider } from '../../contexts_admin/email_marketing_context';
import EmailTemplate from './emailtemplate';
import EditEmailTemplate from './editEmailTemplate';
import SendEmail from './sendEmail';

function EmailMarketingPageMain() {
  
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {

    setActiveTab(index);
  };

  return (
    <div className={styles.tabscontainer}>
      <div className={styles.tabs}>
        <div className={`${styles.tab} ${activeTab === 0 ? styles.active : ''}`} onClick={() => handleTabClick(0)}>Send Email</div>
        <div className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`} onClick={() => handleTabClick(1)}>Create Email Template</div>
        <div className={`${styles.tab} ${activeTab === 2 ? styles.active : ''}`} onClick={() => handleTabClick(2)}>Edit Email Template</div>
      </div>
      <div className={styles.tabcontent}>
        {activeTab === 0 && <div><EmailMarketingProvider><SendEmail/></EmailMarketingProvider></div>}
        {activeTab === 1 && <div><EmailMarketingProvider><EmailTemplate/></EmailMarketingProvider></div>}
        {activeTab === 2 && <div><EmailMarketingProvider><EditEmailTemplate/></EmailMarketingProvider></div>}
      </div>
    </div>
  );
}

export default EmailMarketingPageMain;
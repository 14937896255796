import { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import { useState } from "react";
import styles from "../../styles_admin/Community.module.css";
import { useEmailMarketingContext } from "../../contexts_admin/email_marketing_context";
import React from "react";



function MembershipDialog({isopen , setClose , setClickedLink , communityId}){

    const { membership_fetchByCommunityId  ,  data_membership_admin , loading_emailmarketing_admin} = useEmailMarketingContext();


    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }


  const handleCommunityClick = (Item) => {
     
    setClose(false);
    setClickedLink(Item);
  }

    useEffect(()=>{
        

        setOpen(isopen);
        membership_fetchByCommunityId({
            "communityId":communityId
         });

    },[]);



    





    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle>Select Community</DialogTitle>
      <DialogContent>

      
        <table className={styles.nicetable} width={"100%"}> {/* Apply custom CSS class */}
          <thead>
            <tr>
              <th style={{width:"10%"}}>Plan Name</th>
              <th style={{width:"10%"}}>Plan Price</th>
              <th style={{width:"10%"}}>Validity Days</th>
              <th style={{width:"20%"}}>Discount Type</th>
              <th style={{width:"10%"}}>Discount</th>
              <th style={{width:"10%"}}>Coupon Code</th>
              <th style={{width:"10%"}}>Click to Fecth Offer</th>
            </tr>
          </thead>
          <tbody>
            {data_membership_admin.length > 0 && data_membership_admin.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? styles.pinkrow : styles.greyrow}>
                <td style={{width:"10%"}}>{item.plan_name}</td>
                <td style={{width:"10%"}}>{item.plan_price}</td>
                <td style={{width:"30%"}}>{item.validity_days}</td>
                <td style={{width:"30%"}}>{item.discount_type}</td>
                <td style={{width:"10%"}}>{item.discount}</td>
                <td style={{width:"10%"}}>{item.coupon_code}</td>
                <td style={{width:"20%"}}><span
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={()=>handleCommunityClick(item)}>
      {"Click to Select"}
    </span></td>
              </tr>
            ))}
          </tbody>
        </table>


        

            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default MembershipDialog;
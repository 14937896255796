import  React,{ useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AGE_FROM_P_VAL, AGE_P, AGE_TO_P_VAL, ANNUAL_INCOME_P, ANNUAL_INCOME_P_VAL, BODY_TYPE_P, BODY_TYPE_P_VAL, CITY_P, CITY_P_VAL, COMMUNITY_ID, DIET_TYPE_P, DIET_TYPE_P_VAL, DRINK_TYPE_P, DRINK_TYPE_P_VAL, EDUCATION_P, EDUCATION_P_VAL, GENDER, HEIGHT_FROM_P_VAL, HEIGHT_P, HEIGHT_TO_P_VAL, LIMIT, MARITAL_STATUS_P, MARITAL_STATUS_P_VAL, OCCUPATION_P, OCCUPATION_P_VAL, SKINTONE_P, SKINTONE_P_VAL, SMOKE_TYPE_P, SMOKE_TYPE_P_VAL, STATE_P, STATE_P_VAL, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { Grid, Pagination } from "@mui/material";
import "../../styles/styles.css";

import { useActivityContext } from "../../contexts/activityContext";
import MatriProfileCardActivity from "./MatriProfileCardActivity";
import { useNotificationContext } from "../../contexts/notificationContext";
import { marathiToEnglishNumber } from "../../common_utils/utils";
import { useTranslation } from "react-i18next";
import styles from '../../styles/loading.module.css';
import "../../styles/no_data_found.css";
import RotatingHeart from "../../common_utils/loading_animator";




function Interest_Sent_Recieved({passPath}) {

    const duration = 1000; // ms
    const delay = 200; // ms

    const { t } = useTranslation();
    const { InterestRecievedSent , interest_send_recieve_list , loading_interest} = useActivityContext();
    const { user_validation_data } = useNotificationContext();
    const { type } = useParams();


    const [currentPage, setCurrentPage] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [load, setLoad] = useState(false);

    const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;


    useEffect(() => {

        if(window.location.pathname == "/interset_recieved/other"){
          passPath(t('interest_recieved'));
        }else if(window.location.pathname == "/interset_sent/i"){
            passPath(t('interest_sent'));
        }else{
            passPath(t('accept_request'));
        }
        //alert(localStorage.getItem(HEIGHT_FROM_P_VAL)+"--"+localStorage.getItem(HEIGHT_TO_P_VAL)+"--"+localStorage.getItem(HEIGHT_P));
        setAnimate(true);


        user_validation_data({
            "userId": localStorage.getItem(USER_ID)
        });

        

        InterestRecievedSent(
            {
                "userId":localStorage.getItem(USER_ID),
                "type":type,  // i , other
                "gender":localStorage.getItem(GENDER),
                "communityId":localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset": 0

            }
        );

    
        setTimeout(() => {
            setAnimate(false);
        }, 2000)

    }, []);

    
    const handlePageChange = (event, value) => {
        setAnimate(true);
        setCurrentPage(value);

        InterestRecievedSent(
            {
                "userId":localStorage.getItem(USER_ID),
                "type":type,  // i , other
                "gender":localStorage.getItem(GENDER),
                "communityId":localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset": value == 1 ? 0 : (((value-2)*LIMIT)+LIMIT)

            }
        );

        scrollToTop();


    }

    const refresh = () => {

        user_validation_data({
            "userId": localStorage.getItem(USER_ID)
        });
        

        InterestRecievedSent(
            {
                "userId":localStorage.getItem(USER_ID),
                "type":type,  // i , other
                "gender":localStorage.getItem(GENDER),
                "communityId":localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset": currentPage == 1 ? 0 : (((currentPage-2)*LIMIT)+LIMIT)

            }
        );
    }


    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scroll events
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setIsVisible(scrollTop > 300); // Adjust the scroll threshold as needed
    };

    // Attach scroll event listener on mount
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Remove scroll event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can also use 'auto' for instant scrolling
        });
    };


    const cardStyle = {
        opacity: 0,
        animation: 'fadeIn 0.5s ease-out forwards',
    };


    useEffect(()=>{

        if(loading_interest == false){
            setLoad(true);
        }   
       
        

    },[loading_interest]);


          

    return (

        <>

        
        {  load == false ?  <RotatingHeart/> : 
        <div>
        { interest_send_recieve_list.length > 0 ?
        <Grid container rowSpacing={5} columnSpacing={4} style={{ marginLeft: "auto", marginRight: "auto", marginTop: "200px" , width: "100%" ,position: "relative" }}>
            
            <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position:"absolute" , top:"50px" , right:"0" , zIndex:"0" , width:"120px" , height:"120px"}}/>
            <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position:"absolute" , bottom:"80px" , left:"40px" , zIndex:"0" , width:"120px" , height:"120px"}}/>
            <img src="/images/flower_matri.png" alt="Rotating Image"  style={{position:"absolute" , display:"flex" , height:"100vh" , width:"50%" , marginLeft:"25%"  ,opacity:"0.25"}}/>

            
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={5}>
                     {interest_send_recieve_list.length > 0 &&  (localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(interest_send_recieve_list[1][0]["0"]["total_row_count"]) > 0  :  interest_send_recieve_list[1][0]["0"]["total_row_count"] > 0 ) && window.location.pathname == "/accepted_request/i"  ? Object.values(interest_send_recieve_list[0][0]).filter(item1 =>
                       localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(item1.accept).includes("1")  :  item1.accept.includes("1")).map((value, i) => (
                        <Grid key={value} item>

                            <li className={animate ? 'fadeInListItem' : ''} key={i} style={{ animation: animStr(i) }}>
                                <MatriProfileCardActivity value={value} refresh={refresh} />
                            </li>



                        </Grid>
                    )) : interest_send_recieve_list.length > 0 && interest_send_recieve_list[1][0]["0"]["total_row_count"] > 0 ? Object.values(interest_send_recieve_list[0][0]).map((value, i) => (
                          <Grid key={value} item>
  
                              <li className={animate ? 'fadeInListItem' : ''} key={i} style={{ animation: animStr(i) }}>
                                  <MatriProfileCardActivity value={value} refresh={refresh} />
                              </li>
  
  
  
                          </Grid>
                      )) : <div className="no_data_container">
                      <p className="no_data_label">No Data Found</p>
                    </div>}

                </Grid>
            </Grid>



            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={interest_send_recieve_list.length > 0 ? Math.ceil(localStorage.getItem(TRANSLATED_LANG) == "mr" ?  marathiToEnglishNumber(interest_send_recieve_list[1][0]["0"]["total_row_count"])/8   :   interest_send_recieve_list[1][0]["0"]["total_row_count"] / 8) : 0}
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    color="secondary"
                    shape="rounded"
                    size="large"

                />
            </div>




        </Grid>: ""

                    }

        </div>

                }

        </>
    );




}

export default Interest_Sent_Recieved;
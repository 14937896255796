const searchReducer = (state, action) => {
    switch (action.type) {

      case 'REGULAR_SEARCH':
        return {
          ...state,
          regular_search_list: action.payload,
          loading_regular_search: false,
          error: null,
        };
      case 'REGULAR_SEARCH_ERROR':
        return {
          ...state,
          regular_search_list: [],
          loading_regular_search: false,
          error: action.payload,
        };


        case 'ADVANCED_SEARCH':
        return {
          ...state,
          advanced_search_list: action.payload,
          loading_advanced_result: false,
          error: null,
        };
      case 'ADVANCED_SEARCH_ERROR':
        return {
          ...state,
          advanced_search_list: [],
          loading_advanced_result: false,
          error: action.payload,
        };


        case 'IIT_NIT_IIM_SEARCH':
        return {
          ...state,
          iit_iim_nit_list: action.payload,
          loading_institute: false,
          error: null,
        };
      case 'IIT_NIT_IIM_SEARCH_ERROR':
        return {
          ...state,
          iit_iim_nit_list: [],
          loading_institute: false,
          error: action.payload,
        };


         case 'MATCH_RESULT':
        return {
          ...state,
          matches: action.payload,
          loading_matches: false,
          error: null,
        };
      case 'MATCH_RESULT_ERROR':
        return {
          ...state,
          matches: [],
          loading_matches: false,
          error: action.payload,
        };

        case 'MUTUAL_MATCH_RESULT':
        return {
          ...state,
          matches_result: action.payload,
          loading_mutual: false,
          error: null,
        };
      case 'MUTUAL_MATCH_RESULT_ERROR':
        return {
          ...state,
          matches_result: [],
          loading_mutual: false,
          error: action.payload,
        };

        case 'MATCHES_JOINED_RESULT':
        return {
          ...state,
          matches_joined: action.payload,
          loading_match_joined: false,
          error: null,
        };
      case 'MATCHES_JOINED_RESULT_ERROR':
        return {
          ...state,
          matches_joined: [],
          loading_match_joined: false,
          error: action.payload,
        };


      
        case 'SHORTLIST_INSERT':
        return {
          ...state,
          insert_shortlist: action.payload,
          loading: false,
          error: null,
        };
      case 'SHORTLIST_ERROR':
        return {
          ...state,
          insert_shortlist: [],
          loading: false,
          error: action.payload,
        };

        case 'EXPRESS_INTEREST_INSERT':
        return {
          ...state,
          insert_interest: action.payload,
          loading: false,
          error: null,
        };
      case 'EXPRESS_INTEREST_INSERT_ERROR':
        return {
          ...state,
          insert_interest: [],
          loading: false,
          error: action.payload,
        };
       


        case 'INSERT_NOTIFICATION':
          return {
            ...state,
            insert_notification_data: action.payload,
            loading: false,
            error: null,
          };
        case 'INSERT_NOTIFICATION_ERROR':
          return {
            ...state,
            insert_notification_data: [],
            loading: false,
            error: action.payload,
          };
         
   
      default:
        return state;
    }
  };

  export default searchReducer;
import { useEffect, useState } from "react";
import { useSearchContext } from "../../contexts/searchContext";
import { useLocation } from "react-router-dom";
import { COMMUNITY_ID, LIMIT, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { Grid, Pagination } from "@mui/material";

import MatriProfileCard from "./MatriProfileCard";
import "../../styles/styles.css";
import { useNotificationContext } from "../../contexts/notificationContext";
import { marathiToEnglishNumber } from "../../common_utils/utils";
import { useTranslation } from "react-i18next";
import styles from '../../styles/loading.module.css';
import "../../styles/no_data_found.css";
import RotatingHeart from "../../common_utils/loading_animator";
import React from "react";



function  IITIIMNITSearchResult({passPath}){

    const duration = 1000; // ms
    const delay = 200; // ms
    const { t } = useTranslation();

    const {fetchIIT_IIM_NIT_SEARCH , iit_iim_nit_list , loading_institute} = useSearchContext();
    const {user_validation_data }  = useNotificationContext();

    const {state} = useLocation();
    const { age_from , age_to , gender, 
        marital_status , caste , institute} = state;
  
    const[currentPage , setCurrentPage] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [load, setLoad] = useState(false);


    
    const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;

    useEffect(()=>{


      passPath(t('iit_iim_nit_search_result'));
      setAnimate(true);

      user_validation_data({
        "userId": localStorage.getItem(USER_ID)
    });


        fetchIIT_IIM_NIT_SEARCH(
            {
                "userId":localStorage.getItem(USER_ID),
                "age_from":age_from,
                "age_to":age_to,
                "caste":caste,
                "marital_status":marital_status,
                "college_name":institute,
                "Id":localStorage.getItem(USER_ID),
                "gender": gender,
                "communityId":localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset":"0"
              }
        );

        setTimeout(()=>{
          setAnimate(false);
        },2000)

    },[]);

    const handlePageChange = (event, value) =>{
      setAnimate(true);
      setCurrentPage(value);
  
      fetchIIT_IIM_NIT_SEARCH(
        {
            "userId":localStorage.getItem(USER_ID),
            "age_from":age_from,
            "age_to":age_to,
            "caste":caste,
            "marital_status":marital_status,
            "college_name":institute,
            "Id":localStorage.getItem(USER_ID),
            "gender": gender,
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)],
            "limit":LIMIT,
            "offset":value == 1 ? 0 : (((value-2)*LIMIT)+LIMIT)
          }
        );

        scrollToTop();
        

    }

    const refresh = () => {


      user_validation_data({
        "userId": localStorage.getItem(USER_ID)
    });

        fetchIIT_IIM_NIT_SEARCH(
            {
                "userId":localStorage.getItem(USER_ID),
                "age_from":age_from,
                "age_to":age_to,
                "caste":caste,
                "marital_status":marital_status,
                "college_name":institute,
                "Id":localStorage.getItem(USER_ID),
                "gender": gender,
                "communityId":localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset":currentPage == 1 ? 0 : (((currentPage-2)*LIMIT)+LIMIT)
              }
            );
    

    }


    const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll events
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollTop > 300); // Adjust the scroll threshold as needed
  };

  // Attach scroll event listener on mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Remove scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can also use 'auto' for instant scrolling
    });
  };


  const cardStyle = {
    opacity: 0,
    animation: 'fadeIn 0.5s ease-out forwards',
  };

  useEffect(()=>{

    if(loading_institute == false){
        setLoad(true);
    }
   

  },[loading_institute]);

 


    return (

      <>
        
        {  load == false ?  <RotatingHeart/> : 
        <Grid container rowSpacing={5} columnSpacing={4}  style={{  marginLeft:"auto" , marginRight:"auto" , marginTop:"220px" , width:"100%"}}>
          
           <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position:"absolute" , top:"50px" , right:"0" , zIndex:"0" , width:"120px" , height:"120px"}}/>
             <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position:"absolute" , bottom:"80px" , left:"40px" , zIndex:"0" , width:"120px" , height:"120px"}}/>
             <img src="/images/couple.png" alt="Rotating Image"  style={{position:"absolute" , display:"flex" , height:"100vh" , width:"50%" , marginLeft:"25%"  ,opacity:"0.25"}}/>

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={5}>
            {iit_iim_nit_list.length > 0 && (localStorage.getItem(TRANSLATED_LANG) == "mr" ?  marathiToEnglishNumber(iit_iim_nit_list[1][0]["0"]["total_rows_count"]) > 0  : iit_iim_nit_list[1][0]["0"]["total_rows_count"] > 0) ? Object.values(iit_iim_nit_list[0][0]).map((value ,i) => (
            <Grid key={i} item>

              <li className={animate ? 'fadeInListItem' : ''} key={i} style={{ animation: animStr(i) }}>
              <MatriProfileCard value={value} refresh={refresh}/>
              </li>
      
             
             
            </Grid>
          )): <div className="no_data_container">
          <p className="no_data_label">No Data Found</p>
        </div> }
              
            </Grid>
          </Grid>
    
    
    
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' , marginBottom:"20px" , width:"100%" }}>
          <Pagination
        count={iit_iim_nit_list.length > 0 ?  Math.ceil( localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(iit_iim_nit_list[1][0]["0"]["total_rows_count"])/8  : iit_iim_nit_list[1][0]["0"]["total_rows_count"]/8) : 0}
        page={currentPage}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
        variant="outlined" 
        color="secondary"
        shape="rounded"
        size="large"
        
          />
          </div>
     
         
        
    
          </Grid>
}
</>
          );




};

export default IITIIMNITSearchResult;
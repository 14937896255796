import { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import { useState } from "react";
import styles from "../../styles_admin/Community.module.css";
import { useEmailMarketingContext } from "../../contexts_admin/email_marketing_context";
import React from "react";




function BlogDialog({isopen , setClose , setClickedLink , communityId}){

    const { email_template_blog_fetch  ,  data_emailtemplate_blog_fetch , loading_emailmarketing_admin , clear_blog_list} = useEmailMarketingContext();


    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }


  const handleCommunityClick = (Item) => {
     
    setClose(false);
    setClickedLink(Item);

    clear_blog_list();
    
  }

    useEffect(()=>{
        

        setOpen(isopen);
        email_template_blog_fetch({
            "communityId":communityId
         });

        

    },[]);



    





    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle>Select Community</DialogTitle>
      <DialogContent>

      
        <table className={styles.nicetable} width={"100%"}> 
          <thead>
            <tr>
              <th style={{width:"10%"}}>Title</th>
              <th style={{width:"10%"}}>Keywords</th>
              <th style={{width:"10%"}}>Details</th>
              <th style={{width:"20%"}}>Select Blog</th>
            </tr>
          </thead>
          <tbody>
            {data_emailtemplate_blog_fetch.length > 0 && data_emailtemplate_blog_fetch.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? styles.pinkrow : styles.greyrow}>
                <td style={{width:"10%"}}>{item.title}</td>
                <td style={{width:"10%"}}>{item.key_words}</td>
                <td style={{width:"30%"}}>{item.details}</td>
                <td style={{width:"20%"}}><span
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={()=>handleCommunityClick(item)}>
      {"Click to Select"}
    </span></td>
              </tr>
            ))}
          </tbody>
        </table>


        

            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default BlogDialog;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import aboutusReducer from '../reducer/aboutusReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../common_utils/constants';

const aboutusContext = createContext();

const initialState = {
  aboutus: [],
  contactus:[],
  faqs:[],
  privacy_policy:[],
  refund_policy:[],
  chief_members:[],
  loading: true,
  error: null,
};

let API = `${BASE_URL}`;


export const AboutusProvider = ({ children }) => {

const [state, dispatch] = useReducer(aboutusReducer , initialState);

const fetchAboutus = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/about_community` , params);
      
      dispatch({ type: 'ABOUT_US', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ABOUT_US_ERROR', payload: error });
    }
  };

  const fetchContactus = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/about_community` , params);
      
      dispatch({ type: 'CONTACT_US', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_US_ERROR', payload: error });
    }
  };



  const fetchFaqs = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/about_community` , params);
      
      dispatch({ type: 'FAQS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAQS_ERROR', payload: error });
    }
  };


 
  const fetchPrivacyPolicy = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/about_community` , params);
      
      dispatch({ type: 'PRIVACY_POLICY', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PRIVACY_POLICY_ERROR', payload: error });
    }
  };




  const fetchRefundPolicy = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/about_community` , params);
      
      dispatch({ type: 'REFUND_POLICY', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'REFUND_POLICY_ERROR', payload: error });
    }
  };




  const fetchChiefMembers = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/about_community` , params);
      
      dispatch({ type: 'CHIEF_MEMBERS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CHIEF_MEMBERS_ERROR', payload: error });
    }
  };

 
  
  return <aboutusContext.Provider value={{ ...state , dispatch , fetchAboutus , fetchContactus , fetchFaqs, fetchPrivacyPolicy , fetchRefundPolicy , fetchChiefMembers}}>{children}</aboutusContext.Provider>;
};

export const useAboutusContext = () => useContext(aboutusContext);
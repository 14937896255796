import { useEffect } from "react";
import { useAdminsupportTicketContext } from "../../contexts_admin/supportTicket_context";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";


function ViewComments({isopen , setClose, ticket_id}){


    const {SupportTicketCommentsSelect , data_supportComment_select} = useAdminsupportTicketContext();

    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }

  useEffect(()=>{

    if(data_supportComment_select.length > 0){
      //
      let item = "";

    }else{
        SupportTicketCommentsSelect({"ticket_id":ticket_id});
    }
    

  },[data_supportComment_select]);


    useEffect(()=>{
        setOpen(isopen);
    },[]);

    return(<>
    
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
              <DialogTitle>Comments</DialogTitle>
              <DialogContent>
        
              
              <div>
      {data_supportComment_select.length > 0 && data_supportComment_select.map((comment, index) => (
        <div key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="body1" style={{ color: '#666', marginBottom: '10px' }}>{comment.comments}</Typography>
          <Typography variant="caption" style={{ color: '#888' }}>Posted on {comment.comment_date}</Typography>
        </div>
      ))}
    </div>


   </DialogContent>
                    <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
    
    
    </>);



}

export default ViewComments;
import { Button, Card, CardMedia, Grid, Skeleton } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import { useCallback } from "react";
import { useState } from "react";
import ImageCropperScreen from "../../../../app_utils/ImageCropper";
import { IMAGE_URL, PROFILE_ID, USER_ID } from "../../../../common_utils/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useProofsAdminContext } from "../../../contexts_admin/members_context/proofs_contextAdmin";
import { useLocation } from "react-router-dom";
import React from "react";



function DocumentsUploadAdmin() {


  const dropzoneStyle = {
    border: '2px dashed #d9d9d9',
    borderRadius: '4px',
    padding: '5px',
    textAlign: 'center',
    cursor: 'pointer',
  };


  const { t } = useTranslation();
  const { fetchProofsListAdmin, data_proofs_list_admin, insert_proofsAdmin, insert_proofs_list_admin, update_proofsAdmin, update_proofs_list_admin ,
    update_idproofs , update_educationProofs , update_salaryproofs ,data_idproofs , data_educationproofs , data_salaryproofs } = useProofsAdminContext();

  const [Idproof, setIdProof] = useState("-");
  const [Eduproof, setEduProof] = useState("--");
  const [Salaryproof, setSalaryProof] = useState("---");
  const [open, setopen] = useState(false);
  const [index, setIndex] = useState(-1);
  const [isupdate ,setUpdate] = useState(-1);

  const [arrayImages, setArrayImages] = useState(["", "", ""]);


  const dropzone1 = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setIdProof(acceptedFiles[0]);

      setArrayImages((prevarray) => {
        const newArray = [...prevarray];
        newArray[0] = acceptedFiles[0];

        return newArray;
      });
    },
  });

  const dropzone2 = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    onDrop: (acceptedFiles1) => {
      setEduProof(acceptedFiles1[0]);

      setArrayImages((prevarray) => {
        const newArray = [...prevarray];
        newArray[1] = acceptedFiles1[0];

        return newArray;
      });

    },
  });

  const dropzone3 = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    onDrop: (acceptedFiles2) => {
      setSalaryProof(acceptedFiles2[0]);


      setArrayImages((prevarray) => {
        const newArray = [...prevarray];
        newArray[2] = acceptedFiles2[0];

        return newArray;
      });

    },
  });


  const handlecrop = () => {
    setIndex(0);
    setopen(true);


  };


  const handlecrop2 = () => {
    setIndex(1);
    setopen(true);

  };


  const handlecrop3 = () => {
    setIndex(2);
    setopen(true);

  };


  const onClose = () => {
    setopen(false);

  };


  const convertTofile = (base64Image, indexcropper) => {

    convertBase64ToFile(base64Image);
    setIndex(indexcropper);

  };

  const location = useLocation();


  useEffect(() => {

      const searchParams = new URLSearchParams(location.search);
      const userId = searchParams.get('userId');
      const communityId = searchParams.get('communityId');
      const username = searchParams.get('username');

     
      
    fetchProofsListAdmin({
      "type": "proofs",
      "userId": userId,
      "communityId": communityId,
      "original": "en",
      "translate": ["en"]
    });

      
    }, [location]);



  useEffect(() => {



    if (data_proofs_list_admin.length > 0) {

      if (data_proofs_list_admin[0].flat()[0].id_proofs != null) { downloadFile(data_proofs_list_admin[0].flat()[0].id_proofs, 0); }
      if (data_proofs_list_admin[0].flat()[0].education_proof != null) { downloadFile(data_proofs_list_admin[0].flat()[0].education_proof, 1); }
      if (data_proofs_list_admin[0].flat()[0].income_proof != null) { downloadFile(data_proofs_list_admin[0].flat()[0].income_proof, 2); }

      if(data_proofs_list_admin[0].flat()[0].is_id_verify == "1"){
        setSelectedOption("accept");
      }else if(data_proofs_list_admin[0].flat()[0].is_id_verify == "2"){
        setSelectedOption("reject");
      }
      

      if(data_proofs_list_admin[0].flat()[0].is_education_verify == "1"){
        setSelectedOption2("accept");
      }else if(data_proofs_list_admin[0].flat()[0].is_education_verify == "2"){
        setSelectedOption2("reject");
      }

      if(data_proofs_list_admin[0].flat()[0].is_income_verify == "1"){
        setSelectedOption3("accept");
      }else if(data_proofs_list_admin[0].flat()[0].is_income_verify == "2"){
        setSelectedOption3("reject");
      }


    }

  }, [data_proofs_list_admin]);



  const downloadFile = async (element, index) => {

    try {
      const response = await fetch(IMAGE_URL + "/uploads/" + element);
      const blob = await response.blob();
      const file = new File([blob], element);

      //setFiles(prevFiles => [...prevFiles, file]);

      if(index == 0){
        setIdProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[0] = file;

          return newArray;
        });

      }else if(index ==1){
        setEduProof(file);
        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[1] = file;

          return newArray;
        });

      }else{

        setSalaryProof(file);
        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[2] = file;

          return newArray;
        });

      }
     

    } catch (error) {
      console.error('Error fetching or creating file:', error);
    } 

  };







  const convertBase64ToFile = (base64Image) => {
    if (base64Image) {
      // Extract the base64 data (remove the data:image/png;base64, prefix)
      const base64Data = base64Image.split(',')[1];

      // Convert base64 to a Blob
      const blob = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(blob.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < blob.length; i++) {
        uint8Array[i] = blob.charCodeAt(i);
      }

      // Create a File from the Blob
      const file = new File([arrayBuffer], 'image' + index + '.png', { type: 'image/png' });

      if (index == 0) {
        setIdProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[0] = file;

          return newArray;
        });

      } else if (index == 1) {
        setEduProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[1] = file;

          return newArray;
        });

      } else {
        setSalaryProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[2] = file;

          return newArray;
        });
      }

      // Now 'file' is a File object that you can use or upload as needed
      console.log('File object:', file);
    }
  };




  useEffect(() => {


  

    if(isupdate == 1){
      
          if(insert_proofs_list_admin.affectedRows >= 1){
            alert("Successfully Uploaded");
          }else{
            alert("Error Uploading! Please try again");
          }

    }else if(isupdate == 2){

    

      if( update_proofs_list_admin.success == 1){
        alert("update Successfully Uploaded");
      }else{
        alert("Error Uploading! Please try again");
      }

    }


 }, [insert_proofs_list_admin , update_proofs_list_admin]);


 useEffect(()=>{

    if(data_salaryproofs.affectedRows >= 1){

      alert("Succesfully Verifications Updated");
             
    }
   
 },[data_salaryproofs]);



  const uploadFiles = () => {


    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const communityId = searchParams.get('communityId');
    const profile_id = searchParams.get('profile_id');


    const formData = new FormData();

    arrayImages.forEach((file, index) => {
      formData.append('sampleFile[]', file);
    });

    if(arrayImages[0].name != undefined){

    if(arrayImages[0].name.includes("_")){
      formData.append("id_proof", profile_id + "_" + arrayImages[0].name.split("_")[1]);
    }else if(!arrayImages[0].name.includes("_")){
      formData.append("id_proof", profile_id + "_" + arrayImages[0].name);
    }
  }else{

    formData.append("id_proof", "");

  }

    
    if(arrayImages[1].name != undefined){

    if(arrayImages[1].name.includes("_")){
    formData.append("education_proof", profile_id + "_" + arrayImages[1].name.split("_")[1]);
    }else if(!arrayImages[1].name.includes("_")){
      formData.append("education_proof", profile_id + "_" + arrayImages[1].name);
    }

  }else{

    formData.append("education_proof", "");
  }

  if(arrayImages[2].name != undefined){

    if(arrayImages[2].name.includes("_")){
    formData.append("income_proof", profile_id + "_" + arrayImages[2].name.split("_")[1]);
    }else if(!arrayImages[2].name.includes("_")){
      formData.append("income_proof", profile_id + "_" + arrayImages[2].name);
    }
    
  }else{

    formData.append("income_proof", "");

  }



    formData.append("is_id_verify", "0");
    formData.append("is_education_verify", "0");
    formData.append("is_income_verify", "0");

    formData.append("userId", userId);
    formData.append("communityId", communityId);
    formData.append("profileId", profile_id);

    if (data_proofs_list_admin.length <= 0) {

      setUpdate(1);
      insert_proofsAdmin(formData);
    } else {

      setUpdate(2);
      formData.append("Id", data_proofs_list_admin[0].flat()[0].Id);
      update_proofsAdmin(formData);

    }

   

  };



  const updateStatusVeriifcation = () => {

    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
    const communityId = searchParams.get('communityId');
    const profile_id = searchParams.get('profile_id');



    let  isverifyId = "0" , isverifyeducation = "0" , isverifysalary = "0";

    if(selectedOption == "accept"){
      isverifyId  = "1";
    }else if(selectedOption == "reject"){
      isverifyId  = "2";
    }
       
    
    if(selectedOption2 == "accept"){
      isverifyeducation  = "1";
    }else if(selectedOption2 == "reject"){
      isverifyeducation  = "2";
    }


    if(selectedOption3 == "accept"){
      isverifysalary  = "1";
    }else if(selectedOption3 == "reject"){
      isverifysalary  = "2";
    }

    update_idproofs({
      "isverify":isverifyId ,
      "communityId":communityId,
      "userId":userId 
    });


    update_educationProofs({
      "isverify":isverifyeducation ,
      "communityId":communityId,
      "userId":userId 
    });



    update_salaryproofs({
      "isverify":isverifysalary ,
      "communityId":communityId,
      "userId":userId 
    });



  }


  const [selectedOption, setSelectedOption] = useState(null);

    // Function to handle option change
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [selectedOption2, setSelectedOption2] = useState(null);

    // Function to handle option change
    const handleOptionChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };

    const [selectedOption3, setSelectedOption3] = useState(null);

    // Function to handle option change
    const handleOptionChange3 = (event) => {
        setSelectedOption3(event.target.value);
    };


  return (<>

    <Grid container spacing={3} width={"1300px"} style={{ justifyContent: "center", width: "100%", position: "absolute", top: "130px" }}>

      <Skeleton variant="rectangular" height={220} animation="wave" />
      <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
        <Card>


          {Idproof == "-" ? <CardMedia component="img" height="250" alt={`Image ${1}`} style={{ objectFit: "contain" }} /> : <CardMedia component="img" height="220" image={URL.createObjectURL(Idproof)} alt={`Image ${1}`} style={{ objectFit: "contain" }} />}


          <Button key={11} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9"
          }} onClick={handlecrop}  >Crop</Button>

          <div {...dropzone1.getRootProps()} style={dropzoneStyle}>
            <input {...dropzone1.getInputProps()} />
            <p>Upload Your ID Proof</p>
          </div>
          {data_proofs_list_admin.length > 0 ? <b style={{border:"1px solid palevioletred" , width:"100%"}}>{data_proofs_list_admin[0][0]["is_id_verify"] == "0" ? "Verification Going on" :  data_proofs_list_admin[0][0]["is_id_verify"] == "1" ?  "Verified" :  data_proofs_list_admin[0][0]["is_id_verify"] == "2" ?  "Rejected" : "" }</b> : "" }


          <div>
            <label>
                <input
                    type="radio"
                    name="acceptReject"
                    value="accept"
                    checked={selectedOption === "accept"}
                    onChange={handleOptionChange}
                />
                Accept
            </label>
            <label>
                <input
                    type="radio"
                    name="acceptReject"
                    value="reject"
                    checked={selectedOption === "reject"}
                    onChange={handleOptionChange}
                />
                Reject
            </label>

        </div>

        </Card>

       

      </Grid>



      <Skeleton variant="rectangular" height={220} animation="wave" />
      <Grid item xs={12} sm={6} md={4} lg={3} key={2}>
        <Card>


          {Eduproof == "--" ? <CardMedia component="img" height="250" alt={`Image ${1}`} style={{ objectFit: "contain" }} /> : <CardMedia component="img" height="220" image={URL.createObjectURL(Eduproof)} alt={`Image ${1}`} style={{ objectFit: "contain" }} />}


          <Button key={21} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9"
          }} onClick={handlecrop2}  >Crop</Button>

          <div {...dropzone2.getRootProps()} style={dropzoneStyle}>
            <input {...dropzone2.getInputProps()} />
            <p>Upload Your Education Proof</p>
          </div>
          {data_proofs_list_admin.length > 0 ? <b style={{border:"1px solid palevioletred" , width:"100%"}}>{data_proofs_list_admin[0][0]["is_education_verify"] == "0" ? "Verification Going on" :  data_proofs_list_admin[0][0]["is_education_verify"] == "1" ?  "Verified" :  data_proofs_list_admin[0][0]["is_education_verify"] == "2" ?  "Rejected" : "" }</b> : "" }
        
          <div>
            <label>
                <input
                    type="radio"
                    name="acceptReject2"
                    value="accept"
                    checked={selectedOption2 === "accept"}
                    onChange={handleOptionChange2}
                />
                Accept
            </label>
            <label>
                <input
                    type="radio"
                    name="acceptReject2"
                    value="reject"
                    checked={selectedOption2 === "reject"}
                    onChange={handleOptionChange2}
                />
                Reject
            </label>

        </div>


        </Card>
      </Grid>



      <Skeleton variant="rectangular" height={220} animation="wave" />
      <Grid item xs={12} sm={6} md={4} lg={3} key={3}>
        <Card>


          {Salaryproof == "---" ? <CardMedia component="img" height="250" style={{ objectFit: "contain" }} /> : <CardMedia component="img" height="220" image={URL.createObjectURL(Salaryproof)} alt={`Image ${1}`} style={{ objectFit: "contain" }} />}


          <Button key={31} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9"
          }} onClick={handlecrop3}   >Crop</Button>

          <div {...dropzone3.getRootProps()} style={dropzoneStyle}>
            <input {...dropzone3.getInputProps()} />
            <p>Upload Your Income Proof</p>
          </div>
          {data_proofs_list_admin.length > 0 ? <b style={{border:"1px solid palevioletred" , width:"100%"}}>{data_proofs_list_admin[0][0]["is_income_verify"] == "0" ? "Verification Going on" :  data_proofs_list_admin[0][0]["is_income_verify"] == "1" ?  "Verified" :  data_proofs_list_admin[0][0]["is_income_verify"] == "2" ?  "Rejected" : "" }</b> : "" }

          <div>
            <label>
                <input
                    type="radio"
                    name="acceptReject3"
                    value="accept"
                    checked={selectedOption3 === "accept"}
                    onChange={handleOptionChange3}
                />
                Accept
            </label>
            <label>
                <input
                    type="radio"
                    name="acceptReject3"
                    value="reject"
                    checked={selectedOption3 === "reject"}
                    onChange={handleOptionChange3}
                />
                Reject
            </label>

        </div>


        </Card>
      </Grid>

      <Button key={32} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9" , marginTop:"50px" , marginLeft:"auto" , marginRight:"auto" , width:"30%"
          }} onClick={uploadFiles}   >Upload Files</Button>

      <Button key={35} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9" , marginTop:"50px" , marginLeft:"auto" , marginRight:"auto" , width:"30%"
          }} onClick={updateStatusVeriifcation}   >Update Verification</Button>



    </Grid>

    {open == true && index == 0 ? <ImageCropperScreen image={[Idproof]} openPass={open} indexcropper={index} close={onClose} passBase64={convertTofile} /> : open == true && index == 1 ? <ImageCropperScreen image={[Eduproof]} openPass={open} indexcropper={0} close={onClose} passBase64={convertTofile} /> : open == true && index == 2 ? <ImageCropperScreen image={[Salaryproof]} openPass={open} indexcropper={0} close={onClose} passBase64={convertTofile} /> : ""}


  </>);

}

export default DocumentsUploadAdmin;
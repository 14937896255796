const memberdetailReducer = (state, action) => {
    switch (action.type) {
        
      case 'VIEW_MEMBER_DETAILS':
        return {
          ...state,
          list_member_details: action.payload,
          loading: false,
          error: null,
        };
      case 'VIEW_MEMBER_DETAILS_ERROR':
        return {
          ...state,
          list_member_details: [],
          loading: false,
          error: action.payload,
        };



        case 'VIEW_MEMBER_DETAILS_OWN':
        return {
          ...state,
          list_member_details_own: action.payload,
          loading_own_details: false,
          error: null,
        };
      case 'VIEW_MEMBER_DETAILS_OWN_ERROR':
        return {
          ...state,
          list_member_details_own: [],
          loading_own_details: false,
          error: action.payload,
        };




        case 'HOROSCOPE_CHART_VIEWHORO':
        return {
          ...state,
          data_horoscope_horo: action.payload,
          loading: false,
          error: null,
        };
      case 'HOROSCOPE_CHART_VIEWHORO_ERROR':
        return {
          ...state,
          data_horoscope_horo: [],
          loading: false,
          error: action.payload,
        };




        case 'NAVMSA_CHART_VIEWHORO':
        return {
          ...state,
          data_navmsa_horo: action.payload,
          loading: false,
          error: null,
        };
      case 'NAVMSA_CHART_VIEWHORO_ERROR':
        return {
          ...state,
          data_navmsa_horo: [],
          loading: false,
          error: action.payload,
        };


        case 'MATCH_MAKING_HORO':
        return {
          ...state,
          data_match_making_horo: action.payload,
          loading: false,
          error: null,
        };
      case 'MATCH_MAKING_HORO_ERROR':
        return {
          ...state,
          data_match_making_horo: [],
          loading: false,
          error: action.payload,
        };


        case 'INSERT_VIEW_PROFILE':
          return {
            ...state,
            data_insert_view_profile: action.payload,
            loading: false,
            error: null,
          };
        case 'INSERT_VIEW_PROFILE_ERROR':
          return {
            ...state,
            data_insert_view_profile: [],
            loading: false,
            error: action.payload,
          };

          case 'INSERT_VIEW_CONTACT':
            return {
              ...state,
              data_insert_view_contact: action.payload,
              loading: false,
              error: null,
            };
          case 'INSERT_VIEW_CONTACT_ERROR':
            return {
              ...state,
              data_insert_view_contact: [],
              loading: false,
              error: action.payload,
            };


            case 'SHORTLIST_INSERT_DETAILS':
              return {
                ...state,
                insert_shortlist_data: action.payload,
                loading: false,
                error: null,
              };
            case 'SHORTLIST_INSERT_DETAILS_ERROR':
              return {
                ...state,
                insert_shortlist_data: [],
                loading: false,
                error: action.payload,
              };
    

              case 'EXPRESS_INTEREST_INSERT_DETAILS':
              return {
                ...state,
                insert_interest_data: action.payload,
                loading: false,
                error: null,
              };
            case 'EXPRESS_INTEREST_INSERT_DETAILS_ERROR':
              return {
                ...state,
                insert_interest_data: [],
                loading: false,
                error: action.payload,
              };

   
              case 'BLOCK_USER':
              return {
                ...state,
                insert_block_user: action.payload,
                loading: false,
                error: null,
              };
            case 'BLOCK_USER_ERROR':
              return {
                ...state,
                insert_block_user: [],
                loading: false,
                error: action.payload,
              };


              case 'INSERT_NOTIFICATION_MEMBER':
                return {
                  ...state,
                  insert_notificatioin_data: action.payload,
                  loading: false,
                  error: null,
                };
              case 'INSERT_NOTIFICATION_MEMBER_ERROR':
                return {
                  ...state,
                  insert_notificatioin_data: [],
                  loading: false,
                  error: action.payload,
                };


                case 'ACCEPT_REJECT_DETAILS':
                return {
                  ...state,
                  insert_accept_reject_list: action.payload,
                  loading: false,
                  error: null,
                };
              case 'ACCEPT_REJECT_DETAILS_ERROR':
                return {
                  ...state,
                  insert_accept_reject_list: [],
                  loading: false,
                  error: action.payload,
                };




                   
              case 'ACCEPT_REJECT_DETAILS_ERROR':
                return {
                  ...state,
                  insert_accept_reject_list: [],
                  loading: false,
                  error: action.payload,
                };
      default:
        return state;
    }
  };

  export default memberdetailReducer;
import { Button, FormControl, Grid, Switch, TextField, Typography } from "@mui/material";
import styles from "../styles/register.module.css";

import { useRef, useState ,useEffect} from "react";
import { DropdownSelect, RadioButton, StyledTextField } from "../app_utils/input_fields";
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { birth_date, month_values, year, gender } from "../data/data";
import {produce} from 'immer';
import { Link, Navigate } from "react-router-dom";
import {generateYears, getProfileId , getTodayDate} from "../common_utils/utils";
import {PUBLIK_KEY} from "../common_utils/constants";
import { useMyContext } from "../contexts/registerContext";
import RotatingHeart from "../common_utils/loading_animator";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "../styles/animationfade.css";
import ModalPopup from "../app_utils/modalpopup";
import { validationlistRegister } from "../common_utils/validations";
import { useTranslation } from "react-i18next";
import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



function Register() {


    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs') || theme.breakpoints.down('sm'));

    const {register , data , loading , error , remove} = useMyContext();
    const { t } = useTranslation();
    const [year, setYears] = useState([]);


    const [clicked , setclick] = useState(false);
    const [validate , setvalidate] = useState(false);
    const [isAnimated, setAnimated] = useState(false);
    const [validationtext , setValidationtext] = useState("");

     

    useEffect(()=>{

        const currentYear = new Date().getFullYear();

        setYears(generateYears(1901  , currentYear-18));

    },[]);

   

    const  handlefromchild = () => {
        setvalidate(false);
    }


    useEffect( () => () =>  remove(), [] );

    const navigate = useNavigate();

    useEffect(() => {

       
       
        setAnimated(true);

          // Show an alert when a response is received
              if(data.length > 0){
                if(data[0].success  == "1"){
                    
                    alert("Registered Successfully");
                    navigate("/login");

                    localStorage.setItem();
                    
                }else if(data[0].success  == "0"){
                   
                    
                    setvalidate(true);
                    const htmlContent = { __html: "<b>Email Or Mobile Number Already Exists</b>"};
                    setValidationtext(htmlContent);

                }
            }
        
      }, [data]);

    

    const [textRegister, setFieldsRegister] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        birthDate:'',
        month:'',
        year:'',
        emailId:'',
        mobileNumber:'',
        password:''
      });

      const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;
    
        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
          draft[fieldName] = newValue;
        });
    
        // Set the updated state
        setFieldsRegister(nextState);
      };

      const onClick = () =>{

      let validationlist =  validationlistRegister(textRegister.firstName , textRegister.lastName , textRegister.gender , textRegister.birthDate ,textRegister.month ,textRegister.year , 
            textRegister.emailId , textRegister.mobileNumber  ,textRegister.mobileNumber.length, textRegister.password);

            const htmlContent = { __html: validationlist.join("")};

            if(validationlist.length > 0){

                setValidationtext(htmlContent);
                setvalidate(true);

            }else{

        const article = {
            "name":textRegister.firstName,
            "surname":textRegister.lastName,
            "emailid":textRegister.emailId,
            "password":textRegister.password,
            "gender":textRegister.gender,
            "birthdate":textRegister.year+"-"+textRegister.month+"-"+textRegister.birthDate,
            "mobile":textRegister.mobileNumber,
            "profile_id":getProfileId(),
            "community_id":"6",
            "community_name":"Pioneer Community",
            "mobile_reg_token":"",
            "web_reg_token":"",
            "joined_date":getTodayDate()
            };
            
           setclick(true);
           register(article);

        }

          

      };

         
     
    
    return (

        <>
        {clicked == true && loading == true ?  <RotatingHeart/> : 

        
<CSSTransition
      in={isAnimated}
      timeout={800} // Duration of the transition in milliseconds
      classNames="fade"
      unmountOnExit
    >
            <Grid container className={styles.section}  >
                <div  className={styles.cardsection}>

                    <Grid item >
                        <h2>{t('register_community')}</h2>

                    </Grid>

                    <Grid container spacing={{ xs: 1, md: 1 }}  columnSpacing={1} rowSpacing={1} className={styles.content}>

                        <Grid item xs={12} md={6} lg ={6}  xl={6}>
                            <StyledTextField label={t('first_name')}  variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#D74894"} hovercolor={"#E3256B"} height={50} onChange={handleChange('firstName')} value={textRegister.firstName}/>
                        </Grid>

                        <Grid item xs={12} md={6} lg ={6}  xl={6}>
                            <StyledTextField label={t('last_name')} variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#D74894"} hovercolor={"#E3256B"} height={50} onChange={handleChange('lastName')}  value={textRegister.lastName}/>
                        </Grid>


                    </Grid>

                    <Grid item spacing={1} className={styles.content} >

                        <DropdownSelect options={gender} label={t('gender')} focusedBorderColor={"#E3256B"}
                            borderColor={"#D74894"} hoverBorderColor={"#E3256B"} height={50}
                            onChange={handleChange('gender')}
                            value={textRegister.gender}
                            sx={{ width: "100%", backgroundColor: "#E3256B", color: "white", marginTop: "-5px" }} />



                    </Grid>

                    <Grid container spacing={1} className={styles.content} style={{ marginTop: "-25px" }}>

                        <Grid item xs={12} md={4}>

                            <DropdownSelect options={birth_date}  label={t('birth_date')} focusedBorderColor={"#E3256B"}
                                borderColor={"#D74894"} hoverBorderColor={"#E3256B"} height={50}
                                sx={{ width: "100%", backgroundColor: "#E3256B", color: "white" }} value={textRegister.birthDate} onChange={handleChange('birthDate')} />



                        </Grid>

                        <Grid item xs={12} md={4}>

                            <DropdownSelect options={month_values} label={t('birth_month')} focusedBorderColor={"#E3256B"}
                                borderColor={"#D74894"} hoverBorderColor={"#E3256B"} height={50} onChange={handleChange('month')}
                                value={textRegister.month} />



                        </Grid>

                        <Grid item xs={12} md={4}>

                            <DropdownSelect options={year} label={t('birth_year')} focusedBorderColor={"#E3256B"}
                                borderColor={"#D74894"} hoverBorderColor={"#E3256B"} height={50} onChange={handleChange('year')}
                                value={textRegister.year} />
                        </Grid>


                        <Grid item xs={12} style={{ marginTop: "-15px"}}>
                            <StyledTextField label={t('emailid')} variant="outlined" focusedcolor={"#E3256B"}
                                bordercolor={"#D74894"} hovercolor={"#E3256B"} height={50}
                                style={{overflow:"auto"}}
                                onChange={handleChange('emailId')} value={textRegister.emailId}/>
                        </Grid>


                        <Grid item xs={12} >
                            <StyledTextField label={t('mobile_number')} variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#D74894"} hovercolor={"#E3256B"} height={50} 
                                onChange={handleChange('mobileNumber')} value={textRegister.mobileNumber}/>
                        </Grid>


                        <Grid item xs={12} >
                            <StyledTextField type="password" label={t('password')} variant="contained" focusedcolor={"#E3256B"}
                                bordercolor={"#D74894"} hovercolor={"#E3256B"} height={50}  onChange={handleChange('password')}
                                value={textRegister.password}/>
                        </Grid>

                        <Grid item xs={12}>

                            <Button
                                variant="contained"
                                sx={{ width: "100%", height: '40px', backgroundColor: "#E3256B", color: "white", marginTop: "15px" }}
                                onClick={onClick} // Adjust width and height as needed
                            >
                                {t('click_register')}
                            </Button>

                        </Grid>

                        <Grid item xs={12}>

                            <Button
                                variant="contained"
                                style={{ height: isXs ? '150' : '40'  , width: "100%", backgroundColor: "#E3256B", color: "white" , marginTop:"20px" }}// Adjust width and height as needed
                            >
                                <Link to={"/login"} style={{ textDecoration: 'none' , color:"white"}}>{t('already_signup')}</Link>
                            </Button>

                        </Grid>

                    </Grid>




                      { validate == true ? <ModalPopup  title="Validation Alert" content={validationtext}  openvalidate={validate} onChildValue={handlefromchild}/> : ""}


                </div>
            </Grid>

            </CSSTransition>


        }


        </>



    );


}



export default Register;
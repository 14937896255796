import React, { useEffect } from 'react';
import '../../styles/comparisonbox.css'; // Import the CSS file for styling
import { Grid } from '@mui/material';
import { MARITAL_STATUS_P_VAL, NAME } from '../../common_utils/constants';
import { useTranslation } from 'react-i18next';
import { getInches } from '../../common_utils/utils';
import { useState } from 'react';
import { isEqualState } from 'react-advanced-cropper';
import { CSSTransition } from 'react-transition-group';


const ComparisonBox = ({list_member_details}) => {


  const { t } = useTranslation();

  const [isAnimated, setAnimated] = useState(false);


  const isequalMarital = (prefer_marital , marital) => {
    
    return prefer_marital != undefined ?  prefer_marital == marital : false;
  };

  const isequalHeights = (prefer_height , height) => {

    if(prefer_height != undefined && prefer_height != "0"){

      let height1 =  getInches(prefer_height.split("-")[0]);
      let height2 =  getInches(prefer_height.split("-")[1]);
  
      let heightcompare = getInches(height);

      return heightcompare >= height1 && heightcompare <= height2;
    }else{
      return false;
    }
  
  };

  const isequalCaste = (prefer_caste , caste) => {
    return prefer_caste != undefined ? prefer_caste == caste : false;
  };


  const isequalSubcaste = (prefer_subcaste , subcaste) => {
    return prefer_subcaste != undefined ?  prefer_subcaste == subcaste : false;
  };

  const isequalState = (prefer_state , state) => {
    return prefer_state != undefined ? prefer_state.includes(state)  : false;
  };


  const isequalCity = (prefer_city , city) => {
    return prefer_city != undefined ? prefer_city.includes(city) : false;
  };

  const isequalEducation = (prefer_education , education) => {
    return prefer_education != undefined ? prefer_education.includes(education) : false;
  };
  
  const isequalOccupation = (prefer_occupation , occupation) => {
    return prefer_occupation != undefined ? prefer_occupation.includes(occupation) : false;
  };

  const isequalFamily = (prefer_family , family) => {
    return prefer_family != undefined ? prefer_family.includes(family) : false;
  };

  const isequalDietType = (prefer_diettype , diettype) => {
    return prefer_diettype != undefined ? prefer_diettype.includes(diettype) : false;
  };

  const isequalSkintone = (prefer_skintone , skintone) => {

    if(prefer_skintone != undefined){
      let array_skintone = prefer_skintone.split(",");
      return array_skintone.some(value => value.trim() === skintone);
    }else{
      return false;
    }

  };

  const isequalBodytype = (prefer_bodytype , bodytype) => {
    return prefer_bodytype != undefined ? prefer_bodytype.includes(bodytype) : false;
  };

  const isequalDrinktype= (prefer_drinktype , drinktype) => {
    return prefer_drinktype != undefined ? prefer_drinktype.includes(drinktype) : false;
  };

  const isequalSmoketype = (prefer_smoketype ,smoketype) => {
    return prefer_smoketype != undefined ?  prefer_smoketype.includes(smoketype) : false;
  };

  const isequalAnnualincome = (prefer_annualincome , annualincome) => {

    if(prefer_annualincome != undefined){

      let min_income =  prefer_annualincome.split("-")[0];
      let max_income =  prefer_annualincome.split("-")[1];
  
      return annualincome >= min_income && annualincome <= max_income;
    }else{
      return false;
    }
    
  };


  const [booleanArray, setBooleanArray] = useState([false ,false , false, false , false , false ,false , false, false , false , false ,false , false, false , false]);
  const [count , setcount] = useState(-1);
  const [percent , setpercent] = useState(-1);

  // Function to toggle a specific index in the boolean array
  const updateAtIndex = (index) => {
    setBooleanArray((prevArray) => {
      const newArray = [...prevArray];

      if(index == 0){
        newArray[index] = isequalMarital(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["marital_status"] : "0" , list_member_details[0][0]["0"] != undefined ? list_member_details[0][0]["0"]["marital_status"] : "1");
      }else if(index == 1){
        newArray[index] = isequalHeights( list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["height_range"]  : "0", list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["height"] : "1");
      }else if(index == 2){
        newArray[index] = isequalCaste(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["caste"] : "0" , list_member_details[0][0]["0"] != undefined ? list_member_details[0][0]["0"]["caste"] : "1");
      }else if(index == 3){
        newArray[index] = isequalSubcaste(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["subcaste"] : "0" , list_member_details[0][0]["0"] != undefined ? list_member_details[0][0]["0"]["subcaste"] : "1");
      }else if(index == 4){
        newArray[index] = isequalState(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["state"] : "0",  list_member_details[1][0]["0"] != undefined ? list_member_details[1][0]["0"]["perm_state"]  :"1");
      }else if(index == 5){
        newArray[index] = isequalCity(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["city"] : "0" , list_member_details[1][0]["0"] != undefined ? list_member_details[1][0]["0"]["perm_city"] : "1");
      }else if(index == 6){
        newArray[index] = isequalEducation(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["education_list"] : "0" , list_member_details[3][0]["0"] != undefined ? list_member_details[3][0]["0"]["education"] : "1");
      }else if(index == 7){
        newArray[index] = isequalOccupation(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["occupation"]  :"0" , list_member_details[4][0]["0"] != undefined ? list_member_details[4][0]["0"]["occupation"] : "1");
      }else if(index == 8){
        newArray[index] = isequalFamily(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["family_value"] :"0" , list_member_details[5][0]["0"] != undefined ? list_member_details[5][0]["0"]["family_value"] : "1");
      }else if(index == 9){
        newArray[index] = isequalDietType(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["diet_type"] : "0" , list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["diet_type"] : "1");
      }else if(index == 10){
        newArray[index] = isequalSkintone(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["skintone"] : "0" , list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["skintone"] : "1");
      }else if(index == 11){
        newArray[index] = isequalBodytype(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["body_type"] : "0" , list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["body_type"] : "1");
      }else if(index == 12){
        newArray[index] = isequalSmoketype(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["drink_type"] : "0" , list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["drink_type"] : "1");
      }else if(index == 13){
        newArray[index] = isequalDrinktype(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["smoke_type"] : "0" , list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["smoke_type"] : "1");
      }else if(index == 14){
        newArray[index] = isequalAnnualincome(list_member_details[10][0]["0"] != undefined ? list_member_details[10][0]["0"]["annual_income"] : "0" , list_member_details[4][0]["0"] != undefined ? list_member_details[4][0]["0"]["annual_income"] : "1");
      }
      
      return newArray;
    });
  };


  useEffect(()=>{

    

  booleanArray[0] =  updateAtIndex(0);
  booleanArray[1] =  updateAtIndex(1);
  booleanArray[2] =  updateAtIndex(2);
  booleanArray[3] =  updateAtIndex(3);
  booleanArray[4] =  updateAtIndex(4);
  booleanArray[5] =  updateAtIndex(5);
  booleanArray[6] =  updateAtIndex(6);
  booleanArray[7] =  updateAtIndex(7);
  booleanArray[8] =  updateAtIndex(8);
  booleanArray[9] =  updateAtIndex(9);
  booleanArray[10] =  updateAtIndex(10);
  booleanArray[11] =  updateAtIndex(11);
  booleanArray[12] =  updateAtIndex(12);
  booleanArray[13] =  updateAtIndex(13);
  booleanArray[14] =  updateAtIndex(14);

  } , []);

  useEffect(()=>{

    

    const trueCount = booleanArray.filter(value => value === true).length;
    setcount(trueCount);

    let percentage =  (trueCount * 100)/15;
    setpercent(percentage);

  },[booleanArray]);


  return (

    <>

    <div className="div_main">

    <Grid container xs={12}>
    <div className='circular_rectangle'>{count+"/15"}</div>

    <div className='circular_rectangle2'>{Math.round(percent)+"%"}</div>

    </Grid>
    
    <div className="stylish_profile_matching">

      

      <Grid container xs={12}>
      <div className="profile_box left_profile">

         <div style={{"textAlign":"right" , "marginTop":"10px" , "marginRight":"50px" , "marginBottom":"30px" , "fontFamily":"Dosis" , "fontSize":"20px" ,  "color":"#D74894" , "fontWeight":"bold"}}>{localStorage.getItem(NAME)+" (You)"}</div>

        <div className="arrow"></div>
       
        <div className="profile_details">

        
          
          <Grid container spacing={2} style={{"textAlign":"right"}}>
      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('marital_status_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined ?  list_member_details[10][0]["0"]["marital_status"]  : ""}</span>
      </Grid>
      <Grid item xs={2}>
        { booleanArray[0] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

     

      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('height_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["height_range"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[1] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('caste_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["caste"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[2] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('subcaste_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["subcaste"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[3] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('state_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["state"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[4] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('city_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["city"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[5] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('education_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["education_list"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[6] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('occupation_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["occupation"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[7] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('familyvalue_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["family_value"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[8] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('diettype_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["diet_type"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[9] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


       <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('skintone_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["skintone"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[10] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('bodytype_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["body_type"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[11] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('drinktype_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["drink_type"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[12] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('smoketype_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["smoke_type"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[13] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>


      <Grid item xs={10} style={{"lineHeight":"1"}}>
      <span className="key11">{t('annual_income_match_l') + ":"}</span> <span className="value11">{list_member_details[10][0]["0"] != undefined  ? list_member_details[10][0]["0"]["annual_income"] : ""}</span>
      </Grid>
      <Grid item xs={2}>
      { booleanArray[14] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>

      

    </Grid>
         
        </div>
      </div>

      <div className="profile_box right_profile">
        <div className="arrow"></div>
        <div className="profile_details">

        <div style={{"textAlign":"left" , "marginLeft":"30px" , "marginBottom":"30px" , "fontFamily":"Dosis" , "fontSize":"20px" ,  "color":"#D74894" , "fontWeight":"bold"}}>{list_member_details[0][0]["0"]["fullname"]}</div>
       <Grid container spacing={2} style={{"textAlign":"left" , paddingLeft:"30px"}}>


       <Grid item xs={1} style={{"lineHeight":"0.6"}}>
       { booleanArray[0] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('marital_status_l') + ":"}</span> <span className="value11">{list_member_details[0][0]["0"] != undefined ? list_member_details[0][0]["0"]["marital_status"] : ""}</span>
      </Grid>
     

      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
    { booleanArray[1] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('height_l') + ":"}</span> <span className="value11">{list_member_details[2][0]["0"] != undefined ?  list_member_details[2][0]["0"]["height"] :""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
    { booleanArray[2] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('caste_p_l') + ":"}</span> <span className="value11">{list_member_details[0][0]["0"] != undefined ?  list_member_details[0][0]["0"]["caste"] : ""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
    { booleanArray[3] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('subcaste_l') + ":"}</span> <span className="value11">{list_member_details[0][0]["0"] != undefined ?  list_member_details[0][0]["0"]["subcaste"] : ""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[4] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('state_p_l') + ":"}</span> <span className="value11">{list_member_details[1][0]["0"] != undefined ? list_member_details[1][0]["0"]["perm_state"] : ""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[5] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('city_p_l') + ":"}</span> <span className="value11">{list_member_details[1][0]["0"] != undefined ? list_member_details[1][0]["0"]["perm_city"] : ""}</span>
      </Grid>


      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[6] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('education_p_l') + ":"}</span> <span className="value11">{list_member_details[3][0]["0"] != undefined ? list_member_details[3][0]["0"]["education"] : ""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[7] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('occuaption_p_l') + ":"}</span> <span className="value11">{list_member_details[4][0]["0"] != undefined ? list_member_details[4][0]["0"]["occupation"] : ""}</span>
      </Grid>




      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[8] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('family_value_p_l') + ":"}</span> <span className="value11">{list_member_details[5][0]["0"] != undefined ?  list_member_details[5][0]["0"]["family_value"] : ""}</span>
      </Grid>




      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[9] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('diet_p_l') + ":"}</span> <span className="value11">{list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["diet_type"] : ""}</span>
      </Grid>


      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[10] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('skintone_l') + ":"}</span> <span className="value11">{list_member_details[2][0]["0"] != undefined ?  list_member_details[2][0]["0"]["skintone"] : ""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[11] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('body_type_p_l') + ":"}</span> <span className="value11">{list_member_details[2][0]["0"] != undefined ?  list_member_details[2][0]["0"]["body_type"] : ""}</span>
      </Grid>




      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[12] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('drink_type_p_l') + ":"}</span> <span className="value11">{list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["drink_type"] : ""}</span>
      </Grid>




      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[13] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('smoke_type_p_l') + ":"}</span> <span className="value11">{list_member_details[2][0]["0"] != undefined ? list_member_details[2][0]["0"]["smoke_type"] : ""}</span>
      </Grid>



      <Grid item xs={1} style={{"lineHeight":"0.6"}}>
      { booleanArray[14] ? <div className="rounded_green_tick" /> : <div className="rounded_grey_tick" />}
      </Grid>
       <Grid item xs={11}>
      <span className="key11">{t('annual_income_l') + ":"}</span> <span className="value11">{list_member_details[4][0]["0"] != undefined ?  list_member_details[4][0]["0"]["annual_income"] : ""}</span>
      </Grid>


     

      </Grid>

      
          
        </div>
      </div>
      </Grid>

    </div>


    </div>

    </>
  );

};

export default ComparisonBox;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/mmeber_recducer';
import { BASE_URL } from '../../common_utils/constants';

const mmebersContext = createContext();

const initialState = {
  loading_freemmebers: true,
  error: null,
  data_free_members: [],
  data_paid_members: [],
  data_deleted_members: [],
  data_blocked_members: [],
  data_unapproved_members: [],
  list_member_details_own_admin: [],
  list_images_unapproved:[],
  total_row_count_unapproved:0,
  loading_own_details_admin: true,
  error_own_details: null,
  verifypic1_list:[],
  verifypic2_list:[],
  verifypic3_list:[],
  verifypic4_list:[],
  verifypic5_list:[],
  verifypic6_list:[],
  verifypic7_list:[],
  verifypic8_list:[],
  verify_user_profile_list:[],
  insert_featured_profile_list:[],
  delete_featured_profile_list:[],
  list_master_data : [],
  data_multibasic : 0,
  data_multicontact :0,
  data_multieducation :0,
  data_multifamily :0,
  data_multilifestyle :0,
  data_multioccupation :0,
  data_multihoroscope :0,
  data_multiphoto :0,
  data_multipartnerprefs :0,
  data_multinotification :0,
  data_multinotification_count :0,
  data_multisettings :0,
  data_multiproof :0,

  data_annual_income:0,
  data_birthstar:0,
  data_blood_group:0,
  data_bodytype:0,
  data_caste:0,
  data_city:0,
  data_createdby:0,
  data_biginstitute:0,
  data_degree:0,
  data_diet_type:0,
  data_drinktype:0,
  
  data_fmlstatus:0,
  data_fmltype:0,
  data_fmlvalues:0,
  data_housetype:0,
  data_maritalstatus:0,
  data_languages:0,
  data_occupation:0,
  data_rashi:0,
  data_skintone:0,
  data_smoketype:0,
  data_state:0,
  data_subcaste:0,
  data_registeration:0,
  list_caste_admin:[]

};

let API = `${BASE_URL}`;


export const MemberProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const freeMembers = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/free_members` , article);
      

      dispatch({ type: 'FREEMEMBERS_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'FREEMEMBERS_ERROR', payload: error });
    }
    
  };


  const PremiumMembers = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/paid_members` , article);
      

      dispatch({ type: 'PAID_MEMBER_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'PAID_MEMBER_ERROR', payload: error });
    }
  };



  const DeletedMembers = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/deleted_members` , article);
      

      dispatch({ type: 'DELTED_MEMBER_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'DELETED_MEMBER_ERROR', payload: error });
    }
  };



  const BlockedMembersAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/blocked_members` , article);
      
      dispatch({ type: 'BLOCKED_MEMBER_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'BLOCKED_MEMBER_ERROR', payload: error });
    }
  };




  const UnapprovedProfilesAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/unapproved_profiles` , article);
      
      dispatch({ type: 'UNAPPROVED_MEMBER_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'UNAPPROVED_MEMBER_ERROR', payload: error });
    }
  };



  const UnapprovedProfilesPics = async (article) => {

    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/pictures_select` , article);
      
      dispatch({ type: 'PICTURES_SELECT_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'PICTURES_SELECT_ERROR', payload: error });
    }

  };


  
  const MemberDetailsListOWnAdmin = async (params) => {
    try {

      const response = await axios.post(`${API}`+`fetch_data/profiledetail_fetch` , params);
      dispatch({ type: 'VIEW_MEMBER_DETAILS_OWN_ADMIN', payload: response.data.data });
    } catch (error) {

      dispatch({ type: 'VIEW_MEMBER_DETAILS_OWN_ADMIN_ERROR', payload: error });
    }
  };


  const clearlist_member_details_own_admin = async (params) => {
  
      dispatch({ type: 'CLEAR_LIST_ADMIN_DATA', payload:"" });
  };


  const clearlist_list_images = async (params) => {
  
    dispatch({ type: 'CLEAR_LIST_IMAGES', payload:"" });
};


const verifypic1 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic1` , article);
    
    dispatch({ type: 'VERIFYPIC1', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC1_ERROR', payload: error });
  }
  
};



const verifypic2 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic2` , article);
    
    dispatch({ type: 'VERIFYPIC2', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC2_ERROR', payload: error });
  }
  
};


const verifypic3 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic3` , article);
    
    dispatch({ type: 'VERIFYPIC3', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC3_ERROR', payload: error });
  }
  
};


const verifypic4 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic4` , article);
    
    dispatch({ type: 'VERIFYPIC4', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC4_ERROR', payload: error });
  }
  
};


const verifypic5 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic5` , article);
    
    dispatch({ type: 'VERIFYPIC5', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC5_ERROR', payload: error });
  }
  
};


const verifypic6 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic6` , article);
    
    dispatch({ type: 'VERIFYPIC6', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC6_ERROR', payload: error });
  }
  
}

const verifypic7 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic7` , article);
    
    dispatch({ type: 'VERIFYPIC7', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC7_ERROR', payload: error });
  }
  
};


const verifypic8 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic8` , article);
    
    dispatch({ type: 'VERIFYPIC8', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC8_ERROR', payload: error });
  }
  
};



const verify_user_profile = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verify_user_profile` , article);
    
    dispatch({ type: 'VERIFY_USER_PROFILE', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFY_USER_PROFILE_ERROR', payload: error });
  }
  
};



const insert_featured_profile = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/insert_featured_profile` , article);
    
    dispatch({ type: 'INSERT_FEATURED_PROFILE', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'INSERT_FEATURED_PROFILE_ERROR', payload: error });
  }
  
};



const delete_featured_profile = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/delete_featured_profile` , article);
    
    dispatch({ type: 'DELETE_FEATURED_PROFILE', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'DELETE_FEATURED_PROFILE_ERROR', payload: error });
  }
  
};


const master_table_fetch_all = async (article) => {

  try {
    const response = await axios.post(`${API}`+`fetch_data/master_table_fetch_all` , article);
    
    dispatch({ type: 'MASTER_FETCH_ALL', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'MASTER_FETCH_ALL_ERROR', payload: error });
  }
  
};



const master_table_fetch_all_clear = () => {

  try {
    dispatch({ type: 'MASTER_FETCH_ALL_CLEAR'});
  } catch (error) {
  }
  
};


const INSERT_MULTI_BASICDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_basicdetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_BASICDETAIL', payload: response.data.data});

  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_BASICDETAIL_ERROR', payload: error });

    
  }
  
};



const INSERT_MULTI_CONTACTDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_contactdetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_CONTACTDETAIL', payload: response.data.data});


   
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_CONTACTDETAIL_ERROR', payload: error });

    
  }
  
};


const INSERT_MULTI_EDUDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_educationdetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_EDUDETAIL', payload: response.data.data});

    
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_EDUDETAIL_ERROR', payload: error });
  }
  
};




const INSERT_MULTI_FAMILYDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_familydetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_FAMILYDETAIL', payload: response.data.data});



     console.log(response.data.data);
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_FAMILYDETAIL_ERROR', payload: error });

    console.log(error);
  }
  
  
};



const INSERT_MULTI_LIFESTYLEDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_lifestyledetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_LIFESTYLEDETAIL', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_LIFESTYLEDETAIL_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_OCCUPATIONDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_occupationdetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_OCCUPATIONDETAIL', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_OCCUPATIONDETAIL_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_HOROSCOPEDETAIL = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_horoscopedetail` , article);
    
    dispatch({ type: 'INSERT_MULTI_HOROSCOPEDETAIL', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_HOROSCOPEDETAIL_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_PARTNER_PREFERENCE = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_partner_preference` , article);
    
    dispatch({ type: 'INSERT_MULTI_PARTNER_PREFERENCE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_PARTNER_PREFERENCE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_PHOTO = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_photo` , article);
    
    dispatch({ type: 'INSERT_MULTI_PHOTO', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_PHOTO_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_NOTIFICATION = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_notification` , article);
    
    dispatch({ type: 'INSERT_MULTI_NOTIFICATION', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_NOTIFICATION_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_NOTIFICATIONCOUNT = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_notificationcount` , article);
    
    dispatch({ type: 'INSERT_MULTI_NOTIFICATIONCOUNT', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_NOTIFICATIONCOUNT_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_SETTINGS = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_settings` , article);
    
    dispatch({ type: 'INSERT_MULTI_SETTINGS', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_SETTINGS_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_PROOF = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_proof` , article);
    
    dispatch({ type: 'INSERT_MULTI_PROOF', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_PROOF_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_ANNUALINCOME = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_annualincome` , article);
    
    dispatch({ type: 'INSERT_MULTI_ANNUALINCOME', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_ANNUALINCOME_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_BIRTHSTAR = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_birthstar` , article);
    
    dispatch({ type: 'INSERT_MULTI_BIRTHSTAR', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_BIRTHSTAR_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_BLOODGROUP = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_bloodgroup` , article);
    
    dispatch({ type: 'INSERT_MULTI_BLOODGROUP', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_BLOODGROUP_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_BODYTYPE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_bodytype` , article);
    
    dispatch({ type: 'INSERT_MULTI_BODYTYPE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_BODYTYPE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_CASTE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_caste` , article);
    
    dispatch({ type: 'INSERT_MULTI_CASTE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_CASTE_ERROR', payload: error });
  }
  
};

const INSERT_MULTI_CITY = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_city` , article);
    
    dispatch({ type: 'INSERT_MULTI_CITY', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_CITY_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_CREATEDBY = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_createdby` , article);
    
    dispatch({ type: 'INSERT_MULTI_CREATEDBY', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_CREATEDBY_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_BIGINSTITUTE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_biginstitute` , article);
    
    dispatch({ type: 'INSERT_MULTI_BIGINSTITUTE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_BIGINSTITUTE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_DEGREE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_degree` , article);
    
    dispatch({ type: 'INSERT_MULTI_DEGREE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_DEGREE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_DIETTYPE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_diet_type` , article);
    
    dispatch({ type: 'INSERT_MULTI_DIETTYPE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_DIETTYPE_ERROR', payload: error });
  }
  
};




const INSERT_MULTI_DRINKTYPE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_drinktype` , article);
    
    dispatch({ type: 'INSERT_MULTI_DRINKTYPE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_DRINKTYPE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_FMLSTATUS = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_fmlstatus` , article);
    
    dispatch({ type: 'INSERT_MULTI_FMLSTATUS', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_FMLSTATUS_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_FMLTYPE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_fmltype` , article);
    
    dispatch({ type: 'INSERT_MULTI_FMLTYPE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_FMLTYPE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_FMLVALUES = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_fmlvalues` , article);
    
    dispatch({ type: 'INSERT_MULTI_FMLVALUES', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_FMLVALUES_ERROR', payload: error });
  }
  
};




const INSERT_MULTI_HOUSETYPE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_housetype` , article);

    dispatch({ type: 'INSERT_MULTI_HOUSETYPE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_HOUSETYPE_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_MARITALSTATUS = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_maritalstatus` , article);
    
    dispatch({ type: 'INSERT_MULTI_MARITALSTATUS', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_MARITALSTATUS_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_LANGUAGES = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_languages` , article);
    
    dispatch({ type: 'INSERT_MULTI_LANGUAGES', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_LANGUAGES_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_OCCUPATION = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_occupation` , article);
    
    dispatch({ type: 'INSERT_MULTI_OCCUPATION', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_OCCUPATION_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_RASHI = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_rashi` , article);
    
    dispatch({ type: 'INSERT_MULTI_RASHI', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_RASHI_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_SKINTONE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_skintone` , article);
    
    dispatch({ type: 'INSERT_MULTI_SKINTONE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_SKINTONE_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_SMOKETYPE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_smoketype` , article);
    
    dispatch({ type: 'INSERT_MULTI_SMOKETYPE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_SMOKETYPE_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_STATE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_state` , article);
    
    dispatch({ type: 'INSERT_MULTI_STATE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_STATE_ERROR', payload: error });
  }
  
};



const INSERT_MULTI_REGISTERATION = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_registeration` , article);
    
    dispatch({ type: 'INSERT_MULTI_REGISTERATION', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_REGISTERATION_ERROR', payload: error });
  }
  
};


const INSERT_MULTI_SUBCASTE = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/insert_multi_subcaste` , article);
    
    dispatch({ type: 'INSERT_MULTI_SUBCASTE', payload: response.data.data});
  } catch (error) {
    dispatch({ type: 'INSERT_MULTI_SUBCASTE_ERROR', payload: error });
  }
  
};



const EXECUTE_CREATE_STATEMENTS = async (article) => {
  
  try {
    const response = await axios.post(`${API}`+`adminpanel/role_community/executeCreateStatemnets` , article);
    
    dispatch({ type: 'EXECUTE_CREATE_STATEMENTS', payload: []});
  } catch (error) {
    dispatch({ type: 'EXECUTE_CREATE_STATEMENTS_ERROR', payload: error });
  }
  
};


const caste_fetch = async (article) => {
  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/caste_fetch` , article);
    dispatch({ type: 'CASTE_FETCH', payload: response.data.data  });
  } catch (error) {
    dispatch({ type: 'CASTE_FETCH_ERROR', payload: error });
  }
};



const clear_featuredprofiles = async (article) => {

  try {
    
    dispatch({ type: 'CLEAR_FEATURED_PROFILE', payload: "" });
  } catch (error) {

    console.log(error);
  }
  
};

const clear_listmaster_table = async (article) => {

  try {
    
    dispatch({ type: 'CLEAR_MASTER_TABLE', payload: "" });
  } catch (error) {

    console.log(error);
  }
  
};



  
  return <mmebersContext.Provider value={{ ...state , dispatch , freeMembers , MemberDetailsListOWnAdmin , clearlist_member_details_own_admin , PremiumMembers , DeletedMembers , BlockedMembersAdmin , UnapprovedProfilesAdmin , UnapprovedProfilesPics , clearlist_list_images , verifypic1 ,verifypic2 ,verifypic3 ,verifypic4 ,verifypic5 , verifypic6 ,verifypic7 ,verifypic8 , verify_user_profile , insert_featured_profile , delete_featured_profile , clear_featuredprofiles , master_table_fetch_all ,INSERT_MULTI_BASICDETAIL , INSERT_MULTI_EDUDETAIL ,INSERT_MULTI_FAMILYDETAIL , INSERT_MULTI_HOROSCOPEDETAIL ,INSERT_MULTI_CONTACTDETAIL , INSERT_MULTI_LIFESTYLEDETAIL ,INSERT_MULTI_NOTIFICATION , INSERT_MULTI_NOTIFICATIONCOUNT ,INSERT_MULTI_OCCUPATIONDETAIL , INSERT_MULTI_PARTNER_PREFERENCE ,INSERT_MULTI_PHOTO ,INSERT_MULTI_PROOF ,INSERT_MULTI_SETTINGS , INSERT_MULTI_ANNUALINCOME , INSERT_MULTI_BIGINSTITUTE, INSERT_MULTI_BIRTHSTAR , INSERT_MULTI_BLOODGROUP , INSERT_MULTI_BODYTYPE , INSERT_MULTI_CASTE,
    INSERT_MULTI_CITY , INSERT_MULTI_CREATEDBY , INSERT_MULTI_DEGREE , INSERT_MULTI_DIETTYPE , INSERT_MULTI_DRINKTYPE , INSERT_MULTI_FMLSTATUS,
    INSERT_MULTI_FMLTYPE , INSERT_MULTI_FMLVALUES , INSERT_MULTI_HOUSETYPE , INSERT_MULTI_LANGUAGES , INSERT_MULTI_MARITALSTATUS ,
    INSERT_MULTI_OCCUPATION , INSERT_MULTI_RASHI , INSERT_MULTI_SKINTONE , INSERT_MULTI_SMOKETYPE , INSERT_MULTI_STATE , INSERT_MULTI_SUBCASTE , caste_fetch,
    EXECUTE_CREATE_STATEMENTS , INSERT_MULTI_REGISTERATION , master_table_fetch_all_clear , clear_listmaster_table
  }}>{children}</mmebersContext.Provider>;
};

export const useAdminMembersContext = () => useContext(mmebersContext);
import { useState } from "react";
import { useUploadedFilesContextAdmin } from "../../contexts_admin/upoadedfiles_Context";
import { useEffect } from "react";
import { ADMIN_COMMUNITY_ID, IMAGE_URL, ROLE } from "../../../common_utils/constants";
import RotatingHeart from "../../../common_utils/loading_animator";
import StylishLabel from "../../component/StylishLabel";
import { Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Pagination } from "@mui/material";
import VerifiedSharp from '@mui/icons-material/VerifiedSharp';
import { library } from "@fortawesome/fontawesome-svg-core";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import StylishLabelOther from "../../component/StylishLabelOther";
import React from "react";


function srcset(image, width, height, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${width * cols}&h=${
        height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

function UploadedProof(){

    const {uploadedproofs_select  , list_uploadedProofs_admin , loading_uploadedFiles , total_row_count_uploadedproofs} = useUploadedFilesContextAdmin();


    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [msg , setMsg] = useState(false);
    const [id , setId] = useState(false);
    const [userId , setUserId] = useState(false);
    const [imageArray, setImageArray] = useState([{ id: 1, name: '' }]);

    useEffect(()=>{


        if(list_uploadedProofs_admin.length > 0 || loading_uploadedFiles == false){


            setLoad(true);
            setImageArray([]);
                
            for (let j = 0; j < list_uploadedProofs_admin.length; j++) {
                for (let k = 0; k < list_uploadedProofs_admin[j].length; k++) {
                  const imageUrl = list_uploadedProofs_admin[j][k];

                  console.log(imageUrl);

                  setImageArray(prevState => [
                    ...prevState,
                    { id: prevState.length + 1, name: imageUrl }
                  ]);
                }
              }

           
  
          }else{
  
  
            let communityId_admin  =  "0";
            if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
  
              communityId_admin = localStorage.getItem("communityId_admin");
              setCommunityId(communityId_admin);
  
            }else{
             communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
              setCommunityId(communityId_admin);
            }
  
            uploadedproofs_select({
            "communityId":communityId_admin,
            "offset":"0" 
         });

        }


    },[list_uploadedProofs_admin]);



    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  
  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setImageArray([]);

        localStorage.setItem("communityId_admin" , communityId_admin);
        setCommunityId(communityId_admin);

        uploadedproofs_select({
            "communityId":communityId_admin,
            "offset":"0" 
         });
      
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
    
        uploadedproofs_select({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),  
          });
  
    }





    let role = localStorage.getItem(ROLE);

    return (
      <>
      { load == false ?  <RotatingHeart/> : 
         
         <div  style={{backgroundColor:"#FAFBFD"}}>
  
          { role == "developer" ? <StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
  
          <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "50px" : "50px" , width: "100%" , paddingRight:"15px"   }}>
       
          
          <ImageList
        sx={{
          width: 1000,
          height: 650,
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: 'translateZ(0)',
        }}
        rowHeight={220}
        gap={1}
        cols={3}
        
      >
        {imageArray.map((item) => {
          const cols = item.featured ? 2 : 1;
          const rows = item.featured ? 2 : 1;
  
          return (<>
            <ImageListItem key={item.name.url} cols={cols} rows={rows} >
              <img
                {...srcset(IMAGE_URL+"uploads/"+item.name.id_proofs, 250, 200, rows, cols)}
                alt={"No Image"}
                loading="lazy"
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />


              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 60%, rgba(0,0,0,0) 100%)', 
                }}
                title={ item.name.is_id_verify == "0" ? "Click to Approve (User Identity)" : item.name.is_id_verify == "1" ? "Verified (User Identity)" :  "Rejected (User Identity)"}
                position="top"
                actionIcon={
                 
                  <IconButton
                    sx={{ color: item.name.is_id_verify == "0" ? "#d3d3d3" : item.name.is_id_verify == "1" ?  "#00cc00"  : "#e32636" }}
                    aria-label={"Click to Approve"}
                  >
                    <VerifiedSharp />
                  </IconButton>
                  
                }
                actionPosition="left"
              />
            </ImageListItem>
            <ImageListItem key={item.name.url} cols={cols} rows={rows} >
            <img
                {...srcset(IMAGE_URL+"uploads/"+item.name.education_proof, 250, 200, rows, cols)}
                alt={"No Image"}
                loading="lazy"
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />






              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 60%, rgba(0,0,0,0) 100%)', 
                }}
                title={ item.name.is_education_verify == "0" ? "Click to Approve (Education Proof)" : item.name.is_education_verify == "1" ? "Verified (Education Proof)" :  "Rejected (Education Proof)"}
                position="top"
                actionIcon={
                 
                  <IconButton
                    sx={{ color: item.name.is_education_verify == "0" ? "#d3d3d3" : item.name.is_education_verify == "1" ?  "#00cc00"  : "#e32636" }}
                    aria-label={"Click to Approve"}
                  >
                    <VerifiedSharp />
                  </IconButton>
                  
                }
                actionPosition="left"
              />
            </ImageListItem>



            <ImageListItem key={item.name.url} cols={cols} rows={rows} >
            <img
                {...srcset(IMAGE_URL+"uploads/"+item.name.income_proof, 250, 200, rows, cols)}
                alt={"No Image"}
                loading="lazy"
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 60%, rgba(0,0,0,0) 100%)', 
                }}
                title={ item.name.is_income_verify == "0" ? "Click to Approve (Income Proof)" : item.name.is_income_verify == "1" ? "Verified (Income Proof)" :  "Rejected (Income Proof)"}
                position="top"
                actionIcon={
                 
                  <IconButton
                    sx={{ color: item.name.is_income_verify == "0" ? "#d3d3d3" : item.name.is_income_verify == "1" ?  "#00cc00"  : "#e32636" }}
                    aria-label={"Click to Approve"}
                  >
                    <VerifiedSharp />
                  </IconButton>
                  
                }
                actionPosition="left"
              />
            </ImageListItem>

            </>);
        })}
      </ImageList>
    
  
  
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
              <Pagination
                  count={list_uploadedProofs_admin.length > 0 && Math.ceil(total_row_count_uploadedproofs / 10)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="secondary"
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  size="medium"
              />
          </div>
  
      </Grid>
  
  
      {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
  
     
  
      </div>
  
      }
      </>
    );



}
export default UploadedProof;
import { useTranslation } from "react-i18next";
import styles from "../../styles/contactdetail.module.css";
import axios, { Axios } from "axios";
import { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "../../styles/transitionstyle.css";
import { useState } from "react";
import { useRef } from "react";
import { COMMUNITY_ID, NAME, USER_ID } from "../../common_utils/constants";
import MsgConfirmClose from "../../app_utils/MsgConfirmClose";
import { useNotificationContext } from "../../contexts/notificationContext";
import React from "react";



function Contact_detail({ list_member_details, InsertViewContact, urlSearchParams }) {

  const { t } = useTranslation();
  const [isAnimated, setAnimated] = useState(false);
  const { allowed_features_list, allowed_list , insert_allowed_from_user , insert_allowed_list , update_allowed_from_user , update_allowed_list , insert_notificatioin_data_notification , insertNotification_memberdetails_notification , user_validation_data_list } = useNotificationContext();

  const initialized = useRef(false);
  const [msg, setMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isgrant, setgrant] = useState("-1");
  const [firsttime , setfirstTime]  = useState(false);


  useEffect(() => {


    

  if(firsttime == false){
    allowed_list({
      "from_id": localStorage.getItem(USER_ID),
      "to_id": urlSearchParams.get("userId"),
      "type": "phone",
      "communityId": "1"
    });

    setfirstTime(true);
  }

    if(allowed_features_list.length > 0){
     setgrant(allowed_features_list[0]["is_grant"]);
    }


  }, [allowed_features_list]);



  useEffect(() => {
  
    if(insert_notificatioin_data_notification.affectedRows != undefined){
   if(insert_notificatioin_data_notification.affectedRows == 1 && insert_allowed_list.affectedRows == undefined){

      insert_allowed_from_user({
        "from_id":localStorage.getItem(USER_ID),
        "to_id":urlSearchParams.get("userId"),
        "type":"phone",
        "communityId":localStorage.getItem(COMMUNITY_ID)
    });

    }
  }


   console.log("test");

  if(insert_allowed_list.affectedRows != undefined){
    if(insert_allowed_list.affectedRows == 1 &&   allowed_features_list[0] == undefined){

      allowed_list({
        "from_id": localStorage.getItem(USER_ID),
        "to_id": urlSearchParams.get("userId"),
        "type": "phone",
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });
      

    }
  }


  }, [insert_notificatioin_data_notification , insert_allowed_list]);


  const handleopenmsg = () => {
    setMsg(true);
  }

  const handleclosemsg = () => {
    setMsg(false);

    insertNotification_memberdetails_notification({
      "notifi_type": "request_call",
      "message": localStorage.getItem(NAME) + " sent you the Mobile number / call request",
      "sender_type": "user",
      "sender_id": localStorage.getItem(USER_ID),
      "reciever_id": urlSearchParams.get("userId"),
      "communityId": localStorage.getItem(COMMUNITY_ID)
    });

  }

  const handleCancel = () => {
    setMsg(false);
  }


  useEffect(() => {

    setAnimated(true);



    if (!initialized.current) {
      initialized.current = true;


      if(user_validation_data_list[0].length >  0){
      if(user_validation_data_list[1][0]["num_contact"]  <= user_validation_data_list[0][0]["number_contacts"]){

        if(list_member_details[1][0]["0"] != undefined){
      InsertViewContact({
        "fromId": localStorage.getItem(USER_ID),
        "memberId": urlSearchParams.get('userId'),
        "communityId": localStorage.getItem(COMMUNITY_ID)
      });
    }
      }
    }

    }


  }, []);

  const requestCallNumber = () => {

    setTitle("Request Call/Mobile Number");
    setContent("Are you sure u want to request call to this person?");
    setMsg(true);

  }


  return (
    <>


      <CSSTransition
        in={isAnimated}
        timeout={2000} // Duration of the transition in milliseconds
        classNames="fade"
        unmountOnExit>
          { user_validation_data_list[0].length >  0 ?

        <div>
          {user_validation_data_list[1][0]["num_contact"]  <= user_validation_data_list[0][0]["number_contacts"]  ||   list_member_details[16][0]["0"]["view_contacts"].split(",").includes(urlSearchParams.get("userId")) ?
        <div style={{ "paddingBottom": "30px" }}>
          <div className={styles.rounded_box}>
            <div className={styles.header}>{t('contact_details_label')}</div>

            {list_member_details[1][0]["0"] != undefined ? <div className={styles.contact_details_value}>
              <span className={styles.keycontact}>{t('mobile') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[14][0]["0"] == undefined ? list_member_details[1][0]["0"]["mobile_number"] :  list_member_details[14][0]["0"]["phone_privacy"] == "2" && allowed_features_list[0] == undefined ? <span onClick={requestCallNumber}>{t('request_phone_number')}</span> : isgrant == "0" ?  "Request Sent!"   : list_member_details[1][0]["0"]["mobile_number"]}</span><br />
              <span className={styles.keycontact}>{t('contact_whatsapp_l') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["whatsapp_number"]}</span><br />
              <span className={styles.keycontact}>{t('emailid') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["emailid"]}</span><br />
              <span className={styles.keycontact}>{t('alternate_mobile_l') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[14][0]["0"] == undefined ? list_member_details[1][0]["0"]["alternate_mobile"] :  list_member_details[14][0]["0"]["phone_privacy"] == "2" && allowed_features_list[0] == undefined ? <span onClick={requestCallNumber}>{t('request_phone_number')}</span> : isgrant == "0" ?  "Request Sent!" : list_member_details[1][0]["0"]["alternate_mobile"]}</span><br />
              <span className={styles.keycontact}>{t('alternate_email_l') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["alternate_email"]}</span><br />
              <span className={styles.keycontact}>{t('perm_country_label') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["perm_country"]}</span><br />
              <span className={styles.keycontact}>{t('perm_state_label') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["perm_state"]}</span><br />
              <span className={styles.keycontact}>{t('perm_city_label') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["perm_city"]}</span><br />
              <span className={styles.keycontact}>{t('perm_address') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["permanent_adddress"]}</span><br />
              <span className={styles.keycontact}>{t('work_country_label') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["work_country"]}</span><br />
              <span className={styles.keycontact}>{t('work_state_label') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["work_state"]}</span><br />
              <span className={styles.keycontact}>{t('work_city_label') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["work_city"]}</span><br />
              <span className={styles.keycontact}>{t('working_address') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["working_address"]}</span><br />
              <span className={styles.keycontact}>{t('contact_time_l_only') + ":"}</span> <span className={styles.valuecontact}>{list_member_details[1][0]["0"]["contact_time"]}</span><br />

            </div>
              :
              <div>{"Contact Details not available"}</div>

            }

          </div>



          <MsgConfirmClose
            isOpen={msg}
            handleClose={handleclosemsg}
            handleCancel={handleCancel}
            title={title}
            content={content}
            closeText={"Request Call"}
            cancelText={"Cancel"} />

        </div> :
        <div style={{fontSize:"25px" , marginTop:"200px" , fontWeight:"bold" , color:"#E3256B" , textAlign:"center"}}>{"sorry View contact limit as per your plan has reached its peak"}</div>

          }
        </div>

        : <div style={{fontSize:"25px" , marginTop:"200px" , fontWeight:"bold" , color:"#E3256B" , textAlign:"center"}}>{"Only Premium user can see contact details Please Click to upgrade"}</div>}


      </CSSTransition>



    </>
  );


}
export default Contact_detail;

const reducer = (state, action) => {
    switch (action.type) {

      case 'DASHBOARD_SUCCESS':
        return {
          ...state,
          data_dashboard_admin: action.payload,
          loading_dashboard_admin: false,
          error: null,
        };

      case 'DASHBOARD_ERROR':
        return {
          ...state,
          data_dashboard_admin: [],
          loading_dashboard_admin: false,
          error: action.payload,
        };


        case 'COMMUNITY_SUCCESS':
        return {
          ...state,
          data_community: action.payload,
          loading_community: false,
          error: null,
        };

      case 'COMMUNITY_ERROR':
        return {
          ...state,
          data_community: [],
          loading_community: false,
          error: action.payload,
        };


      default:
        return state;
    }
  };

  export default reducer;
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchContext } from "../../contexts/searchContext";
import { useNormalSearchContext } from "../../contexts/normalSearchContext";
import { COMMUNITY_ID, USER_ID } from "../../common_utils/constants";
import { Button, Grid } from "@mui/material";
import { color } from "@mui/system";
import { json, useNavigate } from "react-router-dom";





function SelectSavedSearch({passPath}){


    const { t } = useTranslation();

    const {SelectSavedSearch , data_selected_saved_search} = useNormalSearchContext();

    useEffect(()=>{
        passPath(t('saved_search'));
        
        SelectSavedSearch({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID)
        });
    
    },[]);

    const navigate = useNavigate();

    

    const handleSearch = (value) => {

        

       if(value.search_type == "normal"){

        const jsondata = JSON.parse(value.search_params);

        const jsonData  = {
           "age_from":jsondata.age_from,
           "age_to" : jsondata.age_to,
           "marital_status" : jsondata.marital_status,
           "education_list": jsondata.education_list,
           "occupation_list": jsondata.occupation_list,
           "gender":jsondata.gender,
           "caste": jsondata.caste

        }
        
        navigate('/normal_search', { state:  {jsonData }  });

       }else if(value.search_type == "mangalik"){

        const jsondata = JSON.parse(value.search_params);

        const jsonData  = {
           "age_from":jsondata.age_from,
           "age_to" : jsondata.age_to,
           "marital_status" : jsondata.marital_status,
           "education_list": jsondata.education_list,
           "occupation_list": jsondata.occupation_list,
           "gender":jsondata.gender,
           "caste": jsondata.caste

        }
        
        navigate('/mangalik_search', { state:  {jsonData }  });


       }else if(value.search_type == "handicap"){


        const jsondata = JSON.parse(value.search_params);

        const jsonData  = {
           "age_from":jsondata.age_from,
           "age_to" : jsondata.age_to,
           "marital_status" : jsondata.marital_status,
           "education_list": jsondata.education_list,
           "occupation_list": jsondata.occupation_list,
           "gender":jsondata.gender,
           "caste": jsondata.caste

        }
        
        navigate('/handicap_search', { state:  {jsonData }  });

        
       }else if(value.search_type == "iit_iim_nit"){

        const jsondata = JSON.parse(value.search_params);

        const jsonData  = {
           "age_from":jsondata.age_from,
           "age_to" : jsondata.age_to,
           "gender":jsondata.gender,
           "marital_status":jsondata.marital_status,
           "caste": jsondata.caste,
           "institute": jsondata.institute
        }
        
        navigate('/iit_iim_nit_search', { state:  {jsonData }  });
        
        
       }else if(value.search_type == "advanced_search"){

        const jsondata = JSON.parse(value.search_params);

        const jsonData  = {
           "age_from":jsondata.age_from,
           "age_to" : jsondata.age_to,
           "gender":jsondata.gender,
           "profile_id":jsondata.profile_id == "0" ? "" : jsondata.profile_id,
           "name": jsondata.name == "0" ? "" : jsondata.name,
           "fathername":jsondata.fathername == "0" ? "" : jsondata.fathername,
           "height_from":jsondata.height_from,
           "height_to":jsondata.height_to,
           "marital_status":jsondata.marital_status,
           "mother_tongue":jsondata.mother_tongue,
           "location": jsondata.location == "0" ? "" : jsondata.location,
           "education_list":jsondata.education_list,
           "occupation_list": jsondata.occupation_list,
           "rashi": jsondata.rashi,
           "birth_star": jsondata.birth_star,
           "min_income": jsondata.min_income == "0" ? "" : jsondata.min_income,
           "max_income": jsondata.max_income == "0" ? "" : jsondata.max_income,
           "mangalik": jsondata.mangalik
        }

        navigate('/advanced_search', { state:  {jsonData }  });
        
        
       }


    }

    return(<>
    
    <Grid container spacing={5} style={{ marginTop: "165px", width: "100%" , marginBottom:"70px" }}>
        <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={5}>
                    {data_selected_saved_search.length > 0 ? data_selected_saved_search.map((value, i) => (
                        <Grid key={i} item>
 
 <li key={value.search_name} style={{ margin: '16px 0', backgroundColor: '#f0f0f0', padding: '16px', borderRadius: '16px' ,border: '2px solid pink' }}>
            <h3 style={{color:"#e91e63"}}>{value.search_name}</h3>
            <p style={{color:"#000000"}}>{"Search Type : "+value.search_type}</p>
          </li>

          <Button variant="contained" color="primary" fullWidth onClick={() => handleSearch(value)}>
            Search
          </Button>


                        </Grid>
                    )) : <div className="no_data_container">
                    <p className="no_data_label">No Data Found</p>
                  </div>}

                </Grid>
        </Grid>
      </Grid>
    
    
    </>);



}

export default SelectSavedSearch;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer/registerReducer';
import { BASE_URL } from '../common_utils/constants';

const registerContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  login_status:-1,
  data_login: []
};

let API = `${BASE_URL}`;


export const MyProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const register = async (article) => {
    try {
      const response = await axios.post(`${API}`+`signup/` , article);
      

      dispatch({ type: 'REGISTER_SUCCESS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'REGISTER_ERROR', payload: error });
    }
  };


  const login = async (input) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/login/` , input);
      

      dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.success , payload1:response.data.data });
    } catch (error) {
      dispatch({ type: 'LOGIN_ERROR', payload: error });
    }
  };

  const remove = () => {
    dispatch({ type: 'REMOVE'});
  }

  
  return <registerContext.Provider value={{ ...state , dispatch , register ,login ,remove}}>{children}</registerContext.Provider>;
};

export const useMyContext = () => useContext(registerContext);

import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid , IconButton , Modal} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';






function OnBehalf(){

  const {createdBy_fetch , createdBy_insert , createdBy_update , data_insert_createdby , list_createdby_admin , data_update_createdby , delete_master_data} = useMasterContextAdmin();

  const [created_by, setCreatedBy] = useState('');
  const [createdby_update, setCreatedbyUpdate] = useState('');
  const [craetedby_id, setCreatedById] = useState('');

  const [open , setopen] = useState(false);
  const [first , setFirst] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(()=>{

   if(first == false){
    createdBy_fetch();

     setFirst(true);
   }
   

  },[list_createdby_admin , data_insert_createdby , data_update_createdby]);


  const handleCreatedbyChange = (event) => {
  setCreatedBy(event.target.value);
};


const handleCreatedbyUpdateChange = (event) => {
  setCreatedbyUpdate(event.target.value);
};


const handleAddCreatedby = () => {
  if (created_by.trim() !== '') {

   setFirst(false);
   createdBy_insert({'label':created_by})
   
  }
};


const handleUpdateCreatedby = () => {

  if (createdby_update.trim() !== '') {

    setFirst(false);
   createdBy_update({'label':createdby_update , 'value' :craetedby_id})
    setopen(false);

  }

};


const handleEditClick = (religion , Id) => {

  setFirst(false);
  setopen(true);
  setCreatedbyUpdate(religion);
  setCreatedById(Id);

}



const handleDeleteClick = (is_enabled , Id) => {
  delete_master_data({ "type":"created_by"  , "is_enabled":"1" , "Id":Id});

  setTimeout(() => {

    createdBy_fetch();
  
  }, 500);
}



const handleClose = () => {
   setopen(false);
};

let role = localStorage.getItem(ROLE);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>OnBehalf Value</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_createdby_admin.length > 0 &&  list_createdby_admin.map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.label}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.label , religion.Id)}>
        <EditIcon />
      </IconButton></TableCell>
      <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter OnBehalf Value"
        variant="outlined"
        value={created_by}
        fullWidth
        onChange={handleCreatedbyChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddCreatedby}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>


          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Created By"
            variant="outlined"
            value={createdby_update}
            fullWidth
            onChange={handleCreatedbyUpdateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateCreatedby}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>


        </div>
      );


}
export default OnBehalf;
import React, { useRef, useState, useEffect } from 'react';
import '../../styles/timelineother.css';
import { useTranslation } from 'react-i18next';
import { useMemberDetailsContext } from '../../contexts/memberdetailsContext';
import Person2Icon from '@mui/icons-material/Person2';
import PhoneAndroid from '@mui/icons-material/PhoneAndroid';
import DocumentScanner from '@mui/icons-material/DocumentScanner';
import Image from '@mui/icons-material/Image';
import Verified from '@mui/icons-material/Verified';

import { height } from '@mui/system';
import { Stack } from '@mui/material';
import UserDetail from './user_detail';
import Contact_detail from './contact_detail';
import Horoscope_details from './horoscope_detail';
import ComparisonBox from './ComparisonBox';
import PhotosVerification from './photos_verification';
import { useLocation } from 'react-router-dom';
import RotatingHeart from '../../common_utils/loading_animator';




const TimelineData2 = ({passPath}) => {


  const { t } = useTranslation();
  const { list_member_details , setContextData ,  fetchNavmsaChartHoro , InsertViewProfile ,  InsertViewContact , data_navmsa_horo , data_insert_view_profile , data_insert_view_contact 
  , insertShortlist_memberdetails ,  express_interest_insert_mmeberdetails , insert_block_user , insert_shortlist_data, 
  insert_interest_data , insert_block_user_data , insertNotification_memberdetails ,
   accept_reject_request_details ,  insert_accept_reject_list , insert_notificatioin_data , loading_own_details} = useMemberDetailsContext();

  const targetRefs = useRef(Array.from({ length: 3 }, () => React.createRef()));

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const[click , setclick] = useState(false); 
  const[click2 , setclick2] = useState(false); 
  const[click3 , setclick3] = useState(false); 
  const[click4 , setclick4] = useState(false); 
  const[click5 , setclick5] = useState(false); 
  const [load, setLoad] = useState(false);

  useEffect(()=>{

    
    setclick(true);
    setContextData(urlSearchParams.get('userId'));
    
  },[]);

  const handleclick = () => {
    setclick(true);
    setclick2(false);
    setclick3(false);
    setclick4(false);
    setclick5(false);
  }

  const handleclickcontact = () => {
    setclick(false);
    setclick2(true);
    setclick3(false);
    setclick4(false);
    setclick5(false);
  }

  const handleclickhoroscope = () => {
    setclick(false);
    setclick2(false);
    setclick3(true);
    setclick4(false);
    setclick5(false);
  }

  const handleclickphone = () => {
    setclick(false);
    setclick2(false);
    setclick3(false);
    setclick4(true);
    setclick5(false);
  }

  const handleclickmatch = () => {
    setclick(false);
    setclick2(false);
    setclick3(false);
    setclick4(false);
    setclick5(true);
  }


  const scrollToTarget1 = (index) => {

    if (targetRefs.current[index].current) {
      targetRefs.current[index].current.scrollIntoView({
        behavior: "smooth",
        block: "start",

      });
    }

  };


  const [isMobile, setIsMobile] = useState(false)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  useEffect(()=>{

    if(loading_own_details == false){
        setLoad(true);
    }
   

},[loading_own_details]);




  return (

    <React.Fragment>
      {  load == false ?  <RotatingHeart/> : 
    <div>

      <div className="breadcrumb">
        <a href="#" className="active" onClick={() => { scrollToTarget1(0) }}>Basic Details</a>
        <a href="#" onClick={() => { scrollToTarget1(1) }}>Lifestyle</a>
        <a href="#" onClick={() => { scrollToTarget1(2) }}>Physical Details</a>
        <a href="#" onClick={() => { scrollToTarget1(3) }}>Contact Details</a>
        <a href="#" onClick={() => { scrollToTarget1(4) }}>Horoscope Details</a>
        <a href="#" onClick={() => { scrollToTarget1(5) }}>Partner Details</a>

      </div>


      {
        list_member_details.length > 0 ?

          <div className="container">

            <div style={{"marginLeft":"15%" ,marginRight:"15%"}}>
            <Stack direction="row" spacing={10}>

              <div className={`click-btn btn-style1 ${click == true ? 'clicked' : ''}`} onClick={handleclick}>
                <div>
                  <div className="icon">
                    <Person2Icon style={{ width: "40px", height: "40px", color: "#3498db" }} />
                  </div>

                  <div className="label"> <p style={{ "verticalAlign": "center", "paddingTop": "-10px", "fontFamily": "Dosis" }}>User Details</p> </div>
                </div>
              </div>


              <div className={`click-btn btn-style2 ${click2 == true ? 'clicked2' : ''}`} onClick={handleclickcontact}>
                <div>
                  <div className="icon">
                    <PhoneAndroid style={{ width: "40px", height: "40px", color: "#704dee" }} />
                  </div>
                  <div className="label2"> <p style={{ "verticalAlign": "center", "paddingTop": "-10px", "fontFamily": "Dosis" }}>Contact Details</p> </div>
                </div>
              </div>


              <div className={`click-btn btn-style3 ${click3 == true ? 'clicked3' : ''}`} onClick={handleclickhoroscope}>
                <div>
                  <div className="icon">
                    <DocumentScanner style={{ width: "40px", height: "40px", color: "#ee4d4d" }} />
                  </div>
                  <div className="label3"> <p style={{ "verticalAlign": "center", "paddingTop": "-10px", "fontFamily": "Dosis" }}>Horoscope</p> </div>
                </div>
              </div>


              <div className={`click-btn btn-style4 ${click4 == true ? 'clicked4' : ''}`} onClick={handleclickphone}>
                <div>
                  <div className="icon">
                    <Image style={{ width: "40px", height: "40px", color: "#D74894" }} />
                  </div>
                  <div className="label4"> <p style={{ "verticalAlign": "center", "paddingTop": "-10px", "fontFamily": "Dosis" }}>Photos & Verification</p> </div>
                </div>
              </div>



              <div className={`click-btn btn-style5 ${click5 == true ? 'clicked5' : ''}`} onClick={handleclickmatch}>
                <div>
                  <div className="icon">
                    <Verified style={{ width: "40px", height: "40px", color: "#E3256B" }} />
                  </div>
                  <div className="label5"> <p style={{ "verticalAlign": "center", "paddingTop": "-10px", "fontFamily": "Dosis" }}>Match Details</p> </div>
                </div>
              </div>

              </Stack>


            </div>

            <div> {click == true ? <UserDetail list_member_details={list_member_details} urlSearchParams={urlSearchParams}  InsertViewProfile={InsertViewProfile} data_insert_view_profile={data_insert_view_profile}  insertShortlist_memberdetails={insertShortlist_memberdetails}   express_interest_insert_mmeberdetails={express_interest_insert_mmeberdetails}  insert_block_user={insert_block_user}  insert_shortlist_data={insert_shortlist_data}  insert_interest_data={insert_interest_data} insert_block_user_data={insert_block_user_data}  insertNotification_memberdetails={insertNotification_memberdetails}  accept_reject_request_details={accept_reject_request_details} insert_accept_reject_list={insert_accept_reject_list}/> : click2 == true ? <Contact_detail list_member_details={list_member_details} InsertViewContact={InsertViewContact} data_insert_view_contact={data_insert_view_contact} insertNotification_memberdetails={insertNotification_memberdetails} urlSearchParams={urlSearchParams} insert_notificatioin_data={insert_notificatioin_data}/> : click3 == true ? <Horoscope_details list_member_details={list_member_details}  fetchNavmsaChartHoro={fetchNavmsaChartHoro} data_navmsa_horo={data_navmsa_horo}    urlSearchParams={urlSearchParams}/> : click4 == true ?  <PhotosVerification  list_member_details={list_member_details}/>   :  <ComparisonBox  list_member_details={list_member_details}/>} </div>
           
          </div>
          :
          <div></div>
      }

    </div>
     }    
    </React.Fragment>
  );
};

export default TimelineData2;






import React from 'react';
import styles from '../styles/loading.module.css';

const RotatingHeart = () => {



  return (
    
    <div className={styles.main_container}>
    <div className={styles.image_container}>
      <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} />

     
      <h3 className={styles.animate_charcter}>Loading...</h3>
    
  
</div>
</div>

  
  );
};

export default RotatingHeart;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerPartnerprefs from '../reducer/partnerprefsReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const partnerprefsContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  data_city_multiple:[],
  partner_insert:[],
  partner_update:[]
};

let API = `${BASE_URL}`;


export const PartnerPrefsProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerPartnerprefs , initialState);

const fetchPartnerPrefsItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/partner_prefs_details_edit` , params);

      dispatch({ type: 'PARTNER_PREFS_DATA_FETCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PARTNER_PREFS_DATA_FETCH_ERROR', payload: error });
    }
  };



  const fetch_city_multiple = async (passdata) => {
    try {
     
        const response = await axios.post(`${API}`+`fetch_data/city_fetch_multiple` , passdata);
      dispatch({ type: 'CITY_FETCH_MULTIPLE', payload: response.data.data });

      
    } catch (error) {
      dispatch({ type: 'CITY_FETCH_MULTIPLE_ERROR', payload: error });
    }
  };


  const submit_partner_prefs_details = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_partner` , article);
      dispatch({ type: 'PARTNER_DETAILS_SUBMIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PARTNER_DETAILS_SUBMIT_ERROR', payload: error });
    }
  };


  const update_partner_prefs_details = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_partner` , article);
      dispatch({ type: 'PARTNER_DETAILS_UPDATE', payload: response.data });
    } catch (error) {
      dispatch({ type: 'PARTNER_DETAILS_UPDATE_ERROR', payload: error });
    }
  };





  useEffect(() => {
       
    fetchPartnerPrefsItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [ localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <partnerprefsContext.Provider value={{ ...state , dispatch , fetchPartnerPrefsItems , fetch_city_multiple , submit_partner_prefs_details ,update_partner_prefs_details}}>{children}</partnerprefsContext.Provider>;
};

export const usePartnerPrefsContext = () => useContext(partnerprefsContext);
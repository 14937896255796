


import { useEffect } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAdminsupportTicketContext } from "../../contexts_admin/supportTicket_context";
import React from "react";




function AddComment({isopen , setClose, ticket_id}){


const {loading_supportTikcet_admin , data_supportComment , SupportTicketCommentsInsert} = useAdminsupportTicketContext();



    const [open, setOpen] = useState(false);
    const [Comments, setComments] = useState("");
    const [IsSubmit, setSubmit] = useState(false);


    useEffect(()=>{

        if(data_supportComment.affectedRows >= 1 && IsSubmit == true){
           
            setClose(false);
            setOpen(false);

            alert("Submitted Successfully");

        }


    },[data_supportComment]);


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }


  const handleInputChange = (event) => {
    setComments(event.target.value);
  };



  const handleAddComments = () => {

    if(Comments.length > 2){

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString()+" "+currentDate.getHours()+":"+currentDate.getMinutes(); 

        SupportTicketCommentsInsert({
            "tickets_id":ticket_id,
             "comments": Comments,
             "comment_date":formattedDate
            })

            setSubmit(true);

    }else{

        alert("Comments Should have atleast length of 2");
    }
    


  }


    useEffect(()=>{

        setOpen(isopen);
       
    },[]);




   




    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth="true">
      <DialogTitle>Add Comments</DialogTitle>
      <DialogContent>

      
      <TextField
        id="multiline-textfield"
        label="Enter Comments"
        multiline
        style={{marginTop:"5px"}}
        value={Comments}
        onChange={handleInputChange}
        rows={4} // Specify the number of rows you want to display
        fullWidth // Optionally, make the TextField occupy the full width
        variant="outlined" 
        
        // You can use 'outlined' or 'filled' variant
      />
                
        
            </DialogContent>
            <DialogActions>
            <Button onClick={handleAddComments}>Add Comments</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default AddComment;
import { useEffect } from "react";
import { useNotificationContext } from "../../contexts/notificationContext";
import { COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Paper, TextField, Typography, imageListItemBarClasses } from "@mui/material";
import styles from '../../styles/notification_list.module.css';
import "../../styles/styles.css";
import React, { useState } from "react";



function NotificationList() {


    const duration = 1000; // ms
    const delay = 200; // ms

    const [animate, setAnimate] = useState(false);
    const [index, setIndex] = useState(-1);
    const [accept_reject, setAcceptReject] = useState(-1);
    const { notificationList, notification_list, accept_reject_notification, notification_accept_reject, accept_reject_request, accept_reject_list, insert_notification_count, insert_notification_count_list, reset,
        update_allowed_from_user, update_allowed_list , update_allowed_from_user_horo , update_allowed_list_horo } = useNotificationContext();

    const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;


    useEffect(() => {




        if (notification_list.length <= 0) {

            notificationList({
                "userId": localStorage.getItem(USER_ID),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)]
            });

        }

        if (notification_accept_reject.length > 0) {
            setOpen(true);
        }


        if (insert_notification_count_list.affectedRows == 1) {
            setOpen(false);
            window.location.reload();
        }





    }, [notification_list, notification_accept_reject, accept_reject_list, insert_notification_count_list]);

    const [open, setOpen] = useState(false);


    const handleacceptReject = (index) => {

        let type = "";
        if (notification_list[0][0][index]["notifi_type"] == "interest") {
            type = "interest";
        } else if(notification_list[0][0][index]["notifi_type"] == "request_call"){
            type = "request_call";
        }else if(notification_list[0][0][index]["notifi_type"] == "request_horoscope"){
            type = "request_horoscope";
        }

        accept_reject_notification({
            "userId": notification_list[0][0][index]["reciever_id"],
            "sender_id": notification_list[0][0][index]["sender_id"],
            "type": type,
            "communityId": localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });

        setIndex(index);
        setInputText("");

    };


    const handleClose = () => {
        setOpen(false);
    };





    const openUserDetails = () => {
        // Replace '/new-page' with the actual route you want to navigate to

        // setOpen(false);

        const newRoute = '/user_details';

        // Replace these with the actual sensitive data you want to pass
        const userId = notification_list[0][0][index]["sender_id"];
        const shortlist = notification_list[0][0][index]["notifi_type"] == "interest" ? "--" : notification_list[0][0][index]["notifi_type"] == "request_call" ? "---" : notification_list[0][0][index]["notifi_type"] == "request_horoscope" ? "----"   : "";
        const likes = "--";
        const blocked_users = "--";

        // Store the data in a location state object
        const stateData = { userId, shortlist, likes, blocked_users };


        // Construct the URL with the state data
        const urlWithState = `${newRoute}?userId=${userId}&shortlist=${shortlist}&likes=${likes}&blocks=${blocked_users}`;

        // Open the link in a new tab
        window.open(urlWithState, '_blank');

        // If opening in a new tab is successful, navigate in the current tab

    };



    const handleacceptRequest = () => {

       

        if (notification_list[0][0][index]["notifi_type"] == "interest") {

            accept_reject_request({
                "from_id": notification_list[0][0][index]["reciever_id"],
                "to_id": notification_list[0][0][index]["sender_id"],
                "comments": inputText,
                "accept": "1",
                "reject": "0"
            });

        }else if(notification_list[0][0][index]["notifi_type"] == "request_call"){

            update_allowed_from_user({
                "from_id":notification_list[0][0][index]["reciever_id"],
                "to_id":notification_list[0][0][index]["sender_id"],
                "type":"phone",
                "communityId":localStorage.getItem(COMMUNITY_ID)
            });

        }else if(notification_list[0][0][index]["notifi_type"] == "request_horoscope"){

            update_allowed_from_user_horo({
                "from_id":notification_list[0][0][index]["reciever_id"],
                "to_id":notification_list[0][0][index]["sender_id"],
                "type":"horoscope",
                "communityId":localStorage.getItem(COMMUNITY_ID)
            });

        }


        insert_notification_count({
            "notification_id": notification_list[0][0][index]["Id"],
            "user_id": notification_list[0][0][index]["reciever_id"],
            "isread": "1"
        });


        setTimeout(() => {

            setOpen(false);

        }, 300);

    }



    const handleRejectRequest = () => {

        accept_reject_request({
            "from_id": notification_list[0][0][index]["reciever_id"],
            "to_id": notification_list[0][0][index]["sender_id"],
            "comments": inputText,
            "accept": "0",
            "reject": "1"
        });


        insert_notification_count({
            "notification_id": notification_list[0][0][index]["Id"],
            "user_id": notification_list[0][0][index]["reciever_id"],
            "isread": "1"
        });

        setTimeout(() => {

            setOpen(false);

        }, 300);

    }



    const [inputText, setInputText] = useState('');

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    return (
        /* <div style={{position:"absolute" , top:"165px" , marginLeft:"10%" , marginRight:"auto"}}>
         <div className={styles.container}>
         {Object.values(notification_list[0][0]).map((item, index) => (
           <div key={index} className={styles.gridItem}>
             <h3>{item.notifi_type}</h3>
             <p>{item.message}</p>
             <Button variant="contained" color="primary">
               Click me
             </Button>
           </div>
         ))}
       </div>
       </div>*/


        <Grid container spacing={5} style={{   marginTop: "165px", width: "100%" }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    {notification_list.length > 0 ? Object.values(notification_list[0][0]).map((value, i) => (
                        <Grid key={i} item className={styles.gridItem} style={{ marginRight: "20px" }}>

                            <li className={animate ? 'fadeInListItem' : ''} key={i} style={{ animation: animStr(i), paddingRight: "20px" }}>
                                <div style={{ color: "#d74894", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", textAlign: "left" }}>{value.notifi_type}</div>
                                <div style={{ marginTop: "10px", fontWeight: "normal", fontSize: "16px", color: "#d74894", textAlign: "left" }}>{value.message}</div>
                                <Button variant="contained" color="secondary" style={{ marginTop: "10px", float: "left" }} onClick={() => handleacceptReject(i)}>
                                    {value.notifi_type == "interest" ? "Accept Or Reject the request" : value.notifi_type == "request_call" ? "Grant/Reject The Permission" :  value.notifi_type == "request_horoscope" ? "Grant/Reject The Permission " :  ""}
                                </Button>
                            </li>



                        </Grid>
                    )) : []}

                </Grid>
            </Grid>

            {notification_accept_reject.length > 0 ?
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{notification_list[0][0][index]["notifi_type"] == "interest" ? "Interest Accept/Reject" : notification_list[0][0][index]["notifi_type"] == "request_call"  ?  "Grant/Reject Permission" : ""}</DialogTitle>
                    <DialogContent>
                        <div>{"Fullname : " + notification_accept_reject[0].flat()[0]["0"]["fullname"]}</div>
                        <div>{"Age : " + notification_accept_reject[0][0]["0"]["age"]}</div>
                        <div>{"Marital Status : " + notification_accept_reject[0][0]["0"].marital_status}</div>
                        <div>{"Education Degree : " + notification_accept_reject[0][0]["0"].degree_name}</div>
                        <div>{"Occupation Name : " + notification_accept_reject[0][0]["0"].occupation_name}</div>

                        <TextField
                            label="Enter Your Comments"
                            variant="outlined"
                            value={inputText}
                            onChange={handleInputChange}
                            style={{ width: '100%', marginTop: "10px" }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleacceptRequest} color="primary">
                        { notification_list[0][0][index]["notifi_type"] == "interest" ? "Accept" : notification_list[0][0][index]["notifi_type"] == "request_call" ? "Grant Call Perrmission"  : notification_list[0][0][index]["notifi_type"] == "request_horoscope" ? "Grant Horoscope Perrmission" : ""}
                        </Button>
                       <Button onClick={handleRejectRequest} color="primary">
                            Reject
                        </Button>
                        
                        <Button onClick={openUserDetails} color="primary">
                            View Details
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            close
                        </Button>
                    </DialogActions>
                </Dialog> : ""
            }

        </Grid>

    );



}

export default NotificationList;
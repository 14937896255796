
const translations = [
  {
    value: 'Translations',
    label: 'Translations',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Hindi',
    value: 'hi',
  },
  {
    label: 'Gujarati',
    value: 'gu',
  },
  {
    label: 'Marathi',
    value: 'mr',
  },{
    label: 'Tamil',
    value: 'ta',
  },{
    label: 'Urdu',
    value: 'ur',
  },

];


const with_photo = [
  {
    value: 'With Photo',
    label: 'With Photo',
  },
  {
    value: 'Without Photo',
    label: 'Without Photo',
  }
];


const parent_stay_toption = [{"value":"1" ,"label":"I have to stay with parents after marriage"} , 
{"value":"2" ,"label":"I will not stay with parents after marriage"},
{"value":"3" ,"label":"Not decided Will see"}];


const EmploymentType = [
  {
  value: 'Private Sector',
  label: 'Private Sector',
   },
  {
    value: 'Public Sector',
    label: 'Public Sector',
  },
  {
    value: 'Self Employed',
    label: 'Self Employed',
  },
  {
    value: 'Contract Basis',
    label: 'Contract Basis',
  },
]

const created_by = [
        {
        value: '1',
        label: 'Self',
         },
        {
          value: '2',
          label: 'Father',
        },
        {
          value: '3',
          label: 'Mother',
        },
        {
          value: '4',
          label: 'Brother',
        },
        {
          value: '5',
          label: 'Sister',
        },
          {
            value: '6',
            label: 'Uncle',
          },{
            value: '7',
            label: 'Aunt',
          },{
            value: '8',
            label: 'GrandFather',
          },
          {
            value: '9',
            label: 'Friend',
          }
      ];


      const gender = [
        {
        value: 'Male',
        label: 'Male',
         },
        {
          value: 'Female',
          label: 'Female',
        }
    ];


    const rashish = [
        {
        value: '1',
        label: 'Mesha (Aries)',
         },
        {
          value: '2',
          label: 'Vrishabha (Taurus)',
        },{
            value: '3',
            label: 'Mithuna (Gemini)',
          },{
            value: '4',
            label: 'Karka (Cancer)',
          },{
            value: '5',
            label: 'Simha (Leo)',
          },{
            value: '6',
            label: 'Kanya (Virgo)',
          },{
            value: '7',
            label: 'Tula (Libra)',
          },{
            value: '8',
            label: 'Vrishchika (Scorpio)',
          },{
            value: '9',
            label: 'Dhanu (Sagittarius)',
          },{
            value: '10',
            label: 'Makara (Capricorn)',
          },{
            value: '11',
            label: 'Mesha (Aries)',
          },,{
            value: '12',
            label: 'Meena (Pisces)',
          },
    ];


    const birth_star = [
        {
        value: '1',
        label: 'Ashwini',
         },
        {
          value: '2',
          label: 'Bharani',
        },{
            value: '3',
            label: 'Krittika',
          },{
            value: '4',
            label: 'Rohini',
          },{
            value: '5',
            label: 'Mrigashira',
          },{
            value: '6',
            label: 'Ardra',
          },{
            value: '7',
            label: 'Punarvasu',
          },{
            value: '8',
            label: 'Pushya',
          },{
            value: '9',
            label: 'Ashlesha',
          },{
            value: '10',
            label: 'Magha',
          },{
            value: '11',
            label: 'Purva Phalguni',
          },{
            value: '12',
            label: 'Uttara Phalguni',
          },{
            value: '13',
            label: 'Hasta',
             },
            {
              value: '14',
              label: 'Chitra',
            },{
                value: '15',
                label: 'Swati',
              },{
                value: '16',
                label: 'Vishakha',
              },{
                value: '17',
                label: 'Anuradha',
              },{
                value: '18',
                label: 'Jyeshtha',
              },{
                value: '19',
                label: 'Mula',
              },{
                value: '20',
                label: 'Purva Ashadha',
              },{
                value: '21',
                label: 'Uttara Ashadha',
              },{
                value: '22',
                label: 'Shravana',
              },{
                value: '23',
                label: 'Shatabhisha',
              },{
                value: '24',
                label: 'Purva Bhadrapada',
              },{
                value: '25',
                label: 'Uttara Bhadrapada',
              },{
                value: '26',
                label: 'Revati',
              },
    ];




    const height = [
        {
        value: '4ft 0inch',
        label: '4ft 0inch',
         },
        {
          value: '4ft 1inch',
          label: '4ft 1inch',
        },{
            value: '4ft 2inch',
            label: '4ft 2inch',
          },{
            value: '4ft 3inch',
            label: '4ft 3inch',
          },{
            value: '4ft 4inch',
            label: '4ft 4inch',
          },{
            value: '4ft 5inch',
            label: '4ft 5inch',
          },{
            value: '4ft 6inch',
            label: '4ft 6inch',
          },{
            value: '4ft 7inch',
            label: '4ft 7inch',
          },{
            value: '4ft 8inch',
            label: '4ft 8inch',
          },{
            value: '4ft 9inch',
            label: '4ft 9inch',
          },{
            value: '4ft 10inch',
            label: '4ft 10inch',
          },{
            value: '4ft 11inch',
            label: '4ft 11inch',
          },{
            value: '5ft 0inch',
            label: '5ft 0inch',
             },
            {
              value: '5ft 1inch',
              label: '5ft 1inch',
            },{
                value: '5ft 2inch',
                label: '5ft 2inch',
              },{
                value: '5ft 3inch',
                label: '5ft 3inch',
              },{
                value: '5ft 4inch',
                label: '5ft 4inch',
              },{
                value: '5ft 5inch',
                label: '5ft 5inch',
              },{
                value: '5ft 6inch',
                label: '5ft 6inch',
              },{
                value: '5ft 7inch',
                label: '5ft 7inch',
              },{
                value: '5ft 8inch',
                label: '5ft 8inch',
              },{
                value: '5ft 9inch',
                label: '5ft 9inch',
              },{
                value: '5ft 10inch',
                label: '5ft 10inch',
              },{
                value: '5ft 11inch',
                label: '5ft 11inch',
              },{
                value: '6ft 0inch',
                label: '6ft 0inch',
              },{
                value: '6ft 1inch',
                label: '6ft 1inch',
              },{
                value: '6ft 2inch',
                label: '6ft 2inch',
              },{
                  value: '6ft 3inch',
                  label: '6ft 3inch',
                },{
                  value: '6ft 4inch',
                  label: '6ft 4inch',
                },{
                  value: '6ft 5inch',
                  label: '6ft 5inch',
                },{
                  value: '6ft 6inch',
                  label: '6ft 6inch',
                },{
                  value: '6ft 7inch',
                  label: '6ft 7inch',
                },{
                  value: '6ft 8inch',
                  label: '6ft 8inch',
                },{
                  value: '6ft 9inch',
                  label: '6ft 9inch',
                },{
                  value: '6ft 10inch',
                  label: '6ft 10inch',
                },{
                  value: '6ft 11inch',
                  label: '6ft 11inch',
                },{
                  value: '7ft 0inch',
                  label: '7ft 0inch',
                },
                {
                    value: '7ft 1inch',
                    label: '7ft 1inch',
                  },
                  {
                    value: '7ft 2inch',
                    label: '7ft 2inch',
                  }, {
                    value: '7ft 3inch',
                    label: '7ft 3inch',
                  }, {
                    value: '7ft 4inch',
                    label: '7ft 4inch',
                  }, {
                    value: '7ft 5inch',
                    label: '7ft 5inch',
                  }
    ];

    


    const birth_date = [
      {
      value: '0',
      label: 'Birth Date',
       },
      {
        value: '1',
        label: '1',
      },{
          value: '2',
          label: '2',
        },{
          value: '3',
          label: '3',
        },{
          value: '4',
          label: '4',
        },{
          value: '5',
          label: '5',
        },{
          value: '6',
          label: '6',
        },{
          value: '7',
          label: '7',
        },{
          value: '8',
          label: '8',
        },{
          value: '9',
          label: '9',
        },{
          value: '10',
          label: '10',
        },{
          value: '11',
          label: '11',
        },{
          value: '12',
          label: '12',
           },
          {
            value: '13',
            label: '13',
          },{
              value: '14',
              label: '14',
            },{
              value: '15',
              label: '15',
            },{
              value: '16',
              label: '16',
            },{
              value: '17',
              label: '17',
            },{
              value: '18',
              label: '18',
            },{
              value: '19',
              label: '19',
            },{
              value: '20',
              label: '20'
            },{
              value: '21',
              label: '21',
            },{
              value: '22',
              label: '22',
            },{
              value: '23',
              label: '23',
            },{
              value: '24',
              label: '24',
            },{
              value: '25',
              label: '25',
            },
            {
              value: '26',
              label: '26',
            },
            {
              value: '27',
              label: '27',
            },
            {
              value: '28',
              label: '28',
            },
            {
              value: '29',
              label: '29',
            },
            {
              value: '30',
              label: '30',
            },
            {
              value: '31',
              label: '31',
            },
  ];



  const month_values = [
    {
    value: '0',
    label: 'Month',
     },
    {
      value: '1',
      label: 'January',
    },{
        value: '2',
        label: 'February',
      },{
        value: '3',
        label: 'March',
      },{
        value: '4',
        label: 'April',
      },{
        value: '5',
        label: 'May',
      },{
        value: '6',
        label: 'June',
      },{
        value: '7',
        label: 'July',
      },{
        value: '8',
        label: 'August',
      },{
        value: '9',
        label: 'September',
      },{
        value: '10',
        label: 'October',
      },{
        value: '11',
        label: 'November',
      },{
        value: '12',
        label: 'December',
         },
        ]



    const blood_group = [
        {
        value: 'A+',
        label: 'A+',
         },
        {
          value: 'A-',
          label: 'A-',
        },{
            value: 'B+',
            label: 'B+',
          },{
            value: 'B-',
            label: 'B-',
             },
            {
              value: 'AB+',
              label: 'AB+',
            },{
                value: 'AB-',
                label: 'AB-',
              }, {
                value: 'O+',
                label: 'O+',
              },{
                  value: 'O-',
                  label: 'O-',
                },
                {
                    value: 'Dont Know',
                    label: 'Dont know',
                  },
  
    ];




    const year = [
      {
      value: '0',
      label: 'Birth Year',
       },
      {
        value: '1980',
        label: '1980',
      },{
          value: '1981',
          label: '1981',
        },{
          value: '1982',
          label: '1982',
        },{
          value: '1983',
          label: '1983',
        },{
          value: '1984',
          label: '1984',
        },{
          value: '1985',
          label: '1985',
        },{
          value: '1986',
          label: '1986',
        },{
          value: '1987',
          label: '1987',
        },{
          value: '1988',
          label: '1988',
        },{
          value: '1989',
          label: '1989',
        },{
          value: '1990',
          label: '1990',
        },{
          value: '1991',
          label: '1991',
        },{
          value: '1992',
          label: '1993',
           },
          {
            value: '1993',
            label: '1993',
          },{
              value: '1994',
              label: '1994',
            },{
              value: '1995',
              label: '1995',
            },{
              value: '1996',
              label: '1996',
            },{
              value: '1997',
              label: '1997',
            },{
              value: '1998',
              label: '1998',
            },{
              value: '1999',
              label: '1999',
            },{
              value: '2000',
              label: '2000'
            },{
              value: '2001',
              label: '2001',
            },{
              value: '2002',
              label: '2002',
            },{
              value: '2003',
              label: '2003',
            },{
              value: '2004',
              label: '2004',
            },{
              value: '2005',
              label: '2005',
            },
            {
              value: '2006',
              label: '2006',
            },
            {
              value: '2007',
              label: '2007',
            },
            {
              value: '2008',
              label: '2008',
            },
            {
              value: '2009',
              label: '2009',
            },
            {
              value: '2010',
              label: '2010',
            },
          
  ];


 

    const skintone = [
        {
        value: '1',
        label: 'Fair',
         },
        {
          value: '2',
          label: 'Very Fair',
        },{
            value: '3',
            label: 'Wheatish',
          },{
            value: '4',
            label: 'Wheatish Medium',
          },{
            value: '5',
            label: 'Wheatish brown',
          },{
            value: '6',
            label: 'Dark',
          }
    ];


    const family_values = [
        {
            value: '1',
            label: 'Traditional',
        },
        {
            value: '2',
            label: 'Orthodox',
        },
        {
            value: '3',
            label: 'Liberal',
        },
        {
            value: '4',
            label: 'Moderate',
        }

    ];

    const family_type = [
        {
            value: '1',
            label: 'Nuclear Family',
        },
        {
            value: '2',
            label: 'Joint Family',
        },
      
    ];


   
    




    const family_status = [
        {
            value: '1',
            label: 'Rich',
        },
        {
            value: '2',
            label: 'High class',
        },
        {
            value: '3',
            label: 'Middle Class',
        },
        {
            value: '4',
            label: 'Upper Middle class',
        },
        {
            value: '5',
            label: 'Lower Middle Class',
        },
        {
            value: '6',
            label: 'Lower class',
        },
    ];



    const languages = [
        {
            value: '1',
            label: 'Hindi',
        },
        {
            value: '2',
            label: 'Gujarati',
        },
        {
            value: '3',
            label: 'English',
        },
        {
            value: '4',
            label: 'Bengali',
        },
        {
            value: '5',
            label: 'Tamil',
        },
        {
            value: '6',
            label: 'Telugu',
        },
        {
            value: '7',
            label: 'Marathi',
        },
        {
            value: '8',
            label: 'Tamil',
        },
        {
            value: '9',
            label: 'Urdu',
        },
        {
            value: '10',
            label: 'Malayalam',
        },
        {
            value: '11',
            label: 'Kannada',
        },
        {
            value: '12',
            label: 'Odia',
        },
        {
            value: '13',
            label: 'Punjabi',
        },
        {
            value: '14',
            label: 'Assamese',
        },
        {
            value: '15',
            label: 'Maithili',
        },
        {
            value: '16',
            label: 'Kashmiri',
        },
        {
            value: '17',
            label: 'Konkani',
        },
        {
            value: '18',
            label: 'Sindhi',
        },
        {
            value: '19',
            label: 'Manipuri',
        },
        {
            value: '20',
            label: 'Bodo',
        },
        {
            value: '21',
            label: 'Santhali',
        },


    ];


    const marital_status_english = [
         
        {
            value: '1',
            label: 'Unmarried',
        },
        {
          value: '2',
          label: 'Divorced',
        },
        {
            value: '3',
            label: 'Widower',
        },
        {
            value: '4',
            label: 'Widowed',
        },

    ];

    


const annual_income_english = [
         
        {
            value: '100000-300000',
            label: '100000-300000',
        },
        {
            value: '300000-500000',
            label: '300000-500000',
        },
        {
           value: '500000-1000000',
            label: '1000000-1500000',
        },
        {
            value: '1500000-2000000',
            label: '1500000-2000000',
        },
        {
            value: '2500000-3000000',
            label: '2500000-3000000',
        },
        {
            value: '3000000-3500000',
            label: '3000000-3500000',
        },{
            value: '3500000-4000000',
            label: '3500000-4000000',
        },
         {
            value: '4000000-5000000',
            label: '4000000-5000000',
        },{
            value: '5000000-7000000',
            label: '5000000-7000000',
        },

    ];



    const residential_status = [
         
        {
            value: '1',
            label: 'Student visa',
        },
        {
            value: '2',
            label: 'Permanent Visa',
        },
        {
            value: '3',
            label: 'Temporary Visa',
        },
        {
            value: '4',
            label: 'Work Permit',
        },

    ];















const created_by_hindi = [
    {
        value: '1',
        label: 'आत्म',
    },
    {
        value: '2',
        label: 'पिता',
    },
    {
        value: '3',
        label: 'माता',
    },
    {
        value: '4',
        label: 'भाई',
    },
    {
        value: '5',
        label: 'बहन',
    },
    {
        value: '6',
        label: 'चाचा',
    },
    {
        value: '7',
        label: 'आंटी',
    },
    {
        value: '8',
        label: 'दादा',
    },
    {
        value: '9',
        label: 'दोस्त',
    }
];

const gender_hindi = [
    {
        value: '1',
        label: 'पुरुष',
    },
    {
        value: '2',
        label: 'महिला',
    },
    {
        value: '3',
        label: 'अन्य',
    }
];

const rashish_hindi = [
    {
        value: '1',
        label: 'मेष (अरीज)',
    },
    {
        value: '2',
        label: 'वृषभ (टॉरस)',
    },
    {
        value: '3',
        label: 'मिथुन (जेमिनी)',
    },
    {
        value: '4',
        label: 'कर्क (कैंसर)',
    },
    {
        value: '5',
        label: 'सिंह (लियो)',
    },
    {
        value: '6',
        label: 'कन्या (वर्ग)',
    },
    {
        value: '7',
        label: 'तुला (लीब्रा)',
    },
    {
        value: '8',
        label: 'वृश्चिक (स्कॉर्पियो)',
    },
    {
        value: '9',
        label: 'धनु (सैजिटेरियस)',
    },
    {
        value: '10',
        label: 'मकर (कैप्रिकॉर्न)',
    },
    {
        value: '11',
        label: 'कुंभ (एक्वेरियस)',
    },
    {
        value: '12',
        label: 'मीन (पिसेस)',
    }
];

const birth_star_hindi = [
    {
        value: '1',
        label: 'अश्विनी',
    },
    {
        value: '2',
        label: 'भरणी',
    },
    {
        value: '3',
        label: 'कृत्तिका',
    },
    {
        value: '4',
        label: 'रोहिणी',
    },
    {
        value: '5',
        label: 'मृगशीर्षा',
    },
    {
        value: '6',
        label: 'आर्द्रा',
    },
    {
        value: '7',
        label: 'पुनर्वसु',
    },
    {
        value: '8',
        label: 'पुष्य',
    },
    {
        value: '9',
        label: 'आश्लेषा',
    },
    {
        value: '10',
        label: 'मघा',
    },
    {
        value: '11',
        label: 'पूर्व फाल्गुनी',
    },
    {
        value: '12',
        label: 'उत्तर फाल्गुनी',
    },
    {
        value: '13',
        label: 'हस्त',
    },
    {
        value: '14',
        label: 'चित्रा',
    },
    {
        value: '15',
        label: 'स्वाति',
    },
    {
        value: '16',
        label: 'विशाखा',
    },
    {
        value: '17',
        label: 'अनुराधा',
    },
    {
        value: '18',
        label: 'ज्येष्ठा',
    },
    {
        value: '19',
        label: 'मूला',
    },
    {
        value: '20',
        label: 'पूर्व आषाढ़ा',
    },
    {
        value: '21',
        label: 'उत्तर आषाढ़ा',
    },
    {
        value: '22',
        label: 'श्रवण',
    },
    {
        value: '23',
        label: 'शतभिषा',
    },
    {
        value: '24',
        label: 'पूर्व भाद्रपद',
    },
    {
        value: '25',
        label: 'उत्तर भाद्रपद',
    },
    {
        value: '26',
        label: 'रेवती',
    }
];




const height_hindi = [
    {
        value: '4 फीट',
        label: '4 फीट',
    },
    {
        value: '4 फीट 1 इंच',
        label: '4 फीट 1 इंच',
    },
    {
        value: '4 फीट 2 इंच',
        label: '4 फीट 2 इंच',
    },
    {
        value: '4 फीट 3 इंच',
        label: '4 फीट 3 इंच',
    },
    {
        value: '4 फीट 4 इंच',
        label: '4 फीट 4 इंच',
    },
    {
        value: '4 फीट 5 इंच',
        label: '4 फीट 5 इंच',
    },
    {
        value: '4 फीट 6 इंच',
        label: '4 फीट 6 इंच',
    },
    {
        value: '4 फीट 7 इंच',
        label: '4 फीट 7 इंच',
    },
    {
        value: '4 फीट 8 इंच',
        label: '4 फीट 8 इंच',
    },
    {
        value: '4 फीट 9 इंच',
        label: '4 फीट 9 इंच',
    },
    {
        value: '4 फीट 10 इंच',
        label: '4 फीट 10 इंच',
    },
    {
        value: '4 फीट 11 इंच',
        label: '4 फीट 11 इंच',
    },
    {
        value: '5 फीट',
        label: '5 फीट',
    },
    {
        value: '5 फीट 1 इंच',
        label: '5 फीट 1 इंच',
    },
    {
        value: '5 फीट 2 इंच',
        label: '5 फीट 2 इंच',
    },
    {
        value: '5 फीट 3 इंच',
        label: '5 फीट 3 इंच',
    },
    {
        value: '5 फीट 4 इंच',
        label: '5 फीट 4 इंच',
    },
    {
        value: '5 फीट 5 इंच',
        label: '5 फीट 5 इंच',
    },
    {
        value: '5 फीट 6 इंच',
        label: '5 फीट 6 इंच',
    },
    {
        value: '5 फीट 7 इंच',
        label: '5 फीट 7 इंच',
    },
    {
        value: '5 फीट 8 इंच',
        label: '5 फीट 8 इंच',
    },
    {
        value: '5 फीट 9 इंच',
        label: '5 फीट 9 इंच',
    },
    {
        value: '5 फीट 10 इंच',
        label: '5 फीट 10 इंच',
    },
    {
        value: '5 फीट 11 इंच',
        label: '5 फीट 11 इंच',
    },
    {
        value: '6 फीट',
        label: '6 फीट',
    },
    {
        value: '6 फीट 1 इंच',
        label: '6 फीट 1 इंच',
    },
    {
        value: '6 फीट 2 इंच',
        label: '6 फीट 2 इंच',
    },
    {
        value: '6 फीट 3 इंच',
        label: '6 फीट 3 इंच',
    },
    {
        value: '6 फीट 4 इंच',
        label: '6 फीट 4 इंच',
    },
    {
        value: '6 फीट 5 इंच',
        label: '6 फीट 5 इंच',
    },
    {
        value: '6 फीट 6 इंच',
        label: '6 फीट 6 इंच',
    },
    {
        value: '6 फीट 7 इंच',
        label: '6 फीट 7 इंच',
    },
    {
        value: '6 फीट 8 इंच',
        label: '6 फीट 8 इंच',
    },
    {
        value: '6 फीट 9 इंच',
        label: '6 फीट 9 इंच',
    },
    {
        value: '6 फीट 10 इंच',
        label: '6 फीट 10 इंच',
    },
    {
        value: '6 फीट 11 इंच',
        label: '6 फीट 11 इंच',
    },
    {
        value: '7 फीट',
        label: '7 फीट',
    },
    {
        value: '7 फीट 1 इंच',
        label: '7 फीट 1 इंच',
    },
    {
        value: '7 फीट 2 इंच',
        label: '7 फीट 2 इंच',
    },
    {
        value: '7 फीट 3 इंच',
        label: '7 फीट 3 इंच',
    },
    {
        value: '7 फीट 4 इंच',
        label: '7 फीट 4 इंच',
    },
    {
        value: '7 फीट 5 इंच',
        label: '7 फीट 5 इंच',
    },
];




const skintone_hindi = [
    {
        value: 'गोरा',
        label: 'गोरा',
    },
    {
        value: 'बहुत गोरा',
        label: 'बहुत गोरा',
    },
    {
        value: 'गेहूंआ सा',
        label: 'गेहूंआ सा',
    },
    {
        value: 'गेहूंआ सा मध्यम',
        label: 'गेहूंआ सा मध्यम',
    },
    {
        value: 'गेहूंआ सा भूरा',
        label: 'गेहूंआ सा भूरा',
    },
    {
        value: 'गहरा',
        label: 'गहरा',
    },
];


const family_values_hindi = [
    {
        value: 'पारंपरिक',
        label: 'पारंपरिक',
    },
    {
        value: 'पारंपरिक',
        label: 'पारंपरिक',
    },
    {
        value: 'उदार',
        label: 'उदार',
    },
    {
        value: 'मध्यम',
        label: 'मध्यम',
    },
];


const family_type_hindi = [
    {
        value: 'न्यूक्लियर परिवार',
        label: 'न्यूक्लियर परिवार',
    },
    {
        value: 'संयुक्त परिवार',
        label: 'संयुक्त परिवार',
    },
];


const family_status_hindi = [
    {
        value: 'धनी',
        label: 'धनी',
    },
    {
        value: 'उच्च वर्ग',
        label: 'उच्च वर्ग',
    },
    {
        value: 'मध्यम वर्ग',
        label: 'मध्यम वर्ग',
    },
    {
        value: 'ऊपरी मध्यम वर्ग',
        label: 'ऊपरी मध्यम वर्ग',
    },
    {
        value: 'निचला मध्यम वर्ग',
        label: 'निचला मध्यम वर्ग',
    },
    {
        value: 'निचला वर्ग',
        label: 'निचला वर्ग',
    },
];


const languages_hindi = [
    {
        value: 'हिन्दी',
        label: 'हिन्दी',
    },
    {
        value: 'गुजराती',
        label: 'गुजराती',
    },
    {
        value: 'अंग्रेज़ी',
        label: 'अंग्रेज़ी',
    },
    {
        value: 'बंगाली',
        label: 'बंगाली',
    },
    {
        value: 'तमिल',
        label: 'तमिल',
    },
    {
        value: 'तेलुगु',
        label: 'तेलुगु',
    },
    {
        value: 'मराठी',
        label: 'मराठी',
    },
    {
        value: 'तमिल',
        label: 'तमिल',
    },
    {
        value: 'उर्दू',
        label: 'उर्दू',
    },
    {
        value: 'मलयालम',
        label: 'मलयालम',
    },
    {
        value: 'कन्नड़',
        label: 'कन्नड़',
    },
    {
        value: 'ओडिया',
        label: 'ओडिया',
    },
    {
        value: 'पंजाबी',
        label: 'पंजाबी',
    },
    {
        value: 'असमिया',
        label: 'असमिया',
    },
    {
        value: 'मैथिली',
        label: 'मैथिली',
    },
    {
        value: 'कश्मीरी',
        label: 'कश्मीरी',
    },
    {
        value: 'कोंकणी',
        label: 'कोंकणी',
    },
    {
        value: 'सिंधी',
        label: 'सिंधी',
    },
    {
        value: 'मणिपुरी',
        label: 'मणिपुरी',
    },
    {
        value: 'बोडो',
        label: 'बोडो',
    },
    {
        value: 'संथाली',
        label: 'संथाली',
    },
];


const marital_status_hindi = [
    {
        value: 'अविवाहित',
        label: 'अविवाहित',
    },
    {
        value: 'विवाहित',
        label: 'विवाहित',
    },
    {
        value: 'विधवा',
        label: 'विधवा',
    },
    {
        value: 'विधुर',
        label: 'विधुर',
    },
];

      

const residential_status_hindi = [
    {
        value: 'छात्र वीजा',
        label: 'छात्र वीजा',
    },
    {
        value: 'स्थायी वीजा',
        label: 'स्थायी वीजा',
    },
    {
        value: 'अस्थायी वीजा',
        label: 'अस्थायी वीजा',
    },
    {
        value: 'काम परमिट',
        label: 'काम परमिट',
    },
];













const created_by_guj = [
    {
        value: 'સ્વયં',
        label: 'સ્વયં',
    },
    {
        value: 'પિતા',
        label: 'પિતા',
    },
    {
        value: 'માતા',
        label: 'માતા',
    },
    {
        value: 'ભાઈ',
        label: 'ભાઈ',
    },
    {
        value: 'બહેન',
        label: 'બહેન',
    },
    {
        value: 'કાકા',
        label: 'કાકા',
    },
    {
        value: 'ફુઆ',
        label: 'ફુઆ',
    },
    {
        value: 'દાદા',
        label: 'દાદા',
    },
    {
        value: 'મિત્ર',
        label: 'મિત્ર',
    },
];


const gender_guj = [
    {
        value: 'પુરુષ',
        label: 'પુરુષ',
    },
    {
        value: 'સ્ત્રી',
        label: 'સ્ત્રી',
    },
    {
        value: 'અન્ય',
        label: 'અન્ય',
    },
];


const rashish_guj = [
    {
        value: 'મેષ (અરીએસ)',
        label: 'મેષ (અરીએસ)',
    },
    {
        value: 'વૃષાભ (ટોરસ)',
        label: 'વૃષાભ (ટોરસ)',
    },
    {
        value: 'મિથુન (જેમિની)',
        label: 'મિથુન (જેમિની)',
    },
    {
        value: 'કર્ક (કેન્સર)',
        label: 'કર્ક (કેન્સર)',
    },
    {
        value: 'સિંહ (લિઓ)',
        label: 'સિંહ (લિઓ)',
    },
    {
        value: 'કન્યા (વર્ગો)',
        label: 'કન્યા (વર્ગો)',
    },
    {
        value: 'તુલા (લિબ્રા)',
        label: 'તુલા (લિબ્રા)',
    },
    {
        value: 'વૃશ્ચિક (સ્કોર્પિયો)',
        label: 'વૃશ્ચિક (સ્કોર્પિયો)',
    },
    {
        value: 'ધનુ (સગિટેરિયસ)',
        label: 'ધનુ (સગિટેરિયસ)',
    },
    {
        value: 'મકર (કેપ્રિકોર્ન)',
        label: 'મકર (કેપ્રિકોર્ન)',
    },
    {
        value: 'કુંભ (એક્વેરિયસ)',
        label: 'કુંભ (એક્વેરિયસ)',
    },
    {
        value: 'મીન (પિશીસ)',
        label: 'મીન (પિશીસ)',
    },
];




const birth_star_guj = [
    {
        value: 'અશ્વિની',
        label: 'અશ્વિની',
    },
    {
        value: 'ભરણી',
        label: 'ભરણી',
    },
    {
        value: 'કૃતિકા',
        label: 'કૃતિકા',
    },
    {
        value: 'રોહિણી',
        label: 'રોહિણી',
    },
    {
        value: 'મૃગશિરા',
        label: 'મૃગશિરા',
    },
    {
        value: 'આર્દ્રા',
        label: 'આર્દ્રા',
    },
    {
        value: 'પુનર્વસુ',
        label: 'પુનર્વસુ',
    },
    {
        value: 'પુષ્ય',
        label: 'પુષ્ય',
    },
    {
        value: 'અશ્લેષા',
        label: 'અશ્લેષા',
    },
    {
        value: 'મઘા',
        label: 'મઘા',
    },
    {
        value: 'પૂર્વા ફાલ્ગુની',
        label: 'પૂર્વા ફાલ્ગુની',
    },
    {
        value: 'ઉત્તરા ફાલ્ગુની',
        label: 'ઉત્તરા ફાલ્ગુની',
    },
    {
        value: 'હસ્ત',
        label: 'હસ્ત',
    },
    {
        value: 'ચિત્રા',
        label: 'ચિત્રા',
    },
    {
        value: 'સ્વાતી',
        label: 'સ્વાતી',
    },
    {
        value: 'વિશાખા',
        label: 'વિશાખા',
    },
    {
        value: 'અનુરાધા',
        label: 'અનુરાધા',
    },
    {
        value: 'જ્યેષ્ઠા',
        label: 'જ્યેષ્ઠા',
    },
    {
        value: 'મૂલા',
        label: 'મૂલા',
    },
    {
        value: 'પૂર્વા આષાઢા',
        label: 'પૂર્વા આષાઢા',
    },
    {
        value: 'ઉત્તરા આષાઢા',
        label: 'ઉત્તરા આષાઢા',
    },
    {
        value: 'શ્રાવણ',
        label: 'શ્રાવણ',
    },
    {
        value: 'શતભિષા',
        label: 'શતભિષા',
    },
    {
        value: 'પૂર્વા ભાદ્રપદ',
        label: 'પૂર્વા ભાદ્રપદ',
    },
    {
        value: 'ઉત્તરા ભાદ્રપદ',
        label: 'ઉત્તરા ભાદ્રપદ',
    },
    {
        value: 'રેવતી',
        label: 'રેવતી',
    },
];




const skintone_guj = [
    {
        value: 'ગોરુ',
        label: 'ગોરુ',
    },
    {
        value: 'ખૂબ ગોરુ',
        label: 'ખૂબ ગોરુ',
    },
    {
        value: 'ગમોંદગુળું',
        label: 'ગમોંદગુળું',
    },
    {
        value: 'ગમોંદગુળું મધ્યમ',
        label: 'ગમોંદગુળું મધ્યમ',
    },
    {
        value: 'ગમોંદગુળું ભૂરું',
        label: 'ગમોંદગુળું ભૂરું',
    },
    {
        value: 'ઘણું',
        label: 'ઘણું',
    },
];



const family_values_guj = [
    {
        value: 'પરંપરાગત',
        label: 'પરંપરાગત',
    },
    {
        value: 'ઓર્થોડોક્સ',
        label: 'ઓર્થોડોક્સ',
    },
    {
        value: 'ઉદાર',
        label: 'ઉદાર',
    },
    {
        value: 'મધ્યમ',
        label: 'મધ્યમ',
    },
];


const family_type_guj = [
    {
        value: 'ન્યુક્લિયર પરિવાર',
        label: 'ન્યુક્લિયર પરિવાર',
    },
    {
        value: 'સંયુક્ત પરિવાર',
        label: 'સંયુક્ત પરિવાર',
    },
];


const family_status_guj = [
    {
        value: 'ધનવાન',
        label: 'ધનવાન',
    },
    {
        value: 'ઉચ્ચ વર્ગ',
        label: 'ઉચ્ચ વર્ગ',
    },
    {
        value: 'મધ્યમ વર્ગ',
        label: 'મધ્યમ વર્ગ',
    },
    {
        value: 'ઉચ્ચ મધ્યમ વર્ગ',
        label: 'ઉચ્ચ મધ્યમ વર્ગ',
    },
    {
        value: 'નિમ્ન મધ્યમ વર્ગ',
        label: 'નિમ્ન મધ્યમ વર્ગ',
    },
    {
        value: 'નિમ્ન વર્ગ',
        label: 'નિમ્ન વર્ગ',
    },
];




const languages_guj = [
    {
        value: 'હિન્દી',
        label: 'હિન્દી',
    },
    {
        value: 'ગુજરાતી',
        label: 'ગુજરાતી',
    },
    {
        value: 'અંગ્રેજી',
        label: 'અંગ્રેજી',
    },
    {
        value: 'બંગાળી',
        label: 'બંગાળી',
    },
    {
        value: 'તમિલ',
        label: 'તમિલ',
    },
    {
        value: 'તેલુગુ',
        label: 'તેલુગુ',
    },
    {
        value: 'મરાઠી',
        label: 'મરાઠી',
    },
    {
        value: 'તમિલ',
        label: 'તમિલ',
    },
    {
        value: 'ઉર્દુ',
        label: 'ઉર્દુ',
    },
    {
        value: 'મલયાલમ',
        label: 'મલયાલમ',
    },
    {
        value: 'કન્નડ',
        label: 'કન્નડ',
    },
    {
        value: 'ઓડિયા',
        label: 'ઓડિયા',
    },
    {
        value: 'પંજાબી',
        label: 'પંજાબી',
    },
    {
        value: 'અસામીસ',
        label: 'અસામીસ',
    },
    {
        value: 'મૈથિલી',
        label: 'મૈથિલી',
    },
    {
        value: 'કાશ્મીરી',
        label: 'કાશ્મીરી',
    },
    {
        value: 'કોંકણી',
        label: 'કોંકણી',
    },
    {
        value: 'સિંધી',
        label: 'સિંધી',
    },
    {
        value: 'મણિપુરી',
        label: 'મણિપુરી',
    },
    {
        value: 'બોડો',
        label: 'બોડો',
    },
    {
        value: 'સાંથાલી',
        label: 'સાંથાલી',
    },
];



const marital_status_guj = [
    {
        value: 'અવિવાહિત',
        label: 'અવિવાહિત',
    },
    {
        value: 'વિવાહિત',
        label: 'વિવાહિત',
    },
    {
        value: 'વિધવા',
        label: 'વિધવા',
    },
    {
        value: 'વિધુર',
        label: 'વિધુર',
    },
];


const residential_status_guj = [
    {
        value: 'વિદ્યાર્થી વીઝા',
        label: 'વિદ્યાર્થી વીઝા',
    },
    {
        value: 'સ્થાયી વીઝા',
        label: 'સ્થાયી વીઝા',
    },
    {
        value: 'અસ્થાયી વીઝા',
        label: 'અસ્થાયી વીઝા',
    },
    {
        value: 'કામ પરમિટ',
        label: 'કામ પરમિટ',
    },
];













const created_by_marathi = [
  {
    value: 'स्वतंत्र',
    label: 'स्वतंत्र',
  },
  {
    value: 'वडिल',
    label: 'वडिल',
  },
  {
    value: 'आई',
    label: 'आई',
  },
  {
    value: 'भाऊ',
    label: 'भाऊ',
  },
  {
    value: 'बहिण',
    label: 'बहिण',
  },
  {
    value: 'काका',
    label: 'काका',
  },
  {
    value: 'आंटी',
    label: 'आंटी',
  },
  {
    value: 'आजोबा',
    label: 'आजोबा',
  },
  {
    value: 'मित्र',
    label: 'मित्र',
  }
];

const gender_marathi = [
  {
    value: 'पुरुष',
    label: 'पुरुष',
  },
  {
    value: 'स्त्री',
    label: 'स्त्री',
  },
  {
    value: 'इतर',
    label: 'इतर',
  }
];

const rashish_marathi = [
  {
    value: 'मेष (वृष)',
    label: 'मेष (वृष)',
  },
  {
    value: 'वृषभ (वृष)',
    label: 'वृषभ (वृष)',
  },
  {
    value: 'मिथुन (मिथुन)',
    label: 'मिथुन (मिथुन)',
  },
  {
    value: 'कर्क (कर्कट)',
    label: 'कर्क (कर्कट)',
  },
  {
    value: 'सिंह (सिंह)',
    label: 'सिंह (सिंह)',
  },
  {
    value: 'कन्या (कन्या)',
    label: 'कन्या (कन्या)',
  },
  {
    value: 'तुला (तुळा)',
    label: 'तुला (तुळा)',
  },
  {
    value: 'वृश्चिक (वृश्चिक)',
    label: 'वृश्चिक (वृश्चिक)',
  },
  {
    value: 'धनु (धनु)',
    label: 'धनु (धनु)',
  },
  {
    value: 'मकर (मकर)',
    label: 'मकर (मकर)',
  },
  {
    value: 'कुंभ (कुंभ)',
    label: 'कुंभ (कुंभ)',
  },
  {
    value: 'मीन (मीन)',
    label: 'मीन (मीन)',
  }
];

const birth_star_marathi = [
  {
    value: 'अश्विनी',
    label: 'अश्विनी',
  },
  {
    value: 'भरणी',
    label: 'भरणी',
  },
  {
    value: 'कृत्तिका',
    label: 'कृत्तिका',
  },
  {
    value: 'रोहिणी',
    label: 'रोहिणी',
  },
  {
    value: 'मृगशीर्षा',
    label: 'मृगशीर्षा',
  },
  {
    value: 'आर्द्रा',
    label: 'आर्द्रा',
  },
  {
    value: 'पुनर्वसु',
    label: 'पुनर्वसु',
  },
  {
    value: 'पुष्य',
    label: 'पुष्य',
  },
  {
    value: 'आश्लेषा',
    label: 'आश्लेषा',
  },
  {
    value: 'मघा',
    label: 'मघा',
  },
  {
    value: 'पूर्व फाल्गुनी',
    label: 'पूर्व फाल्गुनी',
  },
  {
    value: 'उत्तर फाल्गुनी',
    label: 'उत्तर फाल्गुनी',
  },
  {
    value: 'हस्त',
    label: 'हस्त',
  },
  {
    value: 'चित्रा',
    label: 'चित्रा',
  },
  {
    value: 'स्वाती',
    label: 'स्वाती',
  },
  {
    value: 'विशाखा',
    label: 'विशाखा',
  },
  {
    value: 'अनुराधा',
    label: 'अनुराधा',
  },
  {
    value: 'ज्येष्ठा',
    label: 'ज्येष्ठा',
  },
  {
    value: 'मूल',
    label: 'मूल',
  },
  {
    value: 'पूर्व आषाढा',
    label: 'पूर्व आषाढा',
  },
  {
    value: 'उत्तर आषाढा',
    label: 'उत्तर आषाढा',
  },
  {
    value: 'श्रवण',
    label: 'श्रवण',
  },
  {
    value: 'शततारा',
    label: 'शततारा',
  },
  {
    value: 'पूर्व भाद्रपद',
    label: 'पूर्व भाद्रपद',
  },
  {
    value: 'उत्तर भाद्रपद',
    label: 'उत्तर भाद्रपद',
  },
  {
    value: 'रेवती',
    label: 'रेवती',
  },
];





const skintone_marathi = [
  {
    value: 'गोरा',
    label: 'गोरा',
  },
  {
    value: 'वाहतुक',
    label: 'वाहतुक',
  },
  {
    value: 'गहूळ',
    label: 'गहूळ',
  },
  {
    value: 'गहूळ मध्यम',
    label: 'गहूळ मध्यम',
  },
  {
    value: 'गहूळ ब्राउन',
    label: 'गहूळ ब्राउन',
  },
  {
    value: 'काळा',
    label: 'काळा',
  }
];

const family_values_marathi = [
  {
    value: 'परंपरागत',
    label: 'परंपरागत',
  },
  {
    value: 'अर्थोडॉक्स',
    label: 'अर्थोडॉक्स',
  },
  {
    value: 'उदार',
    label: 'उदार',
  },
  {
    value: 'मध्यम',
    label: 'मध्यम',
  }
];

const family_type_marathi = [
  {
    value: 'न्यूक्लियर कुटुंब',
    label: 'न्यूक्लियर कुटुंब',
  },
  {
    value: 'संयुक्त कुटुंब',
    label: 'संयुक्त कुटुंब',
  },
];

const family_status_marathi = [
  {
    value: 'श्रीमंत',
    label: 'श्रीमंत',
  },
  {
    value: 'उच्च वर्ग',
    label: 'उच्च वर्ग',
  },
  {
    value: 'मध्यम वर्ग',
    label: 'मध्यम वर्ग',
  },
  {
    value: 'अपर मध्यम वर्ग',
    label: 'अपर मध्यम वर्ग',
  },
  {
    value: 'कमी मध्यम वर्ग',
    label: 'कमी मध्यम वर्ग',
  },
  {
    value: 'कमी वर्ग',
    label: 'कमी वर्ग',
  },
];

const languages_marathi = [
  {
    value: 'हिंदी',
    label: 'हिंदी',
  },
  {
    value: 'गुजराती',
    label: 'गुजराती',
  },
  {
    value: 'इंग्रजी',
    label: 'इंग्रजी',
  },
  {
    value: 'बंगाली',
    label: 'बंगाली',
  },
  {
    value: 'तामिळ',
    label: 'तामिळ',
  },
  {
    value: 'तेलगु',
    label: 'तेलगु',
  },
  {
    value: 'मराठी',
    label: 'मराठी',
  },
  {
    value: 'तामिळ',
    label: 'तामिळ',
  },
  {
    value: 'उर्दू',
    label: 'उर्दू',
  },
  {
    value: 'मलयालम',
    label: 'मलयालम',
  },
  {
    value: 'कन्नड़',
    label: 'कन्नड़',
  },
  {
    value: 'ओडिया',
    label: 'ओडिया',
  },
  {
    value: 'पंजाबी',
    label: 'पंजाबी',
  },
  {
    value: 'असमीज',
    label: 'असमीज',
  },
  {
    value: 'मैथिली',
    label: 'मैथिली',
  },
  {
    value: 'कश्मीरी',
    label: 'कश्मीरी',
  },
  {
    value: 'कोंकणी',
    label: 'कोंकणी',
  },
  {
    value: 'सिंधी',
    label: 'सिंधी',
  },
  {
    value: 'मणिपुरी',
    label: 'मणिपुरी',
  },
  {
    value: 'बोडो',
    label: 'बोडो',
  },
  {
    value: 'संथाली',
    label: 'संथाली',
  },
];

const marital_status_marathi = [
  {
    value: 'अविवाहित',
    label: 'अविवाहित',
  },
  {
    value: 'विवाहित',
    label: 'विवाहित',
  },
  {
    value: 'विधवा',
    label: 'विधवा',
  },
  {
    value: 'विधुर',
    label: 'विधुर',
  },
];

const annual_income_marathi = [
  {
    value: '100000-300000',
    label: '100000-300000',
  },
  {
    value: '300000-500000',
    label: '300000-500000',
  },
  {
    value: '500000-1000000',
    label: '1000000-1500000',
  },
  {
    value: '1500000-2000000',
    label: '1500000-2000000',
  },
  {
    value: '2500000-3000000',
    label: '2500000-3000000',
  },
  {
    value: '3000000-3500000',
    label: '3000000-3500000',
  },
  {
    value: '3500000-4000000',
    label: '3500000-4000000',
  },
  {
    value: '4000000-5000000',
    label: '4000000-5000000',
  },
  {
    value: '5000000-7000000',
    label: '5000000-7000000',
  },
];

const residential_status_marathi = [
  {
    value: 'विद्यार्थी वीजा',
    label: 'विद्यार्थी वीजा',
  },
  {
    value: 'स्थायी वीजा',
    label: 'स्थायी वीजा',
  },
  {
    value: 'अस्थायी वीजा',
    label: 'अस्थायी वीजा',
  },
  {
    value: 'काम परमिट',
    label: 'काम परमिट',
  },
];










const created_by_tamil = [
  {
    value: 'சுயநானா',
    label: 'சுயநானா',
  },
  {
    value: 'அப்பா',
    label: 'அப்பா',
  },
  {
    value: 'அம்மா',
    label: 'அம்மா',
  },
  {
    value: 'சகோதரன்',
    label: 'சகோதரன்',
  },
  {
    value: 'சகோதரி',
    label: 'சகோதரி',
  },
  {
    value: 'மாமா',
    label: 'மாமா',
  },
  {
    value: 'அந்தை',
    label: 'அந்தை',
  },
  {
    value: 'அந்தாள்',
    label: 'அந்தாள்',
  },
  {
    value: 'நண்பர்',
    label: 'நண்பர்',
  },
];

const gender_tamil = [
  {
    value: 'ஆண்',
    label: 'ஆண்',
  },
  {
    value: 'பெண்',
    label: 'பெண்',
  },
  {
    value: 'மற்றவை',
    label: 'மற்றவை',
  },
];

const rashish_tamil = [
  {
    value: 'மேஷம் (ரீஸ்)',
    label: 'மேஷம் (ரீஸ்)',
  },
  {
    value: 'ரிஷபம் (டாரஸ்)',
    label: 'ரிஷபம் (டாரஸ்)',
  },
  {
    value: 'மிதுனம் (ஜெமினி)',
    label: 'மிதுனம் (ஜெமினி)',
  },
  {
    value: 'கடகம் (கேன்சர்)',
    label: 'கடகம் (கேன்சர்)',
  },
  {
    value: 'சிம்மம் (லியோ)',
    label: 'சிம்மம் (லியோ)',
  },
  {
    value: 'கன்யா (விருகம்)',
    label: 'கன்யா (விருகம்)',
  },
  {
    value: 'துலாம் (லிப்ரா)',
    label: 'துலாம் (லிப்ரா)',
  },
  {
    value: 'விருச்சிகம் (ஸ்கார்பியோ)',
    label: 'விருச்சிகம் (ஸ்கார்பியோ)',
  },
  {
    value: 'தனுசு (சட்டியாரி)',
    label: 'தனுசு (சட்டியாரி)',
  },
  {
    value: 'மகரம் (கேப்ரிகான்)',
    label: 'மகரம் (கேப்ரிகான்)',
  },
  {
    value: 'கும்பம் (ஆகுவேரியஸ்)',
    label: 'கும்பம் (ஆகுவேரியஸ்)',
  },
  {
    value: 'மீனம் (பைஸிஸ்)',
    label: 'மீனம் (பைஸிஸ்)',
  },
];

const birth_star_tamil = [
  {
    value: 'அசுவினி',
    label: 'அசுவினி',
  },
  {
    value: 'பரணி',
    label: 'பரணி',
  },
  {
    value: 'கிருத்திகை',
    label: 'கிருத்திகை',
  },
  {
    value: 'ரோகிணி',
    label: 'ரோகிணி',
  },
  {
    value: 'மிருகசிரம்',
    label: 'மிருகசிரம்',
  },
  {
    value: 'ஆர்த்ரா',
    label: 'ஆர்த்ரா',
  },
  {
    value: 'புனர்பூசம்',
    label: 'புனர்பூசம்',
  },
  {
    value: 'பூசம்',
    label: 'பூசம்',
  },
  {
    value: 'ஆயில்யம்',
    label: 'ஆயில்யம்',
  },
  {
    value: 'மகம்',
    label: 'மகம்',
  },
  {
    value: 'பூரம்',
    label: 'பூரம்',
  },
  {
    value: 'உத்திரம்',
    label: 'உத்திரம்',
  },
  {
    value: 'ஹஸ்தம்',
    label: 'ஹஸ்தம்',
  },
  {
    value: 'சித்திரை',
    label: 'சித்திரை',
  },
  {
    value: 'ஸ்வாதி',
    label: 'ஸ்வாதி',
  },
  {
    value: 'விசாகம்',
    label: 'விசாகம்',
  },
  {
    value: 'அனுஷம்',
    label: 'அனுஷம்',
  },
  {
    value: 'ஜேஷ்டம்',
    label: 'ஜேஷ்டம்',
  },
  {
    value: 'மூலம்',
    label: 'மூலம்',
  },
  {
    value: 'பூராடம்',
    label: 'பூராடம்',
  },
  {
    value: 'உத்திராடம்',
    label: 'உத்திராடம்',
  },
  {
    value: 'திருவோணம்',
    label: 'திருவோணம்',
  },
  {
    value: 'ஷடபிஷம்',
    label: 'ஷடபிஷம்',
  },
  {
    value: 'பூர்வாஷாடம்',
    label: 'பூர்வாஷாடம்',
  },
  {
    value: 'உத்திராஷாடம்',
    label: 'உத்திராஷாடம்',
  },
  {
    value: 'ஶ்ரவணம்',
    label: 'ஶ்ரவணம்',
  },
  {
    value: 'ஷடபிஷம்',
    label: 'ஷடபிஷம்',
  },
  {
    value: 'பூர்வாபாதம்',
    label: 'பூர்வாபாதம்',
  },
  {
    value: 'உத்திராபாதம்',
    label: 'உத்திராபாதம்',
  },
  {
    value: 'ரேவதி',
    label: 'ரேவதி',
  },
];

// Note: Some values in "rashish" array have been corrected to match the proper Tamil names.





const skintone_tamil = [
  {
    value: 'எளிதான',
    label: 'எளிதான',
  },
  {
    value: 'மிகவும் எளிதான',
    label: 'மிகவும் எளிதான',
  },
  {
    value: 'கம்பளி',
    label: 'கம்பளி',
  },
  {
    value: 'கம்பளி நடுநிலை',
    label: 'கம்பளி நடுநிலை',
  },
  {
    value: 'கம்பளி மண்',
    label: 'கம்பளி மண்',
  },
  {
    value: 'கருப்பு',
    label: 'கருப்பு',
  },
];

const family_values_tamil = [
  {
    value: 'பாரம்பரிய',
    label: 'பாரம்பரிய',
  },
  {
    value: 'பரம்பராக்க',
    label: 'பரம்பராக்க',
  },
  {
    value: 'குறிப்பாக்க',
    label: 'குறிப்பாக்க',
  },
  {
    value: 'மிதமான',
    label: 'மிதமான',
  },
];

const family_type_tamil = [
  {
    value: 'குடும்பம்',
    label: 'குடும்பம்',
  },
  {
    value: 'இருக்கை குடும்பம்',
    label: 'இருக்கை குடும்பம்',
  },
];

const family_status_tamil = [
  {
    value: 'சிறந்த',
    label: 'சிறந்த',
  },
  {
    value: 'உயர் வர்க்கம்',
    label: 'உயர் வர்க்கம்',
  },
  {
    value: 'நடு வர்க்கம்',
    label: 'நடு வர்க்கம்',
  },
  {
    value: 'உயர் நடு வர்க்கம்',
    label: 'உயர் நடு வர்க்கம்',
  },
  {
    value: 'கீழ் நடு வர்க்கம்',
    label: 'கீழ் நடு வர்க்கம்',
  },
  {
    value: 'கீழ் வர்க்கம்',
    label: 'கீழ் வர்க்கம்',
  },
];

const languages_tamil = [
  {
    value: 'ஹிந்தி',
    label: 'ஹிந்தி',
  },
  {
    value: 'குஜராத்தி',
    label: 'குஜராத்தி',
  },
  {
    value: 'ஆங்கிலம்',
    label: 'ஆங்கிலம்',
  },
  {
    value: 'பெங்காளி',
    label: 'பெங்காளி',
  },
  {
    value: 'தமிழ்',
    label: 'தமிழ்',
  },
  {
    value: 'தெலுங்கு',
    label: 'தெலுங்கு',
  },
  {
    value: 'மராத்தி',
    label: 'மராத்தி',
  },
  {
    value: 'உருது',
    label: 'உருது',
  },
  {
    value: 'மலையாளம்',
    label: 'மலையாளம்',
  },
  {
    value: 'கன்னடம்',
    label: 'கன்னடம்',
  },
  {
    value: 'ஒரியா',
    label: 'ஒரியா',
  },
  {
    value: 'பஞ்சாபி',
    label: 'பஞ்சாபி',
  },
  {
    value: 'அசாமி',
    label: 'அசாமி',
  },
  {
    value: 'மைதிலி',
    label: 'மைதிலி',
  },
  {
    value: 'காஷ்மீரி',
    label: 'காஷ்மீரி',
  },
  {
    value: 'கொங்கணி',
    label: 'கொங்கணி',
  },
  {
    value: 'சிந்தி',
    label: 'சிந்தி',
  },
  {
    value: 'மணிப்பூரி',
    label: 'மணிப்பூரி',
  },
];

const marital_status_tamil = [
  {
    value: 'புருஷனாக',
    label: 'புருஷனாக',
  },
  {
    value: 'பெண்',
    label: 'பெண்',
  },
  {
    value: 'மறுமுன் பரிசுக்காரன்',
    label: 'மறுமுன் பரிசுக்காரன்',
  },
  {
    value: 'மறுமுன் பரிசுக்காரி',
    label: 'மறுமுன் பரிசுக்காரி',
  },
];

const annual_income = [
  {
    value: '100000-300000',
    label: '100000-300000',
  },
  {
    value: '300000-500000',
    label: '300000-500000',
  },
  {
    value: '500000-1000000',
    label: '1000000-1500000',
  },
  {
    value: '1500000-2000000',
    label: '1500000-2000000',
  },
  {
    value: '2500000-3000000',
    label: '2500000-3000000',
  },
  {
    value: '3000000-3500000',
    label: '3000000-3500000',
  },
  {
    value: '3500000-4000000',
    label: '3500000-4000000',
  },
  {
    value: '4000000-5000000',
    label: '4000000-5000000',
  },
  {
    value: '5000000-7000000',
    label: '5000000-7000000',
  },
];

const residential_status_tamil = [
  {
    value: 'பள்ளிக்காரர் விசா',
    label: 'பள்ளிக்காரர் விசா',
  },
  {
    value: 'நிரந்தர விசா',
    label: 'நிரந்தர விசா',
  },
  {
    value: 'தாமதம் விசா',
    label: 'தாமதம் விசா',
  },
  {
    value: 'வேலை அனுமதி',
    label: 'வேலை அனுமதி',
  },
];












const created_by_urdu = [
  {
    value: 'Self',
    label: 'خود',
  },
  {
    value: 'Father',
    label: 'والد',
  },
  {
    value: 'Mother',
    label: 'والدہ',
  },
  {
    value: 'Brother',
    label: 'بھائی',
  },
  {
    value: 'Sister',
    label: 'بہن',
  },
  {
    value: 'Uncle',
    label: 'چچا/ماموں',
  },
  {
    value: 'Aunt',
    label: 'خالہ/ماماں',
  },
  {
    value: 'GrandFather',
    label: 'دادا/نانا',
  },
  {
    value: 'Friend',
    label: 'دوست',
  }
];

const gender_urdu = [
  {
    value: 'Male',
    label: 'مرد',
  },
  {
    value: 'Female',
    label: 'عورت',
  },
  {
    value: 'Other',
    label: 'دیگر',
  }
];






const rashish_urdu = [
  {
    value: 'Mesha (Aries)',
    label: 'میشا (حمل)',
  },
  {
    value: 'Vrishabha (Taurus)',
    label: 'ورشبھ (ثور)',
  },
  {
    value: 'Mithuna (Gemini)',
    label: 'مثلث (جوزا)',
  },
  {
    value: 'Karka (Cancer)',
    label: 'کرک (سرطان)',
  },
  {
    value: 'Simha (Leo)',
    label: 'سنگل (اسد)',
  },
  {
    value: 'Kanya (Virgo)',
    label: 'کنیا (سنبلہ)',
  },
  {
    value: 'Tula (Libra)',
    label: 'تولہ (میزان)',
  },
  {
    value: 'Vrishchika (Scorpio)',
    label: 'ورشک (عقرب)',
  },
  {
    value: 'Dhanu (Sagittarius)',
    label: 'دھنو (قوس)',
  },
  {
    value: 'Makara (Capricorn)',
    label: 'مکر (جدی)',
  },
  {
    value: 'Kumbha (Aquarius)',
    label: 'کمبہ (دلو)',
  },
  {
    value: 'Meena (Pisces)',
    label: 'مینا (حوت)',
  },
];

const birth_star_urdu = [
  {
    value: 'Ashwini',
    label: 'اشوینی',
  },
  {
    value: 'Bharani',
    label: 'بھرنی',
  },
  {
    value: 'Krittika',
    label: 'کرتیکا',
  },
  {
    value: 'Rohini',
    label: 'روہنی',
  },
  {
    value: 'Mrigashira',
    label: 'مریگشرا',
  },
  {
    value: 'Ardra',
    label: 'آردرا',
  },
  {
    value: 'Punarvasu',
    label: 'پنڈراوسو',
  },
  {
    value: 'Pushya',
    label: 'پوشیا',
  },
  {
    value: 'Ashlesha',
    label: 'آشلیشا',
  },
  {
    value: 'Magha',
    label: 'مغا',
  },
  {
    value: 'Purva Phalguni',
    label: 'پوروا فالگونی',
  },
  {
    value: 'Uttara Phalguni',
    label: 'اُتّرا فالگونی',
  },
  {
    value: 'Hasta',
    label: 'ہستا',
  },
  {
    value: 'Chitra',
    label: 'چترا',
  },
  {
    value: 'Swati',
    label: 'سواتی',
  },
  {
    value: 'Vishakha',
    label: 'وشاکھا',
  },
  {
    value: 'Anuradha',
    label: 'انورادھا',
  },
  {
    value: 'Jyeshtha',
    label: 'جیشتھا',
  },
  {
    value: 'Mula',
    label: 'مولا',
  },
  {
    value: 'Purva Ashadha',
    label: 'پوروا اشادھا',
  },
  {
    value: 'Uttara Ashadha',
    label: 'اُتّرا اشادھا',
  },
  {
    value: 'Shravana',
    label: 'شراونا',
  },
  {
    value: 'Shatabhisha',
    label: 'شتابھیشا',
  },
  {
    value: 'Purva Bhadrapada',
    label: 'پوروا بھادرپادا',
  },
  {
    value: 'Uttara Bhadrapada',
    label: 'اُتّرا بھادرپادا',
  },
  {
    value: 'Revati',
    label: 'ریواتی',
  },
];



const languages_urdu = [
  {
    value: 'Hindi',
    label: 'ہندی',
  },
  {
    value: 'Gujarati',
    label: 'گجراتی',
  },
  {
    value: 'English',
    label: 'انگریزی',
  },
  {
    value: 'Bengali',
    label: 'بنگالی',
  },
  {
    value: 'Tamil',
    label: 'تامل',
  },
  {
    value: 'Telugu',
    label: 'تیلگو',
  },
  {
    value: 'Marathi',
    label: 'مراٹھی',
  },
  {
    value: 'Urdu',
    label: 'اردو',
  },
  {
    value: 'Malayalam',
    label: 'ملیالم',
  },
  {
    value: 'Kannada',
    label: 'کنڈہ',
  },
  {
    value: 'Odia',
    label: 'اوڑیا',
  },
  {
    value: 'Punjabi',
    label: 'پنجابی',
  },
  {
    value: 'Assamese',
    label: 'آسامی',
  },
  {
    value: 'Maithili',
    label: 'میتھلی',
  },
  {
    value: 'Kashmiri',
    label: 'کشمیری',
  },
  {
    value: 'Konkani',
    label: 'کونکانی',
  },
  {
    value: 'Sindhi',
    label: 'سندھی',
  },
  {
    value: 'Manipuri',
    label: 'منیپوری',
  },
  {
    value: 'Bodo',
    label: 'بوڈو',
  },
  {
    value: 'Santhali',
    label: 'سانتالی',
  },
];





const skintone_urdu = [
  {
    value: 'Fair',
    label: 'گورا',
  },
  {
    value: 'Very Fair',
    label: 'بہت گورا',
  },
  {
    value: 'Wheatish',
    label: 'گہرے گیہوا رنگ کا',
  },
  {
    value: 'Wheatish Medium',
    label: 'میڈیم گیہوا رنگ کا',
  },
  {
    value: 'Wheatish brown',
    label: 'گہرا بھورا رنگ کا',
  },
  {
    value: 'Dark',
    label: 'سیاہ',
  },
];

const family_values_urdu = [
  {
    value: 'Traditional',
    label: 'روایتی',
  },
  {
    value: 'Orthodox',
    label: 'اورتھوڈاکس',
  },
  {
    value: 'Liberal',
    label: 'لبرل',
  },
  {
    value: 'Moderate',
    label: 'معتدل',
  },
];

const family_type_urdu = [
  {
    value: 'Nuclear Family',
    label: 'نیوکلیئر فیملی',
  },
  {
    value: 'Joint Family',
    label: 'جوائنٹ فیملی',
  },
];

const family_status_urdu = [
  {
    value: 'Rich',
    label: 'امیر',
  },
  {
    value: 'High class',
    label: 'اعلیٰ طبقہ',
  },
  {
    value: 'Middle Class',
    label: 'مڈل کلاس',
  },
  {
    value: 'Upper Middle class',
    label: 'اپر مڈل کلاس',
  },
  {
    value: 'Lower Middle Class',
    label: 'لوئر مڈل کلاس',
  },
  {
    value: 'Lower class',
    label: 'لوئر کلاس',
  },
];



const marital_status_urdu = [
  {
    value: 'Unmarried',
    label: 'غیر شادی شدہ',
  },
  {
    value: 'Married',
    label: 'شادی شدہ',
  },
  {
    value: 'Widower',
    label: 'بیوہ کا شوہر',
  },
  {
    value: 'Widowed',
    label: 'بیوہ',
  },
];



const residential_status_urdu = [
  {
    value: 'Student visa',
    label: 'طلباء ویزا',
  },
  {
    value: 'Permanent Visa',
    label: 'مستقر ویزا',
  },
  {
    value: 'Temporary Visa',
    label: 'زمانہ ویزا',
  },
  {
    value: 'Work Permit',
    label: 'کام پرمٹ',
  },
];





export{

  created_by,
  created_by_guj,
  created_by_hindi,
  created_by_marathi,
  created_by_tamil,
  created_by_urdu,
  



  birth_date,
  month_values,
  year,
  gender,
  translations,
  marital_status_english,
  marital_status_guj,
  marital_status_hindi,
  marital_status_tamil,
  marital_status_marathi,
  marital_status_urdu,
  
  family_status,
  family_status_hindi,
  family_status_marathi,
  family_status_tamil,
  family_status_urdu,

  birth_star,
  birth_star_guj,
  birth_star_hindi,
  birth_star_marathi,
  birth_star_tamil,
  birth_star_urdu,
  family_status_guj,
  family_type,
  family_type_marathi,
  family_type_hindi,
  family_type_tamil,
  family_type_urdu,
  family_type_guj,

  family_values,
  family_values_hindi,
  family_values_marathi,
  family_values_tamil,
  family_values_urdu,
  family_values_guj,


  rashish,
 
  rashish_hindi,
  rashish_tamil,
  rashish_urdu,
  rashish_guj,

  residential_status,
  residential_status_hindi,
  residential_status_marathi,
  residential_status_tamil,
  residential_status_urdu,
  residential_status_guj,

  skintone,
  skintone_hindi,
  skintone_guj,
  skintone_marathi,
  skintone_tamil,
  skintone_urdu,

  languages,
  languages_guj,
  languages_hindi,
  languages_marathi,
  languages_tamil,
  languages_urdu,

  annual_income_english,

  height,
  EmploymentType,
  with_photo

}


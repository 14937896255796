import React, { useState } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import { useAboutusContext } from '../../contexts/aboutusContext';
import { useEffect } from 'react';
import { COMMUNITY_ID, IMAGE_URL, TRANSLATED_LANG } from '../../common_utils/constants';
import { useTranslation } from 'react-i18next';




const ChiefMembersPage = ({passPath}) => {

  const { t } = useTranslation();
    const{ fetchChiefMembers , chief_members} = useAboutusContext();
   const[ chiefmember , setChiefMembers] = useState([]);

   useEffect(()=>{

    passPath(t('chief_members'));

   },[]);

   useEffect(()=>{

    if(chief_members.length <= 0){
        fetchChiefMembers({
        "type":"chief_members",   //about , contact , chief_members , privacy_policy , refund_policy , faqs
        "communityId": localStorage.getItem(COMMUNITY_ID),
         "original": "en",
         "translate": [localStorage.getItem(TRANSLATED_LANG)]
       });
    }else{

       

    }

   },[chief_members]);


  const gridContainerStyle = {
    marginTop: '2rem', // Adjust the top margin as needed
  };

  const gridItemStyle = {
    padding: '1.5rem',
  };

  return (
    <>{ chief_members.length > 0 ? 
    <div style={{position: "absolute", top: "165px" , width:"100%"}}>
    <Container maxWidth="lg" style={gridContainerStyle}>
      <Grid container spacing={3}>
        {Object.values(chief_members[0][0]).map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} style={gridItemStyle}>
              <img src={IMAGE_URL+"/uploads/"+member.image} alt={member.name} style={{ width: '100%', height: '300px', marginBottom: '1rem' }} />
              <Typography variant="h6" style={{ color: '#E3256B', marginBottom: '0.5rem' }}>
                {member.name}
              </Typography>
              <Typography variant="subtitle1" style={{ color: '#D74894', marginBottom: '0.5rem' }}>
                {member.position}
              </Typography>
              <Typography variant="body2" style={{ lineHeight: 1.6 }}>
                {member.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
    </div>
    :""
}
    </>
  );
};

export default ChiefMembersPage;

import React, { useEffect, useState } from "react";
import { useSearchContext } from "../../contexts/searchContext";
import { useLocation } from "react-router-dom";
import { AGE_FROM_P_VAL, AGE_P, AGE_TO_P_VAL, ANNUAL_INCOME_P, ANNUAL_INCOME_P_VAL, BODY_TYPE_P, BODY_TYPE_P_VAL, CITY_P, CITY_P_VAL, COMMUNITY_ID, DIET_TYPE_P, DIET_TYPE_P_VAL, DRINK_TYPE_P, DRINK_TYPE_P_VAL, EDUCATION_P, EDUCATION_P_VAL, GENDER, HEIGHT_FROM_P_VAL, HEIGHT_P, HEIGHT_TO_P_VAL, LIMIT, MARITAL_STATUS_P, MARITAL_STATUS_P_VAL, OCCUPATION_P, OCCUPATION_P_VAL, SKINTONE_P, SKINTONE_P_VAL, SMOKE_TYPE_P, SMOKE_TYPE_P_VAL, STATE_P, STATE_P_VAL, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { Grid, IconButton, Pagination, Tooltip } from "@mui/material";
import "../../styles/styles.css";
import MatriProfileCard from "../search_result/MatriProfileCard";
import { useNotificationContext } from "../../contexts/notificationContext";
import { marathiToEnglishNumber } from "../../common_utils/utils";
import { useTranslation } from "react-i18next";
import styles from '../../styles/loading.module.css';
import "../../styles/no_data_found.css";
import RotatingHeart from "../../common_utils/loading_animator";
import InfoIcon from '@mui/icons-material/Info';
import MsgConfirm from "../../app_utils/MsgConfirm";
import Footer from "../../components/Footer";
import { useRef } from "react";



function useWindowDimensions() {
    const [dimensions, setDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return dimensions;
  }


const MatchResult = ({passPath }) => {

    const duration = 1000; // ms
    const delay = 200; // ms

    const { width, height } = useWindowDimensions();

    const { MATCH_RESULT, matches , loading_matches } = useSearchContext();
    const {user_validation_data }  = useNotificationContext();
    const { state } = useLocation();
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [load, setLoad] = useState(false);

    const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;
    const download = process.env.PUBLIC_URL + '/download.jpg';

    const [msg, setMsg] = useState(false);
    const [title, setTitle] = useState("Match Alert!");
    const [content, setContent] = useState("Please Adjust Partner Preference and Partner Preference filter to show your results , if you are not getting results from your current partner preference");

    const handleclosemsg = (event) => {

        setMsg(false);
      }



    useEffect(() => {
        // alert(localStorage.getItem(HEIGHT_FROM_P_VAL)+"--"+localStorage.getItem(HEIGHT_TO_P_VAL)+"--"+localStorage.getItem(HEIGHT_P));

        passPath(t('latest_matches'));
        setAnimate(true);


        user_validation_data({
            "userId": localStorage.getItem(USER_ID)
        });

        MATCH_RESULT(
            {
                "userId": localStorage.getItem(USER_ID),
                "age_from": localStorage.getItem(AGE_FROM_P_VAL),
                "age_to": localStorage.getItem(AGE_TO_P_VAL),
                "height_from": localStorage.getItem(HEIGHT_FROM_P_VAL),
                "height_to": localStorage.getItem(HEIGHT_TO_P_VAL),
                "marital_status": localStorage.getItem(MARITAL_STATUS_P_VAL),
                "perm_state": localStorage.getItem(STATE_P_VAL),
                "perm_city": localStorage.getItem(CITY_P_VAL),
                "education_list": localStorage.getItem(EDUCATION_P_VAL),
                "occupation_list": localStorage.getItem(OCCUPATION_P_VAL),
                "income_min": localStorage.getItem(ANNUAL_INCOME_P_VAL) != null ?  localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[0] : "",
                "income_max": localStorage.getItem(ANNUAL_INCOME_P_VAL) != null ? localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[1] : "",
                "complexion": localStorage.getItem(SKINTONE_P_VAL),
                "body_type": localStorage.getItem(BODY_TYPE_P_VAL),
                "diet_type": localStorage.getItem(DIET_TYPE_P_VAL),
                "drink_type": localStorage.getItem(DRINK_TYPE_P_VAL),
                "smoke_type": localStorage.getItem(SMOKE_TYPE_P_VAL),
                "age_filter": localStorage.getItem(AGE_P),
                "height_filter": localStorage.getItem(HEIGHT_P),
                "marital_filter": localStorage.getItem(MARITAL_STATUS_P),
                "state_filter": localStorage.getItem(STATE_P),
                "city_filter": localStorage.getItem(CITY_P),
                "edu_filter": localStorage.getItem(EDUCATION_P),
                "occup_filter": localStorage.getItem(OCCUPATION_P),
                "income_filter": localStorage.getItem(ANNUAL_INCOME_P),
                "complexion_filter": localStorage.getItem(SKINTONE_P),
                "body_type_filter": localStorage.getItem(BODY_TYPE_P),
                "eat_type_filter": localStorage.getItem(DIET_TYPE_P),
                "drink_type_filter": localStorage.getItem(DRINK_TYPE_P),
                "smoke_type_filter": localStorage.getItem(SMOKE_TYPE_P),
                "Id": "1",
                "gender": localStorage.getItem(GENDER),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset": 0
            }
        );

        setTimeout(() => {
            setAnimate(false);
        }, 2000)

    }, []);


    


    const handlePageChange = (event, value) => {
        setAnimate(true);
        setCurrentPage(value);



        MATCH_RESULT(
            {
                "userId": localStorage.getItem(USER_ID),
                "age_from": localStorage.getItem(AGE_FROM_P_VAL),
                "age_to": localStorage.getItem(AGE_TO_P_VAL),
                "height_from": localStorage.getItem(HEIGHT_FROM_P_VAL),
                "height_to": localStorage.getItem(HEIGHT_TO_P_VAL),
                "marital_status": localStorage.getItem(MARITAL_STATUS_P_VAL),
                "perm_state": localStorage.getItem(STATE_P_VAL),
                "perm_city": localStorage.getItem(CITY_P_VAL),
                "education_list": localStorage.getItem(EDUCATION_P_VAL),
                "occupation_list": localStorage.getItem(OCCUPATION_P_VAL),
                "income_min": localStorage.getItem(ANNUAL_INCOME_P_VAL) != null ?  localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[0] : "",
                "income_max": localStorage.getItem(ANNUAL_INCOME_P_VAL) != null ? localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[1] : "",
                "complexion": localStorage.getItem(SKINTONE_P_VAL),
                "body_type": localStorage.getItem(BODY_TYPE_P_VAL),
                "diet_type": localStorage.getItem(DIET_TYPE_P_VAL),
                "drink_type": localStorage.getItem(DRINK_TYPE_P_VAL),
                "smoke_type": localStorage.getItem(SMOKE_TYPE_P_VAL),
                "age_filter": localStorage.getItem(AGE_P),
                "height_filter": localStorage.getItem(HEIGHT_P),
                "marital_filter": localStorage.getItem(MARITAL_STATUS_P),
                "state_filter": localStorage.getItem(STATE_P),
                "city_filter": localStorage.getItem(CITY_P),
                "edu_filter": localStorage.getItem(EDUCATION_P),
                "occup_filter": localStorage.getItem(OCCUPATION_P),
                "income_filter": localStorage.getItem(ANNUAL_INCOME_P),
                "complexion_filter": localStorage.getItem(SKINTONE_P),
                "body_type_filter": localStorage.getItem(BODY_TYPE_P),
                "eat_type_filter": localStorage.getItem(DIET_TYPE_P),
                "drink_type_filter": localStorage.getItem(DRINK_TYPE_P),
                "smoke_type_filter": localStorage.getItem(SMOKE_TYPE_P),
                "Id": localStorage.getItem(USER_ID),
                "gender": localStorage.getItem(GENDER),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit":LIMIT,
                "offset": value == 1 ? 0 : (((value - 2) * LIMIT) + LIMIT)
            }
        );

        scrollToTop();


    }

    const refresh = () => {

        user_validation_data({
            "userId": localStorage.getItem(USER_ID)
        });

        MATCH_RESULT(
            {
                "userId": localStorage.getItem(USER_ID),
                "age_from": localStorage.getItem(AGE_FROM_P_VAL),
                "age_to": localStorage.getItem(AGE_TO_P_VAL),
                "height_from": localStorage.getItem(HEIGHT_FROM_P_VAL),
                "height_to": localStorage.getItem(HEIGHT_TO_P_VAL),
                "marital_status": localStorage.getItem(MARITAL_STATUS_P_VAL),
                "perm_state": localStorage.getItem(STATE_P_VAL),
                "perm_city": localStorage.getItem(CITY_P_VAL),
                "education_list": localStorage.getItem(EDUCATION_P_VAL),
                "occupation_list": localStorage.getItem(OCCUPATION_P_VAL),
                "income_min": localStorage.getItem(ANNUAL_INCOME_P_VAL) != null ?  localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[0] : "",
                "income_max": localStorage.getItem(ANNUAL_INCOME_P_VAL) != null ? localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[1] : "",
                "complexion": localStorage.getItem(SKINTONE_P_VAL),
                "body_type": localStorage.getItem(BODY_TYPE_P_VAL),
                "diet_type": localStorage.getItem(DIET_TYPE_P_VAL),
                "drink_type": localStorage.getItem(DRINK_TYPE_P_VAL),
                "smoke_type": localStorage.getItem(SMOKE_TYPE_P_VAL),
                "age_filter": localStorage.getItem(AGE_P),
                "height_filter": localStorage.getItem(HEIGHT_P),
                "marital_filter": localStorage.getItem(MARITAL_STATUS_P),
                "state_filter": localStorage.getItem(STATE_P),
                "city_filter": localStorage.getItem(CITY_P),
                "edu_filter": localStorage.getItem(EDUCATION_P),
                "occup_filter": localStorage.getItem(OCCUPATION_P),
                "income_filter": localStorage.getItem(ANNUAL_INCOME_P),
                "complexion_filter": localStorage.getItem(SKINTONE_P),
                "body_type_filter": localStorage.getItem(BODY_TYPE_P),
                "eat_type_filter": localStorage.getItem(DIET_TYPE_P),
                "drink_type_filter": localStorage.getItem(DRINK_TYPE_P),
                "smoke_type_filter": localStorage.getItem(SMOKE_TYPE_P),
                "Id": localStorage.getItem(USER_ID),
                "gender": localStorage.getItem(GENDER),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)],
                "limit": LIMIT,
                "offset": currentPage == 1 ? 0 : (((currentPage - 2) * LIMIT) + LIMIT)
            }
        );

    }


    useEffect(()=>{

        if(matches.length > 0){
        if(matches[1][0]["0"]["total_row_count"] == 0){
                 setMsg(true);
            }
        }


    },[matches]);

    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scroll events
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setIsVisible(scrollTop > 300); // Adjust the scroll threshold as needed
    };

    // Attach scroll event listener on mount
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Remove scroll event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can also use 'auto' for instant scrolling
        });
    };


    const cardStyle = {
        opacity: 0,
        animation: 'fadeIn 0.5s ease-out forwards',
    };


    useEffect(()=>{

        if(loading_matches == false){
            setLoad(true);

        }
       

    },[loading_matches]);

    const  custom_tooltip = {
        fontSize : "20px" /* Adjust the font size as needed */
      }


      //const {width, height} = useWindowDimensions();
      useEffect(()=>{
      
        //passHeight(height+400);
      
      } , []);
  
    


    return (
        
        <React.Fragment>

        <div>
        {  load == false ?  <RotatingHeart/> : 
        <Grid  container rowSpacing={5} columnSpacing={3} style={{ marginLeft: "auto", marginRight: "auto", marginTop: "165px",  width: "100%", position: "relative" , backgroundColor:"#F5F5F5"  }}>

<img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position: "absolute", top: "50px", right: "0", zIndex: "2", width: "120px", height: "120px"}}/>
  <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position: "absolute", bottom: "80px", left: "40px", zIndex: "2", width: "120px", height: "120px"}}/>
  <img src="/images/flower_matri.png" alt="Rotating Image" style={{position: "absolute", height: "100vh", width: "50%", left: "25%", opacity: "0.25", zIndex: "0"}}/>

             <Tooltip 
      title="This functionality shows you the matches of the profiles whose profile details match your partner preference and you can filter the profiles based on the partner preference you set."
      arrow 
      placement="right" 
      enterDelay={500} 
      disableFocusListener
      style={{ position:"absolute" ,top:"100px" , left:"30px"}}
      classes={{ tooltip: 'custom-tooltip' }}
    >
      <IconButton style={{width:"40px" , height:"40px"}}>
        <InfoIcon style={{width:"40px" , height:"40px"}}/>
      </IconButton>
    </Tooltip>


            <Grid item xs={12} >
                <Grid container justifyContent="center" spacing={5}>
                    {matches.length > 0 && (localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(matches[1][0]["0"]["total_row_count"]) > 0 :  matches[1][0]["0"]["total_row_count"] > 0) ? Object.values(matches[0][0]).map((value, i) => (
                        <Grid key={i} item>
 
                            <li className={animate ? 'fadeInListItem' : ''} key={i} style={{ animation: animStr(i) }}>
                                <MatriProfileCard value={value} refresh={refresh} />
                            </li>



                        </Grid>
                    )) : <div className="no_data_container">
                    <p className="no_data_label">No Data Found</p>
                  </div>}

                </Grid>
            </Grid>



            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" ,zIndex:"30" }}>
                <Pagination
                    count={matches.length > 0 ? Math.ceil(localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(matches[1][0]["0"]["total_row_count"])/8 :  matches[1][0]["0"]["total_row_count"] / 8) : 0}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="large"
                />
            </div>

            <MsgConfirm
          isOpen={msg}
          handleClose={handleclosemsg}
          title={title}
          content={content}
          closeText={"Ok Understood"}
        />


      


        </Grid>

      

      }


</div>

        </React.Fragment>
    );




};

export default MatchResult;
const reducerProofs = (state, action) => {
    switch (action.type) {


      case 'PROOFS_FETCH':
        return {
          ...state,
          data_proofs_list: action.payload,
          loading: false,
          error: null,
        };
      case 'PROOFS_FETCH_ERROR':
        return {
          ...state,
          data_proofs_list: [],
          loading: false,
          error: action.payload,
        };


        case 'INSERT_PROOFS':
      return {
        ...state,
        insert_proofs_list: action.payload,
        loading: false,
        error: null,
      };
    case 'INSERT_PROOFS_ERROR':
      return {
        ...state,
        insert_proofs_list: [],
        loading: false,
        error: action.payload,
      };



      case 'UPDATE_PROOFS':
        return {
          ...state,
          update_proofs_list: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_PROOFS_ERROR':
        return {
          ...state,
          update_proofs_list: [],
          loading: false,
          error: action.payload,
        };


        
      
       
      default:
        return state;
    }
  };

  export default reducerProofs;
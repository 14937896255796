import { Card, CardMedia, Grid, Typography } from "@mui/material";
import React  from "react";



const Banner = ({item }) => {

    let items = [];
  

     
    
  

        const media = (
            <Grid item  key={item.Id}>
                <CardMedia
                    className="Media"
                    image={item.photos}
                    title={item.slider_text}>
                </CardMedia>
                <Typography className="MediaCaption">
                       { <div style={{width:"100%"}} dangerouslySetInnerHTML={{__html:item.slider_text}} /> }
                    </Typography>
            </Grid>
        )
    
        items.push(media);
        

  
    return (
      <Card raised className="Banner">
          <Grid container spacing={0} className="BannerGrid">
              {items}
          </Grid>
      </Card>
  )
  
  };

  export default Banner;
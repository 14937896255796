import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerHoroscope from '../../reducer_admin/horoscopeReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const horoscopeAdminContext = createContext();

const initialState = {
  data_horoscope_data_admin: [],
  loading: true,
  error: null,
  data_horoscope_admin: [],
  data_navmsa_admin: [],
  horoscope_insert_admin:[],
  horoscope_update_admin:[]
};

let API = `${BASE_URL}`;


export const HoroscopAdminProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerHoroscope , initialState);

const fetchHoroscopeItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/horoscope_details_edit` , params);

      console.log(response.data.data[1][0].Id);
      

      dispatch({ type: 'HOROSCOPE_DETAILS_EDIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HOROSCOPE_DETAILS_EDIT_ERROR_ADMIN', payload: error });
    }
  };



  const fetchHoroscopeChartAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/horoscope_chart` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'HOROSCOPE_CHART_VIEW_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HOROSCOPE_CHART_VIEW_ERROR_ADMIN', payload: error });
    }
  };



  const fetchNavmsaChartAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/navmsa_chart` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'NAVMSA_CHART_VIEW_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'NAVMSA_CHART_VIEW_ERROR_ADMIN', payload: error });
    }
  };

  
  const submit_horoscopedetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_horoscope` , article);
      dispatch({ type: 'INSERT_HOROSCOPE_DETAILS_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_HOROSCOPE_DETAILS_ERROR_ADMIN', payload: error });
    }
  };


  const update_horoscopedetailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_horoscope` , article);
      dispatch({ type: 'UPDATE_HOROSCOPE_DETAILS_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_HOROSCOPE_DETAILS_ERROR_ADMIN', payload: error });
    }
  };


  




 
  
  return <horoscopeAdminContext.Provider value={{ ...state , dispatch , fetchHoroscopeItemsAdmin , fetchHoroscopeChartAdmin , fetchNavmsaChartAdmin , submit_horoscopedetailsAdmin ,update_horoscopedetailsAdmin}}>{children}</horoscopeAdminContext.Provider>;
};

export const useHoroscopeAdminContext = () => useContext(horoscopeAdminContext);
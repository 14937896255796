import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';


import { Secondcolor } from '../../common_utils/colors';
import ImageGalleryWithCrop from './ImageGalleryWithCrop';
import { Dialog, DialogActions, DialogContent, DialogTitle , Button } from '@mui/material';
import { useRef } from 'react';
import { useBasicContext } from '../../contexts/basicdetailsContext';
import { IMAGE_URL } from '../../common_utils/constants';


const FileUpload = () => {


  const{picList} = useBasicContext();

  const [image, setImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 4 / 3 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [uploadFiles,  setuploadFiles] = useState("0");
  const cropperRef = useRef(null);


  useEffect(()=>{

    if(picList.length > 0){

     if(picList[0].flat()[0].pic1 != null){  downloadFile(picList[0].flat()[0].pic1 , 0); }   
     if(picList[0].flat()[0].pic2 != null){  downloadFile(picList[0].flat()[0].pic2 , 1); }
     if(picList[0].flat()[0].pic3 != null){  downloadFile(picList[0].flat()[0].pic3 , 2); }
     if(picList[0].flat()[0].pic4 != null){  downloadFile(picList[0].flat()[0].pic4 , 3); }
     if(picList[0].flat()[0].pic5 != null){  downloadFile(picList[0].flat()[0].pic5 , 4); }
     if(picList[0].flat()[0].pic6 != null){  downloadFile(picList[0].flat()[0].pic6 , 5); }
     if(picList[0].flat()[0].pic7 != null){  downloadFile(picList[0].flat()[0].pic7 , 6); }
     if(picList[0].flat()[0].pic8 != null){  downloadFile(picList[0].flat()[0].pic8 , 7); }

    }

  },[picList]);


  
  const downloadFile = async (element , index) => {

    try {
      const response = await fetch(IMAGE_URL+"/uploads/"+element);
      const blob = await response.blob();
      const file = new File([blob], element);

      //setFiles(prevFiles => [...prevFiles, file]);

      setFiles((prevFiles) =>{ let files = [...prevFiles]; files[index] = file;  return files});
      
    } catch (error) {
      console.error('Error fetching or creating file:', error);
    }finally{

      
       
    }

  };



  const onDrop = useCallback((acceptedFiles) => {
    // Assuming there is an array of existing files in state called 'files'
    
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);

  }, [setFiles]);



  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });



  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const onChange = (cropper) => {
		console.log(cropper.getCoordinates(), cropper.getCanvas());
	};


  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop, pixelCrop) => {
    setCompletedCrop(pixelCrop);
  };
  

  const parentFunction = () => {
    setuploadFiles("1");
  };

  const savedchanges = () => {
    setuploadFiles("0");
    setOpen(false);
  };







  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop Your Profile Images or Click to add Profile Images</p>
      </div>
      {files.length > 0 ? <div style={{marginTop:"30px" , backgroundColor:Secondcolor , textAlign:"center" , padding:"10px" , color:"white"}} onClick={handleOpen}>{ files.length+" Files Selected!"  }</div> : ""}

     
      <Dialog open={open} onClose={handleClose} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1500px",  // Set your width here
        },
      },
      }}>
        <DialogTitle>Your Image Gallery</DialogTitle>
        <DialogContent>
         <ImageGalleryWithCrop images={files}  childFunction={uploadFiles}  passtocropper={savedchanges}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={parentFunction} color="primary">
            Upload Files
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #d9d9d9',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

const fileItemStyle = {
  margin: '10px',
  display: 'inline-block',
};

const filePreviewStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
};

export default FileUpload;

import React from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';



const SectionBox = ({ title, details, icon, top, left  }) => (
    <Grid item>
      <Box
        sx={{
          width: '380px',
          height: details != "" && details != null && details !=  undefined ?  '500px' : '0px',
          border: '2px solid #D74894',
          borderRadius: '8px',
          backgroundColor: '#FEE8F1',
          margin: '16px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#D74894',
            color: '#fff',
            padding: '12px',
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around', // Added to align icon to the right
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{title}</div>
          {icon && (
            <IconButton sx={{ color: '#ffffff' }} /* Other styles as needed */>
              {icon}
            </IconButton>
          )}
        </Box>
  
        { details != "" && details != null && details !=  undefined ?  <div style={{ padding: '10px' }}>{details}</div> : "" }
      </Box>
    </Grid>
  );
  

  export default SectionBox;
import React, { useState, useEffect } from "react";
import { format } from "date-fns";

const locales = {
  es: require("date-fns/locale/es"),
  fr: require("date-fns/locale/fr")
};

const WrapperStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridColumnGap: "5px",
  gridRowGap: "5px",
  maxWidth: "250px",
  minHeight: "167px",
  maxHeight: "167px",
  margin: "25px auto"
};

const CaptionStyle = {
  color: "#555",
  fontSize: "0.75em"
};

const MonthButtonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: "8px",
  textAlign: "center",
  borderRadius: "4px",
  margin: "0 auto",
  outline: "none",
  '&:focus, &:active, &:hover': {
    background: "#ff7494",
    color: "white"
  },
  '&:disabled': {
    cursor: "not-allowed",
    userSelect: "none"
  }
};

const MonthPicker = ({ date, onMonthPicked }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    });
    return () => clearTimeout(timeout);
  }, []);

  const pickMonth = (e) => {
    onMonthPicked(e.target.dataset.month);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const monthWith30days = [
    "February",
    "April",
    "June",
    "September",
    "November"
  ];

  const today = new Date();

  return (
    <div style={show ? {} : { display: "none" }}>
      <p style={CaptionStyle}>Select month</p>
      <div style={WrapperStyle}>
        {months.map((m, index) => (
          <button
            key={m}
            onClick={pickMonth}
            hidden={
              (date > 30 && monthWith30days.includes(m)) ||
              (date === "30" && m === "February")
            }
            data-month={index + 1}
            style={{
              ...MonthButtonStyle,
              border: index === today.getMonth() ? "1px solid black" : "none"
            }}
          >
            {format(
              new Date(today.getFullYear(), months.indexOf(m), date),
              "MMMM",
              {
                locale: locales["en"]
              }
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MonthPicker;

import  { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ROLE } from '../../../common_utils/constants';
import { Typography } from '@mui/material';
import { useCommunityContextAdmin } from '../../contexts_admin/community_context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React from "react";



function UpdateCommunity() {


    const location = useLocation();
  const Details = location.state.details;


    const {update_community , data_update_community } = useCommunityContextAdmin();
    const [update , setupdate] = useState("0");

    const [formData, setFormData] = useState({
        name: '',
        code: '',
        fullname: '',
        contact_person: '',
        mobile: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        pin: '',
        language: '',
        website: '',
        superadmin1: '',
        superadmin2: '',
        password: '',
        community_icon: ''
      });
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

      useEffect(()=>{

      

            setFormData({
              name: Details.name,
              code: Details.code,
              fullname: Details.fullname,
              contact_person: Details.contact_person,
              mobile: Details.mobile,
              email: Details.email,
              address: Details.address,
              city: Details.city,
              state: Details.state,
              country: Details.country,
              pin: Details.pin,
              language: Details.language,
              website: Details.website,
              superadmin1: Details.superadmin1,
              superadmin2: Details.superadmin2,
              password: Details.password,
              community_icon: Details.community_icon
            });
          
            setupdate("1");

      },[]);


      const navigate = useNavigate();
      useEffect(()=>{

        

        if(data_update_community.affectedRows >= 1 &&  update == "1"){
           
          // alert("Community updated Successfully");

            navigate("/list_community");
            window.location.reload();

        }

      },[data_update_community]);


      const [errors, setErrors] = useState({});
      const ClickToAddCommunity = () => {


        const requiredFields = ['name', 'code', 'fullname', 'contact_person', 'mobile', 'city', 'state', 'website', 'superadmin1', 'superadmin2', 'password'];
    const newErrors = {};
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = 'This field is required';
      }
    });
    setErrors(newErrors);

    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0) {

        update_community({
            "name": formData.name,
            "code": formData.code,
            "fullname": formData.fullname,
            "contact_person": formData.contact_person,
            "mobile": formData.mobile,
            "email": formData.email,
            "address": formData.address,
            "city": formData.city,
            "state": formData.state,
            "country": formData.country,
            "pin": formData.pin,
            "language": formData.language,
            "website": formData.website,
            "superadmin1": formData.superadmin1,
            "superadmin2": formData.superadmin2,
            "staffadmin1": Details.superadmin1,
            "staffadmin2": Details.superadmin2,
            "password": formData.password,
            "community_icon": formData.community_icon,
            "Id": Details.Id
          });

        }else{
            alert("All Fields with * mark are compulsory");
        }


      }

      let role = localStorage.getItem(ROLE);
    
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>
          
          <Typography variant="h5" style={{ color: '#800080' , backgroundColor:"pink" , padding:"10px 20px" , fontWeight:"bold"}}>Update Community</Typography>

          <div style={{ display: 'flex', gap: '20px' }}>
            <TextField name="name" label="Community Name *" variant="outlined" value={formData.name} onChange={handleChange} fullWidth/>
            <TextField name="code" label="Code *" variant="outlined" value={formData.code} onChange={handleChange} fullWidth/>
          </div>
          <TextField name="fullname" label="Community Fullname *"  variant="outlined" value={formData.fullname} onChange={handleChange} />
          <div style={{ display: 'flex', gap: '20px' }}>
            <TextField name="contact_person" label="Contact Person *" variant="outlined" value={formData.contact_person} onChange={handleChange}  fullWidth/>
            <TextField name="mobile" label="Mobile *" variant="outlined" value={formData.mobile} onChange={handleChange} fullWidth/>
          </div>
          <TextField name="email" label="Email" variant="outlined" value={formData.email} onChange={handleChange} />
          <TextField name="address" label="Address" variant="outlined" value={formData.address} onChange={handleChange} />
          <div style={{ display: 'flex', gap: '20px' }}>
            <TextField name="city" label="City *" variant="outlined" value={formData.city} onChange={handleChange} fullWidth/>
            <TextField name="state" label="State *" variant="outlined" value={formData.state} onChange={handleChange} fullWidth/>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <TextField name="country" label="Country" variant="outlined" value={formData.country} onChange={handleChange}  fullWidth/>
            <TextField name="pin" label="Pin" variant="outlined" value={formData.pin} onChange={handleChange}  fullWidth/>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <TextField name="language" label="Language" variant="outlined" value={formData.language} onChange={handleChange} fullWidth/>
            <TextField name="website" label="Website *" variant="outlined" value={formData.website} onChange={handleChange} fullWidth/>
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <TextField name="superadmin1" label="Superadmin 1  *" variant="outlined" value={formData.superadmin1} onChange={handleChange} fullWidth/>
            <TextField name="superadmin2" label="Superadmin 2  *" variant="outlined" value={formData.superadmin2} onChange={handleChange} fullWidth/>
          </div>
          <TextField name="password" label="Password *" type="password" variant="outlined" value={formData.password} onChange={handleChange} />
          <TextField name="community_icon" label="Community Icon" variant="outlined" value={formData.community_icon} onChange={handleChange} />
          <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={ClickToAddCommunity}>Update Community Details</Button>
        </div>
      );


}

export default UpdateCommunity;

const reducerPartnerprefs = (state, action) => {
    switch (action.type) {
      case 'PARTNER_PREFS_DATA_FETCH':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'PARTNER_PREFS_DATA_FETCH_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };


        case 'CITY_FETCH_MULTIPLE':
        return {
          ...state,
          data_city_multiple: action.payload,
          loading: false,
          error: null,
        };
      case 'CITY_FETCH_MULTIPLE_ERROR':
        return {
          ...state,
          data_city_multiple: [],
          loading: false,
          error: action.payload,
        };


        case 'PARTNER_DETAILS_SUBMIT':
        return {
          ...state,
          partner_insert: action.payload,
          loading: false,
          error: null,
        };
      case 'PARTNER_DETAILS_SUBMIT_ERROR':
        return {
          ...state,
          partner_insert: [],
          loading: false,
          error: action.payload,
        };


        case 'PARTNER_DETAILS_UPDATE':
          return {
            ...state,
            partner_update: action.payload,
            loading: false,
            error: null,
          };
        case 'PARTNER_DETAILS_UPDATE_ERROR':
          return {
            ...state,
            partner_update: [],
            loading: false,
            error: action.payload,
          };


   
      default:
        return state;
    }
  };

  export default reducerPartnerprefs;
import { useEffect, useState } from "react";
import { useAboutusContext } from "../../contexts/aboutusContext";
import ReactMarkdown from 'react-markdown';
import { Parser } from 'html-to-react';
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import React from "react";
import { COMMUNITY_ID, TRANSLATED_LANG } from "../../common_utils/constants";


function PrivacyPolicy({passPath}){

    const { t } = useTranslation();

    const containerRef = useRef(null);

   const{ fetchPrivacyPolicy , privacy_policy} = useAboutusContext();
   const[privacypolicy_string , setPrivacyPolicy] = useState("");

   useEffect(()=>{

    passPath(t('privacy_policy_label'));

    if(privacy_policy.length <= 0){
        fetchPrivacyPolicy({
        "type":"privacy_policy",   //about , contact , chief_members , privacy_policy , refund_policy , faqs
        "communityId":localStorage.getItem(COMMUNITY_ID),
         "original": "en",
         "translate": [localStorage.getItem(TRANSLATED_LANG)]
       });
    }else{

        setPrivacyPolicy(privacy_policy[0][0]["0"]["description"]);

       // alert(aboutus[0][0]["description"]);

    }

   },[privacy_policy]);

   const parseHtml = new Parser();
 
   return (
   <div style={{position: "absolute", top: "165px" , width:"100%"}}>
   {privacy_policy.length > 0 ?
    <div dangerouslySetInnerHTML={{ __html: privacypolicy_string }} />
   :""
  }
   </div>
   );



}
export default PrivacyPolicy;
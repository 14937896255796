import { useEffect, useState } from "react";
import { useEmailMarketingContext } from "../../contexts_admin/email_marketing_context";
import { TextField ,Grid ,Button} from "@mui/material";
import { ROLE } from "../../../common_utils/constants";
import RichTextEditor from "../../component/RichTextEditor";
import React from "react";



function EmailTemplate(){

    const { insert_emailtemplate , data_insert_template , clear_insert_template} = useEmailMarketingContext(); 

    const [TemplateType , setTemplateType] = useState("");
    const [HtmlContent, setHtmlContent] = useState("");

    useEffect(()=>{

        if(data_insert_template.affectedRows >= 1){

            clear_insert_template();
           alert("Inserted Successfully");

        }


    },[data_insert_template]);
   

    const handleOptionChange = (event) => {
        setTemplateType(event.target.value);
    }

    const handleContent = (content) => {
        setHtmlContent(content);
    }

    const ClickToAddTemplates = () => {

        insert_emailtemplate({"template_details":HtmlContent , "type":TemplateType});

    }

    let role = localStorage.getItem(ROLE);

    return(<>
    <Grid container spacing={4} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "-25px" : "25px", left: role == "developer" ? "20px" : "15px" , width: "95%" , paddingRight:"15px" ,justifyContent:"center" , height:'90%'   }}>

    <Grid item xs={12} style={{marginTop:"50px"}}>

    <TextField
      label="Enter type for Template"
      variant="outlined"
      value={TemplateType}
      onChange={handleOptionChange}
      fullWidth
      style={{marginTop:"10px" , float:"right" }}
      >
    </TextField>

    </Grid>

    <Grid item xs={12}>

    <RichTextEditor SentContent={handleContent} />

    </Grid>

    <Grid style={{display:'flex' , justifyContent:'center', marginTop:"30px"}}>

    <Button variant="contained" style={{  width: "600px" , backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={ClickToAddTemplates}>Add Template</Button>


    </Grid>

    </Grid>
    </>);

}

export default EmailTemplate;
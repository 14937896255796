import React ,{useState , useEffect}  from "react";
import {
  useTheme,
  useMediaQuery,
  Divider,
  Typography,
  IconButton,
  Badge,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import DrawerComponent from "./Drawer";

import { about ,profile ,matches ,searches, profile_acitivty , inbox } from "../data/link_menu";
import styles from "../styles/link_menu.module.css";
import { useTranslation } from 'react-i18next';
import "../translations/en.json";
import DrawerComponentLogin from "./DrawerLogin";
import { fontSize } from "@mui/system";
import { IS_LOGIN, TRANSLATED_LANG, USER_ID } from "../common_utils/constants";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useNotificationContext } from "../contexts/notificationContext";
import MsgConfirm from "../app_utils/MsgConfirm";
import MsgConfirmClose from "../app_utils/MsgConfirmClose";




/*const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
    

  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));*/







function NavbarLogin({passPath}) {

    const { t, i18n } = useTranslation();

    const {notification_list , notificationList ,  user_validation_data_list , update_payment_expire} = useNotificationContext();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [msg , setMsg] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
      i18n.changeLanguage('en');

      notificationList({
        "userId": localStorage.getItem(USER_ID),
        "communityId": "1",
        "original": "en",
        "translate": [localStorage.getItem(TRANSLATED_LANG)]
    });

  
    }, []);

    useEffect(()=>{


      if(user_validation_data_list.length > 0){
     
      
      if(user_validation_data_list[2][0]["remaining_days"] != null){
        if(user_validation_data_list[2][0]["remaining_days"] <= 0){
  

         
          if(user_validation_data_list[0].length > 0){
  
            update_payment_expire({
              "userId":localStorage.getItem(USER_ID) ,
              "packageId":user_validation_data_list[0][0]["planId"]
            });
  
            
          }
        
  
          let message = "Your Membership is expired Please Select Your Plan Again to continue using our services";
            setMsg(true);
            setTitle("Membership Expired!");
            setContent(message);
  
        }
      }
    }
       


    },[user_validation_data_list]);


    const onlogout = () =>{

      localStorage.removeItem(IS_LOGIN);
      window.location.href = '/home';

    }



    const handleClickLink = (path) => {

      passPath(path);
       
     };

     const handleclosemsg = () => {
      setMsg(false);
      navigate('/membership', { replace: true });
     }

     const handlecancelmsg = () => {
      setMsg(false);
     }
   

 /* const theme2 = createTheme({
    typography: {
      poster: {
        fontSize: '4rem',
        color: 'red',
      },
      // Disable h3 variant
      h3: undefined,
    },
  });


  const roundedGradientStyleWithborder = {
    display: 'inline-block',
    borderRadius: '1px',
    color:  'red' ,
    transition: 'background 0.3s ease, border 0.3s ease',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif', 
    fontWeight: 'bold', // Set font weight to bold
    textDecoration: 'none',// Use Roboto font
    marginTop:'15px',
    borderTop:"2px solid red"
  };


  const menuItemStyle = {
    // Add your inline styles for MenuItem
    backgroundColor: 'pink'  ,
    color:"white",
    '&:hover': {
      backgroundColor: '#1976d2', // Change background color on hover
      color: '#ffffff', // Change text color on hover
    },
  };

  const roundedGradientStyle = {
    display: 'inline-block',
    padding: '5px 25px',
    color: '#ffffff',
    borderRadius: '30px',
    transition: 'background 0.3s ease, border 0.3s ease',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif', 
    fontWeight: 'bold', // Set font weight to bold
    textDecoration: 'none'// Use Roboto font
  };

  const handleHover = (event) => {
    event.target.style.border = '2px solid white'; // Change border color on hover
    event.target.style.background = 'linear-gradient(45deg,  #D74894 , #FFb6c1)'; // Change background on hover
  };

  const handleLeave = (event) => {
    event.target.style.border = '2px solid transparent'; // Revert to transparent border on leave
    event.target.style.background = 'transparent'; // Revert background on leave
  };


  */


  
  const roundedGradientStyle = {
    display: 'inline-block',
    padding: '5px 8px',
    color: '#ffffff',
    borderRadius: '30px',
    transition: 'background 0.3s ease, border 0.3s ease',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif', 
    fontWeight: 'bold', // Set font weight to bold
    textDecoration: 'none',
    zIndex:'2',
    marginLeft:"20px"
  };

  const [isDropdownVisible, setDropdownVisibility] = useState(true);
  const [isMenuVisible, setMenuVisibility] = useState(false);


  const handleHover = (event) => {

    event.target.style.border = '2px solid white'; // Change border color on hover
    event.target.style.background = 'linear-gradient(45deg,  var(--menu-gradient-colour1) , var(--menu-gradient-colour2))';
     // Change background on hover
  };

  const handleLeave = (event) => {

    event.target.style.border = '2px solid transparent'; // Revert to transparent border on leave
    event.target.style.background = 'transparent';
     // Revert background on leave
  };



   const handleNotification  = () =>{

    navigate('/notification_list', { replace: true });

   }


  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let items_profile = [];
  let items_searches = [];
  let items_matches = [];
  let items_profile_acitivity = [];
  let items_inbox = [];

  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const MAX_WIDTH_FOR_MOBILE = 900;
  
var buttonIsVisible = window.visualViewport.width < window.Width;


  return (
    
    width > MAX_WIDTH_FOR_MOBILE ?
          <div id="main-div" style={{
             backgroundColor:"var(--menu-container-colour)"  , boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            width: buttonIsVisible ? "100%" : "100%",
            height:"65px",
            justifyContent:"right",
            position:"fixed",
            textAlign:"center",
            top:"0",
            zIndex:"4",
            
            }}>



<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
      {t('my_profile')}
      </Link>
      {isDropdownVisible ? (
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
            profile.forEach((s,i) => {

               if(i <= profile.length-2){
                items_profile.push(<><Link exact to={s[1]}  onClick={() =>handleClickLink(t(s[0]))} ><span >{t(s[0])}</span></Link>
                <Divider /></>);
               }else{
                items_profile.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                </>);
               }
            
              
            })
          }
          {items_profile}
        </div>
      ): ''}
</div>
<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
        {t('search')}
      </Link>
      {isDropdownVisible ? (
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
            searches.forEach((s,i) => {

               if(i <= searches.length-2){
                items_searches.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                <Divider /></>);
               }else{
                items_searches.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                </>);
               }
            
              
            })
          }
          {items_searches}
        </div>
      ): ''}
</div>

<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
        {t('latest_matches')}
      </Link>
      {isDropdownVisible ? (
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
            matches.forEach((s,i) => {

               if(i <= matches.length-2){
                items_matches.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                <Divider /></>);
               }else{
                items_matches.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                </>);
               }
            
              
            })
          }
          {items_matches}
        </div>
      ): ''}
</div>



<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
        {t('activity')}
      </Link>
      {isDropdownVisible ? (
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
            profile_acitivty.forEach((s,i) => {

               if(i <= profile_acitivty.length-2){
                items_profile_acitivity.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                <Divider /></>);
               }else{
                items_profile_acitivity.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                </>);
               }
            
              
            })
          }
          {items_profile_acitivity}
        </div>
      ): ''}
</div>


<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
        {t('inbox')}
      </Link>
      {isDropdownVisible ? (
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
            inbox.forEach((s,i) => {

               if(i <= inbox.length-2){
                items_inbox.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                <Divider /></>);
               }else{
                items_inbox.push(<><Link exact to={s[1]} onClick={() =>handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                </>);
               }
            
              
            })
          }
          {items_inbox}
        </div>
      ): ''}
</div>

<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/membership"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        onClick={() =>handleClickLink(t('upgrade'))}
      >
        {t('upgrade')}
      </Link>
</div>

<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/chat_message"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        onClick={() =>handleClickLink(t('chat_message'))}
      >
        {t('chat_message')}
      </Link>
</div>

<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/download"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        
      >
        {t('download_app')}
      </Link>
</div>


<IconButton onClick={handleNotification}>
  <Badge badgeContent={ notification_list.length > 0 ? Object.values(notification_list[0][0]).length : 0} color="secondary">
    <NotificationsNoneIcon  style={{ fontSize: 30 , color: "#fff" }}/>
  </Badge>
</IconButton>

<div style={{marginLeft:"5%" , background:"#E3256B" , padding: '21px'}}>


    
    

       <Link
        to="#"
        onClick={onlogout}
        style={{ fontFamily: 'Roboto, sans-serif', 
        fontWeight: 'bold', textDecoration: 'none', display: 'inline-block',
        
        color: '#ffffff'}}>
        {t('logout')}
      </Link>
      
      </div>  



      <MsgConfirmClose
          isOpen={msg}
          handleClose={handleclosemsg}
          handleCancel={handlecancelmsg}
          title={title}
          content={content}
          cancelText={"Cancel Dialog"}
          closeText={"Upgrade Membrship"}
        />

   

    </div> : <DrawerComponentLogin/>

    

         
  );



 



}
export default NavbarLogin;



import { useEffect } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";




function DetailBlogs({isopen , setClose, Details}){


    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }




    useEffect(()=>{
    

        setOpen(isopen);
       
    },[]);




   




    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Details of Blog {Details.title}</DialogTitle>
      <DialogContent>

      
      <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '10px', marginTop: '20px' }}>
  <Typography variant="h6" style={{ color: '#AA336A', borderBottom: '2px solid #333', paddingBottom: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Blog Details</Typography>
  <div style={{ marginBottom: '20px' }}>
    <Typography variant="subtitle1" style={{ color: 'purple', marginTop: "10px" }}>Title: <span style={{ fontWeight: 'bold', color: '#333' }}>{Details.title}</span></Typography>
    <Typography variant="subtitle1" style={{ color: 'purple', marginTop: "10px" }}>KeyWords: </Typography>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {Details.key_words.split(',').map((status, index) => (
        <Chip key={index} label={status.trim()} color={"primary"} style={{ marginRight: '4px', marginTop: '5px' }} />
      ))}
    </div>
  </div>
  <div style={{ marginBottom: '20px' }}>
    <Typography variant="subtitle1" style={{ color: '#000000' }}>Blogs Details:</Typography>
    <Typography variant="subtitle1" style={{ color: '#000000', marginTop: "10px" }}>
      <div dangerouslySetInnerHTML={{ __html: Details.details }} />
    </Typography>
  </div>
</div>
        
            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default DetailBlogs;
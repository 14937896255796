import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useEducationContext } from "../../contexts/educationalContext";
import { Maincolor, Secondcolor } from "../../common_utils/colors";
import { useTranslation } from "react-i18next";
import { DropdownSelect, StyledTextField } from "../../app_utils/input_fields";
import  React,{ useState } from "react";
import { CSSTransition } from "react-transition-group";
import { produce } from "immer";
import { useEffect } from "react";
import "../../styles/animationfade.css";
import { COMMUNITY_ID, PROFILE_ID, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { useOccupationContext } from "../../contexts/occupationdetailsContext";
import { employmentType } from "../../common_utils/utils";
import { EmploymentType } from "../../data/data";
import { DateTime } from "luxon";
import { lookupViaCity } from "city-timezones";
import { useNavigate } from "react-router-dom";
import RotatingHeart from "../../common_utils/loading_animator";



function Occupational_Details({passPath}) {

    const { data, loading, error, occupation_insert, occupation_update, submit_occupationdetails, update_occupationdetails } = useOccupationContext();

    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();
    const [occupation_list, setOccupation] = useState([]);
    const [annualIncome, setAnualIncome] = useState([]);
    const [load, loadset] = useState(true);




    const langwiseOccupation = (occupation_data) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const occup_name = occupation_data.map(user => ({
                label: user.occupation,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));


            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const occup_name = occupation_data.map(user => ({
                label: user.occup_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occup_name);
        }


    }




    useEffect(() => {



        passPath(t('occupation_details_label'));
        /*  const cityLookup = lookupViaCity('Ahmedabad');
  
          const timezoneIdentifier = cityLookup[0].timezone;
          const now = DateTime.now().setZone(timezoneIdentifier);
          const offsetInHours = now.offset / 60;
    
          alert(offsetInHours);
  
          */

        if (data.length > 0) {

            langwiseOccupation(data[1].flat());

            const annualIncome = data[2].flat().map(user => ({
                label: user.label,  // Adjust based on your data structure
                value: user.value         // Adjust based on your data structure
            }));

            setAnualIncome(annualIncome);


            const setup = data[0].flat()[0];

            if (setup != undefined) {

                const nextState = produce((draft) => {

                    draft["occupation"] = setup.occupation_list;
                    draft["detail"] = setup.occupation_detail;
                    draft["income"] = setup.annual_income;
                    draft["employment_type"] = setup.employment_type;
                    draft["office_address"] = setup.office_address;
                

                });

                // Set the updated state
                setOccupationFields(nextState);
            }


        }


    }, [data]);



    const navigate = useNavigate();

   useEffect(() => {

      if(occupation_insert.affectedRows >= 1 ||  occupation_update.success == 1){
     setTimeout(() => {
      
       navigate('/horoscope', { replace: true });
     }, 1200);
    }


   }, [occupation_insert , occupation_update]);



    const [occupationFields, setOccupationFields] = useState({
        occupation: '',
        detail: '',
        income: '',
        employment_type: '',
        office_address: '',
    });

    const [errors, setErrors] = useState({
        occupation: false,
        detail: false,
        income: false,
        employment_type: false,
        office_address: false,
    });

    const [hasBeenFocused, setHasBeenFocused] = useState({
        occupation: false,
        detail: false,
        income: false,
        employment_type: false,
        office_address: false,
    });


    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setOccupationFields(nextState);
        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {
                const trimmedValue = newValue;

                //alert(trimmedValue);

                draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === '';


            })
        );

    };




    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;


        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);

                if (occupationFields[fieldName] !== '') {
                    draftErrors[fieldName] = false;
                } else {
                    draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                }


            })
        );

    }




    const handleSubmit = () => {


        const newErrors = produce(errors, (draftErrors) => {
            for (const field in occupationFields) {
                const trimmedValue = occupationFields[field];

                draftErrors[field] = trimmedValue === '' || trimmedValue.length <= 0;
                draftErrors["detail"] = false;


            }
        });




        setErrors(newErrors);


        if (Object.values(newErrors).some(error => error) == true) {



        } else {


            loadset(false);
            const article = {
                "occupation_list": occupationFields.occupation,
                "occupation_detail": occupationFields.detail,
                "annual_income": occupationFields.income,
                "employment_type": occupationFields.employment_type,
                "office_address": occupationFields.office_address,
                "userId": localStorage.getItem(USER_ID),
                "communityId": localStorage.getItem(COMMUNITY_ID),
                "profileId": localStorage.getItem(PROFILE_ID),
                "Id": data[0].flat()[0] == undefined ? "0" : data[0].flat()[0].Id
            }



            if (data[0].flat()[0] == undefined) {
                submit_occupationdetails(article);
            } else {
                update_occupationdetails(article);
            }




        }




    };




    return (

        <>

{  load == false ?  <RotatingHeart/> : 
            <div style={{  width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "220px" , marginBottom:"60px" }}>
                <CSSTransition
                    in={true}
                    timeout={500} // Duration of the transition in milliseconds
                    classNames="fade"
                    unmountOnExit>

                    <Grid container xs={12} >

                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>


                            <Grid item xs={12} md={12} >

                                <DropdownSelect options={occupation_list} label={t('occupation_list_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('occupation')}
                                    onMouseDown={handleClick('occupation')}
                                    value={occupationFields.occupation} />

                                {errors.occupation && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>

                            <Grid item xs={12} md={12}>


                                <StyledTextField label={t('occupation_details_label')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    rows={3}
                                    onChange={handleChange('detail')}
                                    value={occupationFields.detail}
                                />


                            </Grid>

                            <Grid item xs={12} md={12} >

                                <DropdownSelect options={annualIncome} label={t('annual_income_p_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('income')}
                                    onMouseDown={handleClick('income')}
                                    value={occupationFields.income} />

                                {errors.income && <span style={{ color: 'red', marginBottom: "5px" }}>required!</span>}

                            </Grid>

                            <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>

                                <DropdownSelect options={EmploymentType} label={t('employment_type_l')} variant="contained" focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} height={50} width={"100%"} borderWidth={0.5}
                                    onChange={handleChange('employment_type')}
                                    onMouseDown={handleClick('employment_type')}
                                    value={occupationFields.employment_type} />

                                {errors.employment_type && <span style={{ color: 'red', marginBottom: "5px" }}>required!</span>}

                            </Grid>

                            <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>


                                <StyledTextField label={t('office_address_l')} focusedcolor={"#E3256B"}
                                    bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                    rows={3}
                                    onChange={handleChange('office_address')}
                                    value={occupationFields.office_address}
                                />

                                {errors.office_address && <span style={{ color: 'red' }}>required!</span>}

                            </Grid>


                            <Grid item xs={12} md={12} style={{ marginTop: "0px" }}>
                                <Button type="button" style={{
                                    justifyContent: "center", alignContent: "center",
                                    background: "#E3256B", width: "100%", color: "white", fontWeight: "bold"
                                }} onClick={handleSubmit}>Submit Occupation Details</Button>

                            </Grid>


                        </Grid>

                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={1}>

                        </Grid>


                    </Grid>

                </CSSTransition>

            </div>

                            }



        </>

    );


}

export default Occupational_Details;
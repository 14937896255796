import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import translationEN from '../translations/en.json';
import translationHI from '../translations/hi.json';
import translationGU from '../translations/gu.json';
import translationMR from '../translations/mr.json';
import translationTA from '../translations/ta.json';
import translationUR from '../translations/ur.json';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      hi: {translation: translationHI},
      gu: {translation: translationGU},
      mr: {translation: translationMR},
      ta: {translation: translationTA},
      ur: {translation: translationUR}
    },
    lng: 'en', // Set default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

  
export default i18n;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer/basicReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const basicContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  dataInsert:[],
  dataUpdate:[],
  picInsert:[],
  picUpdate:[],
  picList:[]
};

let API = `${BASE_URL}`;


export const BasicProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const fetchBasicItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/basic_details_edit` , params);
      
      dispatch({ type: 'BASIC_DETAILS_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BASIC_DETAILS_EDIT_ERROR', payload: error });
    }
  };



  const fetchImagesItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/profile_fetch` , params);
      
      dispatch({ type: 'IMAGE_FETCH_EDIT' , payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'IMAGE_FETCH_EDIT_ERROR' , payload: error });
    }
  };



  const submit_basicdetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/basic_detail` , article);
      dispatch({ type: 'BASIC_DETAILS_SUBMIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BASIC_DETAILS_SUBMIT_ERROR', payload: error });
    }
  };


  const update_basicdetails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/basic_detail` , article);
      dispatch({ type: 'BASIC_DETAILS_UPDATE', payload: response.data });
    } catch (error) {
      dispatch({ type: 'BASIC_DETAILS_UPDATE_ERROR', payload: error });
    }
  };


  const insert_photos = async (formData) => {
    try {
      const response = await axios.post(`${API}`+'profile/insert_photos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'INSERT_PHOTOS', payload: response.data.data });
      
    } catch (error) {
      dispatch({ type: 'INSERT_PHOTOS_ERROR', payload: error });
    }
  };



  const update_photos = async (formData) => {
    try {
      const response = await axios.patch(`${API}`+'profile/update_photos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({ type: 'UPDATE_PHOTOS', payload: response.data });
      
    } catch (error) {
      dispatch({ type: 'UPDATE_PHOTOS_ERROR', payload: error });
    }
  };




  useEffect(() => {
       
    fetchBasicItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });


        fetchImagesItems({
          "type":"pictures",  // basic , contact , education , family , horoscope , lifestyle , occupation , partner_prefs , pictures , proofs
          "userId":localStorage.getItem(USER_ID),
          "communityId":localStorage.getItem(COMMUNITY_ID),
        "original": "en",
        "translate": [localStorage.getItem(TRANSLATED_LANG)]
      });
    
  }, []);


 
  
  return <basicContext.Provider value={{ ...state , dispatch , fetchBasicItems , fetchImagesItems , submit_basicdetails , update_basicdetails , insert_photos ,update_photos}}>{children}</basicContext.Provider>;
};

export const useBasicContext = () => useContext(basicContext);
const membershipReducer = (state, action) => {
    switch (action.type) {
      case 'MEMBERSHIP':
        return {
          ...state,
          membsership_list: action.payload,
          loading: false,
          error: null,
        };
      case 'MEMBERSHIP_ERROR':
        return {
          ...state,
          membsership_list: [],
          loading: false,
          error: action.payload,
        };



        case 'CREATE_ORDER':
        return {
          ...state,
          create_order_data: action.payload,
          loading: false,
          error: null,
        };
      case 'CREATE_ORDER_ERROR':
        return {
          ...state,
          create_order_data: [],
          loading: false,
          error: action.payload,
        };




        case 'VERIFY_PAYMENT':
        return {
          ...state,
          verify_payment_list: action.payload,
          loading: false,
          error: null,
        };
      case 'VERIFY_PAYMENT_ERROR':
        return {
          ...state,
          verify_payment_list: [],
          loading: false,
          error: action.payload,
        };


        case 'INSERT_PAYMENT':
        return {
          ...state,
          insert_payment: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_PAYMENT_ERROR':
        return {
          ...state,
          insert_payment: [],
          loading: false,
          error: action.payload,
        };



        case 'UPDATE_PAYMENT':
        return {
          ...state,
          update_payment: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_PAYMENT_ERROR':
        return {
          ...state,
          update_payment: [],
          loading: false,
          error: action.payload,
        };



   
      default:
        return state;
    }
  };

  export default membershipReducer;
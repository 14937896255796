const reducer = (state, action) => {
    switch (action.type) {
      
      case 'ADD_SAMUHLAGAN_SUCCESS':
        return {
          ...state,
          data_insert_samuhlagan: action.payload,
          loading_samuhlagan: false,
          error: null,
        };
      case 'ADD_SAMUHLAGAN_ERROR':
        return {
          ...state,
          data_insert_samuhlagan: [],
          loading_samuhlagan: false,
          error: action.payload,
        };

      case 'SAMUHLAGAN_LIST_SUCCESS':
        return {
          ...state,
          list_samuhlagan_admin: action.payload,
          loading_samuhlagan: false,
          error: null,
        };
      case 'SAMUHLAGAN_LIST_ERROR':
        return {
          ...state,
          list_samuhlagan_admin: [],
          loading_samuhlagan: false,
          error: action.payload,
        };

      case 'UPDATE_SAMUHLAGAN_SUCCESS':
        return {
          ...state,
          data_update_samuhlagan: action.payload,
          loading_samuhlagan: false,
          error: null,
        };
      case 'UPDATE_SAMUHLAGAN_ERROR':
        return {
          ...state,
          data_update_samuhlagan: [],
          loading_samuhlagan: false,
          error: action.payload,
        };
      
      // Cases for events
      case 'ADD_EVENT_SUCCESS':
        return {
          ...state,
          data_insert_event: action.payload,
          loading_event: false,
          error: null,
        };
      case 'ADD_EVENT_ERROR':
        return {
          ...state,
          data_insert_event: [],
          loading_event: false,
          error: action.payload,
        };

      case 'EVENT_LIST_SUCCESS':
        return {
          ...state,
          list_event_admin: action.payload[0].filter(item => item.eventname != 'samuhlagan'),
          total_row_count_event : action.payload[1][0].total_row_count,
          loading_event: false,
          error: null,
        };
      case 'EVENT_LIST_ERROR':
        return {
          ...state,
          list_event_admin: [],
          loading_event: false,
          error: action.payload,
        };



        case 'EVENT_LIST_SUCCESS_SAMUHLAGAN':
        return {
          ...state,
          list_event_samuhlagan_admin: action.payload[0].filter(item => item.eventname == 'samuhlagan'),
          total_row_count_samuhlagan : action.payload[1][0].total_row_count,
          loading_event: false,
          error: null,
        };
      case 'EVENT_LIST_ERROR_SAMUHLAGAN':
        return {
          ...state,
          list_event_samuhlagan_admin: [],
          loading_event: false,
          error: action.payload,
        };
        

      case 'UPDATE_EVENT_SUCCESS':
        return {
          ...state,
          data_update_event: action.payload,
          loading_event: false,
          error: null,
        };
      case 'UPDATE_EVENT_ERROR':
        return {
          ...state,
          data_update_event: [],
          loading_event: false,
          error: action.payload,
        };
      
      default:
        return state;
    }
  };
  
  export default reducer;
  
import { Button, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useWebThemeContextAdmin } from "../../contexts_admin/web_theme_context";
import { useEffect } from "react";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import { useState } from "react";
import StylishLabel from "../../component/StylishLabel";
import { bool } from "yup";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import StylishLabelOther from "../../component/StylishLabelOther";





function WebManagement(){

  const {insert_update_visibility , insert_update_webtheme , select_web_theme , select_visibility , data_insert_webtheme , data_insert_visibility , data_select_webtheme , data_select_visibility} = useWebThemeContextAdmin();

  const [webfunction , setwebfunction] = useState("");
  const [communityId , setcommunityId] = useState("");
  const [openCommunity , setopenCommunity] = useState(false);

  useEffect(()=>{

    let communityId_admin  =  "0";
    if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

      communityId_admin = localStorage.getItem("communityId_admin");
      setcommunityId(communityId_admin);

    }else{
     communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
     setcommunityId(communityId_admin);
    }

   // alert(localStorage.getItem(ADMIN_COMMUNITY_ID)+"______"+localStorage.getItem("communityId_admin"));


    setcommunityId(communityId_admin);
    select_visibility({"communityId":communityId_admin});

    


  },[data_insert_visibility]);

const handleFunctionChange = (event) => {

  setwebfunction(event.target.value);

};

  const handleAddFunction = () => {
    if (webfunction.trim() !== '') {

      insert_update_visibility({
        "type_name":webfunction,
        "visibility":'0',
        "communityId":communityId

      });
      
    }
  };

  const handleSwitchChange = (data , event, index) => {

    const newData = [...data_select_visibility];
    event.target.checked  ? newData[index].visibility = 0 :  newData[index].visibility = 1;

    insert_update_visibility({
      "type_name":data.type_name,
      "visibility":newData[index].visibility,
      "communityId":communityId

    });

  };

  const handleClickCommunityLink = (communityId_admin) => {

    setcommunityId(communityId_admin);
    localStorage.setItem("communityId_admin" , communityId_admin);

  };


  const handleClickCommunity = () => {

    setopenCommunity(true);

  };


  const handleCloseCommunity = (isopen) => {
    setopenCommunity(isopen);
  }


  let role = localStorage.getItem(ROLE);

  return(<>
  

  { role == "developer" ? <StylishLabelOther text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "150px" : "100px", left: role == "developer" ? "5px" : "5px" , width: "100%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>


 

  <Grid container spacing={1}>

  <Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>functionality</TableCell>
                  <TableCell>Visibility</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data_select_visibility.length > 0 && data_select_visibility.map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                     <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.type_name}</TableCell>
                    <TableCell style={{ color: 'black', fontSize: '15px'  }}>
              <Switch
                  checked={religion.visibility == 0 ? true : false}
                  onChange={(event) => handleSwitchChange(religion , event, index)}
                  color="primary"
                />
                </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>



          

</Grid>


<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter Functionality"
        variant="outlined"
        value={webfunction}
        fullWidth
        onChange={handleFunctionChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddFunction}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

</Grid>


</Grid>


{openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

  </div>




  
  
  </>);


}

export default WebManagement;
import React, { useEffect } from "react";
import styles from "../../styles/featured_profile.module.css";
import { useState } from "react";
import { InputLabel } from "@mui/material";
import { BASE_URL, IMAGE_URL } from "../../common_utils/constants";
import { useTranslation } from "react-i18next";



const FeaturedProfile = ({items_featured}) => {


    const[ishidden , setishidden] = useState("");
    const { t } = useTranslation();


    useEffect(() =>{

     if(Object.values(items_featured[4][0]).length > 0){
       setishidden(Object.values(items_featured[4][0])[0].ishidden);
      }
 
    }, []);


     return(
      <React.Fragment>
  
    <div className="fatured-section">
     {ishidden == "0" ?
     <div>
      <InputLabel style={{backgroundColor:"#E3256B" , color:"white" , fontWeight:"bold" , textAlign:"center" , marginTop:"20px" , justifyContent:"center" ,alignContent:"center" ,alignItems:"center"  , fontSize:"25px" , marginLeft:"35%" , marginRight:"35%"  , borderRadius:"20px" , "fontFamily":"Dosis"}}>{t('featured_profiles')}</InputLabel>
     <div className={styles.container}>
    

    {
       items_featured.length > 0 ?  

       items_featured[4][0] && Object.values(items_featured[4][0]).map((item ,index) => (

        <div className={styles.card_wrapper} key={index}>
      
       <div className={styles.card}>
         
         <div className={styles.card_image}>
           <img  src={IMAGE_URL+"/uploads/"+item.pic} style={{ width:"100%" , height:"100%"}}/>
         </div>
 
       <ul className={styles.social_icons} key={item.Id}>
         <li>
           <a href="">
             <i className={styles.fab}></i>
           </a>
         </li>
       </ul>
 
       <div className={styles.details}>
         <span className={styles.span}>{item.fullname}</span><br/>
           
         <span className={styles.span2}>{"height : "+item.height}</span><br/>
           <span className={styles.span2}>{"Education : "+item.education}</span><br/>
           <span className={styles.span3}>{item.iit_nit == "1" ? "Highly Educated Top Institute" : ""}</span>{item.iit_nit == "1" ? <br/> :""}
           <span className={styles.span3}>{item.is_admin_service == "1" ? "From Administrative service" : ""}</span>{item.is_admin_service == "1" ? <br/> :""}
           <span className={styles.span3}>{item.occupation}</span><br/>
        
       </div>
     </div>
   </div>


       ))
        : [] 
   
   
   }

    
      
    </div></div>  : []

}
</div>
      </React.Fragment>
     );


    


}

export default FeaturedProfile;
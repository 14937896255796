const reducerPartnerprefs = (state, action) => {
    switch (action.type) {
      case 'PARTNER_PREFS_DATA_FETCH_ADMIN':
        return {
          ...state,
          data_partner_prefs_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'PARTNER_PREFS_DATA_FETCH_ERROR_ADMIN':
        return {
          ...state,
          data_partner_prefs_admin: [],
          loading: false,
          error: action.payload,
        };


        case 'CITY_FETCH_MULTIPLE_ADMIN':
        return {
          ...state,
          data_city_multiple_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'CITY_FETCH_MULTIPLE_ERROR_ADMIN':
        return {
          ...state,
          data_city_multiple_admin: [],
          loading: false,
          error: action.payload,
        };


        case 'PARTNER_DETAILS_SUBMIT_ADMIN':
        return {
          ...state,
          partner_insert_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'PARTNER_DETAILS_SUBMIT_ERROR_ADMIN':
        return {
          ...state,
          partner_insert_admin: [],
          loading: false,
          error: action.payload,
        };


        case 'PARTNER_DETAILS_UPDATE_ADMIN':
          return {
            ...state,
            partner_update_admin: action.payload,
            loading: false,
            error: null,
          };
        case 'PARTNER_DETAILS_UPDATE_ERROR_ADMIN':
          return {
            ...state,
            partner_update_admin: [],
            loading: false,
            error: action.payload,
          };


   
      default:
        return state;
    }
  };

  export default reducerPartnerprefs;
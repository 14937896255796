import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, MenuItem, Pagination, IconButton, Modal } from "@mui/material";
import { ADMIN_COMMUNITY_ID, ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StylishLabel from '../../component/StylishLabel';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { DashboardProvider } from '../../contexts_admin/dashboard_context';
import CommunityModal from '../../component/CommunityModal';
import StylishLabelOther from '../../component/StylishLabelOther';
import { useEffect } from 'react';







function Caste_page(){
  
  const {subcaste_fetch  , caste_fetch , caste_insert , caste_update , data_insert_caste , list_caste_admin , data_update_caste ,religion_fetch ,list_religion_admin , delete_master_data ,list_subcaste_admin} = useMasterContextAdmin();

  const [selectedOption , setSelectedOption] = useState('');
  const [selectedOption1 , setSelectedOption1] = useState('');
  const [selectedOption2 , setSelectedOption2] = useState('');

  const [religion, setReligion] = useState('');
  const [religion_display, setReligionUpdate] = useState('');
  const [religion_id, setReligionId] = useState('');
  const [casteId, setCasteId] = useState('');
  const [caste, setCaste] = useState('');
  const [caste2, setCaste2] = useState('');

  const [open , setopen] = useState(false);
  const [insert , setInsert] = useState(false);

  const [openCommunity , setopenCommunity] = useState(false);
  const [communityId, setCommunityId] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [Id, setId] = useState('');

  const [isdispaly , setdisplay] = useState(false);

    const handleReligionChange = (event) => {
    setReligion(event.target.value);
  };
  

  const handleCasteChange = (event) => {
     setCaste(event.target.value);
  }


  const handleCasteChange2 = (event) => {
    setCaste2(event.target.value);
 }


 useEffect(()=>{

  setdisplay(false);
       if(list_subcaste_admin[0] != undefined){



        if(list_subcaste_admin[0].length == 0){
          delete_master_data({ "type":"caste"  , "is_enabled":"1" , "Id":Id});

          setTimeout(()=>{

            caste_fetch({
              "religion_id": selectedOption,
              "communityId": communityId,
              "offset": currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10)
            });

          },500);

        }else if(list_subcaste_admin[0].length > 0 && isdispaly == true){

          
          alert("You can not delete this caste as it has entry in Subcaste table");
        }

       
       
       
       }
       

    },[list_subcaste_admin]);

 const handleDeleteClick = (is_enabled , Id) => {
  //delete_master_data({ "type":"caste"  , "is_enabled":"1" , "Id":Id});

  setId(Id);

    setTimeout(() => {
      let communityId_admin  =  "0";

      if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
  
        communityId_admin = localStorage.getItem("communityId_admin");
        setCommunityId(communityId_admin);
  
        
      }else{
       communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
        setCommunityId(communityId_admin);
      }
  

      setdisplay(true);
      subcaste_fetch({
        "casteId":Id,
        "communityId": communityId_admin,
        "offset":"0"
      });

      } , 500);

  
  
}

  useEffect(()=>{

   // alert(localStorage.getItem(ADMIN_COMMUNITY_ID));
    religion_fetch();

  },[]);

  useEffect(()=>{
    

    if((data_insert_caste.affectedRows >= 1 &&  insert == true) || (data_update_caste.affectedRows >= 1 &&  insert == true) ){

     // alert(selectedOption+"___"+communityId+"____"+currentPage);

      caste_fetch({
        "religion_id": selectedOption,
        "communityId": communityId,
        "offset": currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10)
      });



    }

    setInsert(false);
  
  },[data_insert_caste , data_update_caste]);

  const handleOptionChange = (event) => {

    setSelectedOption(event.target.value);

         let communityId_admin  =  "0";

          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

            
          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

         // alert(communityId_admin);
          
    caste_fetch({
      "religion_id":event.target.value,
      "communityId": communityId_admin,
      "offset":"0"
    });

  }

  const handleOptionChange1 = (event) => {

    setSelectedOption1(event.target.value);

  }

  const handleOptionChange2 = (event) => {

    setSelectedOption2(event.target.value);

  }

  const handleClickCommunity = () => {
    setopenCommunity(true);
  }


  const handleCloseCommunity = (isopen) => {
    setopenCommunity(isopen);
  }



  const handleClickCommunityLink = (communityId_admin) => {

    setCommunityId(communityId_admin);
    localStorage.setItem("communityId_admin" , communityId_admin);

  }


  const handlePageChange = (event, value) => {
  
    setCurrentPage(value);

    caste_fetch({
      "religion_id":selectedOption,
      "communityId": communityId,
        "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
    });

 } 

   const AddCaste = () => {

    if(selectedOption1 != "" && caste != ""){

      caste_insert({
        "religion_id": selectedOption1,
        "communityId": communityId,
        "caste":caste
      });

      setInsert(true);

    }
  
   }



   const updateCaste = () => {

    //alert(selectedOption+"___"+caste2);

    if(selectedOption != "" && caste2 != ""){

      caste_update({
        "Id": casteId,
        "communityId": communityId,
        "caste":caste2
      });

      setInsert(true);
      setopen(false);

    }
  
   }

   const handleClose = () => {
    setopen(false);
   };

   const handleEditClick = (caste , id) => {
  

    setCasteId(id);
    religion_fetch();
    setCaste2(caste);
    setTimeout( function()  {setSelectedOption2(selectedOption);  setopen(true);} , 300);

   }


    let role = localStorage.getItem(ROLE);
  
   return (

    <div>{ role == "developer" ? <div><StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} style={{width:"100%"}} /></div> : ""}
                            
     

        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "150px" : "150px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>



    <TextField
      select
      label="Select Religion to List Caste"
      variant="outlined"
      value={selectedOption}
      onChange={handleOptionChange}
      style={{marginTop:"10px" , float:"right" , width:"200px" , marginRight:"20px"}}
      >
      {list_religion_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.religion_name}
        </MenuItem>
      ))}
    </TextField>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Caste</TableCell>
                  <TableCell>Religion Name</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_caste_admin.length > 0 && list_caste_admin[0].map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.caste}</TableCell>
                    <TableCell>{religion.religion}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.caste , religion.Id)}><EditIcon /></IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>



          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_caste_admin.length > 0 && Math.ceil(list_caste_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>

</Grid>



<Grid  item xs={5} >
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex' , height:"200px" }}>
    <Grid container spacing={1}>


    <Grid item xs={12}>

    <TextField
      select
      label="Select Religion"
      variant="outlined"
      value={selectedOption1}
      onChange={handleOptionChange1}
      style={{ float:"right" , width:"410px" }}
      >
      {list_religion_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.religion_name}
        </MenuItem>
      ))}
    </TextField>


</Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Caste"
        variant="outlined"
        value={caste}
        fullWidth
        onChange={handleCasteChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
         onClick={AddCaste}>
        Add Caste
      </Button>

      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>

          {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}


          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
      <Grid item xs={12}>

<TextField
  select
  label="Select Religion"
  variant="outlined"
  value={selectedOption2}
  onChange={handleOptionChange2}
  style={{ float:"right" , width:"360px" }}
  >
  {list_religion_admin.map((option) => (
    <MenuItem key={option.Id} value={option.Id}>
      {option.religion_name}
    </MenuItem>
  ))}
</TextField>


</Grid>

<Grid  item xs={12}>
  <TextField
    label="Enter Caste"
    variant="outlined"
    value={caste2}
    fullWidth
    onChange={handleCasteChange2}
    style={{ marginRight: '10px', flex: 1 }}/>
</Grid>



<Grid item xs={12}>
  <Button
    variant="contained"
    color="primary"
    fullWidth
     onClick={updateCaste}
    >
    Update Caste
  </Button>

  </Grid>
      </Grid>
    </Modal>

    

        </div>

        </div>
      );




}

export default Caste_page;
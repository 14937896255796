const reducer = (state, action) => {
  switch (action.type) {
    case 'BASIC_DETAILS_EDIT_ADMIN':
      return {
        ...state,
        data_admin: action.payload,
        loading_admin: false,
        error_basic_admin: null,
      };
    case 'BASIC_DETAILS_EDIT_ERROR_ADMIN':
      return {
        ...state,
        data_admin: [],
        loading_admin: false,
        error_basic_admin: action.payload,
      };


      case 'IMAGE_FETCH_EDIT_ADMIN':
      return {
        ...state,
        picList_admin: action.payload,
        loading_admin: false,
        error_basic_admin: null,
      };
    case 'IMAGE_FETCH_EDIT_ERROR_ADMIN':
      return {
        ...state,
        picList_admin: [],
        loading_admin: false,
        error_basic_admin: action.payload,
      };



    case 'BASIC_DETAILS_SUBMIT_ADMIN':
      return {
        ...state,
        dataInsert_admin: action.payload,
        loading_admin: false,
        error_basic_admin: null,
      };
    case 'BASIC_DETAILS_SUBMIT_ERROR_ADMIN':
      return {
        ...state,
        dataInsert_admin: [],
        loading_admin: false,
        error_basic_admin: action.payload,
      };

      case 'BASIC_DETAILS_UPDATE_ADMIN':
      return {
        ...state,
        dataUpdate_admin: action.payload,
        loading_admin: false,
        error_basic_admin: null,
      };
    case 'BASIC_DETAILS_UPDATE_ERROR_ADMIN':
      return {
        ...state,
        dataUpdate_admin: [],
        loading_admin: false,
        error_basic_admin: action.payload,
      };


      case 'INSERT_PHOTOS_ADMIN':
      return {
        ...state,
        picInsert_admin: action.payload,
        loading_admin: false,
        error_basic_admin: null,
      };
    case 'INSERT_PHOTOS_ERROR_ADMIN':
      return {
        ...state,
        picInsert_admin: [],
        loading_admin: false,
        error_basic_admin: action.payload,
      };


      case 'UPDATE_PHOTOS_ADMIN':
        return {
          ...state,
          picUpdate_admin: action.payload,
          loading_admin: false,
          error_basic_admin: null,
        };
      case 'UPDATE_PHOTOS_ERROR_ADMIN':
        return {
          ...state,
          picUpdate_admin: [],
          loading_admin: false,
          error_basic_admin: action.payload,
        };



    default:
      return state;
  }
};

export default reducer;
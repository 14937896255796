import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer/lifestyleReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const lifestyleContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  life_insert:[],
  life_update:[],
};

let API = `${BASE_URL}`;


export const LifestyleProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const fetchLifestyleItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/lifestyle_details_edit` , params);
      

      dispatch({ type: 'LIFESTYLE_DETAILS_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'LIFESTYLE_DETAILS_EDIT_ERROR', payload: error });
    }
  };


  const submit_lifestyledetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_physical_lifestyle` , article);
      dispatch({ type: 'LIFESTYLE_DETAILS_SUBMIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'LIFESTYLE_DETAILS_SUBMIT_ERROR', payload: error });
    }
  };


  const update_lifestyledetails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_physical_lifestyle` , article);
      dispatch({ type: 'LIFESTYLE_DETAILS_UPDATE', payload: response.data });
    } catch (error) {
      dispatch({ type: 'LIFESTYLE_DETAILS_UPDATE_ERROR', payload: error });
    }
  };



  useEffect(() => {
       
    fetchLifestyleItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <lifestyleContext.Provider value={{ ...state , dispatch , fetchLifestyleItems , submit_lifestyledetails , update_lifestyledetails}}>{children}</lifestyleContext.Provider>;
};

export const useLifestyleContext = () => useContext(lifestyleContext);
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, Grid, InputLabel, Modal, TextField } from "@mui/material";
import { DropdownSelect, StyledTextField } from "../../app_utils/input_fields";
import { gender, height, with_photo } from "../../data/data";
import { produce } from "immer";
import { generateYears } from "../../common_utils/utils";
import { COMMUNITY_ID, GENDER, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { useAdvancedSearchContext } from "../../contexts/advancedSearchContext";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";




function AdvancedSearch({passPath}) {

    const { data, loading, error , insertSavedSearch} = useAdvancedSearchContext();
    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();

    const [languages, setLanguages] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [degree, setDegreeList] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [rashi, setRashi] = useState([]);
    const [birth_star, setbirthstar] = useState([]);
    const [annual_income, setAnnualincome] = useState([]);

    const location = useLocation();




    const modalStyle = {
        position: 'absolute',
        width: 300,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid #e91e63', // Pink border
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.5)', // Shadow
        borderRadius: '8px',
        padding: '20px',
        outline: 'none',
      };
    
      const modalTitleStyle = {
        color: '#e91e63', // Pinkish color
        textAlign: 'center',
        marginBottom: '20px',
      };
    


    const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };



    const degree_lngwise = (degree) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const degree_list = degree.map(user => ({
                label: user.degree_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const degree_list = degree.map(user => ({
                label: user.degree_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const degree_list = degree.map(user => ({
                label: user.degree_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const degree_list = degree.map(user => ({
                label: user.degree_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const degree_list = degree.map(user => ({
                label: user.degree_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const degree_list = degree.map(user => ({
                label: user.degree_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setDegreeList(degree_list);
        }


    }

    const occupation_lngwise = (occupation) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const occupation_list = occupation.map(user => ({
                label: user.occupation,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const occupation_list = occupation.map(user => ({
                label: user.occup_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const occupation_list = occupation.map(user => ({
                label: user.occup_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const occupation_list = occupation.map(user => ({
                label: user.occup_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const occupation_list = occupation.map(user => ({
                label: user.occup_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const occupation_list = occupation.map(user => ({
                label: user.occup_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }));

            setOccupation(occupation_list);
        }


    }


    const navigate = useNavigate();
    const handleClickSearch = () =>{

        let age_from = advancedSearch.age_from == '' ? "0" : advancedSearch.age_from;
        let age_to   = advancedSearch.age_to == '' ? "0" : advancedSearch.age_to;
        let profile_id = advancedSearch.profileId.length == 0 ? "0" : advancedSearch.profileId;
        let gender = advancedSearch.gender == '' ? "0" : advancedSearch.gender;
        let name = advancedSearch.fullname.length == 0 ? "0" : advancedSearch.fullname;
        let fathername = advancedSearch.fathername.length == 0 ? "0" : advancedSearch.fathername;
        let height_from = advancedSearch.height_from == '' ? "0" : advancedSearch.height_from;
        let height_to = advancedSearch.height_to  == '' ? "0" : advancedSearch.height_to;
        let marital_status = advancedSearch.marital_status  == '' ? "0" : advancedSearch.marital_status;
        let mother_tongue = advancedSearch.mother_tongue  == '' ? "0" : advancedSearch.mother_tongue;
        let location = advancedSearch.location == '' ? "0" :  advancedSearch.location;
        let education_list = advancedSearch.education == '' ? "0" : advancedSearch.education;
        let occupation_list = advancedSearch.occupation == '' ? "0" : advancedSearch.occupation;
        let rashi = advancedSearch.rashi == '' ? "0" :advancedSearch.rashi;
        let birth_star = advancedSearch.birth_star == '' ? "0" : advancedSearch.birth_star;
        let min_income =  advancedSearch.min_income == '' ? "0" : advancedSearch.min_income;
        let max_income = advancedSearch.max_income == '' ? "0" : advancedSearch.max_income;
        let mangalik = advancedSearch.mangalik == '' ? "0" : advancedSearch.mangalik;
        
        navigate('/advanced_search_result', { state: { age_from: age_from, age_to: age_to , profile_id:profile_id , gender :gender , name:name , fathername:fathername , height_from:height_from , height_to:height_to
             , marital_status: marital_status, mother_tongue:mother_tongue , location:location , education_list:education_list , occupation_list:occupation_list,  rashi:rashi , birth_star:birth_star , min_income:min_income,
             max_income:max_income , mangalik:mangalik
             } });

          
    }


    const handleSubmitSaveSearch = () => {
        handleClose();

        let age_from = advancedSearch.age_from == '' ? "0" : advancedSearch.age_from;
        let age_to   = advancedSearch.age_to == '' ? "0" : advancedSearch.age_to;
        let profile_id = advancedSearch.profileId.length == 0 ? "0" : advancedSearch.profileId;
        let gender = advancedSearch.gender == '' ? "0" : advancedSearch.gender;
        let name = advancedSearch.fullname.length == 0 ? "0" : advancedSearch.fullname;
        let fathername = advancedSearch.fathername.length == 0 ? "0" : advancedSearch.fathername;
        let height_from = advancedSearch.height_from == '' ? "0" : advancedSearch.height_from;
        let height_to = advancedSearch.height_to  == '' ? "0" : advancedSearch.height_to;
        let marital_status = advancedSearch.marital_status  == '' ? "0" : advancedSearch.marital_status;
        let mother_tongue = advancedSearch.mother_tongue  == '' ? "0" : advancedSearch.mother_tongue;
        let location = advancedSearch.location == '' ? "0" :  advancedSearch.location;
        let education_list = advancedSearch.education == '' ? "0" : advancedSearch.education;
        let occupation_list = advancedSearch.occupation == '' ? "0" : advancedSearch.occupation;
        let rashi = advancedSearch.rashi == '' ? "0" :advancedSearch.rashi;
        let birth_star = advancedSearch.birth_star == '' ? "0" : advancedSearch.birth_star;
        let min_income =  advancedSearch.min_income == '' ? "0" : advancedSearch.min_income;
        let max_income = advancedSearch.max_income == '' ? "0" : advancedSearch.max_income;
        let mangalik = advancedSearch.mangalik == '' ? "0" : advancedSearch.mangalik;


        insertSavedSearch({
            "search_name":inputValue,
            "search_params":JSON.stringify({ age_from: age_from, age_to: age_to , profile_id:profile_id , gender :gender , name:name , fathername:fathername , height_from:height_from , height_to:height_to
                , marital_status: marital_status, mother_tongue:mother_tongue , location:location , education_list:education_list , occupation_list:occupation_list,  rashi:rashi , birth_star:birth_star , min_income:min_income,
                max_income:max_income , mangalik:mangalik
                }),
          "search_type":"advanced_search",
           "userId": localStorage.getItem(USER_ID),
           "communityId":localStorage.getItem(COMMUNITY_ID)
          });

        
        navigate('/advanced_search_result', { state: { age_from: age_from, age_to: age_to , profile_id:profile_id , gender :gender , name:name , fathername:fathername , height_from:height_from , height_to:height_to
             , marital_status: marital_status, mother_tongue:mother_tongue , location:location , education_list:education_list , occupation_list:occupation_list,  rashi:rashi , birth_star:birth_star , min_income:min_income,
             max_income:max_income , mangalik:mangalik
             } });

    }

    useEffect(() => {

        passPath(t('advanced_search'));
        setAnimated(true);
        if (data.length > 0) {


            const languages = Object.keys(data[0]).map(key => ({
                label: data[0][key].label,  // Adjust based on your data structure
                value: data[0][key].value         // Adjust based on your data structure
            }));

            const maritalStatus = Object.keys(data[1]).map(key => ({
                label: data[1][key].label,  // Adjust based on your data structure
                value: data[1][key].value         // Adjust based on your data structure
            }));


            const rashi = Object.keys(data[5]).map(key => ({
                label: data[5][key].label,  // Adjust based on your data structure
                value: data[5][key].value         // Adjust based on your data structure
            }));

            const birth_star = Object.keys(data[6]).map(key => ({
                label: data[6][key].label,  // Adjust based on your data structure
                value: data[6][key].value         // Adjust based on your data structure
            }));

            const annual_income = Object.keys(data[7]).map(key => ({
                label: data[7][key].label,  // Adjust based on your data structure
                value: data[7][key].value         // Adjust based on your data structure
            }));


            degree_lngwise(data[2].flat());
            occupation_lngwise(data[4].flat());


            setLanguages(languages);
            setMaritalStatus(maritalStatus);
            setRashi(rashi);
            setbirthstar(birth_star);
            setAnnualincome(annual_income);


            if(location.state != null){

                const {jsonData} = location.state;

            setAdvancedSearch({
                profileId: jsonData.profile_id,
                gender:  jsonData.gender,
                fullname: jsonData.name,
                fathername: jsonData.fathername,
                age_from:  jsonData.age_from,
                age_to: jsonData.age_to,
                height_from: jsonData.height_from,
                height_to: jsonData.height_to,
                mother_tongue: jsonData.mother_tongue,
                marital_status: jsonData.marital_status,
                location: jsonData.location,
                education: jsonData.education_list,
                occupation: jsonData.occupation_list,
                rashi:  jsonData.rashi,
                birth_star: jsonData.birth_star,
                min_income: jsonData.min_income,
                max_income: jsonData.max_income,
                mangalik: jsonData.mangalik
            });

        }


        }

    }, [data]);



    const [advancedSearch, setAdvancedSearch] = useState({
        profileId: '',
        gender: localStorage.getItem(GENDER).toLowerCase() == "male" ? "Female" : "Male",
        fullname: '',
        fathername: '',
        age_from: '16',
        age_to: '70',
        height_from: '4ft 0inch',
        height_to: '7ft 5inch',
        mother_tongue: '',
        marital_status: '',
        location: '',
        education: '',
        occupation: '',
        rashi: '',
        birth_star: '',
        min_income: '',
        max_income: "",
        mangalik: ""
    });

    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setAdvancedSearch(nextState);
    };

    return (
        <>
            <div style={{ display: 'grid', placeItems: 'center', marginTop:"230px" , width: "100%", paddingLeft: "25%", paddingRight: "25%" }}>

                <Grid container columnSpacing={1} xs={12} md={12}>

                    <Grid item xs={12} md={12}  >

                        <StyledTextField label={t('profileid_label')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('profileId')}
                            value={advancedSearch.profileId}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <DropdownSelect options={gender} label={t('gender')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('gender')}
                            value={advancedSearch.gender} />

                    </Grid>

                    <Grid item xs={12} md={6} style={{ marginTop: "15px" }}>

                        <StyledTextField label={t('name_l')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('fullname')}
                            value={advancedSearch.fullname}
                        />

                    </Grid>

                    <Grid item xs={12} md={12} style={{ marginTop: "15px" }}>

                        <StyledTextField label={t('father_name_l')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('fathername')}
                            value={advancedSearch.fathername}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={generateYears(16, 70)} label={t('from_age')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('age_from')}
                            value={advancedSearch.age_from} />

                    </Grid>

                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={generateYears(16, 70)} label={t('to_age')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('age_to')}
                            value={advancedSearch.age_to} />

                    </Grid>


                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={height} label={t('from_height')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('height_from')}
                            value={advancedSearch.height_from} />

                    </Grid>

                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={height} label={t('to_height')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('height_to')}
                            value={advancedSearch.height_to} />

                    </Grid>


                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={languages} label={t('mother_tongue_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('mother_tongue')}
                            value={advancedSearch.mother_tongue} />

                    </Grid>

                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={maritalStatus} label={t('marital_status_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('marital_status')}
                            value={advancedSearch.marital_status} />

                    </Grid>



                    <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>

                        <StyledTextField label={t('location')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('location')}
                            value={advancedSearch.location}
                        />

                    </Grid>

                    <Grid item xs={12} md={6} style={{ marginTop: "-5px" }}>

                        <DropdownSelect options={degree} label={t('education_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('education')}
                            value={advancedSearch.education} />

                    </Grid>

                    <Grid item xs={12} md={12} >

                        <DropdownSelect options={occupation} label={t('occuaption_p_l')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('fullname')}
                            value={advancedSearch.fullname}
                        />

                    </Grid>


                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={rashi} label={t('rashi_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('rashi')}
                            value={advancedSearch.rashi} />

                    </Grid>


                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={birth_star} label={t('birth_star_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('birth_star')}
                            value={advancedSearch.birth_star} />

                    </Grid>



                    <Grid item xs={12} md={6} style={{ marginTop: "5px" }}>

                        <StyledTextField label={t('min_income')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('min_income')}
                            value={advancedSearch.min_income}
                        />

                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginTop: "5px" }}>

                        <StyledTextField label={t('max_income')} focusedcolor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                            onChange={handleChange('max_income')}
                            value={advancedSearch.max_income}
                        />

                    </Grid>


                    <Grid item xs={12} md={2.5}></Grid>
                    <Grid item xs={12} md={3.5} style={{marginLeft:"-10px" , marginTop:"10px" ,marginBottom:"30px"}}>
                <Button style={{
                 marginTop:"10px",
                background: "#E3256B", width: "200px",padding:"10px"  , color: "white", fontWeight: "bold"
            }} onClick={handleClickSearch}>Search</Button>
                </Grid>

                <Grid item xs={12} md={3.5} style={{marginRight:"-10px" , marginTop:"10px" , marginBottom:"30px"}}>
                <Button style={{
                 marginTop:"10px",
                background: "#E3256B", width: "200px",padding:"10px"  , color: "white", fontWeight: "bold"
            }} onClick={handleOpen}>Save & Search</Button>
                </Grid>
                <Grid item xs={12} md={2.5}></Grid>
                    

                </Grid>

                


                <Modal open={open} onClose={handleClose} >
        <div style={modalStyle}>
          <h2 style={modalTitleStyle}>Save And Search</h2>
          <TextField
            label="Enter Your Save & Search Title"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button onClick={handleSubmitSaveSearch} variant="contained" color="primary" style={{ marginTop: 16 }}>
            Save & Search
          </Button>
        </div>
      </Modal>

            </div>
        </>
    );



}

export default AdvancedSearch;
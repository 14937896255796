import React ,{useState , useEffect}  from "react";
import {
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";

import { about } from "../data/link_menu";
import styles from "../styles/link_menu.module.css";
import { useTranslation } from 'react-i18next';
import i18n from "../translations/i18n";
import "../translations/en.json";
import DrawerComponentLogin from "./DrawerLogin";




/*const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
    

  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));*/







function Navbar({passPath}) {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('es');
  }, []);



 /* const theme2 = createTheme({
    typography: {
      poster: {
        fontSize: '4rem',
        color: 'red',
      },
      // Disable h3 variant
      h3: undefined,
    },
  });


  const roundedGradientStyleWithborder = {
    display: 'inline-block',
    borderRadius: '1px',
    color:  'red' ,
    transition: 'background 0.3s ease, border 0.3s ease',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif', 
    fontWeight: 'bold', // Set font weight to bold
    textDecoration: 'none',// Use Roboto font
    marginTop:'15px',
    borderTop:"2px solid red"
  };


  const menuItemStyle = {
    // Add your inline styles for MenuItem
    backgroundColor: 'pink'  ,
    color:"white",
    '&:hover': {
      backgroundColor: '#1976d2', // Change background color on hover
      color: '#ffffff', // Change text color on hover
    },
  };

  const roundedGradientStyle = {
    display: 'inline-block',
    padding: '5px 25px',
    color: '#ffffff',
    borderRadius: '30px',
    transition: 'background 0.3s ease, border 0.3s ease',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif', 
    fontWeight: 'bold', // Set font weight to bold
    textDecoration: 'none'// Use Roboto font
  };

  const handleHover = (event) => {
    event.target.style.border = '2px solid white'; // Change border color on hover
    event.target.style.background = 'linear-gradient(45deg,  #D74894 , #FFb6c1)'; // Change background on hover
  };

  const handleLeave = (event) => {
    event.target.style.border = '2px solid transparent'; // Revert to transparent border on leave
    event.target.style.background = 'transparent'; // Revert background on leave
  };


  */

  const roundedGradientStyle = {
    display: 'inline-block',
    padding: '5px 10px',
    color: '#ffffff',
    borderRadius: '30px',
    transition: 'background 0.3s ease, border 0.3s ease',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif', 
    fontWeight: 'bold', // Set font weight to bold
    textDecoration: 'none',
    zIndex:'2',
    marginLeft:"35px"
  };

  const [isDropdownVisible, setDropdownVisibility] = useState(true);
  const [isMenuVisible, setMenuVisibility] = useState(false);


  const handleHover = (event) => {

    event.target.style.border = '2px solid var(--menu-border-colour)'; // Change border color on hover
    event.target.style.background = 'linear-gradient(45deg,  var(--menu-gradient-colour1) , var(--menu-gradient-colour2))';
     // Change background on hover
  };

  const handleLeave = (event) => {

    event.target.style.border = '2px solid transparent'; // Revert to transparent border on leave
    event.target.style.background = 'transparent';
     // Revert background on leave
  };


  const handleClickLink = (path) => {

   passPath(path);
    
  };



  

  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event ,path) => {
    setAnchorEl(event.currentTarget);
    passPath(path);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let items_home = [];

  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const MAX_WIDTH_FOR_MOBILE = 900;
  
var buttonIsVisible = window.visualViewport.width < window.Width;


  return (
    
    width > MAX_WIDTH_FOR_MOBILE ?
          <div id="main-div" style={{
            backgroundColor: 'var(--menu-container-colour)'  , boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            width: buttonIsVisible ? "100%" : "100%",
            height:"65px",
            justifyContent:"right",
            alignItems:"center",
            position:"fixed",
            textAlign:"center",
            top:"0",
            zIndex:"4",
            }}>

<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/home"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        onClick={() =>handleClickLink("home")}
        
      >
        {t('home')}
      </Link>
</div>
<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        
      >
        {t('about')}
      </Link>
      {isDropdownVisible ? (
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
            about.forEach((s,i) => {

               if(i <= about.length-2){
                items_home.push(<><Link exact to={s[1]} onClick={() => handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                <Divider /></>);
               }else{
                items_home.push(<><Link exact to={s[1]} onClick={() => handleClickLink(t(s[0]))}><span >{t(s[0])}</span></Link>
                </>);
               }
            
              
            })
          }
          {items_home}
        </div>
      ): ''}
</div>
<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/search"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        onClick={() => handleClickLink(t('search'))}
      >
        {t('search')}
      </Link>
</div>

<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/contact"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        onClick={() => handleClickLink(t('contact_us'))}
      >
        {t('contact_us')}
      </Link>
</div>

<div className={styles.dropdown_container} onMouseLeave={handleLeave}>
<Link
        to="/test"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
        
      >
        {t('download_app')}
      </Link>
</div>

<div style={{marginLeft:"10%" , background: 'var(--menu-container-right-colour)' , padding: '19px'}}>


    <Link
        to="/register"
        style={{ fontFamily: 'Roboto, sans-serif', 
        fontWeight: 'bold', textDecoration: 'none', display: 'inline-block',
        padding: '5px 20px',
        color: '#ffffff' }}
        onClick={() => handleClickLink("register")}>
        {t('register')}
        
      </Link>

       <Link style={{position:"absolute" , width:"5px" , height:"30px"  , backgroundColor:"white" , right:"10"}}></Link>
    

      <Link
        to="/login"
        style={roundedGradientStyle}
        onClick={() => handleClickLink("login")}
      >
        {t('login')}
      </Link>
      
      </div>  

   

    </div> : <DrawerComponent/>

         
  );



 



}
export default Navbar;
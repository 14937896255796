import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../../reducer_admin/lifestyleReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const lifestyleAdminContext = createContext();

const initialState = {
  data_lifestyle_admin: [],
  loading: true,
  error: null,
  life_insert_admin:[],
  life_update_admin:[],
};

let API = `${BASE_URL}`;


export const LifestyleProviderAdmin = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const fetchLifestyleItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/lifestyle_details_edit` , params);
      

      dispatch({ type: 'LIFESTYLE_DETAILS_EDIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'LIFESTYLE_DETAILS_EDIT_ERROR_ADMIN', payload: error });
    }
  };


  const submit_lifestyledetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_physical_lifestyle` , article);
      dispatch({ type: 'LIFESTYLE_DETAILS_SUBMIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'LIFESTYLE_DETAILS_SUBMIT_ERROR_ADMIN', payload: error });
    }
  };


  const update_lifestyledetailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_physical_lifestyle` , article);
      dispatch({ type: 'LIFESTYLE_DETAILS_UPDATE_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'LIFESTYLE_DETAILS_UPDATE_ERROR_ADMIN', payload: error });
    }
  };





 
  
  return <lifestyleAdminContext.Provider value={{ ...state , dispatch , fetchLifestyleItemsAdmin , submit_lifestyledetailsAdmin , update_lifestyledetailsAdmin}}>{children}</lifestyleAdminContext.Provider>;
};

export const useLifestyleAdminContext = () => useContext(lifestyleAdminContext);
import React, { useEffect, useState } from "react";
import styles  from "../../styles/happystory.module.css";
import { InputLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";



const Happystory = ({items_main}) => {

     const[ishidden , setishidden] = useState("");
     const { t } = useTranslation();
     const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const widthcheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const MAX_WIDTH_FOR_MOBILE = 900;

     useEffect(() =>{

      if(Object.values(items_main[5][0]).length > 0){
        setishidden(Object.values(items_main[5][0])[0].ishidden);
       }
  
     }, []);
    
      

    return(

        <React.Fragment>
        <div className="happy-story">
      { ishidden == "0" ? <div style={{  color: "#494949" , marginTop:"25px" }}>
     <InputLabel style={{backgroundColor:"#E3256B" , color:"white"  , textAlign:"center" , justifyContent:"center" ,alignContent:"center" ,alignItems:"center" , fontWeight:"bold" , fontSize:"25px" , marginLeft:widthcheck <= MAX_WIDTH_FOR_MOBILE ? "5%" : "33%" , marginRight:widthcheck <= MAX_WIDTH_FOR_MOBILE ? "5%" :"33%"  , borderRadius:"20px" , "fontFamily":"Dosis"}}>{t('happy_stories')}</InputLabel>


        <div className={styles.main} style={{marginTop:"30px"}}>
         <ul className={styles.cards}>

        { items_main[0].length > 0 ? items_main[5][0] && Object.values(items_main[5][0]).map((item, index) => (



        <li className={styles.cards_item} key={index}>
      <div className={styles.card}>
        <div className={styles.card_image}>
          <img src={item.image}  />
          
        </div>
        <div className={styles.card_content}>
          <h3 className={styles.card_title}>{item.husband_name+" & "+item.wife_name}</h3>
          <div className={styles.card_text}>
            <p>
                {"Marriage Date : "+ item.marriage_date}<br/>
                <Typography variant="body1" component="div" >
        {showFullText ? item.details : item.details.slice(0, 80)}
        {item.details.length > 50 && (
          <span onClick={toggleText} style={{ cursor: 'pointer', color: '#3498db' }} key={index}>
            {showFullText ? ' Read Less' : ' Read More'}
          </span>
        )}
      </Typography>
                
            </p>
            <a href="" className={styles.read_more_button}>Read More</a>
            <hr />

          </div>
        </div>
      </div>
    </li>

        )) : [] }

    </ul>
    </div>
    </div> : <div></div>

        }
        
        </div>
        </React.Fragment>

    );



};

export default Happystory;
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";


function ViewDetailsMembership({isopen , setClose, Details}){

     
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }




    useEffect(()=>{
    

        setOpen(isopen);
       
    },[]);

    return (<>

        <Dialog open={open} onClose={handleClose} maxWidth="lg">
              <DialogTitle>Details of Plan {Details.plan_name}</DialogTitle>
              <DialogContent>
        
              
              <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', marginBottom: '20px', border: '1px solid #ccc' }}>
      <Typography variant="h6" style={{ color: '#333', borderBottom: '2px solid #AA336A', paddingBottom: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Details</Typography>
      <div>
        <div style={{ marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Plan Name: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.plan_name}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Plan Price: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.plan_price}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Validity Days: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.validity_days}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Profile Display: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.profile_display}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Gallery View: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.gallery_view}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Chat Option: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.chatoption}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Express Interset Number: <span style={{ fontWeight: 'bold', color: '#000' }}>{"Number of Express Interest "+Details.number_express_interest}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>HoroscopeView: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.horoscopeview}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Pdf Access: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.pdf_access}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Number of Pdf Access: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.num_pdf_access}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Verified Phone numbers: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.verified_phonenumbers}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Allowed Contacts: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.allowed_contacts}</span></Typography>
       
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Number Contacts: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.number_contacts}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Number of Horoscope: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.number_horoscope}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Number of Chat: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.number_chat}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Number of Video: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.number_video}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Verified ID Docs: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.verified_id_docs}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Discount Type: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.discount_type}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Discount: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.discount}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Coupon Code: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.coupon_code}</span></Typography>
        </div>
      </div>
    </div>


   </DialogContent>
                    <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
        
                </>
                
              );




}

export default ViewDetailsMembership;
import { Button, Card, CardMedia, Grid, Skeleton } from "@mui/material";
import { useProofsContext } from "../../contexts/proofs_context";
import { useDropzone } from 'react-dropzone';
import { useCallback } from "react";
import React , { useState } from "react";
import ImageCropperScreen from "../../app_utils/ImageCropper";
import { COMMUNITY_ID, IMAGE_URL, PROFILE_ID, USER_ID } from "../../common_utils/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";




const DocumentsUpload = ({passPath}) => {


  const dropzoneStyle = {
    border: '2px dashed #d9d9d9',
    borderRadius: '4px',
    padding: '5px',
    textAlign: 'center',
    cursor: 'pointer',
  };


  const { t } = useTranslation();
  const { fetchProofsList, data_proofs_list, insert_proofs, insert_proofs_list, update_proofs, update_proofs_list } = useProofsContext();

  const [Idproof, setIdProof] = useState("-");
  const [Eduproof, setEduProof] = useState("--");
  const [Salaryproof, setSalaryProof] = useState("---");
  const [open, setopen] = useState(false);
  const [index, setIndex] = useState(-1);
  const [isupdate ,setUpdate] = useState(-1);

  const [arrayImages, setArrayImages] = useState(["", "", ""]);


  const dropzone1 = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setIdProof(acceptedFiles[0]);

      setArrayImages((prevarray) => {
        const newArray = [...prevarray];
        newArray[0] = acceptedFiles[0];

        return newArray;
      });
    },
  });

  const dropzone2 = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    onDrop: (acceptedFiles1) => {
      setEduProof(acceptedFiles1[0]);

      setArrayImages((prevarray) => {
        const newArray = [...prevarray];
        newArray[1] = acceptedFiles1[0];

        return newArray;
      });

    },
  });

  const dropzone3 = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    onDrop: (acceptedFiles2) => {
      setSalaryProof(acceptedFiles2[0]);


      setArrayImages((prevarray) => {
        const newArray = [...prevarray];
        newArray[2] = acceptedFiles2[0];

        return newArray;
      });

    },
  });


  const handlecrop = () => {
    setIndex(0);
    setopen(true);


  };


  const handlecrop2 = () => {
    setIndex(1);
    setopen(true);

  };


  const handlecrop3 = () => {
    setIndex(2);
    setopen(true);

  };


  const onClose = () => {
    setopen(false);

  };


  const convertTofile = (base64Image, indexcropper) => {
    convertBase64ToFile(base64Image);
    setIndex(indexcropper);

  };

  useEffect(()=>{

    passPath(t('upload_documents'));

  },[]);


  useEffect(() => {



    if (data_proofs_list.length > 0) {

      if (data_proofs_list[0].flat()[0].id_proofs != null) { downloadFile(data_proofs_list[0].flat()[0].id_proofs, 0); }
      if (data_proofs_list[0].flat()[0].education_proof != null) { downloadFile(data_proofs_list[0].flat()[0].education_proof, 1); }
      if (data_proofs_list[0].flat()[0].income_proof != null) { downloadFile(data_proofs_list[0].flat()[0].income_proof, 2); }

    }

  }, [data_proofs_list]);



  const downloadFile = async (element, index) => {

    try {
      const response = await fetch(IMAGE_URL + "/uploads/" + element);
      const blob = await response.blob();
      const file = new File([blob], element);

      //setFiles(prevFiles => [...prevFiles, file]);

      if(index == 0){
        setIdProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[0] = file;

          return newArray;
        });

      }else if(index ==1){
        setEduProof(file);
        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[1] = file;

          return newArray;
        });

      }else{

        setSalaryProof(file);
        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[2] = file;

          return newArray;
        });

      }
     

    } catch (error) {
      console.error('Error fetching or creating file:', error);
    } 

  };




  const convertBase64ToFile = (base64Image) => {
    if (base64Image) {
      // Extract the base64 data (remove the data:image/png;base64, prefix)
      const base64Data = base64Image.split(',')[1];

      // Convert base64 to a Blob
      const blob = atob(base64Data);
      const arrayBuffer = new ArrayBuffer(blob.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < blob.length; i++) {
        uint8Array[i] = blob.charCodeAt(i);
      }

      // Create a File from the Blob
      const file = new File([arrayBuffer], 'image' + index + '.png', { type: 'image/png' });

      if (index == 0) {
        setIdProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[0] = file;

          return newArray;
        });

      } else if (index == 1) {
        setEduProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[1] = file;

          return newArray;
        });

      } else {
        setSalaryProof(file);

        setArrayImages((prevarray) => {
          const newArray = [...prevarray];
          newArray[2] = file;

          return newArray;
        });
      }

      // Now 'file' is a File object that you can use or upload as needed
      console.log('File object:', file);
    }
  };




  useEffect(() => {

    if(isupdate == 1){
      
          if(insert_proofs_list.affectedRows >= 1){
            alert("Successfully Uploaded");
          }else{
            alert("Error Uploading! Please try again");
          }

    }else if(isupdate == 2){

      if( update_proofs_list.success == 1){
        alert("update Successfully Uploaded");
      }else{
        alert("Error Uploading! Please try again");
      }

    }


 }, [insert_proofs_list , update_proofs_list]);



  const uploadFiles = () => {



    const formData = new FormData();

    arrayImages.forEach((file, index) => {
      formData.append('sampleFile[]', file);
    });

    if(arrayImages[0].name != undefined){

    if(arrayImages[0].name.includes("_")){
      formData.append("id_proof", localStorage.getItem(PROFILE_ID) + "_" + arrayImages[0].name.split("_")[1]);
    }else if(!arrayImages[0].name.includes("_")){
      formData.append("id_proof", localStorage.getItem(PROFILE_ID) + "_" + arrayImages[0].name);
    }
  }else{

    formData.append("id_proof", "");

  }
    
  if(arrayImages[1].name != undefined){

    if(arrayImages[1].name.includes("_")){
    formData.append("education_proof", localStorage.getItem(PROFILE_ID) + "_" + arrayImages[1].name.split("_")[1]);
    }else if(!arrayImages[1].name.includes("_")){
      formData.append("education_proof", localStorage.getItem(PROFILE_ID) + "_" + arrayImages[1].name);
    }
  } else{

    formData.append("education_proof", "");
  }

  if(arrayImages[2].name != undefined){

    if(arrayImages[2].name.includes("_")){
    formData.append("income_proof", localStorage.getItem(PROFILE_ID) + "_" + arrayImages[2].name.split("_")[1]);
    }else if(!arrayImages[2].name.includes("_")){
      formData.append("income_proof", localStorage.getItem(PROFILE_ID) + "_" + arrayImages[2].name);
    }
    
  }else{

    formData.append("income_proof", "");

  }


    formData.append("is_id_verify", "0");
    formData.append("is_education_verify", "0");
    formData.append("is_income_verify", "0");

    formData.append("userId", localStorage.getItem(USER_ID));
    formData.append("communityId", localStorage.getItem(COMMUNITY_ID));
    formData.append("profileId", localStorage.getItem(PROFILE_ID));

    if (data_proofs_list.length <= 0) {

      setUpdate(1);
      insert_proofs(formData);
    } else {

      setUpdate(2);
      formData.append("Id", data_proofs_list[0].flat()[0].Id);
      update_proofs(formData);

    }

   

  };



  return (<React.Fragment>

    <Grid container spacing={3} width={"1300px"} style={{ justifyContent: "center", width: "100%", marginTop:"230px" , marginBottom:"60px"}}>

      <Skeleton variant="rectangular" height={220} animation="wave" />
      <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
        <Card>


          {Idproof == "-" ? <CardMedia component="img" height="250" alt={`Image ${1}`} style={{ objectFit: "contain" }} /> : <CardMedia component="img" height="220" image={URL.createObjectURL(Idproof)} alt={`Image ${1}`} style={{ objectFit: "contain" }} />}


          <Button key={11} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9"
          }} onClick={handlecrop}  >Crop</Button>

          <div {...dropzone1.getRootProps()} style={dropzoneStyle}>
            <input {...dropzone1.getInputProps()} />
            <p>Upload Your ID Proof</p>
          </div>
          {data_proofs_list.length > 0 ? <b style={{border:"1px solid palevioletred" , width:"100%"}}>{data_proofs_list[0][0]["is_id_verify"] == "0" ? "Verification Going on" :  data_proofs_list[0][0]["is_id_verify"] == "1" ?  "Verified" :  data_proofs_list[0][0]["is_id_verify"] == "2" ?  "Rejected" : "" }</b> : "" }

        </Card>
      </Grid>



      <Skeleton variant="rectangular" height={220} animation="wave" />
      <Grid item xs={12} sm={6} md={4} lg={3} key={2}>
        <Card>


          {Eduproof == "--" ? <CardMedia component="img" height="250" alt={`Image ${1}`} style={{ objectFit: "contain" }} /> : <CardMedia component="img" height="220" image={URL.createObjectURL(Eduproof)} alt={`Image ${1}`} style={{ objectFit: "contain" }} />}


          <Button key={21} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9"
          }} onClick={handlecrop2}  >Crop</Button>

          <div {...dropzone2.getRootProps()} style={dropzoneStyle}>
            <input {...dropzone2.getInputProps()} />
            <p>Upload Your Education Proof</p>
          </div>
          {data_proofs_list.length > 0 ? <b style={{border:"1px solid palevioletred" , width:"100%"}}>{data_proofs_list[0][0]["is_education_verify"] == "0" ? "Verification Going on" :  data_proofs_list[0][0]["is_education_verify"] == "1" ?  "Verified" :  data_proofs_list[0][0]["is_education_verify"] == "2" ?  "Rejected" : "" }</b> : "" }


        </Card>
      </Grid>



      <Skeleton variant="rectangular" height={220} animation="wave" />
      <Grid item xs={12} sm={6} md={4} lg={3} key={3}>
        <Card>


          {Salaryproof == "---" ? <CardMedia component="img" height="250" style={{ objectFit: "contain" }} /> : <CardMedia component="img" height="220" image={URL.createObjectURL(Salaryproof)} alt={`Image ${1}`} style={{ objectFit: "contain" }} />}


          <Button key={31} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "100%", opacity: "0.9"
          }} onClick={handlecrop3}   >Crop</Button>

          <div {...dropzone3.getRootProps()} style={dropzoneStyle}>
            <input {...dropzone3.getInputProps()} />
            <p>Upload Your Income Proof</p>
          </div>
          {data_proofs_list.length > 0 ? <b style={{border:"1px solid palevioletred" , width:"100%"}}>{data_proofs_list[0][0]["is_income_verify"] == "0" ? "Verification Going on" :  data_proofs_list[0][0]["is_income_verify"] == "1" ?  "Verified" :  data_proofs_list[0][0]["is_income_verify"] == "2" ?  "Rejected" : "" }</b> : "" }

        </Card>
      </Grid>

      <Button key={32} style={{
            color: "palevioletred", justifyContent: "center", backgroundColor: "antiquewhite", textAlign: "center", fontWeight: "bold",
            fontSize: "20px", width: "60%", opacity: "0.9" , marginTop:"50px" , marginLeft:"auto" , marginRight:"auto" 
          }} onClick={uploadFiles}   >Upload Files</Button>


    </Grid>

    {open == true && index == 0 ? <ImageCropperScreen image={[Idproof]} openPass={open} indexcropper={index} close={onClose} passBase64={convertTofile} /> : open == true && index == 1 ? <ImageCropperScreen image={[Eduproof]} openPass={open} indexcropper={0} close={onClose} passBase64={convertTofile} /> : open == true && index == 2 ? <ImageCropperScreen image={[Salaryproof]} openPass={open} indexcropper={0} close={onClose} passBase64={convertTofile} /> : ""}


  </React.Fragment>);

}

export default DocumentsUpload;
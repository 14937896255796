
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, Modal, IconButton } from "@mui/material";
import { ADMIN_COMMUNITY_ID, ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SwitchLeft from '@mui/icons-material/ToggleOffRounded';
import SwitchRight from '@mui/icons-material/ToggleOn';
import { useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';






function Religion(){
      
  
    const {religion_fetch , religion_insert  , religion_update , data_insert_religion , list_religion_admin , data_update_religion ,delete_master_data , caste_fetch ,list_caste_admin ,clearListCasteAdmin} = useMasterContextAdmin();

    const [Id, setId] = useState('');
    const [religion, setReligion] = useState('');
    const [religion_display, setReligionUpdate] = useState('');
    const [religion_id, setReligionId] = useState('');

    const [open , setopen] = useState(false);
    const [communityId, setCommunityId] = useState(1);

    const [isdispaly , setdisplay] = useState(false);


    useEffect(()=>{
      religion_fetch();

    },[data_insert_religion , data_update_religion , list_caste_admin]);

    useEffect(()=>{
       if(list_caste_admin[0] != undefined){

        if(list_caste_admin[0].length == 0){
          delete_master_data({ "type":"religion"  , "is_enabled":"1" , "Id":Id});

          setTimeout(()=>{

            religion_fetch();

          },500);

        }else if(list_caste_admin[0].length > 0 && isdispaly == true){

          setdisplay(false);
          alert("You can not delete this religion as it has entry in caste table");
        }
       
       
       }
       

    },[list_caste_admin]);


    const handleReligionChange = (event) => {
    setReligion(event.target.value);
  };


  const handleReligionDisplayChange = (event) => {
    setReligionUpdate(event.target.value);
  };


  const handleAddReligion = () => {
    if (religion.trim() !== '') {
      religion_insert({'religion_name':religion})
    }
  };


  const handleUpdateReligion = () => {
    if (religion_display.trim() !== '') {
      religion_update({'religion_name':religion_display , 'Id' :religion_id})
      setopen(false);
    }
  };


  const handleEditClick = (religion , Id) => {
    setopen(true);
    setReligionUpdate(religion);
    setReligionId(Id);
  }


  const handleDeleteClick = (is_enabled , Id) => {

    setId(Id);

    setTimeout(() => {
      let communityId_admin  =  "0";

      if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
  
        communityId_admin = localStorage.getItem("communityId_admin");
        setCommunityId(communityId_admin);
  
        
      }else{
       communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
        setCommunityId(communityId_admin);
      }
  
      setdisplay(true);

      caste_fetch({
        "religion_id": Id,
        "communityId": communityId_admin,
        "offset":"0"
      });

      } , 500);

   

    //
  }

  const handleClose = () => {
     setopen(false);
  };

  let role = localStorage.getItem(ROLE);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

<Grid  item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Religion Name</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_religion_admin.map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.religion_name}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.religion_name , religion.Id)}>
        <EditIcon />
      </IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => handleDeleteClick(religion.enabled == "1" ? "0" : "1" , religion.Id)}>{ <DeleteIcon/>}</IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

</Grid>



<Grid  item xs={5}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>

<Grid item xs={12}>
      <TextField
        label="Enter Religion"
        variant="outlined"
        value={religion}
        fullWidth
        onChange={handleReligionChange}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddReligion}>
        Add
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>







          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Religion"
            variant="outlined"
            value={religion_display}
            fullWidth
            onChange={handleReligionDisplayChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateReligion}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>


        </div>
      );


}
export default Religion;
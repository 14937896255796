import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import contactReducer from '../reducer/contactReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const contactContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  contactInsert:[],
  contactUpdate:[],
  data_city_perm:[],
  data_city_work:[]
};

let API = `${BASE_URL}`;


export const ContactProvider = ({ children }) => {

const [state, dispatch] = useReducer(contactReducer , initialState);

const fetchContactItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/contact_details_edit` , params);
      

      dispatch({ type: 'CONTACT_DATA_FETCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_DATA_FETCH_ERROR', payload: error });
    }
  };


  const submit_contactdetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/contact_detail` , article);
      dispatch({ type: 'CONTACT_DETAILS_SUBMIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_DETAILS_SUBMIT_ERROR', payload: error });
    }
  };


  const update_contactdetails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/contact_detail` , article);
      dispatch({ type: 'CONTACT_DETAILS_UPDATE', payload: response.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_DETAILS_UPDATE_ERROR', payload: error });
    }
  };


  const fetch_city = async (passdata , type) => {
    try {
      if(type == 1){

        const response = await axios.post(`${API}`+`fetch_data/city_fetch` , passdata);
      dispatch({ type: 'CITY_FETCH_PERM', payload: response.data.data });

      }else{
        const response = await axios.post(`${API}`+`fetch_data/city_fetch` , passdata);
        dispatch({ type: 'CITY_FETCH_WORK', payload: response.data.data });
      }
      
    } catch (error) {
      dispatch({ type: 'CONTACT_DETAILS_UPDATE_ERROR', payload: error });
    }
  };


  useEffect(() => {
       
    fetchContactItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <contactContext.Provider value={{ ...state , dispatch , fetchContactItems , submit_contactdetails , update_contactdetails , fetch_city}}>{children}</contactContext.Provider>;
};

export const useContactContext = () => useContext(contactContext);
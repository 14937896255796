const reducer = (state, action) => {
    switch (action.type) {
      case 'LIFESTYLE_DETAILS_EDIT_ADMIN':
        return {
          ...state,
          data_lifestyle_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'LIFESTYLE_DETAILS_EDIT_ERROR_ADMIN':
        return {
          ...state,
          data_lifestyle_admin: [],
          loading: false,
          error: action.payload,
        };
        case 'LIFESTYLE_DETAILS_SUBMIT_ADMIN':
        return {
          ...state,
          life_insert_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'LIFESTYLE_DETAILS_SUBMIT_ERROR_ADMIN':
        return {
          ...state,
          life_insert_admin: [],
          loading: false,
          error: action.payload,
        };
        case 'LIFESTYLE_DETAILS_UPDATE_ADMIN':
        return {
          ...state,
          life_update_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'LIFESTYLE_DETAILS_UPDATE_ERROR_ADMIN':
        return {
          ...state,
          life_update_admin: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducer;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/login_reducer';
import { BASE_URL } from '../../common_utils/constants';

const loginContext = createContext();

const initialState = {
  loading_login_admin: true,
  error: null,
  login_status:-1,
  data_login_admin: []
};

let API = `${BASE_URL}`;


export const AdminLoginProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const login = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/login_adminpanel` , article);
      

      dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'LOGIN_ERROR', payload: error });
    }
  };

  const loginremove = () => {
    dispatch({ type: 'LOGIN_ADMIN_PANEL_REMOVE'});
  }


  
  return <loginContext.Provider value={{ ...state , dispatch , login , loginremove}}>{children}</loginContext.Provider>;
};

export const useAdminLoginContext = () => useContext(loginContext);
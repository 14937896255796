import { Button, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import styles from "../styles/login.module.css";

import React , { useState } from "react";
import { StyledTextField } from "../app_utils/input_fields";
import {produce} from 'immer';
import { Link } from "react-router-dom";

import {isValidEmail, login_validation} from "../common_utils/validations";
import axios from "axios";
import  constants, { GENDER }  from "../common_utils/constants";
import "../styles/animationfade.css";
import { CSSTransition } from "react-transition-group";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ModalPopup from "../app_utils/modalpopup";
import { useNavigate } from "react-router-dom";
import {USER_ID , BIRTH_DATE , COMMUNITY_ID , EMAIL_ID , JOINED_DATE , MOBILE_NO , NAME , PROFILE_ID ,IS_LOGIN} from "../common_utils/constants";
import { useMyContext } from "../contexts/registerContext";
import RotatingHeart from "../common_utils/loading_animator";
import MsgConfirm from "../app_utils/MsgConfirm";
import { setMessages } from "validatorjs";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4';
import { margin } from "@mui/system";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';




const Login = ({passPath}) => {


  const { param } = useParams();
  const { t } = useTranslation();

  const {login , data_login , login_status , loading , error } = useMyContext();
 

  const [clicked , setclick] = useState(false);
  const [validate , setvalidate] = useState(false);
  const [validationtext , setValidationtext] = useState("");

  const [msg, setMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const  handlefromchild = () => {
      setvalidate(false);
  }


  const handleclosemsg = () => {
    setMsg(false);
    window.location.href = "/";
  }


  const navigate = useNavigate();


  const [textLogin, setFieldsLogin] = useState({
  email_phone:"",
  password:""
  });

  const [isAnimated, setAnimated] = useState(false);

  useEffect(() => {

    ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search);
       
    passPath("login");
  
    setAnimated(true);

    if(login_status == "1"){
     
       if(data_login.delete_account == "1"){

        setMsg(true);
        setTitle("Delete Account Alert!");
        setContent("Your Account is deleted hence you cant login");



       }else{


        window.dataLayer.push({
          'user_id': 'USER_ID'
        });


        alert(data_login.community_id);
       
       localStorage.setItem(USER_ID , data_login.Id);
       localStorage.setItem(BIRTH_DATE , data_login.birthdate);
       localStorage.setItem(COMMUNITY_ID , data_login.community_id);
       localStorage.setItem(EMAIL_ID , data_login.emailid);
       localStorage.setItem(JOINED_DATE  , data_login.joined_date);
       localStorage.setItem(MOBILE_NO  , data_login.mobile);
       localStorage.setItem(NAME  , data_login.name+" "+data_login.surname);
       localStorage.setItem(PROFILE_ID , data_login.profile_id);
       localStorage.setItem(GENDER , data_login.gender);
       localStorage.setItem(IS_LOGIN , "1");

        ReactGA.set({ user_id: 789});

       
        window.location.href = '/all_details';
       }
      
                
    }
    
  }, [login_status]);



  const handleChange = (fieldName) => (event) => {
    const newValue = event.target.value;

    // Use immer to update the state based on the current state
    const nextState = produce((draft) => {
      draft[fieldName] = newValue;
    });

    // Set the updated state
    setFieldsLogin(nextState);
  };




  const onClick = () =>{


    ReactGA.event({
      category: 'Login',
      action: 'Clicked Button',
      label: 'Login Button ',
    });


    let validationlist = login_validation(textLogin.email_phone ,textLogin.password);
     
    const htmlContent = { __html: validationlist.join("")};

    if(validationlist.length > 0){

        setValidationtext(htmlContent);
        setvalidate(true);

    }else{

    const input = {
        "emailid":textLogin.email_phone,
        "mobile":textLogin.email_phone ,
        "password":textLogin.password
    };
    

   setclick(true);
   login(input);

   

}

  
    

  };


  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

   const widthcheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const MAX_WIDTH_FOR_MOBILE = 900;

return(

    <>

{clicked == true && loading == true ?  <RotatingHeart/> : 

<CSSTransition
      in={isAnimated}
      timeout={500} // Duration of the transition in milliseconds
      classNames="fade"
      unmountOnExit>

<Grid container className={styles.section}>
        <div  className={styles.cardsection}>
          <Grid container
   justifyContent="center" direction="column" alignItems={"center"} style={{ minHeight: '50vh' }}>


            <h1 style={{textAlign:"center"}}>{t('login_community')}</h1>
     
            <Grid item xs={12} style={{  marginLeft : widthcheck <= MAX_WIDTH_FOR_MOBILE ? "0px" : "0px"}}>
            <StyledTextField  label={t('enter_emailid')} variant="outlined" focusedcolor={"#E3256B"}
            bordercolor={"#D74894"}  hovercolor={"#E3256B"}  width={widthcheck <= MAX_WIDTH_FOR_MOBILE ? 200  : 400 } onChange={handleChange("email_phone")}
            value={textLogin.email_phone} />
            </Grid>

            <Grid item xs={12} style={{marginTop:"20px" , marginLeft : widthcheck <= MAX_WIDTH_FOR_MOBILE ? "0px" : "0px"}}>
            <StyledTextField  label={t('enter_password')} variant="outlined" focusedcolor={"#E3256B"}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            bordercolor={"#D74894"}  hovercolor={"#E3256B"} width={widthcheck <= MAX_WIDTH_FOR_MOBILE ? 200  : 400}  onChange={handleChange("password")}
            value={textLogin.password}/>
            </Grid>

            <Grid item xs={12} style={{marginTop:"30px" , marginLeft : widthcheck <= MAX_WIDTH_FOR_MOBILE ? "0px" : "0px" }}>
            <Button
      variant="contained"
      sx={{ width: widthcheck <MAX_WIDTH_FOR_MOBILE ? '200px' : '400px', height: '40px' , backgroundColor:"#E3256B" ,color:"white"  }}
      onClick={onClick} // Adjust width and height as needed
    >
     {t('click_login')}
    </Button>
                
             </Grid>

             <Grid item xs={12} style={{marginTop:"10px" , marginLeft : widthcheck <= MAX_WIDTH_FOR_MOBILE ? "0px" : "0px"}}>

             <Button
      variant="contained"
      sx={{ width:  widthcheck <MAX_WIDTH_FOR_MOBILE ? '200px' : '400px', height: '40px' , backgroundColor:"#E3256B" ,color:"white" ,textDecoration:"none"  }} // Adjust width and height as needed
    >
      <Link to={"/register"} style={{ textDecoration: 'none' , color:"white"}}> {t('new_user_label')}</Link>
    </Button>

             </Grid>
             <Grid item xs={6} style={{marginTop:"10px"}}>

             <Typography
      variant="contained"
      sx={{ width: '200px', height: '40px' , color:"#E3256B" }} // Adjust width and height as needed
    >
      {t('forgot_password')}
    </Typography>

             </Grid>
            
        
        
          </Grid>

           {validate == true ? <ModalPopup  title="Validation Alert" content={validationtext}  openvalidate={validate} onChildValue={handlefromchild}/> : ""}
        </div>

        <MsgConfirm
          isOpen={msg}
          handleClose={handleclosemsg}
          title={title}
          content={content}
          closeText={"Ok"} />


      </Grid>



     


      </CSSTransition>

}
    
    </>



);


}



export default Login;
import { useEffect, useState } from "react";
import { useNormalSearchContext } from "../../contexts/normalSearchContext";
import { useTranslation } from "react-i18next";
import { Button, Grid, InputLabel, Modal, TextField } from "@mui/material";
import { DropdownSelect } from "../../app_utils/input_fields";
import { gender, with_photo } from "../../data/data";
import { produce } from "immer";
import { generateYears } from "../../common_utils/utils";
import { COMMUNITY_ID, GENDER, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { json, useLocation, useNavigate } from "react-router-dom";
import React from "react";




function IIT_IIM_Search({passPath}) {

    const { data, loading, error , insertSavedSearch} = useNormalSearchContext();
    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();

    const [marital_list, setMarital] = useState([]);
    const [institute, setInstitute] = useState([]);
    const [caste, setCaste] = useState([]);

    const location = useLocation();


    const modalStyle = {
        position: 'absolute',
        width: 300,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid #e91e63', // Pink border
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.5)', // Shadow
        borderRadius: '8px',
        padding: '20px',
        outline: 'none',
      };
    
      const modalTitleStyle = {
        color: '#e91e63', // Pinkish color
        textAlign: 'center',
        marginBottom: '20px',
      };
    


    const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


    const institute_lngwise = (institute) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const institute_list = [{ label: 'Any', value: 'Any' }, ...institute.map(user => ({
                label: user.institute_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setInstitute(institute_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const institute_list = [{ label: 'Any', value: 'Any' }, ...institute.map(user => ({
                label: user.institute_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setInstitute(institute_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const institute_list = [{ label: 'Any', value: 'Any' }, ...institute.map(user => ({
                label: user.institute_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setInstitute(institute_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const institute_list = [{ label: 'Any', value: 'Any' }, ...institute.map(user => ({
                label: user.institute_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setInstitute(institute_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const institute_list = [{ label: 'Any', value: 'Any' }, ...institute.map(user => ({
                label: user.institute_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setInstitute(institute_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const institute_list = [{ label: 'Any', value: 'Any' }, ...institute.map(user => ({
                label: user.institute_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setInstitute(institute_list);
        }


    }

   


    useEffect(() => {


        passPath(t('iit_iim_nit_search'));
        setAnimated(true);

        if (data.length > 0) {

            const marital_status = Object.values(data[0]).map((item) => ({
                label: item.label,
                value: item.value
            }));

            const caste = [{ label: 'Any', value: 'Any' }, ...Object.values(data[1]).map((item) => ({
                label: item.caste,
                value: item.Id
            }))];


            institute_lngwise(data[3].flat());
            setMarital(marital_status);
            setCaste(caste);

            if(location.state != null){

                const {jsonData} = location.state;

            setNormalSearch({
                gender: jsonData.gender,
                age_from: jsonData.age_from,
                age_to: jsonData.age_to,
                marital_status: jsonData.marital_status,
                caste: jsonData.caste,
                institute: jsonData.institute,
            });
        }
            

        }


    }, [data]);


    const [normalSearchField, setNormalSearch] = useState({
        gender: localStorage.getItem(GENDER).toLowerCase() == "male" ? "Female" : "Male",
        age_from: '16',
        age_to: '70',
        marital_status: '1',
        caste: 'Any',
        institute: 'Any',

    });

    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setNormalSearch(nextState);
    };


    const navigate = useNavigate();
    const handleClickSearch = () =>{

        
          navigate('/iit_iim_nit_search_result', { state: { age_from: normalSearchField.age_from, age_to: normalSearchField.age_to , gender: normalSearchField.gender , 
            marital_status:normalSearchField.marital_status , caste:normalSearchField.caste , institute:normalSearchField.institute } });

    }



    const handleSubmitSaveSearch = () => {

        handleClose();

        insertSavedSearch({
            "search_name":inputValue,
            "search_params":JSON.stringify({ age_from: normalSearchField.age_from, age_to: normalSearchField.age_to , gender: normalSearchField.gender , 
                marital_status:normalSearchField.marital_status , caste:normalSearchField.caste , institute:normalSearchField.institute }),
          "search_type":"iit_iim_nit",
           "userId": localStorage.getItem(USER_ID),
           "communityId":localStorage.getItem(COMMUNITY_ID)
          });

          navigate('/iit_iim_nit_search_result', { state: { age_from: normalSearchField.age_from, age_to: normalSearchField.age_to , gender: normalSearchField.gender , 
            marital_status:normalSearchField.marital_status , caste:normalSearchField.caste , institute:normalSearchField.institute } });


       

    }

    return (
        <>
            <div style={{ display: 'grid', placeItems: 'center', height: "60vh", marginTop:"230px" , width: "100%", paddingLeft: "10%", paddingRight: "10%" }}>


<Grid container columnSpacing={1} xs={12} md={12}>

                <Grid container columnSpacing={1} xs={12} md={12} style={{
                    padding: '20px',
                    width: "80%",
                    zIndex: '1',
                    border: '2px dashed #C850C0',
                    borderRadius: '10px',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'padding-box, border-box',
                }} >

                    <Grid item xs={12} md={3}  >

                        <DropdownSelect options={gender} label={t('gender')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('gender')}
                            value={normalSearchField.gender} />

                    </Grid>
                    <Grid item xs={12} md={3}  >

                        <DropdownSelect options={generateYears(16, 70)} label={t('from_age')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('age_from')}
                            value={normalSearchField.age_from} />

                    </Grid>

                    <Grid item xs={12} md={3}  >

                        <DropdownSelect options={generateYears(16, 70)} label={t('to_age')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('age_to')}
                            value={normalSearchField.age_to} />

                    </Grid>

                    <Grid item xs={12} md={3}  >

                        <DropdownSelect options={marital_list} label={t('marital_status_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('marital_status')}
                            value={normalSearchField.marital_status} />

                    </Grid>




                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={caste} label={t('caste_label')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('caste')}
                            value={normalSearchField.caste} />

                    </Grid>
                    <Grid item xs={12} md={6}  >

                        <DropdownSelect options={institute} label={t('institute_name_l')} variant="contained" focusedBorderColor={"#E3256B"}
                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                            onChange={handleChange('institute')}
                            value={normalSearchField.institute} />

                    </Grid>

                    





                </Grid>


                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={3.4}></Grid>

                <Grid item xs={12} md={2.3} style={{justifyContent: "end", alignContent: "end"}}>
                <Button style={{
                 marginTop:"10px",
                background: "#E3256B", width: "200px",padding:"10px"  , color: "white", fontWeight: "bold"
            }} onClick={handleClickSearch}>Search</Button>
                </Grid>

                <Grid item xs={12} md={2.3} style={{justifyContent: "end", alignContent: "end"}}>
                <Button style={{
                 marginTop:"10px",
                background: "#E3256B", width: "200px",padding:"10px"  , color: "white", fontWeight: "bold"
            }} onClick={handleOpen}>Save & Search</Button>
                </Grid>


                </Grid>
               

                <Modal open={open} onClose={handleClose} >
        <div style={modalStyle}>
          <h2 style={modalTitleStyle}>Save And Search</h2>
          <TextField
            label="Enter Your Save & Search Title"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button onClick={handleSubmitSaveSearch} variant="contained" color="primary" style={{ marginTop: 16 }}>
            Save & Search
          </Button>
        </div>
      </Modal>

            </div>

        </>

    );



}
export default IIT_IIM_Search;
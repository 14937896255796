import { Button, Divider, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import { MuiColorInput } from "mui-color-input";
import MatriProfileCard from "../../../pages/search_result/MatriProfileCard";
import { useState } from "react";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import StylishLabel from "../../component/StylishLabel";
import { SearchProvider } from "../../../contexts/searchContext";
import Card from "./card";
import StylishLabelOther from "../../component/StylishLabelOther";
import MenuTheme from "./menu_theme";
import { fontWeight } from "@mui/system";
import Strips from "./striptheme";
import SearchBarHome from "./searchbar_home";
import { useWebThemeContextAdmin } from "../../contexts_admin/web_theme_context";
import { useEffect } from "react";





function WebTheme(){

 
    const {insert_update_webtheme , data_insert_webtheme ,select_web_theme , data_select_webtheme} = useWebThemeContextAdmin();

    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId , setCommunityId] =  useState("");
    const [borderwidth , setborderwidth] =  useState("");


     useEffect(()=>{

    let communityId_admin  =  "0";
    if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

      communityId_admin = localStorage.getItem("communityId_admin");
      setCommunityId(communityId_admin);

    }else{
     communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
     setCommunityId(communityId_admin);
    }

   
    select_web_theme({"communityId":communityId_admin});
   // alert(localStorage.getItem(ADMIN_COMMUNITY_ID)+"______"+localStorage.getItem("communityId_admin"));

  },[]);

  useEffect(()=>{

    if(data_select_webtheme.length > 0){
      
      //alert(data_select_webtheme[0].theme);
    Object.entries(JSON.parse(data_select_webtheme[0].theme)).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });

  }

  },[data_select_webtheme]);

   
  useEffect(()=>{

    if(data_insert_webtheme.affectedRows >= 1){

        alert("Theme Saved Successfully");

    }


  },[data_insert_webtheme]);





   const [fields, setFields] = useState({

    card_bg:'#fce4ec' ,
    card_border:'#800080',
    border_width: '2px',
    card_hover:'#ba68c8',
    card_detail_bg:'#e91e63',
    card_detail_border:'#ffffff',
    
  });


  const [fieldsMenu , setFieldsMenu] = useState({

    menu_container_colour: '#D74894',
    menu_gradient_colour1: '#D74894',
    menu_gradient_colour2: '#FFb6c1',
    menu_border_colour : '#FFFFFF',
    drop_down_content_colour: '#fffeff',
    border_bottom_colour: '#D74894',
    border_top_colour: '#D74894',
    dropdown_content_hover_colour: '#D74894',
    menu_container_right_colour: '#E3256B'

  });

  const [fieldsStrip , setFieldsStrip] = useState({

    strip_colour1 : '#301935',
    strip_colour2 : '#301935',
    strip_text_color: '#ffffff'

  });


  const [fieldSearchbar , setFieldSearchbar] = useState({

    searchbar_gradient1 : '#4158D0',
    searchbar_gradient2 : '#C850C0',
    searchbar_gradient3: '#D74894'
  });


  const handleFieldChange = (field, value) => {

    if(field == "card_bg"){
        document.documentElement.style.setProperty('--card-background-color', value);
    }else if(field == "card_border"){
        document.documentElement.style.setProperty('--card-border-colour', value);
    }else if(field == "card_hover"){
        document.documentElement.style.setProperty('--card-hover-colour', value);
    }else if(field == "card_detail_bg"){
        document.documentElement.style.setProperty('--card-detail-rounded-colour', value);
    }else if(field == "card_detail_border"){
        document.documentElement.style.setProperty('--card-detail-border-colour', value);
    }

    setFields(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };





  const handleFieldChangeMenu = (field, value) => {

    if(field == "menu_container_colour"){
        document.documentElement.style.setProperty('--menu-container-colour', value);
    }else if(field == "menu_gradient_colour1"){
        document.documentElement.style.setProperty('--menu-gradient-colour1', value);
    }else if(field == "menu_gradient_colour2"){
        document.documentElement.style.setProperty('--menu-gradient-colour2', value);
    }else if(field == "menu_border_colour"){
        document.documentElement.style.setProperty('--menu-border-colour', value);
    }else if(field == "drop_down_content_colour"){
        document.documentElement.style.setProperty('--drop-down-content-colour', value);
    }else if(field == "border_bottom_colour"){
        document.documentElement.style.setProperty('--border-bottom-colour', value);
    }else if(field == "border_top_colour"){
        document.documentElement.style.setProperty('--border-top-colour', value);
    }else if(field == "dropdown_content_hover_colour"){
        document.documentElement.style.setProperty('--dropdown-content-hover-colour', value);
    }else if(field == "menu_container_right_colour"){
        document.documentElement.style.setProperty('--menu-container-right-colour', value);
    }

    setFieldsMenu(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };



  const handleFieldChangeStrip = (field, value) => {

    if(field == "strip_colour1"){
        document.documentElement.style.setProperty('--strip-background-color', value);
    }else if(field == "strip_colour2"){
        document.documentElement.style.setProperty('--strip-background-color2', value);
    }else if(field == "strip_text_color"){
        document.documentElement.style.setProperty('--strip-text-color', value);
    }

    setFieldsStrip(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };




    const handleFieldChangeSearchbar = (field, value) => {

    if(field == "searchbar_gradient1"){
        document.documentElement.style.setProperty('--homepage-searchbar-gradient', value);
    }else if(field == "searchbar_gradient2"){
        document.documentElement.style.setProperty('--homepage-searchbar-gradient2', value);
    }else if(field == "searchbar_gradient3"){
        document.documentElement.style.setProperty('--homepage-searchbar-gradient3', value);
    }

    setFieldSearchbar(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };



    const handleBorderWidth = (event) => {

        setborderwidth(event.target.value);
        document.documentElement.style.setProperty('--card-border-width', event.target.value);

    }


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }


      const handleClickCommunityLink = (communityId_admin) => {

        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);

      }

      const resetcards = () => {
         setFields({
            card_bg:'#fce4ec' ,
            card_border:'#800080',
            border_width: '2px',
            card_hover:'#ba68c8',
            card_detail_bg:'#e91e63',
            card_detail_border:'#ffffff',
          });

            document.documentElement.style.setProperty('--card-background-color', '#fce4ec');
            document.documentElement.style.setProperty('--card-border-colour', '#800080');
            document.documentElement.style.setProperty('--card-hover-colour', '#ba68c8');
            document.documentElement.style.setProperty('--card-detail-rounded-colour', '#e91e63');
            document.documentElement.style.setProperty('--card-detail-border-colour', '#ffffff');
            document.documentElement.style.setProperty('--card-border-width', '2px');


      }

      const resetMenu = () => {

        setFieldsMenu({

            menu_container_colour: '#D74894',
            menu_gradient_colour1: '#D74894',
            menu_gradient_colour2: '#FFb6c1',
            menu_border_colour : '#FFFFFF',
            drop_down_content_colour: '#fffeff',
            border_bottom_colour: '#D74894',
            border_top_colour: '#D74894',
            dropdown_content_hover_colour: '#D74894',
            menu_container_right_colour: '#E3256B'
        
          });


        document.documentElement.style.setProperty('--menu-container-colour', '#D74894');
        document.documentElement.style.setProperty('--menu-gradient-colour1', '#D74894');
        document.documentElement.style.setProperty('--menu-gradient-colour2', '#FFb6c1');
        document.documentElement.style.setProperty('--menu-border-colour', '#FFFFFF');
        document.documentElement.style.setProperty('--drop-down-content-colour', '#fffeff');
        document.documentElement.style.setProperty('--border-bottom-colour', '#D74894');
        document.documentElement.style.setProperty('--border-top-colour', '#D74894');
        document.documentElement.style.setProperty('--dropdown-content-hover-colour',  '#D74894');
        document.documentElement.style.setProperty('--menu-container-right-colour', '#E3256B');

      }

      const resetStrip = () => {

        setFieldsStrip({
            strip_colour1 : '#301935',
            strip_colour2 : '#301935',
            strip_text_color: '#ffffff'
          });

          document.documentElement.style.setProperty('--strip-background-color', '#301935');
          document.documentElement.style.setProperty('--strip-background-color2', '#301935');
          document.documentElement.style.setProperty('--strip-text-color', '#ffffff');
        
      }

      const resetSearchbar = () => {
      
        setFieldSearchbar({
            searchbar_gradient1 : '#4158D0',
            searchbar_gradient2 : '#C850C0',
            searchbar_gradient3: '#D74894'
          });

            document.documentElement.style.setProperty('--homepage-searchbar-gradient', '#4158D0');
            document.documentElement.style.setProperty('--homepage-searchbar-gradient2', '#C850C0');
            document.documentElement.style.setProperty('--homepage-searchbar-gradient3', '#D74894');
        
        
      }


      const handleSave = () => {

        let itemSave = {

            '--card-background-color': fields.card_bg,
            '--card-border-colour': fields.card_border,
            '--card-border-width': fields.border_width,
            '--card-hover-colour': fields.card_hover,
            '--card-detail-rounded-colour': fields.card_detail_bg,
            '--card-detail-border-colour': fields.card_detail_border,
        
            '--menu-container-colour': fieldsMenu.menu_container_colour,
            '--menu-gradient-colour1': fieldsMenu.menu_gradient_colour1,
            '--menu-gradient-colour2': fieldsMenu.menu_gradient_colour2,
            '--menu-border-colour' :  fieldsMenu.menu_border_colour,
            '--drop-down-content-colour': fieldsMenu.drop_down_content_colour,
            '--border-bottom-colour': fieldsMenu.border_bottom_colour,
            '--border-top-colour': fieldsMenu.border_top_colour,
            '--dropdown-content-hover-colour': fieldsMenu.dropdown_content_hover_colour,
            '--menu-container-right-colour': fieldsMenu.menu_container_right_colour,
            
            '--strip-background-color': fieldsStrip.strip_colour1,
            '--strip-background-color2':fieldsStrip.strip_colour2,
            '--strip-text-color': fieldsStrip.strip_text_color,
        
            '--aboutus-heading-colour': '#D74894',
            '--aboutus-icon-colour': '#E3256B',
        
            '--homepage-searchbar-gradient': fieldSearchbar.searchbar_gradient1,
            '--homepage-searchbar-gradient2': fieldSearchbar.searchbar_gradient2,
            '--homepage-searchbar-gradient3': fieldSearchbar.searchbar_gradient3
        
          }

          insert_update_webtheme({
            "web_theme": JSON.stringify(itemSave) ,
            "communityId":communityId
          });



      }
  
  

    let role = localStorage.getItem(ROLE);

     return(<>
     
     <div>

     { role == "developer" ? <StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

     <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "0px" : "0px" , width: "100%" , paddingRight:"15px" , backgroundColor:"#F5F5F5"  }}>

     <Grid item xs = {4}>
      <Typography style={{color:"#000000" , margin:"20px" , fontSize:'20px' , fontWeight:"bold"}}>Card Theme</Typography>
     </Grid>
     <Grid item xs = {8}>
      <Button onClick={resetcards} >Reset</Button>
     </Grid>

         <Grid item xs = {6}>

         <MuiColorInput format="hex" value={fields.card_bg} onChange={value => handleFieldChange('card_bg', value)} label={"Card Background"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fields.card_border} onChange={value => handleFieldChange('card_border', value)} label={"Card Border"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fields.card_hover} onChange={value => handleFieldChange('card_hover', value)} label={"Card HoverColour"} fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fields.card_detail_bg} onChange={value => handleFieldChange('card_detail_bg', value)} label={"Card Detail Background"} fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fields.card_detail_border} onChange={value => handleFieldChange('card_detail_border', value)} label={"Card Detail Border"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <TextField name="border_width"  value={borderwidth} onChange={handleBorderWidth}  label={"Card Boder Width"} fullWidth/>


         </Grid>
         <Grid item xs = {6}>
              <Card />
         </Grid>

         <Grid item xs = {4}>
      <Typography style={{color:"#000000" , margin:"20px" , fontSize:'20px' , fontWeight:"bold"}}>Menu Theme</Typography>
     </Grid>

     <Grid item xs = {8}>
      <Button onClick={resetMenu} >Reset</Button>
     </Grid>

         <Grid item xs = {6}>

<MuiColorInput format="hex" value={fieldsMenu.menu_container_colour} onChange={value => handleFieldChangeMenu('menu_container_colour', value)} label={"Menu Container"}  fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.menu_gradient_colour1} onChange={value => handleFieldChangeMenu('menu_gradient_colour1', value)} label={"Menu Gradient1"}  fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.menu_gradient_colour2} onChange={value => handleFieldChangeMenu('menu_gradient_colour2', value)} label={"Menu Gradient2"} fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.menu_border_colour} onChange={value => handleFieldChangeMenu('menu_border_colour', value)} label={"Menu border"} fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.drop_down_content_colour} onChange={value => handleFieldChangeMenu('drop_down_content_colour', value)} label={"Dropdown Content Colour"}  fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.border_bottom_colour} onChange={value => handleFieldChangeMenu('border_bottom_colour', value)} label={"Border Bottom Colour"}  fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.border_top_colour} onChange={value => handleFieldChangeMenu('border_top_colour', value)} label={"Border Top Colour"}  fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.drop_down_content_colour} onChange={value => handleFieldChangeMenu('dropdown_content_hover_colour', value)} label={"Dropdown Content Hover Colour"} fullWidth/>
<div style={{marginTop:"20px"}} />
<MuiColorInput format="hex" value={fieldsMenu.menu_container_right_colour} onChange={value => handleFieldChangeMenu('menu_container_right_colour', value)} label={"Menu Container"} fullWidth/>




</Grid>
<Grid item xs = {6}>
<MenuTheme />
</Grid>




<Grid item xs = {4}>
      <Typography style={{color:"#000000" , margin:"20px" , fontSize:'20px' , fontWeight:"bold"}}>SubTitle Strip Theme</Typography>
     </Grid>
     <Grid item xs = {8}>
      <Button onClick={resetStrip} >Reset</Button>
     </Grid>


         <Grid item xs = {6}>

         <MuiColorInput format="hex" value={fieldsStrip.strip_colour1} onChange={value => handleFieldChangeStrip('strip_colour1', value)} label={"Strip Colour1"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fieldsStrip.strip_colour2} onChange={value => handleFieldChangeStrip('strip_colour2', value)} label={"Strip Colour2"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fieldsStrip.strip_text_color} onChange={value => handleFieldChangeStrip('strip_text_color', value)} label={"Subtitle Text Colour"} fullWidth/>
         
         </Grid>
         <Grid item xs = {6}>
              <div style={{background: 'linear-gradient(90deg, var(--strip-background-color) , var(--strip-background-color2))' }}>

              <InputLabel style={{textAlign:"center" , fontSize:"30px" , fontWeight:"bold" , color:'var(--strip-text-color)'}}>{"Title"}</InputLabel>

              </div>
         </Grid>








      <Grid item xs = {4}>
      <Typography style={{color:"#000000" , margin:"20px" , fontSize:'20px' , fontWeight:"bold"}}>SearchBar Home Theme</Typography>
     </Grid>
     <Grid item xs = {8}>
      <Button onClick={resetSearchbar} >Reset</Button>
     </Grid>

         <Grid item xs = {6}>

         <MuiColorInput format="hex" value={fieldSearchbar.searchbar_gradient1} onChange={value => handleFieldChangeSearchbar('searchbar_gradient1', value)} label={"Searchbar Gradient1"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fieldSearchbar.searchbar_gradient2} onChange={value => handleFieldChangeSearchbar('searchbar_gradient2', value)} label={"Searchbar Gradient2"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
         <MuiColorInput format="hex" value={fieldSearchbar.searchbar_gradient3} onChange={value => handleFieldChangeSearchbar('searchbar_gradient3', value)} label={"Searchbar Gradient3"} fullWidth/>
         
         </Grid>
         <Grid item xs = {6}>
             <SearchBarHome />
         </Grid>


         <Grid item xs = {12} style={{display:"flex" , justifyContent:"center"}}>
         <Button style={{ backgroundColor: '#d74894' , width:"50%"  ,borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" ,color:"#ffffff"  }} onClick={handleSave}>Save Theme</Button>
        </Grid>



     </Grid>
  





     {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

    </div>
     
     
     </>);



}

export default WebTheme;
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const MsgConfirm = ({ isOpen ,title , content , handleClose , closeText }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {closeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MsgConfirm;
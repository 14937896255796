import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";




function getProfileId() {
    // Generate three random uppercase letters
    const randomAlphabets = Array.from({ length: 3 }, () =>
      String.fromCharCode(Math.floor(Math.random() * 26) + 65)
    ).join('');
  
    // Generate random numeric characters (e.g., 6 digits)
    const randomNumeric = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
  
    // Concatenate the alphabets and numeric parts
    const resultString = `${randomAlphabets}${randomNumeric}`;
  
    return resultString;
  }

  function getTodayDate(){


    const currentDate = new Date();

    // Get the individual components of the date (year, month, day)
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-indexed
    const day = currentDate.getDate();
  
    // Format the date as a string (e.g., "YYYY-MM-DD")
    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

   return formattedDate;
  }


  const generateDays = () => {
    const days = Array.from({ length: 31 }, (_, index) => ({
      label: (index + 1).toString(),
      value: index + 1,
    }));
  
    return days;
  };

  const generateMonths = () => {
    const months = Array.from({ length: 12 }, (_, index) => ({
      label: new Date(0, index).toLocaleString('default', { month: 'long' }),
      value: index + 1,
    }));

    console.log(months[0].label+"====-----");
  
    return months;
  };
 

  const generateAge = (startAge, endAge) => {
    const years = Array.from({ length: endAge - startAge + 1 }, (_, index) => ({
      label: (startAge + index).toString(),
      value: startAge + index,
    }));
  
    return years;
  };

  const generateYears = (startYear, endYear) => {
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => ({
      label: (startYear + index).toString(),
      value: startYear + index,
    }));
  
    return years;
  };



  const generateWeight = (weight_from, weight_to) => {
    const weight = Array.from({ length: weight_to - weight_from + 1 }, (_, index) => ({
      label: (weight_from + index).toString()+" KGS",
      value: weight_from + index,
    }));
  
    return weight;
  };

  const employmentType = () => {

    return [{value:"Private Sector" , label:"Private sector"},
    {value:"Public Sector" , label:"Public sector"},
    {value:"Self Employed" , label:"Self Employed"}];

  }

  const getAge = (birthdate) => {
    const currentDate = new Date();
    const birthDate = new Date(birthdate);

    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);

    const calculatedAge = Math.floor(ageInYears);
    return calculatedAge;
  };



  const calculateAge = (birthDate, currentDate) => {
    const birth = new Date(birthDate);
    const current = new Date(currentDate);
  
    let age = current.getFullYear() - birth.getFullYear();
    const monthDifference = current.getMonth() - birth.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && current.getDate() < birth.getDate())) {
      age--;
    }
  
    return age;
  };


 

  const getInches = (height) => {
    const [feet, _, inches] = height.split(/\D+/).map(Number);

    if (!isNaN(feet) && !isNaN(inches)) {
      return feet * 12 + inches;
    }

    return null; // Return null if the format doesn't match
  };


  function GENERATE_RANDOM(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


function marathiToEnglishNumber(marathiNumber) {
  const marathiDigits = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
  const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  let englishNumber = '';

  for (let i = 0; i < marathiNumber.length; i++) {
    const marathiDigit = marathiNumber[i];
    const digitIndex = marathiDigits.indexOf(marathiDigit);

    if (digitIndex !== -1) {
      englishNumber += englishDigits[digitIndex];
    } else {
      // If the character is not a Marathi digit, keep it as it is
      englishNumber += marathiDigit;
    }
  }

  return englishNumber;
}

 


  export{getProfileId , getTodayDate , generateDays ,generateMonths ,generateYears ,generateWeight ,employmentType ,generateAge ,getAge , getInches ,GENERATE_RANDOM , marathiToEnglishNumber , calculateAge};
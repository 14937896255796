import React, { createContext, useContext , useReducer, useEffect } from 'react';
import axios from 'axios';
import activityReducer from '../reducer/activityReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../common_utils/constants';

const activityContext = createContext();

const initialState = {
  list_view_profile:[],  
  loading_shortlist: true,
  loading_profile:true,
  loading_contact:true,
  loading_block:true,
  loading_interest:true,
  loading:true,
  error: null,
  insert_shortlist:[],
  insert_interest:[],
  shortlists_user:[],
  contactlist_user:[],
  block_ignore_list:[],
  interest_send_recieve_list:[],
  insert_interest_data:[]
};

let API = `${BASE_URL}`;


export const ActivityProvider = ({ children }) => {

const [state, dispatch] = useReducer(activityReducer , initialState);

const viewProfileList = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/view_profile` , params);

      dispatch({ type: 'VIEW_PROFILE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'VIEW_PROFILE_ERROR', payload: error });
    }
  };


  const ShortListUsers = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/shortlisted` , params);

      dispatch({ type: 'SHORTLIST_USER', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SHORTLIST_USER_ERROR', payload: error });
    }
  };



  const ViewContactList = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/view_contact` , params);

      dispatch({ type: 'VIEW_CONTACT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'VIEW_CONTACT_ERROR', payload: error });
    }
  };



  const BlockIgnoreList = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/block_ignore_user` , params);

      dispatch({ type: 'BLOCK_IGNORE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BLOCK_IGNORE_ERROR', payload: error });
    }
  };



  const InterestRecievedSent = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/interest_sent_recieved` , params);

      console.log(response.data);

      dispatch({ type: 'INTESREST_SENT_RECIEVED', payload: response.data.data });
    } catch (error) {

      console.log(error);

      dispatch({ type: 'INTESREST_SENT_RECIEVED_ERROR', payload: error });
    }
  };


  const insertShortlist = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/shortlist` , params);

      dispatch({ type: 'SHORTLIST_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SHORTLIST_ERROR', payload: error });
    }
  };

  const express_interest_insert = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/express_interest_insert` , params);

      dispatch({ type: 'EXPRESS_INTEREST_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'EXPRESS_INTEREST_INSERT_ERROR', payload: error });
    }
  };


  const insertNotification_activity = async (params) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification` , params);

      dispatch({ type: 'INSERT_NOTIFICATION_ACTIVITY', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_ACTIVITY_ERROR', payload: error });
    }
  };
 



  return <activityContext.Provider value={{ ...state , dispatch , viewProfileList ,insertShortlist , express_interest_insert , ShortListUsers , ViewContactList , BlockIgnoreList , InterestRecievedSent , insertNotification_activity }}>{children}</activityContext.Provider>;
};

export const useActivityContext = () => useContext(activityContext);
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Modal,
  TextField,
  Typography,
  Container,
  Grid,
  Avatar,
} from '@mui/material';
import '../../styles/HappyStoryModal.css'; // Import your custom CSS file




const HappyStoryModal = ({ open, onClose }) => {
  const [husbandName, setHusbandName] = useState('');
  const [wifeName, setWifeName] = useState('');
  const [marriageDate, setMarriageDate] = useState(new Date());
  const [description, setDescription] = useState('');

  const handleSubmit = () => {
    // Implement your submission logic here
    console.log({
      husbandName,
      wifeName,
      marriageDate,
      description,
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="happy-story-modal"
      className="custom-modal" // Add a class for custom styling
    >
      <Container maxWidth="sm" className="custom-paper"> {/* Add a class for custom styling */}
        <Avatar
          alt="MUI Logo"
          src="https://material-ui.com/static/logo_raw.svg"
          className="custom-avatar" // Add a class for custom styling
        />
        <Typography variant="h5" align="center" gutterBottom>
          Happy Story Screen
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} className="custom-input-field"> {/* Add a class for custom styling */}
            <TextField
              label="Husband's Name"
              fullWidth
              value={husbandName}
              onChange={(e) => setHusbandName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className="custom-input-field"> {/* Add a class for custom styling */}
            <TextField
              label="Wife's Name"
              fullWidth
              value={wifeName}
              onChange={(e) => setWifeName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className="custom-input-field"> {/* Add a class for custom styling */}
            <DatePicker
              selected={marriageDate}
              onChange={(date) => setMarriageDate(date)}
              dateFormat="MM/dd/yyyy"
              className="custom-datepicker" // Add a class for custom styling
              isClearable
              showYearDropdown
              showMonthDropdown
            />
          </Grid>
          <Grid item xs={12} className="custom-input-field"> {/* Add a class for custom styling */}
            <TextField
              label="Description (Min 5 lines)"
              multiline
              rows={5}
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default HappyStoryModal;

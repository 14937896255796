const reducerHoroscope = (state, action) => {
    switch (action.type) {
      case 'HOROSCOPE_DETAILS_EDIT_ADMIN':
        return {
          ...state,
          data_horoscope_data_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'HOROSCOPE_DETAILS_EDIT_ERROR_ADMIN':
        return {
          ...state,
          data_horoscope_data_admin: [],
          loading: false,
          error: action.payload,
        };
        case 'HOROSCOPE_CHART_VIEW_ADMIN':
        return {
          ...state,
          data_horoscope_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'HOROSCOPE_CHART_VIEW_ERROR_ADMIN':
        return {
          ...state,
          data_horoscope_admin: [],
          loading: false,
          error: action.payload,
        };
        case 'NAVMSA_CHART_VIEW_ADMIN':
          return {
            ...state,
            data_navmsa_admin: action.payload,
            loading: false,
            error: null,
          };
        case 'NAVMSA_CHART_VIEW_ERROR_ADMIN':
          return {
            ...state,
            data_horoscope_admin: [],
            loading: false,
            error: action.payload,
          };

          case 'INSERT_HOROSCOPE_DETAILS_ADMIN':
        return {
          ...state,
          horoscope_insert_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_HOROSCOPE_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          horoscope_insert_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_HOROSCOPE_DETAILS_ADMIN':
        return {
          ...state,
          horoscope_update_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_HOROSCOPE_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          horoscope_update_admin: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerHoroscope;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../../src/adminpanel/reducer_admin/masterpageReducerAdmin';
import { BASE_URL } from '../common_utils/constants';

const masterContext = createContext();

const initialState = {
  loading_masterpage: true,
  error: null,
  data_insert_religion: [],
  list_religion_admin:[],
  data_update_religion:[],
  data_insert_caste: [],
  list_caste_admin:[],
  list_castedropdown_admin:[],
  data_update_caste:[],
  data_insert_subcaste: [],
  list_subcaste_admin:[],
  data_update_subcaste:[],
  data_insert_language: [],
  list_language_admin:[],
  data_update_language:[],
  data_insert_country: [],
  list_country_admin:[],
  data_update_country:[],
  data_insert_state: [],
  list_state_admin:[],
  data_update_state:[],
  data_insert_city: [],
  list_city_admin:[],
  data_update_city:[],
  data_insert_createdby:[],
  list_createdby_admin:[],
  data_update_createdby:[],
  data_insert_familystatus:[],
  list_familystatus_admin:[],
  data_update_familystatus:[],
  data_insert_familyvalue:[],
  list_familyvalue_admin:[],
  data_update_familyvalue:[],

};

let API = `${BASE_URL}`;


export const MasterPageProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const religion_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/religion_fetch` , article);
      dispatch({ type: 'RELIGION_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'RELIGION_FETCH_ERROR', payload: error });
    }
  };


  const religion_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/religion_insert` , article);
      
      dispatch({ type: 'RELIGION_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'RELIGION_INSERT_ERROR', payload: error });
    }
  };


  const religion_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/religion_update` , article);
      
      dispatch({ type: 'RELIGION_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'RELIGION_UPDATE_ERROR', payload: error });
    }
  };



  const caste_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/caste_fetch` , article);
      dispatch({ type: 'CASTE_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'CASTE_FETCH_ERROR', payload: error });
    }
  };



  const caste_fetch_dropdown = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/caste_fetch_dropdown` , article);
      dispatch({ type: 'CASTE_FETCH_DROPDOWN', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'CASTE_FETCH_DROPDOWN_ERROR', payload: error });
    }
  };


  const caste_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/caste_insert` , article);
      
      dispatch({ type: 'CASTE_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CASTE_INSERT_ERROR', payload: error });
    }
  };


  const caste_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/caste_update` , article);
      
      dispatch({ type: 'CASTE_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CASTE_UPDATE_ERROR', payload: error });
    }
  };




  const subcaste_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/subcaste_fetch` , article);
      dispatch({ type: 'SUBCASTE_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'SUBCASTE_FETCH_ERROR', payload: error });
    }
  };


  const subcaste_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/subcaste_insert` , article);
      
      dispatch({ type: 'SUBCASTE_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SUBCASTE_INSERT_ERROR', payload: error });
    }
  };


  const subcaste_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/subcaste_update` , article);
      
      dispatch({ type: 'SUBCASTE_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SUBCASTE_UPDATE_ERROR', payload: error });
    }
  };




  const languages_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/languages_fetch` , article);
      dispatch({ type: 'LANGUAGE_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'LANGUAGE_FETCH_ERROR', payload: error });
    }
  };


  const languages_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/languages_insert` , article);
      
      dispatch({ type: 'LANGUAGE_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'LANGUAGE_INSERT_ERROR', payload: error });
    }
  };


  const languages_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/languages_update` , article);
      
      dispatch({ type: 'LANGUAGE_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'LANGUAGE_UPDATE_ERROR', payload: error });
    }
  };




  const country_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/country_fetch` , article);
      dispatch({ type: 'COUNTRY_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'COUNTRY_FETCH_ERROR', payload: error });
    }
  };


  const country_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/country_insert` , article);
      
      dispatch({ type: 'COUNTRY_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'COUNTRY_INSERT_ERROR', payload: error });
    }
  };


  const country_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/country_update` , article);
      
      dispatch({ type: 'COUNTRY_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'COUNTRY_UPDATE_ERROR', payload: error });
    }
  };




  const state_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/state_fetch` , article);
      dispatch({ type: 'STATE_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'STATE_FETCH_ERROR', payload: error });
    }
  };


  const state_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/state_insert` , article);
      
      dispatch({ type: 'STATE_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'STATE_INSERT_ERROR', payload: error });
    }
  };


  const state_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/state_update` , article);
      
      dispatch({ type: 'STATE_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'STATE_UPDATE_ERROR', payload: error });
    }
  };




  const city_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/city_fetch` , article);
      dispatch({ type: 'CITY_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'CITY_FETCH_ERROR', payload: error });
    }
  };


  const city_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/city_insert` , article);
      
      dispatch({ type: 'CITY_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CITY_INSERT_ERROR', payload: error });
    }
  };


  const city_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/city_update` , article);
      
      dispatch({ type: 'CITY_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CITY_UPDATE_ERROR', payload: error });
    }
  };



  const createdBy_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/createdBy_fetch` , article);
      dispatch({ type: 'CREATEDBY_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'CREATEDBY_FETCH_ERROR', payload: error });
    }
  };


  const createdBy_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/createdBy_insert` , article);
      
      dispatch({ type: 'CREATEDBY_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CREATEDBY_INSERT_ERROR', payload: error });
    }
  };


  const createdBy_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/createdBy_update` , article);
      
      dispatch({ type: 'CREATEDBY_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CREATEDBY_UPDATE_ERROR', payload: error });
    }
  };



  const familyStatus_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/familyStatus_fetch` , article);
      dispatch({ type: 'FAMILYSTATYUS_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'FAMILYSTATYUS_FETCH_ERROR', payload: error });
    }
  };


  const familyStatus_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/familyStatus_insert` , article);
      
      dispatch({ type: 'FAMILYSTATYUS_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAMILYSTATYUS_INSERT_ERROR', payload: error });
    }
  };


  const familyStatus_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/familyStatus_update` , article);
      
      dispatch({ type: 'FAMILYSTATYUS_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAMILYSTATYUS_UPDATE_ERROR', payload: error });
    }
  };




  const familyValues_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/familyValues_fetch` , article);
      dispatch({ type: 'FAMILYVALUES_FETCH', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'FAMILYVALUES_FETCH_ERROR', payload: error });
    }
  };


  const familyValues_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/familyValues_insert` , article);
      
      dispatch({ type: 'FAMILYVALUES_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAMILYVALUES_INSERT_ERROR', payload: error });
    }
  };


  const familyValues_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/familyValues_update` , article);
      
      dispatch({ type: 'FAMILYVALUES_UPDATE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAMILYVALUES_UPDATE_ERROR', payload: error });
    }
  };



  const delete_master_data = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/delete_master_data` , article);
      
      dispatch({ type: 'DELETE_MASTER_DATA', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'DELETE_MASTER_DATA_ERROR', payload: error });
    }
  };

  const clearListState = () => {

    dispatch({ type: 'CLEAR_STATE_LIST' });

  };


  const clearListCasteAdmin = () => {

    dispatch({ type: 'CLEAR_LIST_CASTE' });

  };



  


  
 
  return <masterContext.Provider value={{ ...state , dispatch , religion_insert , religion_update , religion_fetch ,caste_fetch ,caste_insert ,caste_update , subcaste_fetch ,subcaste_insert ,subcaste_update , languages_fetch ,languages_insert ,languages_update ,country_fetch ,country_insert ,country_update , state_fetch ,state_insert ,state_update ,city_fetch ,city_insert ,city_update , createdBy_fetch ,createdBy_insert ,createdBy_update , familyStatus_fetch ,familyStatus_insert ,familyStatus_update , familyValues_fetch , familyValues_insert , familyValues_update , caste_fetch_dropdown , clearListState , delete_master_data , clearListCasteAdmin}}>{children}</masterContext.Provider>;
};

export const useMasterContextAdmin = () => useContext(masterContext);
import { useEffect } from "react";
import { useRolesContextAdmin } from "../../contexts_admin/userRoleContext";
import { useParams ,useNavigate} from "react-router-dom";
import { useState } from "react";
import { Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme ,Checkbox , Button , Modal , MenuItem} from "@mui/material";
import { ROLE } from "../../../common_utils/constants";
import { padding } from "@mui/system";
import React from "react";






function AddRoles(){

const{roles_master_select , data_rolesmaster_select , add_rolecommunity , data_insert_roles , add_rolepermission , data_insert_rolespermission ,update_rolecommunity , data_update_roles , roles_permission_fetch , data_rolespermision_select ,add_all_staff , data_insert_allstaff} = useRolesContextAdmin();


const { communityId } = useParams();

const [checkedItems, setCheckedItems] = useState([]);
const [Role , setRole] = useState("");
const [RoleId , setRoleId] = useState("");
const [Role2 , setRole2] = useState("");
const [Role3 , setRole3] = useState("");
const [RoleLabel3 , setRoleLabel3] = useState("");
const [open , setopen] = useState(false);
const [isfirst , setFirst] = useState(false);


const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    userId: '',
    password: '',
    role: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  const handleCheckboxChange = (value) => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
  };

  


  const navigate = useNavigate();
 useEffect(()=>{

    if(data_insert_rolespermission.affectedRows >= 1){
        setCheckedItems([]);
    }

    if(data_insert_allstaff.affectedRows >= 1 && isfirst == true){

        navigate("/list_roles");
        window.location.reload();
       
    }

    if(data_insert_roles.affectedRows >= 1 || data_update_roles.affectedRows >= 1 || data_insert_rolespermission.affectedRows >= 1){

        roles_master_select({
            "communityId":communityId
           });
  
        
    }else{

        roles_master_select({
            "communityId":communityId
           });
        
           roles_permission_fetch();


    }
 

 },[data_insert_roles , data_update_roles , data_insert_rolespermission ,data_insert_allstaff]);

 const handleChangeRoles = (event) => {
    setRole(event.target.value);
 }

 const handleChangeRoles2 = (event) => {
    setRole2(event.target.value);
 }

 const handleChangeRoles3 = (event) => {
    setRole3(event.target.value);
    setRoleLabel3(event.target.label);
    //alert(event.target.label);
 }

 const handleAddRoles = () => {

    add_rolecommunity({
        "communityId":communityId,
        "name":Role
     });

 }

 const editrole = (name , Id) => {

    setRole2(name);
    setRoleId(Id);
    setopen(true);
    
 }

 const handleClose = () => {

        setopen(false);
 }

 const handleUpdateRoles = () => {

    update_rolecommunity({
       "name":Role2,
       "Id":RoleId
    });
    setopen(false);

 }

 const add_permissions = (Id) => {

    add_rolepermission({
     "permission":checkedItems,
     "Id":Id
    });
    
 };

 const handleSaveStaffDetails = () => {

    const item = data_rolesmaster_select.find(item => item.Id === Role3);
   

    add_all_staff({
       "name":userData.name,
       "email":userData.email,
       "phone":userData.phone,
       "userId":userData.userId,
       "password":userData.password,
       "role": item.name,
       "communityId":communityId 
    });

    setFirst(true);

 }


 let role = localStorage.getItem(ROLE);

 return(<>

<Grid container spacing={4} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "145px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px"   }}>

<Grid item xs={12}>
<Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Add User & Roles</Typography>
</Grid>
<Grid item xs={8}>
 <TableContainer component={Paper}>

      <Table>
        <TableHead>
    
          <TableRow style={{width:"100%"}}>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Role</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Permission</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Edit Role</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Add/Edit Permission</TableCell>
        
          </TableRow>
        </TableHead>
        <TableBody>
          {data_rolesmaster_select.length > 0 && data_rolesmaster_select.map((free_members, index) => (
            <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{(index+1)}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.name}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.permissions}</TableCell>
              <TableCell><span
          style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={()=>editrole(free_members.name , free_members.Id)} >
          {"Edit Role"}
        </span></TableCell>
        <TableCell><span
          style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={()=>add_permissions(free_members.Id)} >
          {"Add Permissions"}
        </span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </Grid>

    <Grid  item xs={4}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
<Grid container spacing={1}>


<Grid item xs={12}>
      <TextField
        label="Enter Roles"
        variant="outlined"
        value={Role}
        fullWidth
        onChange={handleChangeRoles}
        style={{ marginRight: '10px', flex: 1 }}/>
        </Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddRoles}>
        Add Roles
      </Button>
      </Grid>

      </Grid>
    </Paper>

          </Grid>

    <Grid item xs={6}>

    <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" }}>Permissions List</Typography>
    <TableContainer component={Paper} style={{ marginBottom:"50px" }}>
      <Table>
        <TableBody>
          {data_rolespermision_select.length > 0 &&  data_rolespermision_select.map((item ,index) => (
            <TableRow key={item.id+(index+1)}>
              <TableCell>
                <Checkbox
                  checked={checkedItems.includes(item.Id)}
                  onChange={() => handleCheckboxChange(item.Id)}
                  style={{padding:"0px"}}
                />
              </TableCell>
              <TableCell style={{fontSize:"16px"}}>{item.permission}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </Grid>

<Grid item xs={6} >

  <Grid container spacing={1}>


    <Grid item xs={12}>
    <Typography  variant="h6" align="left" width="300px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb" }}>Add Staff From roles</Typography>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Name"
        variant="outlined"
        value={userData.name}
        name="name"
        fullWidth
        onChange={handleInputChange}
        style={{ marginRight: '10px', flex: 1 , marginTop:"10px"}}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Email"
        variant="outlined"
        name="email"
        value={userData.email}
        fullWidth
        onChange={handleInputChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Contact"
        variant="outlined"
        name="phone"
        value={userData.phone}
        fullWidth
        onChange={handleInputChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>



    <Grid  item xs={12}>
      <TextField
        label="Enter UserId"
        variant="outlined"
        value={userData.userId}
        name="userId"
        fullWidth
        onChange={handleInputChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Password"
        variant="outlined"
        value={userData.password}
        name="password"
        fullWidth
        onChange={handleInputChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>



    <Grid  item xs={12}>
            <TextField
  select
  label="Select Roles"
  variant="outlined"
  value={Role3}
  name="role"
  onChange= {handleChangeRoles3}
  fullWidth
>
{data_rolesmaster_select.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.name}
        </MenuItem>
      ))}
</TextField>
</Grid>

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSaveStaffDetails}>
        Submit Staff Details
      </Button>

      </Grid>

  </Grid>



</Grid>




    <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
          <TextField
            label="Enter Roles"
            variant="outlined"
            value={Role2}
            fullWidth
            onChange={handleChangeRoles2}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUpdateRoles}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>


    </Grid>
 
 
 </>);




}

export default AddRoles;
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Banner from './banner';
import Box from '@mui/material/Box';
import '../../styles/Example.scss';
import { DropdownSelect, StyledTextField } from '../../app_utils/input_fields';

import { gender } from '../../data/data';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { produce } from 'immer';
import { generateAge } from '../../common_utils/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { textAlign } from '@mui/system';


const Carousel_data = ({ items }) => {

  const ageRange = Array.from({ length: 53 }, (_, index) => index + 18);
  const { t } = useTranslation();

  const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs') || theme.breakpoints.down('sm'));


  const dropdownStyle = {
    margin: '8px',
    minWidth: '220px',
  };

  const selectStyle = {
    borderRadius: '8px', // Rounded border
    backgroundColor: '#fff', // White background color
    color: '#800080', // Purple text color
    '&:hover:not($disabled)': {
      backgroundColor: '#f0f0f0', // Hover background color
    },
    border: 'none'
  };

  const iconStyle = {
    fill: '#800080', // Purple icon color
  };

  const menuItemStyle = {
    color: '#800080', // Purple text color for dropdown items
    backgroundColor: '#fff', // White background color for dropdown items
    borderRadius: '8px', // Rounded border for dropdown items
    '&:hover': {
      backgroundColor: '#f0f0f0', // Hover background color for dropdown items
    },
  };


  const navigate = useNavigate();
  const handleSearch = () => {

    navigate('/normal_search_result', {
      state: {
          age_from: searchFields.fromage, age_to: searchFields.toage, marital_status: "1",
          education_list: "Any", occupation_list: "Any", gender: searchFields.gender,
          caste: "Any", isMangalik: "0", is_handicap: "0"
      }
  });


  }

  const [searchFields, setSearchFields] = useState({
    gender: 'Female',
    fromage: '18',
    toage: '70',
  });

  
  const handleChange = (fieldName) => (event, value) => {

    const newValue = event.target.value;
    const nextState = produce((draft) => {
      draft[fieldName] = newValue;
    });
    // Set the updated state
    setSearchFields(nextState);
  };


  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const MAX_WIDTH_FOR_MOBILE = 900;


  return (
    <>
      <Carousel
        animation="fade"
        indicators={false}
        timeout={500}
        autoPlay={true}
        navButtonsAlwaysVisible={true}
        navButtonsAlwaysInvisible={false}
        cycleNavigation={true}
        fullHeightHover={false}>
        {
          items.length > 0 ? Object.values(items[0][0]).map((item, index) => (
            <Banner item={item} index={index} key={index} />
          )) : []
        }
      </Carousel>

      <div
        style={{
          paddingTop: '10px',
          paddingBottom: '100px',
          backgroundColor: 'red',
          alignItems: 'center',
          height: width <= MAX_WIDTH_FOR_MOBILE ? '60%' :'20%',
          width: '75%',
          position: 'absolute',
          top: width <= MAX_WIDTH_FOR_MOBILE ? '15%' : '78%',
          left: '13%',
          margin: 'auto auto',
          display: 'flex',
          justifyContent: 'center',
          zIndex: '1',
          border: '3px solid #D74894',
          borderRadius: '10px',
          backgroundImage:
            'linear-gradient(43deg, var(--homepage-searchbar-gradient) 0%, var(--homepage-searchbar-gradient2) 46%, var(--homepage-searchbar-gradient3) 100%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: width <= MAX_WIDTH_FOR_MOBILE  ? '45%' : '50%',
            left: width <= MAX_WIDTH_FOR_MOBILE ? '10%' :'5%',
            right:  width <= MAX_WIDTH_FOR_MOBILE ? '10%' : '5%',
            transform: 'translateY(-50%)',
          }}
        >

          <Grid container xs={12} item style={{textAlign: "center"}} >

            <Grid item xs={12} md={3}>

              <Grid item xs={12}>
                <InputLabel style={{ color: "white", fontWeight: "bold", marginLeft: "10px" }}>{t('search_for')}</InputLabel>
              </Grid>

              <Grid item xs={12} >
                <FormControl style={dropdownStyle}>
                  <Select
                    value={searchFields.gender} // Set the selected value or an empty string if there's no initial value
                    onChange={handleChange('gender')}
                    style={selectStyle}
                    inputProps={{
                      style: {
                        '&:hover:not($disabled)': selectStyle,
                      },
                    }}
                    IconComponent={() => <ArrowDropDownIcon style={iconStyle} />}
                  >
                    <MenuItem value={"Female"} style={menuItemStyle}>Bride</MenuItem>
                    <MenuItem value={"Male"} style={menuItemStyle}>Groom</MenuItem>

                  </Select>
                </FormControl>
              </Grid>

            </Grid>

            <Grid item xs={12} md={3}>


              <Grid item xs={12}>
                <InputLabel style={{ color: "white", fontWeight: "bold", marginLeft: "10px" }}>{t('from_age')}</InputLabel>
              </Grid>

              <Grid item xs={12} >
                <FormControl style={dropdownStyle}>
                  <Select
                    value={searchFields.fromage} // Set the selected value or an empty string if there's no initial value
                    onChange={handleChange('fromage')}
                    style={selectStyle}
                    inputProps={{
                      style: {
                        '&:hover:not($disabled)': selectStyle,
                      },
                    }}
                    IconComponent={() => <ArrowDropDownIcon style={iconStyle} />}
                  >
                    {ageRange.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>


            </Grid>




            <Grid item xs={12} md={3}>


            <Grid item xs={12}>
                <InputLabel style={{ color: "white", fontWeight: "bold", marginLeft: "10px" }}>{t('to_age')}</InputLabel>
              </Grid>

              <Grid item xs={12} >
                <FormControl style={dropdownStyle}>
                  <Select
                    value={searchFields.toage} // Set the selected value or an empty string if there's no initial value
                    onChange={handleChange('toage')}
                    style={selectStyle}
                    inputProps={{
                      style: {
                        '&:hover:not($disabled)': selectStyle,
                      },
                    }}
                    IconComponent={() => <ArrowDropDownIcon style={iconStyle} />}
                  >
                    {ageRange.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            </Grid>

            <Grid item style={{textAlign: "center"}} xs={  width <= MAX_WIDTH_FOR_MOBILE  ?  12 : 3} sm={  width <= MAX_WIDTH_FOR_MOBILE ?   12 : 3} >
              <Button style={{ color: "#E3256B", fontWeight: "bold", backgroundColor: "#fff", marginTop: "35px" }} onClick={handleSearch}>Click to Search</Button>
            </Grid>




          </Grid>

        </div>
      </div>
    </>
  );
}

export default Carousel_data;

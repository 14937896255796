


import { useEffect } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import React from "react";




function EventDetails({isopen , setClose, Details}){


    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }




    useEffect(()=>{
    

        setOpen(isopen);
       
    },[]);




   




    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Details of Event "{Details.eventname}"</DialogTitle>
      <DialogContent>

      
      <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
  <Typography variant="h6" style={{ color: '#AA336A', borderBottom: '2px solid #333', paddingBottom: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Event Details</Typography>
  <div style={{ marginBottom: '20px' }}>
    <Typography variant="subtitle1" style={{ color: '#333', marginTop: "10px", fontWeight: 'bold' }}>Event Name: <span style={{ color: '#666' }}>{Details.eventname}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#333', marginTop: "10px", fontWeight: 'bold' }}>Event Date: <span style={{ color: '#666' }}>{Details.date}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#333', marginTop: "10px", fontWeight: 'bold' }}>Event Time: <span style={{ color: '#666' }}>{Details.time}</span></Typography>
    <Typography variant="subtitle1" style={{ color: '#333', marginTop: "10px", fontWeight: 'bold' }}>Event Address/Venue: <span style={{ color: '#666' }}>{Details.venue}</span></Typography>
  </div>
  <Typography variant="h6" style={{ color: '#AA336A', marginTop: "20px", fontWeight: 'bold' }}>Event Details</Typography>
  <Typography variant="body1" style={{ color: '#333', marginTop: "10px", lineHeight: '1.5', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: Details.details }} />
</div>



        
            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default EventDetails;
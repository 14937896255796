import React, { useState, useEffect ,useRef} from 'react';
import { useHomeContext } from '../../contexts/homepageContext';
import Carousel_data from './carousel';

import Happystory from './happystory';
import { InputLabel } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import "../../styles/animationfade.css";
import HomePageAbout from './homepageabout';
import FeaturedProfile from './featured_profile';
import "../../styles/homepage.css";
import RotatingHeart from '../../common_utils/loading_animator';
import { useNotificationContext } from '../../contexts/notificationContext';
import ReactGA from 'react-ga4';




function HomePage(){

    const {data , loading_home , error} = useHomeContext();

    

    const [isVisible , setIsVisible] = useState(false);

    useEffect(()=>{

      setTimeout(() => {
        setIsVisible(true);
      }, 2500)
      
    },[]);

    

  
    if (error) {
        console.error('Error fetching data:', error);
        return <div>Error fetching data</div>;
      }


    if (!data || data.length === 0) {
        return  <div>No data available</div>;
      }

      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const MAX_WIDTH_FOR_MOBILE = 900;
 

    return (
     <>
     {  isVisible == false  ?  <RotatingHeart/> : 
     <div style={{  color: "#494949" , marginTop:"45px" , overflow:"hidden"}} className="pinkish-background">

     <Carousel_data  items={data}/>

     <HomePageAbout />

  <FeaturedProfile items_featured={data} />

    <div style={{height:"0px"}}></div>
     
     <CSSTransition
      in={true}
      timeout={1500} // Duration of the transition in milliseconds
      classNames="fade"
      unmountOnExit>
     <Happystory  items_main={data} />
     </CSSTransition>


   


       
     </div>
       
    }
       
     </>

    );



}


export default HomePage;
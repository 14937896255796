const reducerUploadedFiles = (state, action) => {

    switch (action.type) {


      case 'UPLOADFILES_LIST_SUCCESS':


            const images = action.payload[0].map(item => {
              const picUrlsWithSequence = [];
 
              // Extracting pic URLs and adding them to the picUrls array
              for (let i = 1; i <= 8; i++) {
                const picField = `pic${i}`;
                const isverifypicField = `isverifypic${i}`; 
               
                if(item[picField] != null){
                  picUrlsWithSequence.push({
                    url: item[picField],
                    sequence: i,
                    userId: item["userId"],
                    isverifypic : item[isverifypicField]
                  });
                }
                
              }
              return picUrlsWithSequence;
            });
          



              return{
                ...state,
                list_uploadedFiles_admin: images,
                total_row_count_uploadedfiles:action.payload[1][0].total_row_count,
                loading_uploadedFiles: false,
              error: action.payload,
            };
    
          case 'UPLOADFILES_LIST_ERROR':
            return {
              ...state,
              list_images_unapproved: [],
              loading_uploadedFiles: false,
              error: action.payload,
            };





            case 'UPLOADPROOFS_LIST_SUCCESS':


            const images2 = action.payload[0].map(item => {
              const picUrlsWithSequence = [];
 
               picUrlsWithSequence.push({
                id_proofs: item["id_proofs"],
                education_proof: item["education_proof"],
                income_proof: item["income_proof"],
                is_id_verify: item["is_id_verify"],
                is_education_verify: item["is_education_verify"],
                is_income_verify: item["is_income_verify"],
                userId: item["userId"]
              });
                
              
              return picUrlsWithSequence;
            });
          



              return{
                ...state,
                list_uploadedProofs_admin: images2,
                total_row_count_uploadedproofs:action.payload[1][0].total_row_count,
                loading_uploadedFiles: false,
              error: action.payload,
            };
    
          case 'UPLOADPROOFS_LIST_ERROR':
            return {
              ...state,
              list_uploadedProofs_admin: [],
              loading_freemmebers: false,
              error: action.payload,
            };




            case 'VERIFYPIC11':
              return {
                ...state,
                verifypic1_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'VERIFYPIC21':
              return {
                ...state,
                verifypic2_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'VERIFYPIC31':
              return {
                ...state,
                verifypic3_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC41':
              return {
                ...state,
                verifypic4_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC51':
              return {
                ...state,
                verifypic5_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC61':
              return {
                ...state,
                verifypic6_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };



              case 'VERIFYPIC71':
              return {
                ...state,
                verifypic7_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };


              case 'VERIFYPIC81':
              return {
                ...state,
                verifypic8_list1: action.payload,
                loading_own_details_admin: false,
                error_own_details: null,
              };

       
   
      default:
        return state;
    }
  };

  export default reducerUploadedFiles;
import { Link } from "react-router-dom";
import styles from "../../../styles/link_menu.module.css";



function MenuTheme(){


    let items_profile = [];


    const handleHover = (event) => {

        event.target.style.border = '2px solid white'; // Change border color on hover
        event.target.style.background = 'linear-gradient(45deg,  var(--menu-gradient-colour1) , var(--menu-gradient-colour2))';
         // Change background on hover
      };
    
      const handleLeave = (event) => {
    
        event.target.style.border = '2px solid transparent'; // Revert to transparent border on leave
        event.target.style.background = 'transparent';
         // Revert background on leave
      };


      const roundedGradientStyle = {
        display: 'inline-block',
        padding: '5px 8px',
        color: '#ffffff',
        borderRadius: '30px',
        transition: 'background 0.3s ease, border 0.3s ease',
        background: 'transparent',
        fontFamily: 'Roboto, sans-serif', 
        fontWeight: 'bold', // Set font weight to bold
        textDecoration: 'none',
        zIndex:'2',
        marginLeft:"20px"
      };


    return(<>
    
    <div id="main-div" style={{
             backgroundColor:"var(--menu-container-colour)"  , boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            width:  "100%" ,
            height:"65px",
            justifyContent:"center",
            position:"relative",
            textAlign:"center",
            top:"0",
            zIndex:"4",
            
            }}>



<div className={styles.dropdown_container} >
     
      <Link
        to="#"
        style={roundedGradientStyle}
        onMouseOver={handleHover}
        onMouseLeave={handleLeave}
      >
     My Profile
      </Link>
     
        <div
        className={styles.dropdown_content}
          style={{zIndex:"1"}}
        >
          {
           
                items_profile.push(<><Link exact to={"/"} ><span >{"link1"}</span></Link></>)
              
            }
          
          {items_profile}
        </div>
      
</div>

</div>
    
    </>);


}

export default MenuTheme;
const reducer = (state, action) => {
    
    switch (action.type) {
      case 'SUPPORT_INSERT_SUCCESS':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_support_insert: action.payload,
          error: null
        };
      case 'SUPPORT_INSERT_ERROR':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_support_insert:[],
          error: action.payload
        };
      case 'SUPPORT_UPDATE_SUCCESS':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_support_update: action.payload,
          error: null
        };
      case 'SUPPORT_UPDATE_ERROR':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_support_update:[],
          error: action.payload
        };
      case 'SUPPORT_TICKET_COMMENTS_INSERT':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportComment: action.payload,
          error: null
        };
      case 'SUPPORT_TICKET_COMMENTS_INSERT_ERROR':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportComment:[],
          error: action.payload
        };
      case 'SUPPORT_COMMENTS_SELECT':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportticket_admin: action.payload,
          error: null
        };
      case 'SUPPORT_COMMENTS_SELECT_ERROR':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportticket_admin:[],
          error: action.payload
        };
      case 'SUPPORT_COMMENTS_PRIORITY':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportticket_admin: action.payload,
          error: null
        };
      case 'SUPPORT_COMMENTS_PRIORITY_ERROR':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportticket_admin:[],
          error: action.payload
        };

      case 'SUPPORT_BY_STATUS':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportticket_admin: action.payload,
          error: null
        };
      case 'SUPPORT_BY_STATUS_ERROR':
        return {
          ...state,
          loading_supportTikcet_admin: false,
          data_supportticket_admin:[],
          error: action.payload
        };


        case 'SUPPORT_TIKCET_COMMENT':
          return {
            ...state,
            loading_supportTikcet_admin: false,
            data_supportComment_select: action.payload,
            error: null
          };
        case 'SUPPORT_TIKCET_COMMENT_ERROR':
          return {
            ...state,
            loading_supportTikcet_admin: false,
            data_supportComment_select:[],
            error: action.payload
          };

          

          case 'SUPPORT_TIKCET_COMMENT_DELETE':
          return {
            ...state,
            loading_supportTikcet_admin: false,
            data_supportComment_select:[],
            error: action.payload
          };

      default:
        return state;
    }

  };
  
  export default reducer;
  
import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import VerifyUserIcon from '@mui/icons-material/AccessibilityNewSharp';
import VerifiedSharp from '@mui/icons-material/VerifiedSharp';
import { useEffect } from 'react';
import { Grid, Pagination } from '@mui/material';
import { useAdminMembersContext } from '../../contexts_admin/member_context';
import { useState } from 'react';
import { ADMIN_COMMUNITY_ID, IMAGE_URL, ROLE } from '../../../common_utils/constants';
import RotatingHeart from '../../../common_utils/loading_animator';
import StylishLabel from '../../component/StylishLabel';
import { DashboardProvider } from '../../contexts_admin/dashboard_context';
import CommunityModal from '../../component/CommunityModal';
import MsgConfirmClose from "../../../../src/app_utils/MsgConfirmClose";
import { useUploadedFilesContextAdmin } from '../../contexts_admin/upoadedfiles_Context';
import StylishLabelOther from '../../component/StylishLabelOther';



function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function UploadedFilesPage() {


    const {uploadedfiles_select , list_uploadedFiles_admin , loading_uploadedFiles ,total_row_count_uploadedfiles , verifypic1 ,verifypic2 , verifypic3 , verifypic4 , verifypic5 ,verifypic6 , verifypic7 ,verifypic8,
        verifypic1_list1 , verifypic2_list1 , verifypic3_list1 ,verifypic4_list1 , verifypic5_list1 , verifypic6_list1 , verifypic7_list1 , verifypic8_list1} = useUploadedFilesContextAdmin();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [msg , setMsg] = useState(false);
    const [id , setId] = useState(false);
    const [userId , setUserId] = useState(false);
    const [imageArray, setImageArray] = useState([{ id: 1, name: '' }]);


    useEffect(()=>{


        if(list_uploadedFiles_admin.length > 0 || loading_uploadedFiles == false){

          

            setLoad(true);
            setImageArray([]);
      
                
            for (let j = 0; j < list_uploadedFiles_admin.length; j++) {
                for (let k = 0; k < list_uploadedFiles_admin[j].length; k++) {
                  const imageUrl = list_uploadedFiles_admin[j][k];

                  console.log(imageUrl);

                 // if(imageUrl.url != undefined && imageUrl.url != null){

                  setImageArray(prevState => [
                    ...prevState,
                    { id: prevState.length + 1, name: imageUrl }
                  ]);

                //  }


                }
              }

           
  
          }else{
  
  
            let communityId_admin  =  "0";
            if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){
  
              communityId_admin = localStorage.getItem("communityId_admin");
              setCommunityId(communityId_admin);
  
            }else{
             communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
              setCommunityId(communityId_admin);
            }
  
              uploadedfiles_select({
            "communityId":communityId_admin,
            "offset":"0" 
         });

        }


    },[list_uploadedFiles_admin]);

    useEffect(()=>{

       if(verifypic1_list1.affectedRows >= 1 || verifypic2_list1.affectedRows >= 1 || verifypic3_list1.affectedRows >= 1 || verifypic4_list1.affectedRows >= 1
        || verifypic5_list1.affectedRows >= 1 || verifypic6_list1.affectedRows >= 1 || verifypic7_list1.affectedRows >= 1 || verifypic8_list1.affectedRows >= 1){

        setImageArray([]);

        uploadedfiles_select({
            "communityId":communityId,
            "offset":currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10),  
         });


       }






    },[verifypic1_list1 , verifypic2_list1 ,verifypic3_list1 , verifypic4_list1 ,verifypic5_list1 ,verifypic6_list1 ,verifypic7_list1 ,verifypic8_list1]);

    



    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  
  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setImageArray([]);

        localStorage.setItem("communityId_admin" , communityId_admin);
        setCommunityId(communityId_admin);

        uploadedfiles_select({
            "communityId":communityId_admin,
            "offset":"0" 
         });
      
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);

        uploadedfiles_select({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),  
          });
  
    }

    const handlecancelmsg = () => {

      setMsg(false);

    }

    const handleclosemsg = () =>{


      setMsg(false);

      if(id == 1){
        verifypic1({
         "isverify":"1",
          "userId":userId,
          "communityId":communityId 
       });
   }else if(id == 2){
     verifypic2({
      "isverify":"1",
      "userId":userId,
          "communityId":communityId 
    });
  }else if(id == 3){
   verifypic3({
    "isverify":"1",
    "userId":userId,
          "communityId":communityId 
  });
 }else if(id == 4){
   verifypic4({
    "isverify":"1",
    "userId":userId,
          "communityId":communityId 
  });
 }else if(id == 5){
   verifypic5({
    "isverify":"1",
    "userId":userId,
          "communityId":communityId 
  });
 }else if(id == 6){
   verifypic6({
    "isverify":"1",
    "userId":userId,
    "communityId":communityId   
  });
 }else if(id == 7){
   verifypic7({
    "isverify":"1",
    "userId":userId,
    "communityId":communityId   
  });
 }else if(id == 8){
   verifypic8({
    "isverify":"1",
    "userId":userId,
     "communityId":communityId   
  });
 }



    }

    
    const verifyImage = (id , userId) => {

      setId(id);
      setUserId(userId);
    
      setMsg(true);
      
      
  
    }

    let role = localStorage.getItem(ROLE);

  return (
    <>
    { load == false ?  <RotatingHeart/> : 
       
       <div  style={{backgroundColor:"#FAFBFD"}}>

        { role == "developer" ? <StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "50px" : "50px" , width: "100%" , paddingRight:"15px"   }}>
     
        
        <ImageList
      sx={{
        width: 1000,
        height: 650,
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: 'translateZ(0)',
      }}
      rowHeight={220}
      gap={1}
      cols={5}
      
    >
      {imageArray.map((item , index) => {
        const cols = item.featured ? 2 : 1;
        const rows = item.featured ? 2 : 1;

        return (
          <ImageListItem key={index+1} cols={cols} rows={rows} onClick={()=>verifyImage(item.name.sequence , item.name.userId)}>
            <img
              {...srcset(IMAGE_URL+"uploads/"+item.name.url, 250, 200, rows, cols)}
              alt={"No Image"}
              loading="lazy"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 60%, rgba(0,0,0,0) 100%)', 
              }}
              title={ item.name.isverifypic == "0" ? "("+item.name.userId+" )Click to Approve" : item.name.isverifypic == "1" ? "("+item.name.userId+") Verified" :  "("+item.name.userId+" )Rejected"}
              position="top"
              actionIcon={
               
                <IconButton
                  sx={{ color: item.name.isverifypic == "0" ? "#d3d3d3" : item.name.isverifypic == "1" ?  "#00cc00"  : "#e32636" }}
                  aria-label={"Click to Approve"}
                >
                  <VerifiedSharp  />
                </IconButton>
                
              }
              actionPosition="left"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  


    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_uploadedFiles_admin.length > 0 && Math.ceil(total_row_count_uploadedfiles / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>

    </Grid>


    {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

    <MsgConfirmClose
          isOpen={msg}
          title={"Approve Picture Dialog"}
          content={"Are you sure you want to approve this picture , Please make sure otherwise in order to unverify it you have to search in uploaded files?"}
          closeText={"Approve Picture"}
          handleClose={handleclosemsg}
          handleCancel={handlecancelmsg}
          cancelText={"Cancel"}
          />


    </div>

    }
    </>
  );


}


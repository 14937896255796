import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import contactReducer from '../../reducer_admin/contactReducerAdmin';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../../../common_utils/constants';

const contactContextAdmin = createContext();

const initialState = {
  data_contact_admin: [],
  loading_admin: true,
  error: null,
  contactInsert_admin:[],
  contactUpdate_admin:[],
  data_city_perm_admin:[],
  data_city_work_admin:[]
};

let API = `${BASE_URL}`;


export const ContactProviderAdmin = ({ children }) => {

const [state, dispatch] = useReducer(contactReducer , initialState);

const fetchContactItemsAdmin = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/contact_details_edit` , params);
      

      dispatch({ type: 'CONTACT_DATA_FETCH_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_DATA_FETCH_ERROR_ADMIN', payload: error });
    }
  };


  const submit_contactdetailsAdmin = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/contact_detail` , article);
      dispatch({ type: 'CONTACT_DETAILS_SUBMIT_ADMIN', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_DETAILS_SUBMIT_ERROR_ADMIN', payload: error });
    }
  };


  const update_contactdetailsAdmin = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/contact_detail` , article);
      dispatch({ type: 'CONTACT_DETAILS_UPDATE_ADMIN', payload: response.data });
    } catch (error) {
      dispatch({ type: 'CONTACT_DETAILS_UPDATE_ERROR_ADMIN', payload: error });
    }
  };


  const fetch_cityAdmin = async (passdata , type) => {
    try {
      if(type == 1){

        const response = await axios.post(`${API}`+`fetch_data/city_fetch` , passdata);
      dispatch({ type: 'CITY_FETCH_PERM_ADMIN', payload: response.data.data });

      }else{
        const response = await axios.post(`${API}`+`fetch_data/city_fetch` , passdata);
        dispatch({ type: 'CITY_FETCH_WORK_ADMIN', payload: response.data.data });
      }
      
    } catch (error) {
      dispatch({ type: 'CONTACT_DETAILS_UPDATE_ERROR_ADMIN', payload: error });
    }
  };


  


 
  
  return <contactContextAdmin.Provider value={{ ...state , dispatch , fetchContactItemsAdmin , submit_contactdetailsAdmin , update_contactdetailsAdmin , fetch_cityAdmin}}>{children}</contactContextAdmin.Provider>;
};

export const useContactAdminContext = () => useContext(contactContextAdmin);
import React from 'react';
import { Grid, TextField, MenuItem , Typography ,Button } from '@mui/material';
import { ROLE } from '../../../common_utils/constants';
import { useState} from 'react';
import { useLocation , useNavigate } from "react-router-dom";
import { usePaymentContext } from '../../contexts_admin/payment_context';
import { useEffect } from 'react';

const UpdateMembership = () => {

    
     const {membership_update , data_membrship_update} = usePaymentContext();

     const [issubmit , setIsSubmit] = useState(false);

    let role = localStorage.getItem(ROLE);


    const navigate = useNavigate();
    useEffect(()=>{

        if(data_membrship_update.affectedRows >= 1 && issubmit == true){

            alert("Updated Successfully");

            navigate("/list_membership");
            window.location.reload();

          
       }


    },[data_membrship_update]);



    const location = useLocation();

    useEffect(()=>{

        const details = location.state.myObject.key;

        const initialMembership = {
            image: details.image,
        plan_name: details.plan_name,
        plan_price: details.plan_price,
        details:  details.details,
        validity_days: details.validity_days,
        profile_display: details.profile_display,
        gallery_view: details.gallery_view,
        chatoption: details.chatoption,
        express_interest: details.express_interest,
        number_express_interest: details.number_express_interest,
        horoscopeview: details.horoscopeview,
        pdf_access: details.pdf_access,
        num_pdf_access: details.num_pdf_access,
        verified_phonenumbers: details.verified_phonenumbers,
        allowed_contacts: details.allowed_contacts,
        number_contacts: details.number_contacts,
        number_horoscope: details.number_horoscope,
        number_chat: details.number_chat,
        number_video: details.number_video,
        verified_id_docs: details.verified_id_docs,
        discount_type: details.discount_type,
        coupon_code: details.coupon_code,
        discount: details.discount
          };

        setFormData(initialMembership);


    },[location]);




    const [formData, setFormData] = useState({
        image: '',
        plan_name: '',
        plan_price: '',
        details: '',
        validity_days: '',
        profile_display: '',
        gallery_view: '',
        chatoption: '',
        express_interest: '',
        number_express_interest: '',
        horoscopeview: '',
        pdf_access: '',
        num_pdf_access: '',
        verified_phonenumbers: '',
        allowed_contacts: '',
        number_contacts: '',
        number_horoscope: '',
        number_chat: '',
        number_video: '',
        verified_id_docs: '',
        discount_type: '',
        coupon_code: '',
        discount: '',
        communityId: ''
      });


      

    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const update_membership = () => {

        let communityId = localStorage.getItem("communityId_admin");

        membership_update({ "image": formData.image,
        "plan_name": formData.plan_name,
        "plan_price": formData.plan_price,
        "details": formData.details,
        "validity_days": formData.validity_days,
        "profile_display": formData.profile_display,
        "gallery_view": formData.gallery_view,
        "chatoption": formData.chatoption,
        "express_interest": formData.express_interest,
        "number_express_interest": formData.number_express_interest,
        "horoscopeview": formData.horoscopeview,
        "pdf_access": formData.pdf_access,
        "num_pdf_access": formData.num_pdf_access,
        "verified_phonenumbers": formData.verified_phonenumbers,
        "allowed_contacts": formData.allowed_contacts,
        "number_contacts": formData.number_contacts,
        "number_horoscope": formData.number_horoscope,
        "number_chat": formData.number_chat,
        "number_video": formData.number_video,
        "verified_id_docs": formData.verified_id_docs,
        "discount_type": formData.discount_type,
        "coupon_code": formData.coupon_code,
        "discount": formData.discount,
        "ishidden": 0,
        "Id":location.state?.myObject.key.Id,
        "communityId":communityId});

        setIsSubmit(true);

      }


  return (

    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px"  , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>

<Typography variant="h5" style={{ color: '#800080' , backgroundColor:"pink" , padding:"10px 20px" , fontWeight:"bold"}}>Update Membership</Typography>

    <Grid container spacing={2} style={{width:"100%", display:"flex" , justifyContent:"center"}}>

      <Grid item xs={6}>
        <TextField label="Image" variant="outlined" fullWidth value={formData.image}  name='image' onChange={handleChange} />
      </Grid>
      <Grid item xs={6}>
        <TextField label="Plan Name" variant="outlined" fullWidth  value={formData.plan_name} name='plan_name' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Plan Price" variant="outlined" fullWidth value={formData.plan_price}  name='plan_price' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Validity Days" variant="outlined" fullWidth value={formData.validity_days}  name='validity_days' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Profile Display (Give Description one line)" variant="outlined" fullWidth value={formData.profile_display} name='profile_display' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
      <Grid item xs={6}>
        <TextField
          select
          label="Chat Option"
          variant="outlined"
          value={formData.chatoption}
          name='chatoption'
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </TextField>
      </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField label="Gallery View" variant="outlined" fullWidth value={formData.gallery_view}  name='gallery_view' onChange={handleChange}/>
      </Grid>
      <Grid item xs={12}>
        <TextField label="Horoscope View" variant="outlined" fullWidth value={formData.horoscopeview}  name='horoscopeview'  onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Number of Express Interest" variant="outlined" fullWidth value={formData.number_express_interest} name='number_express_interest' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Number of PDF Access" variant="outlined" fullWidth value={formData.pdf_access} name='pdf_access'  onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Verified Phone Numbers" variant="outlined" fullWidth value={formData.verified_phonenumbers}  name='verified_phonenumbers' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Allowed Contacts" variant="outlined" fullWidth  value={formData.allowed_contacts}  name='allowed_contacts' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Number of Contacts" variant="outlined" fullWidth  value={formData.number_contacts}  name='number_contacts' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Number of Horoscope" variant="outlined" fullWidth  value={formData.number_horoscope}  name='number_horoscope' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Number of Chat" variant="outlined" fullWidth  value={formData.number_chat} name='number_chat' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Number of Video" variant="outlined" fullWidth  value={formData.number_video} name='number_video' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Verified ID Docs" variant="outlined" fullWidth  value={formData.verified_id_docs} name='verified_id_docs' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Coupon Code" variant="outlined" fullWidth  value={formData.coupon_code} name='coupon_code' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField label="Discount" variant="outlined" fullWidth  value={formData.discount}  name='discount' onChange={handleChange}/>
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          label="Discount Type"
          variant="outlined"
          value={formData.discount_type}
          name='discount_type'
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="flash">Flash Sale</MenuItem>
          <MenuItem value="featured">Featured Sale</MenuItem>
          <MenuItem value="special">Special Discount</MenuItem>
          <MenuItem value="normal">Normal</MenuItem>
        </TextField>
      </Grid>


      <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"35px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , width:"50%"}} onClick={update_membership}>Update Membership</Button>


    </Grid>

    </div>
  );
};

export default UpdateMembership;

import { useState } from "react";
import { ADMIN_COMMUNITY_ID, IMAGE_URL, ROLE } from "../../../common_utils/constants";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { useAdminMembersContext } from "../../contexts_admin/member_context";
import { useEffect } from "react";
import StylishLabel from "../../component/StylishLabel";
import RotatingHeart from "../../../common_utils/loading_animator";
import { Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import { createTheme } from "@mui/system";
import { useTheme } from "@emotion/react";
import { Navigate, useNavigate  } from "react-router-dom";
import React from "react";








function PremiumMembers(){

    const { PremiumMembers ,  data_paid_members , loading_freemmebers , clearlist_member_details_own_admin , clear_featuredprofiles} = useAdminMembersContext();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();


    const theme = useTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '& $notchedOutline': {
                borderColor: 'red', // Change this to your desired outline color
              },
              '&:hover $notchedOutline': {
                borderColor: '#FFC0CB', // Change this to your desired outline color on hover
              },
            },
          },
        },
      },
    });


    useEffect(()=>{

        if(data_paid_members.length > 0 || loading_freemmebers == false){

          setLoad(true);

        }else{

          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
             communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

            PremiumMembers({
                "communityId":communityId_admin,
                "offset":"0",
                "query":""  
              });

        }
       
    },[data_paid_members]);


    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  
  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        PremiumMembers({
            "communityId":communityId_admin,
            "offset":"0",
            "query":""  
          });
  
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
    
        PremiumMembers({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
            "query":query  
          });
  
    }
  
    const [query, setValue] = useState('');

    const handleChange = (event) => {
      setValue(event.target.value);

      console.log(event.target.value);

      PremiumMembers({
        "communityId":communityId,
        "offset": "0",
        "query":event.target.value  
      });

    };




    const handleUserdetailsViewClick = (userId , username , profile_id) => {

      const about_user = {
        userId:userId ,
        communityId: communityId,
        username: username,
        profile_id: profile_id
      };

      clearlist_member_details_own_admin();
      clear_featuredprofiles();
      
      navigate(`/user_details_byid?userId=${about_user.userId}&communityId=${about_user.communityId}&username=${about_user.username}&profile_id=${about_user.profile_id}`);

    }


      
      let role = localStorage.getItem(ROLE);

    return (<>
    
   { load == false ?  <RotatingHeart/> : 
       
       <div>

        { role == "developer" ? <StylishLabel  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px" , backgroundColor:"#FAFAFA"  }}>

<Grid key={5} item xs={12}>
  <ThemeProvider theme={theme}>
  <TextField
      label="Enter text to search"
      value={query}
      onChange={handleChange}
      variant="outlined"

      fullWidth
    />
    </ThemeProvider>
    </Grid>
        <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>



<TableContainer component={Paper}>
  <Table>
    <TableHead>
      <Typography  variant="h6" align="left" width="300px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Premium/Paid Members</Typography>
      <TableRow style={{width:"100%"}}>
        <TableCell style={{ width: '5%' }}>Profile Picture</TableCell>
        <TableCell style={{ width: '5%' }}>Name</TableCell>
        <TableCell style={{ width: '5%' }}>ProfileId</TableCell>
        <TableCell style={{ width: '5%' }}>Gender</TableCell>
        <TableCell style={{ width: '5%' }}>EmailID</TableCell>
        <TableCell style={{ width: '5%' }}>Birthdate</TableCell>
        <TableCell style={{ width: '5%' }}>Mobile</TableCell>
        <TableCell style={{ width: '5%' }}>View Details</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data_paid_members.length > 0 && data_paid_members[0].map((free_members, index) => (
        <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
          <TableCell>
          {((currentPage-1)*10)+(index+1)+".        "} <img src={IMAGE_URL+"uploads/"+free_members.pic1} alt="No Image" style={{ width: 50, height: 50 }} />
          </TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.name+" "+free_members.surname}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.profile_id}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.gender}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.emailid}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px' }}>{free_members.birthdate}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px' }}>{free_members.mobile}</TableCell>
          <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUserdetailsViewClick(free_members.Id , free_members.name+" "+free_members.surname , free_members.profile_id)}>
      {"Click to View"}
    </span></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={data_paid_members.length > 0 && Math.ceil(data_paid_members[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>


</Grid>



        </Grid>
    
        {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

    
    </div>
   }
    </>);


}

export default PremiumMembers;
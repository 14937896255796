import React, { createContext, useContext , useReducer, useEffect } from 'react';
import axios from 'axios';
import searchReducer from '../reducer/searchReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG } from '../common_utils/constants';

const searchContext = createContext();

const initialState = {
  regular_search_list: [],
  advanced_search_list:[],
  iit_iim_nit_list:[],
  matches:[],
  matches_result:[],
  matches_joined:[],
  loading_matches: true,
  loading_mutual:true,
  loading_match_joined:true,
  loading_advanced_result:true,
  loading_regular_search:true,
  loading:true,
  error: null,
  insert_shortlist:[],
  insert_interest:[],
  insert_notification_data:[]
};

let API = `${BASE_URL}`;


export const SearchProvider = ({ children }) => {

const [state, dispatch] = useReducer(searchReducer , initialState);

const fetchRegularSearch = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/regular_search` , params);

      dispatch({ type: 'REGULAR_SEARCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'REGULAR_SEARCH_ERROR', payload: error });
    }
  };


  const fetchAdvancedSearch = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/advanced_Search` , params);

      dispatch({ type: 'ADVANCED_SEARCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ADVANCED_SEARCH_ERROR', payload: error });
    }
  };


  const fetchIIT_IIM_NIT_SEARCH = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/iit_nit_iim_search` , params);

      dispatch({ type: 'IIT_NIT_IIM_SEARCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'IIT_NIT_IIM_SEARCH_ERROR', payload: error });
    }
  };




  const MATCH_RESULT = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/` , params);

      dispatch({ type: 'MATCH_RESULT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'MATCH_RESULT_ERROR', payload: error });
    }
  };


  const MUTUAL_MATCH_RESULT = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/mutual_match` , params);

      dispatch({ type: 'MUTUAL_MATCH_RESULT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'MUTUAL_MATCH_RESULT_ERROR', payload: error });
    }
  };

  

  const MATCHES_JOINED_RESULT = async (params) => {
    try {
      const response = await axios.post(`${API}`+`matches/match_just_joined` , params);

      dispatch({ type: 'MATCHES_JOINED_RESULT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'MATCHES_JOINED_RESULT_ERROR', payload: error });
    }
  };


  

  const insertShortlist = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/shortlist` , params);

      dispatch({ type: 'SHORTLIST_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SHORTLIST_ERROR', payload: error });
    }
  };

  const express_interest_insert = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/express_interest_insert` , params);

      dispatch({ type: 'EXPRESS_INTEREST_INSERT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'EXPRESS_INTEREST_INSERT_ERROR', payload: error });
    }
  };


  const insertNotification = async (params) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification` , params);

      dispatch({ type: 'INSERT_NOTIFICATION', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_ERROR', payload: error });
    }
  };



  return <searchContext.Provider value={{ ...state , dispatch , fetchRegularSearch , insertShortlist , express_interest_insert , fetchAdvancedSearch , fetchIIT_IIM_NIT_SEARCH ,MATCH_RESULT  ,MUTUAL_MATCH_RESULT ,MATCHES_JOINED_RESULT , insertNotification}}>{children}</searchContext.Provider>;
};

export const useSearchContext = () => useContext(searchContext);
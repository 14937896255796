import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerFamily from '../reducer/familyReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const familyContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  family_insert:[],
  family_update:[]
};

let API = `${BASE_URL}`;


export const FamilyProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerFamily , initialState);

const fetchFamilyItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/family_details_edit` , params);

      dispatch({ type: 'FAMILY_DATA_FETCH', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FAMILY_DATA_FETCH_ERROR', payload: error });
    }
  };



  const submit_familydetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_family` , article);
      dispatch({ type: 'INSERT_FAMILY_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_FAMILY_DETAILS_ERROR', payload: error });
    }
  };


  const update_familydetails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_family` , article);
      dispatch({ type: 'UPDATE_FAMILY_DETAILS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_FAMILY_DETAILS_ERROR', payload: error });
    }
  };



  useEffect(() => {
       
    fetchFamilyItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <familyContext.Provider value={{ ...state , dispatch , fetchFamilyItems , submit_familydetails , update_familydetails}}>{children}</familyContext.Provider>;
};

export const useFamilyContext = () => useContext(familyContext);
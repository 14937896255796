
const reducer = (state, action) => {
    switch (action.type) {

      case 'INSERT_UPDATE_VISIBILITY':
        return {
          ...state,
          data_insert_visibility: action.payload,
          loading_webtheme: false,
          error: null,
        };

      case 'INSERT_UPDATE_VISIBILITY_ERROR':
        return {
          ...state,
          data_insert_visibility: [],
          loading_webtheme: false,
          error: action.payload,
        };



        case 'INSERT_UPDATE_THEME':
            return {
              ...state,
              data_insert_webtheme: action.payload,
              loading_webtheme: false,
              error: null,
            };
    
          case 'INSERT_UPDATE_THEME_ERROR':
            return {
              ...state,
              data_insert_webtheme: [],
              loading_webtheme: false,
              error: action.payload,
            };



            case 'SELECT_WEB_THEME':
            return {
              ...state,
              data_select_webtheme: action.payload,
              loading_webtheme: false,
              error: null,
            };
    
          case 'SELECT_WEB_THEME_ERROR':
            return {
              ...state,
              data_select_webtheme: [],
              loading_webtheme: false,
              error: action.payload,
            };




            case 'SELECT_WEB_VISIBILITY':
                return {
                  ...state,
                  data_select_visibility: action.payload,
                  loading_webtheme: false,
                  error: null,
                };
        
              case 'SELECT_WEB_VISIBILITY_ERROR':
                return {
                  ...state,
                  data_select_visibility: [],
                  loading_webtheme: false,
                  error: action.payload,
                };



                case 'SELECT_HOMEPAGE_SLIDER':
                  return {
                    ...state,
                    data_homepage_slider: action.payload,
                    loading_webtheme: false,
                    error: null,
                  };
          
                case 'SELECT_HOMEPAGE_SLIDER_ERROR':
                  return {
                    ...state,
                    data_homepage_slider: [],
                    loading_webtheme: false,
                    error: action.payload,
                  };
  


                  case 'SELECT_HOMEPAGE_SLIDER_INSERT':
                  return {
                    ...state,
                    data_homepage_slider_insert: action.payload,
                    loading_webtheme: false,
                    error: null,
                  };
          
                case 'SELECT_HOMEPAGE_SLIDER_INSERT_ERROR':
                  return {
                    ...state,
                    data_homepage_slider_insert: [],
                    loading_webtheme: false,
                    error: action.payload,
                  };
  


                  case 'SELECT_HOMEPAGE_SLIDER_UPDATE':
                  return {
                    ...state,
                    data_homepage_slider_update: action.payload,
                    loading_webtheme: false,
                    error: null,
                  };
          
                case 'SELECT_HOMEPAGE_SLIDER_UPDATE_ERROR':
                  return {
                    ...state,
                    data_homepage_slider_update: [],
                    loading_webtheme: false,
                    error: action.payload,
                  };
  


                  case 'ANDROID_APP_THEME_INSERT':
                    return {
                      ...state,
                      data_insert_androidtheme: action.payload,
                      loading_webtheme: false,
                      error: null,
                    };
            
                  case 'ANDROID_APP_THEME_INSERT_ERROR':
                    return {
                      ...state,
                      data_insert_androidtheme: [],
                      loading_webtheme: false,
                      error: action.payload,
                    };
    

                    case 'ANDROID_APP_THEME_UPDATE':
                    return {
                      ...state,
                      data_update_androidtheme: action.payload,
                      loading_webtheme: false,
                      error: null,
                    };
            
                  case 'ANDROID_APP_THEME_UPDATE_ERROR':
                    return {
                      ...state,
                      data_update_androidtheme: [],
                      loading_webtheme: false,
                      error: action.payload,
                    };
    



                    case 'ANDROID_APP_THEME_FETCH':
                    return {
                      ...state,
                      data_select_androidtheme: action.payload,
                      loading_webtheme: false,
                      error: null,
                    };
            
                  case 'ANDROID_APP_THEME_FETCH_ERROR':
                    return {
                      ...state,
                      data_select_androidtheme: [],
                      loading_webtheme: false,
                      error: action.payload,
                    };

      default:
        return state;
    }
  };

  export default reducer;
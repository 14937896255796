


import { useState } from "react";
import { useBlogsContextAdmin } from "../../contexts_admin/blogs_context";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useEffect } from "react";
import { Chip, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import StylishLabel from "../../component/StylishLabel";
import CommunityModal from "../../component/CommunityModal";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { useNavigate } from "react-router-dom";
import { useEventContextAdmin } from "../../contexts_admin/event_context";
import EventDetails from "./EventDetails";
import StylishLabelOther from "../../component/StylishLabelOther";
import React from "react";



function SamuhLaganList(){

    const {list_event_samuhlagan_admin  , fetchEventsSamuhlagan  , loading_event , total_row_count_samuhlagan} = useEventContextAdmin();
    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [Index, setIndex] = useState(-1);
    const [openEvents , setopenEvent] = useState(false);

   

    let role = localStorage.getItem(ROLE);


    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });

      


    useEffect(()=>{

        if(list_event_samuhlagan_admin.length > 0 || loading_event == false){

          setLoad(true);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1 || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

          fetchEventsSamuhlagan({
                "communityId":communityId_admin,
                "offset":"0",
                "query":""
              });

        }
       
    },[list_event_samuhlagan_admin]);




    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  

  
      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        fetchEventsSamuhlagan({
            "communityId":communityId_admin,
            "offset":"0",
            "query":""  
          });
  
      }



      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);
    
        fetchEventsSamuhlagan({
            "communityId":communityId,
            "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
            "query":query  
          });
  
    }
  
    const [query, setValue] = useState('');

    const handleChange = (event) => {
      setValue(event.target.value);

      fetchEventsSamuhlagan({
        "communityId":communityId,
        "offset": "0",
        "query":event.target.value  
      });

    };

    const handleUserdetailsViewClick = (index) => {
      
      setIndex(index);
      hanldeOpenEvent();

    }

      const hanldeOpenEvent = () => {
        setopenEvent(true);
      }
  
  
      const handleCloseEvent = (isopen) => {
        setopenEvent(isopen);
      }

    
    const navigate = useNavigate();
    const addSamuhLaganEvents = () => {

       navigate("/add_samuhlagan/"+communityId);

    }

   
    const handleUpdateEvent = (index) => {

      navigate('/update_event', {
        // Define your object here
        state: {
          // Define your object here
          myObject: { key: list_event_samuhlagan_admin[index] }
        }
      });


    }



    return (<>
    
    {  load == false ?  <RotatingHeart/> : 
       
       <div  style={{backgroundColor:"#FAFBFD"}}>

        { role == "developer" ? <div><StylishLabelOther  text={"Select Community"}  clickSelectCommunity={handleClickCommunity} style={{width:"140%"}} /></div> : ""}

        <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "0px" : "0px" , width: "100%" , paddingRight:"15px"   }}>
        
        <Grid key={5} item xs={12}>
  <ThemeProvider theme={theme}>
  <TextField
      label="Enter text to search"
      value={query}
      onChange={handleChange}
      variant="outlined"

      fullWidth
    />
    </ThemeProvider>
    </Grid>



    <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>



<TableContainer component={Paper}>
  <Table>
    <TableHead>
      <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>SamuhLagan</Typography>
      <Typography  variant="h6" align="left" width="300px" style={{position:"absolute" , right:"15px" , marginTop:"-32px" ,paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}} onClick={addSamuhLaganEvents}>Click To Add Event</Typography>
      <TableRow style={{width:"100%"}}>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Date</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Time</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Venue/Location</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>View Details</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>View Groups</TableCell>
        <TableCell style={{ fontWeight:"bold" , color:"black" }}>Update Event</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {list_event_samuhlagan_admin.length > 0 && list_event_samuhlagan_admin.map((free_members, index) => (
        <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
            <TableCell style={{ color: 'black', fontSize: '15px'  }}>{(index+1)}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.date}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.time}</TableCell>
          <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.venue}</TableCell>
          <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUserdetailsViewClick(index)}>
      {"View Details"}
    </span></TableCell>
    <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUserdetailsViewClick(index)}>
      {"View Group"}
    </span></TableCell>
    <TableCell><span
      style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }} onClick={() => handleUpdateEvent(index)}>
      {"Update Events"}
    </span></TableCell>

        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_event_samuhlagan_admin.length > 0 && Math.ceil(total_row_count_samuhlagan / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>





        </Grid>

        </Grid>
    

        {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
        {openEvents == true ? <EventDetails isopen={true} setClose={handleCloseEvent}   Details={list_event_samuhlagan_admin[Index]}  /> : ""}

    </div>

    }
    
    </>);


}

export default SamuhLaganList;
const reducerContact = (state, action) => {
    switch (action.type) {
      case 'CONTACT_DATA_FETCH':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'CONTACT_DATA_FETCH_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };
        case 'CITY_FETCH_PERM':
        return {
          ...state,
          data_city_perm: action.payload,
          loading: false,
          error: null,
        };
        case 'CITY_FETCH_WORK':
        return {
          ...state,
          data_city_work: action.payload,
          loading: false,
          error: null,
        };
     
        case 'CONTACT_DETAILS_SUBMIT':
      return {
        ...state,
        contactInsert: action.payload,
        loading: false,
        error: null,
      };
    case 'CONTACT_DETAILS_SUBMIT_ERROR':
      return {
        ...state,
        contactInsert: [],
        loading: false,
        error: action.payload,
      };

      case 'CONTACT_DETAILS_UPDATE':
      return {
        ...state,
        contactUpdate: action.payload,
        loading: false,
        error: null,
      };
    case 'CONTACT_DETAILS_UPDATE_ERROR':
      return {
        ...state,
        contactUpdate: [],
        loading: false,
        error: action.payload,
      };

   
      default:
        return state;
    }
  };

  export default reducerContact;
import React, { useState } from "react";
import { usePartnerPrefsContext } from "../../contexts/partnerPrefsContext";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { DropdownSelect, MultiselectDropdown, StyledTextField } from "../../app_utils/input_fields";
import { generateAge } from "../../common_utils/utils";
import { produce } from "immer";
import { CSSTransition } from "react-transition-group";
import { Button, Grid } from "@mui/material";
import { annual_income_english, height } from "../../data/data";
import { AGE_FROM_P_LBL, AGE_FROM_P_VAL, AGE_TO_P_LBL, AGE_TO_P_VAL, ANNUAL_INCOME_P_VAL, BODY_TYPE_P_VAL, CASTE_P_LBL, CASTE_P_VAL, CITY_P_VAL, COMMUNITY_ID, DIET_TYPE_P_VAL, DRINK_TYPE_P_VAL, EDUCATION_P_VAL, FAMILY_VALUE_P_VAL, HEIGHT_FROM_P_LBL, HEIGHT_FROM_P_VAL, HEIGHT_TO_P_LBL, HEIGHT_TO_P_VAL, MARITAL_STATUS_P_LBL, MARITAL_STATUS_P_VAL, OCCUPATION_P_VAL, PROFILE_ID, SKINTONE_P_VAL, SMOKE_TYPE_P_VAL, STATE_P_VAL, SUBCASTE_P_LBL, SUBCASTE_P_VAL, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import Prefernce_filter from "./preference_filter";
import "../../styles/animationfade.css";
import { findByRole } from "@testing-library/react";
import RotatingHeart from "../../common_utils/loading_animator";
import { useNavigate } from "react-router-dom";
import { toBeRequired } from "@testing-library/jest-dom/matchers";




const PartnerPrefs = ({passPath}) => {


    const { data, loading, error, data_city_multiple, fetch_city_multiple, partner_insert, partner_update
        , submit_partner_prefs_details, update_partner_prefs_details } = usePartnerPrefsContext();
        
    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();
    const [ageRange, setAgeRange] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [degreeList, setDegreeList] = useState([]);
    const [occupationList, setOccupation] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [caste, setCaste] = useState([]);
    const [subcaste, setsubCaste] = useState([]);
    const [skintone, setskintone] = useState([]);
    const [diet, setdiet] = useState([]);
    const [family_value, setFamilyValue] = useState([]);
    const [annual_income, setannualIncome] = useState([]);
    const [body_type, setBodytype] = useState([]);
    const [smoke_type, setSmoketype] = useState([]);
    const [drink_type, setDrinktype] = useState([]);
    const [state_list_comma, setStateComma] = useState("");
    const [initial , setInitial] = useState(false);

    const [open, setopen] = useState(false);
    const [load, loadset] = useState(true);

    const handleClose = () => {

        setopen(false);
    }


    const state_lngwise = (state) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const state_list = [{ label: 'Any', value: 'Any' }, ...state.map(user => ({
                label: user.state_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setStateList(state_list);
        }


    }

    const city_lngwise = (city) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const city_list = [{ label: 'Any', value: 'Any' }, ...city.map(user => ({
                label: user.city_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setCityList(city_list);
        }


    }

    const degree_lngwise = (degree) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        }


    }

    const occupation_lngwise = (occupation) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occupation,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        }


    }




    useEffect(() => {

        passPath(t('partner_preference_label'));
        setAnimated(true);
        setAgeRange(generateAge(18, 70));

        if (data.length > 0) {

            state_lngwise(data[2]);
            city_lngwise(data[3]);
            degree_lngwise(data[4]);
            occupation_lngwise(data[6]);

            const marital_list = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[7]).map(key => ({
                label: data[7][key].label,  // Adjust based on your data structure
                value: data[7][key].value         // Adjust based on your data structure
            }))];

            const caste = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[8]).map(key => ({
                label: data[8][key].caste,  // Adjust based on your data structure
                value: data[8][key].Id         // Adjust based on your data structure
            }))];

            const subcaste = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[9]).map(key => ({
                label: data[9][key].subcaste,  // Adjust based on your data structure
                value: data[9][key].Id         // Adjust based on your data structure
            }))];

            const skintone = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[10]).map(key => ({
                label: data[10][key].label,  // Adjust based on your data structure
                value: data[10][key].value         // Adjust based on your data structure
            }))];

            const diet_type = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[11]).map(key => ({
                label: data[11][key].label,  // Adjust based on your data structure
                value: data[11][key].value         // Adjust based on your data structure
            }))];

            const family_value = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[12]).map(key => ({
                label: data[12][key].label,  // Adjust based on your data structure
                value: data[12][key].value         // Adjust based on your data structure
            }))];

            const body_value = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[13]).map(key => ({
                label: data[13][key].label,  // Adjust based on your data structure
                value: data[13][key].value         // Adjust based on your data structure
            }))];

            const drink_type = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[14]).map(key => ({
                label: data[14][key].label,  // Adjust based on your data structure
                value: data[14][key].value         // Adjust based on your data structure
            }))];

            const smoke_type = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[15]).map(key => ({
                label: data[15][key].label,  // Adjust based on your data structure
                value: data[15][key].value         // Adjust based on your data structure
            }))];

            const annual_income = [{ label: 'Any', value: 'Any' }, ...Object.keys(data[16]).map(key => ({
                label: data[16][key].label,  // Adjust based on your data structure
                value: data[16][key].value         // Adjust based on your data structure
            }))];



            setMaritalStatus(marital_list);
            setCaste(caste);
            setsubCaste(subcaste);
            setskintone(skintone);
            setdiet(diet_type);
            setFamilyValue(family_value);
            setBodytype(body_value);
            setDrinktype(drink_type);
            setSmoketype(smoke_type);
            setannualIncome(annual_income);

            


            const setup = data[0]["0"];
            if (setup != undefined && initial == false) {
                const nextState = produce((draft) => {

                    setStateComma(setup.state);

                    draft["age_from"] = setup.age_range.split("-")[0];
                    draft["age_to"] = setup.age_range.split("-")[1];
                    draft["height_from"] = setup.height_range.split("-")[0];
                    draft["height_to"] = setup.height_range.split("-")[1];
                    draft["marital_status"] = setup.marital_status;
                    draft["caste"] = setup.caste;
                    draft["subcaste"] = setup.subcaste;
                    draft["skintone"] = setup.skintone;
                    draft["state"] = setup.state;
                    draft["city"] = setup.city;
                    draft["education"] = setup.education;
                    draft["occupation"] = setup.occupation;
                    draft["diet_type"] = setup.diet_type;
                    draft["family_value"] = setup.family_value;
                    draft["annual_income"] = setup.annual_income;
                    draft["body_type"] = setup.body_type;
                    draft["smoke_type"] = setup.smoke_type;
                    draft["drink_type"] = setup.drink_type;
                    draft["detail"] = setup.partner_details;

                });

                setPartnerPrefsField(nextState);

            }


        }


        if (data_city_multiple.length > 0) {
           city_lngwise(data_city_multiple);
        }


    }, [data , data_city_multiple ]);

    useEffect(()=>{

        if (state_list_comma != "") {


            setTimeout(() => {
                

            fetch_city_multiple({
                "Ids": state_list_comma,
                "original": "en",
                "translate": [localStorage.getItem(TRANSLATED_LANG)]
            });

              }, 1500);
            
            
        
        }

    },[state_list_comma]);



    const navigate = useNavigate();
     useEffect(() => {

        if (partner_insert.affectedRows >= 1 || partner_update.success == 1) {


            //alert(partnerPrefField.city);

            localStorage.setItem(AGE_FROM_P_VAL , partnerPrefField.age_from);
            localStorage.setItem(AGE_TO_P_VAL ,  partnerPrefField.age_to);
            localStorage.setItem(HEIGHT_FROM_P_VAL , partnerPrefField.height_from);
            localStorage.setItem(HEIGHT_TO_P_VAL , partnerPrefField.height_to);
            localStorage.setItem(MARITAL_STATUS_P_VAL , partnerPrefField.marital_status);
            localStorage.setItem(CASTE_P_VAL , partnerPrefField.caste);
            localStorage.setItem(SUBCASTE_P_VAL , partnerPrefField.subcaste);
            localStorage.setItem(SKINTONE_P_VAL , partnerPrefField.skintone);
            localStorage.setItem(STATE_P_VAL , partnerPrefField.state);
            localStorage.setItem(CITY_P_VAL , partnerPrefField.city);
            localStorage.setItem(EDUCATION_P_VAL , partnerPrefField.education);
            localStorage.setItem(OCCUPATION_P_VAL , partnerPrefField.occupation);
            localStorage.setItem(DIET_TYPE_P_VAL , partnerPrefField.diet_type);
            localStorage.setItem(FAMILY_VALUE_P_VAL , partnerPrefField.family_value);
            localStorage.setItem(ANNUAL_INCOME_P_VAL , partnerPrefField.annual_income);
            localStorage.setItem(BODY_TYPE_P_VAL ,  partnerPrefField.body_type);
            localStorage.setItem(SMOKE_TYPE_P_VAL , partnerPrefField.smoke_type);
            localStorage.setItem(DRINK_TYPE_P_VAL , partnerPrefField.drink_type);



            setTimeout(() => {

                navigate('/docs', { replace: true });
            }, 1000);
        }

    }, [partner_insert, partner_update]);



    const [partnerPrefField, setPartnerPrefsField] = useState({
        age_from: '',
        age_to: '',
        height_from: '',
        height_to: '',
        marital_status: '',
        caste: '',
        subcaste: '',
        skintone: '',
        state: '',
        city: '',
        education: '',
        occupation: '',
        diet_type: '',
        family_value: '',
        annual_income: '',
        body_type: "",
        smoke_type: "",
        drink_type: "",
        detail: ""
    });


    const [errors, setErrors] = useState({
        age_from: false,
        age_to: false,
        height_from: false,
        height_to: false,
        marital_status: false,
        caste: false,
        subcaste: false,
        skintone: false,
        state: false,
        city: false,
        education: false,
        occupation: false,
        diet_type: false,
        family_value: false,
        annual_income: false,
        body_type: false,
        smoke_type: false,
        drink_type: false,
        detail: false
    });


    const [hasBeenFocused, setHasBeenFocused] = useState({
        age_from: false,
        age_to: false,
        height_from: false,
        height_to: false,
        marital_status: false,
        caste: false,
        subcaste: false,
        skintone: false,
        state: false,
        city: false,
        education: false,
        occupation: false,
        diet_type: false,
        family_value: false,
        annual_income: false,
        body_type: false,
        smoke_type: false,
        drink_type: false,
        detail: false
    });

    const handleChange = (fieldName) => (event , value) => {
        const newValue = event.target.value;

        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {

            if (fieldName == "marital_status" || fieldName == "skintone" || fieldName == "state" || fieldName == "city" ||
                fieldName == "education" || fieldName == "occupation" || fieldName == "diet_type" || fieldName == "family_value"
                || fieldName == "body_type" || fieldName == "smoke_type" || fieldName == "drink_type"){

                draft[fieldName] = value.map(lang => lang.value).join(",");

            } else {

                draft[fieldName] = newValue;
            }
        });

        setPartnerPrefsField(nextState);

        if (fieldName == "state") {

            setInitial(true);
            setStateComma(value.map(lang => lang.value).join(","));
         }


        // Set the updated state

    };





    const handleFilter = (fieldName) => (event , value) => {
        const newValue = event.target.label;

        // Use immer to update the state based on the current state
        const filteredOptions = degreeList.filter(option => {
            return option.label.toLowerCase().includes(newValue.toLowerCase()) && !option.deleted;
          });

          console.log(filteredOptions);
          return filteredOptions;

        // Set the updated state

    };


    
    const preference_click = () => {
        setopen(true);
    };


    const handleClick = (fieldName) => (event) => {

        const trimmedValue = event.target.value;

        setHasBeenFocused((prevHasBeenFocused) =>
            produce(prevHasBeenFocused, (draftHasBeenFocused) => {
                draftHasBeenFocused[fieldName] = true;
            })
        );

        //alert(hasBeenFocused[fieldName]);


        setErrors((prevErrors) =>
            produce(prevErrors, (draftErrors) => {

                // alert(trimmedValue);

                //alert(fieldName);


                if (fieldName != "age_from" && fieldName != "age_to" && fieldName != "height_from" && fieldName != "height_to"
                    && fieldName != "marital_status") {

                    draftErrors[fieldName] = true;

                } else {

                    if (partnerPrefField[fieldName] !== '') {
                        draftErrors[fieldName] = false;
                    } else {
                        draftErrors[fieldName] = hasBeenFocused[fieldName] && trimmedValue === undefined;
                    }

                }


            })
        );

    }




    const handleSubmit = () => {



        const newErrors = produce(errors, (draftErrors) => {
            for (const field in partnerPrefField) {
               
                if (field != "age_from" && field != "age_to" && field != "height_from" && field != "height_to"
                    && field != "marital_status") {


                       

                    draftErrors[field] = true;

                    

                     //alert(field+"-----"+draftErrors[field].toLowerCase());
                }

                
            }

               draftErrors["marital_status"] = false;
                draftErrors["caste"] = false;
                draftErrors["subcaste"] = false;
                draftErrors["skintone"] = false;
                draftErrors["state"] = false;
                draftErrors["city"] = false;
                draftErrors["education"] = false;
                draftErrors["occupation"] = false;
                draftErrors["diet_type"] = false;
                draftErrors["family_value"] = false;
                draftErrors["annual_income"] = false;
                draftErrors["body_type"] = false;
                draftErrors["drink_type"] = false;
            
                draftErrors["detail"] = false;
                
        });

        setErrors(newErrors);




        if (newErrors.age_from == true || newErrors.age_to == true || newErrors.height_from == true || newErrors.height_to == true || newErrors.marital_status == true) {

          // alert(Object.values(newErrors).some(error => error)+"--"+newErrors.age_to+"----"+newErrors.height_from+"---"+newErrors.height_to+"----"+newErrors.marital_status);


        } else {


            loadset(false);

            const passdata = {
                "age_range": partnerPrefField.age_from + "-" + partnerPrefField.age_to,
                "height_range": partnerPrefField.height_from + "-" + partnerPrefField.height_to,
                "marital_status": partnerPrefField.marital_status,
                "caste": partnerPrefField.caste,
                "subcaste": partnerPrefField.subcaste,
                "skintone": partnerPrefField.skintone,
                "state": partnerPrefField.state,
                "city": partnerPrefField.city,
                "education": partnerPrefField.education,
                "occupation": partnerPrefField.occupation,
                "diet_type": partnerPrefField.diet_type,
                "family_value": partnerPrefField.family_value,
                "annual_income": partnerPrefField.annual_income,
                "body_type": partnerPrefField.body_type,
                "smoke_type": partnerPrefField.smoke_type,
                "drink_type": partnerPrefField.drink_type,
                "partner_details": partnerPrefField.detail,
                "userId": localStorage.getItem(USER_ID),
                "communityId":localStorage.getItem(COMMUNITY_ID),
                "profileId": localStorage.getItem(PROFILE_ID), 
                "Id": data[0]["0"] == undefined ? "0" : data[0]["0"].Id
            }


            if (data[0]["0"] == null || data[0]["0"] == undefined) {
                submit_partner_prefs_details(passdata);
            } else {
                update_partner_prefs_details(passdata);
            }





        }
    }



    return (
        <React.Fragment>

            {load == false ? <RotatingHeart /> :
                <div style={{ display: 'grid', placeItems: 'center', width: "80%", marginLeft: "10%", marginRight: "10%", marginTop: "230px" , zIndex:"2" }}>

                    <div style={{ backgroundColor: "#E3256B", padding: "10px", position: "fixed", top: "175px", right: "20px", color: "white" }}
                        onClick={preference_click}>
                        Filter Your Preferences
                    </div>

                    <CSSTransition
                        in={isAnimated}
                        timeout={1200} // Duration of the transition in milliseconds
                        classNames="fade"
                        unmountOnExit>

                        <Grid container xs={12} >

                            <Grid item xs={12} md={6} spacing={1}>


                                <Grid container xs={12} md={12} spacing={2}>
                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={ageRange} label={t('from_age')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('age_from')}
                                            value={partnerPrefField.age_from}
                                            onMouseDown={handleClick('age_from')}
                                        />
                                        {errors.age_from && <span style={{ color: 'red' }}>required!</span>}

                                    </Grid>

                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={ageRange} label={t('to_age')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('age_to')}
                                            value={partnerPrefField.age_to}
                                            onMouseDown={handleClick('age_to')}
                                        />
                                        {errors.age_to && <span style={{ color: 'red' }}>required!</span>}

                                    </Grid>

                                </Grid>


                                <Grid container xs={12} md={12} spacing={2}>
                                    <Grid item xs={12} md={5.5}  >


                                        <DropdownSelect options={height} label={t('from_height')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('height_from')}
                                            value={partnerPrefField.height_from}
                                            onMouseDown={handleClick('height_from')} />

                                        {errors.height_from && <span style={{ color: 'red' }}>required!</span>}


                                    </Grid>

                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={height} label={t('to_height')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('height_to')}
                                            value={partnerPrefField.height_to}
                                            onMouseDown={handleClick('height_to')} />

                                        {errors.height_to && <span style={{ color: 'red' }}>required!</span>}


                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={10.7} style={{ marginTop: "10px" }}>

                                    <MultiselectDropdown options={maritalStatus} label={t('marital_status_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"60%"} borderWidth={0.5}
                                        onChange={handleChange('marital_status')}
                                        onMouseDown={handleClick('marital_status')}
                                        filterFunction={handleFilter('marital_status')}
                                        initialSelectedValues={maritalStatus.filter(vals => partnerPrefField.marital_status.includes(vals.value))} />

                                    {errors.marital_status && <span style={{ color: 'red' }}>required!</span>}

                                </Grid>

                                <Grid container xs={12} md={12} spacing={2} style={{ marginTop: "-5px" }}>

                                    <Grid item xs={12} md={5.5} >

                                        <DropdownSelect options={caste} label={t('caste_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('caste')}
                                            value={partnerPrefField.caste} />

                                    </Grid>

                                    <Grid item xs={12} md={5.5}  >

                                        <DropdownSelect options={subcaste} label={t('subcaste_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                            bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                            onChange={handleChange('subcaste')}
                                            value={partnerPrefField.subcaste} />

                                    </Grid>

                                </Grid>

                                <Grid item xs={12} md={10.7} style={{ marginTop: "10px" }}>

                                    <MultiselectDropdown options={skintone} label={t('skintone_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('skintone')}
                                        filterFunction={handleFilter('skintone')}
                                        initialSelectedValues={skintone.filter(vals => partnerPrefField.skintone.includes(vals.value))} />

                                </Grid>


                                <Grid item xs={12} md={10.7} style={{ marginTop: "20px" }}>

                                    <MultiselectDropdown options={stateList} label={t('state_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('state')}
                                        filterFunction={handleFilter('state')}
                                        initialSelectedValues={stateList.filter(vals => partnerPrefField.state.includes(vals.value))} />

                                </Grid>
                                <Grid item xs={12} md={10.7} style={{ marginTop: "20px" }}>

                                    <MultiselectDropdown options={cityList} label={t('city_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('city')}
                                        filterFunction={handleFilter('city')}
                                        initialSelectedValues={cityList.filter(vals => partnerPrefField.city.includes(vals.value))} />

                                </Grid>

                                <Grid item xs={12} md={10.7} style={{ marginTop: "20px" }}>

                                    <MultiselectDropdown options={degreeList} label={t('education_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"50%"} borderWidth={0.5}
                                        onChange={handleChange('education')}
                                        filterFunction={handleFilter('education')}
                                        initialSelectedValues={degreeList.filter(vals => partnerPrefField.education.includes(vals.value))} />

                                </Grid>




                            </Grid>

                            <Grid container xs={12} md={0.5} >
                                <div style={{ backgroundColor: "#E3256B", width: "3px" }}></div>
                            </Grid>

                            <Grid container xs={12} md={5.5} spacing={2} style={{ marginTop: "10px" }}>



                                <Grid item xs={12} md={10.7} style={{ marginTop: "-15px" }}>

                                    <MultiselectDropdown options={occupationList} label={t('occuaption_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"50%"} borderWidth={0.5}
                                        onChange={handleChange('occupation')}
                                        filterFunction={handleFilter('occupation')}
                                        initialSelectedValues={occupationList.filter(vals => partnerPrefField.occupation.includes(vals.value))} />

                                </Grid>

                                <Grid item xs={12} md={10.7}  >

                                    <MultiselectDropdown options={diet} label={t('diet_type_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('diet_type')}
                                        filterFunction={handleFilter('diet_type')}
                                        initialSelectedValues={diet.filter(vals => partnerPrefField.diet_type.includes(vals.value))} />

                                </Grid>
                                <Grid item xs={12} md={10.7} >

                                    <MultiselectDropdown options={family_value} label={t('family_value_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('family_value')}
                                        filterFunction={handleFilter('family_value')}
                                        initialSelectedValues={family_value.filter(vals => partnerPrefField.family_value.includes(vals.value))} />

                                </Grid>





                                <Grid item xs={12} md={10.7} style={{ marginTop: "-15px" }}>

                                    <DropdownSelect options={annual_income} label={t('annual_income_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('annual_income')}
                                        value={partnerPrefField.annual_income} />

                                </Grid>
                                <Grid item xs={12} md={10.7} style={{ marginTop: "-10px" }}>

                                    <MultiselectDropdown options={body_type} label={t('bodytype_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('body_type')}
                                        filterFunction={handleFilter('body_type')}
                                        initialSelectedValues={body_type.filter(vals => partnerPrefField.body_type.includes(vals.value))} />

                                </Grid>



                                <Grid item xs={12} md={10.7}>

                                    <MultiselectDropdown options={smoke_type} label={t('smoke_type_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('smoke_type')}
                                        filterFunction={handleFilter('smoke_type')}
                                        initialSelectedValues={smoke_type.filter(vals => partnerPrefField.smoke_type.includes(vals.value))} />

                                </Grid>
                                <Grid item xs={12} md={10.7} >

                                    <MultiselectDropdown options={drink_type} label={t('drink_type_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                        onChange={handleChange('drink_type')}
                                        filterFunction={handleFilter('drink_type')}
                                        initialSelectedValues={drink_type.filter(vals => partnerPrefField.drink_type.includes(vals.value))}
                                    />

                                </Grid>

                                <Grid item xs={12} md={12} >


                                    <StyledTextField label={t('details_family_l')} focusedcolor={"#E3256B"}
                                        bordercolor={"#7f7f7f"} hovercolor={"#E3256B"} width={"100%"} borderWidth={0.5}
                                        rows={3}
                                        onChange={handleChange('detail')}
                                        value={partnerPrefField.detail}
                                    />

                                </Grid>


                                <Prefernce_filter open={open} handleClosePass={handleClose} />

                            </Grid>

                        </Grid>
                    </CSSTransition>

                    <Button style={{
                        justifyContent: "center", alignContent: "center", marginTop: "30px", marginLeft: "50px",
                        background: "#E3256B", width: "400px", color: "white", fontWeight: "bold", marginBottom: "30px"
                    }} onClick={handleSubmit}>Submit your Partner Preferences</Button>

                </div>
            }


        </React.Fragment>);



}


export default PartnerPrefs;

import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Grid } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import "../../styles/transitionstyle.css";
import { useSettingsContext } from '../../contexts/settingsContext';
import { COMMUNITY_ID, IS_LOGIN, PROFILE_ID, USER_ID } from '../../common_utils/constants';
import MsgConfirm from '../../app_utils/MsgConfirm';
import DeleteAccountReason from '../../app_utils/DeleteAccountReason';
import { useTranslation } from 'react-i18next';
import HappyStoryModal from './add_happystory';


function Settings({passPath}) {

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption2, setSelectedOption2] = useState('');
  const [selectedOption3, setSelectedOption3] = useState('');
  const [isAnimated, setAnimated] = useState(false);
  const [msg, setMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();


  

  const { submit_settings, update_settings, fetch_settings, insert_list, update_list, settings_list , DeleteAccount , delete_account_response } = useSettingsContext();


  const handleopenmsg = () => {
    setMsg(true);
  }

  const handleclosemsg = (event) => {
    setMsg(false);
  }

  const handleDeleteAccount = () => {

    setDialogOpen(true);

  }

  const handleCloseDialog = (text) => {

    setDialogOpen(false);
    DeleteAccount({
      "delete_account_reason":text,
      "userId":localStorage.getItem(USER_ID)
  });


  }

  useEffect(()=>{

    passPath(t('settings'));

  },[]);

  useEffect(() => {

    setAnimated(true);

    if(settings_list.length > 0){

      setSelectedOption(settings_list[0].phone_privacy);
      setSelectedOption2(settings_list[0].photo_privacy);
      setSelectedOption3(settings_list[0].horoscope_privacy);

    }

    if(delete_account_response["success"] == 1){

      alert("Account Deleted Successfully");
      localStorage.removeItem(IS_LOGIN);
      window.location.href = '/home';

    }

  }, [settings_list , delete_account_response]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  const handleChange3 = (event) => {
    setSelectedOption3(event.target.value);
  };

  useEffect(() => {

    if (insert_list.affectedRows == 1) {

      
      setTitle("Submit Privacy");
      setContent("Privacy data Inserted Successfully");
      setMsg(true);

      fetch_settings({
        "userId": localStorage.getItem(USER_ID)
      });

    }else if(insert_list.affectedRows == undefined && update_list.success == "1"){

      setTitle("Update Privacy");
      setContent("Privacy data Updated Successfully");
      setMsg(true);

    }

  }, [insert_list, update_list]);


  const handleSubmitSettings = () => {


    if (settings_list.length <= 0) {
      submit_settings({
        "phone_privacy": selectedOption,
        "photo_privacy": selectedOption2,
        "horoscope_privacy": selectedOption3,
        "userId": localStorage.getItem(USER_ID),
        "communityId": localStorage.getItem(COMMUNITY_ID),
        "profileId": localStorage.getItem(PROFILE_ID)
      });



    } else {

      update_settings({
        "phone_privacy": selectedOption,
        "photo_privacy": selectedOption2,
        "horoscope_privacy": selectedOption3,
        "Id": settings_list[0].Id
      });

    }

  }



  return (
    <>


      <CSSTransition
        in={isAnimated}
        timeout={2000} // Duration of the transition in milliseconds
        classNames="fade"
        unmountOnExit>
        <Grid container spacing={3} width={"1300px"} style={{ justifyContent: "left", width: "100%",  marginTop: "220px" , marginBottom:"60px"}}>
          <FormControl component="fieldset" style={{ marginLeft: "100px" }}>
            <FormLabel component="legend" style={{ "fontSize": "20px", color: "#D74894", fontWeight: "bold", fontFamily: "Gothic" }}>{t('phone_settings')}</FormLabel>
            <RadioGroup
              aria-label="options"
              name="options"
              value={selectedOption}
              onChange={handleChange}>

              <FormControlLabel value="1" control={<Radio style={{ color: "#D74894" }} />} label={t('phone_premium')} />
              <FormControlLabel value="2" control={<Radio style={{ color: "#D74894" }} />} label={t('phone_privacy')} />
            </RadioGroup>
            <FormLabel component="legend" style={{ "fontSize": "20px", color: "#D74894", fontWeight: "bold", fontFamily: "Gothic", marginTop: "20px" }}>{t('photo_settings')}</FormLabel>
            <RadioGroup
              aria-label="options"
              name="options"
              value={selectedOption2}
              onChange={handleChange2}>

              <FormControlLabel value="1" control={<Radio style={{ color: "#D74894" }} />} label={t('photos_all')} />
              <FormControlLabel value="2" control={<Radio style={{ color: "#D74894" }} />} label={t('photos_premium')} />
            </RadioGroup>
            <FormLabel component="legend" style={{ "fontSize": "20px", color: "#D74894", fontWeight: "bold", fontFamily: "Gothic", marginTop: "20px" }}>{t('horoscope_settings')}</FormLabel>
            <RadioGroup
              aria-label="options"
              name="options"
              value={selectedOption3}
              onChange={handleChange3}>

              <FormControlLabel value="1" control={<Radio style={{ color: "#D74894" }} />} label={t('horo_premium')} />
              <FormControlLabel value="2" control={<Radio style={{ color: "#D74894" }} />} label={t('horo_privacy')} />
            </RadioGroup>

            <div style={{ backgroundColor: "#D74894", padding: "10px", color: "#ffffff", fontFamily: "Gothic", fontSize: "22px", justifyContent: "center", textAlign: "center", marginTop: "20px" }}  onClick={handleDeleteAccount}>{t('delete_profile')}</div>
            <div style={{ backgroundColor: "#E3256B", padding: "10px", color: "#ffffff", fontFamily: "Gothic", fontSize: "22px", justifyContent: "center", textAlign: "center", marginTop: "20px" }} onClick={handleSubmitSettings}>{t('submit_settings')}</div>
          </FormControl>



          <MsgConfirm
          isOpen={msg}
          handleClose={handleclosemsg}
          title={title}
          content={content}
          closeText={"Close"} />


          <DeleteAccountReason open={isDialogOpen} handleClose={handleCloseDialog} />

          <HappyStoryModal  open={false} onClose={false} />

        </Grid>

        

      </CSSTransition>
    </>
  );




}

export default Settings;
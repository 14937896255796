const reducerAdvancedSearch = (state, action) => {
    switch (action.type) {
      case 'ADVANCED_SEARCH_EDIT':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'ADVANCED_SEARCH_EDIT_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };


        case 'SAVED_SEARCH_INSERT':
        return {
          ...state,
          data_saved_search: action.payload,
          loading: false,
          error: null,
        };
      case 'SAVED_SEARCH_INSERT_ERROR':
        return {
          ...state,
          data_saved_search: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerAdvancedSearch;
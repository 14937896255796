import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React,{ useEffect, useRef, useState } from 'react';
import { Cropper , CropperRef } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';





const ImageCropperScreen = ({image , openPass , indexcropper , close , passBase64}) => {

  
  const cropperRef = useRef(null);
  const[open ,setOpen] = useState(false);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 / 1 });
  const [images, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);

  useEffect(()=>{
    setOpen(openPass);


    const file = image[indexcropper];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }

  }, []);











  const  handleClose = () =>{
    setOpen(false);
    close(false);
  }


 

  const  handleCrop = () =>{
   
    passBase64(base64Image , indexcropper);
    close(false);

  }

 



  const onChange = (cropper) => {
		setCrop(cropper);
	};


  return(
 
     <>
    
<Dialog open={open}  sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "50%",
          maxWidth: "1500px",  // Set your width here
        },
      },
    }}>
        <DialogTitle>Modal Title</DialogTitle>
        <DialogContent>
        {images && (
        <Cropper
        ref={cropperRef}
        src={images}
        onChange={onChange}
        style={{height:"500px" }}
        onInteractionStart={(croppedImage) => setBase64Image(null)}
        onInteractionEnd={(croppedImage) => setBase64Image(croppedImage.getCanvas().toDataURL())}
        
      />
      )}


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleCrop} color="primary">
            Crop
          </Button>
        </DialogActions>
      </Dialog>


     </>

  );


}

export default ImageCropperScreen;
const reducerFamily = (state, action) => {
    switch (action.type) {
      case 'FAMILY_DATA_FETCH':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'FAMILY_DATA_FETCH_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };

        case 'INSERT_FAMILY_DETAILS':
        return {
          ...state,
          family_insert: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_FAMILY_DETAILS_ERROR':
        return {
          ...state,
          family_insert: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_FAMILY_DETAILS':
        return {
          ...state,
          family_update: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_FAMILY_DETAILS_ERROR':
        return {
          ...state,
          family_update: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerFamily;
import React, { useState, useEffect, useRef } from "react";

const WrapperStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridColumnGap: "5px",
  gridRowGap: "5px",
  maxWidth: "250px",
  maxHeight: "175px",
  overflowY: "scroll",
  margin: "0 auto"
};

const CaptionStyle = {
  color: "#555",
  fontSize: "0.75em",
  marginBottom: "0"
};

const YearButtonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: "4px",
  textAlign: "center",
  borderRadius: "4px",
  margin: "0 auto",
  outline: "none",
  '&:focus, &:active, &:hover': {
    background: "#ff7494",
    color: "white"
  }
};

const PointerStyle = {
  margin: "0 auto",
  cursor: "pointer",
  border: "none",
  background: "none"
};

const YearPicker = ({ onYearPicked }) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = 188;
      }
    });
    return () => clearTimeout(timeout);
  }, []);

  const pickYear = (e) => {
    setShow(false);
    onYearPicked(e.target.textContent);
  };

  let today = new Date();
  let currentYear = today.getFullYear();
  let minYear = currentYear - 50;
  let maxYear = currentYear + 50;
  const years = [];
  for (let i = minYear; i < maxYear; i++) years.push(i);

  return (
    <div style={show ? {} : { display: "none" }}>
      <p style={CaptionStyle}>Select year</p>
      <button
        style={PointerStyle}
        title="Scroll Up to choose previous years"
        onClick={() => (wrapperRef.current.scrollTop -= 21)}
      >
        ▲
      </button>
      <div style={{ ...WrapperStyle, overflowY: "scroll" }} ref={wrapperRef}>
        {years.map(y => (
          <button
            key={y}
            onClick={pickYear}
            style={{ ...YearButtonStyle, border: y === currentYear ? "1px solid black" : "none" }}
          >
            {y}
          </button>
        ))}
      </div>
      <button
        style={PointerStyle}
        title="Scroll down to choose next years"
        onClick={() => (wrapperRef.current.scrollTop += 21)}
      >
        ▼
      </button>
    </div>
  );
};

export default YearPicker;

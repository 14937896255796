
const reducer = (state, action) => {
    switch (action.type) {

      case 'ADD_COMMUNITY_SUCCESS':
        return {
          ...state,
          data_insert_community: action.payload,
          loading_community: false,
          error: null,
        };



         case 'ADD_COMMUNITY_ERROR':
        return {
          ...state,
          data_insert_community: [],
          loading_community: false,
          error: action.payload,
        };





        case 'UPDATE_COMMUNITY_SUCCESS':
        return {
          ...state,
          data_update_community: action.payload,
          loading_community: false,
          error: null,
        };



         case 'UPDATE_COMMUNITY_ERROR':
        return {
          ...state,
          data_update_community: [],
          loading_community: false,
          error: action.payload,
        };



         case 'COMMUNITY_LIST_SUCCESS':
        return {
          ...state,
          list_community_admin: action.payload,
          loading_community: false,
          error: null,
        };


        case 'COMMUNITY_LIST_ERROR':
        return {
          ...state,
          list_community_admin: [],
          loading_community: false,
          error: action.payload,
        };


     
      default:
        return state;
    }
  };

  export default reducer;
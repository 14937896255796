import { useEffect } from "react";
import { useAdminDashboardContext } from "../contexts_admin/dashboard_context";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination } from "@mui/material";
import { useState } from "react";
import styles from "../styles_admin/Community.module.css";
import { useMasterContextAdmin } from "../../contexts/master_pageContext";




function StateModal({isopen , setClose , setClickedLink , countryId}){

    const { state_fetch ,  list_state_admin , loading_community} = useMasterContextAdmin();
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }


  const handleCommunityClick = (Id ,State) => {
     
    setClose(false);
    setClickedLink(Id , State);
  }

    useEffect(()=>{
        
        setOpen(isopen);
        state_fetch({
            "country_id": countryId,
            "offset":"0",
          });
    
    },[]);



     useEffect(()=>{
        
        if(list_state_admin.length > 0){
            
            
        }

    },[list_state_admin]);


    const handlePageChange = (event, value) => {
    
        setCurrentPage(value);

        state_fetch({
            "country_id": countryId,
             "offset": value == 1 ? 0 : (((value - 2) * 10) + 10)
        });
            

    }





    return (<>

<Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Select Community</DialogTitle>
      <DialogContent>

      
        <table className={styles.nicetable}> {/* Apply custom CSS class */}
          <thead>
            <tr>
              <th>Community</th>
              <th>Code</th>
              <th>Contact Person</th>
              <th>Mobile</th>
              <th>City</th>
              <th>Website</th>
              <th>Select Community</th>
            </tr>
          </thead>
          <tbody>
          {list_state_admin.length > 0 &&   list_state_admin[0].map((religion , index) => (
                  <tr key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <td>{religion.Id}</td>
                    <td>{religion.state_name}</td>
                    <td>{religion.state_hindi}</td>
                    <td>{religion.state_guj}</td>
                    <td>{religion.state_marathi}</td>
                    <td>{religion.state_tamil}</td>
                    <td>{religion.state_urdu}</td>
                    <td>{religion.country}</td>
                    <td><span
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={()=>handleCommunityClick(religion.Id , religion.state_name)}>
      {"Click to Select"}
    </span></td>
                  </tr>
                ))}
          </tbody>
        </table>


        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={list_state_admin.length > 0 && Math.ceil(list_state_admin[1][0]["total_row_count"] / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="secondary"
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    size="medium"
                />
            </div>

            </DialogContent>
            <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>

        </>
        
      );


}

export default StateModal;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AGE_P, ANNUAL_INCOME_P, BODY_TYPE_P, CASTE_P, CITY_P, DIET_TYPE_P, DRINK_TYPE_P, EDUCATION_P, FAMILY_VALUE_P, HEIGHT_P, MARITAL_STATUS_P, OCCUPATION_P, SKINTONE_P, SMOKE_TYPE_P, STATE_P, SUBCASTE_P } from "../../common_utils/constants";





const Prefernce_filter = ({ open , handleClosePass }) => {

    const [checked, setChecked] = useState(true);
    const [checkedheight, setCheckedHeight] = useState(false);
    const [checkedMarital, setCheckedMarital] = useState(false);
    const [checkedCaste, setCheckedcaste] = useState(false);
    const [checkedSubcaste, setCheckedsubcaste] = useState(false);
    const [checkedSkintone, setCheckedskintone] = useState(false);
    const [checkedState, setCheckedState] = useState(false);
    const [checkedCity, setCheckedCity] = useState(false);
    const [checkedEducation, setCheckedEducation] = useState(false);
    const [checkedOccupation, setCheckedOccupation] = useState(false);
    const [checkedDiet, setCheckedDiet] = useState(false);
    const [checkeFamilyvalue, setCheckedFamilyValue] = useState(false);
    const [checkedAnnualIncome, setCheckedIncome] = useState(false);
    const [checkedBodyType, setCheckedBodytype] = useState(false);
    const [checkedSmokeType, setCheckedSmoketype] = useState(false);
    const [checkedDrinkType, setCheckedDrinktype] = useState(false);
      

    useEffect(()=>{

        localStorage.setItem(AGE_P , JSON.stringify(true));
        setChecked(true);
    

    }, []);

    
    const [openhere , setOpen] = useState(false);

    const handleChange = () => {
        setChecked(true);  
    }

    const handleChangeheight = (event) => {
    
        setCheckedHeight(event.target.checked);
        localStorage.setItem(HEIGHT_P , JSON.stringify(event.target.checked));
    };

    const handleChangemarital = (event) => {
        setCheckedMarital(event.target.checked);
        localStorage.setItem(MARITAL_STATUS_P , JSON.stringify(event.target.checked));
    };


    const handleChangecaste = (event) => {
        setCheckedcaste(event.target.checked);
        localStorage.setItem(CASTE_P , JSON.stringify(event.target.checked));
    };


    const handleChangesubcaste = (event) => {
        setCheckedsubcaste(event.target.checked);
        localStorage.setItem(SUBCASTE_P , JSON.stringify(event.target.checked));
    };


    const handleChangeskintone = (event) => {
        setCheckedskintone(event.target.checked);
        localStorage.setItem(SKINTONE_P , JSON.stringify(event.target.checked));
    };


    const handleChangecity = (event) => {
        setCheckedCity(event.target.checked);
        localStorage.setItem(CITY_P , JSON.stringify(event.target.checked));
    };


    const handleChangestate = (event) => {
        setCheckedState(event.target.checked);
        localStorage.setItem(STATE_P , JSON.stringify(event.target.checked));
    };


    const handleChangeEducation = (event) => {
        setCheckedEducation(event.target.checked);
        localStorage.setItem(EDUCATION_P , JSON.stringify(event.target.checked));
        
    };


    const handleChangeOcupation = (event) => {
        setCheckedOccupation(event.target.checked);
        localStorage.setItem(OCCUPATION_P , JSON.stringify(event.target.checked));
    };


    const handleChangeDiet = (event) => {
        setCheckedDiet(event.target.checked);
        localStorage.setItem(DIET_TYPE_P , JSON.stringify(event.target.checked));
    };


    const handleChangeFamily = (event) => {
        setCheckedFamilyValue(event.target.checked);
        localStorage.setItem(FAMILY_VALUE_P , JSON.stringify(event.target.checked));
    };


    const handleChangeAnnualIncome = (event) => {
        setCheckedIncome(event.target.checked);
        localStorage.setItem(ANNUAL_INCOME_P , JSON.stringify(event.target.checked));
    };

    const handleChangeBodytype = (event) => {
        setCheckedBodytype(event.target.checked);
        localStorage.setItem(BODY_TYPE_P , JSON.stringify(event.target.checked));
    };

    const handleChangeSmoke = (event) => {
        setCheckedSmoketype(event.target.checked);
        localStorage.setItem(SMOKE_TYPE_P , JSON.stringify(event.target.checked));
    };

    const handleChangedrink = (event) => {
        setCheckedDrinktype(event.target.checked);
        localStorage.setItem(DRINK_TYPE_P , JSON.stringify(event.target.checked));
    };
    

    useEffect(() => {
        setOpen(open);

        

        setChecked(JSON.parse(localStorage.getItem(AGE_P)) || false);
        setCheckedHeight(JSON.parse(localStorage.getItem(HEIGHT_P)) || false);
        setCheckedMarital(JSON.parse(localStorage.getItem(MARITAL_STATUS_P)) || false);
        setCheckedcaste(JSON.parse(localStorage.getItem(CASTE_P)) || false);
        setCheckedsubcaste(JSON.parse(localStorage.getItem(SUBCASTE_P)) || false);
        setCheckedskintone(JSON.parse(localStorage.getItem(SKINTONE_P)) || false);
        setCheckedState(JSON.parse(localStorage.getItem(STATE_P)) || false);
        setCheckedCity(JSON.parse(localStorage.getItem(CITY_P)) || false);
        setCheckedEducation(JSON.parse(localStorage.getItem(EDUCATION_P)) || false);
        setCheckedOccupation(JSON.parse(localStorage.getItem(OCCUPATION_P)) || false);
        setCheckedDiet(JSON.parse(localStorage.getItem(DIET_TYPE_P)) || false);
        setCheckedFamilyValue(JSON.parse(localStorage.getItem(FAMILY_VALUE_P)) || false);
        setCheckedIncome(JSON.parse(localStorage.getItem(ANNUAL_INCOME_P)) || false);
        setCheckedBodytype(JSON.parse(localStorage.getItem(BODY_TYPE_P)) || false);
        setCheckedSmoketype(JSON.parse(localStorage.getItem(SMOKE_TYPE_P)) || false);
        setCheckedDrinktype(JSON.parse(localStorage.getItem(DRINK_TYPE_P)) || false);


       

    }, [open]);

    const handleClose = () => {
        setOpen(false);
        handleClosePass(false);
    };

    const handleSaveChanges = () => {
        
      
        handleClosePass(false);
    };


    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Dialog open={openhere} onClose={handleClose} sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1000px",  // Set your width here
                    },
                },
            }}>
                <DialogTitle>Your Partner Prefrence Filter</DialogTitle>
                <DialogContent>

                    <Grid container xs={12} >

                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={-1}>


                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checked} onChange={handleChange} />}
                                    label={t('age_l')}
                                />

                            </Grid>
                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedheight} onChange={handleChangeheight} />}
                                    label={t('height_range_p_l')}
                                />

                            </Grid>

                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedMarital} onChange={handleChangemarital} />}
                                    label={t('marital_status_p_l')}
                                />

                            </Grid>


                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedCaste} onChange={handleChangecaste} />}
                                    label={t('caste_p_l')}
                                />

                            </Grid>


                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedSubcaste} onChange={handleChangesubcaste} />}
                                    label={t('subcaste_l')}
                                />

                            </Grid>



                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedSkintone} onChange={handleChangeskintone} />}
                                    label={t('skintone_l')}
                                />

                            </Grid>




                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedState} onChange={handleChangestate} />}
                                    label={t('state_p_l')}
                                />

                            </Grid>




                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedCity} onChange={handleChangecity} />}
                                    label={t('city_p_l')}
                                />

                            </Grid>
                        </Grid>

                        <Grid container xs={12} md={6} columnSpacing={1} rowSpacing={-1}>


                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedEducation} onChange={handleChangeEducation} />}
                                    label={t('education_p_l')}
                                />

                            </Grid>
                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedOccupation} onChange={handleChangeOcupation} />}
                                    label={t('occuaption_p_l')}
                                />

                            </Grid>

                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedDiet} onChange={handleChangeDiet} />}
                                    label={t('diet_type_l')}
                                />

                            </Grid>


                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkeFamilyvalue} onChange={handleChangeFamily} />}
                                    label={t('family_value_p_l')}
                                />

                            </Grid>


                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedAnnualIncome} onChange={handleChangeAnnualIncome} />}
                                    label={t('annual_income_p_l')}
                                />

                            </Grid>



                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedBodyType} onChange={handleChangeBodytype} />}
                                    label={t('bodytype_l')}
                                />

                            </Grid>




                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedSmokeType} onChange={handleChangeSmoke} />}
                                    label={t('smoke_type_p_l')}
                                />

                            </Grid>




                            <Grid item xs={12}  >

                                <FormControlLabel
                                    control={<Switch checked={checkedDrinkType} onChange={handleChangedrink} />}
                                    label={t('drink_type_p_l')}
                                />

                            </Grid>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>

                <Button onClick={handleSaveChanges} color="primary">
                        Save Changes
                    </Button>

                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );



}

export default Prefernce_filter;
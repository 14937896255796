import React from 'react';
import '../../src/styles/timelineother.css'; // Import the CSS file for styling
import { GENDER } from '../common_utils/constants';

const RoundedPhoto = ({ imageUrl, altText }) => {
  return (
    <div className="rounded_photo_container">
      <img src={imageUrl} alt={altText} className="rounded_photo"  width={"300px"} height={"300px"} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src= localStorage.getItem(GENDER).toLocaleLowerCase() == "male" ? "https://w7.pngwing.com/pngs/193/660/png-transparent-computer-icons-woman-avatar-avatar-girl-thumbnail.png" :"https://png.pngtree.com/png-vector/20191103/ourmid/pngtree-handsome-young-guy-avatar-cartoon-style-png-image_1947775.jpg";
              }}/>
    </div>
  );
};

export default RoundedPhoto;
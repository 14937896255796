import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/paymentReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';

const paymentContext = createContext();

const initialState = {
  loading_payment_admin: true,
  error: null,
  data_membership_communityId: [],
  data_membrship_insert:[],
  data_membrship_update:[],
  data_membership_fetch_expired:[],
  data_membership_pending_failed:[],
  data_membership_successful:[],
  data_mmebership_updatepayment_verification:[],
  data_payment_all :[]
};

let API = `${BASE_URL}`;


export const PaymentProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const membership_fetchByCommunityId = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/membership_fetchByCommunityId` , article);
      dispatch({ type: 'MEMBERSHIPLIST_COMMUNITYID_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'MEMBERSHIPLIST_COMMUNITYID_ERROR', payload: error });
    }
  };

  const membership_insert = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/membership_insert` , article);
      dispatch({ type: 'MEMBERSHIPINSERT_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'MEMBERSHIPINSERT_ERROR', payload: error });
    }
  };

  const membership_update = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/membership_update` , article);
      dispatch({ type: 'MEMBERSHIUPDATE_COMMUNITYID_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'MEMBERSHIUPDATE_COMMUNITYID_ERROR', payload: error });
    }
  };


  const paidListMember_fetchAll = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/paidListMember_fetchAll` , article);
      dispatch({ type: 'PAIDLIST_FETCHALL_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'PAIDLIST_FETCHALL_ERROR', payload: error });
    }
  };



  const paidListMember_fetchExpired = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/paidListMember_fetchExpired` , article);
      dispatch({ type: 'PAIDLISTEXPIRED_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'PAIDLISTEXPIRED_ERROR', payload: error });
    }
  };



  const paidListMember_fetchPendingOrFailed = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/paidListMember_fetchPendingOrFailed` , article);
      dispatch({ type: 'PAIDLISTPENDING_FAILED_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'PAIDLISTPENDING_FAILED_ERROR', payload: error });
    }
  };




  const paidListMember_fetchSuccessful = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/paidListMember_fetchSuccessful` , article);
      dispatch({ type: 'PAIDSUCESSFUL_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'PAIDSUCESSFUL_ERROR', payload: error });
    }
  };




  const paidListMember_updatePaymentVerification = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/paidListMember_updatePaymentVerification` , article);
      dispatch({ type: 'UPDATEVERIFICATION_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'UPDATEVERIFICATION_ERROR', payload: error });
    }
  };


  
  return <paymentContext.Provider value={{ ...state , dispatch , membership_fetchByCommunityId , membership_insert ,membership_update , paidListMember_fetchExpired ,paidListMember_fetchPendingOrFailed ,paidListMember_fetchSuccessful , paidListMember_updatePaymentVerification , paidListMember_fetchAll}}>{children}</paymentContext.Provider>;
};

export const usePaymentContext = () => useContext(paymentContext);
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NotFoundPage = () => {

  const location = useLocation();

  useEffect(() => {
    // Check if the current location is the not-found route
    if (location.pathname === '/not-found') {
      console.log('Route not found!');
      // You can perform actions or redirect to another page here
    }
  }, [location.pathname]);

  return (
    <div style={{position: "absolute", top: "185px" , marginLeft:"40%"}}>
      <h1 style={{textAlign:"center"}}>404 - Not Found</h1>
      <p style={{textAlign:"center"}}>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;

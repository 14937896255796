import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, MenuItem , Modal ,IconButton ,Pagination} from "@mui/material";
import { ROLE } from '../../../common_utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { MasterPageProvider, useMasterContextAdmin } from '../../../contexts/master_pageContext';
import { useEffect } from 'react';
import StateModal from '../../component/stateModal';








function City_page(){

 
  const {city_fetch ,city_insert ,city_update , data_insert_city , list_city_admin ,data_update_city , list_state_admin , state_fetch , country_fetch ,list_country_admin , delete_master_data} = useMasterContextAdmin();
  

  const [country , setCountry] = useState('');
  const [state , setState] = useState('');
  const [stateId , setStateId] = useState('');
  const [statename , setStatename] = useState('');
  const [state2 , setState2] = useState('');

  const [city , setcity] = useState('');
  const [cityId , setcityId] = useState('');
  const [cityhindi , setcityHindi] = useState('');
  const [citygujarat , setcityGujarat] = useState('');
  const [citymarathi , setcityMarathi] = useState('');
  const [cityTamil , setcityTamil] = useState('');
  const [cityurdu , setcityUrdu] = useState('');

  const [city2 , setcity2] = useState('');
  const [cityhindi2 , setcityHindi2] = useState('');
  const [citygujarat2 , setcityGujarat2] = useState('');
  const [citymarathi2 , setcityMarathi2] = useState('');
  const [cityTamil2 , setcityTamil2] = useState('');
  const [cityurdu2 , setcityUrdu2] = useState('');

  const [openCommunity , setopenCommunity] = useState(false);
  const [statepoptype , setStatePoptype] = useState(0);

  const [open , setopen] = useState(false);
  const [insert , setInsert] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);



  const handleCloseCommunity = (isopen) => {
    setopenCommunity(false);
  }


  const handleClickCommunityLink = (Id , state) => {
   

    //alert(Id);

    if(statepoptype == 0){

      setState(Id);
      setStatename("");
 

    city_fetch({
      "state_id": Id,
      "offset":"0",
    });

    }else if(statepoptype == 1){

      setStatename(state);
      setStateId(Id);


    }else{


      setStatename(state);
    }
    
    
  }


  useEffect(()=>{
    country_fetch();
  },[]);

  useEffect(()=>{

    if(data_insert_city.affectedRows >= 1  ||  data_update_city.affectedRows >= 1){

      setcity('');
      setcityHindi('');
      setcityGujarat('');
      setcityMarathi('');
      setcityTamil('');
      setcityUrdu('');

      city_fetch({
        "state_id": state,
        "offset":currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10),
      });

    }


  },[data_insert_city , data_update_city]);

  const handleOptionChange = (event) =>{

    
    setCurrentPage(1);
    setCountry(event.target.value);
    setStatePoptype(0);
    

    setopenCommunity(true);


  };

  const handleAddDropdown = () => {

   setStatePoptype(1);
   setopenCommunity(true);

  }



   const handleOptionChangeState = (event) =>{

    
    setCurrentPage(1);
    setState(event.target.value);

    city_fetch({
      "state_id":event.target.value,
      "offset":"0"
    });

  };



  const handleOptionChange2 = (event) =>{
    setState2(event.target.value);
    setStatename(event.target.value);

  }


  const handlecityChange = (event) =>{
    setcity(event.target.value);
  }

  const handlecityHindiChange = (event) =>{
    setcityHindi(event.target.value);
  }

  const handlecityGujChange = (event) =>{
    setcityGujarat(event.target.value);
  }


  const handlecityMarathiChange = (event) =>{
    setcityMarathi(event.target.value);
  }


  const handlecityTamilChange = (event) =>{
    setcityTamil(event.target.value);
  }


  const handlecityUrduChange = (event) =>{
    setcityUrdu(event.target.value);
  }


  const handleClose = () => {
      setopen(false);
  }








  const handlecityChange2 = (event) =>{
    setcity2(event.target.value);
  }

  const handlecityHindiChange2 = (event) =>{
    setcityHindi2(event.target.value);
  }

  const handlecityGujChange2 = (event) =>{
    setcityGujarat2(event.target.value);
  }


  const handlecityMarathiChange2 = (event) =>{
    setcityMarathi2(event.target.value);
  }


  const handlecityTamilChange2 = (event) =>{
    setcityTamil2(event.target.value);
  }


  const handlecityUrduChange2 = (event) =>{
    setcityUrdu2(event.target.value);
  }


  const handleEditClick = (city , cityhindi ,cityguj ,citymarathi ,citytamil ,cityurdu , id) => {
  

    setcityId(id);
    country_fetch();
    setcity2(city);
    setcityHindi2(cityhindi);
    setcityGujarat2(cityguj);
    setcityMarathi2(citymarathi);
    setcityTamil2(citytamil);
    setcityUrdu2(cityurdu);
    setTimeout( function()  {setState2(state);  setopen(true);} , 300);

   }


  const handlePageChange = (event, value) => {
  
    setCurrentPage(value);


    city_fetch({
      "state_id": state,
      "offset":value == 1 ? 0 : (((value - 2) * 10) + 10),
    });

 } 




 const handleDeleteClick = (is_enabled , Id) => {
    delete_master_data({ "type":"city"  , "is_enabled":"1" , "Id":Id});
  
    setTimeout(() => {
  
      city_fetch({
        "state_id": state,
        "offset":currentPage == 1 ? 0 : (((currentPage - 2) * 10) + 10),
      });
    
    }, 500);
  }



  const handleAddCity = () => {


    alert(stateId+"___"+country);

    if(city != "" && cityhindi != "" && citygujarat != "" && citymarathi != "" && cityTamil != "" && cityurdu != "" && stateId != ""){
    city_insert({ "city_name" : city,
    "city_hindi" :cityhindi,
    "city_guj" :citygujarat,
    "city_marathi" :citymarathi,
    "city_tamil" : cityTamil,
    "city_urdu" : cityurdu,
    "country_id" : country,
    "state_id" : stateId
    });
  }else{

    alert("All Fields are compulsory");

  }


  }




  const handleUpdatecity = () => {


    if(city2 != "" && cityhindi2 != "" && citygujarat2 != "" && citymarathi2 != "" && cityTamil2 != "" && cityurdu2 != "" && state2 != ""){
      city_update({ "city_name" : city2,
    "city_hindi" :cityhindi2,
    "city_guj" :citygujarat2,
    "city_marathi" :citymarathi2,
    "city_tamil" : cityTamil2,
    "city_urdu" : cityurdu2,
    "Id" : cityId
    });

    setopen(false);

  }else{

    alert("All Fields are compulsory");

  }


  }



  let role = localStorage.getItem(ROLE);

   return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "0px" : "0px" , width: "100%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>

<Grid container spacing={1}>

  <Grid item xs={12} style={{float:"left"}}>

  <TextField
      select
      label="Select Country"
      variant="outlined"
      value={country}
      onChange={handleOptionChange}
      style={{marginTop:"10px" , float:"right" , width:"200px" , marginRight:"20px"}}
      >
      {list_country_admin.map((option) => (
        <MenuItem key={option.Id} value={option.Id}>
          {option.country_name}
        </MenuItem>
      ))}
    </TextField>


  </Grid>





<Grid  item xs={9}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>City Hindi</TableCell>
                  <TableCell>City Gujarat</TableCell>
                  <TableCell>City Marathi</TableCell>
                  <TableCell>City Tamil</TableCell>
                  <TableCell>City Urdu</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list_city_admin.length > 0 && list_city_admin[0].map((religion , index) => (
                  <TableRow key={religion.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                    <TableCell>{religion.Id}</TableCell>
                    <TableCell>{religion.city_name}</TableCell>
                    <TableCell>{religion.city_hindi}</TableCell>
                    <TableCell>{religion.city_guj}</TableCell>
                    <TableCell>{religion.city_marathi}</TableCell>
                    <TableCell>{religion.city_tamil}</TableCell>
                    <TableCell>{religion.city_urdu}</TableCell>
                    <TableCell>{religion.state_name}</TableCell>
                    <TableCell><IconButton onClick={ () => handleEditClick(religion.city_name , religion.city_hindi , religion.city_guj , religion.city_marathi , religion.city_tamil , religion.city_urdu , religion.Id)}><EditIcon /></IconButton></TableCell>
                    <TableCell><IconButton onClick={ () => handleDeleteClick("0" , religion.Id)}><DeleteIcon /></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={list_city_admin.length > 0 && Math.ceil(list_city_admin[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>

</Grid>



<Grid  item xs={3}>
<Paper style={{ padding: '20px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
    <Grid container spacing={1}>


    <Grid item xs={12}>

  <TextField
  label="Select Option"
  variant="outlined"
  value={statename}
  onClick={handleAddDropdown}
  fullWidth>
</TextField>

</Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter City"
        variant="outlined"
        value={city}
        fullWidth
        onChange={handlecityChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Cityname Hindi"
        variant="outlined"
        value={cityhindi}
        fullWidth
        onChange={handlecityHindiChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter Cityname Gujarati"
        variant="outlined"
        value={citygujarat}
        fullWidth
        onChange={handlecityGujChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>



    <Grid  item xs={12}>
      <TextField
        label="Enter Cityname Marathi"
        variant="outlined"
        value={citymarathi}
        fullWidth
        onChange={handlecityMarathiChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Cityname Tamil"
        variant="outlined"
        value={cityTamil}
        fullWidth
        onChange={handlecityTamilChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Cityname Urdu"
        variant="outlined"
        value={cityurdu}
        fullWidth
        onChange={handlecityUrduChange}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleAddCity}>
        Submit City
      </Button>

      </Grid>

      </Grid>
    </Paper>

          </Grid>

          </Grid>


          {openCommunity == true ? <MasterPageProvider><StateModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}  countryId={country}/></MasterPageProvider> : ""}
        
          <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
      <Grid item xs={12}>


      <TextField

  label="Select Option"
  variant="outlined"
  value={statename}
  onChange= {handleOptionChange2}
  fullWidth
>
</TextField>

</Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter City"
        variant="outlined"
        value={city2}
        fullWidth
        onChange={handlecityChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter City Hindi"
        variant="outlined"
        value={cityhindi2}
        fullWidth
        onChange={handlecityChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid  item xs={12}>
      <TextField
        label="Enter City Gujarati"
        variant="outlined"
        value={citygujarat2}
        fullWidth
        onChange={handlecityGujChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>



    <Grid  item xs={12}>
      <TextField
        label="Enter City Marathi"
        variant="outlined"
        value={citymarathi2}
        fullWidth
        onChange={handlecityMarathiChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter City Tamil"
        variant="outlined"
        value={cityTamil2}
        fullWidth
        onChange={handlecityTamilChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>


    <Grid  item xs={12}>
      <TextField
        label="Enter Statename Urdu"
        variant="outlined"
        value={cityurdu2}
        fullWidth
        onChange={handlecityUrduChange2}
        style={{ marginRight: '10px', flex: 1 }}/>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleUpdatecity}>
        Update City
      </Button>

        </Grid>
        </Grid>
        </Modal>


        </div>
      );




}

export default City_page;
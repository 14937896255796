import { Button, Grid, TextField, Typography } from "@mui/material";
import { ROLE } from "../../../common_utils/constants";
import { useState } from "react";
import React from "react";
import DatePicker from "../../datepicker/DatePicker";
import RichTextEditor from "../../component/RichTextEditor";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useEventContextAdmin } from "../../contexts_admin/event_context";






function AddEvent(){

    const {data_insert_event  , addEvent  , loading_event } = useEventContextAdmin();

    const [time, setTime] = useState(null);
    const [passedDate, setPassedDate] = useState('');
    const [event, setevent] = useState('');
    const [venue, setVenue] = useState('');
    const [HtmlContent, setHtmlContent] = useState("");
    const [issubmit , setIsSubmit] = useState(false);

    const { communityId } = useParams();

    const navigate = useNavigate();

    useEffect(()=>{

        if(data_insert_event.affectedRows >= 1 && issubmit == true){
           
            navigate("/events_main");
            window.location.reload();
             
          }
  

    },[data_insert_event]);

   
    const handleTimeChange = (event) => {
      setTime(event.target.value);
    };
  

    const handleVenueChange = (event) => {
      setVenue(event.target.value);
    };


    const handleEventName = (event) => {

        setevent(event.target.value);

    };


      const handleContent = (content) => {

        setHtmlContent(content);
    
        console.log(content);
    
       }

      const  handlePassedDate = (date) =>{

        setPassedDate(date);

       }

       const addSamuhLaganClick = () => {

       // alert(passedDate+"__"+time+"___"+venue+"___"+HtmlContent+"____"+event);

        if(HtmlContent != "" && time != "" && venue != "" && passedDate != "" && event != ""){

            addEvent({
                "eventname":event,
                "date":passedDate,
                "time":time,
                "venue":venue,
                "details":HtmlContent,
                "communityId":communityId
            });
            setIsSubmit(true);

        }else{

            alert("All Fields are compulsory");

        }


       }
    

       
  
     let role = localStorage.getItem(ROLE);

    return (<>
 
 <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "105px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , backgroundColor:"#F8F8F8"}}>
          
          <Typography variant="h5" style={{ color: '#800080' , backgroundColor:"pink" , padding:"10px 20px" , fontWeight:"bold"}}>Add Events</Typography>

          <Grid container spacing={2}>

          <Grid item xs={12}>
          <TextField
            label="Event Name"
            value={event}
            onChange={handleEventName}
            fullWidth
          />
        </Grid>

        <Grid item xs={2} style={{marginTop:"0px" ,paddingLeft:"30px"}}><h3>Select Event Date</h3></Grid>
        <Grid item xs={10} style={{marginTop:"10px"}}>
       
        <DatePicker showToday={true} setPassDate={handlePassedDate}/>
      

        </Grid>
        <Grid item xs={2} style={{marginTop:"0px" ,paddingLeft:"30px"}}><h3>Select Event Time</h3></Grid>
        <Grid item xs={10}>
      <TextField
        label="Choose Event Time"
        defaultValue="00:00"
        type="time"
        value={time}
        onChange= {handleTimeChange}
        style={{ borderColor: '#000', borderWidth: '2px' }}
        InputLabelProps={{ 
          shrink: true, 

        }} 
        // 5 minutes 
        inputProps={{ 
          step: 300, 
        }} 
      />
    

          
         </Grid>


        <Grid item xs={12}>
          <TextField
            label="Venue"
            value={venue}
            onChange={handleVenueChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={2} style={{marginTop:"0px" ,paddingLeft:"30px"}}><h3>Add Event Details</h3></Grid>
        <Grid item xs={12}>
        <RichTextEditor SentContent={handleContent} />
        </Grid>
     
        <Grid item xs={12} alignContent={"center"} alignItems={"center"}>
           <Button variant="contained" style={{ backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"15px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px" , justifyContent:"center" , width:"100%"}} onClick={addSamuhLaganClick} >Save SamuhLagan Events</Button>

</Grid>


          </Grid>

  </div>
 
 
 
 </>);


}


export default AddEvent;
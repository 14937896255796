import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import React from "react";


function ViewDetailsSupport({isopen , setClose, Details}){

     
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
     
    setClose(false);
    setOpen(false);
  }




    useEffect(()=>{
    

        setOpen(isopen);
       
    },[]);

    return (<>

        <Dialog open={open} onClose={handleClose} maxWidth="lg">
              <DialogTitle>Details of Ticket for Customer "{Details.customer_name}"</DialogTitle>
              <DialogContent>
        
              
              <div style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '10px', marginBottom: '20px', border: '1px solid #ccc' }}>
      <Typography variant="h6" style={{ color: '#333', borderBottom: '2px solid #AA336A', paddingBottom: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Details</Typography>
      <div>
        <div style={{ marginBottom: '20px' }}>
          <Typography variant="subtitle1" style={{ color: '#555' }}>Title: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.title}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Customer Name: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.customer_name}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Customer Contact: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.customer_phone}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Customer Complaint: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.customer_complaint}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Customer Timing: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.customer_timing}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Date Time Complaint: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.date_time_complain}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Description: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.description}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Status: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.status_ticket}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Priority: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.priority}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Created By: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.created_by}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Assigned To: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.assigned_to}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Created At: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.created_at}</span></Typography>
          <Typography variant="subtitle1" style={{ color: '#555', marginTop: "10px" }}>Updated At: <span style={{ fontWeight: 'bold', color: '#000' }}>{Details.updated_at}</span></Typography>
        </div>
      </div>
    </div>


   </DialogContent>
                    <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
        
                </>
                
              );




}

export default ViewDetailsSupport;
const reducer = (state, action) => {
    switch (action.type) {
      case 'LIFESTYLE_DETAILS_EDIT':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'LIFESTYLE_DETAILS_EDIT_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };
        case 'LIFESTYLE_DETAILS_SUBMIT':
        return {
          ...state,
          life_insert: action.payload,
          loading: false,
          error: null,
        };
      case 'LIFESTYLE_DETAILS_SUBMIT_ERROR':
        return {
          ...state,
          life_insert: [],
          loading: false,
          error: action.payload,
        };
        case 'LIFESTYLE_DETAILS_UPDATE':
        return {
          ...state,
          life_update: action.payload,
          loading: false,
          error: null,
        };
      case 'LIFESTYLE_DETAILS_UPDATE_ERROR':
        return {
          ...state,
          life_update: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducer;
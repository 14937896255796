const reducerOccupation = (state, action) => {
    switch (action.type) {
      case 'OCCUPATION_DETAILS_EDIT_ADMIN':
        return {
          ...state,
          data_occupation_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'OCCUPATION_DETAILS_EDIT_ERROR_ADMIN':
        return {
          ...state,
          data_occupation_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'INSERT_OCCUP_DETAILS_ADMIN':
        return {
          ...state,
          occupation_insert_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_OCCUP_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          occupation_insert_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_OCCUP_DETAILS_ADMIN':
        return {
          ...state,
          occupation_update_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_OCCUP_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          occupation_update_admin: [],
          loading: false,
          error: action.payload,
        };
   
      default:
        return state;
    }
  };

  export default reducerOccupation;
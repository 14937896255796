import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import settingsReducer from '../reducer/settingsReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const SettingsContext = createContext();

const initialState = {
  insert_list: [],
  update_list:[],
  settings_list:[],
  delete_account_response:[],
  loading: true,
  error: null
};

let API = `${BASE_URL}`;


export const SettingsProvider = ({ children }) => {

const [state, dispatch] = useReducer(settingsReducer , initialState);

  const submit_settings = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_settings` , article);
      dispatch({ type: 'INSERT_SETTINGS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_SETTINGS_ERROR', payload: error });
    }
  };


  const update_settings = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_settings` , article);
      dispatch({ type: 'UPDATE_SETTINGS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_SETTINGS_ERROR', payload: error });
    }
  };


  const fetch_settings = async (article) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/fetch_privacy_settings` , article);
      dispatch({ type: 'FETCH_SETTINGS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'FETCH_SETTINGS_ERROR', payload: error });
    }
  };


  const DeleteAccount = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/delete_account` , article);
      dispatch({ type: 'DELETE_ACCOUNT', payload: response.data });
    } catch (error) {
      dispatch({ type: 'DELETE_ACCOUNT_ERROR', payload: error });
    }
  };



  useEffect(() => {
    fetch_settings({
            "userId":localStorage.getItem(USER_ID)
        });
  }, []);


 
  
  return <SettingsContext.Provider value={{ ...state , dispatch , submit_settings, update_settings, fetch_settings , DeleteAccount}}>{children}</SettingsContext.Provider>;
};

export const useSettingsContext = () => useContext(SettingsContext);
import { useState } from "react";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import CommunityModal from "../../component/CommunityModal";
import StylishLabel from "../../component/StylishLabel";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { usePaymentContext } from "../../contexts_admin/payment_context";
import { Chip, Grid, Pagination, Switch  ,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme ,MenuItem , Modal , Button} from "@mui/material";
import { useEffect } from "react";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useNavigate } from "react-router-dom";
import React from "react";





function PaymentListAdmin(){

    const {paidListMember_fetchAll ,paidListMember_fetchPendingOrFailed ,paidListMember_fetchSuccessful , paidListMember_updatePaymentVerification ,  paidListMember_fetchExpired ,loading_payment_admin ,  data_payment_all , data_membership_fetch_expired , data_membership_pending_failed , data_membership_successful ,data_mmebership_updatepayment_verification } = usePaymentContext();


    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);

    const [MembershipList, setMembershipList] = useState([]);
    const [openDetails , setOpenDetails] = useState(false);
    const [Index , setIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);

    const [Type , setType] = useState("all");
    const [Query , setQuery] = useState("");
    const [Status , setStatus] = useState("");
    const [UserId , setUserId] = useState("");

    const [open , setopen] = useState(false);

    let role = localStorage.getItem(ROLE);

    const handleOpenDetails = (index) => {
      setIndex(index);
      setOpenDetails(true);

    }

    const handleCloseDetails = () => {

      setOpenDetails(false);

    }




    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });


      useEffect(()=>{

        if(data_mmebership_updatepayment_verification.affectedRows >= 1){

             window.location.reload();

        }else if(data_mmebership_updatepayment_verification.affectedRows == 0){
            alert("Failed Uploading status");
        }

      },[data_mmebership_updatepayment_verification]);

      


    useEffect(()=>{

        if(data_payment_all.length > 0 || loading_payment_admin == false){

          setLoad(true);
         

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

          paidListMember_fetchAll({
                "communityId":communityId_admin,
                "offset":"0",
                "query":""
              });

        }
       
    },[data_payment_all]);


    const handleClose = () => {
        setopen(false);
     };

     const handleOpenStatus = (Details) => {

        setStatus(Details.payment_verification);
        setopen(true);
        setUserId(Details.userId);
        

     }



    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  

      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        paidListMember_fetchAll({
            "communityId":communityId_admin,
            "offset":"0",
            "query":""
          });
      }

      const handleChangestatus = (event) => {

        setStatus(event.target.value);

      }


      const handleUpdateMembership = () => {

        paidListMember_updatePaymentVerification({
            "userId":UserId,
            "communityId":communityId,
            "status":Status
        });

    
      };


      const handlePageChange = (event, value) => {
    
        setCurrentPage(value);

           if(Type == "all"){

            paidListMember_fetchAll({
                "communityId":communityId,
                "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
                "query":Query
              });
    

           }else if(Type == "pending"){

            paidListMember_fetchPendingOrFailed({
                "communityId":communityId,
                "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
                "query":Query
              });
    


           }else if(Type == "success"){

            paidListMember_fetchSuccessful({
                "communityId":communityId,
                "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
                "query":Query
              });
    
            
           }else if(Type == "expired"){

            paidListMember_fetchExpired({
                "communityId":communityId,
                "offset": value == 1 ? 0 : (((value - 2) * 10) + 10),
                "query":Query
              });
    
            
           }
          
  
    }


      const handleChange = (event) => {

        setType(event.target.value);

        if(event.target.value == "all"){

            paidListMember_fetchAll({
                "communityId":communityId,
                "offset":"0",
                "query":""
              });

        }else if(event.target.value == "pending"){

            paidListMember_fetchPendingOrFailed({
                "communityId":communityId,
                "offset":"0",
                "query": ""
              });

        }else if(event.target.value == "success"){

            paidListMember_fetchSuccessful({
                "communityId":communityId,
                "offset":"0",
                "query":""
              });

        }else {

            paidListMember_fetchExpired({
                "communityId":communityId,
                "offset":"0",
                "query":""
              });


        }


      }


      const handleChangeSearch = (event) => {

        setQuery(event.target.value);

        if(Type == "all"){

            paidListMember_fetchAll({
                "communityId":communityId,
                "offset":"0",
                "query":event.target.value
              });

        }else if(Type == "pending"){

            paidListMember_fetchPendingOrFailed({
                "communityId":communityId,
                "offset":"0",
                "query": event.target.value
              });

        }else if(Type == "success"){

            paidListMember_fetchSuccessful({
                "communityId":communityId,
                "offset":"0",
                "query":event.target.value
              });

        }else {

            paidListMember_fetchExpired({
                "communityId":communityId,
                "offset":"0",
                "query":event.target.value
              });

        }

      }


      const navigate = useNavigate();
      const getUserDetails = (Details) => {

        navigate(`/user_details_byid?userId=${Details.userId}&communityId=${communityId}&username=${Details.member_name}&profile_id=${""}`);

      }



    return (<>
    
        {  load == false ?  <RotatingHeart/> : 
           
           <div  style={{backgroundColor:"#FAFBFD"}}>
    
            { role == "developer" ? <StylishLabel text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
    
            <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px"   }}>

        <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>


        <Grid container spacing={2} style={{marginBottom:"20px"}}>

        <Grid item xs={6} >
        <TextField
          label="Enter text to search"
          variant="outlined"
          value={Query}
          onChange={handleChangeSearch}
          fullWidth
        >
        </TextField>
      </Grid>

        <Grid item xs={6} >
        <TextField
          select
          label="Filter Below Wise"
          variant="outlined"
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="all">All Payments</MenuItem>
          <MenuItem value="pending">Pending Or failed Payments</MenuItem>
          <MenuItem value="success">Successful Payments</MenuItem>
          <MenuItem value="expired">Expired Payments</MenuItem>
        </TextField>
      </Grid>

      </Grid>
    
        <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Paid Members List</Typography>
    
    <TableContainer component={Paper}>
      <Table>
        <TableHead>

          <TableRow style={{width:"100%"}}>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Member Name</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Plan/Package Name</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Amount</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Order ID</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Payment Date</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Is Payment Verified?</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Update Payment Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data_payment_all.length > 0 && data_payment_all[0].map((free_members, index) => (
            <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
              <TableCell style={{ color: 'black', fontSize: '15px'   }}>{(index+1)}</TableCell>
              <TableCell style={{  fontSize: '15px' , color:"blueviolet", textDecorationLine:"underline"   }}  onClick={()=>getUserDetails(free_members)}>{free_members.member_name}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.package_name}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.amount}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.code}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.date}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.payment_verification == "0" ? "Pending" : free_members.payment_verification == "1" ? "Successful Payment" : "Payment Failed"}</TableCell>
              <TableCell style={{ fontWeight:"bold" , color:"blue", textDecorationLine:"underline" }} onClick={()=>handleOpenStatus(free_members)}>Update Payment</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
            <Pagination
                count={data_payment_all.length > 0 && Math.ceil(data_payment_all[1][0]["total_row_count"] / 10)}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                size="medium"
            />
        </div>
    
    
 
            </Grid>
    
            </Grid>
        
    
            {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
            <Modal open={open} onClose={handleClose}>
      <Grid container spacing={1} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Grid item xs={12}>
        <Grid item xs={12} >
        <TextField
          select
          label="Select Payment Status to update"
          variant="outlined"
          value={Status}
          onChange={handleChangestatus}
          fullWidth
        >
          <MenuItem value="1">Successful</MenuItem>
          <MenuItem value="2">Failed</MenuItem>
        </TextField>
      </Grid>
        </Grid>

        <Button variant="contained" style={{  width: "350px" ,  backgroundColor: '#d74894', borderRadius: '20px' , marginTop:"50px" , marginBottom:"15px" , fontWeight:"bold" , fontSize:"16px"}} onClick={handleUpdateMembership}>Update Payment Status</Button>
        </Grid>
        </Modal>
    
        </div>
    
        }
        
        </>);


}

export default PaymentListAdmin;
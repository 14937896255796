const reducerFamily = (state, action) => {
    switch (action.type) {
      case 'FAMILY_DATA_FETCH_ADMIN':
        return {
          ...state,
          data_family_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'FAMILY_DATA_FETCH_ERROR_ADMIN':
        return {
          ...state,
          data_family_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'INSERT_FAMILY_DETAILS_ADMIN':
        return {
          ...state,
          family_insert_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_FAMILY_DETAILS_ERROR_AMDIN':
        return {
          ...state,
          family_insert_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_FAMILY_DETAILS_ADMIN':
        return {
          ...state,
          family_update_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_FAMILY_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          family_update_admin: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerFamily;
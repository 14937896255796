import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MaskedInput from 'react-text-mask';

const DateMaskedTextField = ({ value, onChange , label }) => {
  const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  return (
    <TextField
      label={label}
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent: MaskedInput,
        value,
        onChange,
        startAdornment: (
          <InputAdornment position="start">
            <DateRangeIcon />
          </InputAdornment>
        ),
      }}
      inputProps={{ mask: dateMask, guide: false }}
    />
  );
};



const TimeMaskedTextField = ({ value, onChange , label }) => {
  const dateMask = [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];

  return (
    <TextField
      label={label}
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent: MaskedInput,
        value,
        onChange,
        startAdornment: (
          <InputAdornment position="start">
            <DateRangeIcon />
          </InputAdornment>
        ),
      }}
      inputProps={{ mask: dateMask, guide: false }}
    />
  );
};


export { DateMaskedTextField , TimeMaskedTextField };

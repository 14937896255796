import React, { createContext, useContext , useReducer, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';
import memberdetailReducer from '../reducer/memberdetailReducer';
import { useState } from 'react';

const memberdetailsContext = createContext();

const initialState = {
  list_member_details:[],  
  list_member_details_own:[],
  loading: true,
  loading_own_details:true,
  error: null,
  data_horoscope_horo: [],
  data_navmsa_horo: [],
  data_match_making_horo: [],
  data_insert_view_profile:[],
  data_insert_view_contact:[],
  insert_shortlist_data:[],
  insert_interest_data:[],
  insert_block_user_data:[],
  insert_notificatioin_data:[],
  insert_accept_reject_list:[]
};

let API = `${BASE_URL}`;


export const MemberDetailsProvider = ({ children }) => {

const [state, dispatch] = useReducer(memberdetailReducer , initialState);
const [data, setData] = useState(null);

const setContextData = (newData) => {
    setData(newData);
  };

const MemberDetailsList = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/profiledetail_fetch` , params);

      dispatch({ type: 'VIEW_MEMBER_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'VIEW_MEMBER_DETAILS_ERROR', payload: error });
    }
  };



  const MemberDetailsListOWn = async (params) => {
    try {

      const response = await axios.post(`${API}`+`fetch_data/profiledetail_fetch` , params);
      dispatch({ type: 'VIEW_MEMBER_DETAILS_OWN', payload: response.data.data });
    } catch (error) {

      dispatch({ type: 'VIEW_MEMBER_DETAILS_OWN_ERROR', payload: error });
    }
  };




    const fetchHoroscopeChartHoro = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/birth_chart` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'HOROSCOPE_CHART_VIEWHORO', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'HOROSCOPE_CHART_VIEWHORO_ERROR', payload: error });
    }
  };



  const fetchNavmsaChartHoro = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/navmsa_chart` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'NAVMSA_CHART_VIEWHORO', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'NAVMSA_CHART_VIEWHORO_ERROR', payload: error });
    }
  };

  


  const fetchMatchMakingHoro = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/match_score` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'MATCH_MAKING_HORO', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'MATCH_MAKING_HORO_ERROR', payload: error });
    }
  };




  const InsertViewProfile = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/view_other_profile` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'INSERT_VIEW_PROFILE', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_VIEW_PROFILE_ERROR', payload: error });
    }
  };



  const InsertViewContact = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/view_other_contact` , params);

      console.log(response.data.data);
      
      dispatch({ type: 'INSERT_VIEW_CONTACT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_VIEW_CONTACT_ERROR', payload: error });
    }
  };



  const insertShortlist_memberdetails = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/shortlist` , params);

      dispatch({ type: 'SHORTLIST_INSERT_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SHORTLIST_INSERT_DETAILS_ERROR', payload: error });
    }
  };

  const express_interest_insert_mmeberdetails = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/express_interest_insert` , params);

      dispatch({ type: 'EXPRESS_INTEREST_INSERT_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'EXPRESS_INTEREST_INSERT_DETAILS_ERROR', payload: error });
    }
  };


  const insert_block_user = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/block_user` , params);

      dispatch({ type: 'BLOCK_USER', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'BLOCK_USER_ERROR', payload: error });
    }
  };


  const insertNotification_memberdetails = async (params) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification` , params);

      dispatch({ type: 'INSERT_NOTIFICATION_MEMBER', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_MEMBER_ERROR', payload: error });
    }
  };


  const accept_reject_request_details = async (article) => {
    try {
      const response = await axios.post(`${API}`+`activity/interest_accept_reject` , article);
      

      dispatch({ type: 'ACCEPT_REJECT_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ACCEPT_REJECT_DETAILS_ERROR', payload: error });
    }
  };


  const insert_notification_count_details = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_notification_count` , article);
      

      dispatch({ type: 'INSERT_NOTIFICATION_COUNT_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_NOTIFICATION_COUNT_DETAILS_ERROR', payload: error });
    }
  };




  useEffect(()=>{
      
    MemberDetailsListOWn({
        "userId":localStorage.getItem(USER_ID),
        "communityId":localStorage.getItem(COMMUNITY_ID),
        "original": "en",
        "Id":localStorage.getItem(USER_ID),
        "myuserId":localStorage.getItem(USER_ID),
        "translate": [localStorage.getItem(TRANSLATED_LANG)]
      });
    
  },[]);


  useEffect(() => {
       
    if(data != null){
    MemberDetailsList({
        "userId":data,
        "communityId":localStorage.getItem(COMMUNITY_ID),
        "original": "en",
        "Id":data,
        "myuserId":localStorage.getItem(USER_ID),
      "translate": [localStorage.getItem(TRANSLATED_LANG)]
      });
    }
    
  }, [data]);
  


  



  return <memberdetailsContext.Provider value={{ ...state , dispatch , MemberDetailsList , MemberDetailsListOWn , fetchHoroscopeChartHoro , fetchNavmsaChartHoro , fetchMatchMakingHoro , InsertViewProfile , InsertViewContact ,setContextData ,insertShortlist_memberdetails , express_interest_insert_mmeberdetails ,insert_block_user , insertNotification_memberdetails , accept_reject_request_details }}>{children}</memberdetailsContext.Provider>;
};

export const useMemberDetailsContext = () => useContext(memberdetailsContext);
const reducerEducation = (state, action) => {
    switch (action.type) {
      case 'EDUCATION_DETAILS_EDIT_ADMIN':
        return {
          ...state,
          data_education_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'EDUCATION_DETAILS_EDIT_ERROR_ADMIN':
        return {
          ...state,
          data_education_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'INSERT_EDUCATION_DETAILS_ADMIN':
        return {
          ...state,
          education_insert_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_EDUCATION_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          education_insert_admin: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_EDUCATION_DETAILS_ADMIN':
        return {
          ...state,
          education_update_admin: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_EDUCATION_DETAILS_ERROR_ADMIN':
        return {
          ...state,
          education_update_admin: [],
          loading: false,
          error: action.payload,
        };

   
      default:
        return state;
    }
  };

  export default reducerEducation;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerNormalSearch from '../reducer/normal_searchReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const normal_searchContext = createContext();

const initialState = {
  data: [],
  data_saved_search: [],
  data_selected_saved_search: [],
  loading: true,
  error: null,
};

let API = `${BASE_URL}`;


export const NormalSearchProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerNormalSearch , initialState);

const fetchNormalSearchItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/normal_searchdata_fetch` , params);
      
      dispatch({ type: 'NORMLA_SEARCH_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'NORMLA_SEARCH_EDIT_ERROR', payload: error });
    }
  };


  const insertSavedSearch = async (params) => {
    try {
      const response = await axios.post(`${API}`+`activity/save_search_insert` , params);

      dispatch({ type: 'SAVED_SEARCH_INSERT' , payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SAVED_SEARCH_INSERT_ERROR' , payload: error });
    }
  };


  const SelectSavedSearch = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/select_saved_search` , params);

      dispatch({ type: 'SAVED_SEARCH_SELECT' , payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'SAVED_SEARCH_SELECT_ERROR' , payload: error });
    }
  };




  useEffect(() => {
       
    fetchNormalSearchItems({
            "userId":localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": [localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <normal_searchContext.Provider value={{ ...state , dispatch , fetchNormalSearchItems , insertSavedSearch , SelectSavedSearch}}>{children}</normal_searchContext.Provider>;
};

export const useNormalSearchContext = () => useContext(normal_searchContext);
import { useEffect, useState } from "react";
import { useNormalSearchContext } from "../../contexts/normalSearchContext";
import { useTranslation } from "react-i18next";
import { Button, Grid, InputLabel, Modal, TextField } from "@mui/material";
import { DropdownSelect } from "../../app_utils/input_fields";
import { gender, with_photo } from "../../data/data";
import { produce } from "immer";
import { generateYears } from "../../common_utils/utils";
import { COMMUNITY_ID, GENDER, TRANSLATED_LANG, USER_ID } from "../../common_utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { grey } from "@mui/material/colors";




function Normal_Search({passPath}) {

    const { data, loading, error , insertSavedSearch , data_saved_search} = useNormalSearchContext();
    const [isAnimated, setAnimated] = useState(false);
    const { t } = useTranslation();

    const [marital_list, setMarital] = useState([]);
    const [degreeList, setDegreeList] = useState([]);
    const [occupationList, setOccupation] = useState([]);
    const [caste_list, setCaste] = useState([]);

    const location = useLocation();
    

     

    const modalStyle = {
        position: 'absolute',
        width: 300,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid #e91e63', // Pink border
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.5)', // Shadow
        borderRadius: '8px',
        padding: '20px',
        outline: 'none',
      };
    
      const modalTitleStyle = {
        color: '#e91e63', // Pinkish color
        textAlign: 'center',
        marginBottom: '20px',
      };
    


    const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

    const degree_lngwise = (degree) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_name,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];



            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const degree_list = [{ label: 'Any', value: 'Any' }, ...degree.map(user => ({
                label: user.degree_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setDegreeList(degree_list);
        }


    }

    const occupation_lngwise = (occupation) => {

        if (localStorage.getItem(TRANSLATED_LANG) == 'en') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occupation,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'hi') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_hindi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'gu') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_guj,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'mr') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_marathi,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ta') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_tamil,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        } else if (localStorage.getItem(TRANSLATED_LANG) == 'ur') {
            const occupation_list = [{ label: 'Any', value: 'Any' }, ...occupation.map(user => ({
                label: user.occup_urdu,  // Adjust based on your data structure
                value: user.Id         // Adjust based on your data structure
            }))];

            setOccupation(occupation_list);
        }


    }




    useEffect(() => {

        passPath(t('normal_search'));
        setAnimated(true);

        if (data.length > 0) {

            const marital_status = Object.values(data[0]).map((item) => ({
                label: item.label,
                value: item.value
            }));

            const caste_listing = [{ label: 'Any', value: 'Any' }, ...Object.values(data[1]).map((item) => ({
                label: item.caste,
                value: item.Id
            }))];

            degree_lngwise(data[2]);
            occupation_lngwise(data[4]);

            setMarital(marital_status);
            setCaste(caste_listing);


            if(location.state != null){

                const {jsonData} = location.state;

            setNormalSearch({
                gender: jsonData.gender,
                age_from: jsonData.age_from,
                age_to: jsonData.age_to,
                marital_status: jsonData.marital_status,
                education: jsonData.education_list,
                occuaption: jsonData.occupation_list,
                with_photo: '',
                caste: jsonData.caste
            });
        }
        
            
            

        }


    }, [data]);


    const [normalSearchField, setNormalSearch] = useState({
        gender: localStorage.getItem(GENDER).toLowerCase() == "male" ? "Female" : "Male",
        age_from: '16',
        age_to: '70',
        marital_status: '1',
        education: 'Any',
        occuaption: 'Any',
        with_photo: '',
        caste: 'Any'
    });

    const handleChange = (fieldName) => (event) => {
        const newValue = event.target.value;
 
        // Use immer to update the state based on the current state
        const nextState = produce((draft) => {
            draft[fieldName] = newValue;
        });

        // Set the updated state
        setNormalSearch(nextState);
    };


    const navigate = useNavigate();
    const handleClickSearch = () => {


        if (location.pathname == "/normal_search" || location.pathname == "/search") {
            navigate('/normal_search_result', {
                state: {
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "0", is_handicap: "0"
                }
            });
        } else if (location.pathname == "/mangalik_search") {

            navigate('/normal_search_result', {
                state: {
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "1", is_handicap: "0"
                }
            });

        } else if (location.pathname == "/handicap_search") {


            navigate('/normal_search_result', {
                state: {
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "0", is_handicap: "Yes"
                }
            });


        }

    }

    useEffect(()=>{



    },[data_saved_search]);


    const handleSubmitSaveSearch = () => {



        handleClose();

        if (location.pathname == "/normal_search" || location.pathname == "/search") {

            insertSavedSearch({
              "search_name":inputValue,
              "search_params":JSON.stringify({
                age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                caste: normalSearchField.caste, isMangalik: "0", is_handicap: "0"
            }),
            "search_type":"normal",
             "userId": localStorage.getItem(USER_ID),
             "communityId":localStorage.getItem(COMMUNITY_ID)
            });

            navigate('/normal_search_result', {
                state: {
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "0", is_handicap: "0"
                }
            });
        } else if (location.pathname == "/mangalik_search") {

            insertSavedSearch({
                "search_name":inputValue,
                "search_type":"mangalik",
                "search_params":JSON.stringify({
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "1", is_handicap: "0"
                }),
               "userId": localStorage.getItem(USER_ID),
               "communityId":localStorage.getItem(COMMUNITY_ID)
              });

            navigate('/normal_search_result', {
                state: {
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "1", is_handicap: "0"
                }
            });


            insertSavedSearch({
                "search_name":inputValue,
                "search_type":"handicap",
                "search_params":JSON.stringify({
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "0", is_handicap: "Yes"
                }),
               "userId": localStorage.getItem(USER_ID),
               "communityId":localStorage.getItem(COMMUNITY_ID)
              });        } else if (location.pathname == "/handicap_search") {


            navigate('/normal_search_result', {
                state: {
                    age_from: normalSearchField.age_from, age_to: normalSearchField.age_to, marital_status: normalSearchField.marital_status,
                    education_list: normalSearchField.education, occupation_list: normalSearchField.occuaption, gender: normalSearchField.gender,
                    caste: normalSearchField.caste, isMangalik: "0", is_handicap: "Yes"
                }
            });


        }

    


    }


    return (
        <>
            <div style={{ display: 'grid', placeItems: 'center', height: "60vh", marginTop:"230px", marginBottom:"50px"  ,width: "100%", paddingLeft: "10%", paddingRight: "10%" }}>


                <Grid container columnSpacing={1} xs={12} md={12}>

                    <Grid container columnSpacing={1} xs={12} md={12} style={{
                        padding: '20px',
                        width: "80%",
                        zIndex: '1',
                        border: '2px dashed #301935',
                        borderRadius: '10px',
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                    }} >

                        <Grid item xs={12} md={3}  >

                            <DropdownSelect options={gender} label={t('gender')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('gender')}
                                value={normalSearchField.gender} />

                        </Grid>
                        <Grid item xs={12} md={3}  >

                            <DropdownSelect options={generateYears(16, 70)} label={t('from_age')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('age_from')}
                                value={normalSearchField.age_from} />

                        </Grid>

                        <Grid item xs={12} md={3}  >

                            <DropdownSelect options={generateYears(16, 70)} label={t('to_age')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('age_to')}
                                value={normalSearchField.age_to} />

                        </Grid>

                        <Grid item xs={12} md={3}  >

                            <DropdownSelect options={marital_list} label={t('marital_status_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('marital_status')}
                                value={normalSearchField.marital_status} />

                        </Grid>




                        <Grid item xs={12} md={4}  >

                            <DropdownSelect options={degreeList} label={t('education_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('education')}
                                value={normalSearchField.education} />

                        </Grid>
                        <Grid item xs={12} md={4}  >

                            <DropdownSelect options={occupationList} label={t('occuaption_p_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('occuaption')}
                                value={normalSearchField.occuaption} />

                        </Grid>

                        <Grid item xs={12} md={4}  >

                            <DropdownSelect options={with_photo} label={t('with_photo')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('with_photo')}
                                value={normalSearchField.with_photo} />

                        </Grid>


                        <Grid item xs={12} md={4}  >

                            <DropdownSelect options={caste_list} label={t('caste_l')} variant="contained" focusedBorderColor={"#E3256B"}
                                bordercolor={"#7f7f7f"} hoverBorderColor={"#E3256B"} height={60} width={"80%"} borderWidth={0.5}
                                onChange={handleChange('caste')}
                                value={normalSearchField.caste} />

                        </Grid>



                    </Grid>


                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={3.4}></Grid>

                    <Grid item xs={12} md={2.3} style={{ justifyContent: "end", alignContent: "end" }}>
                        <Button style={{
                            marginTop: "10px",
                            background: "#E3256B", width: "200px", padding: "10px", color: "white", fontWeight: "bold"
                        }} onClick={handleClickSearch}>Search</Button>
                    </Grid>

                    <Grid item xs={12} md={2.3} style={{ justifyContent: "end", alignContent: "end" }}>
                        <Button style={{
                            marginTop: "10px",
                            background: "#E3256B", width: "200px", padding: "10px", color: "white", fontWeight: "bold"
                        }} onClick={handleOpen}>Save & Search</Button>
                    </Grid>


                </Grid>



                <Modal open={open} onClose={handleClose} >
        <div style={modalStyle}>
          <h2 style={modalTitleStyle}>Save And Search</h2>
          <TextField
            label="Enter Your Save & Search Title"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button onClick={handleSubmitSaveSearch} variant="contained" color="primary" style={{ marginTop: 16 }}>
            Save & Search
          </Button>
        </div>
      </Modal>


            </div>

        </>

    );



}
export default Normal_Search;
import { Button, CardMedia, Grid } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useCallback } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ADMIN_COMMUNITY_ID, IMAGE_URL, ROLE } from "../../../common_utils/constants";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import CommunityModal from "../../component/CommunityModal";
import StylishLabelOther from "../../component/StylishLabelOther";
import StylishLabel from "../../component/StylishLabel";
import { useWebThemeContextAdmin } from "../../contexts_admin/web_theme_context";
import { useEffect } from "react";





function MobileTheme(){


    const {androidapp_theme_insert , androidapp_theme_update , androidapp_theme_fetch , data_insert_androidtheme , data_update_androidtheme , data_select_androidtheme} = useWebThemeContextAdmin();

    const [files , setFiles] = useState([]);
    const [isinsert , setisinsert] = useState(true);
    const [communityId , setcommunityId] = useState("");
    const [openCommunity , setopenCommunity] = useState(false);
    const [first ,setisfirst] = useState(false);

    const dropzoneStyle = { 
        border: '2px dashed #d9d9d9',
       borderRadius: '4px',
       padding: '20px',
       textAlign: 'center',
       cursor: 'pointer',
     };

     useEffect(()=>{

        let communityId_admin  =  "0";
        if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

          communityId_admin = localStorage.getItem("communityId_admin");
          setcommunityId(communityId_admin);

        }else{
         communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
         setcommunityId(communityId_admin);
        }

        androidapp_theme_fetch({"communityId":communityId_admin});

    },[]);


    useEffect(()=>{

        if(data_select_androidtheme.length > 0 && first == false){

            downloadFile(data_select_androidtheme[0].splash_image , 0);
            setFields({
                primary_colour:data_select_androidtheme[0].primary_colour,
                secondary_colour:data_select_androidtheme[0].secondary_colour,
                tertiary_colour: data_select_androidtheme[0].tertiary_colour,
            });

           setisfirst(true);
            setisinsert(false);
        }

        if(data_insert_androidtheme.affectedRows >= 1 ||  data_update_androidtheme.affectedRows >= 1){

           alert("Saved Successfully");

        }
    
    },[data_select_androidtheme , data_insert_androidtheme , data_update_androidtheme]);



    const downloadFile = async (element , index) => {

        try {
          const response = await fetch(element);
          const blob = await response.blob();
          const file = new File([blob], element);
    
          //setFiles(prevFiles => [...prevFiles, file]);
    
          setFiles((prevFiles) =>{ let files = [...prevFiles]; files[index] = file;  return files});
          
        } catch (error) {
          console.error('Error fetching or creating file:', error);
        }
    
      };



   const onDrop = useCallback((acceptedFiles) => {
       // Assuming there is an array of existing files in state called 'files'
   
       setFiles([]);
       setFiles((prevFiles) => [
         ...prevFiles,
         ...acceptedFiles.map((file) =>
           Object.assign(file, {
             preview: URL.createObjectURL(file),
           })
         ),
       ]);
   
     }, [setFiles]);
   
   
   
     const { getRootProps, getInputProps } = useDropzone({
       onDrop,
       accept: 'image/*',
     });

     const [fields, setFields] = useState({

        primary_colour:'#fce4ec' ,
        secondary_colour:'#800080',
        tertiary_colour: '#800080',

      });


      const handleFieldChange = (field, value) => {
        setFields(prevFields => ({
          ...prevFields,
          [field]: value
        }));
      };

      const handleClickCommunity = () => {
        setopenCommunity(true);
      };
    
    
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }


    const handleClickCommunityLink = (communityId_admin) => {
  
      setcommunityId(communityId_admin);
      localStorage.setItem("communityId_admin" , communityId_admin);

     
    };

    const addMobileTheme = () => {

        const formData = new FormData();

        formData.append('sampleFile[]', files[0]);
        
        formData.append("primary_colour" , fields.primary_colour);
        formData.append("secondary_colour" , fields.secondary_colour);
        formData.append("tertiary_colour" , fields.tertiary_colour);
  
        formData.append("communityId" , communityId);
        formData.append("Id" , data_select_androidtheme[0].Id);
  
        if(isinsert == true){

            formData.append("splash_image",  IMAGE_URL+"uploads/"+files[0].name);
            androidapp_theme_insert(formData);

        }else{
            
            if(files[0].name.includes("http:")){
                formData.append("splash_image",  files[0].name);
            }else{
                formData.append("splash_image",  IMAGE_URL+"uploads/"+files[0].name);
            }
            
            androidapp_theme_update(formData);

        }
        


    }

      let role = localStorage.getItem(ROLE);


      return(<>
      

      { role == "developer" ? <StylishLabel text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "150px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingLeft:"15px" , paddingRight:"15px" , paddingTop:"20px" , paddingBottom:"20px" , backgroundColor:"#F8F8F8"}}>
     
      <Grid container spacing={2}>

      <Grid item xs={12}>

<div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop Your Slider Image here</p>
    </div>

</Grid>
<Grid item xs={12}>

{files.length > 0 ? <CardMedia component="img" height="170" width="250px" image = { URL.createObjectURL(files[0])} alt={`Image 0}`}  style={{objectFit:"contain"}} /> : ""}

</Grid>

<Grid item xs={12}>
<MuiColorInput format="hex" value={fields.primary_colour} onChange={value => handleFieldChange('primary_colour', value)} label={"Primary Colour"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
</Grid>
<Grid item xs={12}>
<MuiColorInput format="hex" value={fields.secondary_colour} onChange={value => handleFieldChange('secondary_colour', value)} label={"Secondary Colour"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
</Grid>
<Grid item xs={12}>
<MuiColorInput format="hex" value={fields.tertiary_colour} onChange={value => handleFieldChange('tertiary_colour', value)} label={"Tertiary Colour"}  fullWidth/>
         <div style={{marginTop:"20px"}} />
</Grid>

        <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={addMobileTheme}
      >
        {isinsert == true ? "Save Mobile Theme" : "Update Mobile Theme"}
      </Button>
      </Grid>


      </Grid>

      {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}

      
    </div>
      </>);


}

export default MobileTheme;
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import styles from '../styles_admin/loginpanel.module.css';
import Lock from '@mui/icons-material/Lock';
import Mail from '@mui/icons-material/Mail';
import { useAdminLoginContext } from "../contexts_admin/login_context";
import {produce} from 'immer';
import { useState } from "react";
import RotatingHeart from "../../common_utils/loading_animator";
import { CSSTransition } from "react-transition-group";
import MsgConfirm from "../../app_utils/MsgConfirm";
import ModalPopup from "../../app_utils/modalpopup";
import { login_validation } from "../../common_utils/validations";
import { ADMIN_COMMUNITY_ID, ADMIN_EMAIL, ADMIN_IS_LOGIN, ADMIN_NAME, ADMIN_PHONE, ADMIN_USERID, PERMISSION, ROLE } from "../../common_utils/constants";
import React from "react";


function LoginAdmin(){


    const { data_login_admin , login , login_status ,loading_login_admin , loginremove} = useAdminLoginContext();

    const [textLogin, setFieldsLogin] = useState({
        email_phone:"",
        password:""
        });



        const [clicked , setclick] = useState(false);
  const [validate , setvalidate] = useState(false);
  const [validationtext , setValidationtext] = useState("");

  const [msg, setMsg] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isAnimated, setAnimated] = useState(false);

  const  handlefromchild = () => {
      setvalidate(false);
  }


  const handleclosemsg = () => {
    setMsg(false);
    window.location.href = '/adminpanel';

    loginremove();

  }


    const handleChange = (fieldName) => (event) => {
            const newValue = event.target.value;
        
            // Use immer to update the state based on the current state
            const nextState = produce((draft) => {
              draft[fieldName] = newValue;
            });
        
            // Set the updated state
            setFieldsLogin(nextState);
          };
      

    useEffect(()=>{

        setAnimated(true);

  if(login_status == "1"){


    if(data_login_admin.length == 0){

        setMsg(true);
        setTitle("LOGIN ALERT!");
        setContent("Login ID OR Password must be invalid");

        
    }else{


     // alert(data_login_admin[0].communityId);

        localStorage.setItem(ROLE , data_login_admin[0].role);
        localStorage.setItem(ADMIN_COMMUNITY_ID , data_login_admin[0].communityId);
        localStorage.setItem(PERMISSION , data_login_admin[0].permissions);
        localStorage.setItem(ADMIN_NAME , data_login_admin[0].name);
        localStorage.setItem(ADMIN_EMAIL , data_login_admin[0].email);
        localStorage.setItem(ADMIN_PHONE , data_login_admin[0].phone);
        localStorage.setItem(ADMIN_USERID , data_login_admin[0].userId);
        localStorage.setItem(ADMIN_IS_LOGIN , "1");

        window.location.href = '/dashboard';
        

        //alert(data_login_admin[0].email);
    }
    

    

  }
        
     

    },[login_status]);


    const onclick = () =>{


        let validationlist = login_validation(textLogin.email_phone ,textLogin.password);
     
        const htmlContent = { __html: validationlist.join("")};
    
        if(textLogin.email_phone == "" ||  textLogin.password == ""){
    
            setValidationtext(htmlContent);
            setvalidate(true);
    
        }else{
    
        const input = {
            "userId":textLogin.email_phone,
            "password":textLogin.password
        };


       setclick(true);
       login(input);

    }
        
    
       



    }



    return (
    
    <>


{clicked == true && loading_login_admin == true ?  <RotatingHeart/> : 

<CSSTransition
      in={isAnimated}
      timeout={500} // Duration of the transition in milliseconds
      classNames="fade"
      unmountOnExit>

    <div className={styles.maincover}>
    <div className={styles.container}>
    <input type="checkbox" id="flip" />
    <div className={styles.cover}>
      <div className={styles.front}>
        <img src="images/frontImg.jpg" alt="" />
        <div className={styles.text}>
          <span className={styles.text1}>WELCOME TO <br/> COMMUNITY MATRIMONIAL</span>
          <span className={styles.text2}>Let's get connected</span>
        </div>
      </div>
      <div className={styles.back}>
        <img className={styles.back} src="images/backImg.jpg" alt=""/>
        <div className={styles.text}>
          <span className={styles.text1}>WELCOME TO <br/> with one step</span>
          <span className={styles.text2}>Let's get started</span>
        </div>
      </div>
    </div>
    <div className={styles.forms}>
        <div className={styles.formContent}>
          <div className={styles.loginForm}>
            <div className={styles.title}>Login</div>
          <form action="#">
            <div className={styles.inputBoxes}>
              <div className={styles.inputBox}>
              <Mail />
                <input type="text" placeholder="Enter your email/UserId/Phone" required value={textLogin.email_phone}  onChange={handleChange("email_phone")}/>
              </div>
              <div className={styles.inputBox}>
              <Lock />
                <input type="password" placeholder="Enter your password" required  value={textLogin.password}  onChange={handleChange("password")}/>
              </div>
              <div className={styles.text}><a href="#">Forgot password?</a></div>
              <div className={`${styles.button} ${styles.inputBox}`}>
                <input type="button" value="Sumbit" onClick={onclick}/>
              </div>
              <div className={`${styles.text} ${styles.signUpText}`}>Click to <label for="flip">Flip</label></div>
            </div>
        </form>
      </div>


      
        
    </div>


    
    </div>
    {validate == true ? <ModalPopup  title="Validation Alert" content={validationtext}  openvalidate={validate} onChildValue={handlefromchild}/> : ""}
  </div>

  <MsgConfirm
          isOpen={msg}
          handleClose={handleclosemsg}
          title={title}
          content={content}
          closeText={"Ok"} />

  </div>
  </CSSTransition>
  

  }
    
    </>);


}

export default LoginAdmin;
const activityReducer = (state, action) => {
    switch (action.type) {

        case 'VIEW_PROFILE':
            return {
                ...state,
                list_view_profile: action.payload,
                loading_profile: false,
                error: null,
            };
        case 'VIEW_PROFILE_ERROR':
            return {
                ...state,
                list_view_profile: [],
                loading_profile: false,
                error: action.payload,
            };



        case 'SHORTLIST_USER':
            return {
                ...state,
                shortlists_user: action.payload,
                loading_shortlist: false,
                error: null,
            };
        case 'SHORTLIST_USER_ERROR':
            return {
                ...state,
                shortlists_user: [],
                loading_shortlist: false,
                error: action.payload,
            };



        case 'VIEW_CONTACT':
            return {
                ...state,
                contactlist_user: action.payload,
                loading_contact: false,
                error: null,
            };
        case 'VIEW_CONTACT_ERROR':
            return {
                ...state,
                contactlist_user: [],
                loading_contact: false,
                error: action.payload,
            };



        case 'BLOCK_IGNORE':
            return {
                ...state,
                block_ignore_list: action.payload,
                loading_block: false,
                error: null,
            };
        case 'BLOCK_IGNORE_ERROR':
            return {
                ...state,
                block_ignore_list: [],
                loading_block: false,
                error: action.payload,
            };



        case 'INTESREST_SENT_RECIEVED':
            return {
                ...state,
                interest_send_recieve_list: action.payload,
                loading_interest: false,
                error: null,
            };
        case 'INTESREST_SENT_RECIEVED_ERROR':
            return {
                ...state,
                interest_send_recieve_list: [],
                loading_interest: false,
                error: action.payload,
            };


            
        case 'SHORTLIST_INSERT':
            return {
                ...state,
                insert_shortlist: action.payload,
                loading: false,
                error: null,
            };
        case 'SHORTLIST_ERROR':
            return {
                ...state,
                insert_shortlist: [],
                loading: false,
                error: action.payload,
            };


        case 'EXPRESS_INTEREST_INSERT':
            return {
                ...state,
                insert_interest: action.payload,
                loading: false,
                error: null,
            };
        case 'EXPRESS_INTEREST_INSERT_ERROR':
            return {
                ...state,
                insert_interest: [],
                loading: false,
                error: action.payload,
            };



            
        case 'INSERT_NOTIFICATION_ACTIVITY':
            return {
              ...state,
              insert_interest_data: action.payload,
              loading: false,
              error: null,
            };
          case 'INSERT_NOTIFICATION_ACTIVITY_ERROR':
            return {
              ...state,
              insert_interest_data: [],
              loading: false,
              error: action.payload,
            };


        default:
            return state;
    }
};

export default activityReducer;
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';






function RichTextEditor({SentContent , getContent}) {


    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        SentContent(editorRef.current.getContent());
      }
    };

    const [initialValue , setInitialValue] = useState("");
    
    useEffect(()=>{

      
      setInitialValue(getContent);
     

    },[getContent]);
  
    return (
        <>
        <Editor
          apiKey='fvqzhxvrxcjq51k6xzj10zeaya9w6z91dtj8sccv1lml8jxe'
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            height: 500,
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ],
            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
          }}
          initialValue={initialValue}
        />

           <button onClick={log}>Log editor content</button>

         </>
      );



}

export default RichTextEditor;
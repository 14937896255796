import React, { useState } from 'react';
import styles from '../../styles_admin/Tabs.module.css'; 
import { WebThemeProvider } from '../../contexts_admin/web_theme_context';
import WebManagement from './web_management';
import Homepageslider from './homepageslider';
import WebTheme from './web_theme';

function WebPageMain() {
  
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {

    setActiveTab(index);
  };

  return (
    <div className={styles.tabscontainer}>
      <div className={styles.tabs}>
        <div className={`${styles.tab} ${activeTab === 0 ? styles.active : ''}`} onClick={() => handleTabClick(0)}>Visibility Functionality</div>
        <div className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`} onClick={() => handleTabClick(1)}>HomePage Slider</div>
        <div className={`${styles.tab} ${activeTab === 2 ? styles.active : ''}`} onClick={() => handleTabClick(2)}>WebPage Theme</div>
      </div>
      <div className={styles.tabcontent}>
        {activeTab === 0 && <div> <WebThemeProvider><WebManagement /></WebThemeProvider></div>}
        {activeTab === 1 && <div> <WebThemeProvider><Homepageslider /></WebThemeProvider> </div>}
        {activeTab === 2 && <div><WebThemeProvider><WebTheme /></WebThemeProvider></div>}
      </div>
    </div>
  );
}

export default WebPageMain;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducerEducation from '../reducer/educationalReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const educationContext = createContext();

const initialState = {
  data: [],
  loading: true,
  error: null,
  education_insert: [],
  education_update:[]
};

let API = `${BASE_URL}`;


export const EducationProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducerEducation , initialState);

const fetchEducationItems = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/education_details_edit` , params);

      console.log(response.data.data[1][0].Id);
      

      dispatch({ type: 'EDUCATION_DETAILS_EDIT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'EDUCATION_DETAILS_EDIT_ERROR', payload: error });
    }
  };


  const submit_educationaldetails = async (article) => {
    try {
      const response = await axios.post(`${API}`+`profile/insert_education` , article);
      dispatch({ type: 'INSERT_EDUCATION_DETAILS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_EDUCATION_DETAILS_ERROR', payload: error });
    }
  };


  const update_educationaletails = async (article) => {
    try {
      const response = await axios.patch(`${API}`+`profile/update_education` , article);
      dispatch({ type: 'UPDATE_EDUCATION_DETAILS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_EDUCATION_DETAILS_ERROR', payload: error });
    }
  };



  useEffect(() => {
       
    fetchEducationItems({
            "userId": localStorage.getItem(USER_ID),
            "communityId":localStorage.getItem(COMMUNITY_ID),
            "original": "en",
            "translate": ["en" , localStorage.getItem(TRANSLATED_LANG)]
        });
    
  }, []);


 
  
  return <educationContext.Provider value={{ ...state , dispatch , fetchEducationItems , submit_educationaldetails , update_educationaletails}}>{children}</educationContext.Provider>;
};

export const useEducationContext = () => useContext(educationContext);
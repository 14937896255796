
const reducer = (state, action) => {
    switch (action.type) {

      case 'LOGIN_SUCCESS':
        return {
          ...state,
          data_login_admin: action.payload,
          loading_login_admin: false,
          login_status:action.payload1,
          error: null,
        };

      case 'LOGIN_ERROR':
        return {
          ...state,
          data_login_admin: [],
          loading_login_admin: false,
          login_status:-1,
          error: action.payload,
        };


        case 'LOGIN_ADMIN_PANEL_REMOVE':
        return {
          ...state,
          data_login_admin: [],
          loading_login_admin: false,
          login_status:-1,
          error: null,
        };

      default:
        return state;
    }
  };

  export default reducer;
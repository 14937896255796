import { Grid, InputLabel } from "@mui/material";
import styles from "../../styles/homepage.module.css";
import "../../styles/homepage.css";
import { useTranslation } from "react-i18next";
import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



function HomePageAbout(){

    const { t } = useTranslation();

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs') || theme.breakpoints.down('sm'));

    const widthcheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const MAX_WIDTH_FOR_MOBILE = 900;

    return(
        <>
         <div className="hero-section">
         <h2 style={{backgroundColor:"#E3256B" , color:"white" ,padding:"5px" , marginLeft: widthcheck <= MAX_WIDTH_FOR_MOBILE ? "5%" : "40%" , marginRight: widthcheck <= MAX_WIDTH_FOR_MOBILE ? "5%" :"40%" , marginTop: widthcheck <= MAX_WIDTH_FOR_MOBILE ?  "160%" : "70px" , borderRadius:"20px" , "fontFamily":"Dosis"}}>{t('our_services')}</h2>
         <Grid container xs={12} style={{maxWidth:"1200px" , marginLeft:"10%" , marginRight:"10%" , marginTop:"20px"}}>

         
            <Grid item md={6} xs={12}  sm={12}>


                    <div style={{ border:"15px solid #E3256B" , borderRadius:"25px" , width: widthcheck <= MAX_WIDTH_FOR_MOBILE ? "60%" : "40%" , height: widthcheck <= MAX_WIDTH_FOR_MOBILE ?  "230px"   : "350px" }}> 


                        <img src="https://img.freepik.com/free-photo/authentic-indian-bride-groom-s-hands-holding-together-traditional-wedding-attire_8353-10049.jpg?size=626&ext=jpg&ga=GA1.1.1114997585.1700156501&semt=sph" 
                        style={{position:"relative" , left:"20%" ,  top:"7%" ,borderRadius: "10%" , height: widthcheck <= MAX_WIDTH_FOR_MOBILE ?  "270px"  : "280px" , width:"130%" , justifyContent:"center" }}>


                        </img>


                    </div>
            



            </Grid>

            <Grid item md={6} >

            <h2 className={styles.about_us}  style={{  marginTop :  widthcheck < MAX_WIDTH_FOR_MOBILE ? "30%"  : "0%"}}>{t('welcome_community')}</h2>


            <Grid container xs={12} >
                <InputLabel style={{fontWeight:"bold" , color:"GrayText"}}>{t('service_label')}</InputLabel>
            </Grid>
            <Grid container xs={12} >
                
            <ul>
    <li className={styles.star}>{t('match_making_label')}</li>
    <li className={styles.star}>{t('horoscope_making_label')}</li>
    <li className={styles.star}>{t('updated_profiles')}</li>
    <li className={styles.star}>{t('variety_search_types')}</li>
        </ul>

        </Grid>


        <Grid container xs={12}  style={{marginTop:"20px"}}>
<div className={styles.breadcrumb}>
<Grid item xs={12} md={4} ><a href="#" style={{fontSize:"16px" , fontWeight:"bold"}}>SIGNUP</a></Grid>
<Grid item xs={12} md={4}><a href="#" style={{fontSize:"16px" , fontWeight:"bold"}}>CONNECT</a></Grid>
<Grid item xs={12} md={4}><a href="#" style={{fontSize:"16px" , fontWeight:"bold"}}>INTERACT</a></Grid>
	
</div>
</Grid>


           
             </Grid>


         </Grid>

         </div>
         
        </>
    );



}


export default HomePageAbout;
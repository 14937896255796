import  React , { useEffect, useState } from "react";
import { useSearchContext } from "../../contexts/searchContext";
import { useLocation } from "react-router-dom";
import { AGE_FROM_P_VAL, AGE_P, AGE_TO_P_VAL, ANNUAL_INCOME_P, ANNUAL_INCOME_P_VAL, BODY_TYPE_P, BODY_TYPE_P_VAL, CITY_P, CITY_P_VAL, DIET_TYPE_P, DIET_TYPE_P_VAL, DRINK_TYPE_P, DRINK_TYPE_P_VAL, EDUCATION_P, EDUCATION_P_VAL, GENDER, HEIGHT_FROM_P_VAL, HEIGHT_P, HEIGHT_TO_P_VAL, MARITAL_STATUS_P, MARITAL_STATUS_P_VAL, OCCUPATION_P, OCCUPATION_P_VAL, SKINTONE_P, SKINTONE_P_VAL, SMOKE_TYPE_P, SMOKE_TYPE_P_VAL, STATE_P, STATE_P_VAL, TRANSLATED_LANG } from "../../common_utils/constants";
import { Grid, Pagination } from "@mui/material";
import "../../styles/styles.css";
import MatriProfileCard from "../search_result/MatriProfileCard";
import { useTranslation } from "react-i18next";
import styles from '../../styles/loading.module.css';
import "../../styles/no_data_found.css";
import RotatingHeart from "../../common_utils/loading_animator";
import { marathiToEnglishNumber } from "../../common_utils/utils";




function MutualMatchResult({passPath}) {

    const duration = 1000; // ms
    const delay = 200; // ms


    const { MUTUAL_MATCH_RESULT , matches_result , loading_mutual } = useSearchContext();
    const { state } = useLocation();

    const [currentPage, setCurrentPage] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [load, setLoad] = useState(false);

    const { t } = useTranslation();

    const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;

    useEffect(() => {


        passPath(t('mutual_matches'));
    //   alert(localStorage.getItem(HEIGHT_FROM_P_VAL)+"--"+localStorage.getItem(HEIGHT_TO_P_VAL)+"--"+localStorage.getItem(HEIGHT_P));

        setAnimate(true);
        MUTUAL_MATCH_RESULT(
            {
                "userId":"1",
                "marital_status":localStorage.getItem(MARITAL_STATUS_P_VAL),
                "perm_state":localStorage.getItem(STATE_P_VAL),
                "perm_city":localStorage.getItem(CITY_P_VAL),
                "education": localStorage.getItem(EDUCATION_P_VAL),
                 "occupation": localStorage.getItem(OCCUPATION_P_VAL),
                  "income_min": localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[0],
                   "income_max":localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[1],
                    "complexion":localStorage.getItem(SKINTONE_P_VAL),
                    "body_type":localStorage.getItem(BODY_TYPE_P_VAL),
                    "diet_type":localStorage.getItem(DIET_TYPE_P_VAL),
                    "drink_type":localStorage.getItem(DRINK_TYPE_P_VAL),
                    "smoke_type":localStorage.getItem(SMOKE_TYPE_P_VAL),
                    "Id":"1",
                    "gender":localStorage.getItem(GENDER),
                    "original": "en",
                    "translate": [localStorage.getItem(TRANSLATED_LANG)],
                    "communityId":"1",
                    "offset":0   
              }
        );

        setTimeout(() => {
            setAnimate(false);
        }, 2000)

    }, []);

    const handlePageChange = (event, value) => {
        setAnimate(true);
        setCurrentPage(value);

        MUTUAL_MATCH_RESULT(
            {
                "userId":"1",
                "marital_status":localStorage.getItem(MARITAL_STATUS_P_VAL),
                "perm_state":localStorage.getItem(STATE_P_VAL),
                "perm_city":localStorage.getItem(CITY_P_VAL),
                "education": localStorage.getItem(EDUCATION_P_VAL),
                 "occupation": localStorage.getItem(OCCUPATION_P_VAL),
                  "income_min": localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[0],
                   "income_max":localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[1],
                    "complexion":localStorage.getItem(SKINTONE_P_VAL),
                    "body_type":localStorage.getItem(BODY_TYPE_P_VAL),
                    "diet_type":localStorage.getItem(DIET_TYPE_P_VAL),
                    "drink_type":localStorage.getItem(DRINK_TYPE_P_VAL),
                    "smoke_type":localStorage.getItem(SMOKE_TYPE_P_VAL),
                    "Id":"1",
                    "gender":localStorage.getItem(GENDER),
                    "original": "en",
                    "translate": [localStorage.getItem(TRANSLATED_LANG)],
                    "communityId":"1",
                    "offset": value == 1 ? 0 : ((value-1)+8)   
              }
        );

        scrollToTop();


    }

    const refresh = () => {

        MUTUAL_MATCH_RESULT(
            {
                "userId":"1",
                "marital_status":localStorage.getItem(MARITAL_STATUS_P_VAL),
                "perm_state":localStorage.getItem(STATE_P_VAL),
                "perm_city":localStorage.getItem(CITY_P_VAL),
                "education": localStorage.getItem(EDUCATION_P_VAL),
                 "occupation": localStorage.getItem(OCCUPATION_P_VAL),
                  "income_min": localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[0],
                   "income_max":localStorage.getItem(ANNUAL_INCOME_P_VAL).split("-")[1],
                    "complexion":localStorage.getItem(SKINTONE_P_VAL),
                    "body_type":localStorage.getItem(BODY_TYPE_P_VAL),
                    "diet_type":localStorage.getItem(DIET_TYPE_P_VAL),
                    "drink_type":localStorage.getItem(DRINK_TYPE_P_VAL),
                    "smoke_type":localStorage.getItem(SMOKE_TYPE_P_VAL),
                    "Id":"1",
                    "gender":localStorage.getItem(GENDER),
                    "original": "en",
                    "translate": [localStorage.getItem(TRANSLATED_LANG)],
                    "communityId":"1",
                    "offset": currentPage == 1 ? 0 : ((currentPage-1)+8)   
              }
        );

    }


    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scroll events
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setIsVisible(scrollTop > 300); // Adjust the scroll threshold as needed
    };

    // Attach scroll event listener on mount
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Remove scroll event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can also use 'auto' for instant scrolling
        });
    };


    const cardStyle = {
        opacity: 0,
        animation: 'fadeIn 0.5s ease-out forwards',
    };


    useEffect(()=>{

        if(loading_mutual ==  false){
           setLoad(true);
        }

    },[loading_mutual]);


    return (

        <>
        
        {  load == false ?  <RotatingHeart/> : 
        <Grid container spacing={5} style={{ marginLeft: "auto", marginRight: "auto", marginTop: "60px", position: "absolute", top: "165px", width: "100%" }}>
            
            <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position:"absolute" , top:"50px" , right:"0" , zIndex:"0" , width:"120px" , height:"120px"}}/>
             <img src="/images/heart_matrimony.png" alt="Rotating Image" className={styles.rotating_image} style={{position:"absolute" , bottom:"80px" , left:"50px" , zIndex:"0" , width:"120px" , height:"120px"}}/>
             <img src="/images/couple.png" alt="Rotating Image"  style={{position:"absolute" , display:"flex" , height:"100vh" , width:"50%" , marginLeft:"25%"  ,opacity:"0.25"}}/>

            
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={5}>
                    {matches_result.length > 0 && (localStorage.getItem(TRANSLATED_LANG) == "mr" ? marathiToEnglishNumber(matches_result[1][0]["0"]["total_row_count"]) > 0  :  matches_result[1][0]["0"]["total_row_count"] > 0) ? Object.values(matches_result[0][0]).map((value, i) => (
                        <Grid key={i} item>

                            <li className={animate ? 'fadeInListItem' : ''} key={i} style={{ animation: animStr(i) }}>
                                <MatriProfileCard value={value} refresh={refresh} />
                            </li>



                        </Grid>
                    )) : <div className="no_data_container">
                    <p className="no_data_label">No Data Found</p>
                  </div>}

                </Grid>
            </Grid>



            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px", width: "100%" }}>
                <Pagination
                    count={matches_result.length > 0 ? Math.ceil(matches_result[1][0]["0"]["total_row_count"] / 8) : 0}
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    color="secondary"
                    shape="rounded"
                    size="large"

                />
            </div>




        </Grid>
}
</>
    );




};

export default MutualMatchResult;
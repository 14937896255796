import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import membershipReducer from '../reducer/membershipReducer';
import { BASE_URL, COMMUNITY_ID, TRANSLATED_LANG, USER_ID } from '../common_utils/constants';

const mmebership_searchContext = createContext();

const initialState = {
  membsership_list: [],
  loading: true,
  error: null,
  create_order_data:[],
  verify_payment_list:[],
  insert_payment:[],
  update_payment:[]
};

let API = `${BASE_URL}`;


export const MembershipProvider = ({ children }) => {

const [state, dispatch] = useReducer(membershipReducer , initialState);

const fetchMemberShip = async (params) => {
    try {
      const response = await axios.post(`${API}`+`fetch_data/membership_fetch` , params);
      
      dispatch({ type: 'MEMBERSHIP', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'MEMBERSHIP_ERROR', payload: error });
    }
  };



  const  createOrder = async (params) => {
    try {
      const response = await axios.post(`${API}`+`payment/createOrder` , params);
      
      dispatch({ type: 'CREATE_ORDER', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'CREATE_ORDER_ERROR', payload: error });
    }
  };


  const  verifyPayment = async (params) => {
    try {
      const response = await axios.post(`${API}`+`payment/verifyPayment` , params);
      
      dispatch({ type: 'VERIFY_PAYMENT', payload: response.data });
    } catch (error) {
      dispatch({ type: 'VERIFY_PAYMENT_ERROR', payload: error });
    }
  };

  

  const  PaidListMemberInsert = async (params) => {
    try {
      const response = await axios.post(`${API}`+`about_community/paid_list_member_insert` , params);
      
      dispatch({ type: 'INSERT_PAYMENT', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'INSERT_PAYMENT_ERROR', payload: error });
    }
  };



  const  PaidListMemberUpdate = async (params) => {
    try {
      const response = await axios.patch(`${API}`+`about_community/paid_list_member_update` , params);
      
      dispatch({ type: 'UPDATE_PAYMENT', payload: response.data });
    } catch (error) {
      dispatch({ type: 'UPDATE_PAYMENT_ERROR', payload: error });
    }
  };


  useEffect(() => {
       
    fetchMemberShip({
         "userId":localStorage.getItem(USER_ID),
        "communityId":localStorage.getItem(COMMUNITY_ID),
        "original": "en",
       "translate": [localStorage.getItem(TRANSLATED_LANG)]
     });
    
  }, []);


 
  
  return <mmebership_searchContext.Provider value={{ ...state , dispatch , fetchMemberShip , createOrder , verifyPayment ,PaidListMemberInsert , PaidListMemberUpdate }}>{children}</mmebership_searchContext.Provider>;
};

export const useMembershipContext = () => useContext(mmebership_searchContext);
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/uploadedfilesReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';

const uploadedFilesContext = createContext();

const initialState = {
  loading_uploadedFiles: true,
  error: null,
  list_uploadedFiles_admin:[],
  list_uploadedProofs_admin:[],
  total_row_count_uploadedfiles:0,
  total_row_count_uploadedproofs:0,
  verifypic1_list1:[],
  verifypic2_list1:[],
  verifypic3_list1:[],
  verifypic4_list1:[],
  verifypic5_list1:[],
  verifypic6_list1:[],
  verifypic7_list1:[],
  verifypic8_list1:[],
};

let API = `${BASE_URL}`;


export const UploadsProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);


  const uploadedfiles_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/pictures_select` , article);
      
      dispatch({ type: 'UPLOADFILES_LIST_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'UPLOADFILES_LIST_ERROR', payload: error });
    }
  };

  const uploadedproofs_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/user_content/proofs_select` , article);
      
      dispatch({ type: 'UPLOADPROOFS_LIST_SUCCESS', payload: response.data.data , payload1:response.data.success });
    } catch (error) {
      dispatch({ type: 'UPLOADPROOFS_LIST_ERROR', payload: error });
    }
  };



  const verifypic1 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic1` , article);
    
    dispatch({ type: 'VERIFYPIC11', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC1_ERROR', payload: error });
  }
  
};



const verifypic2 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic2` , article);
    
    dispatch({ type: 'VERIFYPIC21', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC2_ERROR', payload: error });
  }
  
};


const verifypic3 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic3` , article);
    
    dispatch({ type: 'VERIFYPIC31', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC3_ERROR', payload: error });
  }
  
};


const verifypic4 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic4` , article);
    
    dispatch({ type: 'VERIFYPIC41', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC4_ERROR', payload: error });
  }
  
};


const verifypic5 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic5` , article);
    
    dispatch({ type: 'VERIFYPIC51', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC5_ERROR', payload: error });
  }
  
};


const verifypic6 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic6` , article);
    
    dispatch({ type: 'VERIFYPIC61', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC6_ERROR', payload: error });
  }
  
}

const verifypic7 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic7` , article);
    
    dispatch({ type: 'VERIFYPIC71', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC7_ERROR', payload: error });
  }
  
};


const verifypic8 = async (article) => {

  try {
    const response = await axios.post(`${API}`+`adminpanel/user_content/verifypic8` , article);
    
    dispatch({ type: 'VERIFYPIC81', payload: response.data.data , payload1:response.data.success });
  } catch (error) {
    dispatch({ type: 'VERIFYPIC8_ERROR', payload: error });
  }
  
};



  
  return <uploadedFilesContext.Provider value={{ ...state , dispatch , uploadedfiles_select , uploadedproofs_select , verifypic1 ,verifypic2 ,verifypic3 ,verifypic4 ,verifypic5 ,verifypic6 ,verifypic7 ,verifypic8}}>{children}</uploadedFilesContext.Provider>;
};

export const useUploadedFilesContextAdmin = () => useContext(uploadedFilesContext);
const reducerOccupation = (state, action) => {
    switch (action.type) {
      case 'OCCUPATION_DETAILS_EDIT':
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case 'OCCUPATION_DETAILS_EDIT_ERROR':
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };

        case 'INSERT_OCCUP_DETAILS':
        return {
          ...state,
          occupation_insert: action.payload,
          loading: false,
          error: null,
        };
      case 'INSERT_OCCUP_DETAILS_ERROR':
        return {
          ...state,
          occupation_insert: [],
          loading: false,
          error: action.payload,
        };

        case 'UPDATE_OCCUP_DETAILS':
        return {
          ...state,
          occupation_update: action.payload,
          loading: false,
          error: null,
        };
      case 'UPDATE_OCCUP_DETAILS_ERROR':
        return {
          ...state,
          occupation_update: [],
          loading: false,
          error: action.payload,
        };
   
      default:
        return state;
    }
  };

  export default reducerOccupation;
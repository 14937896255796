import { useState } from "react";
import { ADMIN_COMMUNITY_ID, ROLE } from "../../../common_utils/constants";
import CommunityModal from "../../component/CommunityModal";
import StylishLabel from "../../component/StylishLabel";
import { DashboardProvider } from "../../contexts_admin/dashboard_context";
import { usePaymentContext } from "../../contexts_admin/payment_context";
import { Chip, Grid, Pagination, Switch  ,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import RotatingHeart from "../../../common_utils/loading_animator";
import { useNavigate } from "react-router-dom";
import ViewDetailsMembership from "./ViewDetailsMembership";
import React from "react";





function MembershipListAdmin(){

    const {membership_fetchByCommunityId , data_membership_communityId ,loading_payment_admin , membership_update ,data_membrship_update} = usePaymentContext();


    const [load, setLoad] = useState(false);
    const [openCommunity , setopenCommunity] = useState(false);
    const [communityId, setCommunityId] = useState(1);

    const [MembershipList, setMembershipList] = useState([]);
    const [openDetails , setOpenDetails] = useState(false);
    const [Index , setIndex] = useState(-1);

    let role = localStorage.getItem(ROLE);

    const handleOpenDetails = (index) => {
      setIndex(index);
      setOpenDetails(true);

    }

    const handleCloseDetails = () => {

      setOpenDetails(false);

    }




    const theme = useTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& $notchedOutline': {
                  borderColor: 'red', // Change this to your desired outline color
                },
                '&:hover $notchedOutline': {
                  borderColor: '#FFC0CB', // Change this to your desired outline color on hover
                },
              },
            },
          },
        },
      });

      


    useEffect(()=>{

        if(data_membership_communityId.length > 0 || loading_payment_admin == false){

          setLoad(true);
          setMembershipList(data_membership_communityId);

        }else{


          let communityId_admin  =  "0";
          if(localStorage.getItem(ADMIN_COMMUNITY_ID) == -1  || localStorage.getItem(ADMIN_COMMUNITY_ID) == "null"){

            communityId_admin = localStorage.getItem("communityId_admin");
            setCommunityId(communityId_admin);

          }else{
           communityId_admin = localStorage.getItem(ADMIN_COMMUNITY_ID);
            setCommunityId(communityId_admin);
          }

          membership_fetchByCommunityId({
                "communityId":communityId_admin,
              });

        }
       
    },[data_membership_communityId]);




    const handleClickCommunity = () => {
        setopenCommunity(true);
      }
  
  
      const handleCloseCommunity = (isopen) => {
        setopenCommunity(isopen);
      }
  

      const handleClickCommunityLink = (communityId_admin) => {
  
        setCommunityId(communityId_admin);
        localStorage.setItem("communityId_admin" , communityId_admin);
  
        membership_fetchByCommunityId({
            "communityId":communityId_admin,
          });
      }


      const handleSwitchChange = (data , event, index) => {
        const newData = [...data_membership_communityId];
        event.target.checked ? newData[index].ishidden = 0 : newData[index].ishidden = 1;
        setMembershipList(newData);


        membership_update({ 
            "image": data.image,
            "plan_name": data.plan_name,
            "plan_price": data.plan_price,
            "details": data.details,
            "validity_days": data.validity_days,
            "profile_display": data.profile_display,
            "gallery_view": data.gallery_view,
            "chatoption": data.chatoption,
            "express_interest": data.express_interest,
            "number_express_interest": data.number_express_interest,
            "horoscopeview": data.horoscopeview,
            "pdf_access": data.pdf_access,
            "num_pdf_access": data.num_pdf_access,
            "verified_phonenumbers": data.verified_phonenumbers,
            "allowed_contacts": data.allowed_contacts,
            "number_contacts": data.number_contacts,
            "number_horoscope": data.number_horoscope,
            "number_chat": data.number_chat,
            "number_video": data.number_video,
            "verified_id_docs": data.verified_id_docs,
            "discount_type": data.discount_type,
            "coupon_code": data.coupon_code,
            "discount": data.discount,
            "ishidden": newData[index].ishidden,
            "Id": data.Id,
            "communityId":communityId
          });
      
      };


      const navigate = useNavigate();
      const addMembership = () => {

       navigate("/add_membership");

      }


      const handleUpdateMembership = (index) => {

  
        navigate('/update_membership', {
          // Define your object here
          state: {
            // Define your object here
            myObject: { key: MembershipList[index]}
          }
        });
  
  
      };



    return (<>
    
        {  load == false ?  <RotatingHeart/> : 
           
           <div  style={{backgroundColor:"#FAFBFD"}}>
    
            { role == "developer" ? <StylishLabel text={"Select Community"}  clickSelectCommunity={handleClickCommunity} /> : ""}
    
            <Grid container spacing={2} style={{ marginLeft: "auto", marginRight: "auto", position: "absolute", top: role == "developer" ? "165px" : "100px", left: role == "developer" ? "345px" : "345px" , width: "76%" , paddingRight:"15px"   }}>

        <Grid key={6} item xs={12} spacing={0} style={{marginBottom:"10px"}}>
    
        <Typography  variant="h6" align="left" width="200px" style={{paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}>Membership Plans</Typography>
          <Typography  variant="h6" align="left" width="300px" style={{position:"absolute" , right:"15px" , marginTop:"-32px" ,paddingLeft:"20px" , color:"blue" , fontWeight:"bold" , backgroundColor:"#ffc0cb"}}  onClick={addMembership}>Click To Add Membership</Typography>
    
    <TableContainer component={Paper}>
      <Table>
        <TableHead>

          <TableRow style={{width:"100%"}}>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Seq.</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>PlanName</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>PlanPrice</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Validity Days</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Details</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Visibility</TableCell>
            <TableCell style={{ fontWeight:"bold" , color:"black" }}>Edit/View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {MembershipList.length > 0 && MembershipList.map((free_members, index) => (
            <TableRow key={free_members.Id} style={{ backgroundColor: index % 2 === 0 ? '#f0f8ff' : '#f0f0f0' }}>
                <TableCell style={{ color: 'black', fontSize: '15px'  }}>{(index+1)}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.plan_name}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{"RS. -> "+free_members.plan_price}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{"Validity : "+free_members.validity_days}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>{free_members.details}</TableCell>
              <TableCell style={{ color: 'black', fontSize: '15px'  }}>
              <Switch
                  checked={free_members.ishidden == 0 ? true : false}
                  onChange={(event) => handleSwitchChange(free_members, event, index)}
                  color="primary"
                />
                </TableCell>
              <TableCell><span
          style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }}  onClick={()=> handleOpenDetails(index)} >
          {"View Details"}
        </span><br/> <span
          style={{ cursor: 'pointer', textDecoration: 'underline' ,fontSize:"16px" }}  onClick={()=> handleUpdateMembership(index)}>
          {"Edit Details"}
        </span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    
   
    
    
 
            </Grid>
    
            </Grid>
        
    
            {openCommunity == true ? <DashboardProvider><CommunityModal isopen={true} setClose={handleCloseCommunity}   setClickedLink={handleClickCommunityLink}/></DashboardProvider> : ""}
            {openDetails == true ?  <ViewDetailsMembership isopen={true} setClose={handleCloseDetails} Details={MembershipList[Index]} />  : ""}
    
        </div>
    
        }
        
        </>);


}

export default MembershipListAdmin;
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import reducer from '../reducer_admin/rolesReducerAdmin';
import { BASE_URL } from '../../common_utils/constants';

const userRolesContext = createContext();

const initialState = {
  loading_user_roles: true,
  error: null,
  data_insert_roles: [],
  data_update_roles:[],
  data_insert_rolespermission:[],
  data_roles_select:[],
  data_rolespermision_select:[],
  data_insert_allstaff:[],
  data_update_allstaff:[],
  data_rolesmaster_select:[]
};

let API = `${BASE_URL}`;


export const UserRolesProvider = ({ children }) => {

const [state, dispatch] = useReducer(reducer , initialState);

const add_rolecommunity = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/insert_roles` , article);
    
      dispatch({ type: 'ADD_ROLES_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'ADD_ROLES_ERROR', payload: error });
    }
  };



  const add_rolepermission = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/add_roles_permission` , article);
    
      dispatch({ type: 'ADD_ROLES_PERMISSION_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'ADD_ROLES_PERMISSION_ERROR', payload: error });
    }
  };


  const update_rolecommunity = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/update_roles` , article);
    
      dispatch({ type: 'UPDATE_ROLES_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'UPDATE_ROLES_ERROR', payload: error });
    }
  };

  const roles_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/roles_fetch` , article);
      
      dispatch({ type: 'ROLES_LIST_SUCCESS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ROLES_LIST_ERROR', payload: error });
    }
  };


  const roles_master_select = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/roles_master_fetch` , article);
      
      dispatch({ type: 'ROLESMASTER_LIST_SUCCESS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'ROLESMASTER_LIST_ERROR', payload: error });
    }
  };



  const add_all_staff = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/all_staff_insert` , article);
    
      dispatch({ type: 'ADD_ALLSTAFF_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'ADD_ALLSTAFF_ERROR', payload: error });
    }
  };




  const update_all_staff = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/all_staff_update` , article);
    
      dispatch({ type: 'UPDATE_ALLSTAFF_SUCCESS', payload: response.data.data  });
    } catch (error) {
      dispatch({ type: 'UPDATE_ALLSTAFF_ERROR', payload: error });
    }
  };



  const roles_permission_fetch = async (article) => {
    try {
      const response = await axios.post(`${API}`+`adminpanel/role_community/permission_fetch` , article);
      
      dispatch({ type: 'PERMISSION_LIST_SUCCESS', payload: response.data.data });
    } catch (error) {
      dispatch({ type: 'PERMISSION_LIST_ERROR', payload: error });
    }
  };


 
  return <userRolesContext.Provider value={{ ...state , dispatch , add_all_staff ,update_all_staff ,add_rolepermission ,add_rolecommunity , update_rolecommunity , roles_select , roles_permission_fetch , roles_master_select}}>{children}</userRolesContext.Provider>;
};

export const useRolesContextAdmin = () => useContext(userRolesContext);

const reducer = (state, action) => {
    switch (action.type) {

      case 'ADD_BLOGS_SUCCESS':
        return {
          ...state,
          data_insert_blogs: action.payload,
          loading_blogs: false,
          error: null,
        };

      case 'ADD_BLOGS_ERROR':
        return {
          ...state,
          data_insert_blogs: [],
          loading_blogs: false,
          error: action.payload,
        };



        case 'BLOGS_LIST_SUCCESS':
            return {
              ...state,
              list_blogs_admin: action.payload,
              loading_blogs: false,
              error: null,
            };
    
          case 'BLOGS_LIST_ERROR':
            return {
              ...state,
              list_blogs_admin: [],
              loading_blogs: false,
              error: action.payload,
            };

      default:
        return state;
    }
  };

  export default reducer;